<template>
  <div class="relative mt-10 mb-10 z-20">
    <div
      :class="darkMode ? 'darks' : 'lights'"
      class="wrapper-content-items wr_responsive"
    >
      <div class="split-container m-auto w-full">
        <div class="content-principal-home">
          <TaskHome />
          <BannerHome />
        </div>
      </div>
      <div class="wrapper-home">
        <!-- <EventFacebook /> -->
        <AcademyHome />
        <!-- <BlogHome /> -->
        <BannerHomeBottom />
      </div>
    </div>
  </div>
</template>

<script>
// import BlogHome from '../components/home/BlogHome.vue'
import AcademyHome from "../components/home/AcademyHome.vue";
// import EventFacebook from '../components/home/eventFacebook.vue'
import TaskHome from "../components/home/TaskHome.vue";
import BannerHome from "../components/home/BannerHome.vue";
import BannerHomeBottom from "../components/home/BannerHomeBottom.vue";

export default {
  name: "HomeIndex",
  components: {
    // BlogHome,
    BannerHome,
    TaskHome,
    AcademyHome,
    BannerHomeBottom,
    // EventFacebook,
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
};
</script>

<style scoped>
.darks {
  background-color: #233345;
}
.lights {
  @apply bg-white;
}
.content-new-facebook {
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.wrapper-content-items {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  @apply max-w-screen-xl mx-auto px-4 py-4 rounded-xl;
}
.split-container {
  @apply hidden;
}
.wrapper-home {
  @apply flex w-full p-5 flex-col;
}
.content-principal-home {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}
.sidebar-home {
  display: flex;
}
.wr_responsive {
  display: inherit;
}
@screen sm {
  .split-container {
    @apply flex flex-row w-full p-5;
  }
}
@media (max-width: 900px) {
  .sidebar-home {
    display: none;
  }
  .content-principal-home {
    display: none;
  }
}
@media (max-width: 800px) {
  .wr_responsive {
    display: none;
  }
}
</style>
