export default {
  methods: {
    idCloudinaryImg(url) {
      let fitImage = url.split("/upload/");
      let fitImage2 = url.split("/");
      return `https://res.cloudinary.com/${fitImage2[3]}/image/upload/c_crop,dpr_auto,g_custom,q_auto:best/${fitImage[1]}`;
    },
    idCloudinaryAwards(url, width, height) {
      let fitImage = url.split("/upload/");
      let fitImage2 = url.split("/");
      if (fitImage2[2] == "res.cloudinary.com") {
        return `https://res.cloudinary.com/${fitImage2[3]}/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/c_crop,g_custom/h_${height},w_${width},b_auto,c_pad/${fitImage[1]}`;
      } else {
        return url;
      }
    },
  },
};
