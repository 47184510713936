import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

import "../assets/css/sweetalert2.css";
// import 'sweetalert2/dist/sweetalert2.min.css'

const options = {
  confirmButtonColor: "#4429b4",
  cancelButtonColor: "#ff7674",
  backdrop: "rgba(14,47,90,0.6)",
  confirmButtonText: "Aceptar",
  cancelButtonText: "Cancelar",
  reverseButtons: true,
};

Vue.use(VueSweetalert2, options);
