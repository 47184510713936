<template>
  <div class="principal">
    <div class="contain">
      <div class="left">
        <div>
          <div class="hola">
            <h1 id="nameuser">Hola, {{ nombreusuario[0] }}</h1>
          </div>
          <p id="quebueno">¡Que bueno verte!</p>
        </div>
        <hr />
        <div
          v-if="
            dataUrl && dataUrl.showLinkTienda != 'https://undefined.komercia.co'
          "
        >
          <p id="title-url">Tu dirección web:</p>
          <a
            :title="`Ir a al tienda ${dataUrl.showLinkTienda}`"
            :href="`${dataUrl.redirectLinkTienda}`"
            id="linktienda"
            target="_blank"
          >
            <p class="showDomainStore">
              <strong>{{ dataUrl.showLinkTienda }}</strong>
            </p>
          </a>
          <!-- <p>Compartir</p> -->
        </div>
      </div>
      <div class="right">
        <div class="boxabsolute">
          <img
            loading="lazy"
            id="boxes"
            src="../../assets/iconosBannerHome/boxes.png"
            alt
          />
          <img
            loading="lazy"
            id="circles"
            src="../../assets/iconosBannerHome/circles.png"
            alt
          />
          <img
            loading="lazy"
            id="piece"
            src="../../assets/iconosBannerHome/piece.png"
            alt
          />
          <img
            loading="lazy"
            id="xgreen"
            src="../../assets/iconosBannerHome/xgreen.png"
            alt
          />
        </div>
        <div class="boxrelative">
          <div class="boxtext">
            <span class="elipse"></span>
            <p id="textdownload">Descargala ya</p>
            <span class="elipse"></span>
          </div>
          <div class="boxstore">
            <a
              title="Descargar app de Komercia"
              href="https://apps.apple.com/us/app/komercia-pos/id1477983869"
              target="blank"
            >
              <img
                loading="lazy"
                id="store"
                src="../../assets/iconosBannerHome/appstore.png"
              />
            </a>
            <a
              title="Descargar app de Komercia"
              href="https://play.google.com/store/apps/details?id=com.kontrol&hl=es"
              target="blank"
            >
              <img
                loading="lazy"
                id="store"
                src="../../assets/iconosBannerHome/playstore.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLinkTienda: "",
      redirectLinkTienda: "",
    };
  },

  computed: {
    nombreusuario() {
      let name = this.$store.state.configKomercia.usuario.nombre.split(" ");
      return name;
    },
    dataUrl() {
      let dataTienda = this.$store.state.storeData;
      //si la tienda tiene dominio
      if (
        dataTienda.informacion_tienda &&
        dataTienda.informacion_tienda[0].dominio
      ) {
        let newUrl = dataTienda.informacion_tienda[0].dominio.split("//");
        return {
          showLinkTienda: newUrl[1],
          redirectLinkTienda: dataTienda.informacion_tienda[0].dominio,
        };
      }
      //Si la tienda no tiene dominio
      else {
        return {
          showLinkTienda: `${dataTienda.subdominio}.komercia.co`,
          redirectLinkTienda: `https://${dataTienda.subdominio}.komercia.co`,
        };
      }
    },
  },
};
</script>

<style scoped>
.principal {
  width: 100%;
  display: grid;
  align-items: center;
}
.contain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 120px;
  background-color: #4429b4;
  border-radius: 7px;
  /* grid-gap: 5px; */
  justify-content: space-between;
  width: 100%;
}
.left {
  display: grid;
  grid-template-columns: 1fr 35px 1fr;
  justify-items: center;
  align-items: center;
  color: #fff;
  padding: 35px;
}
.hola {
  display: inline-flex;
  font-weight: 500;
}
#quebueno {
  font-size: 16px;
  font-weight: 500;
}
#title-url {
  font-size: 16px;
  font-weight: 500;
}
.hola p {
  font-size: 17px;
}
#linktienda {
  color: #fff;
  font-size: 12px;
}
#linktienda h1 {
  font-weight: normal;
}
#nameuser {
  color: #00dd8d;
  font-size: 18px;
  white-space: nowrap;
  line-height: 1.18;
}
.showDomainStore {
  font-weight: bold;
  font-size: 16px;
}
.left hr {
  border-left: 1px solid #fff;
  height: 100%;
}
.right {
  height: 100%;
  position: relative;
}
.right .boxabsolute {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 7px;
}
.boxabsolute #circles {
  top: -35px;
  position: absolute;
  width: 70px;
  left: 10px;
}
.boxabsolute #boxes {
  position: absolute;
  width: 100px;
  bottom: -45px;
  right: 30px;
}
.boxabsolute #piece {
  position: absolute;
  width: 140px;
  top: -50px;
  right: -53px;
}
.boxabsolute #xgreen {
  position: absolute;
  bottom: 10px;
  width: 15px;
  left: 40px;
}
.boxabsolute #xyellow {
  position: absolute;
  top: 20px;
  right: 85px;
  width: 15px;
}
.boxrelative {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-gap: 5px;
  align-content: center;
  margin: 25px 0px;
}
.boxrelative #store {
  width: 120px;
  transition: all ease 0.5s;
  border-radius: 9px;
}
.boxrelative #store:hover {
  /* background-color: #fdd103; */
  background-color: #ce2181;
}
.boxrelative #textdownload {
  width: 100px;
  height: 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.26px;
  text-align: left;
  color: #00dd8d;
}
.boxtext {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
}
.boxstore {
  display: grid;
  grid-auto-flow: column;
  width: 250px;
  grid-gap: 10px;
  z-index: 998;
}
.elipse {
  width: 3px;
  height: 3px;
  background-color: #e62178;
  border-radius: 30px;
}
.principal {
  display: none;
}
@media (max-width: 767px) {
  .contain {
    display: flex;
  }
  .right {
    width: 100%;
  }
  .left {
    display: none;
  }
  .principal {
    display: inline;
    padding-top: 20px;
  }
}
</style>
