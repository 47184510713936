<template>
  <div class="w-full">
    <p class="text-subtitle mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
      {{ $t("text_inventario_infoVariantes3") }}
    </p>
    <div class="variants">
      <div v-if="existVariants" class="variante_item header">
        <label class="subtitle">{{ $t("text_inventario_nombreOpcion") }}</label>
        <label class="subtitle">
          {{ $t("text_inventario_ValoresOpcion") }}
        </label>
      </div>
      <div
        v-for="(item, index) in variants"
        :id="item.key"
        :key="`variante_${index}`"
        class="variante_item"
      >
        <el-button
          type="danger"
          icon="el-icon-delete"
          @click="deleteVariant(index)"
        ></el-button>
        <div class="custom-input optionName">
          <div class="input-area">
            <el-input
              v-model="item.nombre"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              @input.native="getIndex('nombre', index)"
            ></el-input>
          </div>
        </div>
        <div class="custom-input variant_options">
          <el-input
            v-model="item.newVariant"
            :placeholder="$t('text_inventario_escribeOpcion')"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
            @input.native="getIndexOption('newVariant', index)"
            @keyup.enter.native="
              newOptionOfVariant(item.newVariant, index, item.nombre)
            "
          ></el-input>
          <div class="input-area" @click="focusInputVariant(item.key)">
            <ul>
              <li
                v-for="(option, index) in item.valores"
                :key="`valor_${index}`"
                class="text"
              >
                <div
                  :class="
                    errorsCombinations.indexOf(option.option) == -1
                      ? 'correct'
                      : 'wrong'
                  "
                >
                  <span class="tooltiptext">
                    {{ $t("text_inventario_cambiarNombre") }}
                  </span>
                  <i
                    v-if="errorsCombinations.indexOf(option.option) != -1"
                    class="el-icon-warning-outline"
                  ></i>
                  {{ option.option }}
                  <i
                    class="el-icon-close"
                    @click="deleteOptionOfVariant(option, index)"
                  >
                  </i>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <el-button
          type="primary"
          plain
          @click="newOptionOfVariant(item.newVariant, index, item.nombre)"
        >
          {{ $t("text_inventario_agregar") }} {{ item.nombre }}
        </el-button>
      </div>
      <el-button
        type="primary"
        plain
        :disabled="variants.length >= 3 ? true : false"
        @click="_addVariant"
      >
        {{ $t("text_inventario_agregarVariantes") }}
      </el-button>
    </div>
    {{ validCombinations() }}
    <template v-if="existCombinations2">
      <p
        class="text-subtitle mb-2 mt-2"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        {{ $t("text_inventario_modificarVariantes") }}
      </p>
      <ul>
        <li class="combinations_item header">
          <label class="subtitle">{{ $t("text_inventario_variantes") }}</label>
          <label class="subtitle">{{ $t("text_ventas_precio") }}</label>
          <label class="subtitle">{{ $t("text_ventas_unidades") }}</label>
          <label class="subtitle">
            {{
              storeData.id === 1559 ||
              storeData.id === 16436 ||
              storeData.id === 582
                ? "# WhatsApp / Referencia"
                : $t("text_inventario_referencia2")
            }}
          </label>
        </li>
        <li
          v-for="(item, index) in combinations"
          :key="`combinacion_${index}`"
          class="combinations_item"
        >
          <div class="cntr">
            <label :for="`cbx${index}`" class="label-cbx">
              <input
                :id="`cbx${index}`"
                v-model="item.estado"
                type="checkbox"
                class="invisible inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
              />
              <div class="checkbox">
                <svg width="20px" height="20px" viewBox="0 0 20 20">
                  <path
                    d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"
                  ></path>
                  <polyline points="4 11 8 15 16 6"></polyline>
                </svg>
              </div>
              <span class="combinations_item_options">
                <p
                  v-for="(combinacion, index) in item.combinacion"
                  :key="`desc_${index}`"
                >
                  {{ combinacion }}
                </p>
              </span>
            </label>
          </div>
          <div class="custom-input">
            <div class="input-area">
              <div class="el-input">
                <money
                  v-model="item.precio"
                  v-bind="money"
                  class="money-idTienda"
                  :class="darkMode ? 'darks-money' : 'light-money'"
                >
                </money>
              </div>
            </div>
          </div>
          <div class="custom-input">
            <div class="input-area">
              <el-input
                v-model.trim="item.unidades"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
              ></el-input>
            </div>
          </div>
          <div class="custom-input">
            <div class="input-area">
              <el-input
                v-model="item.sku"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
              ></el-input>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import setFormatMoney from "../mixins/formatCurrent";
export default {
  mixins: [setFormatMoney],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sel: {},
      existVariants: false,
      limitVariants: false,
      existCombinations: false,
      existCombinations2: false,
      hasCharacterSpecial: false,
      errorsCombinations: [],
      params: [],
    };
  },
  computed: {
    ...mapState(["storeData", "darkMode"]),
    darkMode() {
      return this.$store.state.darkMode;
    },
    variants: {
      get() {
        return this.$store.getters.getValidateVariants;
      },
      set(newValue) {
        this.$store.state.variants = newValue;
      },
    },
    combinations: {
      get() {
        return this.$store.state.combinations;
      },
      set(newValue) {
        this.$store.state.combinations = newValue;
      },
    },
  },
  watch: {
    combinations: {
      immediate: true,
      handler(newValue) {
        let errors = [];
        if (newValue.length) {
          newValue.forEach((item) => {
            item.combinacion.forEach((i) => {
              if (i.split("")[i.length - 1] === " ") {
                let c = i.trimEnd();
                errors.push(c);
              }
            });
          });
          this.errorsCombinations = [...new Set(errors)];
        }
      },
    },

    // data() {
    //   this.setCombinations()
    // },
  },
  created() {
    this.setFormatMoney(this.idCountryStore);
    // this.setCombinations()
  },
  // mounted() {
  //   this.editarVariantes()
  // },
  methods: {
    // setCombinations() {
    //   if (this.variants && this.variants.length === 0) {
    //     if (typeof this.data.combinaciones.combinaciones === 'string') {
    //       this.$store.state.combinations = JSON.parse(
    //         this.data.combinaciones.combinaciones
    //       )
    //     } else {
    //       this.$store.state.combinations = []
    //     }
    //     this.$store.state.variants = this.data.variantes
    //   }
    // },
    // editarVariantes() {
    //   if (this.combinations) {
    //     this.combinations.map((item, index) => {
    //       if (item.combinacion[2] === 'Cono sencillo') {
    //         this.combinations[index].precio = 9500
    //       } else if (item.combinacion[2] === 'Cono Doble') {
    //         this.combinations[index].precio = 11000
    //       }  else {
    //         console.log('No existe', item.combinacion)
    //       }
    //       this.combinations[index].unidades = 10000000
    //     })
    //   }
    // },
    validCombinations() {
      if (this.combinations.length !== 0 && this.combinations) {
        this.existCombinations2 = true;
      } else {
        this.existCombinations2 = false;
      }
    },
    focusInputVariant(key) {
      document.querySelector(`#${key} .variant_options input`).focus;
    },
    _addVariant() {
      let variant = {
        nombre: "",
        valores: [],
        key: `variant${this.variants.length + 1}`,
        newVariant: "",
      };
      if (this.variants.length === 0) {
        this.$set(variant, "nombre", "Talla");
      } else if (this.variants.length === 1) {
        this.$set(variant, "nombre", "Color");
      } else {
        this.$set(variant, "nombre", "Material");
      }
      this.$set(this.variants, this.variants.length, variant);
      this.existVariants = true;
      if (this.variants.length === 3) {
        this.limitVariants = true;
      }
    },
    deleteVariant(index) {
      this.variants.splice(index, 1);
      this.limitVariants = false;
      if (this.variants.length === 0) {
        this.existVariants = false;
      }
      this._possibleCombinations();
    },
    validateValues(value) {
      if (value.match(/[`°!@#$%^&*()_+=[\]{};:"\\|,.<>/?¿~]/)) {
        this.$message.error("No se permiten caracteres especiales");
        return value.slice(0, -1);
      } else {
        return value;
      }
    },
    validateValuesOption(value) {
      if (value.match(/[`°!@#$%^&*()_+=[\]{};:"\\|<>?¿~]/)) {
        this.$message.error(
          `Solo se permiten los siguientes caracteres especiales: '/' (barra diagonal) ',' (coma) '.' (punto)`
        );
        return value.slice(0, -1);
      } else {
        return value;
      }
    },
    getIndex(value, index) {
      this.variants[index][value] = this.validateValues(
        this.variants[index][value]
      );
    },
    getIndexOption(value, index) {
      this.variants[index][value] = this.validateValuesOption(
        this.variants[index][value]
      );
    },
    newOptionOfVariant(newVariant, index, nombre) {
      if (newVariant !== "") {
        let option = {
          option: newVariant,
          parent_index: index,
        };
        if (nombre === "") {
          this.$message.error("Ingrese el nombre de la variante");
        } else {
          this.hasCharacterSpecial = false;
          this.variants[index].valores.push(option);
          this.variants[index].newVariant = "";
          this.existCombinations = true;
          this._possibleCombinations();
        }
      } else {
        this.$message.error("No se permiten campos vacíos");
      }
    },
    deleteOptionOfVariant(option, index) {
      this.variants[option.parent_index].valores.splice(index, 1);
      this._possibleCombinations();
    },
    _possibleCombinations() {
      let countLoop = 0;
      let arrayLoop = [];
      for (var i = 0; i < 3; i++) {
        if (this.variants[i] && this.variants[i].valores.length !== 0) {
          countLoop++;
          arrayLoop.push(i);
        }
      }
      this._loopPossibleCombinations(countLoop, arrayLoop);
    },
    dataCombinations(previousCombination) {
      for (let i = 0; i < this.combinations.length; i++) {
        for (let j = 0; j < previousCombination.length; j++) {
          if (
            JSON.stringify(this.combinations[i].combinacion) ===
            JSON.stringify(previousCombination[j].combinacion)
          ) {
            this.$set(
              this.combinations[i],
              "precio",
              previousCombination[j].precio
            );
            this.$set(
              this.combinations[i],
              "unidades",
              previousCombination[j].unidades
            );
            this.$set(this.combinations[i], "sku", previousCombination[j].sku);
          }
        }
      }
    },
    _loopPossibleCombinations(countLoop, arrayLoop) {
      let previousCombination = [];
      if (this.combinations.length) {
        previousCombination = JSON.stringify(this.combinations);
      }
      this.combinations = [];
      if (countLoop === 1) {
        for (let option1 of this.variants[arrayLoop[0]].valores) {
          this.params = {
            combinacion: [],
            estado: true,
            precio: 0.0,
            unidades: 0,
            sku: "",
          };
          this.$set(this.params.combinacion, 0, option1.option);
          this.$set(this.combinations, this.combinations.length, this.params);
        }
      } else if (countLoop === 2) {
        for (let option1 of this.variants[arrayLoop[0]].valores) {
          for (let option2 of this.variants[arrayLoop[1]].valores) {
            this.params = {
              combinacion: [],
              estado: true,
              precio: 0.0,
              unidades: 0,
              sku: "",
            };
            this.$set(this.params.combinacion, 0, option1.option);
            this.$set(this.params.combinacion, 1, option2.option);
            this.$set(this.combinations, this.combinations.length, this.params);
          }
        }
      } else {
        for (let option1 of this.variants[arrayLoop[0]].valores) {
          for (let option2 of this.variants[arrayLoop[1]].valores) {
            for (let option3 of this.variants[arrayLoop[2]].valores) {
              this.params = {
                combinacion: [],
                estado: true,
                precio: 0.0,
                unidades: 0,
                sku: "",
              };
              this.$set(this.params.combinacion, 0, option1.option);
              this.$set(this.params.combinacion, 1, option2.option);
              this.$set(this.params.combinacion, 2, option3.option);
              this.$set(
                this.combinations,
                this.combinations.length,
                this.params
              );
            }
          }
        }
      }
      this.dataCombinations(JSON.parse(previousCombination));
    },
  },
};
</script>

<style scoped>
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.money-idTienda >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  padding: 5px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  width: 100%;
}
.darks-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #f3f4f6;
}
.light-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #1f2937;
}
.text-subtitle {
  font-size: 13px;
  font-weight: 500;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.button {
  max-width: 250px;
  padding: 6px 20px;
  margin-right: 35px;
  background-color: var(--color-green-cta);
  position: relative;
  cursor: pointer;
  border: 1px solid white;
  transition: all 0.2s ease-in-out;
  height: auto !important;
}
.cntr {
  max-width: 340px;
  @apply w-full flex items-center;
}
.label-cbx {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.label-cbx input:checked + .checkbox {
  border-color: var(--color-blue-dark);
}
.label-cbx input:checked + .checkbox svg path {
  fill: var(--color-blue-dark);
}
.label-cbx input:checked + .checkbox svg polyline {
  stroke-dashoffset: 0;
}
.label-cbx:hover .checkbox svg path {
  stroke-dashoffset: 0;
}
.label-cbx .checkbox {
  position: relative;
  float: left;
  margin-right: 8px;
  width: 15px;
  height: 15px;
  border: 2px solid #c8ccd4;
  border-radius: 3px;
}
.label-cbx .checkbox svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 15px;
  height: 15px;
}
.label-cbx .checkbox svg path {
  fill: none;
  stroke: var(--color-blue-dark);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  transition: all 0.6s ease;
}
.label-cbx .checkbox svg polyline {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  transition: all 0.3s ease;
}
.label-cbx > span {
  pointer-events: none;
  vertical-align: middle;
}
.invisible {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}
.variants {
  padding: 0 0 20px 0;
  border-bottom: 1px solid var(--color-border);
}
.variante_item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.variante_item.header label {
  max-width: 200px;
  width: 100%;
  margin-bottom: 5px;
}
.variante_item.header label:last-child {
  max-width: 450px;
  width: 100%;
  margin-left: 10px;
}
.variante_item .el-button--danger {
  margin-right: 10px;
}
.variante_item .custom-input.optionName {
  max-width: 150px;
  width: 100%;
}
.variant_delete {
  height: 35px;
  display: flex;
  align-items: center;
  border-style: none;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
.variant_delete:hover {
  background-color: #e9394e;
}
.variant_delete i {
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.variant_options {
  max-width: 400px;
  width: 100%;
  margin: 0 10px;
}
.variant_options .el-input {
  width: 100%;
}
.variant_options .input-area {
  border: 1px solid var(--color-border);
  border-radius: 6px;
}
.variant_options .input-area:hover {
  background-color: var(--color-blue-light);
}
.variant_options input {
  background-color: transparent !important;
  border-style: none !important;
}
.variant_options .input-area ul {
  margin: 0;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.variant_options .input-area ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 5px;
  /* padding: 5px; */
  border-radius: 4px;
}
/* .variant_options .input-area ul li {
  background-color: #008c9d;
} */
.variant_options .input-area ul li i {
  font-size: 15px;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}
.combinations_item.header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  color: #919191;
}
.combinations_item {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
  color: #919191;
}
.combinations_item_options p {
  margin: 0;
}
.combinations_item_options p {
  color: #919191;
}
.combinations_item_options p::after {
  content: "-";
  margin: 0 3px;
}
.combinations_item_options p:last-of-type:after {
  content: "";
}
.combinations_item_options {
  display: flex;
}
.combinations_item .custom-input .input-area {
  margin: 0px !important;
}
.wrong {
  background: #e9394e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}
.wrong {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.wrong .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #303133;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.wrong .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #303133 transparent transparent transparent;
}
.wrong:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.correct {
  background: #008c9d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}
.correct .tooltiptext {
  display: none;
}
/* #4429b4 */
@media (max-width: 1040px) {
  .cntr {
    max-width: 200px;
    min-width: 200px;
  }
  .combinations_item.header {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
  .combinations_item {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .el-button {
    margin-right: 0px;
  }
}
@media (max-width: 550px) {
  .variante_item {
    flex-direction: column;
  }
  .variante_item .el-button--danger {
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .variante_item .custom-input.optionName {
    max-width: unset;
    width: 60%;
    margin-bottom: 10px;
  }
  .variant_options {
    display: flex;
    max-width: unset;
    width: 100%;
    margin: 0px 0px 10px 0px;
  }
  .variant_options .el-input {
    width: 60%;
  }
  .variant_options .input-area ul {
    flex-direction: row;
    padding: 0 5px;
    height: 100%;
    align-items: center;
  }
  .variant_options .input-area {
    width: 40%;
  }
  .variante_item .el-button {
    margin-bottom: 10px;
  }
  .label-cbx > span {
    word-break: break-all;
  }
  .optionName .el-input__inner {
    padding: 0 10px;
  }
  .el-input .el-input__inner {
    padding: 0 10px;
  }
}
</style>
