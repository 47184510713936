<template>
  <div class="quotation">
    <h2 class="mb-7">Cotización</h2>
    <el-row :gutter="30" class="quotation_form">
      <div class="input-area">
        <p class="text-info mb-2">
          Forma de pago de envío
          <i
            class="el-icon-question el-input__icon"
            @click="
              handleHelp(
                'Tipo de pago',
                '<strong>Pagar con Saldo</strong><br />Es una bolsa de saldo que recargas en mipaquete.com para pagar tus envíos <br /><br /><strong>Pago Contra Entrega (Cobrar el envío del recaudo a realizar)</strong><br />Permite que tus clientes paguen el producto + el envío al momento de recibir el producto. Asegúrate de tener tus datos bancarios guardados en mipaquete.com para poderte realizar la transferencia del recaudo. Ten presente que descontaremos automáticamente el valor del envío del recaudo a realizar. Consulta en mipaquete.com las fechas de pagos de recaudos.',
                true
              )
            "
          ></i>
        </p>
        <el-radio-group v-model="paymentType" resize="horizontal">
          <el-radio :label="101" border resize="horizontal">
            Pago con saldo
          </el-radio>
          <el-radio :label="102" border>
            Pago Contra Entrega (Cobrar el envío del recaudo a realizar)
          </el-radio>
        </el-radio-group>
      </div>
    </el-row>
    <el-row :gutter="30" class="quotation_form">
      <el-col :span="12">
        <div class="input-area">
          <p class="text-info mb-2">
            Valor declarado
            <i
              class="el-icon-question el-input__icon"
              @click="
                handleHelp(
                  'Valor declarado',
                  'Indica aquí el costo del producto, es decir, el valor que costó adquirir la mercancía. Sobre este valor se asegura el envío en caso de pérdida o avería.'
                )
              "
            ></i>
          </p>
          <div v-if="order?.venta?.total">
            <money
              v-model.lazy="order.venta.total"
              class="money-idTienda"
              placeholder="Escribe el numero"
              v-bind="money"
            />
          </div>
          <div v-else>
            <div v-if="declaredValue">
              <money
                v-model.lazy="declaredValue"
                class="money-idTienda"
                placeholder="Escribe el numero"
                v-bind="money"
              />
              <money
                v-model="declaredValueWithoutMoney"
                v-validate="'required|min_value:10000'"
                type="hidden"
                class="money-idTienda"
                v-bind="money"
                data-vv-name="declaredValue"
                data-vv-as="valor declarado"
              />
              <p class="error">{{ errors.first("declaredValue") }}</p>
            </div>
          </div>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Origen (Desde)</p>
          <el-select
            v-model="origin"
            class="inputs-idTienda light-input"
            filterable
            clearable
            remote
            placeholder="Escribe la ciudad"
            style="display: flex"
            :remote-method="(query) => remoteMethod(query, 'originOptions')"
          >
            <el-option
              v-for="location in originOptions"
              :key="`city${location._id}`"
              :label="`${location.locationName} - ${location.departmentOrStateName}`"
              :value="location.locationCode"
            ></el-option>
          </el-select>
          <input
            v-model="origin"
            v-validate="'required'"
            type="hidden"
            data-vv-name="origin_city"
            data-vv-as="ciudad de origen"
          />
          <p class="error">{{ errors.first("origin_city") }}</p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Destino (Hasta)</p>
          <el-select
            v-model="destiny"
            class="inputs-idTienda light-input"
            filterable
            clearable
            remote
            placeholder="Escribe la ciudad"
            style="display: flex"
            :remote-method="(query) => remoteMethod(query, 'destinyOptions')"
          >
            <el-option
              v-for="location in destinyOptions"
              :key="`city${location._id}`"
              :label="`${location.locationName} - ${location.departmentOrStateName}`"
              :value="location.locationCode"
            ></el-option>
          </el-select>
          <input
            v-model="destiny"
            v-validate="'required'"
            type="hidden"
            data-vv-name="destination_city"
            data-vv-as="ciudad de destino"
          />
          <p class="error">{{ errors.first("destination_city") }}</p>
        </div>
        <div v-if="paymentType == 102" class="input-area">
          <p class="text-info mb-2">Valor de la venta</p>
          <money
            v-model.lazy="saleValue"
            class="money-idTienda"
            placeholder="Escribe el numero"
            v-bind="money"
          />
          <p class="text-info">
            Al ser un envío con Pago Contra Entrega (Es decir, con recaudo)
            recaudaremos el valor de la venta más el costo del envío y te
            transferiremos a tu cuenta bancaria el valor recaudado del producto.
          </p>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="input-area">
          <p class="text-info mb-2">Cantidad</p>
          <el-input
            v-model="quantity"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            type="number"
            placeholder="Escribe el numero"
            data-vv-name="quantity"
            data-vv-as="cantidad"
          />
          <p class="error">
            {{ errors.first("quantity") }}
          </p>
        </div>
        <div>
          <p class="text-info mb-2">Peso (kg)</p>
          <el-input-number
            v-model="weight"
            class="inputs-idTienda light-input"
            controls-position="right"
            :min="type == '1' ? 3 : 1"
          />
          <input
            v-model="weight"
            v-validate="'required'"
            type="hidden"
            data-vv-name="weight"
            data-vv-as="peso del paquete"
          />
          <p class="error">
            {{ errors.first("weight") }}
          </p>
        </div>
        <el-row>
          <el-col :span="8">
            <p class="text-info mb-2">Ancho (cm)</p>
            <el-input
              v-model="width"
              v-validate="'required'"
              class="inputs-idTienda light-input"
              placeholder="Centímetros"
              data-vv-name="width"
              data-vv-as="ancho del paquete"
            />
            <p class="error">
              {{ errors.first("width") }}
            </p>
          </el-col>
          <el-col :span="8">
            <p class="text-info mb-2">Alto (cm)</p>
            <el-input
              v-model="height"
              v-validate="'required'"
              class="inputs-idTienda light-input"
              placeholder="Centímetros"
              data-vv-name="height"
              data-vv-as="alto"
            />
            <p class="error">
              {{ errors.first("height") }}
            </p>
          </el-col>
          <el-col :span="8">
            <p class="text-info mb-2">Largo (cm)</p>
            <el-input
              v-model="large"
              v-validate="'required'"
              class="inputs-idTienda light-input"
              placeholder="Centímetros"
              data-vv-name="large"
              data-vv-as="largo"
            />
            <p class="error">
              {{ errors.first("large") }}
            </p>
          </el-col>
        </el-row>
        <div v-if="paymentType == 102" class="input-area">
          <p class="text-info mb-2">Costo de envío</p>
          <el-checkbox v-model="addShippingCost">
            Añadir el valor del envío al valor a recaudar.
          </el-checkbox>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30" type="flex" justify="end">
      <el-button @click.native="goBack">Volver</el-button>
      <el-button
        class="bg-purple-komercia-50 px-4 py-1.5 mr-0 rounded-md text-gray-100"
        @click.native="beforeCreateQuotation"
      >
        Cotizar
      </el-button>
    </el-row>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import setFormatMoney from "../../mixins/formatCurrent";
export default {
  name: "miPaqueteQuotation",
  $_veeValidate: {
    validator: "new", // inject a new validator to be used by the directives.
  },
  mixins: [setFormatMoney],
  data() {
    return {
      dialogVisible: false,
      type: "2",
      declaredValue: 10000,
      addShippingCost: false,
      weight: 1,
      width: null,
      height: null,
      large: null,
      quantity: 1,
      valueCollection: null,
      saleValue: null,
      paymentType: 101,
      delivery: null,
      origin: null,
      destiny: null,
      originOptions: [],
      destinyOptions: [],
    };
  },
  computed: {
    state() {
      return this.$store.state.mipaquete.quotationState;
    },
    towns() {
      return this.$store.state.mipaquete.towns;
    },
    cities() {
      return this.$store.state.cities;
    },
    order() {
      if (this.$store.state.invoice && this.$store.state.invoice.venta) {
        return this.$store.state.invoice;
      } else {
        return "";
      }
    },
    declaredValueWithoutMoney() {
      let value = this.declaredValue;
      // console.log(typeof value)
      return value.toString().replace(/[^0-9,-]+/g, "");
    },
  },
  watch: {
    towns() {
      this.assignOrderData();
    },
    type(value) {
      if (value == 2 && this.weight > 5) {
        this.weight = 5;
      }
      if (value == 2) {
        this.quantity = 1;
      }
    },
    // order() {
    //   this.assignOrderData();
    //   this.setFormatMoney(this.idCountryStore);
    // },
  },
  async created() {
    if (this.$route.query.pre_envio == "true") {
      const { success } = await this.$store.dispatch(
        "GET_INVOICE",
        this.$route.query.order_id
      );
      if (success) {
        this.assignOrderData();
        this.setFormatMoney(this.idCountryStore);
      }
    }
  },
  mounted() {
    this.$store.dispatch("mipaquete/GET_TOWN");
    this.fillState();
  },
  methods: {
    fillState() {
      if (this.state) {
        Object.keys(this.state).forEach((item) => {
          this[item] = this.state[item];
        });
      }
    },
    goBack() {
      this.$store.commit("mipaquete/SET_INDEX_STAGE", 1);
    },
    async assignOrderData() {
      if (this.$route.query.order_id) {
        if (this.order) {
          let product_information = JSON.parse(
            this.order.venta.mi_paquete_pre_envio[0].product_information
          );
          this.declaredValue = null;
          this.quantity = product_information.quantity;
          this.weight = product_information.weight;
          this.width = product_information.width;
          this.height = product_information.height;
          this.large = product_information.large;
          // this.saleValue = this.order.venta.total
          let locate = JSON.parse(
            this.order.venta.mi_paquete_pre_envio[0].locate
          );
          if (locate) {
            this.origin = locate.originDaneCode;
            this.destiny = locate.destinyDaneCode;
            this.towns.filter((item) => {
              if (item.locationCode == this.origin) {
                this.originOptions[0] = item;
              }
              if (item.locationCode == this.destiny) {
                this.destinyOptions[0] = item;
              }
            });
          }
        } else {
          console.log("no tiene pre-envio");
        }
      }
    },
    // setCity(cityId, label, optionsLabel) {
    //   let searchedCity = this.cities.find(city => city.id === cityId)
    //   if (searchedCity) {
    //     let searchedTown = this.towns.find(
    //       town =>
    //         town.locationName
    //           .toLowerCase()
    //           .indexOf(searchedCity.nombre_ciu.toLowerCase()) > -1
    //     )
    //     if (searchedTown) {
    //       this.remoteMethod(searchedCity.nombre_ciu, optionsLabel)
    //       this[label] = searchedTown.locationCode
    //     }
    //   }
    // },
    // setDelivery(courier) {
    //   this.delivery = courier._id
    // },
    remoteMethod(query, optionsLabel) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      if (query !== "") {
        const timeoutDelay = 200;
        setTimeout(() => {
          this[optionsLabel] = this.towns.filter(({ locationName }) => {
            const normalizedLocationName = removeAccents(
              locationName.toLowerCase()
            );
            const normalizedQuery = removeAccents(query.toLowerCase());
            return normalizedLocationName.includes(normalizedQuery);
          });
        }, timeoutDelay);
      } else {
        this[optionsLabel] = [];
      }
    },
    beforeCreateQuotation() {
      this.$validator.validate().then(async (result) => {
        if (result) {
          this.createQuotation();
        }
      });
    },
    async createQuotation() {
      this.dialogVisible = false;
      this.$store.commit("mipaquete/SET_QUOTATION_STATE", this._data);
      let response = await this.$store.dispatch(
        "mipaquete/GET_PACKAGING_OPTIONS",
        {
          type: 1,
          addShippingCost: this.addShippingCost,
          origin: this.origin,
          destiny: this.destiny,
          width: parseInt(this.width),
          height: parseInt(this.height),
          large: parseInt(this.large),
          weight: parseInt(this.weight),
          declaredValue:
            this.order && this.order.venta.total
              ? this.order.venta.total
              : this.declaredValueWithoutMoney,

          saleValue: this.saleValue ? parseInt(this.saleValue) : null,
          valueCollection: this.valueCollection ? this.valueCollection : null,
          quantity: parseInt(this.quantity),
          delivery: this.delivery,
          tracker: uuid.v1(),
          paymentType: this.paymentType,
        }
      );
      if (response.success) {
        this.$store.commit("mipaquete/NEXT_STAGE");
      } else {
        this.$notify({
          type: "error",
          message: response?.data.message,
        });
      }
    },
    handleHelp(title, message, isHtml) {
      this.$alert(message, title, {
        confirmButtonText: "Entendido",
        dangerouslyUseHTMLString: isHtml,
      });
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 10px;
}
.title-input {
  margin-bottom: 7px;
}
.title-input > span {
  font-weight: 600;
}
.el-input-number {
  width: 100% !important;
}
.el-select {
  display: block !important;
}
.el-radio-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.quotation {
  padding: 20px 35px;
}
.quotation__couriers {
  display: grid;
  grid-gap: 5px;
  list-style: none;
}
.quotation__couriers__item {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 20px;
  border: 1px solid #dcdfe6;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.quotation__couriers__item img {
  width: 100%;
  height: 38px;
  object-fit: contain;
}
.quotation__couriers__item:hover {
  border: 1px solid var(--purple);
}
.quotation__couriers__item.selected {
  border: 2px solid var(--purple);
}
.opacity-0 {
  opacity: 0;
}
.error {
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #f14b5a;
}
.el-input__icon {
  line-height: 0 !important;
}
.msg__confirmation {
  width: 100%;
  display: flex;
}
.msg__confirmation p {
  word-break: break-word;
}
.msg__confirmation img {
  width: 50%;
}
</style>

<style scoped>
.quotation_form {
  border: 1px dashed #d8dde3;
  border-radius: 3px;
  padding: 15px;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.input-area {
  width: 100%;
}
.input-idTienda {
  padding: 12px 11px;
  border-radius: 10px;
  background-color: #f2efff;
  font-size: 13px;
  max-height: 40px;
}
.money-idTienda >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-idTienda-valor >>> .el-input__inner {
  padding: 0px 25px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-dominio >>> .el-input__inner {
  font-size: 13px;
  border: none;
  background-color: transparent;
  transition: none;
  padding: 0px;
  height: 20px;
  max-height: 20px;
  @apply ml-2;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
</style>
