<template>
  <div
    class="w-full lg:max-w-screen-lg xl:max-w-screen-xl rounded-xl relative overflow-hidden"
    style="padding: 30px 20px"
  >
    <div
      class="w-full mx-auto content_listcourse"
      :class="darkMode ? 'dark' : 'light'"
    >
      <div class="h-full bg-white flex">
        <div
          class="h-full flex-1 flex flex-col justify-center pt-5 pb-5 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
        >
          <div
            class="mx-auto h-full w-full flex flex-col justify-between max-w-sm lg:w-96 content_listcourse"
          >
            <div>
              <div class="flex justify-center">
                <a
                  title="Abrir Cubiko"
                  href="https://www.cubiko.co/"
                  rel="noreferrer noopener"
                  target="_blank "
                >
                  <img
                    loading="lazy"
                    class="h-8 w-auto"
                    src="../../../assets/payments/cubiko.png"
                    alt="Workflow"
                  />
                </a>
              </div>
              <div class="mt-12">
                <div class="flex flex-col justify-start">
                  <p class="text_title">
                    {{ $t("text_cubiko_text1") }}
                    <strong>{{ $t("text_cubiko_text2") }}</strong>
                    {{ $t("text_cubiko_text3") }}
                    <strong>
                      {{ $t("text_cubiko_text4") }}
                    </strong>
                    {{ $t("text_cubiko_text5") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <div class="space-y-6">
                <div>
                  <div v-if="dataCubiko && dataCubiko.estado == 1">
                    <button
                      class="w-full mb-3 flex justify-center py-2 px-4 border bg-indigo-600 border-indigo-600 rounded-md shadow-sm text-sm font-medium text-white"
                      @click="abrirDashboard"
                    >
                      {{ $t("text_cubiko_abrirCubiko") }}
                    </button>
                    <button
                      class="w-full mb-5 flex justify-center py-2 px-4 border border-indigo-600 rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-transparent"
                      @click="disableIntegration"
                    >
                      {{ $t("text_cubiko_desconectarIntegracion") }}
                    </button>
                  </div>
                  <button
                    v-else
                    class="w-full mb-3 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="loginCubiko"
                  >
                    {{ $t("text_nosesion_iniciar") }}
                  </button>
                  <span
                    class="w-full flex justify-center items-center underline"
                    @click="handleSidebarHelp('74')"
                  >
                    {{ $t("text_helpDesk_Aprende") }}
                    <i
                      slot="suffix"
                      class="el-icon-question el-input__icon"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="hidden lg:block relative w-0 flex-1"
          style="background: #e6007e"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CubikoIndex",
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    dataCubiko() {
      return this.$store.state.dataCubiko;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  created() {
    this.$store.dispatch("GETCUBIKO");
  },
  methods: {
    handleSidebarHelp(contentId) {
      this.$store.state.miscSidebar.isActive = true;
      this.$store.state.miscSidebar.isHelpdesk = false;
      this.$store.state.miscSidebar.data = contentId;
    },
    loginCubiko() {
      var w = 1024;
      var h = 650;
      var dualScreenLeft =
        window.screenLeft != undefined ? window.screenLeft : window.screenX;
      var dualScreenTop =
        window.screenTop != undefined ? window.screenTop : window.screenY;
      var width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      var height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;
      var left = width / 2 - w / 2 + dualScreenLeft;
      var top = height / 2 - h / 2 + dualScreenTop;
      const url = `https://platform.cubiko.co/user/login?next=/user/access?associate%3Dkomercia%26komercia_id%3D${this.storeData.id}%26redirect%3Dhttps%253A%252F%252Fplatform.cubiko.co%252Fdashboard%252F%26state%3D`;
      const configuracion_ventana =
        "menubar=no,toolbar=no,location=no,copyhistory=no,resizable=yes,scrollbars=yes,status=no,directories=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left;
      let windowObjectReference = null;
      // let myParams = {
      //   associate: 'komercia',
      //   komercia_id: this.storeData.id,
      //   redirect: 'https://platform.cubiko.co/dashboard/',
      //   state: '',
      // }
      // let params = new URLSearchParams(myParams).toString()
      // params = encodeURIComponent(params)
      if (windowObjectReference == null || windowObjectReference.closed) {
        windowObjectReference = window.open(
          url,
          "Cubiko",
          configuracion_ventana
        );
        this.$store.dispatch("SAVECUBIKO", true);
        this.$store.dispatch("GETCUBIKO");
      } else {
        windowObjectReference.focus();
      }
    },
    abrirDashboard() {
      var w = 1024;
      var h = 650;
      var dualScreenLeft =
        window.screenLeft != undefined ? window.screenLeft : window.screenX;
      var dualScreenTop =
        window.screenTop != undefined ? window.screenTop : window.screenY;
      var width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      var height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;
      var left = width / 2 - w / 2 + dualScreenLeft;
      var top = height / 2 - h / 2 + dualScreenTop;
      const url = "https://platform.cubiko.co/dashboard?";
      const configuracion_ventana =
        "menubar=no,toolbar=no,location=no,copyhistory=no,resizable=yes,scrollbars=yes,status=no,directories=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left;
      let windowObjectReference = null;
      let myParams = {
        only_view: "true",
      };
      let params = new URLSearchParams(myParams).toString();
      if (windowObjectReference == null || windowObjectReference.closed) {
        // staging1
        windowObjectReference = window.open(
          url + params,
          "Cubiko Products",
          configuracion_ventana
        );
      } else {
        windowObjectReference.focus();
      }
    },
    getUrlParams(url) {
      return `${url}?`
        .split("?")[1]
        .split("&")
        .reduce(
          (params, pair) =>
            ((key, val) => (key ? { ...params, [key]: val } : params))(
              ...`${pair}=`.split("=").map(decodeURIComponent)
            ),
          {}
        );
    },
    disableIntegration() {
      this.$store.dispatch("SAVECUBIKO", false);
      this.$store.dispatch("GETCUBIKO");
    },
  },
  // watch:{
  //   dataCubiko(){

  //   }
  // }
};
</script>
<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}

.content_listcourse {
  min-height: 600px;
  overflow: hidden;
}
.text_title {
  color: #0c0c0c;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
</style>
