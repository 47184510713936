import Vue from "vue";

/* 
  Directiva para que los inputs solo reciban números
  utilizar el modificador native (v-number.native) para componentes inputs de librerías como element.iu
*/
Vue.directive("number", {
  bind: function (el, binding) {
    const p = /^-?\d*$/;
    [
      "input",
      "keydown",
      "keyup",
      "mousedown",
      "mouseup",
      "select",
      "contextmenu",
      "drop",
    ].forEach((event) => {
      el.addEventListener(event, function () {
        if (binding.modifiers.native) {
          if (p.test(el.firstElementChild.value)) {
            el.firstElementChild.oldValue = el.firstElementChild.value;
            el.firstElementChild.oldSelectionStart =
              el.firstElementChild.selectionStart;
            el.firstElementChild.oldSelectionEnd =
              el.firstElementChild.selectionEnd;
          } else if (
            Object.prototype.hasOwnProperty.call(
              el.firstElementChild,
              "oldValue"
            )
          ) {
            el.firstElementChild.value = el.firstElementChild.oldValue;
            el.firstElementChild.setSelectionRange(
              el.firstElementChild.oldSelectionStart,
              el.firstElementChild.oldSelectionEnd
            );
          } else {
            el.firstElementChild.value = "";
          }
        } else {
          if (p.test(el.value)) {
            el.oldValue = el.value;
            el.oldSelectionStart = el.selectionStart;
            el.oldSelectionEnd = el.selectionEnd;
          } else if (Object.prototype.hasOwnProperty.call(el, "oldValue")) {
            el.value = el.oldValue;
            el.setSelectionRange(el.oldSelectionStart, el.oldSelectionEnd);
          } else {
            el.value = "";
          }
        }
      });
    });
  },
});
