<template>
  <el-dialog
    title="Nuevo Cliente"
    :visible.sync="showModal"
    :modalAppendToBody="false"
    @close="clear"
  >
    <div class="wrapper-user">
      <New-user class="new-user" :width="400" />
    </div>
    <div class="wrapper-form">
      <div class="left-form">
        <p class="text-form">Nombre</p>
        <el-input
          v-model="form.name"
          v-validate="'required'"
          placeholder="Escribe el nombre"
          data-vv-name="senderName"
          data-vv-as="Nombre"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        ></el-input>
        <p class="error">{{ errors.first("senderName") }}</p>
      </div>
      <div class="right-form">
        <p class="text-form">Correo</p>
        <el-input
          v-model="form.email"
          v-validate="'email'"
          placeholder="Escribe el correo"
          data-vv-name="senderEmail"
          data-vv-as=" "
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        ></el-input>
        <p class="error">{{ errors.first("senderEmail") }}</p>
      </div>
      <div class="right-form">
        <p class="text-form">
          Tipo de
          <br />Identificación
        </p>
        <el-select
          v-model="form.typeId"
          placeholder="Tipo de identificación"
          filterable
          clearable
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        >
          <el-option
            v-for="item in typesID[0].option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="right-form">
        <p class="text-form">
          Número de
          <br />identificación
        </p>
        <el-input
          v-model="form.numId"
          placeholder="Escribe el número de identificación"
          :v-validate="
            storeData?.informacion_tienda[0]?.paises_id == 7 ? 'numeric' : ''
          "
          :type="
            storeData?.informacion_tienda[0]?.paises_id == 7 ? 'text' : 'number'
          "
          data-vv-name="senderNumId"
          data-vv-as=" "
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        ></el-input>
        <p class="error">{{ errors.first("senderNumId") }}</p>
      </div>
      <div class="right-form">
        <p class="text-form">Celular</p>
        <div class="content_whatsapp_inputs">
          <el-select
            v-model="codeCountryPhone"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
            filterable
            clearable
            placeholder="País"
            style="max-width: 100px"
          >
            <el-option
              v-for="(item, index) in optionsPhone"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="form.mobile"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
            placeholder="Escribe el número celular"
          >
          </el-input>
        </div>
        <!-- <el-input
          placeholder="Escribe el celular"
          v-model="form.mobile"
          v-validate="'numeric'"
          data-vv-name="senderNum"
          data-vv-as=" "
          class="inputs-idTienda"
          :class="darkMode  ? 'darks-input' : 'light-input'"
        >
          <template class="indicative" slot="prepend">57 +</template>
        </el-input> -->
        <p class="error">{{ errors.first("senderNum") }}</p>
      </div>
      <div class="right-form">
        <p class="text-form">Ciudad</p>
        <el-select
          v-model="form.city"
          placeholder="Elige una ciudad"
          clearable
          filterable
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        >
          <el-option
            v-for="item in cities"
            :key="item.id"
            :label="item.nombre_ciu"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showModal = false">Cancelar</el-button>
      <el-button type="primary" @click="createCustomer">Crear</el-button>
    </span>
  </el-dialog>
</template>

<script>
import NewUser from "@/assets/graphics/NewUser";
export default {
  $_veeValidate: {
    validator: "new", // inject a new validator to be used by the directives.
  },
  components: {
    NewUser,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      formLabelWidth: "200px",
      form: {
        name: "",
        email: "",
        city: "",
        mobile: "",
        numId: "",
        typeId: "",
      },
      codeCountryPhone: null,
      wpVisible: false,
      optionsPhone: [
        {
          value: "+54",
          label: "+54   Argentina",
        },
        {
          value: "+55",
          label: "+55   Brasil",
        },
        {
          value: "+56",
          label: "+56 Chile",
        },
        {
          value: "+57",
          label: "+57   Colombia",
        },
        {
          value: "+593",
          label: "+593  Ecuador",
        },
        {
          value: "+34",
          label: "+34 España",
        },
        {
          value: "+1",
          label: "+1 Estados Unidos",
        },
        {
          value: "+52",
          label: "+52 México",
        },
        {
          value: "+51",
          label: "+51 Perú",
        },
        {
          value: "+1",
          label: "+1 Puerto Rico",
        },
        {
          value: "+1",
          label: "+1 República Dominicana",
        },
        {
          value: "+507",
          label: "+507 Panamá",
        },
      ],
    };
  },
  computed: {
    typesID() {
      return this.$store.state.identificationTypes.filter(
        (item) =>
          item.idCountry === this.storeData.informacion_tienda[0].paises_id
      );
    },
    cities() {
      return this.$store.state.cities.filter(
        (cities) =>
          cities.departamento.paises_id ===
          this.storeData.informacion_tienda[0].paises_id
      );
    },
    showModal: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
    storeData() {
      return this.$store.state.storeData;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  methods: {
    async createCustomer() {
      if (
        this.form.numId !== "0123456789" &&
        this.form.email !== "ocasional@komercia.co"
      ) {
        let whatsapp;
        if (this.codeCountryPhone == null) {
          whatsapp = this.form.mobile;
        } else {
          whatsapp = this.codeCountryPhone + this.form.mobile;
        }
        if (this.codeCountryPhone != null) {
          const { estado, data, mensaje } = await this.$store.dispatch(
            "CREATE_CUSTOMER",
            {
              nombre: this.form.name,
              email: this.form.email,
              tipo_identificacion: this.form.typeId,
              identificacion: this.form.numId,
              ciudad: this.form.city,
              telefono: whatsapp,
            }
          );
          if (estado !== 200) {
            let errors = data.errors
              ? Object.values(data.errors)
              : Object.values(data.errores);

            this.open4(errors[0][0]);
          } else {
            this.$emit("onCreate", data);
            this.open1(mensaje);
            this.showModal = false;
          }
        } else {
          this.$swal("Falta indicativo del número telefónico!", "", "error");
        }
      } else {
        this.open4(
          "No puedes crear el cliente, con este correo o número de identificación"
        );
      }
    },
    open1(e) {
      this.$message({
        message: e,
        type: "success",
      });
    },
    open4(e) {
      this.$message.error(e);
    },
    clear() {
      this.form = {};
    },
  },
};
</script>
<style scoped>
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.content_whatsapp_inputs {
  width: 100%;
  display: flex;
}
</style>
<style lang="scss" scoped>
.row {
  width: 100%;
}
.wrapper-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 30px;

  div {
    display: flex;
    align-items: center;
    position: relative;

    .text-form {
      text-align: right;
      padding-right: 20px;
      width: 120px;
      font-size: 12px;
      font-weight: 500;
      color: #919191;
    }
    .el-input {
      flex: 1;
    }
  }
}
.wrapper-user {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 30px 30px;
  margin-top: -20px;
}
.dialog-footer {
  padding: 0 30px;
}
.el-select {
  flex: 1;
}
p.error {
  position: absolute;
  top: 44px;
  font-size: 12px;
  right: 0;
  line-height: 1;
  color: rgb(236, 70, 70);
}
.indicative {
  height: 100%;
}
</style>
