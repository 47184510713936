<template>
  <div
    id="product-card"
    class="relative h-full"
    :class="{ 'product-item': true, box: true, sold_out: soldOut }"
  >
    <router-link
      :class="darkMode ? 'darkbgs' : 'lightbgs'"
      :to="`/inventario/productos/${data.id}?p=2`"
    >
      <img
        loading="lazy"
        v-if="soldOut"
        class="img-product product-image-soldOut object-contain"
        :src="
          data.foto_cloudinary == 'sin_foto.jpeg'
            ? defaultPhoto
            : idCloudinaryAwards(data.foto_cloudinary, 850, 850)
        "
      />
      <p v-if="soldOut" class="overlay-soldOut">
        {{ $t("text_ventas_agotado") }}
      </p>
      <img
        loading="lazy"
        v-if="!soldOut"
        class="img-product object-contain"
        :src="
          data.foto_cloudinary == 'sin_foto.jpeg'
            ? defaultPhoto
            : idCloudinaryAwards(data.foto_cloudinary, 850, 850)
        "
      />
      <div v-if="disableVariant" class="overlay-top">
        <p>{{ $t("text_ventas_variantes_desactivas") }}</p>
      </div>
      <div
        v-if="data.tag_promocion == 1 && data.promocion_valor"
        class="overlay-button bg-red-500 text-white"
        style="right: 10px; top: 20px; width: 68px"
      >
        <p>{{ data.promocion_valor }}% OFF</p>
      </div>
      <div
        v-if="data && data.envio_gratis === 1"
        class="overlay-button bg-green-500 text-white"
        style="left: 10px; bottom: 163px; width: 120px"
      >
        <p>🚚 Envío gratis</p>
      </div>
      <div
        v-if="data && data.favorito === 1"
        class="overlay-button bg-yellow-400 text-black"
        style="left: 10px; bottom: 136px; width: 150px"
      >
        <p>⭐ Producto favorito</p>
      </div>
      <div
        v-if="
          data.drop_shipping &&
          data.drop_shipping.length > 0 &&
          data.drop_shipping[0].estado == 1
        "
        class="overlay-button bg-black text-white"
        style="left: 10px; bottom: 110px; width: 170px"
      >
        <p>📦 Dropshipping</p>
      </div>
      <div class="row">
        <h4
          :class="darkMode ? 'darktxt' : 'lighttxt'"
          class="product-item_title"
        >
          {{ data.nombre }}
        </h4>
        <div class="col-xs-6">
          <span v-if="data.con_variante" class="h5 product-item-price">
            {{ $t("text_ventas_producto_variantes") }}
          </span>
          <div v-else>
            <p class="h5 product-item-price">
              {{ data.precio | formatCurrency(idCountryStore) }}
              <span
                v-if="data.tag_promocion == 1 && data.promocion_valor"
                class="text-gray-400 line-through text-xs"
              >
                {{
                  (data.tag_promocion == 1 && data.promocion_valor
                    ? Math.trunc(data.precio / (1 - data.promocion_valor / 100))
                    : 0) | formatCurrency(idCountryStore)
                }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </router-link>
    <div :class="{ 'product-item-actions': true, two_columns: !inactive }">
      <div
        v-if="inactive"
        :class="darkMode ? 'darkbgs' : 'lightbgs'"
        class="action delete"
        :title="$t('text_inventario_elimnar')"
        @click="hiddenProduct"
      >
        <i class="ti-trash"></i>
      </div>
      <div
        v-if="inactive"
        :class="darkMode ? 'darkbgs' : 'lightbgs'"
        class="icon-resposive"
      >
        <div
          class="action delete-responsive"
          :title="$t('text_inventario_elimnar')"
          @click="hiddenProduct"
        >
          <img
            loading="lazy"
            class="trash"
            src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593700633/delete_wnkotr.webp"
            alt="Icono de eliminar"
          />
        </div>
      </div>
      <div
        v-else
        :class="darkMode ? 'darkbgs' : 'lightbgs'"
        class="action"
        :title="$t('text_inventario_restablecar')"
        @click="restoreProduct"
      >
        <i class="ti-cloud-up"></i>
      </div>
      <router-link
        :to="`/inventario/productos/${data.id}?p=2`"
        class="action editar"
        :class="darkMode ? 'darkbgs' : 'lightbgs'"
        :title="$t('text_inventario_editar')"
      >
        <i class="ti-pencil"></i>
      </router-link>
      <div :class="darkMode ? 'darkbgs' : 'lightbgs'" class="icon-resposive">
        <router-link
          :class="darkMode ? 'darkbgs' : 'lightbgs'"
          :to="`/inventario/productos/${data.id}?p=2`"
          class="action editar-responsive"
          :title="$t('text_inventario_editar')"
        >
          <!-- <i class="ti-pencil"></i> -->
          <img
            loading="lazy"
            class="pencil"
            src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/editar_xizoim.webp"
            alt="Icono de editar"
          />
        </router-link>
      </div>
      <a
        v-if="inactive"
        :class="darkMode ? 'darkbgs' : 'lightbgs'"
        :href="getUrlProduct()"
        :title="$t('text_inventario_previsualizar')"
        target="_blank"
        class="action preview"
      >
        <i class="ti-eye"></i>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import idCloudinary from "../mixins/idCloudinary";
import formatCurrency from "../mixins/formatCurrent";
// import { CldImage, CldTransformation } from 'cloudinary-vue'
export default {
  name: "ProductCard",
  mixins: [idCloudinary, formatCurrency],
  // components: { CldImage, CldTransformationImg },
  props: {
    data: {
      type: Object,
      required: true,
    },
    inactive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      styles: {
        fontFamily: "Helvetica",
        fontSize: 50,
        fontWeight: "bold",
        text: "Agotado",
        background: "#000000",
      },
      defaultPhoto:
        "https://res.cloudinary.com/komercia-store/image/upload/q_auto,f_auto/v1550852556/placeholder/product-image-placeholder.webp",
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    subdomain() {
      return this.$store.state.storeData.subdominio;
    },
    storeData() {
      return this.$store.state.storeData;
    },
    soldOut() {
      const hasCombinaciones =
        this.data.variantes[0] &&
        this.data.variantes[0].combinaciones[0].combinaciones !== "[]" &&
        this.data.variantes[0].combinaciones[0].combinaciones !== "Array";
      if (hasCombinaciones) {
        const variantes = JSON.parse(
          this.data.variantes[0].combinaciones[0].combinaciones
        );
        for (const variante of variantes) {
          if (variante.unidades == "0" && variante.estado) {
            return true;
          }
        }
        return false;
      } else if (this.data.inventario === 0 && this.data.con_variante === 0) {
        return true;
      } else {
        return false;
      }
    },
    disableVariant() {
      const hasCombinaciones =
        this.data.variantes[0] &&
        this.data.variantes[0].combinaciones[0].combinaciones !== "[]" &&
        this.data.variantes[0].combinaciones[0].combinaciones !== "Array";

      if (hasCombinaciones) {
        const variantes = JSON.parse(
          this.data.variantes[0].combinaciones[0].combinaciones
        );
        for (const variante of variantes) {
          if (!variante.estado) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    hiddenProduct() {
      this.$swal({
        title: "Enviar a la papelera",
        html: "<p>¿Quieres enviar este producto a la papelera?</p><p>No se mostrará en tu tienda online</p>",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        icon: "question",
      }).then(async (r) => {
        if (r.isConfirmed) {
          let params = {
            id: this.data.id,
            estado: 0,
          };
          axios
            .post(
              `${this.$configKomercia.url}/api/admin/productos/cambiar-estado`,
              params,
              this.$store.state.configAxios
            )
            .then((response) => {
              this.$swal("Enviado a la papelera!", "", "success");
              const index = this.$store.state.productsData.findIndex(
                (product) => product.id === this.data.id
              );
              this.$store.state.productsData.splice(
                index,
                1,
                response.data.data
              );
              this.$store.dispatch("GET_PRODUCTS", {
                page: 1,
                status: 1,
                per_page: 24,
              });
            })
            .catch(() => {
              this.$swal(
                "Error!",
                "Tu producto no se pudo enviar a la papelera.",
                "error"
              );
            });
        }
      });
    },
    restoreProduct() {
      let params = {
        id: this.data.id,
        estado: 1,
      };
      axios
        .post(
          `${this.$configKomercia.url}/api/admin/producto/cambiar-estado`,
          params,
          this.$store.state.configAxios
        )
        .then((response) => {
          const index = this.$store.state.productsData.findIndex(
            (product) => product.id === this.data.id
          );
          this.$store.state.productsData.splice(index, 1, response.data.data);
        });
    },
    getUrlProduct() {
      const suffix = this.storeData.template !== 3 ? "store" : "co";
      if (
        this.storeData.entidades &&
        this.storeData.entidades.length > 0 &&
        this.storeData.entidades[0].id == 21
      ) {
        if (this.storeData.template == 99) {
          return `https://${this.subdomain}.keepbuy.co/wa/${this.storeData.id}/productos/${this.data.slug}`;
        } else {
          return `https://${this.subdomain}.keepbuy.co/productos/${this.data.slug}`;
        }
      } else {
        if (this.storeData.template == 99) {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}/wa/${this.storeData.id}/productos/${this.data.slug}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}/wa/${this.storeData.id}/productos/${this.data.slug}`;
          }
        } else if (this.storeData.template == 12) {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}`;
          }
        } else {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}/productos/${this.data.slug}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}/productos/${this.data.slug}`;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.darkbgs {
  background-color: #283c51;
}
.lightbgs {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #474d68;
}
h4,
span {
  font-family: "Poppins", sans-serif;
}
#product-card:hover .overlay-top {
  width: 200px;
  left: 32%;
}
.overlay-top {
  top: 15px;
  left: 100%;
  right: 0;
  width: 0;
  height: 29px;
  padding: 5px;
  font-size: 15px;
  background: rgb(255, 255, 255);
  color: var(--purple);
  @apply absolute overflow-hidden rounded-md shadow-md transition-all ease-in duration-300;
}
.overlay-button {
  height: 23px;
  padding: 5px;
  font-size: 13px;
  @apply flex items-center absolute overflow-hidden rounded-md shadow-md transition-all ease-in duration-300 text-center;
}
.product-item {
  width: 100%;
  display: grid;
  align-content: space-between;
  overflow: hidden;
  transition: 0.3s;
  margin-bottom: 0;
}
.product-item.box {
  grid-template-rows: 1fr 40px;
}
.product-item:hover {
  /* transform: scale(0.95); */
  box-shadow: 0 0 6px 6px rgba(71, 77, 104, 0.11);
}

.product-item a {
  width: 100%;
  cursor: pointer;
  color: var(--purple);
}
.product-item a .row {
  padding: 5px 0 10px;
  margin: 0 10px;
}
/* .product-item_title {
  color: #474d68;
} */
.product-item-actions {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid #e8ebf0;
}
.product-item-actions.two_columns {
  grid-template-columns: repeat(2, 1fr);
}
.product-item-actions .action {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: #d7d7d7;
}
.product-item-actions .action ~ .action {
  border-left: 1px solid #e8ebf0;
}
.product-item:hover .product-item-actions .action {
  color: #474d68;
}
.product-item-actions .action:hover {
  background-color: #e8ebf0;
}
.sold_out .product-item-thumbnail {
  background-color: #f2f2f2;
}
.sold_out .product-item-thumbnail img {
  filter: grayscale(1) contrast(0.9);
}
.sold_out .product-item-thumbnail::after {
  position: absolute;
  top: 20px;
  right: 0;
  content: "producto Agotado";
  color: #fff;
  font-size: 13px;
  width: 70px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px 0 0 3px;
  background-color: #f14b5a;
  z-index: 9;
}
.icon-resposive {
  display: none;
}
.product-image-soldOut {
  filter: grayscale(100%);
}
.img-product {
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  transform: scale(1);
  max-height: 334px;
  min-height: 334px;
}
.img-product:hover >>> img {
  transition: all ease-in-out 0.3s;
  transform: scale(1.2);
}
.overlay-soldOut {
  transform: rotate(-45deg);
  top: 30px;
  left: -50px;
  width: 200px;
  padding: 3px;
  font-size: 17px;
  @apply absolute overflow-hidden rounded-md shadow-md text-center text-white bg-red-600;
}
@media (max-width: 1440px) {
  .img-product {
    max-height: 238px;
    min-height: 238px;
  }
}
@media (max-width: 1366px) {
  .img-product {
    max-height: 286.25px;
    min-height: 286.25px;
  }
}
@media (max-width: 1280px) {
  .img-product {
    max-height: 351.33px;
    min-height: 351.33px;
  }
}
@media (max-width: 1040px) {
  .img-product {
    max-height: 271.33px;
    min-height: 271.33px;
  }
}
@media (max-width: 767px) {
  .icon-resposive {
    display: flex;
    align-items: center;
    /* width: 45px; */
  }
  .icon-resposive:nth-child(2) {
    margin-left: 15px;
  }
  .product-item-actions a.editar {
    display: none;
  }
  .product-item-actions .delete {
    display: none;
  }
  .product-item {
    display: flex;
    margin-bottom: 20px;
  }
  .product-item a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #706f6fd9;
  }
  .product-item a:hover {
    background: rgba(218, 218, 218, 0.36);
  }
  .product-item_title {
    color: black;
  }
  .product-item-actions {
    border-top: unset;
    display: flex;
    justify-content: flex-start;
    width: 50%;
    flex-direction: row-reverse;
  }
  .product-item-actions .preview {
    display: none;
  }
  .trash {
    background: transparent;
    color: rgb(255, 168, 1);
  }
  .pencil {
    background: transparent;
    color: rgb(48, 196, 144);
  }
  .product-item-actions .action {
    width: 100%;
    border-radius: 25px;
  }
  .product-item-actions .delete-responsive {
    padding: 13px;
    background: rgba(255, 168, 1, 0.21);
    border-radius: 25px;
    color: rgb(255, 168, 1);
    height: 44px;
    width: 44px;
  }
  .product-item-actions .editar-responsive {
    padding: 15.5px;
    background: rgba(48, 196, 144, 0.21);
    border-radius: 25px;
    color: rgb(48, 196, 144);
    height: 44px;
    width: 44px;
  }
  .product-item-actions .action ~ .action {
    border-left: unset;
  }
  .product-item-actions .editar-responsive:hover {
    background-color: rgba(48, 196, 144, 0.21);
  }
  .product-item-actions .delete-responsive:hover {
    background-color: rgba(255, 168, 1, 0.21);
  }
}
@media (max-width: 325px) {
  .product-item a {
    width: 50%;
  }
}
</style>
