<template>
  <div>
    <div
      v-if="paymentMethods"
      class="w-full flex flex-col justify-center items-center border-indigo-700 rounded-md my-4 p-6"
      :class="paymentMethods.tienda === 1 ? 'border-2' : 'border'"
    >
      <div class="w-full flex flex-row justify-between items-start">
        <p
          class="font-extrabold"
          :class="darkMode ? 'darktxtswiper' : 'lighttxtswiper'"
        >
          {{ $t("text_envios_pagarOnline") }}
          <span class="text-option">({{ $t("text_optional") }})</span>
        </p>
        <el-switch
          v-model="paymentMethods.tienda"
          :active-value="1"
          :inactive-value="0"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="
            updateStateMethods(
              'PICKUP_AND_PAY_IN_STORE',
              paymentMethods.tienda === 1 ? 'ACTIVATE' : 'DEACTIVATE'
            )
          "
        ></el-switch>
      </div>
      <p
        class="w-full flex flex-col justify-center items-start mt-3"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        {{ $t("text_envios_pagarOnlineInfo1") }}
        <br />
        {{ $t("text_envios_pagarOnlineInfo2") }}
      </p>
    </div>
    <div
      v-else
      class="w-full min-h-[130px] flex flex-col justify-center items-center border border-indigo-700 rounded-md my-4 p-6"
    >
      <div class="w-full flex flex-row justify-between items-start">
        <div class="h-[21px] w-[280px] bg-gray-200 animate-pulse"></div>
        <div class="h-[21px] w-[40px] bg-gray-200 animate-pulse"></div>
      </div>
      <div class="w-full flex flex-col justify-center items-start mt-3">
        <div class="w-full h-[42px] bg-gray-200 animate-pulse"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    darkMode: {
      type: Boolean,
      required: true,
    },
    storeData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["paymentMethods"]),
  },
  methods: {
    async updateStateMethods(payment, state) {
      const { success } = await this.$store.dispatch(
        "ACTIVATE_OR_DISABLE_PAYMENT",
        {
          state,
          payment,
          storeId: this.storeData.id,
        }
      );
      if (success) {
        await this.$store.dispatch("GET_STORE_PAYMENTS", {
          storeId: this.storeData.id,
        });
        this.$message.success(
          `Recoger y pagar en la tienda ${
            state === "ACTIVATE" ? "activado" : "desactivado"
          }!`
        );
      } else {
        this.$message.error("Error al cambiar de estado, fallo de API");
      }
    },
  },
};
</script>
<style scoped>
.text-option {
  font-size: 12px;
  color: rgb(68, 68, 68);
}
.darktxt {
  color: #fff;
}
.lighttxt {
  @apply text-gray-800;
}
.darktxtswiper {
  color: #33c9ad;
}
.lighttxtswiper {
  color: #4429b4;
}
.darktxtswiper {
  color: #33c9ad;
}
.lighttxtswiper {
  color: #4429b4;
}
</style>
