<template>
  <el-dialog
    title="Selecciona el área de cobertura"
    width="80%"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    :modalAppendToBody="false"
    @close="close()"
  >
    <div class="zonas">
      <div class="container-map">
        <gmap-map
          ref="map"
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 50vh"
        >
          <gmap-polygon
            v-for="(zona, index) in copyZonas"
            :key="`zona_${index}`"
            :paths="zona.paths"
            :editable="true"
            draggable
            @mousedown="getIdZona(index)"
            ref="polygon"
            :options="
              selectedPolygon == index ? optionsPolygonSelected : optionsPolygon
            "
            @paths_changed="updateEdited($event, index)"
            @rightclick="handleClickForDelete($event, index)"
          >
          </gmap-polygon>
        </gmap-map>
      </div>
      <div class="info-zonas">
        <h2 class="title">Zonas de cobertura</h2>
        <p class="description">
          Todos los pedidos realizados por tus clientes solo podrán ser
          entregados en la zonas delimitadas, de lo contrario el cliente
          recibirá un mensaje por encontrarse fuera de cobertura
        </p>
        <div class="container">
          <div
            v-for="(zona, index) in copyZonas"
            :key="`form_${index}`"
            class="wrapper-zonas"
            :class="selectedPolygon == index ? 'border-left' : ''"
            @click="getIdZona(index)"
          >
            <div class="name">
              <p>Nombre</p>
              <el-input v-model="zona.name" placeholder="nombre"></el-input>
            </div>
            <div class="price">
              <p>Precio</p>
              <el-input v-model="zona.price" placeholder="0"></el-input>
            </div>
            <div class="delivery-time">
              <p>Tiempo de entrega</p>
              <el-input
                v-model="zona.delivery_time"
                placeholder="2-5 horas"
              ></el-input>
            </div>
            <el-button
              type="danger"
              style="height: 40px"
              icon="el-icon-delete"
              @click="removeZona(zona)"
            ></el-button>
          </div>
        </div>
        <el-button
          type="primary"
          style="width: 100%; margin-top: 10px"
          plain
          @click="agregarZona()"
        >
          Agregar nueva zona
        </el-button>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="getCurrentCity">Prueba</el-button> -->
      <el-button @click="showDialog = false">Cancelar</el-button>
      <el-button type="primary" @click="savePaths">Guardar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "shippingModalMap",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    city: {
      type: Number,
    },
    zonas: {
      type: Array,
    },
  },
  data() {
    return {
      listRemoveZonas: [],
      optionsPolygon: {
        fillColor: "DARKSLATEBLUE",
        fillOpacity: 0.2,
        strokeWeight: 1,
        strokeColor: "DARKSLATEBLUE",
      },
      optionsPolygonSelected: {
        fillColor: "DARKVIOLET",
        fillOpacity: 0.2,
        strokeWeight: 1,
        strokeColor: "DARKVIOLET",
      },
      selectedPolygon: -1,
      zoom: 14,
      map: null,
      center: { lat: 0, lng: 0 },
      paths: [
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
      ],
      refZona: {
        name: "Zona 1",
        price: 5000,
        ciudad: this.city,
        delivery_time: "2 días",
        paths: [
          { lat: 0, lng: 0 },
          { lat: 0, lng: 0 },
          { lat: 0, lng: 0 },
          { lat: 0, lng: 0 },
        ],
      },
      copyZonas: [],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      },
    },
  },
  methods: {
    savePaths() {
      let ids = [];
      this.zonas.forEach((item) => ids.push(item.id));

      axios
        .post(
          `${this.$store.state.urlKomercia}/api/admin/zonas/eliminar`,
          { zonas: ids },
          this.$store.state.configAxios
        )
        .then(() => {
          axios
            .post(
              `${this.$store.state.urlKomercia}/api/admin/zonas`,
              { zonas: this.copyZonas },
              this.$store.state.configAxios
            )
            .then(() => (this.showDialog = false))
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    },
    async getCurrentCity() {
      let city = await this.$store.state.cities.find(
        (city) => city.id == this.city
      );
      let map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: 12,
      });
      var request = {
        query: city.nombre_ciu,
        fields: ["name", "geometry"],
      };
      this.map = new google.maps.places.PlacesService(map);
      this.map.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.center.lat = results[0].geometry.location.lat();
          this.center.lng = results[0].geometry.location.lng();
          this.setPaths(this.center.lat, this.center.lng);
        }
      });
    },
    setPaths(lat, lng) {
      this.paths.forEach((path, index) => {
        let sumLat = index < 2 ? lat + 0.01 : lat - 0.01;
        let sumLng = index == 0 || index == 3 ? lng + 0.01 : lng - 0.01;
        if (!this.zonas.length) {
          this.copyZonas[0].paths.splice(index, 1, {
            lat: sumLat,
            lng: sumLng,
          });
        } else {
          this.paths.splice(index, 1, {
            lat: sumLat,
            lng: sumLng,
          });
        }
      });
    },
    async updateEdited(e, index) {
      e.forEach((item) => {
        let path = [];
        item.i.forEach((i) => {
          path.push({ lat: i.lat(), lng: i.lng() });
        });
        this.$set(this.copyZonas[index], "paths", path);
      });
    },
    handleClickForDelete($event, index) {
      if ($event.vertex) {
        this.$refs.polygon[index].$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },
    agregarZona() {
      this.copyZonas.splice(this.copyZonas.length, 0, {
        name: `Zona ${this.copyZonas.length + 1}`,
        price: 5000,
        ciudad: this.city,
        delivery_time: "2 días",
        paths: this.paths,
        id: -1,
      });
    },
    close() {
      this.copyZonas = [];
      this.listRemoveZonas = [];
      this.zoom = 14;
      this.selectedPolygon = -1;
      this.center = { lat: 0, lng: 0 };
      this.paths = [
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
      ];
      this.$refs.polygon = null;
    },
    getIdZona(index) {
      this.selectedPolygon = index;
    },
    removeZona(zona) {
      let index = this.copyZonas.indexOf(zona);

      this.copyZonas.splice(index, 1);
    },
  },
  watch: {
    zonas(value) {
      if (!value.length) {
        this.copyZonas.splice(this.copyZonas.length, 0, {
          name: `Zona ${this.copyZonas.length + 1}`,
          price: 5000,
          ciudad: this.city,
          delivery_time: "2 días",
          paths: this.paths,
          id: -1,
        });
      } else {
        value.forEach((item) => {
          this.copyZonas.push({
            name: item.name,
            price: item.price,
            ciudad: item.ciudades_id,
            delivery_time: item.delivery_time,
            paths: item.paths,
            id: item.id,
          });
        });
      }
      this.getCurrentCity();
    },
  },
};
</script>

<style scoped>
.zonas {
  display: flex;
  width: 100%;
}
.container-map {
  width: 60%;
}
.info-zonas {
  margin-left: 20px;
  max-width: 42%;
  height: 100%;
  overflow-y: auto;
}
h2 {
  margin-bottom: 7px;
  color: #4c4c4c;
  font-weight: 600;
}
.container {
  max-height: 34vh;
  overflow-y: auto;
}
.wrapper-zonas {
  display: grid;
  gap: 7px;
  grid-template-columns: 1fr 0.7fr 1fr 60px;
  align-items: flex-end;
  position: relative;
  padding-left: 3px;
  box-sizing: border-box;
}
.wrapper-zonas.border-left::before {
  content: "";
  height: 40px;
  width: 6px;

  background-color: DARKVIOLET;
  border-radius: 2px;
  position: absolute;
}
.info-zonas .description {
  line-height: 1.6;
  margin-bottom: 1rem;
  word-break: normal;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--purple);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: DARKSLATEBLUE;
}
</style>
