<template>
  <div :class="{ updated: data.updateTMP }">
    <div class="column row-span-2 photo relative">
      <img
        loading="lazy"
        v-if="soldOut"
        class="img-product product-image-soldOut"
        :src="
          dataTMP.foto_cloudinary == 'sin_foto.jpeg'
            ? defaultPhoto
            : idCloudinaryAwards(dataTMP.foto_cloudinary, 150, 150)
        "
      />
      <img
        loading="lazy"
        v-if="!soldOut"
        class="img-product"
        :src="
          dataTMP.foto_cloudinary == 'sin_foto.jpeg'
            ? defaultPhoto
            : idCloudinaryAwards(dataTMP.foto_cloudinary, 150, 150)
        "
      />
    </div>
    <div class="column row-span-1 name">
      <p class="text-xs">{{ dataTMP.nombre }}</p>
      <p v-if="soldOut" class="overlay-soldOut">
        {{ $t("text_ventas_agotado") }}
      </p>
    </div>

    <div class="column row-span-1 inventory">
      <ul v-if="combinaciones.length">
        <li
          v-for="(option, i) in combinaciones"
          :key="`option_${i}`"
          class="combinacion"
        >
          <p class="combinacion_label">
            <span
              v-for="(value, j) in option.combinacion"
              :key="`value_${j}`"
              >{{ value }}</span
            >
          </p>
          <template v-if="modeEdit">
            <el-input-number
              v-model="option.unidades"
              :controls="false"
            ></el-input-number>
            <div class="el-input">
              <money
                v-model="option.precio"
                v-bind="money"
                class="el-input__inner"
              ></money>
            </div>
          </template>
          <template v-else>
            <p>{{ option.unidades }}</p>
            <p>{{ option.precio | formatCurrency(idCountryStore) }}</p>
          </template>
        </li>
      </ul>
      <div v-else class="combinacion">
        <p>- - -</p>
        <!-- <p class="combinacion_label">Unica combinación</p> -->
        <template v-if="modeEdit">
          <el-input v-model="dataTMP.inventario" type="number"></el-input>
          <div class="el-input">
            <money
              v-model="dataTMP.precio"
              v-bind="money"
              class="el-input__inner"
            ></money>
          </div>
        </template>
        <template v-else>
          <p>{{ dataTMP.inventario }}</p>
          <p>{{ dataTMP.precio | formatCurrency(idCountryStore) }}</p>
        </template>
      </div>
    </div>
    <div v-if="modeEdit" class="column row-span-1 actions">
      <el-button
        size="mini"
        icon="ti-close"
        title="Cancelar"
        @click="revertChanges"
      ></el-button>
      <el-button
        type="primary"
        size="mini"
        icon="ti-save"
        title="Guardar"
        @click="saveProduct"
      ></el-button>
      <!-- <el-button size="mini" @click icon="ti-trash"></el-button> -->
    </div>
    <div v-else class="column row-span-1 actions">
      <a
        :href="getUrlProduct()"
        title="Previsualizar"
        target="_blank"
        class="action preview"
      >
        <el-button size="mini" icon="ti-eye"></el-button>
      </a>
      <el-button
        size="mini"
        icon="ti-pencil"
        title="Editar rápido"
        @click="handleEdit()"
      ></el-button>
      <router-link
        :to="`/inventario/productos/${data.id}`"
        class="action"
        title="Mas opciones"
      >
        <el-button size="mini" icon="ti-plus"></el-button>
      </router-link>
    </div>
    <div class="row-start-2 row-end-3 col-start-2 col-end-5">
      <div class="content-tags">
        <el-tooltip
          class="item"
          effect="light"
          content="Categoría"
          placement="top"
        >
          <span
            v-if="dataTMP.categoria_producto.id"
            class="category-tag category"
          >
            {{ dataTMP.categoria_producto.nombre_categoria_producto }}
          </span>
        </el-tooltip>
        <span
          v-if="
            dataTMP.categoria_producto.id && dataTMP.subcategoria_producto.id
          "
          class="separador"
          >&#10095;</span
        >
        <el-tooltip
          class="item"
          effect="light"
          content="Subcategoría"
          placement="top"
        >
          <span
            v-if="dataTMP.subcategoria_producto.id"
            class="category-tag subcategory"
          >
            {{ dataTMP.subcategoria_producto.nombre_subcategoria }}
          </span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import idCloudinary from "../../mixins/idCloudinary";
import formatCurrency from "../../mixins//formatCurrent";
export default {
  mixins: [idCloudinary, formatCurrency],
  props: ["data"],
  data() {
    return {
      dataTMP: null,
      modeEdit: false,
    };
  },
  computed: {
    product: {
      get() {
        return this.data;
      },
      set(newValue) {
        this.$emit("update:data", newValue);
      },
    },
    subdomain() {
      return this.$store.state.storeData.subdominio;
    },
    variantes() {
      if (
        this.dataTMP.variantes &&
        this.dataTMP.variantes.length &&
        this.dataTMP.variantes[0].variantes !== "[object Object]"
      ) {
        return JSON.parse(this.dataTMP.variantes[0].variantes);
      }
      return [];
    },
    combinaciones() {
      if (
        this.dataTMP.variantes &&
        this.dataTMP.variantes.length &&
        this.dataTMP.variantes[0].variantes !== "[object Object]"
      ) {
        return JSON.parse(
          this.dataTMP.variantes[0].combinaciones[0].combinaciones
        );
      }
      return [];
    },
    soldOut() {
      if (
        this.dataTMP.con_variante &&
        this.dataTMP.variantes[0].variantes !== "[object Object]"
      ) {
        const arrCombinations = this.dataTMP.variantes;
        let inventario = 0;
        if (
          arrCombinations.length &&
          arrCombinations[0].variantes !== "[object Object]"
        ) {
          if (arrCombinations[0].combinaciones.length) {
            if (
              JSON.parse(arrCombinations[0].combinaciones[0].combinaciones)
                .length
            ) {
              JSON.parse(
                arrCombinations[0].combinaciones[0].combinaciones
              ).forEach((item) => {
                inventario += parseInt(item.unidades);
              });
            }
          }
        }
        return !inventario;
      } else {
        return !this.dataTMP.inventario;
      }
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  watch: {
    data(value) {
      this.dataTMP = Object.assign({}, value);
      this.setFormatMoney(this.idCountryStore);
    },
  },
  created() {
    this.dataTMP = Object.assign({}, this.data);
    this.setFormatMoney(this.idCountryStore);
  },
  methods: {
    async saveProduct() {
      let productData = this.dataTMP;
      let params = {
        id: productData.id,
        nombre: productData.nombre,
        marca: productData.marca,
        precio: productData.precio || 0,
        garantia: productData.garantia,
        disponibilidad: productData.disponibilidad,
        descripcion: productData.descripcion,
        video: productData.video,
        categoria_producto: productData.categoria_producto.id || 0,
        subcategoria: productData.subcategoria_producto.id || 0,
        activo: productData.activo,
        sku: productData.sku,
        peso: productData.peso,
        inventario: productData.inventario,
        variantes: JSON.stringify(this.variantes),
        combinaciones: JSON.stringify(this.combinaciones),
        envio_gratis: productData.envio_gratis,
        con_variante: productData.con_variante,
      };
      const { status, data } = await this.$store.dispatch(
        "UPDATE_PRODUCT",
        params
      );
      if (status === 200) {
        this.handleEdit();
      } else {
        this.$notify.error(Object.values(data.errores)[0][0]);
      }
    },
    handleEdit() {
      this.modeEdit = !this.modeEdit;
    },
    revertChanges() {
      this.dataTMP = Object.assign({}, this.data);
      this.handleEdit();
    },
    getUrlProduct() {
      const suffix = this.storeData.template !== 3 ? "store" : "co";
      if (
        this.storeData.entidades &&
        this.storeData.entidades.length > 0 &&
        this.storeData.entidades[0].id == 21
      ) {
        if (this.storeData.template == 99) {
          return `https://${this.subdomain}.keepbuy.co/wa/${this.storeData.id}/productos/${this.dataTMP.slug}`;
        } else {
          return `https://${this.subdomain}.keepbuy.co/productos/${this.dataTMP.slug}`;
        }
      } else {
        if (this.storeData.template == 99) {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}/wa/${this.storeData.id}/productos/${this.dataTMP.slug}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}/wa/${this.storeData.id}/productos/${this.dataTMP.slug}`;
          }
        } else if (this.storeData.template == 12) {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}`;
          }
        } else {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}/productos/${this.dataTMP.slug}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}/productos/${this.dataTMP.slug}`;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.product-image-soldOut {
  filter: grayscale(100%);
}
.img-product {
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  transform: scale(1);
}
.img-product:hover >>> img {
  transition: all ease-in-out 0.3s;
  transform: scale(1.2);
}
.overlay-soldOut {
  width: 65px;
  padding: 2px;
  font-size: 11px;
  @apply rounded-md shadow-md text-white bg-red-600;
}
.row.updated {
  background: rgba(223, 224, 224, 0.26);
}
.row * {
  margin: 0;
}

.column {
  @apply grid col-span-1;
  /* height: 100%; */
  /* border-left: 1px solid #c0c4cc86; */
}
.column.photo {
  @apply justify-center p-2;
}
.column.photo >>> .cld-image {
  @apply rounded-lg overflow-hidden border;
}
.column.name {
  justify-content: flex-start;
  align-content: center;
}
.column.inventory ul {
  grid-gap: 5px;
  list-style: none;
}
.column.inventory {
  display: grid;
  justify-content: center;
  padding: 10px 0;
}
.column.inventory .combinacion {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 5px;
  grid-template-columns: 150px 100px 100px;
  justify-items: center;
}
/* .product__photo {
  width: 70px;
  height: 70px;
  object-fit: contain;
} */
.combinacion_label {
  font-weight: bold;
  justify-self: start;
}
.combinacion_label span ~ span::before {
  content: "-";
  margin: 0 5px;
}
.column.actions {
  grid-auto-flow: column;
  grid-gap: 5px;
  justify-content: center;
  align-content: center;
}
.column.actions.with__popover {
  display: none;
}
.column.name > p {
  text-align: left;
  font-weight: bold;
}
.content-tags {
  /* margin-top: 7px; */
  display: flex;
  justify-content: flex-start;
}
.category-tag {
  padding: 4px 10px;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}
.category {
  background-color: #cabefb;
  color: var(--purple);
}
.subcategory {
  background-color: #d5f6e0;
  color: #2f8c4e;
}
.separador {
  padding: 2px 5px;
}
@media (max-width: 1250px) {
  .action.preview {
    display: none;
  }
  /* .column.actions {
    display: none;
  }
  .column.actions.with__popover {
    display: grid;
  } */
}
/* @media (max-width: 1120px) {
  .product__photo {
    width: 50px;
    height: 50px;
  }
} */
</style>
