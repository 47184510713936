<template>
  <div
    class="w-full rounded-xl px-[40px] py-[30px]"
    :class="darkMode ? 'bg-[#1b2836]' : 'bg-white'"
    style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09)"
  >
    <div v-if="policiesData" class="w-full flex flex-col">
      <div class="input-area">
        <p :class="darkMode ? 'text-white' : 'text-[#333]'">
          {{ $t("text_legal_info1") }}
          <i
            slot="suffix"
            class="el-icon-question el-input__icon"
            @click="
              handleHelpLateral(
                'https://www.komercia.co/blog/6111a3cd31f8fc16dd0cfa81'
              )
            "
          ></i>
          <vue-editor
            id="datos"
            v-model="policiesData.datos"
            :class="darkMode ? 'text-white ' : 'text-[#333] '"
          ></vue-editor>
        </p>
      </div>
      <br />
      <div class="input-area">
        <p :class="darkMode ? 'text-white' : 'text-[#333]'">
          {{ $t("text_legal_info2") }}
          <sliderInformation :numberId="68" :actions="true" class="ml-2" />
        </p>
        <vue-editor
          id="garantia"
          v-model="policiesData.garantia"
          :class="darkMode ? 'text-white' : 'text-[#333]'"
        ></vue-editor>
      </div>
      <br />
      <div class="input-area">
        <p :class="darkMode ? 'text-white' : 'text-[#333]'">
          {{ $t("text_legal_info3") }}
          <i
            slot="suffix"
            class="el-icon-question el-input__icon"
            @click="
              handleHelpLateral(
                'https://www.komercia.co/blog/61119f6731f8fc16dd0cfa7e'
              )
            "
          ></i>
        </p>
        <vue-editor
          id="devoluciones"
          v-model="policiesData.devolucion"
          :class="darkMode ? 'text-white' : 'text-[#333]'"
        ></vue-editor>
      </div>
      <br />
      <div class="input-area">
        <p :class="darkMode ? 'text-white' : 'text-[#333]'">
          {{ $t("text_legal_info4") }}
          <i
            slot="suffix"
            class="el-icon-question el-input__icon"
            @click="
              handleHelpLateral(
                'https://www.komercia.co/blog/6111a29131f8fc16dd0cfa80'
              )
            "
          ></i>
        </p>
        <vue-editor
          id="cambio"
          v-model="policiesData.cambio"
          :class="darkMode ? 'text-white' : 'text-[#333]'"
        ></vue-editor>
      </div>
      <div class="mt-10">
        <el-button
          type="primary"
          class="pull-right"
          @click="updatePoliciesData"
        >
          {{ $t("text_inventario_guardar") }}
        </el-button>
        <el-button class="pull-right" @click="redirectTo">
          {{ $t("text_inventario_cancelar") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import sliderInformation from "@/components/information.vue";
export default {
  name: "informationLegal",
  components: {
    VueEditor,
    sliderInformation,
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    policiesData: {
      get() {
        return this.$store.state.policiesData;
      },
      set(newValue) {
        this.$store.state.policiesData = newValue;
      },
    },
    configAxios() {
      return this.$store.state.configAxios;
    },
  },
  methods: {
    updatePoliciesData() {
      let params = {
        datos: this.policiesData.datos,
        garantia: this.policiesData.garantia,
        devolucion: this.policiesData.devolucion,
        cambio: this.policiesData.cambio,
        envios: this.policiesData.envios,
      };
      axios
        .post(
          `${this.$configKomercia.url}/api/admin/politicas`,
          params,
          this.configAxios
        )
        .then(() => {
          this.$message.success("Políticas actualizada correctamente");
        });
    },
    redirectTo() {
      this.$router.push("/");
    },
    handleHelpLateral(art) {
      window.open(`${art}`, "_blank");
    },
  },
};
</script>

<style scoped>
.input-area {
  width: 100%;
}
.input-area .el-input,
.input-area .quillWrapper,
.input-area .el-select {
  width: 100%;
  /* background-color: #f2efff; */
  @apply overflow-hidden rounded;
}
.el-button ~ .el-button {
  margin-right: 10px;
}
.el-icon-question {
  height: initial;
}
.el-input__icon {
  /* display: flex; */
  align-items: center;
}

@media (max-width: 900px) {
  .input-area,
  .quillWrapper {
    max-width: 100%;
    width: 100%;
    /* margin: 5px; */
  }
}
</style>
