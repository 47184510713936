<template>
  <div class="box-photo mb-4" :class="darkMode ? 'darkbg' : 'lightbg'">
    <h2
      class="text-xl font-bold mb-3"
      :class="darkMode ? 'darktxt' : 'lighttxt'"
    >
      Imágenes del producto
    </h2>
    <div class="grid photos">
      <label
        v-loading="loadingSavePhotoMain"
        class="upload-main cursor-pointer"
        @click="widgetCloudinary('major')"
      >
        <div class="hover">
          <img loading="lazy" src="../../assets/upload.png" alt />
        </div>
        <img
          loading="lazy"
          v-if="data.detalle.foto_cloudinary"
          :src="
            data.detalle.foto_cloudinary == defaultPhoto
              ? 'v1550852556/placeholder/product-image-placeholder.jpg'
              : idCloudinaryAwards(data.detalle.foto_cloudinary, 850, 850)
          "
        />
        <div v-else class="photo_empty">
          <div v-if="isLoading" class="overlay">
            <div class="wrapper">
              <i class="el-icon-loading"></i>
              <h2 style="margin-top: 10px">
                {{ $t("text_inventario_cargando") }}
              </h2>
            </div>
          </div>
          <img loading="lazy" src="../../assets/upload.png" />
          <br />
          <p>{{ $t("text_inventario_fotoProducto") }}</p>
        </div>
      </label>
      <div class="photo_list">
        <div
          v-for="(photo, index) in data.fotos"
          :key="index"
          class="photo upload"
        >
          <div class="photo_delete">
            <button
              class="bg-red-400 shadow-md px-2 py-1 rounded-lg text-white hover:bg-red-500"
              @click="deletePhoto(photo, index)"
            >
              Eliminar
              <i class="el-icon-delete"></i>
            </button>
          </div>
          <img
            loading="lazy"
            :src="idCloudinaryAwards(photo.foto_cloudinary, 850, 850)"
          />
        </div>
        <label
          v-if="
            (storeData.id == 1559 || storeData.id == 2785
              ? data.fotos.length <= 15
              : data.fotos.length <= 4) && data.detalle.foto_cloudinary
          "
          class="upload"
          @click="widgetCloudinary('minor')"
        >
          <div class="upload_empty">
            <div v-if="isLoading" class="overlay">
              <div class="wrapper">
                <i
                  style="font-size: 30px !important"
                  class="el-icon-loading"
                ></i>
              </div>
            </div>
            <div class="up-image">
              <i
                style="font-size: 30px !important; color: var(--purple)"
                class="el-icon-upload"
              ></i>
              <p>{{ $t("text_inventario_subirOtraImagen") }}</p>
            </div>
            <!--  <img loading="lazy" src="../../assets/upload.png" alt /> -->
          </div>
        </label>
      </div>
    </div>
    <div class="input-area-full youtube">
      <div class="youtube__input">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_videoProducto") }}
        </p>
        <div class="flex flex-row justify-center">
          <div
            class="content-icon-youtube"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            <i class="ti-youtube" style="font-size: 20px"></i>
          </div>
          <el-input
            v-model="urlYoutube"
            :placeholder="$t('text_inventario_URLvideoYoutube')"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          >
            <i
              slot="suffix"
              class="el-icon-question el-input__icon"
              @click="handleHelp('Video del producto', 'Video del producto')"
            ></i>
          </el-input>
        </div>
      </div>
      <a
        v-if="idYoutube"
        :href="urlYoutube"
        title="Abrir video de YouTube"
        target="_blank"
        class="youtube__thumbnail"
      >
        <img
          loading="lazy"
          :src="`https://img.youtube.com/vi/${idYoutube}/0.jpg`"
          title="Imagen del video"
        />
      </a>
    </div>
  </div>
</template>

<script>
// import { CldImage, CldTransformation } from 'cloudinary-vue'
import cloudinaryWidgetOptions from "../../mixins/cloudinaryWidgetOptions";
import idCloudinary from "../../mixins/idCloudinary";
import axios from "axios";

export default {
  // components: { CldImage, CldTransformation },
  mixins: [cloudinaryWidgetOptions, idCloudinary],
  props: {
    data: {
      type: Object,
      required: true,
    },
    youtube: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      defaultPhoto:
        "https://res.cloudinary.com/komercia-store/image/upload/c_scale,q_auto:best,f_auto/v1550852556/placeholder/product-image-placeholder.jpg",
      photoMainURL: "",
      loadingSavePhotoMain: false,
      youtubeVideoId: "",
      myUploadWidget: null,
      // validVideo: false,
      isLoading: false,
    };
  },
  computed: {
    storeData() {
      return this.$store.state.storeData;
    },
    urlYoutube: {
      get() {
        return this.youtube;
      },
      set(newValue) {
        this.$emit("update:youtube", newValue);
      },
    },
    idYoutube() {
      let myregexp =
        /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^/&]{10,12})/;
      let url = this.urlYoutube;
      if (url && url !== "") {
        // this.validVideo = true
        let id = url.match(myregexp);
        if (id) {
          return id[1];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  methods: {
    handleHelp(title, description) {
      this.$alert(description, title);
    },
    widgetCloudinary(type) {
      if (
        this.storeData &&
        this.storeData.tipo === 0 &&
        this.data.fotos.length >= 2
      ) {
        this.$swal({
          title: "Mejora tu plan",
          html: '<p>Tu límite de imágenes por producto ha sido superado, para agregar más imágenes pásate a <strong class="swal-bold">Premium</strong></p>',
          showCloseButton: true,
          confirmButtonText: "Aceptar",
          backdrop: "rgba(14,47,90,0.6)",
          icon: "warning",
        }).then((r) => {
          if (r.isConfirmed) {
            this.$router.push({ name: "Membresia" });
          }
        });
      } else {
        this.isLoading = true;
        this.myUploadWidget = cloudinary.openUploadWidget(
          this.cloudinaryWidgetOptions(),
          (error, result) => {
            this.isLoading = false;
            this.afterUpload(error, result, type);
          }
        );
        this.myUploadWidget.open();
        setTimeout(() => {
          this.loadingSavePhotoMain = false;
        }, 7000);
      }
    },
    afterUpload(error, result, type) {
      // console.log(result.info)
      if (result.event === "success") {
        this.$emit("upload", { payload: result.info, type });
        this.myUploadWidget.close();
      }
    },
    deletePhoto(photo, index) {
      if (photo.id) {
        let params = {
          id_producto: this.data.detalle.id,
          _method: "DELETE",
        };
        axios
          .post(
            `${this.$configKomercia.url}/api/admin/producto/imagen/adicional/eliminar/${photo.id}`,
            params,
            this.$store.state.configAxios
          )
          .then(() => {
            this.data.fotos.splice(index, 1);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // this.data.fotos.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.content-icon-youtube {
  padding: 6px 15px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  max-height: 40px;
  margin-right: 5px;
}
.text-subtitle {
  font-size: 13px;
  font-weight: 500;
}
.darkbg {
  background-color: #293d53;
}
.lightbg {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.upload,
.upload-main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(199, 195, 200, 0.49);
}
.upload {
  max-width: 120px;
  max-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-main .hover {
  position: absolute;
  opacity: 0;
  border-color: #818592;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
}
.upload-main .hover i {
  color: #818592;
}
.upload-main .hover:hover {
  border: 2px dashed rgba(147, 74, 15, 0.32);
}
.upload-main > div > img {
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.upload-main img {
  max-width: 100%;
  max-height: 100%;
}
.upload-main .photo_empty {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgba(15, 147, 128, 0.32);
  position: relative;
}
.upload-main .photo_empty p {
  font-weight: 400;
  color: #565656;
  font-size: 12px;
}
.overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.952);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.overlay i {
  color: rebeccapurple !important;
  font-size: 50px !important;
}
.wrapper {
  min-height: 30px;
  height: auto;
}
.upload-main div i {
  font-size: 35px;
  color: #b1b5bc;
}
.upload_empty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload_empty img {
  width: 50px;
  height: 50px;
}
.upload > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.iframe_youtube {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.photo_list {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(auto, 110px));
}
.photo_delete {
  display: none;
}
.photo:hover .photo_delete {
  @apply flex absolute bottom-3 right-1;
}
input[type="file"] {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}
.photos_left {
  display: grid;
  grid-row-gap: 10px;
  align-content: start;
}
.photos_right {
  display: grid;
  grid-row-gap: 10px;
  align-content: start;
}
.grid.photos {
  width: initial;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-column-gap: 10px;
  justify-content: start;
  margin-bottom: 15px;
}
.photos > .upload ~ .upload {
  margin-left: 10px;
}
.photos .upload-main {
  width: 250px;
  height: 250px;
  transition: 1s;
}
.upload-main:hover {
  transform: scale(1.03);
  background-color: #f0f0f093;
}
.input-area-full.youtube {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-content: center;
  z-index: 2;
}
.youtube__input {
  display: grid;
  justify-content: start;
  justify-items: start;
  padding-bottom: 5px;
}
.youtube__input .el-button {
  margin-top: 5px;
}
.youtube__thumbnail {
  display: grid;
  align-content: end;
  align-items: end;
  height: 100%;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  padding: 5px;
  margin: 0;
  margin-right: 3px;
}
.youtube__thumbnail img {
  height: 68px;
}
.prueba > img {
  vertical-align: top !important;
}
.up-image {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 4px;
  text-align: center;
}
.up-image > p {
  color: #818592;
  font-weight: bold;
}
.box-photo {
  position: relative;
  width: 100%;
  padding: 23px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
@media (max-width: 767px) {
  .box-photo .box-body {
    padding: 10px;
  }
  .grid.photos {
    display: flex;
  }
  .photos .upload-main {
    max-width: 150px;
    width: 100%;
    height: 150px;
  }
  .photo_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
  }
  .upload {
    max-width: 150px;
    width: 100%;
    height: 150px;
    max-height: unset;
  }
  .input-area-full.youtube {
    width: 100%;
    display: flex;
  }
  .youtube__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
  }
  .youtube .el-input {
    width: 100%;
    max-width: 480px;
  }
}
</style>
