<template>
  <div class="wrapper-content-items" :class="darkMode ? 'darkbgs' : 'lightbgs'">
    <div
      v-if="!stateCategory"
      class="w-full flex justify-between items-center mb-[20px]"
    >
      <h1
        class="text-lg font-bold"
        :class="darkMode ? 'darktxts' : 'lighttxts'"
      >
        {{ $t("text_categorias") }}
      </h1>
      <button
        size="small"
        :class="darkMode ? 'darktxts' : 'lighttxts'"
        class="btnCategories flex items-center"
        @click="handleSidebarHelp('25')"
      >
        {{ $t("text_inventario_aprendeSobreCategorias") }}
        <img
          loading="lazy"
          class="w-5 ml-2"
          src="@/assets/information.webp"
          alt="imgInformation"
        />
      </button>
    </div>
    <div v-if="stateCategory" class="wrapper-btn">
      <button
        size="small"
        :class="darkMode ? 'darktxts' : 'lighttxts'"
        class="btnCategories"
        @click="closeCategory"
      >
        Cerrar venta
      </button>
    </div>
    <div class="wrapper-box">
      <div
        class="content-list-items h-full"
        :class="darkMode ? 'darkbgs2' : 'lightbgs'"
      >
        <div v-if="categoriesData.length > 0" class="categories">
          <div class="categories_header">
            <p :class="darkMode ? 'darktxts' : 'lighttxts'">
              {{ $t("text_inventario_nombre") }}
            </p>
            <p :class="darkMode ? 'darktxts' : 'lighttxts'">Order</p>
            <p :class="darkMode ? 'darktxts' : 'lighttxts'">Imagen</p>
            <p :class="darkMode ? 'darktxts' : 'lighttxts'">
              {{ $t("text_inventario_numeroProductos") }}
            </p>
            <p :class="darkMode ? 'darktxts' : 'lighttxts'" class="text-center">
              {{ $t("text_inventario_copiarUrl") }}
            </p>
            <p :class="darkMode ? 'darktxts' : 'lighttxts'">
              {{ $t("text_ventas_accciones") }}
            </p>
          </div>
          <ul class="categories_list">
            <li
              v-for="(category, index) in categoriesData"
              :key="`category_${index}`"
              class="categories_item"
            >
              <div
                :class="{
                  categories_item_row: true,
                  selected: selectedCategorys === category.id,
                }"
              >
                <div class="categories_item_row_text">
                  <router-link
                    :to="{
                      path: '/inventario/productos#1',
                      query: { category: category.id },
                    }"
                  >
                    <p :class="darkMode ? 'darktxts' : 'lighttxts'">
                      {{ category.nombre_categoria_producto }}
                    </p>
                  </router-link>
                </div>
                <p
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                  class="flex justify-center items-center"
                >
                  {{ category.orden }}
                </p>
                <div v-if="category.imagen_cloudinary" class="wrapper_img">
                  <img
                    loading="lazy"
                    :src="
                      idCloudinaryAwards(category.imagen_cloudinary, 150, 150)
                    "
                    :alt="`img_category_${category.id}`"
                    class="img_autor"
                  />
                </div>
                <div v-else />
                <p
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                  class="flex justify-center items-center"
                >
                  {{ category.producto_count }}
                </p>
                <el-tooltip
                  v-model="category.activeLink"
                  :placeholder="$t('text_inventario_urlCategoriaCopiada')"
                  placement="bottom"
                  effect="light"
                  :manual="true"
                >
                  <div class="wrapper-link" @click="copyLink(category)">
                    <p
                      :class="darkMode ? 'darktxts' : 'lighttxts'"
                      class="link-copy"
                    >
                      <i class="ti-link"></i>{{ $t("text_inventario_copiar") }}
                    </p>
                  </div>
                </el-tooltip>
                <div v-if="!category.edit" class="categories_item_actions">
                  <img
                    loading="lazy"
                    class="mr-1"
                    src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/1057097_cemejt.png"
                    alt="iconEdit"
                    width="23"
                    @click="setDataCategory(1, category, index)"
                  />
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/3935726_mfqhi5.png"
                    alt="iconDelete"
                    width="23"
                    @click="
                      deleteCategory(
                        category.id,
                        index,
                        category.nombre_categoria_producto
                      )
                    "
                  />
                </div>
                <div v-else class="categories_item_actions">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1643158137/x-icon-png-27_wc7njs.png"
                    alt="iconCancelar"
                    width="23"
                    @click="cancelEdit('category', index, category.id)"
                  />
                </div>
              </div>
              <ul
                v-for="(subcategory, index2) in filterSubcategories(
                  subcategoriesData,
                  category.id
                )"
                :key="`subcategory_${index2}`"
                :class="`subcategories_list list${index2}`"
              >
                <li class="subcategories_item">
                  <div
                    :class="darkMode ? 'darktxts' : 'lighttxts'"
                    class="subcategories_item_text"
                  >
                    {{ subcategory.nombre_subcategoria }}
                  </div>
                  <div />
                  <div v-if="subcategory.imagen_cloudinary" class="wrapper_img">
                    <img
                      loading="lazy"
                      :src="
                        idCloudinaryAwards(
                          subcategory.imagen_cloudinary,
                          150,
                          150
                        )
                      "
                      :alt="`img_category_${subcategory.id}`"
                      class="img_autor"
                    />
                  </div>
                  <div v-else />
                  <div
                    :class="darkMode ? 'darktxts' : 'lighttxts'"
                    class="subcategories_item_quantity"
                  >
                    {{ subcategory.producto_count }}
                  </div>
                  <div class="subcategories_item_quantity">
                    <el-tooltip
                      v-model="subcategory.activeLink"
                      content="url de subcategoría copiada!"
                      placement="bottom"
                      effect="light"
                      :manual="true"
                    >
                      <div
                        class="wrapper-link"
                        @click="copyLink(subcategory, category)"
                      >
                        <p
                          class="link-copy"
                          :class="darkMode ? 'darktxts' : 'lighttxts'"
                        >
                          <i class="ti-link"></i>
                          {{ $t("text_inventario_copiar") }}
                        </p>
                      </div>
                    </el-tooltip>
                  </div>
                  <div
                    v-if="!subcategory.edit"
                    class="subcategories_item_actions"
                  >
                    <img
                      loading="lazy"
                      class="mr-1"
                      src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/1057097_cemejt.png"
                      alt="iconEdit"
                      width="23"
                      @click="setDataCategory(2, subcategory, index2)"
                    />
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/3935726_mfqhi5.png"
                      alt="iconDelete"
                      width="23"
                      @click="
                        deleteSubcategory(
                          subcategory.id,
                          subcategory.nombre_subcategoria
                        )
                      "
                    />
                  </div>
                  <div v-else class="categories_item_actions">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1643158137/x-icon-png-27_wc7njs.png"
                      alt="iconCancelar"
                      width="23"
                      @click="cancelEdit('subcategory', index2, subcategory.id)"
                    />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else class="categories_empty">
          <p :class="darkMode ? 'darktxts' : 'lighttxts'">
            {{ $t("text_inventario_noHayCategorias") }}
          </p>
        </div>
      </div>
      <div class="categories_actions">
        <!-- Categoría -->
        <div
          class="categories_actions_create"
          :class="darkMode ? 'darkbgs2' : 'lightbgs'"
        >
          <p
            class="mb-3 text-base text-center font-bold"
            :class="darkMode ? 'darktxts' : 'lighttxts'"
          >
            {{
              categoriesData && categoriesData.length > 0
                ? !category.state
                  ? "Agregar categoría"
                  : "Editar categoría: "
                : "Crear categoría"
            }}
            <span>
              {{ !category.state ? "" : `(${category.name})` }}
            </span>
          </p>
          <div class="w-full flex flex-col items-start">
            <div class="mb-2 w-full">
              <p class="text-info">Nombre de la categoría</p>
              <el-input
                v-model="category.name"
                type="text"
                placeholder="Ingresar nombre"
                class="inputs-idTienda mt-3"
                :class="darkMode ? 'darks-input' : 'light-input'"
              />
            </div>
            <div class="w-full mb-3">
              <p class="text-info mt-2">Prioridad de aparición</p>
              <el-slider
                v-model="category.order"
                :marks="marks"
                :step="1"
                :max="max"
                :show-stops="stops"
              ></el-slider>
              <p class="text-info mt-5">
                Nota: La categoría con alta prioridad alta (10), saldrá en las
                primeras posiciones.
              </p>
            </div>
            <div class="grid grid-cols-2 gap-3 mb-3">
              <section class="section">
                <p class="mb-2 text-info">
                  {{ $t("text_blog_subirImagen") }}
                </p>
                <div class="wrapper-content color-radio">
                  <el-radio-group
                    v-model="category.showPicture"
                    class="wrapper-content"
                  >
                    <el-radio :label="false" border>
                      {{ $t("text_inventario_no") }}
                    </el-radio>
                    <el-radio :label="true" border>
                      {{ $t("text_inventario_si") }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <p
                  v-if="category.showPicture"
                  class="mt-2 text-xs text-justify max-h-52"
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                >
                  <strong>Nota: </strong>Las imágenes solo están disponibles
                  para el template de WhatsApp, para mostrar imágenes
                  selecciones la opción 4 de tipo estilo de categorías
                </p>
              </section>
              <div
                v-if="category.showPicture"
                class="flex flex-col justify-center items-center"
              >
                <img
                  loading="lazy"
                  v-if="category.picture"
                  :src="idCloudinaryAwards(category.picture, 150, 150)"
                  class="rounded-md shadow-md bg-gray-100 h-20 w-20"
                  width="40"
                  height="40"
                />
                <img
                  loading="lazy"
                  v-else
                  :src="banner"
                  class="rounded-md shadow-md bg-gray-100 h-20 w-20"
                />
                <p
                  class="mt-2 mb-3 text-xs text-center"
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                >
                  Tamaño sugerido 80 X 80 pixeles
                </p>
                <button
                  type="button"
                  class="w-full py-2 px-2 border-2 border-gray-800 rounded-md shadow-sm text-sm font-bold text-gray-800"
                  @click="widgetCloudinary(1)"
                >
                  {{ category.picture ? "Editar imagen" : "Asignar imagen" }}
                </button>
              </div>
            </div>
          </div>
          <el-button
            type="primary"
            class="mt-4"
            style="border-radius: 10px; font-size: 13px"
            @click="!category.state ? createCategory() : updateCategory()"
          >
            {{
              !category.state
                ? $t("text_inventario_NuevaCategoria")
                : $t("text_inventario_editarCategoria")
            }}
          </el-button>
        </div>
        <!-- subcategoría -->
        <div
          class="categories_actions_create"
          :class="darkMode ? 'darkbgs2' : 'lightbgs'"
        >
          <p
            :class="darkMode ? 'darktxts' : 'lighttxts'"
            class="mb-3 text-base font-bold text-center"
          >
            {{
              !subCategory.state
                ? "Crear subcategoría"
                : "Editar subcategoría: "
            }}
            <span>
              {{ !subCategory.state ? "" : `(${subCategory.name})` }}
            </span>
          </p>
          <div class="flex flex-col justify-center mb-3">
            <div class="mb-2">
              <p class="text-info">Seleccionar la categoría</p>
              <el-select
                v-model="subCategory.selectedCategory"
                :placeholder="$t('text_inventario_seleccionaCategoria')"
                class="inputs-idTienda w-full"
                :class="darkMode ? 'darks-input' : 'light-input'"
                filterable
                clearable
              >
                <el-option
                  v-for="category in categoriesData"
                  :key="category.id"
                  :label="category.nombre_categoria_producto"
                  :value="category.id"
                />
              </el-select>
            </div>
            <div v-if="subCategory.selectedCategory" class="mb-2">
              <p class="text-info">Nombre de la subcategoría</p>
              <el-input
                v-model="subCategory.name"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                type="text"
                placeholder="Ingresar nombre"
              />
            </div>
          </div>
          <div
            v-if="subCategory.selectedCategory"
            class="grid grid-cols-2 gap-3 mb-3"
          >
            <section class="section">
              <p
                class="mb-2 text-sm"
                :class="darkMode ? 'darktxts' : 'lighttxts'"
              >
                {{ $t("text_blog_subirImagen") }}
              </p>
              <div class="wrapper-content color-radio">
                <el-radio-group
                  v-model="subCategory.showPicture"
                  class="wrapper-content"
                >
                  <el-radio :label="false" border>
                    {{ $t("text_inventario_no") }}
                  </el-radio>
                  <el-radio :label="true" border>
                    {{ $t("text_inventario_si") }}
                  </el-radio>
                </el-radio-group>
              </div>
              <p
                v-if="subCategory.showPicture"
                class="mt-2 text-xs text-justify max-h-52"
                :class="darkMode ? 'darktxts' : 'lighttxts'"
              >
                <strong>Nota: </strong>Las imágenes solo están disponibles para
                el template de WhatsApp, para mostrar imágenes selecciones la
                opción 4 de tipo estilo de categorías
              </p>
            </section>
            <div
              v-if="subCategory.showPicture"
              class="flex flex-col justify-center items-center"
            >
              <img
                loading="lazy"
                v-if="subCategory.picture"
                :src="idCloudinaryAwards(subCategory.picture, 150, 150)"
                class="rounded-md shadow-md bg-gray-100 h-20 w-20"
                width="40"
                height="40"
              />
              <img
                loading="lazy"
                v-else
                :src="banner"
                class="rounded-md shadow-md bg-gray-100 h-20 w-20"
              />
              <p
                class="mt-2 mb-3 text-xs text-center"
                :class="darkMode ? 'darktxts' : 'lighttxts'"
              >
                Tamaño sugerido 80 X 80 pixeles
              </p>
              <button
                type="button"
                class="w-full py-2 px-2 border-2 border-gray-800 rounded-md shadow-sm text-sm font-bold text-gray-800"
                @click="widgetCloudinary(2)"
              >
                {{ subCategory.picture ? "Editar imagen" : "Asignar imagen" }}
              </button>
            </div>
          </div>
          <el-button
            v-if="subCategory.selectedCategory"
            type="primary"
            style="border-radius: 10px; font-size: 13px"
            @click="
              !subCategory.state ? createSubcategory() : updateSubcategory()
            "
          >
            {{
              !subCategory.state
                ? $t("text_inventario_subNuevaCategoria")
                : $t("text_inventario_editarSubCategoria")
            }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import cloudinaryWidgetOptions from "@/mixins/cloudinaryWidgetOptions";
import getIdCloudinary from "@/mixins/getIdCloudinary";
import idCloudinaryAwards from "@/mixins/idCloudinary";
export default {
  name: "inventoryCategories",
  mixins: [cloudinaryWidgetOptions, getIdCloudinary, idCloudinaryAwards],
  props: {
    stateCategory: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      category: {
        state: false,
        id: "",
        showPicture: false,
        name: "",
        picture: "",
        idpicture: "",
        index: "",
        order: 0,
      },
      subCategory: {
        state: false,
        id: "",
        showPicture: false,
        name: "",
        picture: "",
        idpicture: "",
        selectedCategory: "",
        index: "",
      },
      selectedCategorys: "",
      loadingSavePhotoMain: false,
      myUploadWidget: null,
      isLoading: false,
      banner:
        "https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1649367838/2659360_s1ap5f.png",
      max: 10,
      stops: true,
      marks: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
      },
    };
  },
  computed: {
    ...mapState([
      "darkMode",
      "storeData",
      "categoriesData",
      "subcategoriesData",
    ]),
  },
  created() {
    this.$store.dispatch("GET_CATEGORIES");
  },
  methods: {
    handleSidebarHelp(contentId) {
      this.$store.state.miscSidebar.isActive = true;
      this.$store.state.miscSidebar.isHelpdesk = false;
      this.$store.state.miscSidebar.data = contentId;
    },
    cancelEdit(type, index, id) {
      if (type == "category") {
        this.categoriesData[index].edit = false;
        this.category = {
          showPicture: false,
          state: false,
          id: "",
          name: "",
          picture: "",
          idpicture: "",
          index: "",
          order: 0,
        };
      } else if (type == "subcategory") {
        let result = this.subcategoriesData.find((items) => items.id == id);
        if (result) {
          result.edit = false;
        }
        this.subCategory = {
          showPicture: false,
          state: false,
          id: "",
          name: "",
          picture: "",
          idpicture: "",
          selectedCategory: "",
          index: "",
        };
      }
    },
    setDataCategory(type, data, index) {
      if (type == 1) {
        if (this.category?.state) {
          this.categoriesData[this.category.index].edit = false;
        }
        if (data.imagen_cloudinary && data.id_cloudinary) {
          this.category.showPicture = true;
        } else {
          this.category.showPicture = false;
        }
        this.category = {
          state: true,
          id: data.id,
          name: data.nombre_categoria_producto,
          picture: data.imagen_cloudinary,
          idpicture: data.id_cloudinary,
          index: index,
          order: data.orden,
          showPicture:
            data.id_cloudinary && data.imagen_cloudinary ? true : false,
        };
        this.categoriesData[this.category.index].edit = true;
      } else if (type == 2) {
        let result = {};
        if (this.subCategory?.state) {
          result = this.subcategoriesData.find(
            (items) => items.id == this.subCategory.id
          );
          result ? (result.edit = false) : "";
        }
        data.imagen_cloudinary && data.id_cloudinary
          ? (this.subCategory.showPicture = true)
          : (this.subCategory.showPicture = false);
        this.subCategory = {
          state: true,
          id: data.id,
          name: data.nombre_subcategoria,
          picture: data.imagen_cloudinary,
          idpicture: data.id_cloudinary,
          selectedCategory: data.categoria,
          index: index,
          showPicture:
            data.id_cloudinary && data.imagen_cloudinary ? true : false,
        };
        result = this.subcategoriesData.find((items) => items.id == data.id);
        result ? (result.edit = true) : "";
      }
    },
    createCategory() {
      if (this.categoriesData.length >= 5 && this.storeData.tipo === 0) {
        this.$swal({
          title: "Mejora tu plan",
          html: '<p>Tu límite de categorías ha sido superado, para agregar ilimitadas categorías pásate a <strong class="swal-bold">Premium</strong></p>',
          showCloseButton: true,
          confirmButtonText: "Aceptar",
          backdrop: "rgba(14,47,90,0.6)",
          icon: "warning",
        }).then((r) => {
          if (r.isConfirmed) {
            this.$router.push({ name: "Membresia" });
          }
        });
      } else {
        if (this.newCategory !== "") {
          if (this.category.name) {
            let params = {
              nombre_categoria_producto: this.category.name,
              imagen_cloudinary: this.category.picture,
              id_cloudinary: this.category.idpicture,
              orden: this.category.order,
            };
            axios
              .post(
                `${this.$configKomercia.url}/api/admin/categorias`,
                params,
                this.$store.state.configAxios
              )
              .then((response) => {
                this.$store.state.categoriesData.unshift(response.data.data);
                this.category = {
                  name: "",
                  picture: "",
                  idpicture: "",
                  order: 0,
                };
                this.$message.success(
                  "Se ha creado correctamente la categoría"
                );
              });
          } else {
            this.$message.error("Llenar los campos");
          }
        } else {
          this.$notify.warning({
            title: "Cuidado!",
            message: "La categoría esta vacía, Por favor escribe un texto",
          });
        }
      }
    },
    updateCategory() {
      let params = {
        nombre_categoria_producto: this.category.name,
        imagen_cloudinary: this.category.picture,
        id_cloudinary: this.category.idpicture,
        orden: this.category.order,
      };
      axios
        .put(
          `${this.$configKomercia.url}/api/admin/categorias/${this.category.id}`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.categoriesData[this.category.index].edit = false;
          this.category = {
            showPicture: false,
            state: false,
            id: "",
            name: "",
            picture: "",
            idpicture: "",
            index: "",
            order: 0,
          };
          this.$store.dispatch("GET_CATEGORIES");
          this.$message.success("Se ha actualizado correctamente la categoría");
        });
    },
    deleteCategory(id, index, nombre) {
      this.$swal({
        title: "Eliminar categoría",
        text: `¿Estas seguro de eliminar la categoría ${nombre}?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        icon: "question",
      }).then(async (r) => {
        if (r.isConfirmed) {
          axios
            .delete(
              `${this.$configKomercia.url}/api/admin/categorias/${id}`,
              this.$store.state.configAxios
            )
            .then((resp) => {
              if (resp.data.estado === 200) {
                this.deleteImageCloudinary(this.category.picture, "categories");
                this.$store.state.categoriesData.splice(index, 1);
                this.$swal(resp.data.mensaje, "", "success");
              } else if (resp.data.estado === 400) {
                this.$swal(
                  "Error!",
                  "Esta categoria tiene productos asociados",
                  "error"
                );
              } else {
                this.$swal("Error!", resp.data.mensaje, "error");
              }
            })
            .catch((error) => {
              if (error) {
                this.$swal("Error!", error.response.data.error, "error");
              }
            });
        }
      });
    },
    createSubcategory() {
      if (this.newSubcategory !== "") {
        if (this.subCategory.name && this.subCategory.selectedCategory) {
          let params = {
            categoria: this.subCategory.selectedCategory,
            nombre_subcategoria: this.subCategory.name,
            imagen_cloudinary: this.subCategory.picture,
            id_cloudinary: this.subCategory.idpicture,
          };
          axios
            .post(
              `${this.$configKomercia.url}/api/admin/subcategorias`,
              params,
              this.$store.state.configAxios
            )
            .then((response) => {
              this.$store.state.subcategoriesData.unshift(response.data.data);
              this.subCategory = {
                name: "",
                picture: "",
                idpicture: "",
                selectedCategory: "",
              };
              this.$message.success(
                "Se ha creado correctamente la subcategoría"
              );
            });
        } else {
          this.$message.error("Llenar los campos");
        }
      } else {
        this.$notify.warning({
          title: "Cuidado!",
          message: "La subcategoría esta vacía, Por favor escribe un texto",
        });
      }
    },
    updateSubcategory() {
      let params = {
        nombre_subcategoria: this.subCategory.name,
        categoria: this.subCategory.selectedCategory,
        imagen_cloudinary: this.subCategory.picture,
        id_cloudinary: this.subCategory.idpicture,
      };
      axios
        .put(
          `${this.$configKomercia.url}/api/admin/subcategorias/${this.subCategory.id}`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.subcategoriesData[this.subCategory.index].edit = false;
          this.subCategory = {
            showPicture: false,
            state: false,
            id: "",
            name: "",
            picture: "",
            idpicture: "",
            selectedCategory: "",
            index: "",
          };
          this.$store.dispatch("GET_CATEGORIES");
          this.$message.success(
            "Se ha actualizado correctamente la subcategoría"
          );
        });
    },
    deleteSubcategory(id, nombre) {
      this.$swal({
        title: "Eliminar subcategoría",
        text: `¿Estas seguro de eliminar la subcategoría ${nombre}?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        icon: "question",
      }).then(async (r) => {
        if (r.isConfirmed) {
          axios
            .delete(
              `${this.$configKomercia.url}/api/admin/subcategorias/${id}`,
              this.$store.state.configAxios
            )
            .then((resp) => {
              if (resp.data.estado === 200) {
                const index = this.subcategoriesData.findIndex(
                  (subcategory) => subcategory.id === id
                );
                this.deleteImageCloudinary(
                  this.subCategory.picture,
                  "categories"
                );
                this.$store.state.subcategoriesData.splice(index, 1);
                this.$swal(resp.data.mensaje, "", "success");
              } else if (resp.data.estado === 400) {
                this.$swal(
                  "Error!",
                  "Esta subcategoría tiene productos asociados",
                  "error"
                );
              } else {
                this.$swal("Error!", resp.data.mensaje, "error");
              }
            })
            .catch((error) => {
              if (error) {
                this.$swal("Error!", error.response.data.error, "error");
              }
            });
        }
      });
    },
    searchProducts(type, id) {
      if (type === "category") {
        this.$router.push({
          path: "/inventario/productos#1",
          query: { category: id },
        });
      }
    },
    filterSubcategories(subcategories, category) {
      if (subcategories.length) {
        return subcategories.filter(
          (subcategory) => subcategory.categoria === category
        );
      }
      return [];
    },
    copyLink(value, isCategory) {
      this.$set(value, "activeLink", true);
      const suffix = this.storeData.template !== 3 ? "store" : "co";

      if (this.storeData.template == 99) {
        if (this.storeData.informacion_tienda[0].dominio) {
          if (this.hasSubcategory(value)) {
            const link = `${this.storeData.informacion_tienda[0].dominio}/wa/${this.storeData.id}?page=1&category=${isCategory.nombre_categoria_producto}&subcategory=${value.id}`;
            this.getCopyLink(link);
          } else {
            const link = `${this.storeData.informacion_tienda[0].dominio}/wa/${this.storeData.id}?page=1&category=${value.nombre_categoria_producto}`;
            this.getCopyLink(link);
          }
        } else {
          if (this.hasSubcategory(value)) {
            const link = `https://${this.storeData.subdominio}.komercia.${suffix}/wa/${this.storeData.id}?page=1&category=${isCategory.nombre_categoria_producto}&subcategory=${value.id}`;
            this.getCopyLink(link);
          } else {
            const link = `https://${this.storeData.subdominio}.komercia.${suffix}/wa/${this.storeData.id}?page=1&category=${value.nombre_categoria_producto}`;
            this.getCopyLink(link);
          }
        }
      } else {
        if (this.storeData.informacion_tienda[0].dominio) {
          if (this.hasSubcategory(value)) {
            const link = `${this.storeData.informacion_tienda[0].dominio}/productos?page=1&category=${isCategory.nombre_categoria_producto}&subcategory=${value.id}`;
            this.getCopyLink(link);
          } else {
            const link = `${this.storeData.informacion_tienda[0].dominio}/productos?page=1&category=${value.nombre_categoria_producto}`;
            this.getCopyLink(link);
          }
        } else {
          if (this.hasSubcategory(value)) {
            const link = `https://${this.storeData.subdominio}.komercia.${suffix}/productos?page=1&category=${isCategory.nombre_categoria_producto}&subcategory=${value.id}`;
            this.getCopyLink(link);
          } else {
            const link = `https://${this.storeData.subdominio}.komercia.${suffix}/productos?page=1&category=${value.nombre_categoria_producto}`;
            this.getCopyLink(link);
          }
        }
        // }
      }
      setTimeout(() => {
        this.$set(value, "activeLink", false);
      }, 1500);
    },
    hasSubcategory(category) {
      if (category.categoria) {
        return true;
      } else {
        return false;
      }
    },
    getCopyLink(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message.success("Se ha copiado la Url");
    },
    widgetCloudinary(type) {
      this.isLoading = true;
      this.myUploadWidget = cloudinary.openUploadWidget(
        this.cloudinaryWidgetOptions(
          "categories",
          "komercia-store",
          "instagram_photos"
        ),
        (error, result) => {
          this.isLoading = false;
          this.afterUpload(error, result, type);
        }
      );
      this.myUploadWidget.open();
      setTimeout(() => {
        this.loadingSavePhotoMain = false;
      }, 7000);
    },
    async afterUpload(error, result, value) {
      if (result.event === "success") {
        if (value == 1) {
          if (this.category.picture) {
            const { success } = await this.deleteImageCloudinary(
              this.category.picture,
              "categories"
            );
            if (success) {
              this.category.picture = result.info.url;
              this.category.idpicture = result.info.public_id;
            }
          } else {
            this.category.picture = result.info.url;
            this.category.idpicture = result.info.public_id;
          }
          this.updateCategory();
        } else if (value == 2) {
          if (this.subCategory.picture) {
            const { success } = await this.deleteImageCloudinary(
              this.subCategory.picture,
              "categories"
            );
            if (success) {
              this.subCategory.picture = result.info.url;
              this.subCategory.idpicture = result.info.public_id;
            }
          } else {
            this.subCategory.picture = result.info.url;
            this.subCategory.idpicture = result.info.public_id;
          }
          this.updateSubcategory();
        }
        this.$message.success(
          "Imagen cargada correctamente, guarda los cambios"
        );
        this.myUploadWidget.close();
      }
    },
    async deleteImageCloudinary(image, type) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${this.$store.state.configKomercia.url}/api/admin/cloudinary/delete`,
          headers: this.$store.state.configAxios.headers,
          data: {
            image: this.getIdCloudinary(image),
            tipo: type,
            template: this.storeData.template,
          },
        });
        if (data && data.estado === 200) {
          return { success: true, data: data };
        }
      } catch (err) {
        console.log("Error delete cloudinary", err.response.data);
      }
    },
    closeCategory() {
      this.$store.state.modalCategory = false;
    },
  },
};
</script>

<style scoped>
.darkbgs {
  background-color: #1b2836;
}
.darkbgs2 {
  background-color: #283c51;
}
.lightbgs {
  background-color: #fff;
}
.darktxts {
  color: #fff;
}
.lighttxts {
  color: rgb(26, 46, 59);
}
.wrapper-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-top: 10px;
}
.color-radio >>> .el-radio.is-bordered.is-checked {
  border-color: #4429b4;
}
.color-radio >>> .el-radio__input.is-checked + .el-radio__label {
  color: #4429b4;
}
.color-radio >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #4429b4;
  background: #4429b4;
}
.wrapper-content-items {
  width: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.content-header-items {
  padding: 20px 15px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  @apply w-full flex flex-row justify-between items-center;
}
.wrapper-content-title {
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
/* .content-wrapper {
  background-color: var(--background);
} */
.wrapper-box {
  /* padding: 20px; */
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-column-gap: 20px;
}
.content-list-items {
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  max-height: 700px;
  overflow-y: auto;
  @apply w-full;
}
.content-list-items::-webkit-scrollbar {
  -webkit-appearance: none;
}
.content-list-items::-webkit-scrollbar:vertical {
  width: 11px;
}
.content-list-items::-webkit-scrollbar-button:increment,
.content-list-items::-webkit-scrollbar-button {
  display: none;
}
.content-list-items::-webkit-scrollbar:horizontal {
  height: 10px;
}
.content-list-items::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}
.categories ul {
  margin: 0;
  padding: 0;
}
.categories li {
  list-style: none;
}
.wrapper-btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-size: 13px;
}
.btnCategories {
  background: transparent;
  border: 2px solid #f1f1f1;
  border-radius: 8px;
  padding: 8px 12px;
}
.btnCategories:hover {
  border: 2px solid var(--purple);
}
.wrapper_img {
  display: flex;
  width: 100%;
  justify-content: center;
}
.img_autor {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  max-width: 30px;
  max-height: 30px;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.categories_header {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1.2fr 1fr 1fr;
  /*justify-items: center;*/
  border-bottom: 1px solid #f1f1f1;
}
.categories_header p {
  font-weight: 600;
}
.categories_header p:nth-child(2) {
  text-align: center;
}
.categories_header p:nth-child(3) {
  text-align: center;
}
.categories_header p:last-child {
  text-align: right;
}
/* .categories_list {
  border: 1px solid #dadada;
} */
.categories_empty {
  height: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  @apply flex flex-col justify-center items-center;
}
.categories_item {
  /* border-top: 1px solid #dadada; */
  /*padding: 10px 10px;*/
  padding-bottom: 0;
}
.categories_item:first-child {
  border-style: none;
}
.categories_item_row {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1.2fr 1fr 1fr;
  padding: 10px;
  height: 50px;
}
.categories_item_row.selected {
  background-color: #f1f1f1;
}
.categories_item_row_text {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  position: relative;
  padding-left: 21px;
  justify-self: start;
  align-items: center;
  font-weight: 600;
}
.categories_item_row_text::before {
  position: absolute;
  top: 8px;
  left: 0px;
  width: 12px;
  height: 12px;
  background-color: var(--green);
  border-radius: 50%;
  content: "";
}
.categories_item_row > p {
  text-align: center;
}
.categories_item_actions {
  justify-self: end;
  margin-right: 10px;
}
.subcategories_item {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 0.5fr 2fr 1.2fr 1.8fr 1.2fr;
  /*border-top: 1px solid #e9ecef;*/
}
.subcategories_item::before {
  position: absolute;
  top: -20px;
  left: 5px;
  content: "";
  width: 30px;
  height: 40px;
  border-left: 1px solid var(--purple);
  border-bottom: 1px solid var(--purple);
}
.subcategories_list {
  padding: 0 10px !important;
}
.subcategories_list.list0 .subcategories_item::before {
  top: -10px;
  height: 30px;
}
.subcategories_item_text {
  position: relative;
  padding: 10px 0;
  padding-left: calc(21px + 42px);

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-self: start;
}
.subcategories_item_text::before {
  position: absolute;
  top: 13px;
  left: 42px;
  width: 12px;
  height: 12px;
  background-color: var(--green);
  border-radius: 50%;
  content: "";
}
.subcategories_item_quantity {
  padding: 10px 0;
  text-align: center;
  justify-content: center;
  display: flex;
  /*border-top: 1px solid black;*/
}
.categories_item_actions,
.subcategories_item_actions {
  justify-self: end;
  margin-right: 10px;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  color: #9b9ea1;
}

/* RIGHT COLUMN */

.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.categories_actions {
  position: sticky;
  top: 20px;
  /* height: 300px; */
  display: grid;
  grid-row-gap: 10px;
  align-content: start;
}
.categories_actions_create {
  width: 100%;
  padding: 15px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 9%);
  /* border: solid 2px #f3f4f6; */
  @apply w-full flex flex-col;
}
.subcategories_actions_create {
  display: grid;
  grid-template-areas:
    "se se"
    "in bt";
  grid-gap: 10px;
  /* border: solid 2px #f3f4f6; */
  /* padding: 10px; */
  /* border-radius: 9px; */
}
.subcategories_actions_create .el-select {
  grid-area: se;
}
.subcategories_actions_create .el-input {
  grid-area: in;
}
.subcategories_actions_create .el-button {
  grid-area: bt;
}
.link-copy {
  cursor: pointer;
}
.link-copy:hover {
  font-weight: bold;
}
.wrapper-link {
  width: 100%;
  text-align: center;
}
@media (max-width: 1366px) {
  .wrapper-box {
    padding: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
  .categories {
    grid-row: 2;
  }
  .categories_actions {
    position: relative;
    height: initial;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: 1;
    grid-column-gap: 20px;
    margin-bottom: 20px;
  }
  .wrapper-content-items {
    padding: 20px 10px !important;
    overflow-y: auto;
    max-height: 670px;
  }
  .wrapper-btn {
    margin-bottom: 5px;
  }
  .wrapper-content-items::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .wrapper-content-items::-webkit-scrollbar:vertical {
    width: 11px;
  }
  .wrapper-content-items::-webkit-scrollbar-button:increment,
  .wrapper-content-items::-webkit-scrollbar-button {
    display: none;
  }
  .wrapper-content-items::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  .wrapper-content-items::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
}
@media (max-width: 1080px) {
  .categories_actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
  .wrapper-content-items {
    max-height: 650px;
  }
  .wrapper-box {
    padding: 0px;
  }

  .content-list-items {
    padding: 20px 15px;
  }
}
</style>
