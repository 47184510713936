import axios from "axios";

export default {
  namespaced: true,
  state: {
    //store
    dataSalesCount: {},
    dataSalesProfits: {},
    dataProductSales: {},
    dataProductProfits: {},
    dataProductUnits: {},
    dataClients: {},
    dataClientsOrders: {},
  },
  mutations: {
    SET_SALES_COUNT(state, value) {
      state.dataSalesCount = value;
    },
    SET_SALES_PROFITS(state, value) {
      state.dataSalesProfits = value;
    },
    SET_PRODUCT_SALES(state, value) {
      state.dataProductSales = value;
    },
    SET_PRODUCT_PROFITS(state, value) {
      state.dataProductProfits = value;
    },
    SET_PRODUCT_UNITS(state, value) {
      state.dataProductUnits = value;
    },
    SET_CLIENTS(state, value) {
      state.dataClients = value;
    },
    SET_CLIENTS_ORDERS(state, value) {
      state.dataClientsOrders = value;
    },
  },
  actions: {
    async GET_SALES_COUNT({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/sales`,
          headers: rootState.configAxios.headers,
          params: {
            filter_date: params.filter_date || null,
            day_start: params.day_start || null,
            day_end: params.day_end || null,
            year: params.year || null,
            month: params.month || null,
            group_by: params.group_by || null,
            status: params.status || null,
          },
        });
        if (data) {
          commit("SET_SALES_COUNT", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_SALES_PROFITS({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/sales-profits`,
          headers: rootState.configAxios.headers,
          params: {
            filter_date: params.filter_date || null,
            month: params.month || null,
            year: params.year || null,
            day_start: params.day_start || null,
            day_end: params.day_end || null,
            group_by: params.group_by || null,
            status: params.status || null,
          },
        });
        if (data) {
          commit("SET_SALES_PROFITS", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_PRODUCT_SALES({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/products-sales`,
          headers: rootState.configAxios.headers,
          params: {
            filter_date: params.filter_date || null,
            day_start: params.day_start || null,
            day_end: params.day_end || null,
            year: params.year || null,
            month: params.month || null,
            status: params.status || null,
          },
        });
        if (data) {
          commit("SET_PRODUCT_SALES", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_PRODUCT_PROFITS({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/products-profits`,
          headers: rootState.configAxios.headers,
          params: {
            filter_date: params.filter_date || null,
            month: params.month || null,
            year: params.year || null,
            day_start: params.day_start || null,
            day_end: params.day_end || null,
            status: params.status || null,
          },
        });
        if (data) {
          commit("SET_PRODUCT_PROFITS", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_PRODUCT_UNITS({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/products-units`,
          headers: rootState.configAxios.headers,
          params: {
            filter_date: params.filter_date || null,
            month: params.month || null,
            year: params.year || null,
            day_start: params.day_start || null,
            day_end: params.day_end || null,
            status: params.status || null,
          },
        });
        if (data) {
          commit("SET_PRODUCT_UNITS", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },

    async GET_CLIENTS({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/clients`,
          headers: rootState.configAxios.headers,
          params: {
            search_by: params.search_by || null, // name, email, identification
            search_term: params.search_term || null,
            city: params.city || null, // id ciudad
            sort_by: params.sort_by || null, // name, sales o profits
            page: params.page || null, // pagina
            per_page: params.per_page || null, // cuantas quiero mostrar
          },
        });
        if (data) {
          commit("SET_CLIENTS", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_CLIENTS_ORDERS({ commit, rootState }, params) {
      // tengo que tener un buscador de clientes para retornar el id del cliente
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomercia3}/api/store/${params.storeId}/stats/client/${params.userID}/orders`,
          headers: rootState.configAxios.headers,
          params: {
            search_by: params.search_by || null, // name, email, identification
            status: params.status || null, // status de orden
            sort_by: params.sort_by || null, // data o profits
            page: params.page || null, // pagina
            per_page: params.per_page || null, // cuantas quiero mostrar
          },
        });
        if (data) {
          commit("SET_CLIENTS_ORDERS", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
  },
};
