<template>
  <div
    :class="{
      products__item: true,
      draggable__item: true,
      box: true,
      sold_out: !data.con_variante && !data.inventario,
    }"
    @click="mouseover"
  >
    <div class="product-item-thumbnail">
      <img
        loading="lazy"
        :src="idCloudinaryAwards(data.foto_cloudinary, 150, 150)"
        class="img-responsive"
        alt="product image"
      />
    </div>
    <div class="product-item__details">
      <h4 class="product-item_title">{{ data.nombre }}</h4>
      <span class="h5 product-item-price" v-if="data.con_variante == 1">
        {{ $t("text_ventas_producto_variantes") }}
      </span>
      <span class="h5 product-item-price" v-else>
        {{ data.precio | formatCurrency(idCountryStore) }}
      </span>
      <transition name="slide-fade"></transition>
      <div class="product-item__actions">
        <template v-for="(variant, index) in variantes">
          <div
            class="input__area"
            v-if="data.con_variante"
            :key="`variante_${index}`"
          >
            <p>{{ variant.nombre }}:</p>
            <select v-model="combination[index]">
              <option
                v-for="(option, index) in variant.valores"
                :key="`option.${option.option}.${index}`"
                :value="option.option"
              >
                {{ option.option }}
              </option>
            </select>
          </div>
        </template>
        <el-button
          v-if="selectedCombination"
          type="primary"
          :disabled="!selectedCombination.unidades"
          @click="addProduct"
        >
          {{ selectedCombination.unidades ? "Agregar" : "Agotado" }}
        </el-button>
        <el-button
          v-else
          type="primary"
          :disabled="
            !!data.con_variante && combination.length !== variantes.length
          "
          @click="addProduct"
        >
          Agregar
        </el-button>
      </div>
      <!-- <div class="col-xs-6 text-right">
        <span class="h5 product-item-price" v-if="data.con_variante">Producto con variantes</span>
        <template v-else>
          <span class="h5 product-item-price">{{ data.inventario }}</span>
        </template>
      </div>-->
    </div>
  </div>
</template>

<script>
import formatCurrency from "@/mixins/formatCurrent";
import idCloudinaryAwards from "@/mixins/idCloudinary";
export default {
  mixins: [formatCurrency, idCloudinaryAwards],
  name: "ProductCard",
  props: {
    data: Object,
    inactive: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    window.addEventListener("click", () => {
      this.collapseActions = false;
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", () => {
      this.collapseActions = false;
    });
  },
  data() {
    return {
      collapseActions: false,
      combination: [],
    };
  },
  computed: {
    variantes() {
      try {
        if (this.data?.variantes?.length) {
          return JSON.parse(this.data.variantes[0].variantes);
        }
      } catch (err) {
        return [];
      }
      return [];
    },
    combinaciones() {
      if (this.variantes?.length) {
        return JSON.parse(
          this.data.variantes[0].combinaciones[0].combinaciones
        );
      }
      return [];
    },
    selectedCombination() {
      return this.combinaciones.find(
        (combination) =>
          JSON.stringify(combination.combinacion) ===
          JSON.stringify(this.combination)
      );
    },
    subdomain() {
      return this.$store.state.storeData.subdominio;
    },
  },
  methods: {
    mouseover() {
      setTimeout(() => {
        this.collapseActions = true;
      }, 100);
    },
    addProduct() {
      const { id, nombre, foto_cloudinary, precio, con_variante, inventario } =
        this.data;
      const params = {
        id,
        nombre,
        foto_cloudinary,
        precio,
        con_variante,
        cantidad: 1,
        inventario,
      };
      if (this.combination?.length) {
        params.combinacion = this.selectedCombination;
        params.precio = this.selectedCombination.precio;
        params.inventario = +this.selectedCombination.unidades;
      }
      this.$store.dispatch("pos/ADD_PRODUCT", params);

      this.combination = [];
    },
  },
};
</script>

<style scoped>
.products__item {
  position: relative;
  max-width: 250px;
  width: 100%;
  display: grid;
  align-content: space-between;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  padding: 3px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.product-item-thumbnail {
  position: relative;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  z-index: 9;
  border-radius: 4px;
}
.product-item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.products__item .row {
  padding: 10px 0;
  margin: 0 10px;
}
.product-item_title {
  color: #474d68;
}
.product-item-actions {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid #e8ebf0;
}
.product-item-actions.two_columns {
  grid-template-columns: repeat(2, 1fr);
}
.product-item-actions .action {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: #d7d7d7;
}
.product-item-actions .action ~ .action {
  border-left: 1px solid #e8ebf0;
}
.product-item:hover .product-item-actions .action {
  color: #474d68;
}
.product-item-actions .action:hover {
  background-color: #e8ebf0;
}
.product-item__details {
  padding: 5px;
}
.product-item__actions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  opacity: 0;
  padding: 5px;
  grid-gap: 3px;
  display: grid;
  /* align-content: center; */
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.956);
  align-items: flex-end;
  transition: all 0.4s;
}
.products__item:hover .product-item__actions {
  top: 0;
  bottom: 0;
  opacity: 1;
}
.input__area {
  height: 100%;
  padding: 0px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input__area p {
  font-size: 10px;
  margin-bottom: 3px;
}
.input__area select {
  width: 100%;
}
.input__area select {
  display: block;
  font-size: 12px;
  font-family: sans-serif;
  color: #444;
  line-height: 1;
  padding: 5px 8px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid rgba(170, 170, 170, 0.781);
  border-radius: 3px;
  background-color: #fff;
}
.input__area select:hover {
  border-color: #888;
}
.input__area select:focus {
  border-color: #aaa;
  color: #222;
  outline: none;
}
.input__area select option {
  font-weight: normal;
  padding: 10px;
}
.sold_out {
  cursor: initial;
}
.sold_out .product-item-thumbnail,
.sold_out .product-item__details {
  opacity: 0.4;
}
.sold_out .product-item__actions {
  display: none;
}
.sold_out::after {
  position: absolute;
  top: 20px;
  right: 0;
  content: "Agotado";
  color: #fff;
  font-size: 13px;
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px 0 0 3px;
  background-color: #f14b5a;
  z-index: 9;
}
</style>
