<template>
  <div
    class="wrapper-content-information prices mb-4"
    :class="darkMode ? 'darkbg' : 'lightbg'"
  >
    <h2
      class="text-xl font-bold mb-3"
      :class="darkMode ? 'darktxt' : 'lighttxt'"
    >
      Descuento simulado del producto
    </h2>
    <!-- simular descuento -->
    <div class="row">
      <div>
        <div class="input-area-full">
          <p
            class="text-subtitle mb-2"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            {{ $t("text_inventario_deseaAplicarDescuento") }}
          </p>
          <div class="radio-ckecked" style="margin-top: 8px">
            <el-radio v-model="data.info[0].tag_promocion" label="0" border>
              {{ $t("text_inventario_no") }}
            </el-radio>
            <el-radio v-model="data.info[0].tag_promocion" label="1" border>
              {{ $t("text_inventario_si") }}
            </el-radio>
          </div>
        </div>
        <div
          v-if="data.info[0].tag_promocion == 1"
          class="input-area-full"
          style="margin-top: 15px"
        >
          <p
            class="text-subtitle mb-2"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            {{ $t("text_inventario_ValorPorcentaje") }}
          </p>
          <el-input
            v-model="data.info[0].promocion_valor"
            :placeholder="$t('text_inventario_ValorPorcentaje')"
            type="number"
            :max="99"
            :min="0"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          >
            <i
              slot="suffix"
              class="el-icon-question el-input__icon"
              @click="
                handleHelp(
                  'Valor del porcentaje',
                  'Este porcentaje se aplica al valor del producto'
                )
              "
            ></i>
          </el-input>
          <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
            <strong
              class="text-subtitle"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_nota") }}
            </strong>
            {{ $t("text_inventario_notaMsg") }}
          </p>
        </div>
      </div>
      <div v-if="data.info[0].tag_promocion == 1" class="input-area-full">
        <div class="wrapper-items-discount">
          <div class="flex-shrink-0">
            <img
              loading="lazy"
              class="h-32 w-32 rounded object-contain"
              :src="idCloudinaryAwards(data.detalle.foto_cloudinary, 400, 400)"
              alt="Foto card"
            />
          </div>
          <div class="flex-1 min-w-0">
            <div>
              <!-- <span class="absolute inset-0" aria-hidden="true"></span> -->
              <p
                v-if="data.detalle.nombre"
                class="text-sm font-medium text-black"
              >
                {{ data.detalle.nombre }}
              </p>
              <p
                v-if="
                  data.detalle.precio &&
                  data.info[0].tag_promocion == 1 &&
                  data.info[0].promocion_valor
                "
                class="text-sm text-gray-500 truncate line-through mt-2"
              >
                {{
                  data.info[0].tag_promocion == 1 &&
                  data.info[0].promocion_valor
                    ? Math.trunc(
                        data.detalle.precio /
                          (1 - data.info[0].promocion_valor / 100)
                      )
                    : 0 | currency
                }}
              </p>
              <p
                v-else-if="
                  precioCombinaciones &&
                  data.info[0].tag_promocion == 1 &&
                  data.info[0].promocion_valor
                "
                class="text-sm text-gray-500 truncate line-through mt-2"
              >
                {{
                  data.info[0].tag_promocion == 1 &&
                  data.info[0].promocion_valor
                    ? Math.trunc(
                        precioCombinaciones /
                          (1 - data.info[0].promocion_valor / 100)
                      )
                    : 0 | currency
                }}
              </p>
              <div class="flex items-start">
                <p
                  v-if="data.detalle.precio"
                  class="text-2xl text-black truncate"
                >
                  {{ data.detalle.precio | currency }}
                </p>
                <p v-else class="text-2xl text-black truncate">
                  {{ precioCombinaciones | currency }}
                </p>
                <p
                  v-if="
                    data.info[0].tag_promocion == 1 &&
                    data.info[0].promocion_valor
                  "
                  class="text-xs text-green-500 ml-1.5 mt-1"
                >
                  {{ data.info[0].promocion_valor }}% OFF
                </p>
              </div>
              <div
                v-if="data.detalle.envio_gratis != 0"
                class="content_buy_action"
              >
                <p class="card-info-2">
                  <svg
                    class="transporte-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M18 18.5C18.83 18.5 19.5 17.83 19.5 17C19.5 16.17 18.83 15.5 18 15.5C17.17 15.5 16.5 16.17 16.5 17C16.5 17.83 17.17 18.5 18 18.5M19.5 9.5H17V12H21.46L19.5 9.5M6 18.5C6.83 18.5 7.5 17.83 7.5 17C7.5 16.17 6.83 15.5 6 15.5C5.17 15.5 4.5 16.17 4.5 17C4.5 17.83 5.17 18.5 6 18.5M20 8L23 12V17H21C21 18.66 19.66 20 18 20C16.34 20 15 18.66 15 17H9C9 18.66 7.66 20 6 20C4.34 20 3 18.66 3 17H1V6C1 4.89 1.89 4 3 4H17V8H20M3 6V15H3.76C4.31 14.39 5.11 14 6 14C6.89 14 7.69 14.39 8.24 15H15V6H3M5 10.5L6.5 9L8 10.5L11.5 7L13 8.5L8 13.5L5 10.5Z"
                    />
                  </svg>
                  {{ $t("text_inventario_envioGratis") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- descuento real -->
    <div class="row">
      <div>
        <!-- <div class="input-area-full">
          <p
            class="text-subtitle mb-2"
            :class="darkMode  ? 'darktxt' : 'lighttxt'"
          >
            {{ $t('text_inventario_deseaAplicarDescuentoreal') }}
          </p>
          <div class="radio-ckecked" style="margin-top: 8px;">
            <el-radio v-model="info.tag_iva" :label="0" border>{{
              $t('text_inventario_no')
            }}</el-radio>
            <el-radio v-model="info.tag_iva" :label="1" border>{{
              $t('text_inventario_si')
            }}</el-radio>
          </div>
        </div> -->
        <div
          v-if="info.tag_iva == 1"
          class="input-area-full"
          style="margin-top: 15px"
        >
          <p
            class="text-subtitle mb-2"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            {{ $t("text_inventario_ValorPorcentaje") }}
          </p>
          <el-input
            v-model="info.promocion_iva"
            :placeholder="$t('text_inventario_ValorPorcentaje')"
            type="number"
            :max="99"
            :min="0"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          >
            <i
              slot="suffix"
              class="el-icon-question el-input__icon"
              @click="
                handleHelp(
                  'Valor del porcentaje',
                  'Este porcentaje se aplica al valor del producto'
                )
              "
            ></i>
          </el-input>
        </div>
      </div>
      <div v-if="info.tag_iva == 1" class="input-area-full">
        <div class="wrapper-items-discount">
          <div class="flex-shrink-0">
            <img
              loading="lazy"
              class="h-32 w-32 rounded object-contain"
              :src="idCloudinaryAwards(data.detalle.foto_cloudinary, 400, 400)"
              alt="Foto card"
            />
          </div>
          <div class="flex-1 min-w-0">
            <div>
              <!-- <span class="absolute inset-0" aria-hidden="true"></span> -->
              <p
                v-if="data.detalle.nombre"
                class="text-sm font-medium text-black"
              >
                {{ data.detalle.nombre }}
              </p>
              <p
                v-if="data.detalle.precio"
                class="text-sm text-gray-500 truncate line-through mt-2"
              >
                {{ data.detalle.precio | currency }}
              </p>
              <p
                v-else
                class="text-sm text-gray-500 truncate line-through mt-2"
              >
                {{ precioCombinaciones | currency }}
              </p>
              <div class="flex items-start">
                <p
                  v-if="
                    data.detalle.precio &&
                    info.tag_iva == 1 &&
                    info.promocion_iva
                  "
                  class="text-2xl text-black truncate"
                >
                  {{
                    info.tag_iva == 1 && info.promocion_iva
                      ? Math.trunc(
                          data.detalle.precio -
                            (data.detalle.precio * info.promocion_iva) / 100
                        )
                      : 0 | currency
                  }}
                </p>
                <p
                  v-else-if="
                    precioCombinaciones &&
                    info.tag_iva == 1 &&
                    info.promocion_iva
                  "
                  class="text-2xl text-black truncate"
                >
                  {{
                    info.tag_iva == 1 && info.promocion_iva
                      ? Math.trunc(
                          precioCombinaciones -
                            (precioCombinaciones * info.promocion_iva) / 100
                        )
                      : 0 | currency
                  }}
                </p>
                <p
                  v-if="info.tag_iva == 1 && info.promocion_iva"
                  class="text-xs text-green-500 ml-1.5 mt-1"
                >
                  {{ info.promocion_iva }}% OFF
                </p>
              </div>
              <div
                v-if="data.detalle.envio_gratis != 0"
                class="content_buy_action"
              >
                <p class="card-info-2">
                  <svg
                    class="transporte-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M18 18.5C18.83 18.5 19.5 17.83 19.5 17C19.5 16.17 18.83 15.5 18 15.5C17.17 15.5 16.5 16.17 16.5 17C16.5 17.83 17.17 18.5 18 18.5M19.5 9.5H17V12H21.46L19.5 9.5M6 18.5C6.83 18.5 7.5 17.83 7.5 17C7.5 16.17 6.83 15.5 6 15.5C5.17 15.5 4.5 16.17 4.5 17C4.5 17.83 5.17 18.5 6 18.5M20 8L23 12V17H21C21 18.66 19.66 20 18 20C16.34 20 15 18.66 15 17H9C9 18.66 7.66 20 6 20C4.34 20 3 18.66 3 17H1V6C1 4.89 1.89 4 3 4H17V8H20M3 6V15H3.76C4.31 14.39 5.11 14 6 14C6.89 14 7.69 14.39 8.24 15H15V6H3M5 10.5L6.5 9L8 10.5L11.5 7L13 8.5L8 13.5L5 10.5Z"
                    />
                  </svg>
                  {{ $t("text_inventario_envioGratis") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- descuento real -->
    <br />
    <h2
      class="text-xl font-bold mb-3"
      :class="darkMode ? 'darktxt' : 'lighttxt'"
    >
      Precio del producto (Variantes)
    </h2>
    <div class="tabs">
      <div
        :class="{ tabs_item: true, selected: selectedTab == 0 }"
        @click="selectTab(0)"
      >
        <div class="tabs_item_status"></div>
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_productoVariantes") }}
        </p>
      </div>
      <div style="width: 1px; height: 60px; background-color: #f0f2f3"></div>
      <div
        :class="{ tabs_item: true, selected: selectedTab == 1 }"
        @click="selectTab(1)"
      >
        <div class="tabs_item_status"></div>
        <p
          class="flex items-center text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_ventas_producto_variantes") }}
          <sliderInformation :numberId="32" :actions="true" class="ml-2" />
        </p>
      </div>
    </div>
    <div v-if="selectedTab == 0" class="box-body">
      <div class="grid">
        <div class="input-area-full">
          <p
            class="text-subtitle mb-2"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            {{ $t("text_ventas_precio") }}
            <i
              slot="suffix"
              class="el-icon-question el-input__icon"
              @click="handleHelp('Precio', 'Precio del producto')"
            >
            </i>
          </p>
          <div class="el-input">
            <money
              v-model="data.detalle.precio"
              v-bind="money"
              class="money-idTienda"
              :class="darkMode ? 'darks-money' : 'light-money'"
            >
            </money>
          </div>
        </div>
        <div class="input-area-full">
          <p
            class="text-subtitle mb-2"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            {{ $t("text_inventario_referencia") }}
          </p>
          <el-input
            v-model="data.info[0].sku"
            :placeholder="$t('text_inventario_SKU')"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          >
            <i
              slot="suffix"
              class="el-icon-question el-input__icon"
              @click="handleHelp('Referencia', 'Referencia del producto')"
            >
            </i>
          </el-input>
        </div>
        <div class="input-area-full">
          <p
            class="text-subtitle mb-2"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            {{ $t("text_ventas_unidades") }}
          </p>
          <el-input
            v-model="data.info[0].inventario"
            :placeholder="$t('text_ventas_unidades')"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          >
            <i
              slot="suffix"
              class="el-icon-question el-input__icon"
              @click="handleHelp('Unidades', 'Unidades del producto')"
            >
            </i>
          </el-input>
        </div>
      </div>
      <div class="card">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_infoVariantes") }}
          <strong> {{ $t("text_inventario_porEjemplo") }}</strong>
          {{ $t("text_inventario_infoVariantes2") }}
        </p>
        <el-button type="primary" @click="selectTab(1)">
          {{ $t("text_inventario_crearVariantes") }}
        </el-button>
      </div>
    </div>
    <div v-else class="w-full pt-10 px-2">
      <product-variants :data="data" />
    </div>
  </div>
</template>

<script>
import ProductVariants from "../ProductVariants.vue";
import setFormatMoney from "../../mixins/formatCurrent";
import idCloudinary from "../../mixins/idCloudinary";
import sliderInformation from "@/components/information.vue";
export default {
  components: {
    ProductVariants,
    sliderInformation,
  },
  filters: {
    currency(value) {
      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
  },
  mixins: [setFormatMoney, idCloudinary],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      precioCombinaciones: "",
      info: {
        tag_iva: false,
        promocion_iva: "",
      },
    };
  },
  computed: {
    selectedTab() {
      if (
        this.$store.state.productData &&
        this.$store.state.productData.detalle
      ) {
        return this.$store.state.productData.detalle.con_variante;
      } else {
        return "";
      }
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  watch: {
    data() {
      this.setFormatMoney(this.idCountryStore);
    },
  },
  created() {
    this.setFormatMoney(this.idCountryStore);
    this.tempPriceCom();
  },

  methods: {
    selectTab(active) {
      this.$emit("change", { active: active });
    },
    handleHelp(title, description) {
      this.$alert(description, title);
    },
    tempPriceCom() {
      if (
        this.data &&
        this.data.combinaciones &&
        this.data.combinaciones.combinaciones
      ) {
        const combinationSelected = JSON.parse(
          this.data.combinaciones.combinaciones
        );
        if (combinationSelected && combinationSelected.length > 0) {
          this.precioCombinaciones = combinationSelected[0].precio;
        }
      }
    },
  },
};
</script>

<style scoped>
.wrapper-content-information {
  width: 100%;
  padding: 23px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.money-idTienda >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  padding: 5px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #f3f4f6;
}
.light-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #1f2937;
}

.text-subtitle {
  font-size: 13px;
  font-weight: 500;
}
.darkbg {
  background-color: #293d53;
}
.lightbg {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.grid {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.input-area-full {
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area-full .el-input,
.input-area-full .quillWrapper,
.input-area-full .el-select {
  width: 100%;
}
.tabs {
  width: 100%;
  height: 60px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1px 1fr;
  justify-items: center;
}
.tabs div:nth-child(2) {
  background-color: transparent !important;
}
.tabs_item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
}
.tabs_item_status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
}
.tabs_item p {
  margin: 0;
}
.tabs_item.selected {
  border: solid 2px #4429b4;
}
.tabs_item.selected .tabs_item_status {
  background-color: #4429b4;
  border: 1px solid white;
}
.prices .box-body {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
}
.prices .grid {
  width: 260px;
  display: grid;
}
.prices .card {
  width: 200px;
  display: grid;
  justify-content: center;
}
.row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 5fr;
  grid-gap: 10px;
}
.wrapper-items-discount {
  border: solid 2px #f3f4f6;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  @apply relative px-3 py-2 shadow-sm flex items-start space-x-3;
}
.content_buy_action {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.card-info-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #35dd8d;
  padding: 1px 4px;
  border-radius: 5px;
  color: black;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  min-height: 24px;
  max-height: 24px;
}
.transporte-icon {
  fill: black;
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .prices .box-body {
    justify-content: space-between;
    padding: 10px;
    display: flex;
  }
  .prices .grid {
    display: flex;
    width: 50%;
    justify-content: center;
    padding-right: 10px;
  }
  .prices .card {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 20px;
  }
  .prices .card p {
    margin-bottom: 10px;
  }
  .prices .card .el-button {
    white-space: break-spaces;
  }
  .tabs_item {
    padding-left: 10px;
  }
  .tabs_item_status {
    max-width: 12px;
    width: 100%;
    max-height: 12px;
    height: 100%;
  }
  .tabs_item p {
    word-break: break-word;
  }
  .input-area-full {
    max-width: unset;
    width: 100%;
    margin: 5px 0px;
  }
}
@media (max-width: 411px) {
  .prices .card {
    display: none;
  }
  .prices .grid {
    width: 100%;
    padding-right: 0px;
  }
}
</style>
