import axios from "axios";
// import * as moment from 'moment'
import { uuid } from "vue-uuid";

// eslint-disable-next-line no-unused-vars
const URL_MP_DEV = "https://api-v2.dev.mpr.mipaquete.com";
// eslint-disable-next-line no-unused-vars
const URL_MP_PROD = "https://api-v2.mpr.mipaquete.com";
const URL_MP = URL_MP_PROD;

const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA;

export default {
  namespaced: true,
  state: {
    currentIndexStage: 0,
    quotationState: null,
    stages: [
      {
        name: "Auth",
      },
      {
        name: "All",
      },
      {
        name: "Quotation",
      },
      {
        name: "QuotationResult",
      },
      {
        name: "CreateService",
      },
      {
        name: "Package",
      },
    ],
    token: null,
    user: null,
    dataKomercia: null,
    dataMipaquete: null,
    services: null,
    servicesKomercia: null,
    towns: null,
    quotationPayload: null,
    calculatedService: null,
    packagingOptions: null,
    currentCourier: {
      delivery_company_id: 0,
    },
  },
  mutations: {
    NEXT_STAGE(state) {
      state.currentIndexStage += 1;
    },
    PREVIOUS_STAGE(state) {
      state.currentIndexStage -= 1;
    },
    SET_INDEX_STAGE(state, value) {
      state.currentIndexStage = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_USER_KOMERCIA(state, value) {
      state.dataKomercia = value;
    },
    SET_SERVICES(state, value) {
      state.services = value;
    },
    SET_SERVICES_KOMERCIA(state, value) {
      state.servicesKomercia = value;
    },
    SET_TOWN(state, value) {
      state.towns = value;
    },
    SET_QUOTATIONPAYLOAD(state, value) {
      state.quotationPayload = value;
    },
    SET_CALCULATED_SERVICE(state, value) {
      state.calculatedService = value;
    },
    SET_PACKAGING_OPTIONS(state, value) {
      state.packagingOptions = value;
    },
    SET_MESSAGING_OPTIONS(state, value) {
      state.messagingOptions = value;
    },
    SET_CURRENTCOURIER(state, value) {
      state.currentCourier = value;
    },
    SET_QUOTATION_STATE(state, value) {
      state.quotationState = value;
    },
    SET_GET_DEFAULT_DATA_KOMERCIA(state, value) {
      state.dataMipaquete = value;
    },
  },
  getters: {
    currentStage(state) {
      return state.stages[state.currentIndexStage];
    },
  },
  actions: {
    async AUTHENTICATION({ commit }, { email, password }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_MP}/generateapikey`,
          data: {
            email,
            password,
          },
          headers: {
            "session-tracker": uuid.v4(),
          },
        });
        if (data.APIKey) {
          commit("SET_TOKEN", data.APIKey);
        }
        return { success: true, data: data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async SET_DATA_KOMERCIA({ rootState }, { email, password, token }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete/credenciales`,
          headers: rootState.configAxios.headers,
          data: {
            username: email,
            pass: password,
            token: token,
          },
        });
        if (data) {
          // console.log(data.mensaje)
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_DATA_KOMERCIA({ rootState, commit }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete/credenciales`,
          headers: rootState.configAxios.headers,
        });
        if (data && data.data) {
          commit("SET_TOKEN", data.data.token);
          commit("SET_USER_KOMERCIA", data.data);
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_USER_INFORMATION({ commit, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_MP}/getUser`,
          headers: {
            "session-tracker": uuid.v4(),
            apikey: state.token,
          },
        });
        if (data) {
          commit("SET_USER", data);
        }
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_TOWN({ state, commit }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_MP}/getLocations`,
          headers: {
            apikey: state.token,
            "session-tracker": uuid.v4(),
          },
        });
        if (data) {
          commit("SET_TOWN", data);
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_SERVICES({ state, commit, dispatch }, value) {
      try {
        const { data } = await axios({
          method: "POST",
          headers: {
            apikey: state.token,
            "session-tracker": uuid.v4(),
          },
          data: {
            user: state.user._id,
            pageSize: 10,
          },
          url: `${URL_MP}/getSendings/${value}`,
        });

        commit("SET_SERVICES", data);
        return { success: true, data: data };
      } catch (err) {
        dispatch("VALIDATE_AUTHENTICATION", err.response.status);
        return { success: false, data: err.response.data };
      }
    },
    async GET_SERVICES_KOMERCIA({ rootState, commit }, value) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete?page=${value}`,
          headers: rootState.configAxios.headers,
        });
        if (data && data.data) {
          commit("SET_SERVICES_KOMERCIA", data.data);
        }
        return { success: true, data: data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    // PACKAGING OPTIONS
    async GET_PACKAGING_OPTIONS({ commit, state }, payload) {
      commit("SET_QUOTATIONPAYLOAD", payload);
      let requestPayload = {
        originLocationCode: payload.origin,
        destinyLocationCode: payload.destiny,
        width: payload.width,
        height: payload.height,
        length: payload.large,
        weight: payload.weight,
        declaredValue: payload.declaredValue,
        quantity: payload.quantity,
        saleValue: payload.paymentType == 102 ? payload.saleValue : 0,
      };
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_MP}/quoteShipping`,
          data: requestPayload,
          headers: {
            apikey: state.token,
            "session-tracker": payload.tracker,
          },
        });
        commit("SET_PACKAGING_OPTIONS", data);
        commit("SET_CURRENTCOURIER", data[0]);
        return { success: true, data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async CREATE_SERVICE({ state, commit }, payload) {
      let requestPayload = {
        sender: payload.sender,
        receiver: payload.receiver,
        locate: {
          originDaneCode: state.quotationPayload.origin,
          destinyDaneCode: state.quotationPayload.destiny,
        },
        productInformation: {
          quantity: state.quotationPayload.quantity,
          width: state.quotationPayload.width,
          large: state.quotationPayload.large,
          height: state.quotationPayload.height,
          weight: state.quotationPayload.weight,
          forbiddenProduct: true, // si no se envían articulos prohibidos el parametro se configura como true
          declaredValue: state.quotationPayload.declaredValue,
        },
        channel: "komercia",
        deliveryCompany: state.currentCourier.deliveryCompanyId, // id de la transportadora
        comments: payload.comments ? payload.comments : "Sin comentarios",
        description: payload.description, // descripción del producto
        paymentType: state.quotationPayload.paymentType
          ? state.quotationPayload.paymentType
          : 101,
        valueCollection:
          state.quotationPayload.paymentType == 102
            ? state.quotationPayload.valueCollection
            : 0,
        requestPickup: false, // si desea solicitar servicio de recolección del paquete en dirección origen
        adminTransactionData: {
          saleValue:
            state.quotationPayload.paymentType == 102
              ? state.quotationPayload.saleValue
              : 0, //valor de la venta del producto a enviar (aplica para servicio de pago contraentrega, si no se coloca 0)
        },
      };
      try {
        const { data } = await axios({
          headers: {
            apikey: state.token,
            "session-tracker": uuid.v4(),
          },
          method: "POST",
          url: `${URL_MP}/createSending`,
          data: requestPayload,
        });
        commit("SET_QUOTATION_STATE", null);
        commit("SET_QUOTATIONPAYLOAD", null);
        commit("SET_PACKAGING_OPTIONS", null);
        commit("SET_MESSAGING_OPTIONS", null);
        commit("SET_CURRENTCOURIER", null);
        // dispatch('EDIT_SERVICE_KOMERCIA', data)
        return { success: true, data: data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async CREATE_SERVICE_KOMERCIA({ rootState }, value) {
      // console.log(value)
      try {
        axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete`,
          headers: rootState.configAxios.headers,
          data: {
            uuid: value.miPaqueteResponse.mpCode,
            carrito: value.orderId,
          },
        });
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async EDIT_SERVICE_KOMERCIA({ rootState }, value) {
      try {
        axios({
          method: "PUT",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete/${value.value_id}`,
          headers: rootState.configAxios.headers,
          data: {
            uuid: value.miPaqueteResponse.mpCode,
            carrito: value.orderId,
          },
        });
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATE_DEFAULT_DATA_KOMERCIA(
      { rootState },
      { width, length, height, weight, originCity, state }
    ) {
      try {
        const { data } = axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete/base`,
          headers: rootState.configAxios.headers,
          data: {
            width: width,
            length: length,
            height: height,
            weight: weight,
            originCity: originCity,
            state: state,
          },
        });
        if (data) {
          return { success: true, data: data.data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_DEFAULT_DATA_KOMERCIA({ commit, rootState }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_KOMERCIA_API}/api/admin/envios-mipaquete/base`,
          headers: rootState.configAxios.headers,
        });
        if (data && data.data) {
          commit("SET_GET_DEFAULT_DATA_KOMERCIA", data.data);
          return { success: true, data: data.data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    VALIDATE_AUTHENTICATION({ commit }) {
      // localStorage.removeItem('Komercia_mipaquete_token')
      commit("SET_INDEX_STAGE", 1);
    },
  },
};
