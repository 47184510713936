import axios from "axios";

export default {
  namespaced: true,
  state: {
    allNotifications: [],
    infoInformation: {
      page: 1,
      total: 1,
    },
    CountPendingNotifications: null,
  },
  mutations: {
    SET_ALL_NOTIFICATIONS(state, value) {
      state.allNotifications = value;
    },
    SET_COUNT_PENDING_NOTIFICATIONS(state, value) {
      state.CountPendingNotifications = value;
    },
  },
  actions: {
    async GET_All_NOTIFICATIONS({ commit, state, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/v1/notifications`,
          headers: rootState.configAxios.headers,
          params: {
            page: params.page || null,
            limit: params.limit || null,
            storeId: params.storeId || null,
            order: "DESC",
          },
        });
        if (data?.notifications) {
          state.infoInformation.page = data.page;
          state.infoInformation.total = data.total;
          if (params.page > 1) {
            commit("SET_ALL_NOTIFICATIONS", [
              ...state.allNotifications,
              ...data.notifications,
            ]);
          } else {
            commit("SET_ALL_NOTIFICATIONS", data.notifications);
          }
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_COUNT_PENDING_NOTIFICATIONS({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/v1/notifications/count-pending-notifications/${params.storeId}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          commit("SET_COUNT_PENDING_NOTIFICATIONS", data.pendingNotifications);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async PUT_MARK_READ({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaAWS}/api/v1/notifications/mark-as-read`,
          headers: rootState.configAxios.headers,
          params: {
            id: params.id || null,
            storeId: params.storeId || null,
          },
        });
        if (data) {
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async PUT_MARK_ALL_READ({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaAWS}/api/v1/notifications/mark-all-as-read/${params.storeId}`,
          headers: rootState.configAxios.headers,
        });
        return {
          success: true,
          data: data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
  },
};
