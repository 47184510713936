<template>
  <!-- <transition name="el-zoom-in-top"> -->
  <div class="content-wrapper w-full">
    <div class="content-gif-ref" :class="darkMode ? 'bg-dark' : 'bg-lights'">
      <div>
        <img
          loading="lazy"
          class="image-gif w-50"
          src="https://res.cloudinary.com/brahyanr10/image/upload/c_scale,w_180,h_180/v1629690034/Panel-Komercia/Grupo_53_3x_sjhuit.png"
          alt=""
        />
      </div>
      <div class="title pl-10">
        <h2 class="titel-gif" :class="darkMode ? 'darktxtssa' : 'lighttxtssa'">
          <span class="strong-txt">¡Gana!</span> recomendado a Komercia
        </h2>
        <p :class="darkMode ? 'darktxt' : 'lighttxt'">
          <span :class="darkMode ? 'darkboldtxt' : 'lightboldtxt'">
            Recomienda Komercia
          </span>
          a tus amigos y recibe premios
          <span :class="darkMode ? 'darkboldtxt' : 'lightboldtxt'">
            ¡gratis!
          </span>
        </p>
        <p :class="darkMode ? 'darktxt' : 'lighttxt'" class="mt-5">
          Copie y envié su enlace de referencia único o comparte tu
          <strong>
            código referido
            {{
              userData && userData.user_info && userData.user_info.length > 0
                ? userData.user_info[0].id_user
                : userData.id
            }}
          </strong>
        </p>
        <div class="content-url">
          <div class="inputcopy rounded-full">
            <input
              v-model="urlCopy"
              type="text"
              autocomplete="off"
              placeholder
              class="urlreferredcopy bg-transparent"
              :class="darkMode ? 'darkinput' : 'lightinput'"
              disabled
            />
            <div id="buttoncopy" class="btn" @click="saveUrl()">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17">
                <g id="Grupo_9" data-name="Grupo 9" transform="translate(.265)">
                  <g
                    id="Rectángulo_13"
                    data-name="Rectángulo 13"
                    class="cls-1"
                    transform="translate(-.265)"
                  >
                    <rect class="cls-2" width="14" height="13" rx="2" />
                    <rect
                      class="cls-3"
                      x=".5"
                      y=".5"
                      width="13"
                      height="12"
                      rx="1.5"
                    />
                  </g>
                  <g
                    id="Rectángulo_12"
                    data-name="Rectángulo 12"
                    class="cls-1"
                    transform="translate(3.735 4)"
                  >
                    <rect class="cls-2" width="14" height="13" rx="2" />
                    <rect
                      class="cls-3"
                      x=".5"
                      y=".5"
                      width="13"
                      height="12"
                      rx="1.5"
                    />
                  </g>
                </g>
              </svg>
              <p v-if="copia" class="ml-2">Copiar Link</p>
              <p v-else class="ml-2">Copiado!</p>
            </div>
          </div>
          <div class="social-media-ico">
            <p
              class="share-txt mr-4"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              Comparte por
              <span :class="darkMode ? 'darkboldtxt' : 'lightboldtxt'">
                WhatsApp
              </span>
            </p>
            <div class="iconsmedia">
              <a
                :href="`https://wa.me/?text= Te invito a conocer Komercia, la plataforma donde puedes crear tu tienda online y vender por internet: ${encodeURIComponent(
                  urlCopy
                )}`"
                target="_blank"
              >
                <div class="whatsapp">
                  <whatsApp />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="listReferral"
      class="wrapper-content-items box mt-7"
      :class="darkMode ? 'darks' : 'lights'"
      style="margin-bottom: 28px"
    >
      <div class="title-sales">
        <h2
          class="flex flex-row justify-start items-center sub-title"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          Tienes
          <div class="content-num-referred">
            <span class="num-referred">
              {{
                listReferral && listReferral.data && listReferral.data.length
                  ? listReferral.data.length
                  : 0
              }}
            </span>
          </div>
          referidos
          <sliderInformation :numberId="86" :actions="true" class="ml-2" />
        </h2>
      </div>
      <br />
      <div :class="darkMode ? 'darks-tablet' : 'light-tablet'">
        <el-table
          class="rounded-md"
          :data="listReferral.data"
          stripe
          lazy
          border
          style="auto"
          :default-sort="{ prop: 'tienda_info', order: 'descending' }"
        >
          <el-table-column sortable prop="tienda_info" label="ID" width="70">
          </el-table-column>
          <el-table-column prop="email_tienda" label="Correo">
          </el-table-column>
          <el-table-column label="Nombre">
            <template slot-scope="scope">
              <p>{{ scope.row.tienda.nombre }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Plan" width="130">
            <template slot-scope="scope">
              <el-tag
                :type="
                  getPlan(scope.row.tienda.tipo) !== 'Premium' ? 'info' : null
                "
              >
                {{ getPlan(scope.row.tienda.tipo) }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="wrapper-pagination">
        <el-pagination
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="listReferral.last_page"
          :current-page="currentPage"
          @current-change="getReferidos"
        >
        </el-pagination>
      </div>
      <router-link
        to="/awards#1"
        class="txt-refer-link"
        :class="darkMode ? 'darktxtsrefer' : 'lighttxtsrefer'"
      >
        Reclama tus premios 🎁
      </router-link>
    </div>
    <div
      class="wrapper-content-items questions-ref"
      :class="darkMode ? 'darks' : 'lights'"
    >
      <h2 class="sub-title mb-5" :class="darkMode ? 'darktxt' : 'lighttxt'">
        Preguntas Frecuentes
      </h2>
      <el-collapse
        v-model="activePanel"
        class="border-none"
        :class="darkMode ? 'darks-collapse' : 'light-collapse'"
        accordion
      >
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Qué son los referidos?"
          name="1"
        >
          <div class="px-4 pt-4 overflow-hidden">
            El término “referido” hace referencia a toda aquella persona que se
            haya registrado y pagado alguno de los planes de Komercia desde el
            respectivo enlace de referidos de la persona que realizó la
            invitación, puede ser código (numérico) o enlace de referido.<br />
            Solo cuenta como referida aquella persona que haya accedido a algún
            plan vigente en Komercia, por lo cual, debe estar seguro que su
            referido haya hecho el pago del plan correspondiente antes de
            solicitar su beneficio ante algún asesor.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="Mi enlace (URL) de referidos para compartir"
          name="2"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Cada cliente de Komercia encontrará en el Panel de Control de su
            tienda su correspondiente enlace de referidos, ubicado en la barra
            lateral izquierda/ Mi perfil/ Referidos. Solo contará el beneficio
            si su referido se registra desde el enlace anteriormente mencionado
            y adquiere alguna de las membresías de pago para su tienda.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="Beneficios por referir"
          name="3"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Puedes ganar premios dependiendo de la cantidad de referidos que
            tengas. Los enviaremos a la puerta de casa o donde prefieras. Puedes
            encontrar desde artículos para hogar, tecnología, smartphones hasta
            viajes con todo incluido. <br />
            Ver premios
            <a href="https://panel.komercia.co/awards"
              >https://panel.komercia.co/awards</a
            >
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Cuántos premios puedo recibir?"
          name="4"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Depende del número de referidos que tengas. Si tienes una gran
            cantidad de referidos puedes cambiar un premio muy grande o varios
            pequeños, no hay límite, puedes hacerlo varias veces cada que tengas
            referidos.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Cómo reclamo mi premios?"
          name="5"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Al momento de verificar que su referido se haya vinculado
            correctamente con las instrucciones anteriormente mencionadas, podrá
            comunicarse con un asesor para redimir su beneficio, para la
            facilidad del proceso debe tener la siguiente información a su
            disposición: <br /><br />
            - Nombre y enlace de su tienda en Komercia <br />- Nombre y enlace
            de la tienda de su referido <br />- Plan en el cual se encuentra
            suscrito <br /><br />
            Se debe dirigir al chat de atención al cliente con la información
            anterior y solicitar su beneficio a la persona que lo asista en este
            proceso, en cuestión de minutos tendrá a su disposición su beneficio
            de acuerdo a lo mencionado en este documento. <br />La cuenta del
            referido y la suya deberán estar activas en plan de pago para que
            sea aplicable el sistema de referidos.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Debo pagar algo para participar en el programa de referidos?"
          name="6"
        >
          <div class="px-4 pt-4 overflow-hidden">
            No, participar en nuestro programa de referidos es gratis. Queremos
            que ganes por recomendarnos y trabajemos en equipo. Al momento de
            reclamar tu premio, si deberás tener una cuenta de pago activa sea
            mensual o anual.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Debo tener una membresía activa para referir? "
          name="7"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Si, se debe tener una membresía activa para acceder al panel de
            referidos, compartir link y reclamar premios. Para nosotros es
            importante que conozcas las plataformas y seas usuarios activos, de
            esa forma si refieres es por que te has llevado una buena
            experiencia y quieres que otras personas se unan.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Debo pagar algo por recibir los premios en mi domicilio?"
          name="8"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Depende del lugar donde vivas y el premio que vayamos a enviar. En
            algunos casos el usuario debe pagar una parte o totalidad del envío.
            En el chat de soporte puedes consultar toda la información.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Cómo puedo ver mis referidos?"
          name="9"
        >
          <div class="px-4 pt-4 overflow-hidden">
            En el panel de control de tu tienda en la sección de referidos,
            aparece una tabla con tus referidos, cantidad y el estado (Si ha
            pagado). Recuerda que solo cuentan los referidos que han adquirido
            alguna membresía de Komercia.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Cuáles de mis referidos aplican a premios?"
          name="10"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Todos los usuarios que se registraron y crearon una cuenta con tu
            enlace de referido que compartiste o código de referido, a su vez
            estos usuarios pagaron alguna suscripción a Komercia de por lo menos
            dos meses.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="Quiero cambiar mi premio por dinero"
          name="11"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Es posible que recibas el dinero a tu cuenta bancaria si así lo
            prefieres, depende del premio que quieres reclamar, haremos una
            propuesta por medio de nuestro chat de soporte. En caso de aceptar
            la propuesta, enviaremos el dinero en un plazo de 8 días hábiles.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Cual es el límite de personas que puedo referir?"
          name="12"
        >
          <div class="px-4 pt-4 overflow-hidden">
            No hay límite, puede referir todas las personas que quieras y
            cambiar tu referidos por premios cuantas veces quieras o acumularlos
            para obtener los mejores premios.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Mis referidos vencen después de un tiempo sin reclamarlos?"
          name="13"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Si, tus referidos luego de 12 meses vencen contando la fecha de
            registro del referido. Debes reclamarlos antes de ese tiempo o
            escribir al chat de soporte si quieres solicitar un plazo por alguna
            condición especial.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="No hay un premio que quiera, ¿puedo sugerir alguno?"
          name="14"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Si, por nuestro chat de soporte puedes sugerir premios que te
            gustaría que aparezcan como recompensa por tus referidos. Nuestro
            equipo evaluará tu recomendación para ver si es viable publicarlo en
            el listado de premios.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Cómo obtengo más referidos?"
          name="15"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Puedes invitar amigos, familia, compañeros del trabajo, usar redes
            sociales. Todas las estrategias son válidas y te recomendamos ser
            muy creativo y honesto con tus referidos, para que se lleven una
            buena experiencia dentro de Komercia.
            <br />
            Algunos usuarios utilizan estrategias de marketing digital, grupos
            de WhatsApp o incluso en sus charlas o redes sociales tienen publicó
            su link de referidos. Recuerda que no es suficiente con que tu
            referido se registró, debe adquirir alguna membresía, si necesitas
            ayuda de nuestro equipo de soporte para asesorar tus referidos no
            dudes en escribirnos.
          </div>
        </el-collapse-item>
        <el-collapse-item
          class="rounded-md overflow-hidden border-r border-l mb-2"
          title="¿Mi enlace de referido es por mi usuario por tienda?
"
          name="16"
        >
          <div class="px-4 pt-4 overflow-hidden">
            Si una tienda tiene múltiples usuarios, el enlace de referido hace
            referencia solo al usuario que había iniciado sesión en ese momento
            y compartió el enlace. Los referidos de ese usuario aparecerán en su
            panel de control, pero no a los otros usuarios de la misma
            tienda.<br />Cada usuario tiene su propio código y referidos, sin
            importar si son administradores de la misma tienda.
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import whatsApp from "../../../icons/whatsapp.vue";
import sliderInformation from "@/components/information.vue";
export default {
  name: "listReferred",
  components: {
    whatsApp,
    sliderInformation,
  },
  data() {
    return {
      activePanel: "1",
      showmore: false,
      copia: true,
      listReferral: [],
      currentPage: 1,
      urlCopy: "",
    };
  },
  computed: {
    ...mapState(["storeData", "userData", "darkMode"]),
    // urlcopy() {
    //   return `https://login.komercia.co/registrarse?l=${this.storeData.informacion_tienda[0].lenguaje}&c=${this.storeData.informacion_tienda[0].pais.codigo}&r=${this.userData.id}`
    // },
  },
  watch: {
    storeData() {
      this.setUrl();
    },
    userData() {
      this.setUrl();
    },
  },
  created() {
    this.$store.dispatch("GET_USER_DATA");
    this.getReferidos();
    this.setUrl();
  },
  methods: {
    setUrl() {
      if (this.storeData && this.userData) {
        this.urlCopy = `https://login.komercia.co/registrarse?l=${
          this.storeData.informacion_tienda[0].lenguaje
        }&c=${this.storeData.informacion_tienda[0].pais.codigo}&r=${
          this.userData &&
          this.userData.user_info &&
          this.userData.user_info.length > 0
            ? this.userData.user_info[0].id_user
            : this.userData.id
        }`;
      }
    },
    saveUrl() {
      this.getCopyLink();
      this.copia = false;
    },
    getCopyLink() {
      const el = document.createElement("textarea");
      el.value = this.urlCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    getReferidos(page = 1) {
      axios
        .get(
          `${this.$store.state.configKomercia.url}/api/usuario/expert?page=${page}`,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.listReferral = response.data.tiendas;
        });
    },
    getPlan(id) {
      switch (id) {
        case 0:
          return "Emprendedor";
        case 1:
          return "Emprendedor";
        case 3:
          return "Premium";
        case 8:
          return "Premium";
        case 9:
          return "Premium";
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.content-num-referred {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding-bottom: 3px;
  @apply bg-purple-komercia-50 mx-2 flex justify-center items-center box-border relative;
}
.num-referred {
  top: 20%;
  border-radius: 10px;
  line-height: 1;
  @apply m-auto text-white font-bold text-base absolute;
}
.accordion {
  /* background-color: #eee; */
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  font-size: 17px;
  transition: 0.4s;
  border: 1px solid #d7d8d8;
  @apply rounded-md flex justify-between items-center;
}
.wrapper-content-items {
  width: 100%;
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.accordion:hover {
  background-color: rgba(204, 204, 204, 0.387);
}
.accordion:after {
  content: "\002B";
  color: #00d57e;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 20px;
}
.active:after {
  content: "\2212";
  font-size: 20px;
}
.panel {
  /* padding: 0 18px; */
  /* background-color: white; */
  font-size: 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  @apply my-2 rounded-lg;
}
.dark {
  background-color: #121a23;
}
.light {
  background-color: #f5f7fa;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  @apply text-gray-800;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.darktxtssa {
  color: #0bd4c1;
}
.darktxtsrefer {
  color: #0bd4c1;
  border-color: #0bd4c1;
}
.lighttxtssa {
  color: #4429b4;
}
.lighttxtsrefer {
  color: #4429b4;
  border-color: #4429b4;
}
.darkboldtxt {
  color: #aa94ff;
}
.lightboldtxt {
  color: #000;
  font-weight: 900;
}
.bg-dark {
  background-color: #344a63;
}
.bg-lights {
  background-color: #f3f4f6;
}
.darkpane {
  background-color: #344a63;
  color: #fff;
}
.lightpane {
  background-color: #f3f4f6;
  color: #333;
}
.darkinput {
  color: rgb(202, 202, 202);
}
.lightinput {
  color: rgb(108, 108, 108);
}
.lightboldtxtss {
  color: #5e6ddd;
}
.darks-tablet >>> .el-table th.el-table__cell {
  background-color: #344a63;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}

.darks-collapse >>> .el-collapse-item__header {
  color: white;
  background-color: #344a63 !important;
}
.light-collapse >>> .el-collapse-item__header {
  color: black;
  background-color: #f3f4f6 !important;
}

.questions-ref {
  padding: 50px;
}
.content-gif-ref {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  @apply w-full flex flex-row shadow-md rounded-md;
}

.texturl {
  /* padding: 50px; */
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  grid-gap: 25px;
}
.asideleft {
  padding: 0px 10px;
}
.asideright {
  padding: 20px;
  color: #000;
  border-radius: 5px;
  border: 1px solid #d8dce5;
  /* border-radius: 10px; */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
#love {
  height: auto;
  width: 100px;
}
.linkrefer {
  margin-bottom: 15px;
}
.piggy {
  margin-bottom: 15px;
}
.offer {
  margin-bottom: 15px;
}
.funciona {
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
}
.funciona h1 {
  font-size: 20px;
  font-weight: 900;
  /* color: black; */
}
.cosas {
  display: grid;
  margin-top: 30px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  @apply w-full;
}
.card {
  display: flex;
}
.card h2 {
  font-weight: 500;
  font-size: 18px;
}
.card p {
  font-weight: 500;
  line-height: 15px;
}
.socialnet {
  display: grid;
  justify-items: center;
  justify-content: center;

  text-align: center;
  padding: 30px;
}
.socialnet a {
  display: grid;
  justify-items: center;
  font-weight: 500;
}
.moresocial {
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000bd;
  top: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-items: center;
}
.contentsocial {
  width: 500px;
  height: 500px;
  background-color: #ffffff;
}
.social-media-ico {
  @apply w-full flex flex-row justify-start items-center;
}
.share-txt {
  font-size: 16px;
}
.iconsmedia {
  display: grid;
  grid-template-columns: repeat(4, 50px);
}
.fab {
  background-color: #0084ff;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}
.fas {
  width: 40px;
  height: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 30px;
  /* border:1px solid black; */
  cursor: pointer;
}
.fa-times {
  margin: 5px;
}
.fa-facebook-f {
  background-color: #074f93;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
}
.fa-twitter {
  background-color: #0084ff;
  font-size: 25px;

  color: #fff;
}
.fa-facebook-messenger {
  color: #fff;
}
.cls-1 {
  fill: #fff;
  stroke: #1f2937;
}
.cls-2 {
  stroke: none;
}
.cls-3 {
  fill: none;
}

.inputcopy {
  border: 1px solid #4429b4;
  display: grid;
  grid-template-columns: 1fr 130px;
  /* border-radius: 5px; */
  max-width: 530px;
  align-content: center;
}
.urlreferredcopy:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.content-wrapper {
  min-height: 100vh;
  /* padding-top: 30px; */
  margin-right: auto;
  margin-left: auto;
  background: transparent;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
}
.content-info {
  position: relative;
  border-radius: 5px;
  /* background: #fff; */
  margin-bottom: 28px;
  width: 100%;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.titel-gif {
  font-size: 20px;
  @apply mb-2;
}
.strong-txt {
  font-weight: 900;
  font-size: 24px;
}
.txt-subtx {
  font-size: 18px;
}
.bold-txt {
  font-weight: 900;
  color: rgb(0, 0, 0);
}
.title {
  /* margin-bottom: 10px; */
  width: auto;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}
.title p {
  font-size: 15px;
  line-height: 20px;
  /* font-weight: 400; */
  /* color: black; */
  /* margin-bottom: 10px; */
}
.title h1 {
  font-size: 20px;
  /* font-weight: 700; */
  color: #4429b4;
  align-self: flex-start;
  margin-bottom: 20px;
}
.whatsapp {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  cursor: pointer;
}
.txt-refer-link {
  font-size: 14px;
  /* border-color: #4429b4; */
  /* color: #4429b4; */
  @apply w-auto flex flex-row justify-center items-center font-semibold mt-6 px-4 py-4 border rounded-md shadow-md transition duration-300;
}
.txt-refer-link:hover {
  /* background-color: #4429b4; */
  transform: translateY(-5%);
  box-shadow: 0 4px 6px -1px rgba(99, 101, 241, 0.537);
  /* color: #fff; */
  /* border-color: #4429b4; */
  @apply transition duration-300 border;
}
#buttoncopy {
  border: none;
  height: 48px;
  width: 130px;
  cursor: pointer;
  color: #0bd4c1;
  font-weight: 600;
  background-color: #4429b4;
  @apply flex flex-row justify-center items-center rounded-r-full;
}
#buttonsend {
  border: none;
  height: 48px;
  width: 80px;
  background-color: #9ce4bc;
  border-radius: 5px;
  cursor: pointer;
}
.content-url {
  margin-top: 10px;
  display: grid;
  grid-template-rows: 50px 50px;
  grid-gap: 10px;
}
.urlreferred {
  border: 0.5px solid #c5c0c0;
  height: 48px;
  width: 400px;
  border-radius: 5px;
}
.urlreferredcopy {
  height: 48px;
  border: none;
  outline: none;
  box-shadow: none;
  /* width: 400px; */
}
.box {
  padding: 50px;
  @apply overflow-x-auto;
}
.title-table {
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .content-url {
    grid-template-columns: auto;
  }
  .contentsocial {
    width: 450px;
    height: 450px;
  }
  .texturl {
    grid-template-columns: 1fr;
  }
}
</style>
