<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: "LogoutIndex",
  mounted() {
    document.cookie = `authData=; domain = komercia.co; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    window.location.href = `https://login.komercia.co`;
    console.log(2);
  },
  created() {
    document.cookie = `authData=; domain = komercia.co; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    window.location.href = `https://login.komercia.co`;
    console.log(1);
  },
};
</script>
