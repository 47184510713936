var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-services box"},[(_vm.packagingOptions && _vm.packagingOptions.length > 0)?_c('div',{staticClass:"wrapper"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":18,"offset":3}},[_c('p',{staticClass:"title"},[_vm._v("Selecciona un servicio para tu envío")])])],1),_c('br'),(this.$route.query.pre_envio == 'true')?_c('div',{staticClass:"mx-10 mb-5"},[_vm._m(0)]):_vm._e(),(_vm.packagingOptions)?_c('div',{staticClass:"couriers w-full"},_vm._l((_vm.packagingOptions),function(item){return _c('li',{key:item.deliveryCompanyId,class:{
          couriers__item: true,
          selected:
            item.deliveryCompanyId == _vm.currentCourier.deliveryCompanyId,
        },attrs:{"gutter":20},on:{"click":function($event){return _vm.selectCarrier(item)}}},[_c('div',{staticClass:"col wrapper-image"},[_c('img',{attrs:{"loading":"lazy","src":item.deliveryCompanyImgUrl
                ? item.deliveryCompanyImgUrl
                : require('@/assets/mipaquete/placeholder.png')}})]),_c('el-col',[_c('p',{staticClass:"bold"},[_vm._v(_vm._s(item.deliveryCompanyName))]),_c('p',[_vm._v(_vm._s(item.officeAddress))])]),_c('el-col',[_c('p',[_vm._v("Calificación")]),_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(item.score),callback:function ($$v) {_vm.$set(item, "score", $$v)},expression:"item.score"}})],1),_c('el-col',[_c('p',[_vm._v("Precio Envío")]),_c('p',{staticClass:"bold"},[_vm._v(_vm._s(_vm._f("currency")(item.shippingCost))+" COP")])]),_c('el-col',{staticClass:"couriers__item__status"},[_c('i',{staticClass:"el-icon-circle-check"})])],1)}),0):_vm._e(),_c('el-row',{attrs:{"gutter":30}},[(
          _vm.quotationPayload.paymentType == 102 &&
          _vm.quotationPayload.addShippingCost
        )?_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"summary summary__general justify-content-center"},[_c('div',{staticStyle:{"max-width":"300px"}},[_c('h1',[_vm._v("Calculadora de envío")]),_c('p',{staticClass:"text-info"},[_vm._v(" Conoce el valor real del envío que vas a realizar. Como especificaste anteriormente, sumaremos el valor del envío al valor que vamos a recaudar en tu pago contraentrega ")])]),_c('div',{staticClass:"mx-12"},[_c('p',[_c('strong',{staticClass:"mr-2"},[_vm._v("Valor de la venta: ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.quotationPayload.saleValue))+" ")]),_c('p',[_c('strong',{staticClass:"mr-2"},[_vm._v("Valor del envío: ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.currentCourier.shippingCost))+" ")]),_c('p',[_c('strong',{staticClass:"mr-2"},[_vm._v("Costo total del envío:")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.collectionValue))+" ")])])])]):_vm._e()],1),_c('div',{staticClass:"my-6 quotation_result__actions"},[_c('el-button',{nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v("Volver")]),_c('el-button',{staticClass:"bg-purple-komercia-50 px-4 py-1.5 ml-3 rounded-md text-gray-100",attrs:{"disabled":!_vm.currentCourier.deliveryCompanyId},nativeOn:{"click":function($event){return _vm.createQuotation.apply(null, arguments)}}},[_vm._v(" Siguiente ")])],1)],1):_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"w-full flex flex-col justify-center items-center my-10 px-5"},[_c('img',{staticClass:"h-20 mb-5",attrs:{"loading":"lazy","src":require("@/assets/mipaquete/logo-mi-paquete-color.png")}}),_c('p',{staticClass:"text-center font-medium text-xl"},[_vm._v(" No se han encontrado transportadoras para las opciones de tu envío. Inténtalo nuevamente. ")]),_c('div',{staticClass:"my-6 quotation_result__actions"},[_c('el-button',{nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v("Volver")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-green-komercia-600"},[_c('strong',[_vm._v("Nota:")]),_vm._v(" El cliente selecciono esa transportadora por preferencia ")])
}]

export { render, staticRenderFns }