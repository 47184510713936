<template>
  <div
    class="w-full lg:max-w-screen-lg xl:max-w-screen-xl rounded-xl"
    style="padding: 30px 20px"
    :class="darkMode ? 'dark' : 'light'"
  >
    <section>
      <div class="title-sales" :class="darkMode ? 'darks' : 'lights'">
        <h2
          class="flex items-center font-semibold text-lg"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_suscriptores_title") }}
          <sliderInformation :numberId="73" :actions="true" class="ml-2" />
        </h2>
        <el-button
          style="max-height: 40px"
          type="button"
          class="inline-flex w-48 justify-center max-w-xl items-center px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-100 bg-indigo-700 hover:bg-indigo-200"
          @click="openMultiplesProducts('export')"
        >
          Exportar Suscriptores
        </el-button>
      </div>
      <div
        :class="darkMode ? 'darks-tablet' : 'light-tablet'"
        class="table-container"
      >
        <el-table
          :data="subscribers"
          stripe
          lazy
          style="width: 100%"
          :default-sort="{ prop: 'fecha', order: 'descending' }"
        >
          <el-table-column prop="email" :label="$t('text__buzon_eMail')">
          </el-table-column>
          <el-table-column :label="$t('text_suscriptores_fechaSuscripcion')">
            <template slot-scope="scope">
              {{ formatCalendarMoment(scope.row.created_at) }} -
              {{ formatDateMoment(scope.row.updated_at) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <MultiplesProductsDialog
        :visible.sync="dialogVisible"
        :suscriptores="subscribers"
        :tabAction.sync="tabAction"
      />
    </section>
  </div>
</template>

<script>
import formatDateMoment from "@/mixins/formatDateMoment";
import formatCalendarMoment from "@/mixins/formatCalendarMoment";
import MultiplesProductsDialog from "@/components/MultiplesSuscriptores";
import sliderInformation from "@/components/information.vue";
export default {
  name: "SubscribersTable",
  components: {
    MultiplesProductsDialog,
    sliderInformation,
  },
  mixins: [formatDateMoment, formatCalendarMoment],
  data() {
    return {
      dialogVisible: false,
      tabAction: "export",
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    subscribers() {
      return this.$store.state.subscribers.reverse();
    },
  },
  created() {
    this.$store.dispatch("GET_SUBSCRIBERS");
  },
  methods: {
    openMultiplesProducts(value) {
      this.dialogVisible = true;
      this.tabAction = value;
    },
  },
};
</script>

<style scoped>
.darks-tablet >>> .el-table th.el-table__cell {
  background-color: #344a63;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
</style>
<style lang="scss" scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #1b2836;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.state {
  width: 120px;
  display: flex;
  align-items: center;
  color: #1d1c1c;
  font-weight: 500;
  font-size: 12px;
  border-radius: 5px;
}
.bg {
  width: 30px;
  height: 30px;
  color: #4c4c4c;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  margin: 0 10px 0 0;
}
.tag-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  font-weight: 600;
  text-align: center;
  border-radius: 20px;
  padding: 7px 15px;
  font-size: 12px;
}
.table-container > div {
  font-weight: 500;
}
.title-sales {
  display: flex;
  width: 100%;
  padding: 10px 10px 20px;
  @apply justify-between;
}
// .title-sales h2 {
//   color: #4c4c4c;
// }
.box-body {
  padding: 0px 30px 40px 30px;
}
li {
  border-bottom: 0px solid #eee;
  position: relative;
  padding: 15px 10px 15px 10px;
  position: relative;
  list-style: none;
  a {
    padding: 10px 0 !important;
    color: #000 !important;
    text-indent: -40px;
    margin-left: 25px;
    display: block;
    i {
      color: #ccc;
      font-size: 10px !important;
      position: relative;
      left: -5px;
    }
    small {
      text-align: right;
      background: #fff;
      color: #ccc;
      border: 1px solid #ddd;
      padding: 3px 7px;
      border-radius: 15px;
      margin: 0 0 0 7px;
      font-size: 10px;
    }
    img {
      width: 95%;
      padding: 0;
      border-radius: 4px;
      margin: 10px 0;
    }
  }
}
</style>
