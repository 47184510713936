<template>
  <div class="w-full p-6 shadow-lg">
    <h1>Cubiko iframe</h1>
  </div>
</template>

<script>
export default {
  name: "CubikoLogin",
  created() {
    this.getUrlParams();
  },
  methods: {
    getUrlParams(url) {
      console.log(url);
      return `${url}?`
        .split("?")[1]
        .split("&")
        .reduce(
          (params, pair) =>
            ((key, val) => (key ? { ...params, [key]: val } : params))(
              ...`${pair}=`.split("=").map(decodeURIComponent)
            ),
          {}
        );
    },
  },
};
</script>
