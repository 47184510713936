<template>
  <el-tooltip effect="dark" content="Ayuda Komercia">
    <button class="relative" @click="actionSelector()">
      <!-- <span
        class="animate-ping top-[1px] left-[0px] absolute inline-flex w-[16px] h-[16px] rounded-full bg-[#FAC918] opacity-75"
      ></span> -->
      <img
        loading="lazy"
        class="w-[18px]"
        src="../assets/information.webp"
        alt="ImgInformation"
      />
    </button>
  </el-tooltip>
</template>

<script>
export default {
  name: "SliderInformation",
  props: {
    actions: {
      type: Boolean,
      required: true,
    },
    numberId: {
      type: Number,
      default: null,
    },
    informationHelp: {
      type: Object,
      default: null,
    },
  },
  methods: {
    actionSelector() {
      this.actions
        ? this.handleSidebarHelp(this.numberId)
        : this.handleHelp(this.informationHelp);
    },
    handleSidebarHelp(value) {
      this.$store.state.miscSidebar.isActive = true;
      this.$store.state.miscSidebar.isHelpdesk = false;
      this.$store.state.miscSidebar.data = value;
    },
    handleHelp(value) {
      if (value?.title && value?.description) {
        this.$alert(value.description, value.title);
      }
    },
  },
};
</script>
