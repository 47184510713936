<template>
  <div
    class="w-full rounded-xl px-[40px] xl:px-[60px] py-[15px]"
    :class="darkMode ? 'bg-[#1b2836]' : 'bg-white'"
    style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09)"
  >
    <div class="w-full flex flex-col justify-center items-center py-6">
      <div
        class="w-full max-w-[650px] grid grid-cols-3 gap-x-3 justify-center items-center"
      >
        <button
          class="w-auto flex flex-col justify-center items-center text-base rounded-l-xl py-3 transition-all ease-in-out delay-75"
          :class="
            selectOpt == 1
              ? 'text-[#00d57e] bg-[#4429b4]'
              : ' text-[#919191] bg-[#f3f4f6]'
          "
          @click="selectOptMethod(1)"
        >
          {{ $t("text_opc_pagos") }}
        </button>
        <button
          class="w-auto flex flex-col justify-center items-center text-base py-3 transition-all ease-in-out delay-75"
          :class="
            selectOpt == 2
              ? 'text-[#00d57e] bg-[#4429b4]'
              : ' text-[#919191] bg-[#f3f4f6]'
          "
          @click="selectOptMethod(2)"
        >
          {{ $t("text_pago_metodosPago") }}
        </button>
        <button
          class="w-auto flex flex-col justify-center items-center text-base rounded-r-xl py-3 transition-all ease-in-out delay-75"
          :class="
            selectOpt == 3
              ? 'text-[#00d57e] bg-[#4429b4]'
              : ' text-[#919191] bg-[#f3f4f6]'
          "
          @click="selectOptMethod(3)"
        >
          {{ $t("text_pago_politicasPago") }}
        </button>
      </div>

      <div
        class="w-full text-center text-sm my-10"
        :class="darkMode ? 'text-white' : 'text-[#6b6b6b]'"
      >
        {{
          selectOpt == 1
            ? $t("text_pago_subTitle")
            : selectOpt == 2
            ? $t("text_meth_pago_subTitle")
            : "Ingresa las políticas de pago para tu tienda online"
        }}
      </div>

      <div
        class="w-full flex flex-col justify-start items-center transition-all ease-in-out delay-75"
      >
        <PaymentGateways v-if="selectOpt == 1" />
        <PaymentMethods v-if="selectOpt == 2" />
        <div
          v-if="selectOpt == 3"
          class="w-full flex flex-col justify-start items-center"
        >
          <div
            class="w-full flex flex-col sticky justify-center self-start editor"
          >
            <vue-editor
              id="pago"
              v-model="policiesData.pagos"
              class="bg-white rounded-xl"
            ></vue-editor>
            <div class="w-full flex justify-end mt-8">
              <button
                class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out delay-75"
                @click="updatePolicies"
              >
                {{ $t("text_inventario_guardar") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
import PaymentGateways from "./Payments/payment-gateways.vue";
import PaymentMethods from "./Payments/payment-methods.vue";
import axios from "axios";
export default {
  name: "newPayment",
  components: {
    VueEditor,
    PaymentGateways,
    PaymentMethods,
  },
  data() {
    return {
      selectOpt: 1,
    };
  },
  computed: {
    ...mapState(["storeData", "darkMode", "policiesData"]),
  },
  created() {
    const { OptMethod, Gateways, id, refreshtoken, code } = this.$route.query;
    if ((OptMethod && Gateways && id) || refreshtoken || code) {
      this.setInformationFromQuery(OptMethod, Gateways, id, refreshtoken, code);
    }
  },
  methods: {
    selectOptMethod(value) {
      this.selectOpt = value;
      this.setInformationFromQuery(value, null, null);
    },
    async setInformationFromQuery(OptMethod, Gateways, id, refreshtoken, code) {
      const query = {
        code: code !== null ? code : undefined,
        refreshtoken: refreshtoken !== null ? refreshtoken : undefined,
        OptMethod: OptMethod !== null ? OptMethod : undefined,
        Gateways: Gateways !== null ? Gateways : undefined,
        id: id !== null ? id : undefined,
      };

      const currentQuery = { ...this.$route.query };
      const currentHash = this.$route.hash;

      if (
        JSON.stringify(query) !== JSON.stringify(currentQuery) ||
        currentHash !== "3"
      ) {
        try {
          await this.$router.push({
            query,
            hash: "3",
          });
        } catch (error) {
          console.error("Error navigating:", error);
        }
      } else {
        console.log("Navegación redundante evitada");
      }
    },

    updatePolicies() {
      let params = {
        pagos: this.policiesData.pagos,
      };
      axios
        .post(
          `${this.$configKomercia.url}/api/admin/politicas/pago`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.$message.success(
            "sus políticas, condiciones y/o restricciones asociadas a los métodos de pago han sido guardadas."
          );
          this.updateStatePayment({ medio: "politica_pagos", estado: true });
        })
        .catch((error) => {
          if (error) {
            this.$message.error("intente más tarde o comuníquese con soporte.");
          }
        });
    },
    updateStatePayment(params) {
      axios
        .post(
          `${this.$configKomercia.url}/api/admin/medios-pago/cambiar-estado`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.$store.commit("SET_TASK", { id: 5, status: true });
        });
    },
  },
};
</script>
<style scoped>
.editor >>> .ql-toolbar.ql-snow {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.editor >>> .ql-container.ql-snow {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
</style>
