<template>
  <transition name="el-zoom-in-top">
    <div class="content-wrapper" :class="darkMode ? 'dark' : 'light'">
      <section>
        <div class="box"></div>
        <div class="box" :class="darkMode ? 'darks' : 'lights'">
          <h2
            class="tittle-client flex items-center"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            Mis clientes
            <sliderInformation :numberId="75" :actions="true" class="ml-2" />
          </h2>
          <div class="items-sales">
            <div class="filters" :class="darkMode ? 'darktxt' : 'lighttxt'">
              <!-- <h2 class="pb-2">Filtrar por</h2> -->
              <div class="btn-items">
                <div>
                  <p class="mb-1" :class="darkMode ? 'darktxt' : 'lighttxt'">
                    {{ $t("text_ventas_filtrarID") }}
                  </p>
                  <el-input
                    v-model="searchID"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    placeholder="Buscar por Identificación"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    clearable
                    @input="searchCustomers(2)"
                    @clear="clear(2)"
                  ></el-input>
                </div>
                <div class="ml-5">
                  <p class="mb-1" :class="darkMode ? 'darktxt' : 'lighttxt'">
                    {{ $t("text_ventas_filtrarCliente") }}
                  </p>
                  <el-input
                    v-model="searchName"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    placeholder="Buscar por Nombre"
                    clearable
                    @input="searchCustomers(3)"
                    @clear="clear(3)"
                  ></el-input>
                </div>
                <div class="ml-5">
                  <p class="mb-1" :class="darkMode ? 'darktxt' : 'lighttxt'">
                    Filtrar por correo electrónico
                  </p>
                  <el-input
                    v-model="search"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    placeholder="Buscar por E-mail"
                    clearable
                    @input="searchCustomers(1)"
                    @clear="clear(1)"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="btns-itms">
              <el-button
                type="primary"
                class="rounded-xl h-10 font-bold"
                @click="dialogFormVisible = true"
              >
                Crear cliente
              </el-button>
              <el-button
                class="rounded-xl h-10 font-bold"
                style="color: var(--purple); border: 2px solid var(--purple)"
                @click="openMultiplesProducts()"
              >
                Exportar Clientes
              </el-button>
            </div>
          </div>
          <div
            class="box-body table-container"
            :class="darkMode ? 'darks-tablet' : 'light-tablet'"
          >
            <el-table
              :data="copyCustomers"
              border
              stripe
              lazy
              style="width: 100%"
              :default-sort="{ prop: 'fecha', order: 'descending' }"
            >
              <el-table-column
                prop="usuario.nombre"
                label="Nombre"
              ></el-table-column>
              <el-table-column width="150px" label="Identificación">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.usuario.identificacion.length < 21"
                    class="w-full text-center"
                  >
                    <p>{{ scope.row.usuario.identificacion }}</p>
                  </div>
                  <div v-else class="w-full text-center">
                    <p>--</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="usuario.email"
                label="Correo"
              ></el-table-column>
              <el-table-column
                prop="usuario.ciudad"
                label="Ciudad"
                :formatter="city"
              ></el-table-column>
              <el-table-column
                width="150px"
                prop="usuario.user_info[0].telefono"
                label="Celular"
              ></el-table-column>
              <el-table-column width="150px" label="WhatsApp">
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row.usuario.id == 30866 ||
                      scope.row.usuario.id == 1371
                    "
                  ></div>
                  <button
                    v-else
                    class="btnWhatsApp"
                    @click="
                      contactWhatsApp(scope.row.usuario.user_info[0].telefono)
                    "
                  >
                    <i class="fab fa-whatsapp"></i> Contactar
                  </button>
                </template>
              </el-table-column>
              <el-table-column
                width="180px"
                prop="usuario.created_at"
                label="Fecha de creación"
              ></el-table-column>
              <el-table-column width="85px" label="Eliminar">
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row.usuario.identificacion === '0123456789' ||
                      scope.row.usuario.identificacion === '1003915188'
                    "
                  ></div>
                  <el-button
                    v-else
                    class="btn-row"
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click="deleteClient(scope.$index, scope.row.usuario)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            v-if="customers && customers.total > 10 && !isFiltered"
            class="w-full flex justify-center items-center mt-3"
          >
            <el-pagination
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :page-count="customers.last_page"
              :current-page="customers.page"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
        <ModalNewUser :show.sync="dialogFormVisible" />
        <MultiplesProductsDialog
          :visible.sync="dialogVisible"
          :client="customers ? customers.total : 0"
        />
      </section>
    </div>
  </transition>
</template>

<script>
import ModalNewUser from "../components/ModalNewUser.vue";
import MultiplesProductsDialog from "@/components/MultiplesClientes";
import sliderInformation from "@/components/information.vue";
export default {
  name: "CustomerTable",
  components: {
    ModalNewUser,
    MultiplesProductsDialog,
    sliderInformation,
  },
  data() {
    return {
      search: "",
      searchID: "",
      searchName: "",
      copyCustomers: [],
      isFiltered: false,
      currentPage: 1,
      dialogFormVisible: false,
      dialogVisible: false,
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    customers() {
      return this.$store.state.customers;
    },
    storeData() {
      return this.$store.state.storeData;
    },
    cities() {
      return this.$store.state.cities;
    },
  },
  watch: {
    search(value) {
      if (value === "") {
        this.clear();
      }
    },
    searchID(value) {
      if (value === "") {
        this.clear();
      }
    },
    searchName(value) {
      if (value === "") {
        this.clear();
      }
    },
  },
  created() {
    this.currentChange(this.currentPage);
    // this.$store.dispatch("GET_CITIES");
  },
  methods: {
    async currentChange(value) {
      const { success, data } = await this.$store.dispatch(
        "GET_CUSTOMERS",
        value
      );
      if (success) {
        this.copyCustomers = data.data;
      }
    },
    async searchCustomers(id) {
      let type = null;
      let value = null;
      if (id === 1) {
        type = "customer_email";
        value = this.search;
      } else if (id === 2) {
        type = "customer_identification";
        value = this.searchID;
      } else {
        type = "customer_name";
        value = this.searchName;
      }
      const { success, data } = await this.$store.dispatch("FILTER_CUSTOMERS", {
        type: type,
        value: value,
        page: "1",
      });
      if (success) {
        this.copyCustomers = [];
        this.isFiltered = true;
        this.copyCustomers = data.data;
      }
    },
    clear(value) {
      if (value === 1) {
        this.search = "";
      } else if (value === 2) {
        this.searchID = "";
      } else if (value === 3) {
        this.searchName = "";
      } else {
        this.search = "";
        this.searchID = "";
        this.searchName = "";
      }
      this.isFiltered = false;
      this.currentPage = 1;
      this.currentChange(this.currentPage);
      this.copyCustomers = [...this.customers.data];
    },
    openMultiplesProducts() {
      this.dialogVisible = true;
    },
    city(row) {
      const city = this.cities.find((city) => {
        return city.id === row.usuario.ciudad;
      });
      return city ? city.nombre_ciu : "";
    },
    handleDelete(index, row) {
      this.$store.dispatch("UNLINK_CUSTOMER", { usuario: row.id });
    },
    deleteClient(index, row) {
      this.$swal({
        title: "Eliminar producto",
        text: "¿Quieres eliminar permanentemente este cliente?",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        cancelButtonColor: "#d33",
        backdrop: "rgba(14,47,90,0.6)",
        icon: "warning",
      }).then(async (r) => {
        if (r.isConfirmed) {
          this.handleDelete(index, row);
          this.$message({
            type: "success",
            message: "Cliente eliminado!",
          });
        }
      });
    },
    contactWhatsApp(value) {
      if (value.length == 13) {
        let temp = "";
        if (value.charAt(0) === "+") {
          temp = value.slice(1);
        }
        window.open(
          `https://web.whatsapp.com/send?phone=${temp}&text=Buen%20d%C3%ADa%2C%0ASoy%20due%C3%B1o%20de%20la%20tienda%20%C3${window.location}.`,
          "_blank"
        );
      } else if (value.length == 10) {
        let indicativo;
        switch (this.storeData.informacion_tienda[0].paises_id) {
          case 1:
            indicativo = "57";
            break;
          case 3:
            indicativo = "52";
            break;
          case 6:
            indicativo = "54";
            break;
          case 7:
            indicativo = "56";
            break;
          case 8:
            indicativo = "1";
            break;
          case 9:
            indicativo = "51";
            break;
          case 10:
            indicativo = "507";
            break;
          default:
            indicativo = "57";
        }
        window.open(
          `https://web.whatsapp.com/send?phone=${indicativo}${value}&text=Buen%20d%C3%ADa%2C%0ASoy%20due%C3%B1o%20de%20la%20tienda%20%C3${window.location}.`,
          "_blank"
        );
      } else {
        this.$message.error("Numero de celular incorrecto!");
      }
    },
  },
};
</script>
<style scoped>
.darks-tablet >>> .el-table th.el-table__cell {
  background-color: #344a63;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.input-idTienda {
  padding: 12px 11px;
  border-radius: 10px;
  background-color: #f2efff;
  font-size: 13px;
  max-height: 40px;
  width: 220px;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #dadada;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  width: 220px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.btnWhatsApp {
  border: 1px solid #00bb2d;
  border-radius: 6px;
  color: #00bb2d;
  font-size: 14px;
  @apply px-2 py-1;
}
i.fab.fa-whatsapp {
  font-size: 20px;
  margin-right: 5px;
}
</style>
<style lang="scss" scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #1b2836;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.state {
  width: 120px;
  display: flex;
  align-items: center;
  color: #1d1c1c;
  font-weight: 500;
  font-size: 12px;
  border-radius: 5px;
}
.bg {
  width: 30px;
  height: 30px;
  color: #4c4c4c;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  margin: 0 10px 0 0;
}
.tag-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  font-weight: 600;
  text-align: center;
  border-radius: 20px;
  padding: 7px 15px;
  font-size: 12px;
}
.table-container > div {
  font-weight: 500;
}
.table-container > thead {
  color: red;
}
.table-container th > .cell {
  color: red;
}
.title-sales {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px 35px 20px 35px;
}
.title-sales h2 {
  // color: #4c4c4c;
  flex: 1;
}
.filters {
  @apply w-auto flex flex-col justify-center items-start;
}
.tittle-client {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  font-weight: 600;
}
.items-sales {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 30px;
  @apply w-full flex flex-row justify-between items-start;
}
.btn-items {
  @apply w-full flex flex-row justify-start items-center pb-2;
}
.search {
  flex: 0.5;
  margin-right: 20px;
}
.box-body {
  padding: 0px 30px 40px 30px;
}
.btn-row {
  width: 100% !important;
}
</style>
