<template>
  <div
    class="wrapper-content-information mb-4"
    :class="darkMode ? 'darkbg' : 'lightbg'"
  >
    <div class="w-full flex flex-row justify-between items-center mb-2">
      <h2
        class="text-xl font-bold mb-3"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        Información
      </h2>
      <div class="flex flex-row items-center">
        <p class="text-subtitle mr-2">Estado:</p>
        <el-tag :type="data.detalle.activo === '0' ? 'danger' : 'success'">
          {{
            data.detalle.activo === "0"
              ? $t("text_cupones_inactivo")
              : $t("text_cupones_activo")
          }}
        </el-tag>
      </div>
    </div>
    <div class="row">
      <div class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_nombre") }}
        </p>
        <el-input
          v-model="data.detalle.nombre"
          onkeypress="return (event.charCode != 44)"
          :placeholder="$t('text_ventas_nombre_producto')"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        >
          <i
            slot="suffix"
            class="el-icon-question el-input__icon"
            @click="handleHelp('Nombre', 'Nombre del producto')"
          ></i>
        </el-input>
      </div>
      <div class="input-area-full free-shipping">
        <div>
          <p :class="darkMode ? 'darktxt' : 'lighttxt'">
            {{ $t("text_inventario_envioGratis") }}
            <b>
              <el-tag type="warning" size="mini">
                {{ $t("text_inventario_recomendado") }}
              </el-tag>
            </b>
          </p>
          <p class="details" :class="darkMode ? 'darktxt' : 'lighttxt'">
            <strong>{{ $t("text_inventario_recomendado") }}:</strong>
            {{ $t("text_inventario_productosConEnviosGratis") }}
          </p>
        </div>
        <div class="radio-ckecked free-shipping__options">
          <el-radio
            v-model="data.detalle.envio_gratis"
            :label="0"
            border
            style="width: 100%"
          >
            {{ $t("text_inventario_no") }}
          </el-radio>
          <el-radio v-model="data.detalle.envio_gratis" :label="1" border>
            {{ $t("text_inventario_si") }}
          </el-radio>
        </div>
      </div>
    </div>
    <div class="input-area-full">
      <p class="text-subtitle mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_descriptionCorta") }}
        <b>
          <el-tag type="info" size="mini">
            {{ $t("text_inventario_requerido") }}
          </el-tag>
        </b>
      </p>
      <el-input
        v-model="data.info[0].descripcion_corta"
        onkeypress="return (event.charCode != 44)"
        :placeholder="$t('text_inventario_descriptionCortaMSG')"
        class="inputs-idTienda"
        :class="darkMode ? 'darks-input' : 'light-input'"
      >
      </el-input>
      <p class="text-subtitle my-3" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_description") }}
        <b>
          <el-tag type="info" size="mini">
            {{ $t("text_inventario_opcional") }}
          </el-tag>
        </b>
      </p>
      <div class="wrapper-editor">
        <el-tiptap
          v-model="data.info[0].descripcion"
          :extensions="extensions"
          :spellcheck="true"
          :readonly="false"
          :charCounterCount="false"
          :tooltip="true"
          :showMenubar="true"
          :bubble="true"
          output="html"
        />
      </div>
    </div>
    <div class="flex flex-col mb-3">
      <p class="text-subtitle my-3" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_generarDescription") }}
        <b>
          <el-tag type="info" size="mini">
            {{ $t("text_inventario_inteligenciaArtificial") }}
          </el-tag>
        </b>
      </p>
      <p class="text-xs mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_inteligenciaArtificial_description") }}
      </p>
      <div class="flex flex-wrap gap-2">
        <el-input-number
          v-model="nWords"
          class="inputs-idTienda"
          :min="10"
          :max="120"
          size="small"
        ></el-input-number>
        <el-tag
          v-for="tag in inputKeyWords"
          :key="tag"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          v-if="inputVisible"
          v-model="inputValue"
          class="inputs-idTienda"
          :placeholder="$t('text_inventario_palabrasClaves')"
          :class="darkMode ? 'darks-input' : 'light-input'"
          ref="saveTagInput"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else size="small" @click="inputVisible = !inputVisible">
          + {{ $t("text_inventario_agregar") }}
        </el-button>
        <button
          type="primary"
          class="bg-purple-komercia-100 text-white h-[32px] text-center rounded-md hover:bg-purple-komercia-50 px-3"
          :disabled="!stateGenerate ? false : true"
          @click="generateDescription"
        >
          <i v-if="stateGenerate" class="el-icon-loading" />
          {{
            stateGenerate
              ? $t("text_inventario_generandoDescription")
              : $t("text_inventario_generarDescription")
          }}
        </button>
      </div>
    </div>
    <div class="block-slider">
      <span
        class="text-subtitle mb-2"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
      </span>
      <el-slider
        v-model="data.detalle.orden"
        :marks="marks"
        :step="1"
        :max="max"
        :show-stops="stops"
      ></el-slider>
      <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_priopridadAtiendaMSG") }}
      </p>
    </div>
    <br />
    <div class="input-area-full">
      <p class="text-subtitle mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
        Tipo de producto
      </p>
      <p class="details"></p>
      <div class="radio-ckecked" style="margin-top: 8px">
        <el-radio v-model="data.info[0].tipo_servicio" label="0" border>
          Producto
        </el-radio>
        <el-radio v-model="data.info[0].tipo_servicio" label="1" border>
          Servicio
        </el-radio>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="input-area-full g">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_escribirMensajeProducto") }}
        </p>
        <div class="radio-ckecked">
          <el-radio v-model="data.info[0].activar_mensajes" :label="0" border>
            {{ $t("text_inventario_no") }}
          </el-radio>
          <el-radio v-model="data.info[0].activar_mensajes" :label="1" border>
            {{ $t("text_inventario_si") }}
          </el-radio>
        </div>
      </div>
    </div>
    <br />
    <div v-if="data.info[0].activar_mensajes" class="row">
      <div class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_escribirMensajeProductoMSG") }}
        </p>
        <div class="radio-ckecked">
          <el-radio
            v-model="data.info[0].mensaje_obligatorio"
            :label="0"
            border
          >
            {{ $t("text_inventario_no") }}
          </el-radio>
          <el-radio
            v-model="data.info[0].mensaje_obligatorio"
            :label="1"
            border
          >
            {{ $t("text_inventario_si") }}
          </el-radio>
        </div>
      </div>
      <div class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_etiquetaInstrucciones") }}
        </p>
        <el-input
          v-model="data.info[0].label_mensaje"
          placeholder="Nombre del producto"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        >
          <i
            slot="suffix"
            class="el-icon-question el-input__icon"
            @click="handleHelp('Nombre', 'Nombre del producto')"
          ></i>
        </el-input>
      </div>
    </div>
    <div class="input-area-full">
      <p class="text-subtitle mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
        Condición del producto
      </p>
      <p class="details"></p>
      <div class="radio-ckecked" style="margin-top: 8px">
        <el-radio v-model="data.info[0].condicion" :label="0" border>
          Usado
        </el-radio>
        <el-radio v-model="data.info[0].condicion" :label="1" border>
          Nuevo
        </el-radio>
      </div>
    </div>
    <div
      class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-[999999]"
      v-if="stateGenerate"
    >
      <div
        class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
      ></div>
      <div
        class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
      >
        <div class="modal-content py-4 text-left px-6">
          <div class="modal-header flex justify-between items-center pb-3">
            <p class="text-2xl font-bold">Generando la descripción...</p>
          </div>
          <div class="modal-body">
            <p class="text-justify">
              ¡Gracias por tu paciencia! Estamos trabajando para crear una
              descripción precisa y detallada del producto que has solicitado.
              ¡Tu espera valdrá la pena!
            </p>
            <br />
            <span class="text-gray-400">
              En breve se cerra automáticamente esta ventana.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import extensions from "../../mixins/elemenTiptap.vue";
import idCloudinary from "../../mixins/idCloudinary";
export default {
  filters: {
    currency(value) {
      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
  },
  mixins: [extensions, idCloudinary],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasCharacterSpecial: false,
      marks: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
      },
      max: 10,
      stops: true,
      dropshipping: {
        state: false,
        price: 0,
      },
      nWords: 120,
      stateGenerate: false,
      inputKeyWords: ["Figura", "Juguete", "Video juego"],
      inputVisible: false,
      inputValue: "",
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    selectLanguagePanel() {
      return this.$store.state.selectLanguagePanel;
    },
  },
  watch: {
    "data.detalle.nombre"(newValue, oldValue) {
      if (newValue.match(/[`°!@#$%^&*()_+=[\]{};:"\\|<>/?¿~]/)) {
        this.$message.error(
          `Solo se permiten los siguientes caracteres especiales: ',' (coma) '.' (punto)`
        );
        this.data.detalle.nombre = oldValue;
      } else {
        this.hasCharacterSpecial = false;
      }
    },
    // data() {
    //   this.setCombinations()
    // },
  },
  // created() {
  //   this.setCombinations()
  // },
  methods: {
    // setCombinations() {
    //   if (typeof this.data.combinaciones.combinaciones === 'string') {
    //     this.$store.state.combinations = JSON.parse(
    //       this.data.combinaciones.combinaciones
    //     )
    //   } else {
    //     this.$store.state.combinations = []
    //   }
    //   this.$store.state.variants = this.data.variantes
    // },
    validateValues(value) {
      if (value.match(/[`°!@#$%^&*()_+=[\]{};:"\\|,.<>/?¿~]/)) {
        this.$message.error("No se permiten caracteres especiales");
        return value.slice(0, -1);
      } else {
        return value;
      }
    },
    getIndex(value, index) {
      this.variants[index][value] = this.validateValues(
        this.variants[index][value]
      );
    },
    handleHelp(title, description) {
      this.$alert(description, title);
    },

    // handleImageAdded(file, Editor, cursorLocation, resetUploader) {
    //   let params = new FormData()
    //   params.append('file', file)
    //   params.append('upload_preset', 'qciyydun')
    //   params.append('folder', 'description')
    //   let config = {
    //     headers: {
    //       'content-type': 'multipart/form-data',
    //     },
    //   }
    //   axios
    //     .post(
    //       'https://api.cloudinary.com/v1_1/komercia-store/image/upload',
    //       params,
    //       config
    //     )
    //     .then(response => {
    //       let url = response.data.secure_url // Get url from response
    //       Editor.insertEmbed(cursorLocation, 'image', url)
    //       resetUploader()
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    async setDropshipping() {
      const { success, data } = await this.$store.dispatch("SET_DROPSHIPPING", {
        producto: this.data.detalle.id,
        comision: this.dropshipping.price,
      });
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha agregado al listado de dropshipping!",
          "success"
        );
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    async updateDropshipping() {
      const { success, data } = await this.$store.dispatch(
        "UPDATE_DROPSHIPPING",
        {
          producto: this.data.detalle.id,
          comision: this.data.detalle.drop_shipping[0].comision,
          estado: this.data.detalle.drop_shipping[0].estado,
        }
      );
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha actualizado el producto dropshipping!",
          "success"
        );
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    handleClose(tag) {
      this.inputKeyWords.splice(this.inputKeyWords.indexOf(tag), 1);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.inputKeyWords.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    async generateDescription() {
      this.stateGenerate = true;
      const { success, data } = await this.$store.dispatch(
        "GENERATE_DESCRIPTION_PRODUCT",
        {
          language: this.selectLanguagePanel
            ? this.selectLanguagePanel
            : "ES-CO",
          productName: this.data.detalle.nombre,
          keyWords: this.inputKeyWords,
          nWords: this.nWords,
        }
      );
      if (success && data?.data) {
        this.stateGenerate = false;
        this.data.info[0].descripcion = data.data;
        this.$swal(
          "Felicitaciones!",
          "Se generado la descripción del producto!",
          "success"
        );
      } else {
        this.stateGenerate = false;
        this.$swal("Error!", "Error al generar la descripción!", "error");
      }
    },
  },
};
</script>

<style scoped>
.btnUpdatePrice {
  background-color: transparent;
  color: #4429b4;
  border: 2px solid #4429b4;
  border-radius: 10px;
  height: 40px;
  max-height: 40px;
  width: 260px;
  margin-left: 10px;
  transition: all ease 0.3s;
}
.btnUpdatePrice:hover {
  color: #35dd8d;
  border: 2px solid #35dd8d;
}
.wrapper-content-information {
  width: 100%;
  padding: 23px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.text-subtitle {
  font-size: 13px;
  font-weight: 500;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.darktxts {
  background-color: #293d53;
  color: #fff;
}
.lighttxts {
  color: rgb(26, 46, 59);
}
.darkbgs {
  background-color: #1b2836;
}
.lightbgs {
  background-color: #fff;
}
.darkbg {
  background-color: #293d53;
}
.lightbg {
  background-color: #fff;
}

.row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 5fr;
  grid-gap: 10px;
}
.col-left {
  display: grid;
  grid-area: left;
}
.return_products {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.return_products i {
  margin-right: 7px;
}
.col-right {
  position: sticky;
  top: calc(50% - 340px);
  grid-area: right;
}
.title {
  font-size: 20px;
  margin: 15px 0;
  margin-left: 30px;
}
.col-left .box-body {
  display: flex;
  flex-direction: column;
}
.grid {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.input-area {
  max-width: calc(50% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area-full {
  max-width: calc(100% - 20px);
  width: 100%;
  /* margin: 5px; */
}

.input-area .el-input,
.input-area .quillWrapper,
.input-area .el-select {
  width: 100%;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  grid-area: actions;
}
.actions:last-child {
  display: grid;
  justify-content: center;
  grid-auto-flow: row;
  grid-template: 1fr / auto 1fr;
}
.actions_left {
  justify-self: end;
}
.free-shipping {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 2fr;
  align-items: center;
}
.free-shipping__options {
  justify-self: end;
  display: grid;
  grid-gap: 5px;
  align-self: end;
}
.radio-ckecked >>> .el-radio.is-bordered.is-checked {
  border-color: #4429b4;
}
.radio-ckecked >>> .el-radio__input.is-checked + .el-radio__label {
  color: #4429b4;
}
.radio-ckecked >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #4429b4;
  background: #4429b4;
}
.block-slider >>> .el-slider__bar {
  background-color: #4429b4;
}
.block-slider >>> .el-slider__button {
  border: 2px solid #4429b4;
}
.el-radio {
  margin-left: 0 !important;
}
.details {
  font-size: 12px;
  margin: 0;
}
.block-slider {
  margin: 5px;
  margin-top: 30px;
}
.block-slider .el-slider {
  width: 98%;
  padding-top: 5px;
  padding-bottom: 30px;
  padding-left: 5px;
}
.wrapper-editor >>> .el-tiptap-editor {
  height: 400px;
  max-width: 1362px;
}
.wrapper-editor >>> .el-tiptap-editor .border-top-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.wrapper-editor >>> .el-tiptap-editor__menu-bar {
  border: 2px solid #f3f4f6;
  border-bottom: 0;
}
.wrapper-editor >>> .el-tiptap-editor .border-bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wrapper-editor >>> .el-tiptap-editor > .el-tiptap-editor__content {
  border: 2px solid #f3f4f6;
}
.wrapper-editor >>> .el-tiptap-editor__content h1 {
  font-size: 2em;
}
.wrapper-editor >>> .el-tiptap-editor__content h2 {
  font-size: 1.5em;
}
.wrapper-editor >>> .el-tiptap-editor__content h3 {
  font-size: 1.17em;
}
.wrapper-editor >>> .el-tiptap-editor__content h4 {
  font-size: 1.12em;
}
.wrapper-editor >>> .el-tiptap-editor__content h5 {
  font-size: 0.83em;
}
.text_Descuento {
  font-size: 12px;
  margin-top: 5px;
}
@media (max-width: 1100px) {
  .row {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .col-left .box-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .el-radio {
    margin-right: 0px;
  }
  .input-area-full:first-child {
    margin: 0px;
    max-width: 480px;
  }
  .input-area-full {
    margin: 0px;
    max-width: unset;
  }
  .block-slider .el-slider {
    width: 100%;
  }
}
</style>
