<script>
import {
  Doc,
  Paragraph,
  Text,
  Heading,
  Bold,
  Italic,
  Underline,
  Strike,
  Code,
  CodeBlock,
  Blockquote,
  Link,
  BulletList,
  OrderedList,
  ListItem,
  TodoList,
  TodoItem,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Image,
  TextAlign,
  LineHeight,
  Indent,
  HorizontalRule,
  HardBreak,
  TrailingNode,
  History,
  TextColor,
  TextHighlight,
  FormatClear,
  // FontSize,
  Preview,
  Print,
  SelectAll,
} from "element-tiptap";
export default {
  computed: {
    extensions() {
      return [
        new Doc(),
        new Paragraph(),
        new Text(),
        new Heading({ level: 5, bubble: true }),
        new Bold({ bubble: true }),
        new Italic({ bubble: true }),
        new Underline({ bubble: true }),
        new Strike({ bubble: true }),
        new Code({ bubble: true }),
        new CodeBlock({ bubble: true }),
        new Blockquote({ bubble: true }),
        new Link({ bubble: true }),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new ListItem({ bubble: true }),
        new TodoList({ bubble: true }),
        new TodoItem({ bubble: true }),
        new Table({
          resizable: true,
          bubble: true,
        }),
        new TableHeader(),
        new TableRow(),
        new TableCell(),
        new Image({
          urlPattern: "",
          uploadRequest: "",
          bubble: true,
        }),
        new TextAlign({
          alignments: ["left", "center", "right", "justify"],
          bubble: true,
        }),
        new LineHeight({
          lineHeights: ["100%", "200%", "300%"],
        }),
        new Indent({
          minIndent: 0,
          maxIndent: 7,
        }),
        new HorizontalRule({ bubble: true }),
        new HardBreak(),
        new TrailingNode(),
        new History(),
        new TextColor({
          colors: [
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#000000",
          ],
          bubble: true,
        }),
        new TextHighlight({
          colors: [
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#000000",
          ],
          bubble: true,
        }),
        new FormatClear(),
        // new FontSize({
        //   fontSizes: [
        //     '0',
        //     '1',
        //     '2',
        //     '3',
        //     '4',
        //     '5',
        //     '6',
        //     '7',
        //     '8',
        //     '9',
        //     '10',
        //     '11',
        //     '12',
        //     '13',
        //     '14',
        //     '15',
        //     '16',
        //     '17',
        //     '18',
        //     '19',
        //     '20',
        //     '21',
        //     '22',
        //     '23',
        //     '24',
        //     '25',
        //     '26',
        //     '27',
        //     '28',
        //     '29',
        //     '30',
        //     '31',
        //     '32',
        //     '33',
        //     '34',
        //     '35',
        //     '36',
        //     '37',
        //     '38',
        //     '39',
        //     '40',
        //     '41',
        //     '42',
        //     '43',
        //     '44',
        //     '45',
        //     '46',
        //     '47',
        //     '48',
        //     '49',
        //     '50',
        //     '51',
        //     '52',
        //     '53',
        //     '54',
        //     '55',
        //     '56',
        //     '57',
        //     '58',
        //     '59',
        //     '60',
        //     '70',
        //     '80',
        //     '90',
        //   ],
        //   bubble: true,
        // }),
        new Preview(),
        new Print(),
        new SelectAll(),
      ];
    },
  },
};
</script>
