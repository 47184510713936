<template>
  <div class="content-wrapper">
    <div class="content">
      <div class="box">
        <iframe
          src="https://www.tidiochat.com/chat/zspemlh8nl3kx1g0e4axavmyxwfr3qe7?__hstc=183429753.9b64cdda066ce2f12d2eb88a98227232.1516806982927.1518812130490.1519051906482.23&__hssc=183429753.3.1519051906482&__hsfp=3687069429"
          width="100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportIndex",
};
</script>

<style scoped>
.box {
  padding-bottom: 0px;
}
.box iframe {
  border-width: 0px;
  max-height: 650px;
  height: 100vh;
}
</style>
