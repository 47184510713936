import Vue from "vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/es";
import "../../public/index.css";

function RepairProps(cmp) {
  (cmp.mixins || []).forEach((mixin) => {
    if (mixin.props && mixin.props.placement) {
      const defaultValue = mixin.props.placement.default;
      mixin.data = new Proxy(mixin.data, {
        apply(target, thisArg, argArray) {
          const res = Reflect.apply(target, thisArg, argArray);
          return {
            ...(res || {}),
            placement: defaultValue,
          };
        },
      });
      delete mixin.props.placement;
    }
    if (mixin.mixins && mixin.mixins.length > 0) {
      RepairProps(mixin);
    }
  });
}

RepairProps(ElementUI.DatePicker);
RepairProps(ElementUI.TimePicker);
RepairProps(ElementUI.TimeSelect);

Vue.use(ElementUI, { locale });
