var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full rounded-xl px-[40px] py-[30px]",class:_vm.darkMode ? 'bg-[#1b2836]' : 'bg-white',staticStyle:{"box-shadow":"0 0 10px 0 rgba(0, 0, 0, 0.09)"}},[(_vm.policiesData)?_c('div',{staticClass:"w-full flex flex-col"},[_c('div',{staticClass:"input-area"},[_c('p',{class:_vm.darkMode ? 'text-white' : 'text-[#333]'},[_vm._v(" "+_vm._s(_vm.$t("text_legal_info1"))+" "),_c('i',{staticClass:"el-icon-question el-input__icon",attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.handleHelpLateral(
              'https://www.komercia.co/blog/6111a3cd31f8fc16dd0cfa81'
            )}},slot:"suffix"}),_c('vue-editor',{class:_vm.darkMode ? 'text-white ' : 'text-[#333] ',attrs:{"id":"datos"},model:{value:(_vm.policiesData.datos),callback:function ($$v) {_vm.$set(_vm.policiesData, "datos", $$v)},expression:"policiesData.datos"}})],1)]),_c('br'),_c('div',{staticClass:"input-area"},[_c('p',{class:_vm.darkMode ? 'text-white' : 'text-[#333]'},[_vm._v(" "+_vm._s(_vm.$t("text_legal_info2"))+" "),_c('sliderInformation',{staticClass:"ml-2",attrs:{"numberId":68,"actions":true}})],1),_c('vue-editor',{class:_vm.darkMode ? 'text-white' : 'text-[#333]',attrs:{"id":"garantia"},model:{value:(_vm.policiesData.garantia),callback:function ($$v) {_vm.$set(_vm.policiesData, "garantia", $$v)},expression:"policiesData.garantia"}})],1),_c('br'),_c('div',{staticClass:"input-area"},[_c('p',{class:_vm.darkMode ? 'text-white' : 'text-[#333]'},[_vm._v(" "+_vm._s(_vm.$t("text_legal_info3"))+" "),_c('i',{staticClass:"el-icon-question el-input__icon",attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.handleHelpLateral(
              'https://www.komercia.co/blog/61119f6731f8fc16dd0cfa7e'
            )}},slot:"suffix"})]),_c('vue-editor',{class:_vm.darkMode ? 'text-white' : 'text-[#333]',attrs:{"id":"devoluciones"},model:{value:(_vm.policiesData.devolucion),callback:function ($$v) {_vm.$set(_vm.policiesData, "devolucion", $$v)},expression:"policiesData.devolucion"}})],1),_c('br'),_c('div',{staticClass:"input-area"},[_c('p',{class:_vm.darkMode ? 'text-white' : 'text-[#333]'},[_vm._v(" "+_vm._s(_vm.$t("text_legal_info4"))+" "),_c('i',{staticClass:"el-icon-question el-input__icon",attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.handleHelpLateral(
              'https://www.komercia.co/blog/6111a29131f8fc16dd0cfa80'
            )}},slot:"suffix"})]),_c('vue-editor',{class:_vm.darkMode ? 'text-white' : 'text-[#333]',attrs:{"id":"cambio"},model:{value:(_vm.policiesData.cambio),callback:function ($$v) {_vm.$set(_vm.policiesData, "cambio", $$v)},expression:"policiesData.cambio"}})],1),_c('div',{staticClass:"mt-10"},[_c('el-button',{staticClass:"pull-right",attrs:{"type":"primary"},on:{"click":_vm.updatePoliciesData}},[_vm._v(" "+_vm._s(_vm.$t("text_inventario_guardar"))+" ")]),_c('el-button',{staticClass:"pull-right",on:{"click":_vm.redirectTo}},[_vm._v(" "+_vm._s(_vm.$t("text_inventario_cancelar"))+" ")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }