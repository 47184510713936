<template>
  <div
    class="w-full lg:max-w-screen-lg xl:max-w-screen-xl rounded-xl"
    style="padding: 30px 20px"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div class="flex w-full justify-between items-center pb-10">
      <div>
        <h1
          class="flex items-center font-semibold text-lg"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_cupones_title") }}
          <sliderInformation :numberId="69" :actions="true" class="ml-2" />
        </h1>
        <p :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_cupones_subtitle") }}
        </p>
      </div>
      <button
        style="max-height: 40px"
        type="button"
        class="inline-flex w-48 justify-center max-w-xl items-center px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-100 bg-indigo-700 hover:bg-indigo-200"
        @click="newCoupon()"
      >
        {{ $t("text_cupones_crearCupon") }}
      </button>
    </div>
    <div v-show="showForm" class="modal">
      <div class="wrapper-items-form">
        <div ref="form" class="content-form">
          <div class="items-form">
            <div class="title">
              {{
                currentCoupon == -1
                  ? `${$t("text_cupones_crearCupon")}`
                  : `${$t("text_cupones_modificarCupon")}`
              }}
            </div>
            <el-input
              v-model="coupon.nombre"
              class="inputs-idTienda input-name"
              :class="darkMode ? 'darks-input' : 'light-input'"
              :placeholder="$t('text_cupones_nombreCupon')"
            ></el-input>
            <el-select
              v-model="coupon.tipo"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Select"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="
                coupon[coupon.tipo ? 'valor_descuento' : 'porcentaje_descuento']
              "
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              :placeholder="
                coupon.tipo
                  ? `${$t('text_cupones_valor')}`
                  : `${$t('text_cupones_porcentaje')}`
              "
              :prefix-icon="
                coupon.tipo ? 'fa fa-dollar-sign' : 'fas fa-percentage'
              "
            >
            </el-input>
            <el-input
              v-model="coupon.codigo"
              class="inputs-idTienda input-name"
              :class="darkMode ? 'darks-input' : 'light-input'"
              :placeholder="$t('text_cupones_codigo')"
            ></el-input>
            <div class="wrapper-switch">
              <span class="switcher switcher-1">
                <input
                  id="switcher-1"
                  ref="switcher"
                  v-model="coupon.estado"
                  type="checkbox"
                />
                <label for="switcher-1"></label>
              </span>
            </div>
            <div class="w-full flex flex-row">
              <el-radio
                v-model="coupon.publico"
                :label="0"
                border
                class="uppercase"
              >
                {{ $t("text_ventas_privado") }}
              </el-radio>
              <el-radio
                v-model="coupon.publico"
                :label="1"
                border
                class="uppercase"
              >
                {{ $t("text_ventas_publico") }}
              </el-radio>
            </div>
            <el-button type="primary" class="btn" @click="submitForm()">
              {{
                currentCoupon == -1
                  ? `${$t("text_inventario_guardar")}`
                  : `${$t("text_cupones_actualizar")}`
              }}
            </el-button>
            <button
              type="text"
              :disabled="currentCoupon == -1"
              class="btn btn-remove"
              @click="openDialog()"
            >
              <i class="el-icon-delete"></i>
              {{ $t("text_inventario_elimnar") }}
            </button>
          </div>
          <div class="flex justify-center">
            <p
              class="mt-2 text-black underline cursor-pointer"
              @click="closeDialog()"
            >
              {{ $t("text_cupones_cerrarVentana") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div :class="darkMode ? 'darks-tablet' : 'light-tablet'">
      <el-table
        stripe
        lazy
        border
        :data="listCoupons.data"
        style="width: 100%"
        :fit="true"
        highlight-current-row
        :default-sort="{ prop: 'fecha', order: 'descending' }"
      >
        <el-table-column prop="codigo" :label="$t('text_cupones_codigoCupon')">
        </el-table-column>
        <el-table-column
          prop="nombre"
          :label="$t('text_cupones_nombreCupoin')"
          width="210"
        >
        </el-table-column>
        <el-table-column :label="$t('text_cupones_valorDescuento')" width="140">
          <template slot-scope="scope">
            <div v-if="scope.row.tipo" class="text-base font-semibold">
              <i class="fa fa-dollar-sign"></i>
              {{ scope.row.valor_descuento | currency }}
              <br /><span class="text-xs font-medium text-gray-500 -mt-4">
                {{ $t("text_cupones_porValor") }}
              </span>
            </div>
            <div v-else class="text-base font-semibold">
              {{ scope.row.porcentaje_descuento }}
              <i class="fas fa-percentage"></i>
              <br />
              <span class="text-xs font-medium text-gray-500 -mt-4">
                {{ $t("text_cupones_porcentaje") }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_ventas_estado')" width="110">
          <template slot-scope="scope">
            <div v-if="scope.row.estado" class="state active">
              {{ $t("text_cupones_activo") }}
            </div>
            <div v-else class="state inactive">
              {{ $t("text_cupones_inactivo") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_ventas_visibilidad')" width="110">
          <template slot-scope="scope">
            <div v-if="!scope.row.publico" class="state inactive">
              <p class="uppercase">{{ $t("text_ventas_privado") }}</p>
            </div>
            <div v-else class="state active">
              <p class="uppercase">{{ $t("text_ventas_publico") }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_cupones_creacion')">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <b style="margin-left: 10px">
              {{ formatCalendarMoment(scope.row.created_at) }}
            </b>
            <br />
            <p style="margin-left: 24px; font-size: 11px; margin-top: -6px">
              {{ formatDateMoment(scope.row.created_at) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_integraciones_actualizacion')">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <b style="margin-left: 10px">
              {{ formatCalendarMoment(scope.row.updated_at) }}
            </b>
            <br />
            <p style="margin-left: 24px; font-size: 11px; margin-top: -6px">
              {{ formatDateMoment(scope.row.updated_at) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="" width="90">
          <template slot-scope="scope">
            <el-button
              size="mini"
              round
              type="primary"
              plain
              @click="selectCoupon(scope.row)"
            >
              {{ $t("text_inventario_editar") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="listCoupons && listCoupons.total > 10"
        class="w-full flex justify-center items-center mt-3"
      >
        <el-pagination
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="listCoupons.last_page"
          :current-page="listCoupons.current_page"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMoment from "../../mixins/formatDateMoment";
import formatCalendarMoment from "../../mixins/formatCalendarMoment";
import sliderInformation from "@/components/information.vue";
import axios from "axios";
export default {
  name: "ListCupones",
  filters: {
    currency(value) {
      if (value) {
        return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
  components: { sliderInformation },
  mixins: [formatDateMoment, formatCalendarMoment],
  data() {
    return {
      showForm: false,
      currentPage: 1,
      coupon: {
        nombre: "",
        tipo: 0,
        porcentaje_descuento: null,
        valor_descuento: null,
        codigo: "",
        estado: 0,
        publico: 0,
      },
      options: [
        {
          id: 0,
          name: "porcentaje",
        },
        {
          id: 1,
          name: "valor",
        },
      ],
      currentCoupon: -1,
      listCoupons: [],
      baseCoupon: {
        nombre: "",
        tipo: 0,
        porcentaje_descuento: null,
        valor_descuento: null,
        codigo: "",
        estado: 0,
        publico: 0,
      },
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  created() {
    this.currentChange(this.currentPage);
  },
  methods: {
    handleHelp(title, description) {
      this.$alert(description, title);
    },
    closeDialog() {
      this.showForm = false;
    },
    submitForm() {
      if (this.coupon.id) {
        if (this.coupon.tipo == 0) {
          if (
            this.coupon.porcentaje_descuento &&
            this.coupon.porcentaje_descuento > 0
          ) {
            this.updateCoupon(this.coupon.id);
          } else {
            this.$message({
              message: "El porcentaje tiene que ser mayores que cero.",
              type: "error",
            });
          }
        } else if (this.coupon.tipo == 1) {
          if (this.coupon.valor_descuento && this.coupon.valor_descuento > 0) {
            this.updateCoupon(this.coupon.id);
          } else {
            this.$message({
              message: "El valor tiene que ser mayores que cero.",
              type: "error",
            });
          }
        }
      } else {
        if (this.coupon.tipo == 0) {
          if (
            this.coupon.porcentaje_descuento &&
            this.coupon.porcentaje_descuento > 0
          ) {
            this.createCoupon();
          } else {
            this.$message({
              message: "El porcentaje tiene que ser mayores que cero.",
              type: "error",
            });
          }
        } else if (this.coupon.tipo == 1) {
          if (this.coupon.valor_descuento && this.coupon.valor_descuento > 0) {
            this.createCoupon();
          } else {
            this.$message({
              message: "El valor tiene que ser mayores que cero.",
              type: "error",
            });
          }
        }
      }
    },
    currentChange(page) {
      this.getCoupons(page);
      this.currentPage = page;
    },
    createCoupon() {
      this.$store
        .dispatch("CREATE_COUPON", this.coupon)
        .then((res) => {
          this.getCoupons(1);
          this.$message({
            message: res.data.mensaje,
            type: "success",
          });
          this.coupon = this.baseCoupon;
          this.showForm = false;
          this.currentCoupon = -1;
        })
        .catch((error) => {
          for (const property in error.response.data.errores) {
            setTimeout(() => {
              let e = error.response.data.errores[property];
              this.$message.error(`${e}`);
            }, 100);
          }
        });
    },
    selectCoupon(item) {
      this.showForm = true;
      this.coupon = item;
      this.currentCoupon = item;
    },
    getCoupons(page) {
      axios
        .get(
          `${this.$store.state.configKomercia.url}/api/admin/cupones?page=${page}`,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.listCoupons = response.data.cupones;
        });
    },
    updateCoupon(id) {
      axios
        .put(
          `${this.$store.state.configKomercia.url}/api/admin/cupones/${id}`,
          this.coupon,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.getCoupons(1);
          this.$message({
            message: response.data.mensaje,
            type: "success",
          });
          this.coupon = this.baseCoupon;
          this.showForm = false;
          this.currentCoupon = -1;
        });
    },
    newCoupon() {
      this.showForm = true;
      let coupon = {
        nombre: "",
        tipo: 0,
        porcentaje_descuento: null,
        valor_descuento: null,
        codigo: "",
        estado: 0,
        publico: 0,
      };
      let el = this.$refs.form;
      el.classList.toggle("pulse");
      setTimeout(() => {
        el.classList.toggle("pulse");
      }, 2000);
      this.coupon = coupon;
      this.currentCoupon = -1;
    },
    removeCoupon() {
      return axios
        .delete(
          `${this.$store.state.configKomercia.url}/api/admin/cupones/${this.coupon.id}`,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.getCoupons(1);
          this.$message({
            message: response.data.mensaje,
            type: "success",
          });
          this.coupon = this.baseCoupon;
          this.showForm = false;
          this.currentCoupon = -1;
        });
    },
    openDialog() {
      this.$confirm(
        `Desea eliminar el cupón ${this.coupon.nombre}?`,
        "Warning",
        {
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      ).then(() => {
        this.removeCoupon();
      });
    },
  },
};
</script>

<style scoped>
.darks-tablet >>> .el-table th.el-table__cell {
  background-color: #344a63;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.darks-tablet >>> .el-table__body tr.hover-row.current-row > td.el-table__cell,
.darks-tablet
  >>> .el-table__body
  tr.hover-row.el-table__row--striped.current-row
  > td.el-table__cell,
.darks-tablet
  >>> .el-table__body
  tr.hover-row.el-table__row--striped
  > td.el-table__cell,
.darks-tablet >>> .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.inputs-idTienda >>> .el-input__inner {
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.modal {
  padding-top: 200px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @apply w-full h-full fixed z-10 left-0 top-0 overflow-auto;
}
.wrapper-items-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-form {
  width: 100%;
  max-width: 600px;
  padding: 40px 40px 15px 40px;
  border-radius: 6px;

  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
.items-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
}
</style>
<style lang="scss" scoped>
$grey: #cccccc;
$white: #ffffff;

.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.content-cupones {
  display: grid;
  grid-template-columns: minmax(380px, 410px) auto;
  padding: 40px;
  height: 80vh;
  gap: 40px;
}
.wrapper-form {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.form {
  // box-shadow: 0 0 5px 5px hsla(224, 47%, 80%, 0.158);
  padding: 40px 40px 15px 40px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
  // margin-top: -30%;
  background-color: #fff;
}
.pulse {
  animation: shadow-pulse 1s infinite;
}
.title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
  grid-column: 1 / span 2;
  font-size: 1.2rem;
}
.input-name {
  grid-column: 1 / span 2;
}
.wrapper-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
span.switcher {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  input {
    appearance: none;
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    background-color: var(--purple);
    outline: none;
    font-family: "Oswald", sans-serif;
    &:before,
    &:after {
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
    }
    &:before {
      content: "ACTIVO";
      left: 13px;
    }
    &:after {
      content: "INACTIVO";
      right: 11px;
    }
  }
  label {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 4px;
  }
  &.switcher-1 {
    input {
      cursor: pointer;
      transition: 0.25s -0.1s;
      &:checked {
        background-color: $white;
        &:before {
          color: #2f8c4e;
          transition: color 0.5s 0.2s;
        }
        &:after {
          color: $grey;
          transition: color 0.5s;
        }
        & + label {
          left: 0;
          right: 83px;
          background: var(--green);
          transition: left 0.5s, right 0.4s 0.2s;
        }
      }
      &:not(:checked) {
        background: #f2f6f9;
        transition: background 0.5s -0.1s;
        &:before {
          color: $grey;
          transition: color 0.5s;
        }
        &:after {
          color: #fff;
          transition: color 0.5s 0.2s;
        }
        & + label {
          left: 70px;
          right: 0;
          background: var(--darktwo);
          transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
        }
      }
    }
  }
}
.wrapper-coupons {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
}
.coupons {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  padding: 10px;
}
.btn {
  grid-column: 1 / span 2;
  margin-top: 8px;
  font-weight: bold;
}
.btn-remove {
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
  color: rgba(243, 69, 69, 0.719) !important;
  border: none;
}
.btn-remove:hover {
  margin: 0 !important;
  font-weight: bold;
  color: rgba(247, 22, 22, 0.719) !important;
}
.coupon {
  background-color: #fff;
  box-shadow: 0 0 5px 5px hsla(224, 47%, 80%, 0.158);
  border-radius: 4px;
  width: 100%;
  padding: 30px 15px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.205);
  transition: all ease 0.3s;
}
.coupon:hover {
  border: 1px solid var(--green);
}
.coupon-new {
  border: 1px solid var(--purple);
  background-color: hsla(251, 62%, 95%, 0.2);
}
.coupon-new:hover {
  border: 1px solid var(--purple);
}
.top {
  display: flex;
  justify-content: space-between;
}
.top-new {
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  i {
    color: var(--purple);
    background-color: transparent;
    border: 2px solid var(--purple);
    border-radius: 50%;
    padding: 4px;
  }
}
.bottom {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.bottom-new {
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.title-new {
  font-weight: bold;
  color: var(--purple);
  text-transform: uppercase;
  font-size: 1rem;
  padding-top: 5px;
  line-height: 1;
}
.strong {
  font-weight: bold;
}
.value {
  font-size: 1.5rem;
  font-weight: bold;
}
.sup {
  font-size: 0.7rem;
  line-height: 1;
  color: var(--purple);
}
.code {
  font-size: 1.5rem;
  line-height: 1;
  color: var(--purple);
  font-weight: 700;
}
.state {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
}
.inactive {
  background-color: #f7858f;
  color: #ac2f39;
}
.active {
  background-color: #d5f6e0;
  color: #2f8c4e;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(46, 3, 82, 0.274);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
</style>
