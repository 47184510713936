<template>
  <div class="mipaquete-auth-container">
    <div class="auth-panel panel">
      <h3 class="flex items-center">
        {{ activeTabLabel }}
        <sliderInformation :numberId="68" :actions="true" class="ml-2" />
      </h3>
      <login v-if="activeTab === 'login'" @changetab="activeTab = 'signup'" />
      <div v-if="activeTab === 'signup'" @changetab="activeTab = 'login'" />
    </div>
    <div class="info-panel panel">
      <div class="logo-container">
        <img
          loading="lazy"
          class="logo"
          :src="require('@/assets/mipaquete/mi-paquete-box.png')"
        />
      </div>
      <p>
        En mipaquete.com somos la solución logística que necesitas. Integramos a
        las mejores transportadoras y empresas de mensajería para que realices
        más fácil tus envíos nacionales y urbanos, incluyendo la opción de
        envíos con pago contra entrega.
      </p>
      <ol>
        <li>
          Regístrate en
          <a
            title="Ir al registro de mipaquete"
            href="https://app.mipaquete.com/registro"
            target="_blank"
          >
            mipaquete.com/registro
          </a>
        </li>
        <li>Conecta tu tienda en Komercia con mipaquete.com</li>
        <li>Recarga tu saldo.</li>
        <li>Realiza tus envíos.</li>
      </ol>
      <p>
        Conoce más sobre
        <a
          title="Ir a la pagina mipaquete"
          href="https://mipaquete.com/"
          target="_blank"
        >
          mipaquete.com
        </a>
      </p>
      <div class="couriers-section">
        <p>Nuestras transportadoras integradas</p>
        <div class="couriers">
          <img
            loading="lazy"
            v-for="(courier, index) in couriers"
            :key="index"
            :src="require(`../../assets/mipaquete/${courier}`)"
          />
        </div>
      </div>
      <div class="flex items-center">
        Cómo crear una cuenta y vincularla
        <sliderInformation :numberId="68" :actions="true" class="ml-2" />
      </div>
    </div>
  </div>
</template>

<script>
import login from "./auth/login.vue";
import sliderInformation from "@/components/information.vue";
export default {
  name: "miPaqueteAuth",
  components: { login, sliderInformation },
  data() {
    return {
      loading: false,
      activeTab: "login",
      couriers: [
        "tcc.png",
        "logo-envia.png",
        "coordinadora.png",
        "logo-tempo.png",
        "servientrega.png",
      ],
    };
  },
  computed: {
    activeTabLabel() {
      return this.activeTab == "login" ? "Iniciar sesión" : "Crear cuenta";
    },
  },
};
</script>
<style lang="scss">
.mipaquete-auth-container {
  .error {
    margin-top: 5px;
    font-weight: 600;
    font-size: 13px;
    color: #f14b5a;
  }
  .inputs-idTienda {
    border-radius: 10px;
    background-color: none;
    font-size: 13px;
    max-height: 40px;
    margin-bottom: 10px;

    .el-input__inner {
      padding: 0px 15px;
      font-size: 13px;
      border-radius: 10px;
      border: solid 2px #f3f4f6;
      background-color: transparent;
      transition: none;
      max-height: 40px;
    }
  }
}
</style>
<style lang="scss" scoped>
.mipaquete-auth-container {
  display: flex;
  padding: 20px;
  .panel {
    border: 1px dashed #d8dde3;
    border-radius: 3px;
    padding: 15px;
    width: 100%;
  }

  .auth-panel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    h3 {
      font-size: 16px;
    }
  }

  .info-panel {
    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .logo {
        height: 80px;
        object-fit: contain;
      }
    }
    ol {
      margin: 10px 0 10px 40px;
      margin-top: 10px;
      list-style: decimal;
    }
    .couriers-section {
      margin: 20px 0;
      p {
        font-weight: bolder;
      }
      .couriers {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 10px;
        align-items: center;
        img {
          height: 40px;
          object-fit: contain;
        }
      }
    }
    button {
      margin-top: 30px;
      width: 100%;
    }
  }
}
</style>

<style scoped>
/* .flex-column {
  flex-flow: column nowrap;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.input-area {
  width: 100%;
}

.btn-save {
  width: 100%;
  max-width: 220px;
  height: 42px;
  padding: 13px 30px;
  border-radius: 10px;
  font-size: 12px;
}
.mipaquete__auth {
  display: flex;
  justify-content: center;
  padding: 40px;
}
.mipaquete__auth__form {
  width: 40%;
  display: grid;
  grid-gap: 5px;
  margin-right: 50px;
  align-content: center;
}
.mipaquete__auth__overview {
  display: flex;
}
.mipaquete__auth__overview__logo {
  width: 200px;
  object-fit: contain;
}
.mipaquete__auth__overview__description {
  font-weight: 400;
  line-height: 1.3;
}
.mipaquete__auth__overview__partners {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: start;
}
.mipaquete__auth__overview__partners img {
  width: 85px;
  height: 35px;
  object-fit: contain;
}
.partners__item {
  position: relative;
}
.soon::after {
  content: 'Muy pronto';
  position: absolute;
  top: -6px;
  right: -15px;
  width: 62px;
  background-color: var(--purple);
  color: #fff;
  font-size: 10px;
  border-radius: 10px;
  padding: 2px 4px;
  text-align: center;
}*/
</style>
