import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faHeart,
  faSearch,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faCheckSquare,
  faCashRegister,
  faLink,
  faPlus,
  faTimesCircle,
  faDollarSign,
  faPercent,
  faPercentage,
  faPlusCircle,
  faCreditCard,
  faPhoneAlt,
  faPhone,
  faMobileAlt,
  faSync,
  faUser,
  faMapMarkedAlt,
  faAt,
  faPowerOff,
  faTrashAlt,
  faExternalLinkAlt,
  faClone,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare as farSquare } from "@fortawesome/free-regular-svg-icons";
import { faQuestionCircle as fasQuestionCircle } from "@fortawesome/free-regular-svg-icons";

Vue.component("font-awesome-icon", FontAwesomeIcon);

library.add(
  faAngleLeft,
  faHeart,
  faSearch,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faCheckSquare,
  farSquare,
  faCashRegister,
  faLink,
  faPlus,
  faTimesCircle,
  faDollarSign,
  faPercentage,
  faPercent,
  faPlusCircle,
  faCreditCard,
  faPhoneAlt,
  faPhone,
  faMobileAlt,
  faSync,
  faUser,
  faMapMarkedAlt,
  faAt,
  faPowerOff,
  fasQuestionCircle,
  faTrashAlt,
  faExternalLinkAlt,
  faClone,
  faArrowRight
);
