<template>
  <div class="whats">
    <svg
      version="1.1"
      id="Whatsapp"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      style="enable-background: new 0 0 64 64"
      xml:space="preserve"
    >
      <title>whatsapp icon</title>
      <circle class="st0" cx="32" cy="32" r="31" />
      <path
        class="st1"
        d="M41.4,34.8c-0.4-0.2-2.5-1.4-2.9-1.5c-0.4-0.2-0.7-0.2-1,0.2c-0.3,0.4-1.2,1.4-1.4,1.6
	c-0.3,0.3-0.5,0.3-0.9,0.1c-0.4-0.2-1.8-0.7-3.4-2.3c-1.2-1.2-2.1-2.6-2.3-3.1c-0.2-0.4,0-0.7,0.2-0.9c0.2-0.2,0.4-0.5,0.7-0.7
	s0.3-0.4,0.5-0.7c0.2-0.3,0.1-0.5,0-0.8s-0.9-2.4-1.2-3.3c-0.3-0.9-0.7-0.7-0.9-0.8c-0.3,0-0.5-0.1-0.8-0.1c-0.4,0-0.9,0.2-1.2,0.5
	c-0.4,0.4-1.6,1.4-1.6,3.5s1.4,4.3,1.6,4.5c0.2,0.3,2.8,4.9,7.1,6.8c4.3,1.9,4.3,1.3,5.1,1.3c0.8,0,2.6-1,3-1.9c0.4-1,0.4-1.9,0.3-2
	C42.1,35.2,41.8,35.1,41.4,34.8L41.4,34.8z M33.4,45.1c-2.8,0-5.4-0.8-7.7-2.3l-5.4,1.7l1.8-5.2c-1.7-2.4-2.7-5.3-2.7-8.3
	c0-7.8,6.3-14.1,14.1-14.1S47.5,23.2,47.5,31S41.2,45.1,33.4,45.1z M33.4,14.1c-9.3,0-16.9,7.6-16.9,16.9c0,3.1,0.8,6.1,2.4,8.7
	l-3.1,9.1l9.4-3c2.5,1.4,5.3,2.1,8.2,2.1c9.3,0,16.9-7.6,16.9-16.9S42.8,14.1,33.4,14.1L33.4,14.1z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "iconWhatsApp",
};
</script>
<style scoped>
.st0 {
  fill: #30bf39;
}
.st1 {
  fill: #ffffff;
}
</style>
