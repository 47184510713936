<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          style="fill: #5d647f"
          d="M472,72H40C17.945,72,0,89.945,0,112v288c0,22.055,17.945,40,40,40h432c22.055,0,40-17.945,40-40
			V112C512,89.945,494.055,72,472,72z"
        ></path>
      </g>
      <g>
        <path
          style="fill: #ffd100"
          d="M176,232H80c-8.837,0-16-7.163-16-16v-64c0-8.837,7.163-16,16-16h96c8.837,0,16,7.163,16,16v64
			C192,224.837,184.837,232,176,232z"
        ></path>
      </g>
      <g>
        <g>
          <path
            style="fill: #b8bac0"
            d="M120,336H80c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8
				C136,328.837,128.837,336,120,336z"
          ></path>
        </g>
        <g>
          <path
            style="fill: #b8bac0"
            d="M224,336h-40c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8
				C240,328.837,232.837,336,224,336z"
          ></path>
        </g>
        <g>
          <path
            style="fill: #b8bac0"
            d="M328,336h-40c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8
				C344,328.837,336.837,336,328,336z"
          ></path>
        </g>
        <g>
          <path
            style="fill: #b8bac0"
            d="M432,336h-40c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8
				C448,328.837,440.837,336,432,336z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            style="fill: #8a8895"
            d="M232,384H72c-4.422,0-8-3.582-8-8s3.578-8,8-8h160c4.422,0,8,3.582,8,8S236.422,384,232,384z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            style="fill: #8a8895"
            d="M336,384h-72c-4.422,0-8-3.582-8-8s3.578-8,8-8h72c4.422,0,8,3.582,8,8S340.422,384,336,384z"
          ></path>
        </g>
      </g>
      <g>
        <path
          style="fill: #ff4f19"
          d="M368,216.002C359.211,225.821,346.439,232,332.224,232c-26.51,0-48-21.49-48-48s21.49-48,48-48
			c14.213,0,26.983,6.177,35.772,15.993"
        ></path>
      </g>
      <g>
        <polygon
          style="fill: #ff9500"
          points="192,192 112,192 112,176 192,176 192,160 112,160 112,136 96,136 96,232 112,232 112,208 
			192,208 		"
        ></polygon>
      </g>
      <g>
        <circle style="fill: #ffd100" cx="400" cy="184" r="48"></circle>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
