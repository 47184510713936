export default {
  setWindowWidth(state) {
    state.windowWidth = window.innerWidth;
  },
  SET_TOKEN(state, configKomercia) {
    state.configKomercia = configKomercia;
    state.configAxios.headers.Authorization = `Bearer ${configKomercia.accessToken}`;
  },
  SET_USER(state, value) {
    state.user = value;
  },
  SET_USER_DATA(state, value) {
    state.userData = value;
  },
  SET_CITIES(state, value) {
    state.cities = value;
  },
  SET_PRODUCT(state, value) {
    state.productData = value;
  },
  SET_SETTINGS_TEMPLATE(state, value) {
    state.settingsTemplate = value;
  },
  SET_SETTINGS_LINK(state, value) {
    state.settingsLink = value;
  },
  SET_ARTICLE(state, value) {
    state.articleData = value;
  },
  SET_PAYMENTS(state, { value, payment }) {
    if (value?.createdAt) {
      state.paymentsData[payment] = value;
    }
  },
  SET_PAYMENTMETHODS(state, value) {
    state.paymentMethods = value;
  },
  SET_PAYMENTGATEWAYS(state, value) {
    state.paymentGateways = value;
  },
  UPDATE_MODAL_SETTINGS(state, value) {
    state.modalSettingsTemplate = value;
  },
  REFRESH_IFRAME_TEMPLATE(state, value) {
    state.refreshIframeTemplate = value;
  },
  // DELETE_ORDER(state, id) {
  //   let index = state.ordersData.data.findIndex((order) => order.id === id);
  //   if (typeof index === "number") {
  //     state.ordersData.data.splice(index, 1);
  //   }
  // },
  SET_ADDON(state, { type, data }) {
    state.addons[type] = data;
  },
  SET_MERCADOLIBRE_USER(state, value) {
    state.mercadolibreUser = value;
  },
  SET_DATA_MERCADO_PAGO_STATUS(state, value) {
    state.mercadoPagoStatus = value;
  },
  SET_CURRENTORDER(state, value) {
    state.currentOrder = value;
  },
  // SET_ORDER_BY_ID(state, value) {
  //   const index = state.ordersData.data.findIndex(
  //     (order) => order.id === value.id
  //   );
  //   state.ordersData.data.splice(index, 1, {
  //     costo_envio: value.costo_envio,
  //     direccion_entrega: value.direccion_entrega,
  //     estado: value.estado,
  //     fecha: value.fecha,
  //     id: value.id,
  //     metodo_pago: value.metodo_pago,
  //     tienda: value.tienda,
  //     tipo: value.tipo,
  //     total: value.total,
  //     usuario: value.usuario,
  //   });
  // },
  ADD_BRANCH(state, value) {
    state.branchesData.unshift(value);
  },
  SET_BRANCH_BY_INDEX(state, { index, value }) {
    state.branchesData.splice(index, 1, value);
  },
  SET_TASK(state, { id, status }) {
    let task = state.tasksData.find((task) => task.id_tarea === id);
    if (task) {
      if (!task.done) {
        let index = state.tasksData.findIndex((task) => task.id_tarea === id);
        task.done = status;
        state.tasksData.slice(index, 1, task);
      }
    }
  },
  SET_SHIPMENT(state, value) {
    state.shipment = value;
  },
  SET_SHIPMENTRESPONSE(state, value) {
    state.shipmentResponse = value;
  },
  SET_IDRATE(state, value) {
    state.idRate = value;
  },
  SET_INVOICE(state, value) {
    state.invoice = value;
  },
  SET_BRANCH(state, value) {
    state.branch = value;
  },
  SET_ANALYTICS(state, value) {
    state.analytics = value;
  },
  SET_TAGMANAGER(state, value) {
    state.tagManager = value;
  },
  SET_TIDIOUSER(state, value) {
    state.tidioUser = value;
  },
  SET_METATAGFACEBOOK(state, value) {
    state.metaTagFacebook = value;
  },
  SET_PIXELFACEBOOK(state, value) {
    state.pixelFacebook = value;
  },
  SET_MESSENGERFACEBOOK(state, value) {
    state.messengerFacebook = value;
  },
  SET_GOOGLEMERCHANTS(state, value) {
    state.googleMerchants = value;
  },
  UPDATE_COLLAPSE_LAYOUT(state, value) {
    state.collapseLayout = value;
  },
  SET_SUBSCRIBERS(state, value) {
    state.subscribers = value;
  },
  SET_CUSTOMERS(state, value) {
    state.customers = value;
    // const isFound = value.clientes.find(
    //   customer => customer.identificacion === '0123456789'
    // )
    // let customers = value
    // if (!isFound) {
    //   // Este cliente esta quemado y hace referencia al cliente ocacional
    //   customers.clientes = [
    //     ...value.clientes,
    //     {
    //       activo: 1,
    //       app: 0,
    //       ciudad: 1,
    //       created_at: '2018-10-27 11:16:31',
    //       editor: 1,
    //       email: 'ocasional@komercia.co',
    //       foto: 'user.jpg',
    //       foto_facebook: null,
    //       id: 1371,
    //       id_facebook: null,
    //       identificacion: '0123456789',
    //       nombre: 'Cliente Ocasional',
    //       panel: 1,
    //       pivot: Object,
    //       rol: 4,
    //       tienda: 962,
    //       tipo_identificacion: 'CC',
    //       updated_at: '2019-07-04 19:52:07',
    //       user_info: Array[0],
    //     },
    //   ]
    // }
    // state.customers = customers
  },
  // ADD_ORDER(state, value) {
  //   state.ordersData.data.unshift(value);
  // },
  UPDATE_LIMIT_PRODUCTS(state, value) {
    state.alertLimitProducts.show = value;
  },
  SET_COUNTRIES(state, value) {
    state.countries = value;
  },
  SET_CITIES_BY_COUNTRY(state, value) {
    state.citiesByDeparments = value;
  },
  SET_INVENTORY_BY_PRODUCT(
    state,
    { index, quantity, combination, with_variant }
  ) {
    let product = state.productsData[index];
    if (with_variant) {
      let variantes = product.variantes;
      let combinaciones = JSON.parse(
        variantes[0].combinaciones[0].combinaciones
      );
      combinaciones = combinaciones.map((combi) => {
        if (JSON.stringify(combi.combinacion) === JSON.stringify(combination)) {
          combi.unidades = combi.unidades - quantity;
        }
        return combi;
      });
      variantes[0].combinaciones[0].combinaciones =
        JSON.stringify(combinaciones);
      state.productsData.splice(index, 1, {
        ...product,
        variantes,
      });
    } else {
      state.productsData.splice(index, 1, {
        ...product,
        inventario: product.inventario - quantity,
      });
    }
  },
  SET_DATA_CUBIKO(state, value) {
    state.dataCubiko = value;
  },
  SET_LENGUAJE(state, value) {
    state.selectLanguage = value;
  },
  SET_LENGUAJE_PANEL(state, value) {
    state.selectLanguagePanel = value;
  },
  SET_PREVIOUS_PAGE_HOKO(state, value) {
    state.previousPageHoko = value;
  },
  SET_PREVIOUS_PAGE(state, value) {
    state.previousPage = value;
  },
  SET_PREVIOUS_DROPSHIPPING(state, value) {
    state.previousPageDropshipping = value;
  },
  SET_PREVIOUS_PAGE_ORDER(state, value) {
    state.previousPageOrden = value;
  },
  SET_SEARCH_HEADER(state, value) {
    state.modalSearch = value;
  },
  SET_DOMAIN(state, value) {
    state.domain = value;
  },
  SET_DARK_MODE(state, value) {
    state.darkMode = value;
  },
};
