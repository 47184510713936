<template>
  <el-dialog
    center
    :visible.sync="showLimit"
    :modalAppendToBody="false"
    width="500"
  >
    <div class="contentDialog">
      <p class="text-lg">{{ $t("text_payment_limitProducts") }}</p>
      <img
        loading="lazy"
        width="90"
        src="https://res.cloudinary.com/komerciaacademico/image/upload/v1648662138/595067_dn9c6c.png"
        alt="warning"
      />
      <p>{{ limitvalue.text }}</p>
      <router-link to="/configuracion/membresia#4" class="bnt-limitProducts">
        <i class="el-icon-goods" />
        {{ $t("text_inventario_mejoraTuPlan") }}
      </router-link>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      limitvalue: this.$store.state.alertLimitProducts,
      // showLimit: true,
    };
  },
  computed: {
    showLimit: {
      get() {
        return this.$store.getters.getLimitProducts;
      },
      set(value) {
        this.$store.dispatch("updateLimitProduct", value);
      },
    },
    // LimitProducts(){
    //   if(this.$store.state.storeData.tipo === 0){
    //     return "MEJORA TU PLAN, HAS SUPERADO LOS 10 PRODUCTOS DE TU PLAN GRATIS"
    //   }else if(this.$store.state.storeData.tipo === 2){
    //     return "YA CASITO ERES GOLD INVIERTE 300MIL PARA LLEGAR A SERLO"
    //   }else if(this.$store.state.storeData.tipo === 3){
    //     return "ERES UN CRACK"
    //   }
    // },
  },
};
</script>
<style scoped>
.contentDialog {
  display: grid;
  justify-content: center;
  justify-items: center;
  text-align: center;
  grid-gap: 10px;
  max-width: 550px;
  margin: 0 auto;
}
.contentDialog p {
  font-size: 20px;
}
.bnt-limitProducts {
  font-size: 18px;
  @apply bg-purple-komercia-50 text-white py-3 rounded-xl px-3;
}
</style>
