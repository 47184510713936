<template>
  <div class="wrapper-items">
    <div class="item-content py-8 px-10" :class="darkMode ? 'dark' : 'light'">
      <div class="w-full flex flex-col justify-center items-center">
        <div class="w-full flex flex-row justify-between items-center mb-6">
          <p class="txt-title" :class="darkMode ? 'darkTxt' : 'lightTxt'">
            Cupones
          </p>
        </div>
        <div v-if="dataCupones.length > 0" class="grid-items">
          <div
            v-for="item in dataCupones"
            v-show="item.estado && item.publico"
            :key="item.id"
            class="h-full"
          >
            <div
              class="content-grid relative"
              :class="darkMode ? 'darks' : 'lights'"
            >
              <div class="w-full flex flex-col justify-between items-center">
                <img
                  loading="lazy"
                  class="card-img"
                  :src="`${$configKomercia.url}/logos/${item.tienda.logo}`"
                  :alt="`Logo_card_${item.tienda.logo}`"
                />
                <div class="w-full text-center my-2">
                  <p
                    class="text-xl font-bold"
                    :class="darkMode ? 'darkTxt' : 'lightTxt'"
                  >
                    {{ item.tienda.nombre }}
                  </p>
                </div>
                <div class="card-content">
                  {{ item.nombre }}
                  <span v-if="item.tipo">
                    descuento del
                    <span
                      class="font-bold"
                      :class="darkMode ? 'darkTxt' : 'lightTxt'"
                    >
                      <i class="fa fa-dollar-sign"></i>
                      {{ item.valor_descuento | currency }}
                    </span>
                    en tu compra
                  </span>
                  <span v-else>
                    descuento del
                    <span
                      class="font-bold"
                      :class="darkMode ? 'darkTxt' : 'lightTxt'"
                    >
                      {{ item.porcentaje_descuento }}
                      <i class="fas fa-percentage"></i>
                    </span>
                    en tu compra
                  </span>
                </div>
              </div>
              <div
                class="w-full my-5 flex flex-row justify-between items-center cursor-pointer"
                @click="copyLink(item.codigo)"
              >
                <p
                  class="py-3 px-4 rounded-md text-blue-800 bg-blue-200 hover:text-blue-900 hover:bg-blue-100 transition ease-in-out delay-150"
                >
                  Obtener
                </p>
                <div class="flex flex-col items-end">
                  <p class="text-gray-400 text-xs">Promo code</p>
                  <span
                    class="text-base"
                    :class="darkMode ? 'darkTxt' : 'lightTxt'"
                  >
                    <i class="el-icon-copy-document"></i>
                    {{ item.codigo }}
                  </span>
                </div>
              </div>
              <a
                title="Ir a mi tienda"
                target="_blank"
                :href="
                  item.tienda.informacion_tienda[0].dominio != null &&
                  item.tienda.informacion_tienda[0].dominio != ''
                    ? item.tienda.informacion_tienda[0].dominio
                    : `https://${item.tienda.subdominio}.komercia.store`
                "
                class="btn-store"
              >
                Ir tienda
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListCupones",
  filters: {
    currency(value) {
      if (value) {
        return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
  computed: {
    ...mapState(["dataCupones", "darkMode"]),
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  async created() {
    this.$store.dispatch("GET_DATA_ALL_CUPONES");
  },
  methods: {
    copyLink(value) {
      this.getCopyLink(value);
    },
    getCopyLink(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message.success(`Se ha copiado el código ${str} `);
    },
  },
};
</script>
<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  @apply bg-white;
}
.darks {
  background-color: #283c51;
}
.lights {
  background-color: #fff;
}
.darkTxt {
  color: #fff;
}
.lightTxt {
  color: #333;
}
.wrapper-items {
  @apply w-full flex flex-col justify-center items-center;
}

.overlay-top {
  width: 78px;
  top: 10px;
  right: 10px;
  height: 29px;
  padding: 5px;
  font-size: 15px;
  background: #121a23;
  color: white;
  @apply absolute overflow-hidden rounded-md shadow-md;
}
.btn-store {
  @apply bg-black text-white w-full text-center py-2 rounded-md transition ease-in-out delay-150 hover:text-green-200;
}
@media (min-width: 0px) {
  .item-content {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
    @apply w-full flex flex-col justify-center items-center mb-7 rounded-xl;
  }
  .item-content:hover {
  }
  .txt-title {
    font-size: 26px;
    font-weight: 600;
    /* color: #333; */
  }
  .grid-items {
    @apply w-full grid grid-cols-2 gap-6 justify-center items-start;
  }
  .content-grid {
    @apply w-full h-full flex flex-col justify-between items-center py-5 px-6 rounded-xl border border-gray-200;
    transition: box-shadow 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .content-grid:hover {
    @apply shadow-xl;
    transition: box-shadow 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .card-img {
    max-width: 120px;
    max-height: 120px;
    height: 120px;
    @apply object-contain object-center;
  }
  .card-content {
    color: #00a45b;
    border: 2px dashed #00a45b;
    @apply w-full flex flex-col py-3 px-4 border-dashed rounded-md text-center;
  }
}
@media (min-width: 1200px) {
  .grid-items {
    @apply grid grid-cols-3;
  }
}
</style>
