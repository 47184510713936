<template>
  <div class="flex">
    <div class="main-contain">
      <div class="content-stats">
        <stats-home />
      </div>
      <div class="principal">
        <div class="contain bg-purple-komercia-900">
          <div class="left">
            <div
              v-if="
                dataUrl &&
                dataUrl.showLinkTienda != 'https://undefined.komercia.co'
              "
              class="direction"
            >
              <h1 id="nameuser">
                {{ $t("text_inicio_hola") }} {{ nombreusuario[0] }}
              </h1>
              <br />
              <p id="title-url">{{ $t("text_inicio_tuTiendaOnline") }}</p>
              <a
                :title="`Ir a al tienda ${dataUrl.showLinkTienda}`"
                :href="`${dataUrl.redirectLinkTienda}`"
                id="linktienda"
                target="_blank"
              >
                <p class="showDomainStore">
                  <strong>{{ dataUrl.showLinkTienda }}</strong>
                </p>
              </a>
              <!-- <p>Compartir</p> -->
            </div>
          </div>
          <div class="right">
            <div class="boxabsolute">
              <img
                loading="lazy"
                id="boxes"
                src="../../assets/iconosBannerHome/boxes.png"
                alt
              />
              <img
                loading="lazy"
                id="circles"
                src="../../assets/iconosBannerHome/circles.png"
                alt
              />
              <img
                loading="lazy"
                id="piece"
                src="../../assets/iconosBannerHome/piece.png"
                alt
              />
              <img
                loading="lazy"
                id="xgreen"
                src="../../assets/iconosBannerHome/xgreen.png"
                alt
              />
              <img
                loading="lazy"
                id="man"
                src="../../assets/banner-imagen-man.png"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <PremiumAds /> -->
  </div>
</template>

<script>
import StatsHome from "../../components/StatsHome";
// import PremiumAds from './PremiumAds'
export default {
  components: {
    StatsHome,
    // PremiumAds,
  },

  data() {
    return {
      showLinkTienda: "",
      redirectLinkTienda: "",
    };
  },

  computed: {
    nombreusuario() {
      let name = this.$store.state.configKomercia.usuario.nombre.split(" ");
      return name;
    },
    dataTienda() {
      return this.$store.state.storeData;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    dataUrl() {
      if (
        this.dataTienda.entidades &&
        this.dataTienda.entidades.length > 0 &&
        this.dataTienda.entidades[0].id == 21
      ) {
        return {
          showLinkTienda: `${this.dataTienda.subdominio}.keepbuy.co`,
          redirectLinkTienda: `https://${this.dataTienda.subdominio}.keepbuy.co`,
        };
      } else {
        //si la tienda tiene dominio
        if (
          this.dataTienda.informacion_tienda &&
          this.dataTienda.informacion_tienda[0].dominio
        ) {
          let newUrl =
            this.dataTienda.informacion_tienda[0].dominio.split("//");
          return {
            showLinkTienda: newUrl[1],
            redirectLinkTienda: this.dataTienda.informacion_tienda[0].dominio,
          };
        } else if (this.dataTienda.template > 4) {
          return {
            showLinkTienda: `${this.dataTienda.subdominio}.komercia.store`,
            redirectLinkTienda: `https://${this.dataTienda.subdominio}.komercia.store`,
          };
        } else {
          if (this.dataTienda.template === 3) {
            return {
              showLinkTienda: `${this.dataTienda.subdominio}.komercia.co`,
              redirectLinkTienda: `https://${this.dataTienda.subdominio}.komercia.co`,
            };
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.principal {
  width: 100%;
  display: grid;
  align-items: center;
}
.content-stats {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}
.contain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 165px;
  height: 210px;
  border-radius: 12px;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
}
.main-contain {
  width: 100%;
}
.left {
  display: grid;
  grid-template-columns: 1fr 35px 1fr;
  justify-items: center;
  align-items: center;
  color: #fff;
  padding: 35px;
}
.hola {
  display: inline-flex;
  font-weight: 500;
}
#quebueno {
  font-size: 16px;
  font-weight: 500;
}
#title-url {
  font-size: 16px;
  font-weight: 500;
}
.hola p {
  font-size: 17px;
}
#linktienda {
  color: #fff;
  font-size: 12px;
}
#linktienda h1 {
  font-weight: normal;
}
#nameuser {
  color: #00dd8d;
  color: white;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1.18;
}
.showDomainStore {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}
.left hr {
  border-left: 1px solid #fff;
  height: 100%;
}
.right {
  height: 100%;
  position: relative;
}
.right .boxabsolute {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 7px;
}
.boxabsolute #circles {
  top: -35px;
  position: absolute;
  width: 70px;
  left: 10px;
}
.boxabsolute #boxes {
  position: absolute;
  width: 100px;
  bottom: -45px;
  right: 30px;
}
.boxabsolute #piece {
  position: absolute;
  width: 140px;
  top: -50px;
  right: -53px;
}
.boxabsolute #xgreen {
  position: absolute;
  bottom: 10px;
  width: 15px;
  left: 40px;
}
.boxabsolute #man {
  position: absolute;
  bottom: -60px;
  width: 350px;
  left: 40px;
}
.boxabsolute #xyellow {
  position: absolute;
  top: 20px;
  right: 85px;
  width: 15px;
}
.boxrelative {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-gap: 5px;
  align-content: center;
  margin: 25px 0px;
}
.boxrelative #store {
  width: 120px;
  transition: all ease 0.5s;
  border-radius: 9px;
}
.boxrelative #store:hover {
  /* background-color: #fdd103; */
  background-color: #ce2181;
}
.boxrelative #textdownload {
  width: 100px;
  height: 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.26px;
  text-align: left;
  color: #00dd8d;
}
.boxtext {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
}
.boxstore {
  display: grid;
  grid-auto-flow: column;
  width: 250px;
  grid-gap: 10px;
  z-index: 998;
}
.elipse {
  width: 3px;
  height: 3px;
  background-color: #e62178;
  border-radius: 30px;
}
@media (max-width: 976px) {
  .contain {
    height: auto;
  }
}
@media (max-width: 767px) {
  .contain {
    display: flex;
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;
    grid-template-columns: 1fr 0.1fr 1fr;
  }
  .direction {
    margin-left: 5px;
  }
  .name {
    margin-right: 5px;
  }
  #nameuser {
    white-space: unset;
  }
  .showDomainStore {
    word-break: break-word;
  }
}
</style>
