<template>
  <div class="w-full flex flex-col justify-center items-center">
    <el-radio-group
      v-model="selectPayment"
      class="w-full max-w-[900px] flex flex-wrap justify-center items-center wrapper-cards gap-x-4 gap-y-5"
      @change="getPaymentStore($event)"
    >
      <div
        v-for="(item, index) in payments"
        :key="index"
        class="w-full max-w-[210px]"
      >
        <el-radio-button :label="item.data" class="w-full h-full">
          <i
            class="mr-2"
            :class="
              paymentGateways[item.name] === 1
                ? 'el-icon-success text-[#00d57e]'
                : 'el-icon-error text-[#d84343]'
            "
          ></i>
          <img
            loading="lazy"
            :src="item.img"
            :alt="item.name"
            class="flex justify-center items-center"
            :class="[
              item.name === 'tu_compra' || item.name === 'wompi'
                ? 'max-h-[30px]'
                : 'max-h-[38px]',
              selectPayment?.payment === item.data.payment
                ? ' grayscale-0'
                : ' grayscale',
              paymentGateways[item.name] === 1 ? ' grayscale-0' : ' grayscale',
            ]"
          />
        </el-radio-button>
      </div>
    </el-radio-group>

    <div
      v-if="selectPayment?.id"
      class="w-full max-w-[950px] h-full rounded-xl mt-10 px-[30px] py-[20px]"
      :class="darkMode ? 'bg-[#233345]' : 'bg-[#f3f4f6]'"
    >
      <div
        v-if="selectPayment.id == 20"
        class="flex flex-col justify-center items-center py-10"
      >
        <img
          loading="lazy"
          src="../../../assets/imgPayment.svg"
          alt="imagenPayment"
          width="450"
          height="300"
        />
        <p class="max-w-[500px] text-center">
          Las integraciones de pagos no están disponibles en tu territorio
          <strong> {{ storeData?.informacion_tienda[0].pais.pais }}, </strong>
          puedes comunicarte con soporte para obtener mas información.
        </p>
      </div>
      <!-- nequi -->
      <div v-if="selectPayment.id == 12">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          <!-- Guía como Nequi Addi: -->
          Nequi
          <!-- <sliderInformation :numberId="4" :actions="true" class="ml-2" /> -->
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Nequi tiene varios tipos de API de recaudo para tu negocio, que pueden
          usar tus clientes para pagarte. La comisión que te cobramos por estos
          servicios es del 1.5% del valor de la venta, incluyendo el IVA. Nunca
          superará los $2.900 + iva por venta. La plata te llega al día
          siguiente a tu cuenta Bancolombia.
        </p>
        <p
          class="text-sm mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          1. Pagos con notificación push Tu negocio se conecta con Nequi a
          través de una API que, para realizar el pago, pide el número de cuenta
          Nequi del usuario. Luego el usuario recibe un mensaje en la app Nequi
          o mensaje push, que le permitirá ir directo al pago y aceptarlo o
          rechazarlo.
        </p>
        <p
          class="text-sm mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          2. Pagos con código QR Por medio de la API se crea un código QR en el
          sitio web o app de tu negocio. Para pagar, los usuarios lo escanean
          con la app Nequi y listo.
        </p>
        <p
          class="text-sm mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          3. Pagos con débito automático A través de esta API se enviará para
          inscripción de pago automático el número de cuenta del usuario Nequi.
          Después, el usuario recibirá una notificación de la app Nequi para
          aprobar la inscripción de su cuenta en tu negocio.
        </p>
        <p
          class="text-sm mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Cuando la cuenta esté inscrita, cada que el usuario te tenga que
          pagar, tu negocio le cobrará directamente a Nequi y será debitado
          automáticamente de la cuenta del usuario. Al usuario siempre le
          llegará una notificación de la app Nequi para avisarle que se hizo
          este movimiento.
        </p>
        <div class="w-full flex justify-center mt-10">
          <div
            class="bg-[#DA0081] hover:bg-[#F1BFDA] hover:text-[#DA0081] rounded-md px-3 py-2 font-semibold text-center text-white text-lg transition-all ease-in-out duration-300"
          >
            Próximamente, estamos en desarrollo.
          </div>
        </div>
      </div>
      <!-- Addi -->
      <div v-if="selectPayment.id == 11">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar Addi:
          <sliderInformation :numberId="4" :actions="true" class="ml-2" />
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Son una empresa de tecnología que busca impulsar y habilitar el
          comercio digital en Latinoamérica. En Addi quieren que compres lo que
          quieras, cuando quieras, de forma fácil, rápida y transparente. Como
          debe ser.
        </p>
        <p
          class="text-sm mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Es la manera segura de comprar a cuotas lo que quieras en nuestros
          comercios aliados, solo con tu cédula, sin papeleos y 100% online..
        </p>

        <template v-if="storeActiveAddi">
          <ValidationObserver ref="observer" tag="form">
            <div
              v-if="paymentsData[selectPayment.payment.toLowerCase()]"
              class="py-10"
            >
              <div class="flex flex-col mb-4">
                <label
                  for="Client id"
                  class="text-sm"
                  :class="darkMode ? 'text-white' : 'text-[#919191]'"
                >
                  Client id <span class="ml-1 text-red-500">*</span>
                </label>
                <ValidationProvider
                  name="Client id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-input
                    v-model="
                      paymentsData[selectPayment.payment.toLowerCase()].clientID
                    "
                    name="Client id"
                    placeholder="Ingresar el client id"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    :disabled="!statusData"
                  ></el-input>
                  <span v-if="errors" class="text-xs text-red-500">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="flex flex-col mb-4">
                <label
                  for="Client secret"
                  class="text-sm"
                  :class="darkMode ? 'text-white' : 'text-[#919191]'"
                >
                  Client secret <span class="ml-1 text-red-500">*</span>
                </label>
                <ValidationProvider
                  name="Client secret"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-input
                    v-model="
                      paymentsData[selectPayment.payment.toLowerCase()]
                        .clientSecret
                    "
                    name="Client secret"
                    placeholder="Ingresar el client secret"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    :disabled="!statusData"
                  ></el-input>
                  <span v-if="errors" class="text-xs text-red-500">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="flex flex-col mb-4">
                <label
                  for="Ally slug"
                  class="text-sm"
                  :class="darkMode ? 'text-white' : 'text-[#919191]'"
                >
                  Ally slug <span class="ml-1 text-red-500">*</span>
                </label>
                <ValidationProvider
                  name="Ally slug"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-input
                    v-model="
                      paymentsData[selectPayment.payment.toLowerCase()]
                        .ally_slug
                    "
                    name="Ally slug"
                    placeholder="Ingresar el ally slug"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    :disabled="!statusData"
                  ></el-input>
                  <span v-if="errors" class="text-xs text-red-500">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="flex flex-row justify-between items-center">
                <p
                  class="text-sm"
                  :class="darkMode ? 'text-white' : 'text-[#919191]'"
                >
                  Modo
                </p>
                <el-switch
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()]
                      .productionMode
                  "
                  active-text="Producción"
                  inactive-text="Pruebas"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="!statusData"
                  @change="changeModeAddi($event)"
                >
                </el-switch>
              </div>
            </div>
          </ValidationObserver>
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="w-full flex justify-end items-center"
          >
            <button
              class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
              :class="[
                paymentGateways.addi === 1
                  ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                  : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
                !statusData ? 'cursor-wait' : '',
              ]"
              :disabled="!statusData"
              @click="
                changeStatePayment(
                  'ADDI',
                  paymentGateways.addi === 1 ? 'DEACTIVATE' : 'ACTIVATE'
                )
              "
            >
              {{
                paymentGateways.addi === 1
                  ? $t("text_pago_desactivar")
                  : $t("text_pago_activar")
              }}
            </button>
            <button
              class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
              :class="!statusData ? 'cursor-wait' : ''"
              :disabled="!statusData"
              @click="validateAndSubmit"
            >
              {{
                paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                  ? "Actualizar"
                  : "Guardar"
              }}
            </button>
          </div>
        </template>
        <div v-else class="w-full flex justify-center mt-10">
          <a
            href="https://api.whatsapp.com/send/?phone=573107884893&text&type=phone_number&app_absent=0"
            title="Escribirle a soporte Komercia "
            class="bg-[#4E7EFF] hover:bg-[#38539e] hover:text-white rounded-md px-3 py-2 font-semibold text-center text-white text-lg transition-all ease-in-out duration-300"
            target="_blank"
          >
            Consultar con soporte para poder activarla.
          </a>
        </div>
      </div>
      <!-- mercadoPago -->
      <div v-if="selectPayment.id == 3">
        <p
          class="flex items-center font-semibold text-base my-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar Mercado pago:
          <sliderInformation :numberId="61" :actions="true" class="ml-2" />
        </p>
        <div class="grid grid-cols-2 gap-x-3 items-center">
          <div>
            <h1
              class="text-lg font-bold"
              :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
            >
              La pasarela de pagos que te recomendamos:
            </h1>
            <ul class="list-disc ml-5 mt-5">
              <li
                class="text-sm mb-2"
                :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
              >
                Única en el mercado que cubre el 100% en caso de fraude
              </li>
              <li
                class="text-sm mb-2"
                :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
              >
                Retiros ilimitados y sin costo a cualquier cuenta
              </li>
              <li
                class="text-sm mb-2"
                :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
              >
                Sin gastos fijos ni mensualidades, se paga por transacción
                exitosa
              </li>
              <li
                class="text-sm mb-2"
                :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
              >
                Altos niveles de aprobación para maximizar las ventas
              </li>
            </ul>
          </div>
          <div class="flex flex-col justify-center items-center">
            <div
              class="w-full rounded-lg bg-gray-100 py-4 flex justify-center items-center"
            >
              <img
                loading="lazy"
                src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1654013189/komerica/MercadoLibre/Sin_t%C3%ADtulo-1_Mesa_de_trabajo_1_dtsiwf.png"
                alt="logo MercadoPago"
                width="190px"
              />
            </div>
            <img
              loading="lazy"
              class="mt-5"
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1654014625/komerica/MercadoLibre/logo-mercado-pago-png-7-1024x312-1_bd8gmd_jxurv1.png"
              width="260px"
              alt="logosPasarelas"
            />
          </div>
        </div>
        <p
          class="text-sm mt-10"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_mercadoLibreInfo") }}
        </p>

        <button
          v-if="paymentGateways.mercadopago === 0"
          class="py-5 text-[#009cde] hover:text-[#0c3b89] font-bold underline cursor-pointer transition-all ease-in-out"
          @click="redirectMercadopago"
        >
          {{ $t("text_pago_mercadoLibreConecta") }}
          <i class="fas fa-external-link-alt" />
        </button>

        <template v-if="paymentGateways.mercadopago === 1 && mercadolibreUser">
          <p
            class="flex flex-row items-center bg-green-300 rounded-lg p-2 my-4"
          >
            <i class="el-icon-success" style="color: green" />
            <span class="ml-2">
              {{ $t("text_pago_mercadoLibreYaConecta") }}
              <u>{{ mercadolibreUser.email }}</u>
            </span>
          </p>
          <span
            v-if="paymentsData?.mercadopago?.integrationStatus?.daysRemaining"
            class="flex flex-row items-start bg-orange-200 rounded-lg p-2 mt-4"
          >
            <i class="el-icon-warning text-orange-500 mt-1" />
            <p class="ml-2">
              <strong> ¡Importante! Actualización de Token</strong> <br />
              ¡Recuerda actualizar tu token de acceso dentro de los próximos
              <strong>
                {{ paymentsData.mercadopago.integrationStatus.daysRemaining }}
                días!
              </strong>
              Mantén tus credenciales al día para seguir disfrutando los
              servicios de mercado pago sin interrupciones.
            </p>
          </span>
        </template>

        <div class="w-full flex justify-end items-center mt-10">
          <a
            title="Abrir Mercadopago"
            class="rounded-xl px-10 py-3 text-gray-500 hover:text-gray-600 focus:text-gray-600 transition-all ease-in-out duration-300 mr-5"
            href="https://www.mercadopago.com.co/ayuda/costo-recibir-pagos_1774"
            target="_blank"
          >
            {{ $t("text_pago_calculaCostos") }}
          </a>
          <button
            v-if="paymentGateways.mercadopago === 1"
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.mercadopago === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'MERCADOPAGO',
                paymentGateways.mercadopago === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.mercadopago === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            v-if="paymentGateways.mercadopago === 1"
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="updateTokenMercadoPago"
          >
            Renovar token
          </button>
        </div>
      </div>
      <!-- epayco -->
      <div v-if="selectPayment.id == 1">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar ePayco:
          <sliderInformation :numberId="55" :actions="true" class="ml-2" />
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_epaycoInfo") }}
        </p>
        <ValidationObserver ref="observer" tag="form">
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="py-10"
          >
            <div class="flex flex-col mb-4">
              <label
                for="Id ePayco"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Id ePayco <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Id ePayco"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()]
                      .pCustIdCliente
                  "
                  name="Id ePayco"
                  placeholder="Ingresar el id ePayco"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col mb-4">
              <label
                for="P_key"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                P_key <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="P_key"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].pKey
                  "
                  name="P_key"
                  placeholder="Ingresar el P_key"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col mb-4">
              <label
                for="Public key"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Public key <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Public key"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].publicKey
                  "
                  name="Public key"
                  placeholder="Ingresar el Public key"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>

        <div
          v-if="paymentsData[selectPayment.payment.toLowerCase()]"
          class="w-full flex justify-end items-center"
        >
          <a
            title="Abrir Epayco"
            class="rounded-xl px-5 py-3 text-gray-500 hover:text-gray-600 focus:text-gray-600 transition-all ease-in-out duration-300 mr-5"
            href="https://registro.epayco.com/#27186"
            target="blank"
          >
            Crear cuenta
          </a>
          <button
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.payco === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'EPAYCO',
                paymentGateways.payco === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.payco === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="validateAndSubmit"
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- payu -->
      <div v-if="selectPayment.id == 2">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar PayU:
          <sliderInformation :numberId="62" :actions="true" class="ml-2" />
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_epaycoInfo") }}
        </p>
        <ValidationObserver ref="observer" tag="form">
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="py-10"
          >
            <div class="flex flex-col mb-4">
              <label
                for="Api key"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Api key <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name=" Api key"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].apiKey
                  "
                  name="Ingresar el Api key"
                  placeholder="Api key"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col mb-4">
              <label
                for="Api login"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Api login <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Api login"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].apiLogin
                  "
                  name="Api login"
                  placeholder="Ingresar el api login"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col mb-4">
              <label
                for="Merchant ID"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Merchant ID <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Merchant ID"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].merchantId
                  "
                  name="Merchant ID"
                  placeholder="Ingresar el merchant ID"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col">
              <label
                for="Account ID"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Account ID <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Account ID"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].accountId
                  "
                  name="Account ID"
                  placeholder="Ingresar el account ID"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
        <div
          v-if="paymentsData[selectPayment.payment.toLowerCase()]"
          class="w-full flex justify-end items-center"
        >
          <button
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.payu === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'PAYU',
                paymentGateways.payu === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.payu === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="validateAndSubmit"
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- wompi -->
      <div v-if="selectPayment.id == 5">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar Wompi:
          <sliderInformation :numberId="80" :actions="true" class="ml-2" />
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_wompiInfo") }}
        </p>
        <ValidationObserver ref="observer" tag="form">
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="py-10 space-y-2"
          >
            <div class="flex flex-col">
              <label
                for="Llave pública"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Llave pública <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Llave pública"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()]
                      .llavePublica
                  "
                  name="Llave pública"
                  placeholder="Ingresar la Llave pública"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col">
              <label
                for="Llave integridad"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Llave integridad <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Llave integridad"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].integrity
                  "
                  name="Llave integridad"
                  placeholder="Ingresar la Llave de integridad"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col">
              <label
                for="Llave Eventos"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Llave Eventos <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Llave Eventos"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()]
                      .eventSecret
                  "
                  name="Llave Eventos"
                  placeholder="Ingresar la Llave de eventos"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col">
              <label
                for="Llave Eventos"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Seguimiento de transacciones
              </label>
              <div
                class="w-full flex flex-row justify-between rounded-xl border-2 border-[#cdcdcd] hover:border-[#4429b4] focus:border-[#4429b4] px-[16px] py-[11px]"
              >
                <p :class="darkMode ? 'text-white' : 'text-[#333]'">
                  https://api.komercia.app/api/v1/hooks/public/proccess-wompi-payment-status
                </p>
                <div class="header-copy" @click="getCopyLink(4)">
                  <span v-if="!stateCopyWompi" style="font-size: 12px">
                    {{ copyWompi }}
                    <i class="far fa-clone"> </i>
                  </span>
                  <span v-else class="copy-text" style="font-size: 12px">
                    {{ copyWompi }}
                    <i class="far el-icon-check"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <div
          v-if="paymentsData[selectPayment.payment.toLowerCase()]"
          class="w-full flex justify-end items-center"
        >
          <button
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.wompi === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'WOMPI',
                paymentGateways.wompi === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.wompi === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="validateAndSubmit"
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- tucompra -->
      <div v-if="selectPayment.id == 9">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar TuCompra:
          <sliderInformation :numberId="82" :actions="true" class="ml-2" />
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_Tucompra") }}
        </p>
        <ValidationObserver ref="observer" tag="form">
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="py-10"
          >
            <div class="flex flex-col mb-4">
              <label
                for="Id Sistema"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Id Sistema <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Id Sistema"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].username
                  "
                  name="Id Sistema"
                  placeholder="Ingresar el id Sistema"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col">
              <p
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                WebHook
              </p>
              <div
                class="w-full flex flex-row justify-between rounded-xl border-2 border-[#cdcdcd] hover:border-[#4429b4] focus:border-[#4429b4] px-[16px] py-[11px]"
              >
                <p :class="darkMode ? 'text-white' : 'text-[#333]'">
                  https://api2.komercia.co/api/tu-compra/hook
                </p>
                <div class="header-copy" @click="getCopyLink(2)">
                  <span v-if="!stateCopyTucompra" style="font-size: 12px">
                    {{ copyTucompra }}
                    <i class="far fa-clone"> </i>
                  </span>
                  <span v-else class="copy-text" style="font-size: 12px">
                    {{ copyTucompra }}
                    <i class="far el-icon-check"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <div
          v-if="paymentsData[selectPayment.payment.toLowerCase()]"
          class="w-full flex justify-end items-center"
        >
          <button
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.tu_compra === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'TU_COMPRA',
                paymentGateways.tu_compra === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.tu_compra === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="validateAndSubmit"
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- WePay4U Perú -->
      <div v-if="selectPayment.id == 10">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar Wepay4U:
          <sliderInformation :numberId="64" :actions="true" class="ml-2" />
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_WePay4U") }}
        </p>
        <div class="my-4 border-2 border-[#cdcdcd]" />
        <div class="ml-5">
          <ul class="list-disc">
            <li
              class="text-sm mb-2"
              :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
            >
              Este servicio tiene un costo de porcentaje por transacción (3,5%
              con mínimo de 1,50 Dólares + IGV) que le será depositado a lo
              recaudado.
            </li>
            <li
              class="text-sm mb-2"
              :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
            >
              Comisión cada 2 días útiles
            </li>
            <li
              class="text-sm"
              :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
            >
              Factura mensual
            </li>
          </ul>
        </div>
        <div class="w-full flex justify-center my-5">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1677264360/wePay4u/Komercia_pasarela_de_pagos_ngfpxc.png"
            class="my-5 object-cover object-center"
          />
        </div>
        <ValidationObserver ref="observer" tag="form">
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="py-10"
          >
            <div class="flex flex-col mb-4">
              <label
                for="key"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                key <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="key"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].key
                  "
                  name="key"
                  placeholder="Ingresar lA Key"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                  @input="
                    validateKey(
                      paymentsData[selectPayment.payment.toLowerCase()].key
                    )
                  "
                >
                  <i
                    v-if="statusKeyWepay4u"
                    slot="suffix"
                    class="el-icon-success el-input__icon icon_color"
                  />
                  <i
                    v-else
                    slot="suffix"
                    class="el-icon-error el-input__icon icon_color-error"
                  />
                </el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div>
              <p
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                WebHook
              </p>
              <div
                class="w-full flex flex-row justify-between rounded-xl border-2 border-[#cdcdcd] hover:border-[#4429b4] focus:border-[#4429b4] px-[16px] py-[11px]"
              >
                <p :class="darkMode ? 'text-white' : 'text-[#333]'">
                  https://api2.komercia.co/api/wepay4u/hook
                </p>
                <div class="header-copy" @click="getCopyLink(3)">
                  <span v-if="!stateCopyWepay4u" style="font-size: 12px">
                    {{ copyWepay4u }}
                    <i class="far fa-clone"></i>
                  </span>
                  <span v-else class="copy-text" style="font-size: 12px">
                    {{ copyWepay4u }}
                    <i class="far el-icon-check"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <div
          v-if="statusMsgWepay4u"
          class="flex bg-red-200 border border-red-300 rounded-xl mb-10 px-4 py-3"
        >
          <div class="flex-shrink-0">
            <svg
              class="h-5 w-5 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-600">
              LLave incorrecta, por favor comunicarse con WePay4U a través de
              <strong>support@wepay4u.com</strong>
            </h3>
            <div class="mt-2 text-sm text-red-500">
              <ul role="list" class="list-disc pl-5 space-y-1">
                <li>
                  Activar pasarela de pagos WePay4U en Komercia. (Por favor
                  enviar datos de contacto, nombre, correo, número de celular)
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          v-if="paymentsData[selectPayment.payment.toLowerCase()]"
          class="w-full flex justify-end items-center"
        >
          <button
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.wepay4u === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'WEPAY4U',
                paymentGateways.wepay4u === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.wepay4u === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="validateAndSubmit"
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- Flow Chile -->
      <div v-if="selectPayment.id == 7">
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_flowInfo") }}
        </p>
        <a
          title="Abrir Flow"
          class="flex justify-center items-center"
          href="https://www.flow.cl/info.php"
          target="_blank"
        >
          <img
            loading="lazy"
            src="https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1623862555/FLOW_tktlul.png"
            width="500"
            class="my-5 object-cover object-center"
          />
        </a>
        <ValidationObserver ref="observer" tag="form">
          <div
            v-if="paymentsData[selectPayment.payment.toLowerCase()]"
            class="py-10"
          >
            <div class="flex flex-col mb-4">
              <label
                for="Api Key"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Api Key <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Api Key"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].apiKey
                  "
                  name="Api Key"
                  placeholder="Api Key"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="flex flex-col">
              <label
                for="Secret Key"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Secret Key <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Secret Key"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="
                    paymentsData[selectPayment.payment.toLowerCase()].secretKey
                  "
                  name="Secret Key"
                  placeholder="Secret Key"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :disabled="!statusData"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
        <div
          v-if="paymentsData[selectPayment.payment.toLowerCase()]"
          class="w-full flex justify-end items-center"
        >
          <button
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300 mr-5"
            :class="[
              paymentGateways.flow === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'FLOW',
                paymentGateways.flow === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.flow === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="validateAndSubmit"
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- paymentWay -->
      <!-- <div v-if="selectPayment.id == 6">
        <p
          class="flex items-center font-semibold text-base mt-3"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          Guía como integrar Payments Way:
          <a
            title="Ver documentación"
            target="_blank"
            class="ml-1 text-blue-500"
            rel="noreferrer noopener"
            href="https://drive.google.com/file/d/1gvhwh5VTgFy0CTxtj3rs-A2ASr9SY4NI/view?usp=sharing"
            download="Documentacion paymentsWay"
          >
            {{ $t("text_pago_verDocumentacion") }}
          </a>
        </p>
        <p
          class="text-sm mt-4"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ $t("text_pago_paymentWay") }}
        </p>

        <div class="py-10">
          <p
            class="text-sm"
            :class="darkMode ? 'text-white' : 'text-[#919191]'"
          >
            WebHook
          </p>
          <div
            class="w-full flex flex-row justify-between rounded-xl border-2 border-[#cdcdcd] hover:border-[#4429b4] focus:border-[#4429b4] px-[16px] py-[11px]"
          >
            <p :class="darkMode ? 'text-white' : 'text-[#333]'">
              https://api2.komercia.co/api/payments-way/hook
            </p>
            <div class="header-copy" @click="getCopyLink(1)">
              <span v-if="!stateCopyPaymentWay" style="font-size: 12px">
                {{ copyPaymentWay }}
                <i class="far fa-clone"></i>
              </span>
              <span v-else class="copy-text" style="font-size: 12px">
                {{ copyPaymentWay }}
                <i class="far el-icon-check"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="w-full flex justify-end items-center">
          <button
            v-if="paymentsData[selectPayment.payment.toLowerCase()]?.createdAt"
            class="rounded-xl px-10 py-3 border-2 transition-all ease-in-out duration-300"
            :class="[
              paymentGateways.paymenths_way === 1
                ? 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]'
                : 'border-[#f79c1d] hover:border-[#FCD679] text-[#f79c1d] hover:text-[#FCD679]',
              !statusData ? 'cursor-wait' : '',
            ]"
            :disabled="!statusData"
            @click="
              changeStatePayment(
                'PAYMENTS_WAY',
                paymentGateways.paymenths_way === 1 ? 'DEACTIVATE' : 'ACTIVATE'
              )
            "
          >
            {{
              paymentGateways.paymenths_way === 1
                ? $t("text_pago_desactivar")
                : $t("text_pago_activar")
            }}
          </button>
          <button
            v-else
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? 'cursor-wait' : ''"
            :disabled="!statusData"
            @click="
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? updatePayment(
                    paymentsData[selectPayment.payment.toLowerCase()],
                    'PAYMENTS_WAY'
                  )
                : createdPayment(
                    paymentsData[selectPayment.payment.toLowerCase()],
                    'PAYMENTS_WAY'
                  )
            "
          >
            {{
              paymentsData[selectPayment.payment.toLowerCase()]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div> -->
      <!-- siigo -->
      <!-- <div v-if="selectPayment.id == 8">
        <p class="description" :class="darkMode ? 'text-white' : 'text-[#333]'">
          {{ $t("text_pago_epaycoInfo") }}
        </p>
        <div v-if="siigo" class="form">
          <p :class="darkMode ? 'text-white' : 'text-[#333]'">Username</p>
          <el-input
            v-model="siigo.username"
            v-validate="'required'"
            placeholder="Username"
            data-vv-name="Username"
            data-vv-scope="Siigo"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          ></el-input>
          <p :class="darkMode ? 'text-white' : 'text-[#333]'">access_key</p>
          <el-input
            v-model="siigo.access_key"
            v-validate="'required'"
            placeholder="access_key"
            data-vv-name="access_key"
            data-vv-scope="Siigo"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          ></el-input>
          <p :class="darkMode ? 'text-white' : 'text-[#333]'">Estado</p>
          <el-switch
            v-model="siigo.estado"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          >
            >
          </el-switch>
        </div>
        <div class="actions">
          <el-button type="primary" class="ml-3" @click="uptdatedataSiigo()">
            Guardar
          </el-button>
        </div>
      </div> -->
      <!-- credibanco -->
      <!-- <div v-if="selectPayment.id == 4">
        <p class="description" :class="darkMode ? 'text-white' : 'text-[#333]'">
          {{ $t("text_pago_credibancoInfo") }}
        </p>
        <div v-if="paymentGateways.credibanco" class="form">
          <p :class="darkMode ? 'text-white' : 'text-[#333]'">Username</p>
          <el-input
            v-model="paymentGateways.credibanco.username"
            v-validate="'required'"
            placeholder="Username"
            data-vv-name="username"
            data-vv-scope="credibanco"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          ></el-input>
          <p :class="darkMode ? 'text-white' : 'text-[#333]'">Password</p>
          <el-input
            v-model="paymentGateways.credibanco.password"
            v-validate="'required'"
            placeholder="Password"
            data-vv-name="password"
            data-vv-scope="credibanco"
            class="inputs-idTienda"
            :class="darkMode ? 'darks-input' : 'light-input'"
          ></el-input>
        </div>
        <div class="actions">
          <el-button
            v-if="!paymentsData.credibanco.estado"
            type="primary"
            class="pull-right"
            @click="updateGateway('credibanco')"
          >
            {{ $t("text_pago_activar") }}
          </el-button>
          <el-button
            v-else
            type="warning"
            class="pull-right"
            @click="stateGateways('credibanco')"
          >
            {{ $t("text_pago_desactivar") }}
          </el-button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import sliderInformation from "@/components/information.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "PaymentGateways",
  components: { sliderInformation, ValidationObserver, ValidationProvider },
  data() {
    return {
      selectPayment: {
        id: null,
        payment: null,
      },
      statusData: false,
      statusKeyWepay4u: false,
      statusMsgWepay4u: false,
      stateCopyPaymentWay: false,
      stateCopyTucompra: false,
      stateCopyWepay4u: false,
      copyPaymentWay: "Copiar",
      copyTucompra: "Copiar",
      copyWepay4u: "Copiar",
      stateCopyWompi: false,
      copyWompi: "Copiar",
    };
  },
  computed: {
    ...mapState([
      "darkMode",
      "storeData",
      "paymentGateways",
      "mercadoPagoStatus",
      "mercadolibreUser",
      "dataAddi",
      "paymentsData",
    ]),
    mercadoPagoCountry() {
      if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais
      ) {
        return this.storeData.informacion_tienda[0].pais.pais;
      }
    },
    mercadoPagoUrl() {
      if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais
      ) {
        const clientId =
          this.storeData.informacion_tienda[0].pais.pais === "Chile"
            ? process.env.VUE_APP_CLIENT_ID_CHILE
            : process.env.VUE_APP_CLIENT_ID;

        const baseUrl =
          this.storeData.informacion_tienda[0].pais.pais === "Chile"
            ? "https://auth.mercadopago.cl/authorization"
            : "https://auth.mercadopago.com.co/authorization";

        const localURL = `${location.origin}/empresa/pagos#3`;

        return `${baseUrl}?client_id=${clientId}&response_type=code&platform_id=mp&redirect_uri=${localURL.toString()}`;
      }
    },
    mercadoPagoUrlRefresh() {
      if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais
      ) {
        const clientId =
          this.storeData.informacion_tienda[0].pais.pais === "Chile"
            ? process.env.VUE_APP_CLIENT_ID_CHILE
            : process.env.VUE_APP_CLIENT_ID;

        const baseUrl =
          this.storeData.informacion_tienda[0].pais.pais === "Chile"
            ? "https://auth.mercadopago.cl/authorization"
            : "https://auth.mercadopago.com.co/authorization";

        const localURL = `${location.origin}/empresa/pagos#3`;

        return `${baseUrl}?client_id=${clientId}&response_type=code&platform_id=mp&redirect_uri=${localURL.toString()}`;
      }
    },
    payments() {
      if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais
      ) {
        const country = this.storeData.informacion_tienda[0].pais.pais;

        const paymentMappings = {
          Colombia: [
            {
              data: {
                id: 11,
                payment: "ADDI",
              },
              name: "addi",
              img: require("../../../assets/payments/addi.svg"),
            },
            {
              data: {
                id: 3,
                payment: "MERCADOPAGO",
              },
              name: "mercadopago",
              img: require("../../../assets/payments/mercadopago.png"),
            },
            {
              data: {
                id: 1,
                payment: "EPAYCO",
              },
              name: "payco",
              img: require("../../../assets/payments/epayco.png"),
            },
            {
              data: {
                id: 2,
                payment: "PAYU",
              },
              name: "payu",
              img: require("../../../assets/payments/Payu.png"),
            },
            // {
            //   data:{
            //     id: 4,
            // payment:'CREDIBANCO',
            //   },
            //   name: "Credibanco",
            //   img: require("../../../assets/payments/credibanco.png"),
            // },
            {
              data: {
                id: 5,
                payment: "WOMPI",
              },
              name: "wompi",
              img: require("../../../assets/payments/wompi.png"),
            },
            // {
            //   data: {
            //     id: 6,
            //     payment: "PAYMENTS_WAY",
            //   },
            //   name: "paymenths_way",
            //   img: "https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1650637879/komerica/logo-7_mbgqe5.png",
            // },
            {
              data: {
                id: 9,
                payment: "TU_COMPRA",
              },
              name: "tu_compra",
              img: require("../../../assets/payments/tucompra.png"),
            },
            {
              data: {
                id: 12,
                payment: "NEQUI",
              },
              name: "nequi",
              img: require("../../../assets/payments/logoNequi.svg"),
            },
          ],
          Chile: [
            {
              data: {
                id: 7,
                payment: "FLOW",
              },
              name: "flow",
              img: require("../../../assets/payments/flow.png"),
            },
          ],
          Perú: [
            {
              data: {
                id: 10,
                payment: "WEPAY4U",
              },
              name: "wepay4u",
              img: "https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1639078440/wePay4u/powered_by_z7sgqp.png",
            },
          ],
        };

        return paymentMappings[country] || [];
      }

      return [];
    },
    storeActiveAddi() {
      // if (
      //   this.storeData?.id === 1559 ||
      //   this.storeData?.id === 12216 ||
      //   this.storeData?.id === 13015 ||
      //   this.storeData?.id === 7454 ||
      //   this.storeData?.id === 582
      // ) {
      return true;
      // } else {
      //   return false;
      // }
    },
    // this.storeData?.id === 12216 || // Tienda Splash
    // this.storeData?.id === 13015 || // Tienda Ecotrade
    // this.storeData?.id === 15929 || // Tienda Poster's
    // this.storeData?.id === 7454 || // Tienda Piolas y piolas
    // this.storeData?.id === 17460 || // Tienda Near Beauty
    // this.storeData?.id === 10302 || // Tienda ACTIVE HOME
  },
  watch: {
    mercadolibreUser() {
      this.showAlertErrorMercadopago();
    },
    storeData() {
      this.existMercadopagoCode();
    },
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "error")) {
      this.$swal(
        "Usuario no autorizado",
        "El usuario de Mercado Pago no esta autorizado, cierra sesión en mercado pago e ingresa con el usuario autorizado",
        "error"
      );
    }
  },
  async mounted() {
    this.existMercadopagoCode();
    this.selectRadio();
  },
  methods: {
    selectRadio() {
      if (this.$route.query?.Gateways) {
        this.selectPayment = {
          id: this.$route.query.id,
          payment: this.$route.query.Gateways,
        };
        this.getPaymentStore(this.selectPayment);
      }
    },
    async getPaymentStore(value) {
      if (value) {
        this.setInformationFromQuery({
          OptMethod: 1,
          Gateways: value.payment,
          id: value.id,
          refreshtoken: this.$route.query?.refreshtoken || null,
          code: this.$route.query?.code || null,
        });

        this.statusData = false;
        const { success } = await this.$store.dispatch("GET_DATA_PAYMENTS", {
          payment: value.payment,
        });
        if (success) {
          this.statusData = true;
          if (value.payment === "WEPAY4U") {
            this.validateKey(this.paymentsData.wepay4u.key);
          }
          if (
            this.$route.query?.refreshtoken &&
            value.payment === "MERCADOPAGO"
          ) {
            this.updateTokenMercadoPago();
          }
        } else {
          this.statusData = false;
          this.$message.error(
            `Error al traer los datos, fallo de comunicación, payment: ${value.payment}`
          );
        }
      }
    },
    async setInformationFromQuery(params) {
      const query = {
        code: params.code !== null ? params.code : undefined,
        refreshtoken:
          params.refreshtoken !== null ? params.refreshtoken : undefined,
        OptMethod: params.OptMethod !== null ? params.OptMethod : undefined,
        Gateways: params.Gateways !== null ? params.Gateways : undefined,
        id: params.id !== null ? params.id : undefined,
      };

      const currentQuery = { ...this.$route.query };
      const currentHash = this.$route.hash;

      if (
        JSON.stringify(query) !== JSON.stringify(currentQuery) ||
        currentHash !== "3"
      ) {
        try {
          await this.$router.push({
            query,
            hash: "3",
          });
        } catch (error) {
          console.error("Error navigating:", error);
        }
      } else {
        console.log("Navegación redundante evitada");
      }
    },
    validateAndSubmit() {
      this.$refs.observer
        .validate()
        .then(async (response) => {
          if (response) {
            const paymentData =
              this.paymentsData[this.selectPayment.payment.toLowerCase()];
            if (paymentData?.createdAt) {
              this.processPaymentData(
                paymentData,
                this.selectPayment.payment,
                "UPDATE_DATA_PAYMENT"
              );
            } else {
              this.processPaymentData(
                paymentData,
                this.selectPayment.payment,
                "CREATED_PAYMENTS"
              );
            }
          } else {
            this.$message.warning(`Todos los campos son requeridos`);
          }
        })
        .catch((error) => {
          this.$message.error(`La validación del formulario falló, ${error}`);
        });
    },
    async processPaymentData(data, payment, action) {
      this.statusData = false;
      const tempData = JSON.parse(JSON.stringify(data));
      const keysToDelete = ["id", "createdAt", "updatedAt", "deletedAt"];
      keysToDelete.forEach((key) => delete tempData[key]);
      if (action === "CREATED_PAYMENTS") {
        tempData[payment === "ADDI" ? "storeId" : "tiendaId"] =
          this.storeData.id;
      }
      const { success } = await this.$store.dispatch(action, {
        payment,
        data: tempData,
        storeId: this.storeData.id,
      });
      if (success) {
        this.getPaymentStore(this.selectPayment);
        this.statusData = true;
        this.$message.success(
          `Datos ${
            action === "CREATED_PAYMENTS" ? "ingresados" : "actualizados"
          } correctamente!`
        );
      } else {
        this.statusData = true;
        this.$message.error(
          `Error al ${
            action === "CREATED_PAYMENTS" ? "ingresar" : "actualizar"
          } los datos, fallo de comunicación`
        );
      }
    },
    async changeStatePayment(payment, state) {
      this.statusData = false;
      const { success } = await this.$store.dispatch(
        "ACTIVATE_OR_DISABLE_PAYMENT",
        {
          state,
          payment,
          storeId: this.storeData.id,
        }
      );
      // payment === "PAYMENTS_WAY" ||
      if (success) {
        if (payment === "WEPAY4U" || payment === "TU_COMPRA") {
          state === "DEACTIVATE"
            ? (this.paymentsData[payment.toLowerCase()].estado = 0)
            : (this.paymentsData[payment.toLowerCase()].estado = 1);

          this.updatePayment(this.paymentsData[payment.toLowerCase()], payment);
        }
        await this.$store.dispatch("GET_STORE_PAYMENTS", {
          storeId: this.storeData.id,
        });
        this.statusData = true;
        this.$message.success(
          `Pasarela de pagos ${payment} esta ${
            state === "ACTIVATE" ? "activa" : "desactivada"
          }!`
        );
      } else {
        this.statusData = true;
        this.$message.error(
          "Error al cambiar de estado, fallo de comunicación"
        );
      }
    },
    async validateKey(value) {
      try {
        const { data } = await axios.get(
          "https://payment.wepay4u.com/api/v1/validate_service",
          {
            auth: {
              username: value,
              password: "",
            },
          }
        );
        if (data.status) {
          this.statusMsgWepay4u = false;
          this.statusKeyWepay4u = true;
          this.$message({
            type: "success",
            message: "Llave correcta",
          });
        }
      } catch (err) {
        this.$message({
          type: "error",
          message: "Llave incorrecta",
        });
        this.statusKeyWepay4u = false;
        this.statusMsgWepay4u = true;
      }
    },
    showAlertErrorMercadopago() {
      if (
        this.paymentGateways.mercadopago === 1 &&
        this.mercadolibreUser === null
      ) {
        this.$swal(
          "Conexión invalida",
          "Vuelva a conectar su cuenta Mercadopago",
          "error"
        );
      }
    },
    redirectMercadopago() {
      window.location.href = this.mercadoPagoUrl;
    },
    existMercadopagoCode() {
      if (
        this.$route.query?.code &&
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais
      ) {
        this.getMerdopagocredentials(this.$route.query.code);
      }
    },
    async getMerdopagocredentials(code) {
      console.log("Lectura de codigo mercadoPago", code);
      const { success, data } = await this.$store.dispatch(
        "GET_MERCADO_PAGO_CREDENTIALS",
        {
          code: code,
          country: this.storeData.informacion_tienda[0].pais,
        }
      );
      if (success) {
        this.updateMercadopago(data);
      } else {
        this.$message.error(data.data.message);
      }
    },
    async updateMercadopago(dataMercadoPago) {
      this.getDataUserMercadoPago(dataMercadoPago);
      let mercadopago = {
        publicKey: dataMercadoPago.public_key,
        accessToken: dataMercadoPago.access_token,
        refreshToken: dataMercadoPago.refresh_token,
        collectorId: dataMercadoPago.user_id.toString(),
      };
      const { success } = await this.$store.dispatch(
        "SET_INTEGRATION_MERCADO_PAGO",
        {
          publicKey: mercadopago.publicKey,
          accessToken: mercadopago.accessToken,
          refreshToken: mercadopago.refreshToken,
          collectorId: mercadopago.collectorId,
          // idTienda: this.storeData.id,
        }
      );
      if (success) {
        this.changeStatePayment("MERCADOPAGO", "ACTIVATE");
        this.$message.success("Felicitaciones MercadoPago conectado!");
        this.selectPayment = {
          id: 3,
          payment: "MERCADOPAGO",
        };
        this.getPaymentStore(this.selectPayment);
        this.statusData = true;
      } else {
        this.$message.error("La información no subio al servidor");
        this.statusData = true;
      }
    },
    getDataUserMercadoPago(data) {
      this.$store.dispatch("GET_MERCADO_LIBRE_USER", {
        access_token: data.access_token,
      });
    },
    async updateTokenMercadoPago() {
      this.statusData = false;
      this.$message.warning(
        `¡Tranquil@! Estamos actualizando el token de Mercado Pago.`
      );
      const { success, data } = await this.$store.dispatch(
        "RENOVATE_ACCESS_TOKEN_MERCADO_PAGO",
        {
          country: this.storeData.informacion_tienda[0].pais,
          refresh_token: this.paymentsData.mercadopago.refreshToken,
        }
      );
      if (success) {
        this.$router.push({
          path: "",
          query: {
            OptMethod: 1,
            Gateways: "MERCADOPAGO",
            id: 3,
          },
          hash: "3",
        });
        this.updateMercadopago(data);
        this.$message.success(`Mercado pago actualizado correctamente!`);
      } else {
        console.log("error al refrescar el token de mercadoPago");
        this.statusData = true;
      }
    },
    getCopyLink(valueIndex) {
      const urls = {
        1: "https://api2.komercia.co/api/payments-way/hook",
        2: "https://api2.komercia.co/api/tu-compra/hook",
        3: "https://api2.komercia.co/api/wepay4u/hook",
        4: "https://api.komercia.app/api/v1/hooks/public/proccess-wompi-payment-status",
      };

      if (!urls[valueIndex]) return;

      const el = document.createElement("textarea");
      el.value = urls[valueIndex];

      switch (valueIndex) {
        case 1:
          this.stateCopyPaymentWay = true;
          this.copyPaymentWay = "Copiado";
          break;
        case 2:
          this.stateCopyTucompra = true;
          this.copyTucompra = "Copiado";
          break;
        case 3:
          this.stateCopyWepay4u = true;
          this.copyWepay4u = "Copiado";
          break;
        case 4:
          this.stateCopyWompi = true;
          this.copyWompi = "Copiado";
          break;
      }

      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    async changeModeAddi(value) {
      this.statusData = false;
      const { success } = await this.$store.dispatch("CHANGE_MODE_ADDI", {
        productionMode: value,
        storeId: this.storeData.id,
      });
      if (success) {
        this.statusData = true;
        this.$message.success(
          `Addi cambio a modo ${value === 1 ? "producción" : "pruebas"}!`
        );
      } else {
        this.statusData = true;
        this.$message.error("Error al cambiar de modo, fallo de comunicación");
      }
    },
  },
};
</script>

<style scoped>
.el-icon-success {
  font-size: 15px;
  color: #00d57e;
}
.icon_color-error {
  font-size: 15px;
  color: #d50000;
}
.wrapper-cards >>> .el-radio-button__inner {
  background: #ececec;
  border-radius: 12px !important;
  @apply w-full max-w-[210px] h-[66px] flex flex-row justify-center items-center;
}
.wrapper-cards >>> .el-radio-button:first-child .el-radio-button__inner {
  border: none !important;
  @apply rounded-md;
}
.wrapper-cards >>> .el-radio-button:last-child .el-radio-button__inner {
  border: none !important;
  @apply rounded-md;
}
.wrapper-cards
  >>> .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: #e0faef !important;
  border-color: transparent !important;
  border: none !important;
  box-shadow: -1px 0 0 0 #e0faef;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 1px #cdcdcd;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
  background-color: #233345;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
  background-color: #f3f4f6;
}
.header-copy {
  width: 85px;
  background: transparent;
  cursor: pointer;
}
.copy-text {
  color: green;
}
</style>
