// paáina de los iconos https://materialdesignicons.com/

import Vue from "vue";
import "vue-material-design-icons/styles.css";
import FormatBold from "vue-material-design-icons/FormatBold.vue";
import FormatItalic from "vue-material-design-icons/FormatItalic.vue";
import FormatStrikethroughVariant from "vue-material-design-icons/FormatStrikethroughVariant.vue";
import FormatUnderline from "vue-material-design-icons/FormatUnderline.vue";
import FormatPilcrow from "vue-material-design-icons/FormatPilcrow.vue";
import FormatHeader1 from "vue-material-design-icons/FormatHeader1.vue";
import FormatHeader2 from "vue-material-design-icons/FormatHeader2.vue";
import FormatHeader3 from "vue-material-design-icons/FormatHeader3.vue";
import FormatListBulleted from "vue-material-design-icons/FormatListBulleted.vue";
import FormatListNumbered from "vue-material-design-icons/FormatListNumbered.vue";
import FormatQuoteClose from "vue-material-design-icons/FormatQuoteClose.vue";
import CodeTags from "vue-material-design-icons/CodeTags.vue";
import CodeNotEqualVariant from "vue-material-design-icons/CodeNotEqualVariant.vue";
import Redo from "vue-material-design-icons/Redo.vue";
import Undo from "vue-material-design-icons/Undo.vue";
import Image from "vue-material-design-icons/Image.vue";
import LinkVariant from "vue-material-design-icons/LinkVariant.vue";
import CloseCircle from "vue-material-design-icons/CloseCircle.vue";
import Youtube from "vue-material-design-icons/Youtube.vue";
import Circle from "vue-material-design-icons/CheckboxBlankCircle.vue";

import CheckAll from "vue-material-design-icons/CheckAll.vue";
import Check from "vue-material-design-icons/CheckBold.vue";

import Table from "vue-material-design-icons/Table.vue";
import TableRemove from "vue-material-design-icons/TableRemove.vue";
import TableColumnPlusAfter from "vue-material-design-icons/TableColumnPlusAfter.vue";
import TableColumnPlusBefore from "vue-material-design-icons/TableColumnPlusBefore.vue";
import TableColumnRemove from "vue-material-design-icons/TableColumnRemove.vue";
import TableRowPlusBefore from "vue-material-design-icons/TableRowPlusBefore.vue";
import TableRowPlusAfter from "vue-material-design-icons/TableRowPlusAfter.vue";
import TableRowRemove from "vue-material-design-icons/TableRowRemove.vue";

import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";

Vue.component("bold-icon", FormatBold);
Vue.component("italic-icon", FormatItalic);
Vue.component("strike-icon", FormatStrikethroughVariant);
Vue.component("underline-icon", FormatUnderline);
Vue.component("pilcrow-icon", FormatPilcrow);
Vue.component("header1-icon", FormatHeader1);
Vue.component("header2-icon", FormatHeader2);
Vue.component("header3-icon", FormatHeader3);
Vue.component("listbulleted-icon", FormatListBulleted);
Vue.component("listNumberd-icon", FormatListNumbered);
Vue.component("quoted-icon", FormatQuoteClose);
Vue.component("code-icon", CodeTags);
Vue.component("codeBlack-icon", CodeNotEqualVariant);
Vue.component("undo-icon", Undo);
Vue.component("redo-icon", Redo);
Vue.component("imgen-icon", Image);
Vue.component("link-icon", LinkVariant);
Vue.component("close-icon", CloseCircle);
Vue.component("youtube-icon", Youtube);

Vue.component("table-icon", Table);
Vue.component("tableRemove-icon", TableRemove);
Vue.component("tableColumnPlusAfter-icon", TableColumnPlusAfter);
Vue.component("tableColumnPlusBefore-icon", TableColumnPlusBefore);
Vue.component("tableColumnRemove-icon", TableColumnRemove);
Vue.component("tableRowPlusBefore-icon", TableRowPlusBefore);
Vue.component("tableRowPlusAfter-icon", TableRowPlusAfter);
Vue.component("tableRowRemove-icon", TableRowRemove);

Vue.component("arrowDown-icon", ChevronDown);
Vue.component("arrowUp-icon", ChevronUp);
Vue.component("circle-icon", Circle);

Vue.component("check-all-icon", CheckAll);
Vue.component("check-icon", Check);
