import Vue from "vue";
import Vuex from "vuex";
import state from "@/store/state";
import mutations from "@/store/mutations";
import actions from "@/store/actions";
import getters from "@/store/getters";
import pos from "@/store/modules/pos";
import envioclick from "@/store/modules/envioclick";
import mercadopago from "@/store/modules/mercadopago";
import mensajerosurbanos from "@/store/modules/mensajerosurbanos";
import mipaquete from "@/store/modules/mipaquete";
import academia from "@/store/modules/academia";
import helpDesk from "@/store/modules/helpDesk";
import statistics from "@/store/modules/statistics";
import vacantes from "@/store/modules/vacantes";
import partners from "@/store/modules/partners";
import analyticsStore from "@/store/modules/analyticsStore";
import notificationStore from "@/store/modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    pos,
    shipping: envioclick,
    mercadopago,
    mensajerosurbanos,
    mipaquete,
    academia,
    helpDesk,
    vacantes,
    statistics,
    partners,
    analyticsStore,
    notificationStore,
  },
});
