<template>
  <div class="row">
    <MultiplesProductsDialog :products="productsData" />
    <div class="col-md-12">
      <!-- <div class="products_list" v-if="productsFilter.length != 0">
        <div
          class="container-new-product"
          v-if="infoPaginationProducts.total < settingProducts.amount"
        >
          <div
            @click="alert"
            class="box new_product"
            :class="darkMode  ? 'darkbg' : 'lightbg'"
          >
             <img loading="lazy" src="../../assets/addphoto.jpg" alt="agregar producto" />
            <span class="tag-new">
              <i class="fas fa-plus"></i>{{ $t('text_ventas_nuevo_producto') }}
            </span>
          </div>
          <el-button
            class="buttonActions-responsive"
            @click="openMultiplesProducts"
          >
             <img loading="lazy"
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649343897/5859742_yz5cos.png"
              alt="Icono exportar"
              width="30px"
            />
          </el-button>
        </div>
        <template v-for="product in productsFilter">
          <product-card :key="product.id" :data="product" />
        </template>
      </div> -->
      <div class="products_list">
        <div
          v-if="infoPaginationProducts.total < settingProducts.amount"
          class="container-new-product"
        >
          <div
            :class="darkMode ? 'darkbg' : 'lightbg'"
            class="box new_product"
            @click="alert"
          >
            <img
              loading="lazy"
              src="../../assets/addphoto.jpg"
              alt="agregar producto"
            />
            <span class="tag-new">
              <i class="fas fa-plus"></i>
              {{ $t("text_ventas_nuevo_producto") }}
            </span>
          </div>
          <!-- <el-button
            class="buttonActions-responsive"
            @click="openMultiplesProducts"
          >
             <img loading="lazy"
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649343897/5859742_yz5cos.png"
              alt="Icono exportar"
              width="30px"
            />
          </el-button> -->
        </div>
        <div v-for="(product, index) in productsData" :key="index">
          <product-card :key="product.id" :data="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProductCard from "@/components/ProductCard";
import MultiplesProductsDialog from "@/components/MultiplesProducts";
export default {
  components: {
    ProductCard,
    MultiplesProductsDialog,
  },
  props: {
    productsFilter: {
      type: Array,
      required: true,
    },
    productsData: {
      type: Array,
      required: true,
    },
    validateAmount: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      productFields: {
        foto: "foto_cloudinary",
        id_foto: "id_cloudinary",
        nombre: "nombre",
        marca: "marca",
        precio: "precio",
        descripcion: "descripcion",
        video: "video",
        sku: "sku",
        peso: "peso",
        inventario: "inventario",
      },
      dialogVisible: false,
      tabAction: "export",
    };
  },
  computed: {
    ...mapState(["infoPaginationProducts"]),
    darkMode() {
      return this.$store.state.darkMode;
    },
    categoriesData() {
      return this.$store.state.categoriesData.filter(
        (category) => category.producto_count !== 0
      );
    },
    settingProducts() {
      return this.$store.getters.settingProducts;
    },
    // validateAmount() {
    //   return this.$store.state.productsData.length < this.settingProducts.amount
    // },
  },
  methods: {
    alert() {
      if (!this.validateAmount) {
        this.$message({
          showClose: true,
          message: "Has superado el límite de productos de tu plan",
          type: "warning",
        });
      } else {
        this.$router.push("/inventario/productos/nuevo");
      }
    },
    openMultiplesProducts() {
      this.$store.state.modalExport = true;
    },
  },
};
</script>

<style scoped>
.darkbg {
  background-color: #283c51;
}
.lightbg {
  background-color: #fff;
}
.content-wrapper {
  background-color: #eee;
}
.product-item {
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
/* .products_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
} */
.products_list {
  @apply grid w-full grid-cols-1 gap-3;
}
@screen sm {
  .products_list {
    @apply grid-cols-2;
  }
}
@screen md {
  .products_list {
    @apply grid-cols-3;
  }
}
@screen lg {
  .products_list {
    @apply grid-cols-3;
  }
}
@screen xl {
  .products_list {
    @apply grid-cols-5;
  }
}
a.button {
  width: 220px;
  height: 45px;
  justify-content: center;
  justify-self: center;
}
a.button .upgrade {
  width: 100%;
  height: 100%;
}
a.button p {
  width: 130px;
}
a.button i {
  font-size: 30px;
}
.download_excel {
  cursor: pointer;
}
.download_excel img {
  width: 40px;
  height: 40px;
}
.new_product {
  @apply h-full border border-green-komercia-600 border-2;
  min-height: 320px;
  /* height: 100%; */
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  /* border: 1px solid hsla(252, 63%, 43%, 0.361); */
}
.new_product:hover {
  /* transform: scale(0.98); */
  @apply shadow-lg;
  /* box-shadow: 0 0 14px 8px hsla(252, 63%, 43%, 0.118); */
}
.new_product img {
  max-width: 120px;
  max-height: 120px;
  object-fit: contain;
  margin-bottom: 20px;
  border-radius: 4px;
}
.new_product h3 {
  color: #474d68;
  font-size: 13px;
}
/* .row span {
  color: var(--purple);
} */
.new_product p {
  text-align: center;
  font-size: 13px;
  padding: 0 30px;
  color: var(--purple);
}
.buttonActions-responsive {
  display: none;
}
.tag-new {
  @apply bg-green-komercia-600 text-green-komercia-900;
  /* background-color: #cabefb; */
  /* color: var(--purple); */
  padding: 4px 10px;
  border-radius: 4px;
}
@media (max-width: 1366px) {
  .actions {
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "title actions"
      "search search";
  }
  .actions > div:first-child {
    align-self: flex-start;
  }
  .actions > div:last-child {
    align-self: flex-end;
  }
  .products_list {
    @apply grid-cols-4;
  }
}
@media (max-width: 1280px) {
  .products_list {
    @apply grid-cols-3;
  }
}
@media (max-width: 767px) {
  .products_list {
    display: flex;
    flex-direction: column;
  }
  .products_list .product-item {
    background: transparent;
    box-shadow: unset;
    border-radius: 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  }
  .new_product {
    /* display: flex; */
    min-height: auto;
    /* justify-content: flex-start; */
    background: rgba(218, 218, 218, 0.36);
    border: 0;
    background: transparent;
    box-shadow: none;
  }
  .new_product:hover {
    box-shadow: none;
  }
  .new_product h3,
  .new_product .text {
    display: none;
  }
  .new_product img {
    margin-bottom: 0px;
    display: none;
  }
  .new_product p {
    color: black;
    font-weight: 500;
    /* font-family: 'Montserrat', sans-serif; */
    padding: 0 5px;
  }
  .tag-new {
    padding: 10px 20px;
  }
  .buttonActions-responsive {
    display: flex;
    color: var(--purple);
    border: 1px solid var(--purple);
    height: 41px;
    /* margin-bottom: 20px; */
    justify-content: center;
    align-items: center;
    max-width: 171px;
  }
  .container-new-product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}
@media (max-width: 500px) {
  .actions .el-input {
    display: none;
  }
}
@media (max-width: 445px) {
  .new_product {
    width: 100%;
    text-align: center;
  }
  .buttonActions-responsive {
    height: 52px;
  }
  .tag-new[data-v-0de9c0b1] {
    padding: 5px 20px;
  }
}
</style>
