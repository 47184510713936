import axios from "axios";
import storelayout from "@/utils/storelayout.js";
// import { db } from '@/utils/data'
import moment from "moment";
export default {
  // GETDATAHELP({ state }) {
  //   db.collection('categories')
  //     .get()
  //     .then(querySnapshot => {
  //       querySnapshot.forEach(doc => {
  //         state.Helpcategories.push(doc.data())
  //       })
  //     })
  //   db.collection('listarticles')
  //     .get()
  //     .then(querySnapshot => {
  //       querySnapshot.forEach(doc => {
  //         state.Helplistarticulos.push(
  //           Object.assign({}, doc.data(), { id: doc.id })
  //         )
  //       })
  //     })
  // },
  // CREATE_NOTIFICATION({ state }, order) {
  //   db.collection('notifications')
  //     .add({
  //       created_at: new Date(),
  //       status: 0,
  //       type: 1,
  //       store_id: state.storeData.id,
  //       user_id: state.configKomercia.usuario.id,
  //       user: {
  //         id: state.configKomercia.usuario.id,
  //         photo: state.configKomercia.usuario.foto,
  //         name: state.configKomercia.usuario.nombre,
  //       },
  //       order: {
  //         id: order.id,
  //         total: order.total,
  //       },
  //     })
  //     .then(function(docRef) {
  //       console.log('Document written with ID: ', docRef.id)
  //     })
  //     .catch(function(error) {
  //       console.error('Error adding document: ', error)
  //     })
  // },
  GET_PRODUCTS({ state }, { page, status, per_page }) {
    axios
      .get(
        `${state.configKomercia.url}/api/admin/productos/paginados?page=${page}&per_page=${per_page}&status=${status}`,
        state.configAxios
      )
      .then((response) => {
        state.infoPaginationProducts = response.data.productos;
        state.productsData = response.data.productos.data;
      })
      .catch((error) => console.log(error));
  },
  GET_PRODUCTS_BY_CATEGORY({ state }, params) {
    return axios
      .get(
        `${state.configKomercia.url}/api/admin/productos/category/search?q=${params.idCategory}&page=${params.page}&per_page=24`,
        state.configAxios
      )
      .then((response) => {
        state.infoPaginationProducts = response.data.productos;
        state.productsData = response.data.productos.data;
      })
      .catch((error) => {
        return error;
      });
  },
  GET_PRODUCTS_BY_NAME({ state }, params) {
    axios
      .get(
        `${state.configKomercia.url}/api/admin/productos/search?q=${params.search}&page=${params.page}&per_page=24`,
        state.configAxios
      )
      .then((response) => {
        state.infoPaginationProducts = response.data.productos;
        state.productsData = response.data.productos.data;
      })
      .catch((error) => console.log(error));
  },
  PRODUCTS_INACTIVOS({ state }, page = 1) {
    axios
      .get(
        `${state.configKomercia.url}/api/admin/productos/paginados?page=${page}&per_page=24&status=0`,
        state.configAxios
      )
      .then((response) => {
        state.infoPaginationProductsInactivos = response.data.productos;
        state.productsInactivos = response.data.productos.data;
      })
      .catch((error) => console.log(error));
  },
  GET_CATEGORIES({ state }) {
    axios
      .get(
        `${state.configKomercia.url}/api/admin/categorias`,
        state.configAxios
      )
      .then((response) => {
        state.categoriesData = response.data.data.categorias.map((category) => {
          category.edit = false;
          return category;
        });
        state.subcategoriesData = response.data.data.subcategorias.map(
          (subcategory) => {
            subcategory.edit = false;
            return subcategory;
          }
        );
      });
  },
  GET_POLICIES({ state }) {
    axios
      .get(`${state.configKomercia.url}/api/admin/politicas`, state.configAxios)
      .then((response) => {
        state.policiesData = response.data.data || {};
        if (response.data.data) {
          state.shippingPolicies = response.data.data.envios;
        }
      });
  },
  GET_SHIPPING({ state }) {
    axios
      .get(`${state.configKomercia.url}/api/admin/envios`, state.configAxios)
      .then((response) => {
        state.shippingData = response.data.data.informacion;
      });
  },
  // GET_ORDERS({ state }, page = 1) {
  //   axios
  //     .get(
  //       `${state.configKomercia.url}/api/admin/ventas/ordenes/paginacion?per_page=15&page=${page}`,
  //       state.configAxios
  //     )
  //     .then((response) => {
  //       state.ordersData = response.data;
  //     });
  // },
  // GET_ALL_ORDERS({ state }) {
  //   axios
  //     .get(
  //       `${state.configKomercia.url}/api/admin/ventas/ordenes`,
  //       state.configAxios
  //     )
  //     .then((response) => {
  //       let orders = response.data.data.ventas;
  //       let quotations = response.data.data.cotizaciones;
  //       state.allOrdersData = quotations.concat(orders);
  //     });
  // },
  // async GET_FILTER_ORDERS_ID_OR_STATE({ state }, params) {
  //   try {
  //     const { data } = await axios({
  //       method: "GET",
  //       url: `${state.configKomercia.url}/api/admin/ventas/ordenes/filters`,
  //       headers: state.configAxios.headers,
  //       params: {
  //         type: params.type || null,
  //         value: params.value || null,
  //         page: params.page || null,
  //       },
  //     });
  //     if (data) {
  //       return { success: true, data: data };
  //     }
  //   } catch (err) {
  //     return { success: false, data: err.response };
  //   }
  // },
  // async GET_FILTER_ORDERS_DATE({ state }, params) {
  //   try {
  //     const { data } = await axios({
  //       method: "GET",
  //       url: `${state.configKomercia.url}/api/admin/ventas/ordenes/filters`,
  //       headers: state.configAxios.headers,
  //       params: {
  //         type: params.type || null,
  //         start: params.start || null,
  //         end: params.end || null,
  //         page: params.page || null,
  //       },
  //     });
  //     if (data) {
  //       return { success: true, data: data };
  //     }
  //   } catch (err) {
  //     return { success: false, data: err.response };
  //   }
  // },
  async GET_FILTER_ORDERS({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/sales/admin`,
        headers: state.configAxios.headers,
        params: {
          page: params.page || null,
          limit: params.limit || null,
          order: params.order || null,
          storeId: params.storeId || null,
          id: params.id || null,
          startDate: params.startDate || null,
          endDate: params.endDate || null,
          clientName: params.clientName || null,
          clientIdentification: params.clientIdentification || null,
          state: params.state || null,
          paymentMethod: params.paymentMethod || null,
        },
      });
      if (data) {
        state.allOrdersData = data;
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  GET_COUNTRIES({ state, commit }) {
    return axios
      .get(`${state.configKomercia.url}/api/paises`, state.configAxios)
      .then((response) => {
        commit("SET_COUNTRIES", response.data.data);
      });
  },
  async UPDATE_DEPARTMENTS({ state }, id) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/departamentos/${id}`,
      });
      if (data) {
        state.departments = data.data;
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_DEPARTMENTS({ state }) {
    try {
      if (state.currentCountry) {
        const { data } = await axios({
          method: "GET",
          url: `${state.configKomercia.url}/api/departamentos/${state.currentCountry}`,
          headers: state.configAxios.headers,
        });
        if (data) {
          state.departments = data.data;
          return { success: true, data: data };
        }
      } else {
        console.log("Null id Country");
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  GET_ADDONS({ state }) {
    axios
      .get(
        `${state.configKomercia.url}/api/admin/apis-externas`,
        state.configAxios
      )
      .then((response) => {
        if (response.data.data) {
          state.addons.facebook = response.data.data.facebook || "{}";
          state.addons.analytics = response.data.data.analytics || "{}";
        }
      });
  },
  SAVE_ADDON({ state }, { type, data }) {
    let params = {};
    params[type] = JSON.stringify(data);
    axios
      .post(
        `${state.configKomercia.url}/api/admin/apis-externas`,
        params,
        state.configAxios
      )
      .then(() => {});
  },
  // CHANGE_USER_STORE({ state }, params) {
  //   axios
  //     .post(
  //       `${state.configKomercia.url}/api/admin/tienda/cambiar`,
  //       params,
  //       state.configAxios
  //     )
  //     .then(response => {
  //       return response
  //     })
  // },

  // async GET_HELPDESK_DATA({ state }) {
  //   return await axios.get(
  //     `${state.configHelpdesk.url}articles/${state.miscSidebar.data}`
  //   )
  // },
  // async GET_HELPDESK_DATA({ state }) {
  //   return await axios.get(`${state.configHelpdesk.url}articles/`)
  // },
  // async GET_ANNOUNCEMENT_DATA({ state }) {
  //   return await axios.get(`${state.configHelpdesk.url}announcements/`);
  // },
  async GET_STORE({ state, dispatch }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/admin/tienda`,
        headers: state.configAxios.headers,
      });
      if (data) {
        dispatch("GET_CATEGORIES_FOR_STORES");
        state.storeData = data.data;
        // state.currentCountry = data.data.ciudad.departamento.paises_id
        state.currentCountry = data.data.informacion_tienda[0].pais.id;
        state.stateOnlineStore = !!data.data.estado;
        state.tasksData = data.data.tareas.map((task) => {
          task.done = task.done === 1;
          return task;
        });
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_CATEGORIES_FOR_STORES({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/categorias`,
        headers: state.configAxios.headers,
      });
      if (data) {
        state.categoriesForStores = data.data;
      }
    } catch (err) {
      console.log("Error al cargar el producto", id, err.response.data);
    }
  },
  GET_MESSAGES({ state }) {
    axios
      .get(
        `${state.configKomercia.url}/api/admin/tienda/mensajes/contacto`,
        state.configAxios
      )
      .then((response) => {
        response.data.data.forEach((doc) => {
          state.messages.push(
            Object.assign({}, doc, {
              hacecuanto: moment(doc.created_at).startOf("minute").fromNow(),
            })
          );
        });
      });
  },
  // GET_CATEGORIES_BY_STORES(state) {
  //   axios
  //     .get(`${state.configKomercia.url}/api/categorias`, state.configAxios)
  //     .then((response) => {
  //       state.storeData.categorias = response.data.data;
  //     });
  // },
  async GET_PRODUCT({ state, commit }, id) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/admin/productos/${id}`,
        headers: state.configAxios.headers,
      });
      if (data) {
        let storeData = data.data;
        storeData.detalle.envio_gratis = storeData.detalle.envio_gratis || 0;
        storeData.detalle.activo = storeData.detalle.activo.toString();
        storeData.detalle.garantia = storeData.info.garantia
          ? storeData.info.garantia.toString()
          : "";
        storeData.detalle.categoria_producto.id =
          storeData.detalle.categoria_producto.id === 0
            ? ""
            : storeData.detalle.categoria_producto.id;
        storeData.detalle.subcategoria_producto.id =
          storeData.detalle.subcategoria_producto.id === 0
            ? ""
            : storeData.detalle.subcategoria_producto.id;
        commit("SET_PRODUCT", storeData);
        return { success: true, data: storeData };
      }
    } catch (err) {
      console.log("Error al cargar el producto", id, err.response.data);
      return { success: false };
    }
  },
  updateLimitProduct({ commit }, value) {
    commit("UPDATE_LIMIT_PRODUCTS", value);
  },
  async CREATE_PRODUCT({ state, commit }, product) {
    let params = new FormData();
    params.append("foto_cloudinary", product.foto);
    params.append("id_foto", product.id_foto);
    params.append("nombre", product.nombre);
    params.append("marca", product.marca);
    params.append("precio", product.precio || 0);
    params.append("disponibilidad", 1);
    params.append("descripcion", product.descripcion);
    params.append("video", product.video);
    params.append("categoria_producto", 0);
    params.append("subcategoria", 0);
    params.append("activo", 1);
    params.append("sku", product.sku);
    params.append("peso", product.peso);
    params.append("garantia", product.garantia);
    params.append("inventario", product.inventario);
    params.append("variantes", product.variantes);
    params.append("combinaciones", product.combinaciones);
    params.append("envio_gratis", product.envio_gratis);
    params.append("con_variante", product.con_variante);
    const response = await axios
      .post(
        `${state.configKomercia.url}/api/admin/productos`,
        params,
        state.configAxios
      )
      .catch((error) => {
        let showlimit = true;
        state.alertLimitProducts.text = error.response.data.error;
        commit("UPDATE_LIMIT_PRODUCTS", showlimit);
      });
    const data = response.data.data;
    state.productsData.unshift({
      activo: parseInt(response.data.data.detalle.activo),
      foto_cloudinary: response.data.data.detalle.foto_cloudinary,
      id: response.data.data.detalle.id,
      nombre: response.data.data.detalle.nombre,
      precio: response.data.data.detalle.precio || 0,
      visitas: 0,
      inventario: response.data.data.info[0].inventario,
      slug: response.data.data.detalle.slug,
      con_variante: response.data.data.detalle.con_variante,
      variantes: [
        {
          combinaciones: [
            {
              combinaciones: response.data.data.combinaciones.combinaciones,
            },
          ],
        },
      ],
    });
    commit("SET_TASK", { id: 1, status: true });
    return data;
  },
  async UPDATE_PRODUCT({ state }, params) {
    try {
      const response = await axios.put(
        `${state.configKomercia.url}/api/admin/productos/${params.id}`,
        params,
        state.configAxios
      );
      let productsData = state.productsData;
      let productIndex = productsData.findIndex(
        (product) => product.id === parseInt(params.id)
      );
      let newProduct = {
        updateTMP: true,
        activo: parseInt(response.data.data.detalle.activo),
        foto_cloudinary: response.data.data.detalle.foto_cloudinary,
        id: response.data.data.detalle.id,
        nombre: response.data.data.detalle.nombre,
        precio: response.data.data.detalle.precio || 0,
        visitas: 0,
        inventario: response.data.data.info[0].inventario,
        slug: response.data.data.detalle.slug,
        con_variante: response.data.data.detalle.con_variante,
        categoria_producto: response.data.data.detalle.categoria_producto,
        subcategoria_producto: response.data.data.detalle.subcategoria_producto,
        variantes: response.data.data.detalle.variantes,
      };
      productsData.splice(productIndex, 1, newProduct);
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  },
  async UPDATE_STATUS_PRODUCT({ state }, params) {
    return await axios.post(
      `${state.configKomercia.url}/api/admin/productos/cambiar-estado`,
      params,
      state.configAxios
    );
  },
  async SET_STATE_STORE({ state }, params) {
    return await axios
      .post(
        `${state.configKomercia.url}/api/admin/tienda/actualizar/estado`,
        params,
        state.configAxios
      )
      .then((response) => {
        state.stateOnlineStore = response.data.data;
      });
  },
  async DELETE_PRODUCT({ state }, id) {
    return await axios.delete(
      `${state.configKomercia.url}/api/admin/productos/${id}`,
      state.configAxios
    );
  },
  GET_USER({ state, commit } /*firebase*/) {
    axios
      .get(`${state.configKomercia.url}/api/user`, state.configAxios)
      .then((response) => {
        commit("SET_USER", response.data.data);
        state.configKomercia.usuario = response.data.data;
        storelayout.store_id = state.configKomercia.usuario.tienda;
        if (!response.data.data.panel) {
          state.welcomeTourStatus = true;
          // firebase
          //   .database()
          //   .ref(`stores/${state.configKomercia.usuario.tienda}`)
          //   .update(storelayout)
          axios.post(
            `${state.configKomercia.url}/api/usuario/tour/actualizar`,
            {
              tour: "panel",
              valor: true,
            },
            state.configAxios
          );
        }
        if (!state.configKomercia.usuario.tienda) {
          window.location.href = "https://perfil.komercia.co";
        }
      });
  },
  GET_USER_DATA({ state, commit }) {
    return axios
      .get(`${state.configKomercia.url}/api/user`, state.configAxios)
      .then((response) => {
        commit("SET_USER_DATA", response.data.data);
      });
  },

  // async CREATE_MERCADOPAGO({ state }) {
  //   const response = await axios.post(
  //     `${state.configKomercia.url}/api/admin/medios-pago/mercado-pago`,
  //     state.configAxios
  //   );
  //   return response.data;
  // },
  async GET_ORDER_BY_ID({ state, commit }, id) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/ventas/ordenes/${id}`,
      state.configAxios
    );
    commit("SET_CURRENTORDER", response.data.data);
    let data = response.data.data;
    let direccion = JSON.parse(data.venta.direccion_entrega);
    data.venta.direccion_entrega = direccion;
    this.orden = data;
  },
  async UPDATE_ORDER({ state, commit }, { data, total }) {
    let order = {
      id_orden: data.venta.id,
      productos: data.productos.map((product) => {
        return {
          unidades: product.unidades,
          precio: product.precio_producto,
          id: product.producto.id,
        };
      }),
      total,
    };
    const response = await axios.post(
      `${state.configKomercia.url}/api/admin/cotizacion/actualizar/a/venta`,
      order,
      state.configAxios
    );
    // commit("SET_ORDER_BY_ID", response.data.data);
  },
  async GET_BRANCH({ state, commit }) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/sedes`,
      state.configAxios
    );
    state.branchesData = response.data.data;
    commit("SET_BRANCH", response.data.data);
  },
  async CREATE_BRANCH({ state, commit, dispatch }, payload) {
    try {
      const response = await axios.post(
        `${state.configKomercia.url}/api/admin/sedes`,
        payload,
        state.configAxios
      );
      commit("ADD_BRANCH", response.data.data);
      dispatch("UPDATE_TASK_STATUS", { id: 7, status: true });
      return response;
    } catch (err) {
      return err.response;
    }
  },
  async UPDATE_BRANCH({ state, commit }, payload) {
    const response = await axios.put(
      `${state.configKomercia.url}/api/admin/sedes/${payload.id}`,
      payload,
      state.configAxios
    );
    const index = state.branchesData.findIndex(
      (branch) => branch.id === parseInt(payload.id)
    );
    commit("SET_BRANCH_BY_INDEX", { index, value: response.data.data });
  },
  async DELETE_HEADQUATER({ state }, id) {
    return await axios.delete(
      `${state.configKomercia.url}/api/admin/sedes/${id}`,
      state.configAxios
    );
  },
  async UPDATE_TASK_STATUS({ state, commit }, id) {
    const task = state.storeData.tareas.find(
      (task) => task.id_tarea === id
    ) || {
      done: true,
    };
    if (!task.done) {
      await axios.post(
        `${state.configKomercia.url}/api/admin/tienda/actualizar-tarea`,
        { tarea_id: id },
        state.configAxios
      );
      commit("SET_TASK", { id, status: true });
    }
  },

  async GET_INVOICE({ state, commit, dispatch }, id) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/admin/ventas/ordenes/${id}`,
        headers: state.configAxios.headers,
      });
      if (data?.estado === 200) {
        // dispatch("VALIDATE_INVOICE_WITH_NOTIFICATION", data);
        let direccion = JSON.parse(data?.data?.venta?.direccion_entrega);
        data.data.venta.direccion_entrega = direccion;
        commit("SET_INVOICE", data.data);
        return { success: true };
      }
    } catch (err) {
      if (err) {
        console.error("ERROR GET_INVOICE", err);
        return { success: false };
      }
    }
  },
  // VALIDATE_INVOICE_WITH_NOTIFICATION({ state }, data) {
  //   let notification;
  //   if (state.notifications) {
  //     notification = state.notifications.find(
  //       (item) => item.order.id === data.venta.id
  //     );
  //   }
  //   if (notification && notification.status == 0) {
  //     // dispatch('UPDATE_NOTIFICATION', notification)
  //     console.log(notification);
  //   }
  // },
  async GET_ANALYTICS({ state, commit }) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/apis-externas`,
      state.configAxios
    );
    const data = response.data.data || {
      analytics: "",
      tag_manager: "",
      tidio_user: "",
      pixel_facebook: "",
      facebook_pixel_metatag_1: "",
      facebook_chat: "",
      google_merchant: "",
    };
    commit("SET_ANALYTICS", data.analytics);
    commit("SET_TAGMANAGER", data.tag_manager);
    commit("SET_TIDIOUSER", data.tidio_user);
    commit("SET_PIXELFACEBOOK", data.pixel_facebook);
    commit("SET_METATAGFACEBOOK", data.facebook_pixel_metatag_1);
    commit("SET_MESSENGERFACEBOOK", data.facebook_chat);
    commit("SET_GOOGLEMERCHANTS", data.google_merchant);
  },
  async DELETE_INVOICE({ state, dispatch }, id) {
    try {
      const { data } = await axios({
        method: "DELETE",
        url: `${state.configKomercia.url}/api/admin/ventas/ordenes/${id}`,
        headers: state.configAxios.headers,
      });
      if (data) {
        // const { success } = await dispatch("GET_FILTER_ORDERS", {
        //   page: state.previousPageOrden,
        //   limit: 25,
        //   order: "DESC",
        //   storeId: state.storeData.id,
        //   method: "DELETE_INVOICE",
        // });
        return { success: true };
      }
    } catch (err) {
      console.log("DELETE_INVOICE", err?.response);
      return { success: false };
    }
  },
  async UPDATE_INVOICE({ state }, params) {
    try {
      const { data } = await axios.put(
        `${state.configKomercia.url}/api/admin/ventas/ordenes/${params.id}`,
        { estado: params.estado },
        state.configAxios
      );
      if (data) {
        return {
          success: true,
          data: data,
        };
      }
    } catch (err) {
      return {
        success: false,
        data: err.response,
      };
    }
  },
  async UPDATE_SHIPPING_STATE({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/ventas/orden/envio/estado`,
        headers: state.configAxios.headers,
        data: {
          estado: params.estado,
          carrito: params.carrito,
        },
      });
      if (data && data.estado == 200) {
        return { success: true, data: data.data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_CITIES({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/ciudades`,
      });
      if (data) {
        state.cities = data.data;
        localStorage.setItem("storeCities", JSON.stringify(state.cities));
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  GET_CITIES_BY_DEPARTMENT({ state, commit }, id = 2) {
    return axios
      .get(`${state.configKomercia.url}/api/ciudades/${id}`)
      .then((response) => {
        commit("SET_CITIES_BY_COUNTRY", response.data.data);
      });
  },
  async GET_SUBSCRIBERS({ state, commit }) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/tienda/suscriptores`,
      state.configAxios
    );
    const data = response.data.data;
    commit("SET_SUBSCRIBERS", data);
  },
  async GET_CUSTOMERS({ state, commit }, page) {
    try {
      const { data } = await axios.get(
        `${state.configKomercia.url}/api/admin/clientes/listado?page=${page}`,
        state.configAxios
      );
      commit("SET_CUSTOMERS", data.data);
      return { success: true, data: data.data };
    } catch (err) {
      return { success: false, data: err.response.data };
    }
  },
  async FILTER_CUSTOMERS({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/admin/clientes/filters`,
        headers: state.configAxios.headers,
        params: {
          type: params.type || null,
          value: params.value || null,
          page: params.page || null,
        },
      });
      if (data && data.estado === 200) {
        return { success: true, data: data.data };
      }
    } catch (err) {
      console.log(err.response);
      return { success: false, data: err.response };
    }
  },
  async CREATE_CUSTOMER({ state, dispatch }, params) {
    try {
      const response = await axios.post(
        `${state.configKomercia.url}/api/admin/clientes/crear`,
        params,
        state.configAxios
      );
      dispatch("GET_CUSTOMERS");
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async UPDATE_STORE({ state }, params) {
    // console.log(params)
    return await axios.post(
      `${state.configKomercia.url}/api/admin/tienda/informacion/cambiar`,
      params,
      state.configAxios
    );
  },
  async UPLOAD_EXTRA_PHOTO_OF_PRODUCT({ state }, params) {
    try {
      return await axios.post(
        `${state.configKomercia.url}/api/admin/producto/imagen/adicional/subir`,
        params,
        state.configAxios
      );
    } catch (err) {
      return err.response;
    }
  },
  async REMOVE_CLOUDINARY_PHOTO({ state }, id_cloudinary) {
    const params = {
      _method: "DELETE",
      id_cloudinary,
    };
    return await axios.post(
      `${state.configKomercia.url}/api/admin/producto/imagen/eliminar/cloudinary`,
      params,
      state.configAxios
    );
  },
  async UNLINK_CUSTOMER({ state, dispatch }, payload) {
    await axios.post(
      `${state.configKomercia.url}/api/admin/clientes/desvincular`,
      payload,
      state.configAxios
    );
    dispatch("GET_CUSTOMERS");
  },
  CREATE_COUPON({ state }, payload) {
    return axios.post(
      `${state.configKomercia.url}/api/admin/cupones`,
      payload,
      state.configAxios
    );
  },
  CREATE_DESCUENTOS({ state }, payload) {
    return axios.post(
      `${state.configKomercia.url}/api/admin/descuentos`,
      payload,
      state.configAxios
    );
  },
  CREATE_ARTICLE_BLOG({ state }, payload) {
    return axios.post(
      `${state.configKomercia.url}/api/admin/blog`,
      payload,
      state.configAxios
    );
  },
  async GET_ARTICLE({ state, commit }, id) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/blog/${id}`,
      state.configAxios
    );
    let data = response.data.blogs;
    commit("SET_ARTICLE", data);
  },
  PAY_SUBSCRIPTION({ state }, payload) {
    return axios.post(
      `${state.configKomercia.url}/api/admin/tienda/compra/plan`,
      payload,
      state.configAxios
    );
  },
  async CHANGE_TEMPLATE({ state, dispatch }, payload) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/tienda/actualizar/template`,
        data: payload,
        headers: state.configAxios.headers,
      });
      if (data) {
        dispatch("GET_STORE");
        return { success: true, data };
      }
    } catch (err) {
      console.error(`Error al cambiar template`);
      return { success: false, data: err.response };
    }
  },
  async CREATE_TEMPLATE_NODE({ state }, payload) {
    let dataTemplate = [];
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlNodeTemplates}/template${payload.id}`,
        data: dataTemplate,
        headers: state.configAxios.headers,
      });
      if (data && data.success) {
        console.log("template " + payload.id + " creado");
        return { success: true, data };
      } else {
        console.log(data.error);
      }
    } catch (err) {
      console.error(
        `Error al crear la plantilla ${payload.id}, ${err.response}`
      );
      return { success: false, data: err.response };
    }
  },
  async GET_SETTINGS_TEMPLATE({ state }, payload) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${
          payload.template === 15 || payload.template === 6
            ? `${state.urlKomerciaAWS}/api/v1/templates/websites/template?criteria=${payload.id}`
            : `${state.urlNodeTemplates}/template${payload.template}?id=${payload.id}`
        }`,
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      console.error(
        `Error al cargar datos de la plantilla ${payload.template}, ${err.response}`
      );
      return { success: false, data: err.response };
    }
  },
  // WEB_SITES
  async GET_WEB_SITES({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/templates/websites`,
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async CRATED_WEB_SITES({ state }, payload) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaAWS}/api/v1/templates/websites`,
        data: {
          subdomain: payload.subdomain,
          templateNumber: payload.templateNumber,
          domain: payload.domain,
          // demoId: payload.demoId, Campo adicional para copiar setting de una tienda
          demoId: null,
          isMain: false,
          active: true,
        },
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async UPDATE_WEB_SITES({ state }, payload) {
    try {
      const { data } = await axios({
        method: "PATCH",
        url: `${state.urlKomerciaAWS}/api/v1/templates/websites/${payload.id}`,
        data: {
          subdomain: payload.subdomain,
          templateNumber: payload.templateNumber,
          domain: payload.domain,
        },
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async DELETE_WEB_SITES({ state }, payload) {
    // console.log(payload);
    try {
      const { data } = await axios({
        method: "DELETE",
        url: `${state.urlKomerciaAWS}/api/v1/templates/websites/${payload.id}`,
        headers: state.configAxios.headers,
        params: {
          templateId: payload.templateId || null,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_DOMAIN_AVAILABLE_WEBSITE({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaAWS}/api/v1/templates/websites/domain?domain=${params.domain}`,
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_SUBDOMAIN_AVAILABLE_WEBSITE({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaAWS}/api/v1/templates/websites/subdomain?subdomain=${params.subdomain}`,
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async DELETE_TEMPLATE_NODE({ state }, payload) {
    try {
      const { data } = await axios.delete(
        `${
          payload === 15 || payload === 6
            ? state.urlNodeTemplatesDemo
            : state.urlNodeTemplates
        }/template${payload}`,
        state.configAxios
      );
      return { success: true, data: data };
    } catch (err) {
      return { success: false, data: err.response.data };
    }
  },
  async GETCUBIKO({ commit, state }) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/cubiko`,
      state.configAxios
    );
    commit("SET_DATA_CUBIKO", response.data.data);
  },
  async SAVECUBIKO({ state }, payload) {
    // if (payload) {
    const params = {
      estado: payload ? true : false,
      nombre: "Cubiko",
      api_key: "Cubiko",
      redirect_url: "https://www.cubiko.co/",
    };
    await axios.post(
      `${state.configKomercia.url}/api/admin/cubiko`,
      params,
      state.configAxios
    );
    // }
  },
  SETDATAHOKO({ dispatch, state }, payload) {
    axios.post(
      `${state.configKomercia.url}/api/admin/hoko`,
      payload,
      state.configAxios
    );
    dispatch("GETDATAHOKO");
  },
  async GETDATAHOKO({ state }) {
    const response = await axios.get(
      `${state.configKomercia.url}/api/admin/hoko`,
      state.configAxios
    );
    // console.log(response)
    state.dataHoko = response.data.data;
  },
  //Etiquetas
  async GET_ALL_TAGS({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/admin/tag`,
        headers: state.configAxios.headers,
      });
      if (data) {
        state.tagsData = data.tags.data;
        return { success: true, data: data.tags.data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async CREATED_TAGS({ state, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/tag`,
        headers: state.configAxios.headers,
        data: {
          name: params.name,
          status: params.status,
          order: params.order,
          visible: params.visible,
          edit: false,
        },
      });
      if (data) {
        dispatch("GET_ALL_TAGS");
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async UPDATE_TAGS({ state, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/tag/${params.id}`,
        headers: state.configAxios.headers,
        data: {
          _method: "PUT",
          name: params.name,
          status: params.status,
          order: params.order,
          visible: params.visible,
          edit: false,
        },
      });
      if (data) {
        dispatch("GET_ALL_TAGS");
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async DELETE_TAGS({ state, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "DELETE",
        url: `${state.configKomercia.url}/api/admin/tag/${params.id}`,
        headers: state.configAxios.headers,
      });
      if (data && data.estado == 200) {
        dispatch("GET_ALL_TAGS");
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async CREATED_SUB_TAGS({ state, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/tag/${params.idTag}/add-property`,
        headers: state.configAxios.headers,
        data: {
          name: params.name,
          status: params.status,
          order: params.order,
          edit: false,
        },
      });
      if (data) {
        dispatch("GET_ALL_TAGS");
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async UPDATE_SUB_TAGS({ state, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/tag/edit-property/${params.id}`,
        headers: state.configAxios.headers,
        data: {
          _method: "PUT",
          name: params.name,
          status: params.status,
          order: params.order,
          edit: false,
        },
      });
      if (data) {
        dispatch("GET_ALL_TAGS");
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async DELETE_SUB_TAGS({ state, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "DELETE",
        url: `${state.configKomercia.url}/api/admin/tag/delete-property/${params.id}`,
        headers: state.configAxios.headers,
      });
      if (data && data.estado == 200) {
        dispatch("GET_ALL_TAGS");
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async ADD_TAGS_PRODUCT({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/tag/add-product`,
        headers: state.configAxios.headers,
        data: {
          product: params.product,
          tag_property: params.tag_property,
        },
      });
      if (data && data.estado == 200) {
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async DElETE_TAGS_PRODUCT({ state }, params) {
    try {
      const { data } = await axios({
        method: "DELETE",
        url: `${state.configKomercia.url}/api/admin/tag/${params.tag_property_id}/product/${params.productos_id}`,
        headers: state.configAxios.headers,
      });
      if (data && data.estado == 200) {
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_DATA_PERKS({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaHelpDesk}/api/perks?page=${params}&per_page=12`,
        headers: state.configAxios.headers,
      });
      if (data) {
        state.dataPerks = data.body;
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async SEARCH_PERKS({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaHelpDesk}/api/perks/filter`,
        headers: state.configAxios.headers,
        params: {
          name: params.name || null,
          country: params.country || null,
        },
      });
      if (data) {
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_ARTICLE_HELPDESK({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaHelpDesk}/api/public/article/${params.id}?idHelpdesk=582`,
      });
      if (data) {
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async VIEW_ARTICLE_HELPDESK({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaHelpDesk}/api/views/articles?idHelpdesk=582`,
        data: {
          idArticle: params.id,
        },
      });
      if (data) {
        return { success: true, data: data.body };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_DATA_ALL_CUPONES({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/cupones/publicos`,
        headers: state.configAxios.headers,
      });
      if (data) {
        state.dataCupones = data.cupones;
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  // dropshipping
  async SET_DROPSHIPPING({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/dropshipping/productos`,
        headers: state.configAxios.headers,
        data: {
          producto: params.producto,
          comision: params.comision,
        },
      });
      if (data) {
        return { success: true, data: data.data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async UPDATE_DROPSHIPPING({ state }, params) {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `${state.configKomercia.url}/api/admin/dropshipping/productos/${params.producto}`,
        headers: state.configAxios.headers,
        data: {
          comision: params.comision,
          estado: params.estado,
        },
      });
      if (data) {
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_AWARDS_DATA({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaHelpDesk}/api/awards`,
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data: data.body };
      }
    } catch (err) {
      console.log("GET_AWARDS_DATA", err.response);
      return { success: false, data: err.response };
    }
  },
  async GENERATE_DESCRIPTION_PRODUCT({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaAWS}/api/v1/ai-suggetions/products`,
        data: {
          language: params.language,
          productName: params.productName,
          keyWords: params.keyWords,
          nWords: params.nWords,
        },
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },

  // Coupon
  async GET_IS_COUPON_AVAILABLE({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/cuopons/check/${
          params.coupon.startsWith("KOMERCIA-CODE-") ? "multiple" : "coupon"
        }`,
        headers: state.configAxios.headers,
        params: {
          coupon: params.coupon,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
  async GET_VERIFY_CLAIM({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/cuopons/${
          params.coupon.startsWith("KOMERCIA-CODE-")
            ? "multiple-subscriptions"
            : "check/store"
        }`,
        headers: state.configAxios.headers,
        params: {
          storeId: params.storeId,
        },
      });
      if (data) {
        return { success1: true, data1: data };
      }
    } catch (err) {
      return { success1: false, data: null };
    }
  },
  async PUT_REDEEM_COUPON({ state }, params) {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `${state.urlKomerciaAWS}/api/v1/cuopons/redeem`,
        headers: state.configAxios.headers,
        data: {
          storeId: params.storeId,
          coupon: params.coupon,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: err?.response };
    }
  },

  // GET_PAYMENTGATEWAYS
  // async GET_PAYMENT_GATEWAYS({ state, commit, dispatch }, params) {
  //   try {
  //     const { data } = await axios({
  //       method: "GET",
  //       url: `${state.configKomercia.url}/api/admin/medios-pago`,
  //       headers: state.configAxios.headers,
  //     });
  //     if (data?.estado === 200) {
  //       if (params?.idCountry) {
  //         dispatch("GET_BANKS_COUNTRY", params.idCountry);
  //       }
  //       commit("SET_PAYMENTS", data.data);

  //       dispatch("GET_MERCADO_LIBRE_USER", {
  //         access_token: data.data.mercado_pago.access_token,
  //       });
  //       commit("SET_PAYMENTGATEWAYS", {
  //         payco: data.data.payco,
  //         payu: data.data.payu,
  //         mercado_pago: data.data.mercado_pago,
  //         credibanco: data.data.credibanco,
  //         wompi: data.data.wompi,
  //         flow: data.data.flow,
  //         payments_way: data.data.payments_way,
  //       });
  //       // commit("SET_PAYMENTMETHODS", [
  //       //   data.data.contraentrega,
  //       //   data.data.convenir,
  //       //   data.data.tienda,
  //       //   data.data.consignacion,
  //       //   data.data.efecty,
  //       //   data.data.nequi,
  //       //   data.data.daviplata,
  //       // ]);
  //       state.paymentMethods = [
  //         data.data.contraentrega,
  //         data.data.convenir,
  //         data.data.tienda,
  //         data.data.consignacion,
  //         data.data.efecty,
  //         data.data.nequi,
  //         data.data.daviplata,
  //       ];
  //       return { success: true, data: data.data };
  //     }
  //   } catch (err) {
  //     console.log("Error GET_PAYMENT_GATEWAYS", error.response);
  //     return { success: false };
  //   }
  // },
  // async GET_PAYMENT_ADDI({ state }, params) {
  //   try {
  //     const { data } = await axios({
  //       method: "GET",
  //       url: `${state.urlKomerciaAWS}/api/v1/payment-gateaways/addi/auth/credentials/${params.storeId}`,
  //       headers: state.configAxios.headers,
  //     });
  //     if (data) {
  //       state.dataAddi.clientID = data.clientID;
  //       state.dataAddi.clientSecret = data.clientSecret;
  //       state.dataAddi.ally_slug = data.ally_slug;
  //       state.dataAddi.productionMode = data.productionMode;
  //     }
  //   } catch (err) {
  //     console.error(`GET ERROR PAYMENT ADDI`);
  //     console.log(err.response);
  //   }
  // },
  // async CREATE_PAYMENT_ADDI({ state }, params) {
  //   try {
  //     const { data } = await axios({
  //       method: "POST",
  //       url: `${state.urlKomerciaAWS}/api/v1/payment-gateaways/addi/auth/credentials`,
  //       headers: state.configAxios.headers,
  //       data: {
  //         storeId: params.storeId,
  //         clientID: params.clientID,
  //         clientSecret: params.clientSecret,
  //         ally_slug: params.ally_slug,
  //       },
  //     });
  //     if (data) {
  //       return { success: true, data };
  //     }
  //   } catch (err) {
  //     return { success: false };
  //   }
  // },

  async GET_STORE_PAYMENTS({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/stores/payment-methods/public/${params.storeId}`,
        headers: {
          KOMERCIA_PUBLIC_ROUTES_KEY: state.routerKey,
        },
      });
      if (data) {
        state.paymentGateways = {
          addi: data.data.addi,
          credibanco: data.data.credibanco,
          flow: data.data.flow,
          mercadopago: data.data.mercadopago,
          payco: data.data.payco,
          paymenths_way: data.data.paymenths_way,
          payu: data.data.payu,
          tu_compra: data.data.tu_compra,
          wepay4u: data.data.wepay4u,
          wompi: data.data.wompi,
        };
        state.paymentMethods = {
          consignacion: data.data.consignacion,
          contraentrega: data.data.contraentrega,
          convenir: data.data.convenir,
          daviplata: data.data.daviplata,
          efecty: data.data.efecty,
          nequi: data.data.nequi,
          tienda: data.data.tienda,
        };
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
  async GET_DATA_PAYMENTS({ state, commit, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/stores/private/payment-gateways`,
        headers: state.configAxios.headers,
        params: {
          paymentGateawayMethod: params.payment,
        },
      });
      if (data) {
        if (params.payment === "MERCADOPAGO" && data?.data?.accessToken) {
          dispatch("GET_MERCADO_LIBRE_USER", {
            access_token: data.data.accessToken,
          });
        }
        let payment = null;
        payment = params.payment.toLowerCase();

        commit("SET_PAYMENTS", {
          value: data.data,
          payment,
        });
        return { success: true, data };
      }
    } catch (err) {
      return { success: false, data: null };
    }
  },
  async CREATED_PAYMENTS({ state, commit, dispatch }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaAWS}/api/v1/stores/private/payment-gateways/dynamic-create/${params.storeId}`,
        headers: state.configAxios.headers,
        params: {
          paymentGateawayMethod: params.payment,
        },
        data: params.data,
      });
      if (data) {
        let payment = null;
        payment = params.payment.toLowerCase();

        commit("SET_PAYMENTS", {
          value: data.data,
          payment,
        });
        if (params.payment === "MERCADOPAGO" && data?.data?.accessToken) {
          dispatch("GET_MERCADO_LIBRE_USER", {
            access_token: data.data.accessToken,
          });
        }

        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
  async UPDATE_DATA_PAYMENT({ state }, params) {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `${state.urlKomerciaAWS}/api/v1/stores/private/payment-gateways/dynamic-update/${params.storeId}`,
        headers: state.configAxios.headers,
        params: {
          paymentGateawayMethod: params.payment,
        },
        data: params.data,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
  async ACTIVATE_OR_DISABLE_PAYMENT({ state }, params) {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `${state.urlKomerciaAWS}/api/v1/stores/private/payment-gateways/dynamic-status/${params.storeId}`,
        headers: state.configAxios.headers,
        params: {
          paymentGateawayMethod: params.payment,
          operation: params.state,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async GET_MERCADO_PAGO_CREDENTIALS({}, value) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `https://api.mercadopago.com/oauth/token`,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
        data: {
          client_secret:
            value.country.id === 1
              ? process.env.VUE_APP_CLIENT_SECRET
              : value.country.id === 7
              ? process.env.VUE_APP_CLIENT_SECRET_CHILE
              : process.env.VUE_APP_CLIENT_SECRET,
          client_id:
            value.country.id === 1
              ? process.env.VUE_APP_CLIENT_ID
              : value.country.id === 7
              ? process.env.VUE_APP_CLIENT_ID_CHILE
              : process.env.VUE_APP_CLIENT_ID,
          grant_type: "authorization_code",
          code: value.code,
          redirect_uri: `${location.origin}/empresa/pagos`,
        },
      });
      if (data) {
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async RENOVATE_ACCESS_TOKEN_MERCADO_PAGO({}, value) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `https://api.mercadopago.com/oauth/token`,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
        params: {
          client_secret:
            value.country.id === 1
              ? process.env.VUE_APP_CLIENT_SECRET
              : value.country.id === 7
              ? process.env.VUE_APP_CLIENT_SECRET_CHILE
              : process.env.VUE_APP_CLIENT_SECRET,
          client_id:
            value.country.id === 1
              ? process.env.VUE_APP_CLIENT_ID
              : value.country.id === 7
              ? process.env.VUE_APP_CLIENT_ID_CHILE
              : process.env.VUE_APP_CLIENT_ID,
          grant_type: "refresh_token",
          refresh_token: value.refresh_token,
          redirect_uri: `${location.origin}/empresa/pagos`,
        },
      });
      if (data) {
        return { success: true, data: data };
      }
    } catch (err) {
      return { success: false, data: err.response };
    }
  },
  async GET_MERCADO_LIBRE_USER({ commit }, params) {
    // console.log(params);
    if (params?.access_token) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `https://api.mercadolibre.com/users/me?access_token=${params.access_token}`,
        });
        if (data) {
          commit("SET_MERCADOLIBRE_USER", data);
          return { success: true, data: data };
        }
      } catch (err) {
        console.log("Error Token mercadoPago", err.response.data);
      }
    }
  },
  async SET_INTEGRATION_MERCADO_PAGO({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.configKomercia.url}/api/admin/medios-pago/mercado-pago`,
        headers: state.configAxios.headers,
        data: {
          public_key: params.publicKey,
          access_token: params.accessToken,
          refresh_token: params.refreshToken,
          collector_id: params.collectorId,
          estado: 1,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
  async GET_STATE_MERCADO_PAGO({ state }) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/payments/panel/mercadopago/status`,
        headers: state.configAxios.headers,
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },

  async CANCEL_PAYMENT_ADDI({ state }, params) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${state.urlKomerciaAWS}/api/v1/payment-gateaways/addi/application/cancel`,
        headers: state.configAxios.headers,
        data: {
          orderId: params.orderId,
          amount: params.amount,
          environment: params.environment,
          storeId: params.storeId,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
  async CHANGE_MODE_ADDI({ state }, params) {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `${state.urlKomerciaAWS}/api/v1/payment-gateaways/addi/credentials/mode`,
        headers: state.configAxios.headers,
        data: {
          storeId: params.storeId,
          productionMode: params.productionMode,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },

  async GET_BANKS_COUNTRY({ state, commit }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.configKomercia.url}/api/bancos?pais=${params.idCountry}`,
        headers: state.configAxios.headers,
      });
      if (data) {
        state.banksData = data.data;
        return { success: true };
      }
    } catch (err) {
      console.log("Error GET_BANKS_COUNTRY", error.response);
      return { success: false };
    }
  },

  async GET_INTEGRATIONS_STATUS({ state }, params) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${state.urlKomerciaAWS}/api/v1/stores/private/integrations/${params.storeId}`,
        headers: {
          KOMERCIA_PUBLIC_ROUTES_KEY: state.routerKey,
        },
      });
      if (data) {
        return { success: true, data };
      }
    } catch (err) {
      return { success: false };
    }
  },
};
