export default {
  data() {
    return {
      money: {},
    };
  },
  computed: {
    idCountryStore() {
      return this.$store.state.currentCountry;
    },
  },
  watch: {
    idCountryStore(value) {
      this.setFormatMoney(value);
    },
  },
  created() {
    if (this.idCountryStore) {
      this.setFormatMoney(this.idCountryStore);
    }
  },
  methods: {
    setFormatMoney(countryId) {
      // console.log("countryId", countryId);
      const commonConfig = {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: " ",
        precision: 2,
        masked: false,
      };

      const countryConfigs = {
        1: { ...commonConfig, precision: 0 },
        2: { ...commonConfig },
        3: { ...commonConfig, decimal: "." },
        6: { ...commonConfig },
        7: { ...commonConfig },
        8: { ...commonConfig },
        9: { ...commonConfig, decimal: ".", prefix: "s/ " },
        10: { ...commonConfig },
      };

      this.money = countryConfigs[countryId] || commonConfig;
    },
  },
  filters: {
    formatCurrency(n, paises_id) {
      let formatNumber;
      let formatcurrent;
      if (n || n == 0) {
        // formatNumber = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        switch (paises_id) {
          // Colombia
          case 1:
            formatNumber = new Intl.NumberFormat("es-CO").format(n);
            formatcurrent = `$ ${formatNumber}`;
            break;
          // internacional
          case 2:
            formatNumber = new Intl.NumberFormat("en-US").format(n);
            formatcurrent = `$ ${formatNumber} USD`;
            break;
          // Mexico
          case 3:
            formatNumber = new Intl.NumberFormat("es-MX").format(n);
            formatcurrent = `$ ${formatNumber} MXN`;
            break;
          // Argentina
          case 6:
            formatNumber = new Intl.NumberFormat("es-AR").format(n);
            formatcurrent = `$ ${formatNumber} ARS`;
            break;
          // Chile
          case 7:
            formatNumber = new Intl.NumberFormat("es-CL").format(n);
            formatcurrent = `$ ${formatNumber} CLP`;
            break;
          // Puerto Rico
          case 8:
            formatNumber = new Intl.NumberFormat("es-PR").format(n);
            formatcurrent = `$ ${formatNumber} USD`;
            break;
          // Peru
          case 9:
            formatNumber = new Intl.NumberFormat("es-PE").format(n);
            formatcurrent = `S/ ${formatNumber}`;
            break;
          case 10:
            formatNumber = new Intl.NumberFormat("en-US").format(n);
            formatcurrent = `$ ${formatNumber} USD`;
            break;
        }
        return formatcurrent;
      } else {
        return "Sin precio";
      }
    },
  },
};
