<template>
  <div class="form">
    <div class="form__inputs">
      <div class="input-area">
        <p class="text-info">Dirección</p>
        <!-- <el-input
          ref="autocomplete"
          placeholder="Dirección"
          class="inputs-idTienda"
          type="text"
          data-vv-name="address_field"
          data-vv-as="dirección"
          v-validate="'required'"
          v-model="payload.address"
        /> -->
        <gmap-place-input
          class="inputs-idTienda"
          :default-place="payload.address"
          @place_changed="setPlace"
        >
        </gmap-place-input>
        <span class="error_label">{{ errors.first("address_field") }}</span>
        <span
          class="error_label"
          v-if="!payload.lat && !payload.lng && payload.address.length !== 0"
          >La dirección no es valida
        </span>
      </div>
      <div>
        <input
          v-validate="'required'"
          name="latitude_field"
          type="hidden"
          v-model="payload.lat"
          class="inputs-idTienda"
        />
        <input
          v-validate="'required'"
          name="longitude_field"
          type="hidden"
          v-model="payload.lng"
          class="inputs-idTienda"
        />
      </div>
      <div class="input-area">
        <p class="text-info">Ciudad</p>
        <el-select
          v-model="payload.city"
          remote
          filterable
          placeholder="Ciudad"
          :remote-method="remoteMethod"
          class="inputs-idTienda"
        >
          <el-option
            v-for="city in options"
            :key="city.id"
            :label="
              city.id == 0
                ? 'Sin espicificar'
                : `${city.departamento.nombre_dep} - ${city.nombre_ciu}`
            "
            :value="city.id"
          ></el-option>
        </el-select>
      </div>
      <div class="input-area">
        <p class="text-info">Nombre de la sede</p>
        <el-input
          data-vv-name="sede_field"
          data-vv-as="sede"
          v-validate="'required'"
          v-model="payload.branch_name"
          class="inputs-idTienda"
        ></el-input>
        <span class="error_label">{{ errors.first("sede_field") }}</span>
        <!-- <span class="error_label" v-if="(!payload.bracnh_name)">Escribe un nombre para esta sede.</span> -->
      </div>
      <div class="input-area">
        <p class="text-info">Horario</p>
        <el-input v-model="payload.schedule" class="inputs-idTienda"></el-input>
      </div>
    </div>
    <div class="m-[5px]">
      <el-button type="primary" @click="saveData">Guardar</el-button>
      <el-button class="pull-right" @click="redirectTo">Cancelar</el-button>
    </div>
  </div>
</template>

<script>
import normalizeString from "../mixins/normalizeString";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  mixins: [normalizeString],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.updateOptions();
    this.timeoutAutoComplete = setTimeout(() => {
      this.autocomplete = new google.maps.places.SearchBox(
        this.$refs.autocomplete
      );
      this.autocomplete.addListener("places_changed", () => {
        let places = this.autocomplete.getPlaces();
        places.forEach((place) => {
          this.payload.address = place.formatted_address;
          this.payload.lat = place.geometry.location.lat();
          this.payload.lng = place.geometry.location.lng();
        });
      });
    }, 1000);
  },
  beforeDestroy() {
    clearTimeout(this.timeoutAutoComplete);
  },
  $_veeValidate: {
    validator: "new", // inject a new validator to be used by the directives.
  },
  data() {
    return {
      options: [
        {
          id: 0,
        },
      ],
      loading: false,
      timeoutAutoComplete: null,
    };
  },
  computed: {
    cities() {
      return this.$store.state.cities;
    },
    payload: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    "payload.address"(value) {
      if (!value) {
        this.payload.lat = 0;
        this.payload.lng = 0;
      }
    },
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.cities.filter((item) => {
            return (
              this.normalize(item.nombre_ciu).indexOf(this.normalize(query)) >
              -1
            );
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    setPlace(place) {
      this.payload.address = place.formatted_address;
      this.payload.lat = place.geometry.location.lat();
      this.payload.lng = place.geometry.location.lng();
    },
    updateOptions() {
      this.options = [this.cities.find((city) => city.id == this.payload.city)];
    },
    saveData() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$emit("saveData", this.params);
        }
      });
    },
    // getAddressData(addressData, placeResultData) {
    //   this.params.direccion = placeResultData.formatted_address
    //   this.params.latitud = addressData.latitude
    //   this.params.longitud = addressData.longitude
    // },
    redirectTo() {
      this.$router.push("/empresa/sedes");
    },
  },
};
</script>

<style scoped>
.form {
  height: 100%;
  display: grid;
  justify-items: center;
  align-content: space-between;
  overflow-y: auto;
  background-color: #fff;
  padding: 5px;
}
.form__inputs {
  width: 100%;
}
.el-button {
  margin-left: 0;
}
.error_label {
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
  color: brown;
}

.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
  margin-bottom: 3px;
}
.input-area {
  width: 100%;
  margin-bottom: 10px;
}

.inputs-idTienda >>> .pac-target-input {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  height: 40px;
  max-height: 40px;
  width: 100%;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
</style>
