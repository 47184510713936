<template>
  <svg
    id="8c62078c-26d9-4b11-ac3c-7e09acc3373e"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    viewBox="0 0 873.74 509.56"
  >
    <defs>
      <linearGradient
        id="2119e7a0-1a3a-47f0-94ca-815c508cc8cf"
        x1="367.4"
        y1="509.56"
        x2="367.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="gray" stop-opacity="0.25" />
        <stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
        <stop offset="1" stop-color="gray" stop-opacity="0.1" />
      </linearGradient>
      <linearGradient
        id="c9af2afa-1938-40dc-a1df-6d95ad5ed736"
        x1="367.14"
        y1="369.23"
        x2="367.14"
        y2="343.1"
        xlink:href="#2119e7a0-1a3a-47f0-94ca-815c508cc8cf"
      />
      <linearGradient
        id="45e290ad-38e4-4ccd-bbf0-a3375439af51"
        x1="725.74"
        y1="400.02"
        x2="725.74"
        y2="150.02"
        xlink:href="#2119e7a0-1a3a-47f0-94ca-815c508cc8cf"
      />
      <linearGradient
        id="6eb28ab0-2165-417a-9d4c-554628bfc165"
        x1="832.87"
        y1="462.75"
        x2="946.87"
        y2="462.75"
        xlink:href="#2119e7a0-1a3a-47f0-94ca-815c508cc8cf"
      />
      <linearGradient
        id="302a07c6-4de2-4733-824a-97747ae9d7f0"
        x1="833.74"
        y1="257.02"
        x2="833.74"
        y2="177.02"
        xlink:href="#2119e7a0-1a3a-47f0-94ca-815c508cc8cf"
      />
    </defs>
    <title>add user</title>
    <g opacity="0.5">
      <polygon
        points="0 0 0 25.71 0 509.56 734.81 509.56 734.81 25.71 734.81 0 0 0"
        fill="url(#2119e7a0-1a3a-47f0-94ca-815c508cc8cf)"
      />
    </g>
    <rect x="10.9" y="31.71" width="716.12" height="468.79" fill="#fff" />
    <rect x="10.9" y="9.41" width="716.12" height="34.49" fill="#f5f5f5" />
    <circle cx="38.15" cy="26.13" r="8.36" fill="#ff5252" />
    <circle cx="61.15" cy="26.13" r="8.36" fill="#ff0" />
    <circle cx="84.14" cy="26.13" r="8.36" fill="#69f0ae" />
    <rect
      x="301.81"
      y="157.05"
      width="131.7"
      height="14.11"
      fill="#6c63ff"
      opacity="0.5"
    />
    <rect x="206.7" y="217.67" width="144.24" height="14.11" fill="#e0e0e0" />
    <rect
      x="547.52"
      y="412.89"
      width="144.24"
      height="14.11"
      transform="translate(1076.15 644.68) rotate(-180)"
      fill="#3ad29f"
    />
    <rect x="206.17" y="247.98" width="321.93" height="14.11" fill="#e0e0e0" />
    <rect x="206.17" y="278.3" width="321.93" height="14.11" fill="#e0e0e0" />
    <rect
      x="300.25"
      y="343.1"
      width="133.79"
      height="26.13"
      fill="url(#c9af2afa-1938-40dc-a1df-6d95ad5ed736)"
    />
    <rect x="301.29" y="345.48" width="131.7" height="21.38" fill="#6c63ff" />
    <g opacity="0.5">
      <circle
        cx="725.74"
        cy="275.02"
        r="125"
        fill="url(#45e290ad-38e4-4ccd-bbf0-a3375439af51)"
      />
    </g>
    <circle cx="725.74" cy="275.02" r="117" fill="#f5f5f5" />
    <g opacity="0.5">
      <path
        d="M905.18,442.17l-2.25-.8-.12.2-.13,0v-6.1a14.06,14.06,0,0,0-.17-2.2,23.33,23.33,0,0,0,8-10.56,27.51,27.51,0,1,0-43.57,0,23.33,23.33,0,0,0,8,10.56,14.06,14.06,0,0,0-.17,2.2v6.68h0a57,57,0,0,0-41.86,55v50h114v-50A57,57,0,0,0,905.18,442.17Z"
        transform="translate(-163.13 -195.22)"
        fill="url(#6eb28ab0-2165-417a-9d4c-554628bfc165)"
      />
    </g>
    <path
      d="M726.74,245.71h0a54.88,54.88,0,0,1,54.88,54.88v48.16a0,0,0,0,1,0,0H671.86a0,0,0,0,1,0,0V300.59A54.88,54.88,0,0,1,726.74,245.71Z"
      fill="#6c63ff"
    />
    <rect
      x="712.18"
      y="227.8"
      width="26.88"
      height="33.6"
      rx="13.44"
      ry="13.44"
      fill="#fda57d"
    />
    <polygon
      points="702.86 251.66 711.87 270.98 720.83 262.02 711.87 248.3 702.86 251.66"
      opacity="0.05"
    />
    <path
      d="M866.3,445.94l9,19.32,9-9-9-13.72-4.12,1.26a10.33,10.33,0,0,0-1.18.44Z"
      transform="translate(-163.13 -195.22)"
      fill="#fff"
    />
    <polygon
      points="748.42 250.72 739.37 270.98 730.41 262.02 739.62 247.57 748.42 250.72"
      opacity="0.05"
    />
    <path
      d="M911.24,445l-9,20.27-9-9,9.21-14.45,1.69.48a44.93,44.93,0,0,1,5.35,1.91Z"
      transform="translate(-163.13 -195.22)"
      fill="#fff"
    />
    <polygon
      points="720.47 260.37 725.48 267.68 730.77 260.37 720.47 260.37"
      fill="#fda57d"
    />
    <path
      d="M855.62,482.07s-5.34,53.1-1.89,56.56"
      transform="translate(-163.13 -195.22)"
      opacity="0.1"
    />
    <path
      d="M921.6,482.07s5.34,53.1,1.89,56.56"
      transform="translate(-163.13 -195.22)"
      opacity="0.1"
    />
    <path
      d="M888.75,439.32A22.29,22.29,0,0,0,902,435a13.44,13.44,0,0,0-13.27-11.32h0A13.44,13.44,0,0,0,875.48,435,22.29,22.29,0,0,0,888.75,439.32Z"
      transform="translate(-163.13 -195.22)"
      opacity="0.05"
    />
    <circle cx="725.62" cy="212.78" r="26.48" />
    <circle cx="725.62" cy="221.08" r="22.4" fill="#fda57d" />
    <path
      d="M870.7,394.09l5.66,6.91s24.82,2.2,27.65-3.46-16.34-9.74-16.34-9.74Z"
      transform="translate(-163.13 -195.22)"
      opacity="0.05"
    />
    <path
      d="M870.7,393.46l5.66,6.91s24.82,2.2,27.65-3.46-16.34-9.74-16.34-9.74Z"
      transform="translate(-163.13 -195.22)"
    />
    <ellipse cx="703.17" cy="219.61" rx="1.57" ry="2.83" fill="#fda57d" />
    <ellipse cx="748.1" cy="219.61" rx="1.57" ry="2.83" fill="#fda57d" />
    <g opacity="0.5">
      <polygon
        points="873.74 208.09 842.67 208.09 842.67 177.03 824.81 177.03 824.81 208.09 793.74 208.09 793.74 225.96 824.81 225.96 824.81 257.02 842.67 257.02 842.67 225.96 873.74 225.96 873.74 208.09"
        fill="url(#302a07c6-4de2-4733-824a-97747ae9d7f0)"
      />
    </g>
    <rect x="826.15" y="183.02" width="15.18" height="68" fill="#3ad29f" />
    <rect
      x="989.28"
      y="378.25"
      width="15.18"
      height="68"
      transform="translate(1245.99 -779.84) rotate(90)"
      fill="#3ad29f"
    />
  </svg>
</template>
<script>
export default {
  props: ['width'],
}
</script>

<style></style>
