import moment from "moment";
export default {
  methods: {
    formatCalendarMoment(date) {
      return moment(date).calendar(null, {
        lastDay: "[Ayer]",
        sameDay: "[Hoy]",
        nextDay: "[Mañana]",
        lastWeek: "dddd",
        nextWeek: "dddd",
        sameElse: "L",
      });
    },
  },
};
