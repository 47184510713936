import { render, staticRenderFns } from "./k-helpbutton.vue?vue&type=template&id=b49a4c66&scoped=true"
import script from "./k-helpbutton.vue?vue&type=script&lang=js"
export * from "./k-helpbutton.vue?vue&type=script&lang=js"
import style0 from "./k-helpbutton.vue?vue&type=style&index=0&id=b49a4c66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b49a4c66",
  null
  
)

export default component.exports