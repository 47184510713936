<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="
      item.inventario === item.cantidad
        ? 'Última unidad!'
        : 'Unidades disponibles'
    "
    placement="top"
  >
    <li
      class="products__item border rounded"
      :class="
        item.inventario === item.cantidad
          ? 'border-[#f14b5a]'
          : 'border-[#00dd8d]'
      "
    >
      <i @click="removeProduct" class="icon-close"></i>
      <figure class="item__image">
        <img
          loading="lazy"
          :src="idCloudinaryAwards(item.foto_cloudinary, 150, 150)"
          :alt="item.nombre"
        />
      </figure>
      <div class="item__content--wrapper">
        <p class="item__name">{{ item.nombre }}</p>
        <div class="item__combination" v-if="item.con_variante">
          <p v-for="(item, index) in combination" :key="`item_${index}`">
            {{ item }}
          </p>
        </div>
        <p class="item__price">
          Cant.: {{ item.cantidad }} X
          {{ priceByUnit | formatCurrency(idCountryStore) }}
        </p>
      </div>
      <div class="item__action">
        <button
          type="button"
          :class="item.cantidad <= 1 ? 'btn_disabled' : ''"
          :disabled="item.cantidad <= 1"
          @click="removeUnitProduct"
        >
          <i class="ti-minus"></i>
        </button>
        <button @click="addUnitProduct">
          <i class="ti-plus"></i>
        </button>
      </div>
      <p class="item__total">
        {{ (item.cantidad * priceByUnit) | formatCurrency(idCountryStore) }}
      </p>
    </li>
  </el-tooltip>
</template>

<script>
import formatCurrency from "@/mixins/formatCurrent";
import idCloudinaryAwards from "@/mixins/idCloudinary";
export default {
  props: ["item"],
  mixins: [formatCurrency, idCloudinaryAwards],
  computed: {
    priceByUnit() {
      return this.item.con_variante
        ? this.item.combinacion.precio
        : this.item.precio;
    },
    combination() {
      return this.item.con_variante ? this.item.combinacion.combinacion : null;
    },
  },
  methods: {
    removeUnitProduct() {
      this.$store.dispatch("pos/REMOVE_UNIT_PRODUCT", this.item);
    },
    addUnitProduct() {
      this.$store.dispatch("pos/ADD_UNIT_PRODUCT", this.item);
    },
    removeProduct() {
      this.$store.dispatch("pos/REMOVE_PRODUCT", this.item);
    },
  },
  filters: {
    currency(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return "Sin precio";
    },
  },
};
</script>

<style scoped>
.products__item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "img content actions"
    "img content actions";
  align-content: center;
  grid-column-gap: 8px;
  margin-bottom: 10px;
  padding: 7px;
  position: relative;
}
.item__image {
  grid-area: img;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid hsla(213, 16%, 87%, 0.486);
  border-radius: 4px;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.item__image img {
  /* padding: 6px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item__content--wrapper {
  grid-area: content;
  display: grid;
  align-content: flex-start;
}
.item__action {
  display: grid;
  grid-gap: 5px;
  grid-auto-flow: column;
  justify-content: end;
  align-self: end;
  margin-top: 7px;
}
.item__action button {
  display: grid;
  place-content: center;
  padding: 5px;
  font-size: 10px;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  background-color: var(--green);
  cursor: pointer;
  outline: none;
}
.btn_disabled {
  background-color: #9c9c9c !important;
  cursor: no-drop !important;
}
.item__name {
  align-self: end;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.item__combination {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  justify-content: start;
  align-content: center;
}
.item__combination p {
  background-color: var(--green);
  color: #fff;
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: -5;
}
.item__price {
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
}
.item__total {
  line-height: 1;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
}
.icon-close {
  position: absolute;
  font-size: 8px;
  right: -1px;
  top: -1px;
  cursor: pointer;
  color: #fff;
  background: #9c9c9c;
  padding: 3px;
  border-radius: 2px;
  line-height: 1;
  transition: all ease 0.3s;
}
.icon-close:hover {
  color: #fff;
  background: #ff2f55;
}
</style>
