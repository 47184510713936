<template>
  <section
    class="w-full lg:max-w-screen-lg xl:max-w-screen-xl rounded-xl"
    style="padding: 30px 20px"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div :class="darkMode ? 'darks' : 'lights'">
      <div class="flex w-full justify-between items-center pb-5">
        <div>
          <h2
            class="flex items-center"
            :class="darkMode ? 'darktxts' : 'lighttxts'"
          >
            {{ $t("text__buzon_title") }}
            <sliderInformation :numberId="72" :actions="true" class="ml-2" />
          </h2>
          <p :class="darkMode ? 'darktxt' : 'lighttxt'">
            {{ $t("text__buzon_subtitle") }}
          </p>
        </div>
      </div>
      <div class="container-chat clearfix">
        <div id="people-list" class="people-list">
          <div class="search">
            <el-input
              v-model="searchCustomer"
              type="text"
              :placeholder="$t('text__buzon_buscarCliente')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              ><i slot="suffix" class="el-input__icon el-icon-search"></i
            ></el-input>
          </div>
          <ul v-show="searchCustomer === ''" class="list">
            <li
              v-for="(chat, index) in reverseMsgs"
              :key="`chat${index}`"
              :class="{
                people_item: true,
                clearfix: true,
                selected: chat.selected,
              }"
              @click="selectChat(chat, index)"
            >
              <div class="about">
                <div class="flex">
                  <strong :class="darkMode ? 'darktxt' : 'lighttxt'">
                    {{ chat.nombre }}</strong
                  >
                  <p class="ml-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
                    {{ chat.hacecuanto }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <ul
            v-show="chatsSearched?.length && searchCustomer !== ''"
            class="list"
          >
            <li
              v-for="(chat, index) in reverseChatsSearched"
              :key="`chatFiltered${index}`"
              class="people_item clearfix"
              @click="selectChat(chat, index)"
            >
              <div class="about">
                <div class="flex">
                  <strong :class="darkMode ? 'darktxt' : 'lighttxt'">
                    {{ chat.nombre }}
                  </strong>
                  <p class="ml-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
                    {{ chat.hacecuanto }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <transition name="slide-fade">
          <div v-if="selectedChat" v-show="showChat" class="chat">
            <div class="chat-history">
              <ul>
                <template v-if="selectChat != {}">
                  <li class="lista">
                    <div class="message-data">
                      <span class="message-data-name">
                        <i class="fa fa-circle online"></i>
                        <strong :class="darkMode ? 'darktxt' : 'lighttxt'">
                          {{ selectedChat.chat.nombre }}
                        </strong>
                      </span>
                      <li :class="darkMode ? 'darktxt' : 'lighttxt'">
                        <strong :class="darkMode ? 'darktxt' : 'lighttxt'">
                          {{ $t("text__buzon_fechaEnvio") }}
                        </strong>
                        {{ formatCalendarMoment(selectedChat.chat.created_at) }}
                        -
                        {{ formatDateMoment(selectedChat.chat.created_at) }}
                      </li>
                      <li :class="darkMode ? 'darktxt' : 'lighttxt'">
                        <strong :class="darkMode ? 'darktxt' : 'lighttxt'">
                          {{ $t("text__buzon_eMail") }}
                        </strong>
                        {{ selectedChat.chat.email }}
                      </li>
                      <li :class="darkMode ? 'darktxt' : 'lighttxt'">
                        <strong :class="darkMode ? 'darktxt' : 'lighttxt'">
                          {{ $t("text__buzon_telefono") }}
                        </strong>
                        {{ selectedChat.chat.telefono }}
                      </li>
                      <span class="message-data-time"></span>
                    </div>
                    <div
                      class="message my-message"
                      :class="darkMode ? 'darktxt' : 'lighttxt'"
                    >
                      {{ selectedChat.chat.mensaje }}
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div v-else class="chat empty" :class="darkMode ? 'darks' : 'lights'">
            <img
              loading="lazy"
              v-if="
                (storeData.entidades &&
                  storeData.entidades[0] &&
                  storeData.entidades[0].id != 21) ||
                storeData.entidades?.length == 0
              "
              src="../assets/komerciaLogo2.webp"
            />
            <img
              loading="lazy"
              v-else
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1644004641/Keep/LOGO-NEGRO_ljywje.png"
              alt=""
            />
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>
<script>
import sliderInformation from "@/components/information.vue";
import formatDateMoment from "@/mixins/formatDateMoment";
import formatCalendarMoment from "@/mixins/formatCalendarMoment";
export default {
  name: "ChatIndex",
  components: { sliderInformation },
  mixins: [formatDateMoment, formatCalendarMoment],
  data() {
    return {
      value2: true,
      chats: [],
      chatsSearched: [],
      selectedChat: null,
      message: "",
      searchCustomer: "",
      showChat: false,
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    reverseMsgs() {
      return this.msgs.slice(0).reverse();
    },
    reverseChatsSearched() {
      return this.chatsSearched.slice(0).reverse();
    },
    msgs() {
      this.setChats(this.$store.state.messages);
      return this.$store.state.messages;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  watch: {
    searchCustomer(value) {
      this.chatsSearched = this.chats.filter((chat) =>
        chat.nombre.toLowerCase().includes(value.toLowerCase())
      );
    },
  },
  created() {
    this.$store.dispatch("GET_MESSAGES");
  },
  methods: {
    setChats(value) {
      this.chats = value;
    },
    selectChat(chat, index) {
      if (this.selectedChat) {
        this.selectedChat.chat.selected = false;
      }
      chat.selected = true;
      this.selectedChat = {
        index: index,
        chat: chat,
      };
      this.openChat();
    },
    openChat() {
      this.showChat = true;
    },
    hiddenChat() {
      this.showChat = false;
    },
  },
};
</script>
<style scoped>
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #1b2836;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.darktxts {
  color: #5e39f1;
}
.lighttxts {
  color: #4429b4;
}
.lista li {
  margin: 10px 0;
}
.chat_options {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 10px;
}
.chat_option {
  width: 200px;
  background-color: #fff;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.chat_option img {
  max-width: 40px;
  max-height: 40px;
}
.chat_option .content-wrapper {
  background-color: #eee;
}
.content {
  min-height: initial !important;
  padding-bottom: 30px;
}
.people-list .list {
  height: calc(100% - 80px);
  overflow-y: auto;
}
.people_item {
  cursor: pointer;
}
.chat {
  background: transparent;
}
.people_item_photo img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.chat-header img {
  width: 70px !important;
  height: 70px;
  object-fit: cover;
}
.chat.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat.empty img {
  width: 65%;
  filter: grayscale(1) opacity(0.2);
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}
.pending_messages {
  position: absolute;
  background-color: red;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media (max-width: 1150px) {
  .chat.empty {
    display: none;
  }
}
</style>
