export default {
  methods: {
    normalize(str, replace = "_") {
      let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (let i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);
      let ret = [];
      for (let i = 0, j = str.length; i < j; i++) {
        let c = str.charAt(i);
        if (Object.prototype.hasOwnProperty.call(mapping, str.charAt(i)))
          ret.push(mapping[c]);
        else ret.push(c);
      }
      return ret
        .join("")
        .replace(/[^-A-Za-z0-9]+/g, replace)
        .toLowerCase();
    },
  },
};
