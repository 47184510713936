<template>
  <div class="flex justify-end" :class="{ 'fade-in': isVisible }">
    <div class="grid grid-cols-3 gap-6">
      <el-tooltip class="item" effect="dark" content="Producto visitado">
        <span
          class="inline-flex items-center rounded-full bg-orange-50 px-5 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20 space-x-2"
        >
          <span>{{
            productAnalytics?.data[0]?.analytics?.viewedProduct || 0
          }}</span>
          <i class="el-icon-view text-base"></i>
        </span>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="Producto agregado al carrito"
      >
        <span
          class="inline-flex items-center rounded-full bg-blue-50 px-5 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 space-x-2"
        >
          <span>{{
            productAnalytics?.data[0]?.analytics?.addedToCart || 0
          }}</span>
          <i class="el-icon-shopping-cart-full text-base"></i>
        </span>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="Producto comprado">
        <span
          class="inline-flex items-center rounded-full bg-green-50 px-5 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 space-x-2"
        >
          <span>{{
            productAnalytics?.data[0]?.analytics?.clickedPayCart || 0
          }}</span>
          <i class="el-icon-money text-base"></i>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pickupDate: "",
      filter: {
        page: 1,
        limit: 99,
        name: null,
        startDate: null,
        endDate: null,
      },
      isVisible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isVisible = true;
    });
  },
  computed: {
    productAnalytics() {
      return this.$store.state.analyticsStore.dataProductAnalytics;
    },
    storeData() {
      return this.$store.state.storeData;
    },
    filterProduct() {
      if (this.productAnalytics && this.productAnalytics.data) {
        const product = this.productAnalytics.data.find(
          (product) => product.id === this.product.detalle.id
        );
        return product ? product : null;
      }
      return null;
    },
  },
  watch: {
    storeData() {
      this.sendDataFilter();
    },
  },
  created() {
    if (this.storeData?.id) {
      this.sendDataFilter();
    }
  },

  methods: {
    sendDataFilter() {
      if (this.product.detalle.nombre) {
        this.filter.name = this.product.detalle.nombre;
        this.getDataCategories(this.filter);
      } else {
        this.getDataCategories(this.filter);
      }
    },
    async getDataCategories({ page, limit, name, startDate, endDate }) {
      const storeId = this.storeData.id;
      await this.$store.dispatch("analyticsStore/GET_PRODUCT_ANALYTICS", {
        storeId,
        page,
        limit,
        name,
        startDate,
        endDate,
      });
    },
  },
};
</script>

<style scoped>
.inputSearch-filth >>> .el-input__inner,
.el-range-editor.el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  width: 100%;
  /* -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s; */
  /* cursor: pointer; */
  /* position: relative; */
}
.inputSearch-filt >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  position: relative;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.wrapper-pagination >>> .el-pagination.is-background .btn-next {
  color: black;
  background-color: transparent;
}
.darks-pagination >>> .el-pagination button:disabled {
  color: white;
  background-color: #233345;
}
.darks-pagination >>> .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: center center no-repeat #233345;
  color: white;
}
.light-pagination >>> .el-pagination button:disabled {
  color: #4429b4;
  background-color: #fff;
}
.light-pagination >>> .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: center center no-repeat #fff;
  color: #4429b4;
}
.darks-pagination >>> .el-pager li.active {
  color: #fff;
  background-color: #344a63;
}
.darks-pagination >>> .el-pager li:hover {
  color: #fff;
  background-color: #4429b4;
}
.darks-pagination >>> .el-pager li {
  color: #fff;
  background-color: #233345;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
