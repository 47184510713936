<template>
  <div id="app">
    <div
      v-show="showModalWApp"
      class="modal w-full h-full fixed z-50 left-0 top-0 overflow-auto"
    >
      <div class="w-full flex justify-center items-center">
        <div
          ref="form"
          class="content-form w-full flex flex-col items-center justify-center py-5 px-5 relative rounded-lg bg-white shadow-md"
        >
          <h3
            class="text-center text-lg font-medium text-purple-komercia-100 mt-5"
          >
            Komercia
          </h3>
          <p class="text-xs text-green-whatsApp-100">Chat Soporte</p>
          <div class="w-full flex justify-center items-center mb-2">
            <img
              loading="lazy"
              src="./assets/qrcode-WhatsApp.png"
              class="w-44"
              alt="QR-WhatsApp-Komercia"
            />
          </div>
          <!-- <h3 class="text-base leading-6 font-medium text-green-whatsApp-100">
            Elije una opción
          </h3> -->
          <div class="px-4 pt-3">
            <!-- <button
              class="mb-3 px-4 py-2 bg-green-whatsApp-200 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-whatsApp-100 transition ease-in-out delay-75"
              @click="setWApp(1)"
            >
              <i class="fab fa-whatsapp mr-1" />WhatsApp App 📱
            </button> -->
            <button
              class="px-4 py-2 bg-green-whatsApp-50 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-whatsApp-200 transition ease-in-out delay-75"
              @click="setWApp()"
            >
              <i class="fab fa-whatsapp mr-1" /> Abrir WhatsApp
            </button>
          </div>

          <button
            class="absolute -top-3 -right-3 bg-red-400 text-white rounded-full px-3 py-2 hover:bg-red-500 transition ease-in-out delay-75"
            @click="showModalWApp = !showModalWApp"
          >
            X
          </button>
        </div>
      </div>
    </div>
    <button
      v-if="visibleBtnWhatsApp"
      class="bottom-5 right-5 fixed bg-green-whatsApp-50 text-base font-bold text-white py-2 px-4 rounded-full z-50 hover:shadow-lg hover:bg-green-whatsApp-200 transition ease-in-out delay-75"
      @click="showModalWApp = !showModalWApp"
    >
      <i class="fab fa-whatsapp mr-1" /> Soporte
    </button>

    <NoticeHeader v-if="!expiredStore(storeData.fecha_expiracion, 1)" />
    <!-- <Publicity
      v-if="
        (showRefer &&
          storeData.entidades &&
          storeData.entidades[0] &&
          storeData.entidades[0].id != 21) ||
          storeData.entidades.length == 0
      "
    /> -->
    <modalUpdates v-if="expiredDate('2023-08-09') && showBannerStore" />
    <!-- <PublicityPrueba
      v-if="
        expiredDate(storeData.fecha_expiracion) &&
        modalExpiration.modalMembership
      "
      :data="modalExpiration"
    /> -->
    <!-- <HeaderAddi /> -->
    <!-- <PublicityPrueba v-if="courtesyDays > 0 && showBannerStore" /> -->
    <ModalRefer v-if="modalrefer" class="absolute modalRef" />
    <HeaderCloseStore />
    <ModalNotSesion v-if="modalNotSesion" />
    <NotStore v-if="storeData.id == 0" />
    <template v-else>
      <notAvailable />
      <router-view />
      <WelcomeCalendly />
    </template>
    <!-- <releases-button /> -->
    <router-view v-if="storeData.id == 0" />
  </div>
</template>
<script>
import expiredDate from "./mixins/expiredDate";
import moment from "moment";

export default {
  name: "App",
  components: {
    ModalRefer: () => import("./components/modalRefer/modalRefer.vue"),
    WelcomeTour: () => import("./components/WelcomeTour.vue"),
    notAvailable: () => import("./components/notAvailable.vue"),
    NotStore: () => import("./components/NotStore.vue"),
    NoticeHeader: () => import("./components/NoticeHeader.vue"),
    //  Publicity: () => import('./components/publicidad.vue'),
    // PublicityPrueba: () => import("./components/publicidadPrueba.vue"),
    // HeaderAddi: () => import("./components/Ko-header-addi.vue"),

    HeaderCloseStore: () => import("./components/HeaderOffline.vue"),
    ModalNotSesion: () => import("./components/ModalNotSesion.vue"),
    //  ReleasesButton: () => import('@/components/ReleasesButton.vue'),
    modalUpdates: () => import("./components/notification/modalNotify.vue"),
    WelcomeCalendly: () => import("./components/calendly/WelcomeCalendly.vue"),
  },
  mixins: [expiredDate],
  data() {
    return {
      modalNotSesion: false,
      modalExpiration: {
        stateDemo: false,
        modalMembership: false,
        daysUntilExpiration: 0,
        daysBetweenCreationAndExpiration: 0,
        messageModalMembership: "",
      },
      showModalWApp: false,
      visibleBtnWhatsApp: true,
    };
  },
  computed: {
    showBannerStore() {
      return this.$store.state.showPublicityPrueba;
    },
    modalrefer() {
      return this.$store.state.modalrefer;
    },
    showRefer() {
      return this.$store.state.showPublicity;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  watch: {
    storeData() {
      this.setDays();
    },
    "$route.path"(value) {
      this.setVisibleWApp(value);
    },
  },
  async created() {
    const { success } = await this.$store.dispatch("GET_STORE");
    if (success) {
      this.$store.dispatch("GET_STORE_PAYMENTS", {
        storeId: this.storeData.id,
      });
    }
    this.$store.dispatch("GET_CITIES");
    setTimeout(this.loadModalNotSesion, 14000);
    this.$store.commit("SET_DOMAIN", "komercia.co");
    this.setDays();
    this.setVisibleWApp(this.$route.path);
  },
  methods: {
    loadModalNotSesion() {
      // console.log('launch')
      let showModal = true;
      if (Object.keys(this.storeData).length === 0) {
        showModal = true;
      } else {
        showModal = false;
      }
      this.modalNotSesion = showModal;
    },
    setDays() {
      if (this.storeData?.created_at && this.storeData?.fecha_expiracion) {
        var currentDate = new Date();
        var creationDate = new Date(
          moment(this.storeData.created_at).locale("en").format("LL")
        );
        var expirationDate = new Date(
          moment(this.storeData.fecha_expiracion).locale("en").format("LL")
        );
        this.modalExpiration.daysUntilExpiration = Math.round(
          (expirationDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        this.modalExpiration.daysBetweenCreationAndExpiration = Math.round(
          (expirationDate - creationDate) / (1000 * 60 * 60 * 24)
        );
        if (this.modalExpiration.daysBetweenCreationAndExpiration <= 20) {
          this.modalExpiration.stateDemo = true;
          if (
            this.modalExpiration.daysUntilExpiration >= 0 &&
            this.modalExpiration.daysUntilExpiration <= 20
          ) {
            this.modalExpiration.modalMembership = true;
            this.modalExpiration.messageModalMembership =
              "Modo de prueba. Quedan " +
              this.modalExpiration.daysUntilExpiration +
              " días hasta el vencimiento.";
            this.modalExpiration.modalMembership = true;
          }
        } else {
          if (this.modalExpiration.daysUntilExpiration <= 1) {
            this.modalExpiration.modalMembership = true;
          }
          if (
            this.modalExpiration.daysUntilExpiration >= 0 &&
            this.modalExpiration.daysUntilExpiration <= 365
          ) {
            this.modalExpiration.messageModalMembership =
              "Membresía activa (NORMAL). Quedan " +
              this.modalExpiration.daysUntilExpiration +
              " días hasta el vencimiento.";
          } else if (this.modalExpiration.daysUntilExpiration >= 0) {
            this.modalExpiration.messageModalMembership =
              "Membresía activa (RARO). Quedan " +
              this.modalExpiration.daysUntilExpiration +
              " días hasta el vencimiento.";
          }
        }
        if (this.modalExpiration.daysUntilExpiration < 0) {
          this.modalExpiration.messageModalMembership = "vencida";
        }
      }
    },
    setWApp() {
      let text = "";
      window.open(`https://wa.me/573107884893/?text=${text}`, "_blank");
      window.open(
        `https://web.whatsapp.com/send?phone=573107884893&text=${text}`,
        "_blank"
      );
    },
    setVisibleWApp(value) {
      if (
        value === "/tienda/editor/modal" ||
        value === "/editor/LinkKomercia" ||
        value === "/diseno/editor" ||
        value === "/editor/template" ||
        value === "/editor/santiago" ||
        value === "/editor/helpDesk"
      ) {
        this.visibleBtnWhatsApp = false;
      } else {
        this.visibleBtnWhatsApp = true;
      }
    },
  },
};
</script>
<style scoped>
.modal {
  padding-top: 250px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.content-form {
  max-width: 320px;
}
@media (min-width: 0px) {
  .modalRef {
    @apply hidden;
  }
}
@media (min-width: 768px) {
  .modalRef {
    @apply flex;
  }
}
</style>
<style lang="scss">
@import "sass/main";
@import "./assets/css/main.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
:root {
  --red: #f14b5a;
  // --green: #80e3a1;
  --green: #00dd8d;
  --darkone: #373d43;
  --darktwo: #9b9ea1;
  --purple: #4429b4;
  --yellow: #fdd103;
  --main_color: #f14b5a;
  --text_color: #000;
  --button_color: #f14b5a;
  --button_text_color: #fff;
  --background_color: #fff;
  --background: #f5f7fa;
  $--color-primary: "#4429B4";
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 11px;
}
::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar:horizontal {
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 20px;
  border: 2px solid #b6b6b6;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.01rem;
}
h1 {
  // font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-family: "Poppins", sans-serif;
}
body {
  color: #333;
}
hr {
  border: 0;
  border-top: 1px solid #eee;
  height: 0;
  box-sizing: content-box;
}
.content-wrapper {
  // background-color: var(--background);
}
.input-area .input-label {
  height: 20px;
  display: flex;
  align-items: center;
}
.input-area .input-label .el-icon-question {
  height: initial;
}
.input-area .el-select {
  width: 100%;
}
.el-input__icon.el-icon-question {
  cursor: pointer;
  font-size: 18px;
  color: #747e9c;
}
.input-area:hover .el-input__icon:hover {
  color: #3cc693;
}
.inventory .el-input-number {
  max-width: 100px;
  width: 100%;
}
.el-collapse-item__header {
  padding-left: 10px;
  background-color: #eee !important;
}
.el-button ~ .el-button {
  margin-right: 10px;
}
.branches .el-popover {
  right: 70px;
  z-index: 5;
}
.font-area .el-textarea__inner {
  font-size: 18px;
}
.legal .input-area .quillWrapper .ql-editor {
  max-height: 500px;
  overflow: auto;
}
.product_form .input-area-full .quillWrapper .ql-editor {
  max-height: 300px;
  overflow: auto;
}
.data_form .el-input__inner {
  // border: 1px solid #9b9ea1 !important;
}
.data_form .el-input__inner::placeholder {
  color: #9b9ea1;
}
.data_form .el-select .el-input .el-select__caret {
  color: #9b9ea1;
}
.color_item_picker .el-color-picker,
.color_item_picker .el-color-picker__trigger {
  width: 100%;
  height: 100%;
}
.grid_store_share div i {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 2;
  color: #0f9380;
  border: 1px solid #0f9380;
  border-radius: 50%;
  cursor: pointer;
}
.categories_actions .el-input__inner::placeholder {
  color: #9b9ea1;
}
.content-wrapper {
  min-height: calc(100vh - 150px);
  overflow: auto;
}
.not_available {
  display: none;
}
.nav.navbar-nav {
  margin: 0;
}
.header_store-preview p {
  margin: 0;
}
.youtube .el-input {
  width: 480px;
}
.el-dialog .el-input-group__prepend {
  padding: 10px 24px 10px 28px;
  display: flex;
  justify-content: center;
}
.VueCarousel-navigation-button {
  padding: 5px;
  border-radius: 4px;
  transition: 0.2s;
}
.VueCarousel-navigation-button:focus {
  outline: none !important;
  background-color: rgb(214, 214, 214);
}
.VueCarousel-navigation-button:not(.VueCarousel-navigation--disabled):focus {
  outline: none !important;
}
.VueCarousel-navigation-button:not(.VueCarousel-navigation--disabled):hover {
  cursor: pointer;
  font-size: 20px;
}
.green-button {
  border-radius: 3px;
  font-weight: 600;
  padding: 9px 17px;
  background-color: var(--green);
  color: var(--purple);
}
.green-button:hover {
  background-color: #90f3b1;
}
.mipaquete .el-message-box__content {
  overflow: auto !important;
  height: 70vh !important;
}
</style>
