<template>
  <div
    class="w-full rounded-xl overflow-auto p-7"
    :class="darkMode ? 'dark' : 'light'"
    style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09)"
  >
    <National />
  </div>
</template>

<script>
import axios from "axios";
import National from "./Shipping/National";
export default {
  name: "generalShipping",
  components: {
    National,
  },
  data() {
    return {
      tabPosition: "true",
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    nationalState() {
      return this.$store.state.shippingData[0];
    },
    internationalState() {
      return this.$store.state.shippingData[1];
    },
  },
  created() {
    this.$store.dispatch("GET_SHIPPING");
  },
  methods: {
    updateState(shipping, state) {
      let params = {
        id: shipping.id,
        estado: state,
      };
      axios
        .post(
          `${this.$configKomercia.url}/api/admin/envios/estado/cambiar`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          shipping.estado = state;
          this.$store.commit("SET_TASK", { id: 6, status: true });
        });
    },
  },
};
</script>

<style scoped>
.dark {
  background-color: #233345;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.ko-radio-group {
  display: flex;
  list-style: none;
  border: 1px solid #d8dce5;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.ko-radio-button {
  width: 50%;
  display: flex;
  justify-content: center;
  filter: grayscale(1);
}
.ko-radio-button.active {
  filter: grayscale(0);
}
.ko-radio-button a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.ko-radio-button .router-link-exact-active {
  color: #fff;
  background-color: var(--green);
}
.ko-radio-button .icon_shipping {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.ko-radio-button .icon_shipping img {
  width: 100%;
  height: 100%;
}
.ko-radio-button ~ .ko-radio-button {
  border-left: 1px solid #d8dce5;
}
</style>
