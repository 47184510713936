<template>
  <div class="box-body p-0">
    <div v-if="productsFilter.length != 0" class="table">
      <div class="table__header">
        <p></p>
        <p>{{ $t("text_ventas_nombre_producto") }}</p>
        <div class="table__header__inventory">
          <p>{{ $t("text_ventas_combinacion") }}</p>
          <p>{{ $t("text_ventas_unidades") }}</p>
          <p>{{ $t("text_ventas_precio") }}</p>
        </div>
        <p>{{ $t("text_ventas_accciones") }}</p>
      </div>
      <Row
        v-for="product in productsFilter"
        :key="`product${product.id}`"
        :data="{ ...product }"
        class="row"
      />
    </div>
    <div v-else class="table">
      <div class="table__header">
        <p></p>
        <p>{{ $t("text_ventas_nombre_producto") }}</p>
        <div class="table__header__inventory">
          <p>{{ $t("text_ventas_combinacion") }}</p>
          <p>{{ $t("text_ventas_unidades") }}</p>
          <p>{{ $t("text_ventas_precio") }}</p>
        </div>
        <p>{{ $t("text_ventas_accciones") }}</p>
      </div>
      <Row
        v-for="product in productsData"
        :key="`product${product.id}`"
        :data="{ ...product }"
        class="row"
      />
    </div>
  </div>
</template>

<script>
import Row from "@/components/Products/Row";
export default {
  components: { Row },
  props: {
    productsFilter: {
      type: Array,
      required: true,
    },
    productsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { prueba: null };
  },
  computed: {
    settingProducts() {
      return this.$store.getters.settingProducts;
    },
  },
};
</script>

<style scoped>
.box-body {
  @apply rounded-xl w-full bg-white;
}
.table * {
  margin: 0;
}
.table {
  @apply w-full border rounded-t-xl;
  display: grid;
}
.table__header {
  @apply grid bg-purple-komercia-500 rounded-tr-xl rounded-tl-xl py-2;
  display: grid;
  grid-template-columns: 100px 270px 385px auto;
  border-top: 1px solid #c0c4cc86;
  border-bottom: 1px solid #c0c4cc86;
}
.table__header p {
  @apply text-white;
  font-weight: bold;
  display: grid;
  align-content: center;
  text-align: center;
  height: 100%;
  /* border-left: 1px solid #c0c4cc86; */
  padding: 10px 0;
}
.table__header > p:last-child {
  /* border-right: 1px solid #c0c4cc86; */
}
.table__header__inventory {
  display: grid;
  grid-template-columns: 150px 100px 100px;
  justify-content: center;
  justify-items: center;
  align-content: center;
  grid-gap: 5px;
  /* border-left: 1px solid #c0c4cc86; */
}
.row {
  @apply grid grid-flow-col items-center;
  grid-template-columns: 120px 270px 385px auto;
  grid-template-rows: auto 45px;
}
.row:nth-child(even) {
  background: #f6f3ff;
}
@media (max-width: 1250px) {
  .table__header {
    grid-template-columns: 100px 206px 385px auto;
  }
  .row {
    grid-template-columns: 100px 206px 385px auto;
  }
}
@media (max-width: 1120px) {
  .table__header {
    grid-template-columns: 60px 200px 380px auto;
  }
  .row {
    grid-template-columns: 60px 200px 380px auto;
  }
}
</style>
