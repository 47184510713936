<template>
  <div class="relative">
    <div class="w-full flex flex-row">
      <div class="w-full rounded-lg">
        <gmap-map
          :center="centerMap"
          :zoom="12"
          style="width: 100%; height: 500px"
        >
          <gmap-marker
            v-for="(branch, i) in branchesData"
            :position="{ lat: branch.latitud, lng: branch.longitud }"
            :clickable="true"
            :draggable="false"
            :key="`branch_${i}`"
          ></gmap-marker>
        </gmap-map>
      </div>
      <div class="px-3 w-full max-w-[310px]">
        <div class="p-[5px] flex flex-row justify-between items-center">
          <p class="flex flex-row items-center mb-1">
            Crear nueva sede
            <sliderInformation :numberId="38" :actions="true" class="ml-2" />
          </p>
          <router-link to="/empresa/sedes/nueva">
            <el-button type="primary">Crear</el-button>
          </router-link>
        </div>
        <ul class="branches_list">
          <li
            class="branches_item"
            v-for="(branch, index) in branchesData"
            @click="selectBranch(branch)"
            :key="`li_${index}`"
          >
            <div>
              <h4 data-testid="name-sede">{{ branch.nombre_sede }}</h4>
              <p>{{ branch.direccion }}</p>
              <p>{{ branch.horario }}</p>
            </div>
            <el-popover :ref="`popover${index}`" placement="left" width="160">
              <ul class="branches_item_options">
                <li :data-testid="`edit-${index}`">
                  <router-link :to="`/empresa/sedes/editar/${branch.id}`">
                    <i class="el-icon-edit"></i> Editar
                  </router-link>
                </li>
                <li
                  :data-testid="`remove-sede-${index}`"
                  @click="beforeDeleteBranch(branch, index)"
                >
                  <a> <i class="el-icon-delete"></i> Eliminar </a>
                </li>
              </ul>
              <el-button
                icon="el-icon-more"
                slot="reference"
                @click="togglePopover(branch)"
              ></el-button>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import sliderInformation from "@/components/information.vue";
export default {
  name: "branchesIndex",
  components: { sliderInformation },
  data() {
    return {
      beforeBranch: null,
      branchSelected: {
        latitud: 0,
        longitud: 0,
      },
    };
  },
  watch: {
    branchesData(value) {
      if (value.length) {
        this.branchSelected = {
          latitud: value[0].latitud,
          longitud: value[0].longitud,
        };
      }
    },
  },
  computed: {
    branchesData() {
      return this.$store.state.branchesData;
    },
    centerMap() {
      return {
        lat: this.branchSelected.latitud,
        lng: this.branchSelected.longitud,
      };
    },
  },
  methods: {
    togglePopover(branch) {
      if (branch.visible) {
        branch.visible = false;
      } else {
        branch.visible = true;
        if (this.beforeBranch && this.beforeBranch !== branch) {
          this.beforeBranch.visible = false;
        }
      }
      this.beforeBranch = branch;
    },
    beforeDeleteBranch(branch, index) {
      this.$confirm("Desea eliminar esta sede?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.deleteBranch(branch, index);
        })
        .catch(() => {
          this.$message("No se elimino la sede");
        });
    },
    async deleteBranch(branch, index) {
      const { status } = await this.$store.dispatch(
        "DELETE_HEADQUATER",
        branch.id
      );
      if (status === 200) {
        this.$message.success("Sede eliminada");
        this.$store.state.branchesData.splice(index, 1);
      }
    },
    selectBranch(branch) {
      this.branchSelected = branch;
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.branches_item {
  max-width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  cursor: pointer;
}
.branches_item {
  border-top: 1px solid #d8dce5;
}
.branches_item_options li {
  display: flex;
  cursor: pointer;
}
.branches_item_options li a {
  width: 100%;
  padding: 10px;
}
.branches_item_options li a:hover {
  background-color: #eee;
}
.branches_item:last-child {
  border-bottom: 1px solid #d8dce5;
}
</style>
