import axios from "axios";

const URL_KOMERCIA_VACANCIES = process.env.VUE_APP_URL_KOMERCIA;

export default {
  namespaced: true,
  state: {
    allVacancies: [],
    allVacanciesbyUser: [],
    // userVacancies: [],
  },
  mutations: {
    // SET_ALL_VACANCIES(state, value) {
    //   state.allVacancies = value
    // },
  },
  getters: {},
  actions: {
    // Funcionando
    async GET_ALL_VACANCIES({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_KOMERCIA_VACANCIES}/api/admin/vacantes`,
          headers: rootState.configAxios.headers,
        });
        if (data && data.vacantes && data.vacantes.data.length > 0) {
          state.allVacancies = data.vacantes.data;
          // commit('SET_ALL_VACANCIES', data)
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_ALL_VACANCIESBYUSER({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_KOMERCIA_VACANCIES}/api/admin/vacantes/user`,
          headers: rootState.configAxios.headers,
        });
        if (data != null) {
          state.allVacanciesbyUser = data.vacante.data;
          // commit('SET_ALL_VACANCIES', data)
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },

    async CREATED_VACANCIES({ rootState, dispatch }, params) {
      // console.log(params)
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_VACANCIES}/api/admin/vacantes`,
          headers: rootState.configAxios.headers,
          data: {
            titulo: params.titulo,
            descripcion: params.descripcion,
            tipo_contrato: params.tipo_contrato,
            presupuesto: params.presupuesto,
            categoria: params.categoria,
            categoria2: params.empty,
            categoria3: params.empty,
            como_aplicar: params.como_aplicar,
            posicion: params.posicion,
            locacion: params.locacion,
          },
        });
        if (data) {
          dispatch("GET_ALL_VACANCIES");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_VACANTE({ rootState, dispatch }, id) {
      // console.log(id)
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${URL_KOMERCIA_VACANCIES}/api/admin/vacantes/${id.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data && data.estado == 200) {
          dispatch("GET_ALL_VACANCIES");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_VACANTE({ rootState, dispatch }, params) {
      console.log(params);
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_VACANCIES}/api/admin/vacantes/${params.id}`,
          headers: rootState.configAxios.headers,
          data: {
            _method: "PUT",
            titulo: params.titulo,
            descripcion: params.descripcion,
            tipo_contrato: params.tipo_contrato,
            presupuesto: params.presupuesto,
            categoria: params.categoria,
            como_aplicar: params.como_aplicar,
            posicion: params.posicion,
            locacion: params.locacion,
          },
        });
        if (data) {
          dispatch("GET_ALL_VACANCIES");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
  },
};
