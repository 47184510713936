import axios from "axios";

const URL_KOMERCIA_PARTNERS = process.env.VUE_APP_URL_KOMERCIA;

export default {
  namespaced: true,
  state: {
    allPartners: [],
  },
  mutations: {
    // SET_ALL_VACANCIES(state, value) {
    //   state.allVacancies = value
    // },
  },
  getters: {},
  actions: {
    // Funcionando
    async GET_ALL_PARTNERS({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${URL_KOMERCIA_PARTNERS}/api/admin/partners`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.allPartners = data.partners.data;
          // commit('SET_ALL_VACANCIES', data)
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_PARTNERS({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_PARTNERS}/api/admin/partners`,
          headers: rootState.configAxios.headers,
          data: {
            name: params.name,
            location: params.location,
            tags: params.tags,
            url: params.url,
            logo: params.logo,
            precio: params.precio,
            descripcion: params.descripcion,
          },
        });
        if (data) {
          dispatch("GET_ALL_PARTNERS");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_PARTNERS({ rootState, dispatch }, id) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${URL_KOMERCIA_PARTNERS}/api/admin/partners/${id.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data && data.estado == 200) {
          dispatch("GET_ALL_PARTNERS");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_PARTNERS({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_PARTNERS}/api/admin/partners/${params.id}`,
          headers: rootState.configAxios.headers,
          data: {
            _method: "PUT",
            name: params.name,
            location: params.location,
            tags: params.tags,
            url: params.url,
            logo: params.logo,
            precio: params.precio,
            descripcion: params.descripcion,
          },
        });
        if (data) {
          dispatch("GET_ALL_PARTNERS");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
  },
};
