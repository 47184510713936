<template>
  <div
    v-if="storeData && storeData.redes"
    class="wrapper-items-redes"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div class="content-items-redes">
      <p class="title-redes" :class="darkMode ? 'darks' : 'lights'">
        {{ $t("text_whatsapp_conectaRedeSociales") }}
      </p>
      <div class="grid mt-10">
        <div
          class="wrapper-card-social"
          :class="darkMode ? 'darksCard' : 'lightsCard'"
        >
          <div class="bg-img bg-wapp">
            <img
              loading="lazy"
              class="image_icono"
              src="../../assets/iconSocial/whatsappSocial.png"
            />
          </div>
          <div class="w-full">
            <p class="flex items-center text-info mb-1">
              WhatsApp
              <sliderInformation :numberId="67" :actions="true" class="ml-2" />
            </p>
            <div v-if="wpVisible" class="content_whatsapp_inputs">
              <el-select
                v-model="codeCountryPhone"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                filterable
                placeholder="País"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input
                v-model="storeData.redes[0].whatsapp"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                placeholder="Número de Whatsapp"
              >
                <i
                  v-if="storeData.redes[0].whatsapp"
                  slot="suffix"
                  class="el-icon-success el-input__icon icon_color"
                />
                <i
                  v-else
                  slot="suffix"
                  class="el-icon-error el-input__icon icon_color-error"
                />
              </el-input>
            </div>
            <div v-else class="content_whatsapp_inputs">
              <el-input
                v-model="storeData.redes[0].whatsapp"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                placeholder="Número de Whatsapp"
                :disabled="true"
                @click="showInputWP"
              >
                <i
                  v-if="storeData.redes[0].whatsapp"
                  slot="suffix"
                  class="el-icon-success el-input__icon icon_color"
                />
                <i
                  v-else
                  slot="suffix"
                  class="el-icon-error el-input__icon icon_color-error"
                />
              </el-input>
              <el-button
                class="wpbutton"
                type="info"
                size="small"
                style="height: 40px; margin-left: 5px; border-radius: 10px"
                plain
                @click="showInputWP"
              >
                {{ $t("text_inventario_editar") }}
              </el-button>
              <a
                v-if="storeData.redes[0].whatsapp.charAt(0) == '3'"
                title="Abrir WhatsApp"
                :href="`https://web.whatsapp.com/send?phone=+57${storeData.redes[0].whatsapp}`"
                target="blank"
              >
                <el-button
                  class="wpbutton"
                  type="success"
                  size="small"
                  style="height: 40px; margin-left: 5px; border-radius: 10px"
                  plain
                  >{{ $t("text_inventario_probar") }}</el-button
                >
              </a>
              <a
                v-else
                title="Abrir WhatsApp"
                :href="`https://web.whatsapp.com/send?phone=+${storeData.redes[0].whatsapp}`"
                target="blank"
              >
                <el-button
                  class="wpbutton"
                  type="success"
                  size="small"
                  style="height: 40px; margin-left: 5px; border-radius: 10px"
                  plain
                  >{{ $t("text_inventario_probar") }}</el-button
                >
              </a>
            </div>
          </div>
        </div>
        <div
          class="wrapper-card-social"
          :class="darkMode ? 'darksCard' : 'lightsCard'"
        >
          <div class="bg-img bg-facebook">
            <img
              loading="lazy"
              class="image_icono"
              src="../../assets/iconSocial/FacebookSocial.png"
            />
          </div>
          <div class="content-card-social">
            <p class="text-info mb-2">Facebook</p>
            <el-input
              v-model="storeData.redes[0].facebook"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Tu Fanpage"
              @change="showButton"
            >
              <i
                v-if="storeData.redes[0].facebook"
                slot="suffix"
                class="el-icon-success el-input__icon icon_color"
              />
              <i
                v-else
                slot="suffix"
                class="el-icon-error el-input__icon icon_color-error"
              />
            </el-input>
          </div>
        </div>
        <div
          class="wrapper-card-social"
          :class="darkMode ? 'darksCard' : 'lightsCard'"
        >
          <div class="bg-img bg-instagram">
            <img
              loading="lazy"
              class="image_icono"
              src="../../assets/iconSocial/InstagramSocial.png"
            />
          </div>
          <div class="content-card-social">
            <p class="text-info mb-2">Instagram</p>
            <el-input
              v-model="storeData.redes[0].instagram"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Usuario Instagram"
              @change="showButton"
            >
              <i
                v-if="storeData.redes[0].instagram"
                slot="suffix"
                class="el-icon-success el-input__icon icon_color"
              />
              <i
                v-else
                slot="suffix"
                class="el-icon-error el-input__icon icon_color-error"
              />
            </el-input>
          </div>
        </div>
        <div
          class="wrapper-card-social"
          :class="darkMode ? 'darksCard' : 'lightsCard'"
        >
          <div class="bg-img bg-twitter">
            <img
              loading="lazy"
              class="image_icono"
              src="../../assets/iconSocial/twitterSocial.png"
            />
          </div>
          <div class="content-card-social">
            <p class="text-info mb-2">Twitter</p>
            <el-input
              v-model="storeData.redes[0].twitter"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Link de Twitter"
              @change="showButton"
            >
              <i
                v-if="storeData.redes[0].twitter"
                slot="suffix"
                class="el-icon-success el-input__icon icon_color"
              />
              <i
                v-else
                slot="suffix"
                class="el-icon-error el-input__icon icon_color-error"
              />
            </el-input>
          </div>
        </div>
        <div
          class="wrapper-card-social"
          :class="darkMode ? 'darksCard' : 'lightsCard'"
        >
          <div class="bg-img bg-youtube">
            <img
              loading="lazy"
              class="image_icono"
              src="../../assets/iconSocial/youtubeSocial.png"
            />
          </div>
          <div class="content-card-social">
            <p class="text-info mb-2">YouTube</p>
            <el-input
              v-model="storeData.redes[0].youtube"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Canal de Youtube"
              @change="showButton"
            >
              <i
                v-if="storeData.redes[0].youtube"
                slot="suffix"
                class="el-icon-success el-input__icon icon_color"
              />
              <i
                v-else
                slot="suffix"
                class="el-icon-error el-input__icon icon_color-error"
              />
            </el-input>
          </div>
        </div>
        <div
          class="wrapper-card-social"
          :class="darkMode ? 'darksCard' : 'lightsCard'"
        >
          <div class="bg-img bg-facebook">
            <img
              loading="lazy"
              class="image_icono"
              src="../../assets/iconSocial/tiktokSocial.png"
            />
          </div>
          <div class="content-card-social">
            <p class="text-info mb-2">TikTok</p>
            <el-input
              v-model="storeData.redes[0].tiktok"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Canal de Tiktok"
              @change="showButton"
            >
              <i
                v-if="storeData.redes[0].tiktok"
                slot="suffix"
                class="el-icon-success el-input__icon icon_color"
              />
              <i
                v-else
                slot="suffix"
                class="el-icon-error el-input__icon icon_color-error"
              />
            </el-input>
          </div>
        </div>
      </div>
      <div class="mt-8 w-full flex items-end justify-center">
        <el-button
          v-if="stateButton"
          type="primary"
          class="button_save btn-save"
          @click="validateForm"
        >
          {{ $t("text_guardaCambios") }}
        </el-button>
        <el-button v-else disabled type="success" class="button_save btn-save">
          {{ $t("text_guardadoCorrectamente") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import sliderInformation from "@/components/information.vue";
export default {
  name: "generalSocial",
  components: { sliderInformation },
  data() {
    return {
      codeCountryPhone: null,
      wpVisible: false,
      options: [
        {
          value: "+54",
          label: "+54 Argentina",
        },
        {
          value: "+55",
          label: "+55 Brasil",
        },
        {
          value: "+56",
          label: "+56 Chile",
        },
        {
          value: "+57",
          label: "+57 Colombia",
        },
        {
          value: "+593",
          label: "+593 Ecuador",
        },
        {
          value: "+34",
          label: "+34 España",
        },
        {
          value: "+1",
          label: "+1 Estados Unidos",
        },
        {
          value: "+52",
          label: "+52 México",
        },
        {
          value: "+51",
          label: "+51 Perú",
        },
        {
          value: "+1",
          label: "+1 Puerto Rico",
        },
        {
          value: "+1",
          label: "+1 República Dominicana",
        },
        {
          value: "+507",
          label: "+507 Panamá",
        },
      ],
      value: "",
      inputInsta: "",
      stateButton: true,
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    storeData: {
      get() {
        return this.$store.state.storeData;
      },
      set(newValue) {
        this.$store.state.storeData = newValue;
      },
    },
  },
  created() {
    if (
      this.storeData &&
      this.storeData.redes &&
      this.storeData.redes[0].whatsapp == ""
    ) {
      this.wpVisible = true;
    }
  },
  methods: {
    showButton() {
      this.stateButton = true;
    },
    showInputWP() {
      this.storeData.redes[0].whatsapp = "";
      this.wpVisible = true;
      this.stateButton = true;
    },
    validateForm() {
      if (this.storeData) {
        // if (
        //   this.storeData.redes[0].whatsapp != '' &&
        //   this.codeCountryPhone == null
        // ) {
        //   this.$message.error(
        //     'Debes seleccionar el código del país para WhatsApp'
        //   )
        // } else if (
        //   this.storeData.redes[0].whatsapp == '' &&
        //   this.codeCountryPhone != null
        // ) {
        //   this.$message.error(
        //     'Seleccionaste el código de país, falta el número de WhatsApp'
        //   )
        // } else {
        this.updateStoreData();
        // }
      }
    },
    async updateStoreData() {
      if (this.storeData.redes[0].whatsapp != "") {
        this.wpVisible = false;
      }
      if (this.storeData.redes[0].instagram) {
        let result = this.storeData.redes[0].instagram.split(":");
        if (result[0] != "https") {
          this.inputInsta = `https://www.instagram.com/${result}`;
          this.storeData.redes[0].instagram = this.inputInsta;
        } else {
          this.inputInsta = this.storeData.redes[0].instagram;
          this.storeData.redes[0].instagram = this.inputInsta;
        }
      }
      let whatsapp;
      if (this.codeCountryPhone == null) {
        whatsapp = this.storeData.redes[0].whatsapp;
      } else {
        whatsapp = this.codeCountryPhone + this.storeData.redes[0].whatsapp;
        this.storeData.redes[0].whatsapp = whatsapp;
      }
      let params = {
        nombre: this.storeData.nombre,
        categoria: this.storeData.categoria,
        telefono: this.storeData.informacion_tienda[0].telefono,
        departamento: this.storeData.ciudad.departamento.id,
        ciudad: this.storeData.ciudad.id,
        email: this.storeData.informacion_tienda[0].email_tienda,
        descripcion: this.storeData.informacion_tienda[0].descripcion,
        nosotros: this.storeData.paginas_tienda[0].nosotros,
        mision: this.storeData.paginas_tienda[0].mision,
        vision: this.storeData.paginas_tienda[0].vision,
        facebook: this.storeData.redes[0].facebook,
        instagram: this.inputInsta,
        twitter: this.storeData.redes[0].twitter,
        youtube: this.storeData.redes[0].youtube,
        whatsapp: whatsapp,
        tiktok: this.storeData.redes[0].tiktok,
        subdominio: this.storeData.subdominio,
        moneda: this.storeData.informacion_tienda[0].moneda,
        lenguaje: this.storeData.informacion_tienda[0].lenguaje,
        pais: this.storeData.informacion_tienda[0].paises_id,
        etiquetas: this.storeData.informacion_tienda[0].etiquetas,
        fachada: this.storeData.fachada,
        minimo_compra: this.storeData.informacion_tienda[0].valor_compra_minimo,
      };
      const { status } = await this.$store.dispatch("UPDATE_STORE", params);
      if (status === 200) {
        this.$message.success("Información actualizada correctamente");
      }
      this.stateButton = false;
    },
  },
};
</script>

<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  color: #fff;
}
.lights {
  color: #1f2937;
}
.wrapper-items-redes {
  width: 100%;
  overflow: auto;
  border-radius: 12px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.content-items-redes {
  padding: 30px 40px;
  @apply flex flex-col;
}
.title-redes {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 16px;
}
.wrapper-card-social {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-card-social {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bg-img {
  width: 65px;
  height: 65px;
  margin-right: 15px;
  padding: 16.6px;
  border-radius: 13px;
}
.image_icono {
  width: 35px;
  margin-right: 15px;
}
.bg-wapp {
  background-color: #e0faef;
}
.bg-facebook {
  background-color: #f2efff;
}
.bg-instagram {
  background-color: #faebff;
}
.bg-twitter {
  background-color: #ecf7ff;
}
.bg-youtube {
  background-color: #fad4d4;
}
.bg-tiktok {
  background-color: #f4f4f4;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-idTienda >>> .el-input.is-disabled.el-input__inner {
  background-color: transparent;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  cursor: not-allowed;
}
.darks-input >>> .el-input__inner .el-input.is-disabled {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner .el-input.is-disabled {
  color: #1f2937;
}
.btn-save {
  width: 100%;
  max-width: 220px;
  height: 42px;
  padding: 13px 30px;
  border-radius: 10px;
  font-size: 12px;
}
.content_whatsapp_inputs {
  width: 100%;
  display: flex;
}
.wpbutton {
  width: 70px;
}
.el-icon-success {
  font-size: 15px;
  color: #00d57e;
}
.icon_color-error {
  font-size: 15px;
  color: #d50000;
}
</style>
