// import { faSearch } from '@fortawesome/free-solid-svg-icons'

const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA;
const URL_KOMERCIA_API2 = process.env.VUE_APP_URL_KOMERCIA2;
const URL_KOMERCIA_HELPDESK = process.env.VUE_APP_URL_KOMERCIA_HELPDESK;
const URL_KOMERCIA_TEMPLATE = process.env.VUE_APP_URL_KOMERCIA_TEMPLATE;
const URL_KOMERCIA_AWS = process.env.VUE_APP_URL_KOMERCIA_AWS;

export default {
  alertLimitProducts: {
    text: "",
    show: false,
  },
  Helplistarticulos: [],
  Helpcategories: [],
  configKomercia: {},
  urlKomercia: URL_KOMERCIA_API,
  urlKomercia3: URL_KOMERCIA_API2,
  urlKomerciaHelpDesk: URL_KOMERCIA_HELPDESK,
  urlNodeTemplates: URL_KOMERCIA_TEMPLATE,
  urlKomerciaAWS: URL_KOMERCIA_AWS,
  routerKey: "c6979297-txfg-4962-7sag-709c76a71755",
  configAxios: {
    headers: {
      "content-type": "application/json",
      Authorization: "",
      "Access-Control-Allow-Origin": "*",
      Access: "application/json",
    },
  },
  infoPaginationProducts: [],
  modalSettingsTemplate: false,
  user: null,
  userData: null,
  ipAddress: "",
  welcomeTourStatus: false,
  stepNumber: 0,
  visibleSubdomainDialog: false,
  settingsTemplate: {},
  settingsLink: {},
  refreshIframeTemplate: false,
  tasksData: [],
  productsData: [],
  productData: null,
  articleData: null,
  tagsData: [],
  categoriesData: [],
  subcategoriesData: [],
  storeData: {},
  categoriesForStores: null,
  policiesData: {},
  cropperBanner: null,
  paymentsData: {
    addi: {
      clientID: "",
      clientSecret: "",
      ally_slug: "",
      storeId: "",
    },
    mercadopago: {},
    epayco: {
      pCustIdCliente: "",
      pKey: "",
      publicKey: "",
    },
    payu: {
      apiKey: "",
      apiLogin: "",
      merchantId: "",
      accountId: "",
    },
    wompi: {
      llavePublica: "",
      integrity: "",
    },
    payments_way: {
      estado: 1,
      formId: "https://api2.komercia.co/payments-way/hook",
    },
    tu_compra: {
      estado: 1,
      username: "",
    },
    wepay4u: { key: "", estado: 1 },
    flow: { apiKey: "", secretKey: "" },
  },
  paymentMethods: {},
  paymentGateways: [],
  template7Data: null,
  identificationTypes: [
    {
      idCountry: 1,
      nameCountry: "Colombia",
      option: [
        {
          label: "CÉDULA DE CIUDADANÍA",
          value: "CC",
        },
        {
          label: "CÉDULA DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PPN",
        },
        {
          label: "NÚMERO DE SEGURIDAD SOCIAL",
          value: "SSN",
        },
        {
          label: "LICENCIA DE CONDUCCIÓN",
          value: "LIC",
        },
        {
          label: "NÚMERO DE IDENTIFICACIÓN TRIBUTARIA",
          value: "NIT",
        },
        {
          label: "TARJETA DE IDENTIDAD",
          value: "TI",
        },
      ],
    },
    {
      idCountry: 2,
      nameCountry: "Internacional",
      option: [
        {
          label: "CÉDULA DE CIUDADANÍA",
          value: "CC",
        },
        {
          label: "CLAVE ÚNICA DEL REGISTRO DE POBLACIÓN",
          value: "CURP",
        },
        {
          label: "DOCUMENTO NACIONAL DE IDENTIDAD",
          value: "DNI",
        },
        {
          label: "LICENCIA DE CONDUCIR O IDENTIFICACIÓN",
          value: "ID",
        },
        {
          label: "CÉDULA DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PPN",
        },
        {
          label: "LICENCIA DE CONDUCCIÓN",
          value: "LIC",
        },
      ],
    },
    {
      idCountry: 3,
      nameCountry: "México",
      option: [
        {
          label: "CLAVE ÚNICA DEL REGISTRO DE POBLACIÓN",
          value: "CURP",
        },
        {
          label: "CÉDULA DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PPN",
        },
      ],
    },
    {
      idCountry: 6,
      nameCountry: "Argentina",
      option: [
        {
          label: "DOCUMENTO NACIONAL DE IDENTIDAD",
          value: "DNI",
        },
        {
          label: "CÉDULA DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PPN",
        },
      ],
    },
    {
      idCountry: 7,
      nameCountry: "Chile",
      option: [
        {
          label: "CÉDULA DE IDENTIDAD",
          value: "DNI",
        },
        {
          label: "CÉDULA DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PPN",
        },
      ],
    },
    {
      idCountry: 8,
      nameCountry: "Puerto Rico",
      option: [
        {
          label: "LICENCIA DE CONDUCIR O IDENTIFICACIÓN",
          value: "ID",
        },
        {
          label: "CÉDULA DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PASAPORTE",
        },
      ],
    },
    {
      idCountry: 9,
      nameCountry: "Perú",
      option: [
        {
          label: "DOCUMENTO NACIONAL DE IDENTIDAD",
          value: "DNI",
        },
        {
          label: "CARNET DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "REG. ÚNICO DE CONTRIBUYENTES",
          value: "RUC",
        },
        {
          label: "PASAPORTE",
          value: "PASAPORTE",
        },
      ],
    },
    {
      idCountry: 10,
      nameCountry: "Panamá",
      option: [
        {
          label: "CÉDULA DE IDENTIDAD PERSONAL",
          value: "CIP",
        },
        {
          label: "CARNET DE EXTRANJERÍA",
          value: "CE",
        },
        {
          label: "PASAPORTE",
          value: "PASAPORTE",
        },
      ],
    },
  ],
  shippingData: [
    {
      estado: true,
    },
    {
      estado: true,
    },
  ],
  banksData: {},
  branchesData: [],
  themesData: [],
  bannersData: [],
  showCropperBanner: false,
  colorsData: {},
  fontsData: [],
  // ordersData: {},
  allOrdersData: [],
  postsData: [],
  unsplashData: [],
  departments: [],
  cities: [],
  citiesByDeparments: [],
  customers: null,
  combinations: [],
  variants: [],
  shippingPolicies: "",
  addons: {
    facebook: null,
  },
  mercadolibreUser: null,
  mercadoPagoStatus: null,
  allStore: {},
  currentOrder: null,
  servicesData: null,
  idRate: 0,
  shipmentResponse: {},
  shipment: {
    radio: "0",
    pickupDate: "",
    senderName: "",
    destinationName: "",
    senderLastName: "",
    destinationLastName: "",
    destinationCompany: "",
    senderCompany: "",
    senderNeighborhood: "",
    destinationNeighborhood: "",
    externalSenderReference: "",
    externalDestinationReference: "",
    senderPhone: "",
    destinationPhone: "",
    senderEmail: "",
    destinationEmail: "",
    myShipmentReference: "",
  },
  invoice: null,
  branch: [],
  analytics: "",
  tagManager: "",
  tidioUser: "",
  pixelFacebook: "",
  metaTagFacebook: "",
  messengerFacebook: "",
  googleMerchants: "",
  notifications: null,
  collapseLayout: false,
  subscribers: [],
  messages: [],
  stateOnlineStore: true,
  currentCountry: null,
  productsInactivos: null,
  infoPaginationProductsInactivos: null,
  countries: [],
  openSliderOver: false,
  dataCubiko: "",
  modalSearch: false,
  modalrefer: false,
  windowWidth: window.innerWidth,
  miscSidebar: {
    isActive: false,
    isHelpdesk: true,
    data: null,
  },
  darkMode: false,
  facturaShow: false,
  showSlideIntegrations: false,
  selectItegration: "",
  selectLanguage: "",
  selectLanguagePanel: "",
  modalCategory: false,
  modalEtiquetas: false,
  showPublicity: true,
  showPublicityPrueba: true,
  dataHoko: {
    user: "",
    pass: "",
    token: "",
    statehoko: "",
  },
  previousPageHoko: 1,
  previousPage: 1,
  previousPageOrden: 1,
  previousPageDropshipping: 1,
  modalExport: false,
  domain: "",
  modalVacantes: false,
  dataPerks: [],
  dataCupones: [],
  dataAddi: {
    state: 0,
    clientID: null,
    clientSecret: null,
    ally_slug: null,
    productionMode: 1,
  },
};
