<template>
  <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
    <div class="flex flex-col">
      <DialogLimit />
      <!-- <div class="product_form"> -->
      <!-- <div class="bgModal" v-if="modalCategory" @click="closeCategory">
          <div class="divmodal">
            <Modal-Category />
          </div>
        </div> -->
      <div class="w-full flex items-center justify-center">
        <Photos
          :data="emptyProduct"
          :youtube="emptyProduct.info[0].video"
          @upload="getPhoto"
          @update:youtube="getYoutube"
        />
        <!-- <Information :data="emptyProduct" v-if="hasImage" />
          <Inventory
            v-if="hasImage"
            :data="emptyProduct"
            @change="updateWithVariant"
          /> -->
      </div>
      <!-- <div class="actions" v-if="hasImage">
          <el-button class="pull-right" @click="redirectTo" type="danger">
            {{ $t('text_inventario_cancelar') }}
          </el-button>
          <el-button type="primary" class="pull-right" @click="createProduct">
            {{ $t('text_inventario_guardar') }}
          </el-button>
        </div>
        <div class="col-right">
          <AsideComponent :data="emptyProduct" v-if="hasImage" />
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import Photos from "@/components/ProductForm/Photos.vue";
// import Information from '@/components/ProductForm/Information.vue'
// import AsideComponent from '@/components/ProductForm/Aside.vue'
// import Inventory from '@/components/ProductForm/Inventory.vue'
import DialogLimit from "@/components/ProductForm/LimitProducts.vue";
// import ModalCategory from '@/views/Inventory/Categories.vue'
export default {
  name: "NewProducts",
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     this.prevRoute = from;
  //   });
  // },
  components: {
    Photos,
    DialogLimit,
    // Information,
    // Inventory,
    // AsideComponent,
    // ModalCategory,
  },
  data() {
    return {
      prevRoute: null,
      photo: null,
      emptyProduct: {
        detalle: {
          id: 0,
          activo: "1",
          disponibilidad: "1",
          foto: "",
          foto_cloudinary: "",
          nombre: "",
          precio: 0,
          categoria_producto: {
            id: "",
            nombre_categoria_producto: "",
          },
          subcategoria_producto: {
            id: "",
            nombre_subcategoria: "",
          },
          garantia: "",
          envio_gratis: 0,
          con_variante: 0,
        },
        fotos: [],
        info: [
          {
            descripcion: "",
            inventario: 0,
            marca: "",
            peso: "",
            sku: "",
            video: "",
          },
        ],
        combinaciones: {},
        variantes: [],
      },
      loading: false,
      loadingText: "",
      response: null,
      hasImage: false,
    };
  },
  computed: {
    settingProducts() {
      return this.$store.getters.settingProducts;
    },
    productsData() {
      return this.$store.state.productsData;
    },
    modalCategory() {
      return this.$store.state.modalcategory;
    },
  },
  watch: {
    productsData(value) {
      if (this.$store.getters.settingProducts.amount <= value.length) {
        this.$router.push("/inventario/productos#1");
      }
    },
  },
  created() {
    if (
      this.$store.getters.settingProducts.amount <=
      this.$store.state.productsData.length
    ) {
      this.$router.push("/inventario/productos#1");
    }
  },
  methods: {
    getPhoto(photo) {
      if (photo.type === "major") {
        this.photo = photo.payload;
        this.createProduct();
      }
    },
    async createProduct() {
      this.loadingText = "Creando el producto en el servidor...";
      this.loading = true;
      let params = {
        foto: this.photo.secure_url,
        id_foto: this.photo.public_id,
        nombre: this.emptyProduct.detalle.nombre || "Nombre del producto",
        marca: this.emptyProduct.info[0].marca,
        precio: this.emptyProduct.detalle.precio || 0,
        disponibilidad: this.emptyProduct.detalle.disponibilidad,
        descripcion: this.emptyProduct.info[0].descripcion,
        video: this.emptyProduct.info[0].video,
        categoria_producto:
          this.emptyProduct.detalle.categoria_producto.id || 0,
        subcategoria: this.emptyProduct.detalle.subcategoria_producto.id || 0,
        activo: this.emptyProduct.detalle.activo,
        sku: this.emptyProduct.info[0].sku,
        peso: this.emptyProduct.info[0].peso,
        garantia: this.emptyProduct.detalle.garantia,
        inventario: this.emptyProduct.info.inventario,
        variantes: this.emptyProduct.variantes,
        combinaciones: this.emptyProduct.combinaciones.combinaciones,
        envio_gratis: this.emptyProduct.detalle.envio_gratis,
        con_variante: this.emptyProduct.detalle.con_variante,
        tag: this.emptyProduct.detalle.tag,
      };
      this.response = await this.$store.dispatch("CREATE_PRODUCT", params);
      this.$router.push(`/inventario/productos/${this.response.detalle.id}`);
      this.loading = false;
      this.hasImage = true;
    },
    savePhotos(photos, id) {
      for (let [index, photo] of photos.entries()) {
        this.loading = true;
        let params = new FormData();
        params.append("id_producto", id);
        params.append("foto", photo);
        const { status } = this.$store.dispatch(
          "UPLOAD_EXTRA_PHOTO_OF_PRODUCT",
          params
        );
        if (status === 200) {
          this.$message.success("Foto subida satisfactoriamente");
          if (index === photos.length - 1) {
            this.loading = false;
            this.$router.push("/inventario/productos#1");
          }
        } else {
          this.loading = false;
        }
      }
    },
    redirectTo() {
      this.$router.push("/inventario/productos#1");
    },
    updateWithVariant(state) {
      this.emptyProduct.detalle.con_variante = state;
    },
    validator(product) {
      if (
        product.detalle.nombre !== "" &&
        product.detalle.foto_cloudinary !== ""
      ) {
        return true;
      } else {
        if (product.detalle.nombre === "") {
          this.$message("Debe ingresar el nombre del producto");
        } else {
          this.$message("Debe subir mínimo una foto del producto");
        }
      }
    },
    getYoutube(url) {
      this.emptyProduct.info[0].video = url;
    },
    closeCategory(event) {
      const element = event.target.className;
      if (element === "bgModal") {
        this.$store.state.modalcategory = false;
      }
    },
  },
};
</script>

<style scoped>
.bgModal {
  background-color: rgba(0, 0, 0, 0.768);
  z-index: 99999;
  top: 0px;
  left: 0px;
  @apply w-full h-full fixed flex justify-center items-center;
}
.divmodal {
  z-index: 999999;
  max-width: 1500px;
  max-height: 800px;
  @apply w-full flex justify-start items-center shadow-md rounded-md overflow-hidden overflow-y-auto;
}
.backandhelp {
  display: grid;
  grid-area: top;
}
/* .product_form {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'top    right'
    'left    right'
    'actions right';
  grid-column-gap: 20px;
  grid-template-columns: 9fr 3fr;
  align-items: start;
  margin: 0 auto;
} */
.col-left {
  display: grid;
  grid-area: left;
}
.return_products {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.return_products i {
  margin-right: 7px;
}
.col-right {
  position: sticky;
  top: 178px;
  grid-area: right;
}
.title {
  font-size: 20px;
  margin: 15px 0;
  margin-left: 30px;
}
.col-left .box-body {
  display: flex;
  flex-direction: column;
}
.grid {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.input-area {
  max-width: calc(50% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area-full {
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area .el-input,
.input-area .quillWrapper,
.input-area .el-select {
  width: 100%;
}
.iframe_youtube {
  width: 100px;
  height: 100px;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  grid-area: actions;
}
.actions:last-child {
  display: grid;
  justify-content: center;
  grid-auto-flow: row;
  grid-template: 1fr / auto 1fr;
}
.actions_left {
  justify-self: end;
}
.free-shipping {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 2fr;
  align-items: center;
}
.free-shipping div:nth-child(2) {
  /*justify-self: end;*/
  align-self: end;
}
.details {
  font-size: 12px;
  margin: 0;
}
/*@media (max-width: 1200px) {
    .actions{
      order: 2;
    }
  }*/
@media (max-width: 1440px) {
  .divmodal {
    max-width: 1300px;
  }
  .wrapper-content-items {
    padding: 10px 20px;
  }
}
@media (max-width: 1366px) {
  .divmodal {
    max-width: 1200px;
  }
}
@media (max-width: 1280px) {
  .divmodal {
    max-width: 1100px;
  }
}
@media (max-width: 1080px) {
  .divmodal {
    max-width: 850px;
  }
}
@media (max-width: 1200px) {
  /* .product_form {
    grid-template-areas:
      'top'
      'left'
      'right'
      'actions';
    grid-template-columns: 12fr;
  } */
}
@media (max-width: 550px) {
  .actions:last-child {
    display: grid;
    justify-content: center;
    grid-auto-flow: row;
    grid-template: 1fr 1fr / auto;
    grid-row-gap: 10px;
  }
}
</style>
