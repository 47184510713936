<script>
export default {
  name: "mixinsStates",
  computed: {
    saleStateHoko() {
      return [
        // CANCELADA => 0
        {
          id: 0,
          state: "Cancelada",
          color: "#bec4c1",
          type: "info",
        },
        // ACTIVA => 1
        {
          id: 1,
          state: "Activa",
          color: "#30C46F",
          type: "success",
        },
        // DESPACHADA => 2,
        {
          id: 2,
          state: "Despachada",
          color: "#30C46F",
          type: "success",
        },
        // ENTREGADA => 3
        {
          id: 3,
          state: "Entregada",
          color: "#30C46F",
          type: "success",
        },
        // ANULADA => 4
        {
          id: 4,
          state: "Anulada",
          color: "#30C46F",
          type: "success",
        },
        // GENERADA => 5
        {
          id: 5,
          state: "Generada",
          color: "#30C46F",
          type: "success",
        },
        // EN NOVEDAD => 6
        {
          id: 6,
          state: "En novedad",
          color: "#30C46F",
          type: "success",
        },
        // EN REPARTO => 7
        {
          id: 7,
          state: "En reparto",
          color: "#30C46F",
          type: "success",
        },
        // EN BODEGA => 8
        {
          id: 8,
          state: "En bodega",
          color: "#30C46F",
          type: "success",
        },
        // REEXPEDICIÓN => 9
        {
          id: 9,
          state: "Reexpedución",
          color: "#30C46F",
          type: "success",
        },
        // SOLUCIONADA EN MALLA => 10
        {
          id: 10,
          state: "Solicitud en malla",
          color: "#30C46F",
          type: "success",
        },
        // DEVOLUCIÓN => 11
        {
          id: 11,
          state: "Devolución",
          color: "#30C46F",
          type: "success",
        },
        // EN PROCESAMIENTO => 12
        {
          id: 12,
          state: "En procesamiento",
          color: "#30C46F",
          type: "success",
        },
        // RECIBIDO DEL CLIENTE => 13
        {
          id: 13,
          state: "Recibido del cliente",
          color: "#30C46F",
          type: "success",
        },
        // REDIRECCIONADO => 14
        {
          id: 14,
          state: "Redireccionado",
          color: "#30C46F",
          type: "success",
        },
        // EN ESPERA DE RUTA DOMESTICA => 15
        {
          id: 15,
          state: "En espera de ruta domestica",
          color: "#30C46F",
          type: "success",
        },
        // MERCANCIA RECOGIDA => 16
        {
          id: 16,
          state: "Mercancía recogida",
          color: "#30C46F",
          type: "success",
        },
        // PAGADO => 17
        {
          id: 17,
          state: "Pagado",
          color: "#30C46F",
          type: "success",
        },
        // ERROR POR SALDO => 18
        {
          id: 18,
          state: "Error por saldo",
          color: "#30C46F",
          type: "success",
        },
        // PAGADO A TIENDA => 19
        {
          id: 19,
          state: "Pagado a tienda",
          color: "#30C46F",
          type: "success",
        },
        // DEVOLUCION COBRADO => 20
        {
          id: 20,
          state: "Devolución cobrado",
          color: "#30C46F",
          type: "success",
        },
        // ERROR POR API => 21
        {
          id: 21,
          state: "Error por API",
          color: "#30C46F",
          type: "success",
        },
        // TRANSPORTADORA INVALIDA => 22
        {
          id: 22,
          state: "Transportadora invalida",
          color: "#30C46F",
          type: "success",
        },
      ];
    },
    saleStateShort() {
      return [
        {
          id: 0,
          state: "Sin Pagar",
          color: "#FFA801",
          type: "warning",
        },
        {
          id: 1,
          state: "Pagada",
          color: "#30C490",
          type: "success",
        },
        {
          id: 3,
          state: "Cancelada",
          color: "#EB4D4B",
          type: "info",
        },
        {
          id: 4,
          state: "Despachado",
          color: "#0000FF",
          type: "",
        },
        {
          id: 6,
          state: "Entregado",
          color: "#4429AE",
          type: "success",
        },
      ];
    },
    shippingState() {
      return [
        {
          id: 1,
          state: this.$t("text_header_pendiente"),
          color: "#FFA801",
        },
        {
          id: 2,
          state: this.$t("text_ventas_enEmpaque"),
          color: "#FFA801",
        },
        {
          id: 3,
          state: this.$t("text_ventas_enTransito"),
          color: "#4429AE",
        },
        {
          id: 4,
          state: this.$t("text_ventas_devuelto"),
          color: "#0000FF",
        },
        {
          id: 5,
          state: this.$t("text_ventas_entregado"),
          color: "#30C490",
        },
        {
          id: 6,
          state: this.$t("text_ventas_cancelado"),
          color: "#EB4D4B",
        },
      ];
    },
    saleState() {
      return [
        {
          id: 0,
          value: "0",
          state: this.$t("text_ventas_sinPagar"),
          state2: "text_ventas_sinPagar",
          color: "#FFA801",
          type: "warning",
        },
        {
          id: 1,
          value: "1",
          state: this.$t("text_ventas_pagada"),
          state2: "text_ventas_pagada",
          color: "#30C490",
          type: "success",
        },
        {
          id: 2,
          value: "2",
          state: this.$t("text_ventas_rechazada"),
          state2: "text_ventas_rechazada",
          color: "#EB4D4B",
          type: "danger",
        },
        {
          id: 3,
          value: "3",
          state: this.$t("text_ventas_cancelado"),
          state2: "text_ventas_cancelado",
          color: "#EB4D4B",
          type: "info",
        },
        {
          id: 4,
          value: "4",
          state: this.$t("text_ventas_despachado"),
          state2: "text_ventas_despachado",
          color: "#0000FF",
          type: "",
        },
        {
          id: 5,
          value: "5",
          state: this.$t("text_ventas_recibido"),
          state2: "text_ventas_recibido",
          color: "#30336B",
          type: "success",
        },
        {
          id: 6,
          value: "6",
          state: this.$t("text_ventas_entregado"),
          state2: "text_ventas_entregado",
          color: "#4429AE",
          type: "success",
        },
        {
          id: 7,
          value: "7",
          state: this.$t("text_ventas_finalizado"),
          state2: "text_ventas_finalizado",
          color: "#0000FF",
          type: "success",
        },
        {
          id: 8,
          value: "8",
          state: this.$t("text_ventas_cotizacion"),
          state2: "text_ventas_cotizacion",
          color: "#706FD3",
          type: "primary",
        },
        {
          id: 9,
          value: "9",
          state: this.$t("text_ventas_procesandoPago"),
          state2: "text_ventas_procesandoPago",
          color: "#FFA801",
          type: "primary",
        },
        {
          id: 10,
          value: "10",
          state: this.$t("text_ventas_rechazada"),
          state2: "text_ventas_rechazada",
          color: "#EB4D4B",
          type: "primary",
        },
        {
          id: 11,
          value: "11",
          state: this.$t("text_ventas_abandonada"),
          state2: "text_ventas_abandonada",
          color: "#BD0004",
          type: "primary",
        },
        {
          id: 12,
          value: "12",
          state: this.$t("text_ventas_retenida"),
          state2: "text_ventas_retenida",
          color: "#FF4500",
          type: "primary",
        },
        {
          id: 13,
          value: "13",
          state: this.$t("text_ventas_reversada"),
          state2: "text_ventas_reversada",
          color: "#25283B",
          type: "primary",
        },
      ];
    },
    channelBuy() {
      return [
        {
          name: "WhatsApp",
          color: "#128C7E",
        },
        {
          name: "Instagram",
          color: "#C13584",
        },
        {
          name: "Facebook",
          color: "#3b5998",
        },
        {
          name: "Punto físico",
          color: "#f14b5a",
        },
        {
          name: "Checkout",
          color: "#4429b4",
        },
        {
          name: "Shopify",
          color: "#95bf47",
        },
        {
          name: "Checkout - Hoko",
          color: "#0059f7",
        },
        {
          name: "Hoko",
          color: "#0059f7",
        },
      ];
    },
    customShortcuts() {
      return [
        { key: "hoy", label: "Hoy", value: "day", isSelected: false },
        {
          key: "estaSemana",
          label: "Esta Semana",
          value: "week",
          isSelected: false,
        },
        {
          key: "semanaPasada",
          label: "Semana Pasada",
          value: "-week",
          isSelected: false,
        },
        {
          key: "esteMes",
          label: "Este Mes",
          value: "month",
          isSelected: false,
        },
        {
          key: "mesPasado",
          label: "Mes Pasado",
          value: "-month",
          isSelected: false,
        },
        { key: "esteAno", label: "Este Año", value: "year", isSelected: false },
        {
          key: "anoPasado",
          label: "Año Pasado",
          value: "-year",
          isSelected: false,
        },
      ];
    },
    methodPay() {
      return [
        { value: "5", label: "Efecty" },
        { value: "23", label: "PayU" },
        { value: "9", label: "Pago contraentrega" },
        { value: "7", label: "Pago a convenir" },
        { value: "24", label: "Mercado Pago" },
        { value: "22", label: "HOKO (Recaudo en efectivo)" },
        { value: "15", label: "Daviplata" },
        { value: "16", label: "Nequi" },
        { value: "25", label: "ePayco" },
        { value: "4", label: "Consignación" },
        { value: "20", label: "Wepay4U" },
        { value: "21", label: "TuCompra" },
        { value: "18", label: "Wompi" },
        { value: "19", label: "FLOW" },
        { value: "6", label: "Paga en Tienda" },
      ];
    },
  },
};
</script>
