<template>
  <el-dialog
    title="¿Deseas duplicar este producto?"
    :visible.sync="boolean"
    :modalAppendToBody="false"
  >
    <p>Proporciona un nombre para tu nuevo producto</p>
    <el-input v-model="nombre" autocomplete="off"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="boolean = false">Cancelar</el-button>
      <el-button type="primary" @click="duplicateProduct">Duplicar</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.nombre = `Copia de ${this.payload.detalle.nombre}`;
  },
  data() {
    return {
      nombre: "",
    };
  },
  computed: {
    boolean: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      },
    },
    variantsNormal() {
      return this.$store.state.variants;
    },
    selectedTab() {
      return this.$store.state.productData.detalle.con_variante;
    },
  },
  methods: {
    async duplicateProduct() {
      if (this.selectedTab === 1) {
        if (this.variantsNormal.length === 3) {
          if (
            this.variantsNormal[0].nombre === "" ||
            this.variantsNormal[1].nombre === "" ||
            this.variantsNormal[2].nombre === "" ||
            this.variantsNormal[0].valores.length === 0 ||
            this.variantsNormal[1].valores.length === 0 ||
            this.variantsNormal[2].valores.length === 0
          ) {
            this.$message.error("No se permiten campos vacios");
          } else {
            this.dataSave();
          }
        } else if (this.variantsNormal.length === 2) {
          if (
            this.variantsNormal[0].nombre === "" ||
            this.variantsNormal[1].nombre === "" ||
            this.variantsNormal[0].valores.length === 0 ||
            this.variantsNormal[1].valores.length === 0
          ) {
            this.$message.error("No se permiten campos vacios");
          } else {
            this.dataSave();
          }
        } else if (this.variantsNormal.length === 1) {
          if (
            this.variantsNormal[0].nombre === "" ||
            this.variantsNormal[0].valores.length === 0
          ) {
            this.$message.error("No se permiten campos vacios");
          } else {
            this.dataSave();
          }
        } else {
          this.$message.warning(
            'Si tu producto no tiene variantes, selecciona la opción "sin variantes".'
          );
        }
      } else if (this.selectedTab === 0) {
        this.dataSave();
      }
    },
    async dataSave() {
      const response = await this.$store.dispatch("CREATE_PRODUCT", {
        foto: "https://res.cloudinary.com/komercia-store/image/upload/q_auto,f_auto/v1590711378/0/products/product-image-placeholder.webp",
        id_foto: "",
        nombre: this.nombre || `Copia de ${this.payload.detalle.nombre}`,
        marca: this.payload.info[0].marca ? this.payload.info[0].marca : "",
        precio: this.selectedTab === 0 ? this.payload.detalle.precio || 0 : 0,
        disponibilidad: this.payload.detalle.disponibilidad,
        descripcion: this.payload.info[0].descripcion
          ? this.payload.info[0].descripcion
          : "",
        video: this.payload.info[0].video ? this.payload.info[0].video : "",
        categoria_producto: this.payload.detalle.categoria_producto.id || 0,
        subcategoria: this.payload.detalle.subcategoria_producto.id || 0,
        activo: this.payload.detalle.activo,
        sku: this.payload.info[0].sku ? this.payload.info[0].sku : "",
        peso: this.payload.info[0].peso,
        garantia: this.payload.detalle.garantia,
        inventario: this.payload.info.inventario,
        variantes:
          this.selectedTab === 1
            ? JSON.stringify(this.payload.variantes)
            : "[]",
        combinaciones:
          this.selectedTab === 1
            ? this.payload.combinaciones.combinaciones
            : "[]",
        envio_gratis: this.payload.detalle.envio_gratis,
        con_variante:
          this.selectedTab === 1 ? this.payload.detalle.con_variante : 0,
      });
      this.$store.dispatch("GET_PRODUCT", response.detalle.id);
      this.$router.push(`/inventario/productos/${response.detalle.id}`);
      this.boolean = false;
    },
  },
};
</script>

<style></style>
