<template>
  <el-dialog
    :title="$t('text_ventas_exportarMisVentas')"
    :visible.sync="dialogVisible"
    width="20%"
    :modalAppendToBody="false"
  >
    <div
      class="py-5 px-6 flex flex-col items-center shadow-lg border rounded-lg border-gray-200"
    >
      <img
        loading="lazy"
        src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649276085/2264789_x9nlrl.png"
        alt="icon export"
        width="140"
      />
      <h1 class="text-xl font-bold mt-5">
        {{ $t("text_ventas_descargar") }}
      </h1>
      <p class="text-center text-sm mt-2 text-gray-400">
        Descarga un listado de tus ordenes en Komercia. Puedes descargarlo en
        formato
      </p>

      <button class="btn_export" @click="exportOrden()" download>
        <button>Descargar archivo</button>
      </button>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "salesExport",
  props: {
    visible: Boolean,
    storeData: Object,
  },
  data() {
    return {};
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      },
    },
  },
  methods: {
    async exportOrden() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${this.$configKomercia.url}/api/admin/ventas/exportar/${this.storeData.id}`,
          headers: this.$store.state.configAxios.headers,
        });

        if (data) {
          window.open(
            `${this.$configKomercia.url}/${data.data.route}`,
            "_blank"
          );
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
  },
};
</script>

<style scoped>
.btn_export {
  @apply mt-5 border rounded-md border-indigo-700 text-indigo-700 px-5 py-2 cursor-pointer;
}
.btn_export:hover {
  @apply border-green-500 text-green-500;
}
</style>
