<template>
  <div
    v-if="progress < 100"
    class="content-tasks"
    :class="darkMode ? 'darkbg' : 'lightbg'"
  >
    <div class="text-footer-tasks">
      <div>
        <h1 :class="darkMode ? 'darktxt' : 'lighttxt'">
          Tiene tareas pendientes
        </h1>
        <p :class="darkMode ? 'darktxt' : 'lighttxt'">
          Te damos algunos tips para que tu tienda sea perfecta.
        </p>
        <p :class="darkMode ? 'darktxt' : 'lighttxt'">
          Completa al 100% toda la configuración, es muy fácil!
        </p>
        <br />
        <el-progress
          :text-inside="true"
          :stroke-width="16"
          :percentage="progress"
          color="#4429b4"
        ></el-progress>
      </div>
      <div class="text-footer-image">
        <img loading="lazy" src="../../assets/store-welcome.png" width="85%" />
      </div>
    </div>
    <div class="list-tasks">
      <template v-for="(task, index) in tasksDataFilter">
        <div
          v-if="index < 6"
          :key="`task${index}`"
          :class="{ task_item: true, done: task.done }"
        >
          <button
            v-if="task.tarea.link.includes('/diseno')"
            class="box-body pulsing"
            @click="checkTask(task.id_tarea, task.tarea.link)"
            @click.native="checkTask(task.id_tarea)"
          >
            <div class="pulsing">
              <img
                loading="lazy"
                :src="
                  require(`../../assets/color-icons/icon${task.id_tarea}.png`)
                "
                width="35px"
              />
              <p class="title-task">{{ task.tarea.title }}</p>
            </div>
            <div>
              <p class="text-description">{{ task.tarea.description }}</p>
            </div>
            <div>
              <p class="title-task-foot">Paso {{ task.id_tarea }}</p>
              <p class="title-task-foot" style="color: #000">
                menos de 5 minutos
              </p>
            </div>
          </button>
          <button
            v-else
            class="box-body"
            :class="{ pulse_button: index == 0 }"
            @click="checkTask(task.id_tarea, task.tarea.link)"
          >
            <div>
              <img
                loading="lazy"
                :src="
                  require(`../../assets/color-icons/icon${task.id_tarea}.png`)
                "
                width="35px"
                class="pulse-button"
              />
              <p class="title-task">{{ task.tarea.title }}</p>
            </div>
            <div>
              <p class="text-description">{{ task.tarea.description }}</p>
            </div>
            <div>
              <p class="title-task-foot text-pasos">Paso {{ task.id_tarea }}</p>
              <p class="title-task-foot text-pasos-time" style="color: #000">
                menos de 3 minutos
              </p>
            </div>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    tasksData() {
      return this.$store.state.tasksData;
    },
    progress() {
      let task = this.$store.state.tasksData;
      return Math.round(
        (100 * task.filter((task) => task.done === true).length) / task.length
      );
    },
    tasksDataFilter() {
      let value = this.tasksData.filter((task) => !task.done);
      return value;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  methods: {
    checkTask(tarea_id, router) {
      this.$router.push(router);
      let params = {
        tarea_id,
      };
      axios
        .post(
          `${this.$store.state.configKomercia.url}/api/admin/tienda/actualizar-tarea`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.$store.dispatch("GET_STORE"); //--> esta petición debería cambiarse por una exclusiva de las tareas
        })
        .catch((error) => {
          if (error) {
            console.log("Error!");
          }
        });
    },
  },
};
</script>

<style scoped>
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.darkbg {
  background-color: #293d51;
}
.lightbg {
  background-color: #fff;
}
.content-tasks {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
  padding: 20px 15px 20px;
}
.list-tasks {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.task_item {
  /* cursor: pointer; */
  /* margin-bottom: 10px; */
  border-radius: 5px;
  height: 100%;
  margin: 0 auto 0;
  background: #fff;
  transition: all ease 0.3s;
}
.task_item:hover {
  /* margin-bottom: 10px; */
  box-shadow: 0 0 0 2px #4429b4;
}
.task_item .box-body::before,
.task_item .box-body::after {
  content: "";
  display: none;
}
.box-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: #bfbfc0 1px solid;
  border-radius: 5px;
  height: 100%;
}

.box-body div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}
.task_item .title {
  color: var(--green);
  font-weight: bold;
}
.task_item.done .title {
  text-decoration: line-through;
}
i.el-icon {
  font-size: 28px;
}
i.el-icon.el-icon-time {
  color: #eee;
}
.title-task {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  word-break: break-word;
}
.title-task-foot {
  color: #000;
  font-weight: 500;
  color: var(--purple);
}
.text-pasos {
  font-size: 16px;
}
.text-pasos-time {
  font-size: 10px;
}
.text-footer-tasks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.text-footer-tasks h1 {
  font-size: 20px;
  font-weight: 600;

  margin-bottom: 10px;
}
.text-footer-tasks p {
  font-size: 12px;
  font-weight: 500;

  margin-bottom: 5px;
}
.text-footer-image {
  margin-left: 12px;
}
.text-description {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin: 7px 0;
}

.pulse_button {
  cursor: pointer;
  position: relative;
  /* box-shadow: 0 0 0 0 rgba(#4429b4); */
  animation: shadow-pulse 1s infinite;
  transition: 1s;
}
.pulse_button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}
@media (max-width: 767px) {
  .content-tasks {
    padding: 25px 10px;
  }
}
@media (max-width: 700px) {
  .box-body div:nth-child(3) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4px;
  }
}
@media (max-width: 660px) {
  .list-tasks {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 470px) {
  .list-tasks {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 370px) {
  .text-footer-image img {
    display: none;
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px #3f24ac70;
  }
  100% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
  }
}
</style>
