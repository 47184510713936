<template>
  <div class="content-newSteps">
    <div class="items-newSteps">
      <div class="items-left">
        <div class="items-top">
          <h1 class="txt-item-top-1">Let's Get Started 🎉</h1>
          <p class="txt-item-top-2 mt-4">
            The first to get up and running with Panel is to get data into your
            project:
          </p>
          <p class="txt-item-top-3">Komercia panel</p>
        </div>
        <div class="items-mid">
          <p class="txt-item-mid-1">Implementation Options</p>
          <p class="txt-item-mid-2">
            Use as yor project token as your implement
          </p>
          <p class="txt-item-mid-3">Pro tip: Use a proxy when you implement</p>
        </div>
        <div class="mt-6">
          <p class="txt-item-mids">SDK QUICK STATS GUIDES</p>
        </div>
        <div class="item-bottom-btns">
          <div v-for="(item, index) in buttons" :key="index">
            <button class="btns">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-step"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
              <div class="txt-item">
                <p class="txt-title">{{ item.txt }}</p>
              </div>
            </button>
          </div>
        </div>
        <p class="txt-item-mid-1 mt-10">Not Ready to Implement</p>
        <div class="item-bottom mt-5">
          <div v-for="(item, index) in NotImplement" :key="index">
            <button class="btns">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-step"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
              <div class="txt-item">
                <p class="txt-title">{{ item.txt }}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="items-right">
        <img
          loading="lazy"
          src="https://res.cloudinary.com/brahyanr10/image/upload/q_auto,f_auto/v1651553515/Panel-Komercia/Steps/step-1_ngz0q3.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewStepsIndex",
  data() {
    return {
      buttons: [
        {
          id: 1,
          txt: "JavaScript",
        },
        {
          id: 2,
          txt: "JavaScript",
        },
        {
          id: 3,
          txt: "JavaScript",
        },
        {
          id: 4,
          txt: "JavaScript",
        },
        {
          id: 5,
          txt: "JavaScript",
        },
        {
          id: 6,
          txt: "JavaScript",
        },
        {
          id: 7,
          txt: "JavaScript",
        },
        {
          id: 8,
          txt: "JavaScript",
        },
      ],
      NotImplement: [
        {
          id: 1,
          txt: "JavaScript",
        },
        {
          id: 2,
          txt: "JavaScript",
        },
        {
          id: 3,
          txt: "JavaScript",
        },
      ],
    };
  },
};
</script>
<style scoped>
.content-newSteps {
  @apply w-full flex flex-col justify-center items-center;
}
.items-newSteps {
  max-width: 1280px;
  @apply w-full flex flex-row justify-center items-center mt-10;
}
.items-left {
  width: 70%;
  @apply flex flex-col justify-center items-start mr-10;
}
.items-top {
  @apply w-full flex flex-col justify-center items-start;
}
.txt-item-top-1 {
  color: #333;
  font-size: 35px;
  @apply w-full flex flex-col justify-center items-start font-bold;
}
.txt-item-top-2 {
  color: #333;
  font-size: 13px;
  @apply w-full flex flex-col justify-center items-start;
}
.txt-item-top-3 {
  color: #333;
  font-size: 13px;
  @apply w-full flex flex-col justify-center items-start font-bold;
}
.items-mid {
  @apply w-full flex flex-col justify-center items-start mt-8;
}
.txt-item-mid-1 {
  color: #333;
  font-size: 20px;
  @apply w-full flex flex-col justify-center items-start font-bold;
}
.txt-item-mid-2 {
  color: #333;
  font-size: 14px;
  @apply w-full flex flex-col justify-center items-start mt-3;
}
.txt-item-mid-3 {
  color: #333;
  font-size: 14px;
  @apply w-full flex flex-col justify-center items-start mt-1;
}
.txt-item-mids {
  color: #333;
  font-size: 12px;
  @apply w-full flex flex-col justify-center items-start mt-5 mb-5 font-semibold;
}
.items-right {
  width: 30%;
  @apply flex flex-col justify-center items-center;
}
.item-bottom-btns {
  @apply w-full grid grid-cols-4 gap-4 justify-center items-center;
}
.btns {
  @apply w-full flex flex-row justify-center items-center border shadow-md py-4 rounded-xl px-3;
}
.btns:hover {
  border-color: blueviolet;
}
.icon-step {
  background-color: #e9e9e9;
  @apply w-auto h-10 px-2 py-1 rounded-lg;
}

.txt-item {
  @apply w-full flex flex-col justify-center items-start pl-3;
}
.txt-title {
  font-size: 16px;
  @apply w-full flex flex-col justify-center items-start font-bold;
}
.item-bottom {
  @apply w-full grid grid-cols-4 gap-4 justify-center items-center;
}
</style>
