<template>
  <div
    class="contents wrapper-content-items"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div class="wrapper-box">
      <div class="box-bodys" :class="darkMode ? 'dark' : 'light'">
        <div v-show="showForm" class="modal" @click="closeOrder">
          <div class="wrapper-items-form">
            <div class="content-form" :class="darkMode ? 'dark' : 'light'">
              <p
                class="mb-2"
                style="font-size: 18px; font-weight: 600"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                Agregar usuario administrador
              </p>
              <p
                class="mb-3"
                style="font-weight: 500; font-size: 14px; color: #818592"
              >
                Escribe el correo electrónico de la persona que quieres agregar
                para administrar tu tienda.
              </p>
              <div class="w-full mt-4">
                <p
                  class="text-info mb-2"
                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                >
                  {{ $t("text_perfil_correoElectronico") }}
                </p>
                <div class="w-full flex">
                  <el-input
                    v-model="emailInvitation"
                    class="inputs-idTienda"
                    :class="darkMode ? 'darks-input' : 'light-input'"
                    :placeholder="$t('text_perfil_correoElectronico')"
                    type="email"
                  />
                  <button
                    type="primary"
                    class="border rounded-lg bg-purple-komercia-600 text-white font-bold px-4 py-2 ml-2 hover:bg-green-komercia-600"
                    @click="sendInvitation"
                  >
                    Enviar
                  </button>
                </div>
              </div>
              <div class="flex justify-center mt-3">
                <p
                  class="mt-2 underline cursor-pointer"
                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                  @click="closeDialog()"
                >
                  {{ $t("text_cupones_cerrarVentana") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-row justify-between items-center">
          <p class="user-photo mt-6" :class="darkMode ? 'darktxt' : 'lighttxt'">
            Listado de administradores
          </p>
          <div class="flex flex-row justify-end items-center">
            <button
              type="primary"
              class="border rounded-lg bg-purple-komercia-600 text-white font-bold px-4 py-2 ml-2 hover:bg-green-komercia-600"
              @click="showForm = !showForm"
            >
              Agregar administrador
            </button>
            <a
              title="Abrir ayuda Komercia"
              class="ml-4 flex items-center border border-purple-komercia-100 rounded-lg py-2 px-3 text-purple-komercia-100 hover:border-purple-600 hover:text-purple-600"
              href="https://ayuda.komercia.co/komercia/categories/582/Mi%20perfil%20/26"
              target="_blank"
            >
              {{ $t("text_helpDesk_Aprende") }}
              <img
                loading="lazy"
                class="w-5 ml-2"
                src="@/assets/information.webp"
                alt="imgInformation"
              />
            </a>
          </div>
        </div>
        <div
          v-if="copyDataAdmin"
          :class="darkMode ? 'darks-tablet' : 'light-tablet'"
        >
          <el-table
            stripe
            lazy
            border
            :data="copyDataAdmin"
            style="width: 100%"
            :fit="true"
            highlight-current-row
          >
            <el-table-column label="#ID" width="110" align="center">
              <template slot-scope="scope">
                <div class="flex justify-center">
                  <p>{{ scope.row.user.id }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Nombre" align="center">
              <template slot-scope="scope">
                <div class="flex justify-center">
                  <p>{{ scope.row.user.nombre }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Correo" align="center">
              <template slot-scope="scope">
                <div class="flex justify-center">
                  <p>{{ scope.row.user.email }}</p>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="Rol" width="200" align="center">
                <template slot-scope="scope">
                  <div class="flex justify-center">
                    <p>{{ scope.row.user.rol }}</p>
                  </div>
                </template>
              </el-table-column> -->
            <el-table-column width="180" align="center">
              <template slot-scope="scope">
                <div class="flex justify-center">
                  <el-button
                    type="danger"
                    plain
                    @click="
                      openDialog(scope.row.user.id, scope.row.user.nombre)
                    "
                  >
                    <i class="el-icon-delete"></i> Desvincular
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminUser",
  data() {
    return {
      showForm: false,
      // dataAdmin: [],
      copyDataAdmin: [],
      emailInvitation: "",
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    userData() {
      return this.$store.state.userData;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  created() {
    this.getUserAdmin();
  },
  methods: {
    closeDialog() {
      this.showForm = false;
    },
    closeOrder(event) {
      const element = event.target.className;
      if (element === "wrapper-items-form" || element === "modal") {
        this.showForm = false;
      }
    },
    async getUserAdmin() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${this.$configKomercia.url}/api/admin/administrators`,
          headers: this.$store.state.configAxios.headers,
        });
        if (data) {
          let dataAdmin = data.data;
          if (dataAdmin) {
            this.copyDataAdmin = dataAdmin.filter((item) => {
              if (!item.users_id) {
                return item;
              }
            });
          }
        }
      } catch (err) {
        if (err.response.status === 400) {
          console.log(err.response);
        }
      }
    },
    async sendInvitation() {
      if (this.emailInvitation != "") {
        try {
          const { data } = await axios({
            method: "POST",
            url: `${this.$configKomercia.url}/api/admin/administrators/invite`,
            data: {
              email: this.emailInvitation,
            },
            headers: this.$store.state.configAxios.headers,
          });
          if (data) {
            if (data.estado == 200) {
              this.$message.success(data.mensaje);
              this.closeDialog();
            }
          }
        } catch (err) {
          if (err.response.status === 400) {
            this.$message.error(
              err.response.data.errores["administradores.already_assigned"][0]
            );
          }
        }
      } else {
        this.$message.error("Ingresar correo electrónico!");
      }
    },
    openDialog(id, name) {
      this.$confirm(`Desea eliminar el administrador ${name}?`, "Warning", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }).then(() => {
        this.deleteAdmin(id);
      });
    },
    async deleteAdmin(value) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${this.$configKomercia.url}/api/admin/administrators/delete`,
          headers: this.$store.state.configAxios.headers,
          data: {
            user: value,
          },
        });
        if (data) {
          this.$message.success("Usuario eliminado correctamente!");
          this.getUserAdmin();
        }
      } catch (err) {
        if (err.response.status === 400) {
          console.log(err.response);
        }
      }
    },
  },
};
</script>

<style scoped>
.wrapper-content-items {
  width: 100%;
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.wrapper-box {
  position: relative;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
}
.modal {
  padding-top: 200px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @apply w-full h-full fixed z-10 left-0 top-0 overflow-auto;
}
.wrapper-items-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-form {
  width: 100%;
  max-width: 600px;
  padding: 40px 40px 15px 40px;
  border-radius: 6px;
  /* background-color: #fff; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
el-input {
  background-color: transparent;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 20px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.user-photo {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}
.dark {
  background-color: #1b2836;
}
.light {
  @apply bg-white;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table th.el-table__cell {
  background-color: #344a63;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
</style>
