<template>
  <div class="table-container">
    <table class="table table-striped">
      <div class="thead">
        <ul>
          <li></li>
          <li>Producto</li>
          <li>Variante</li>
          <li>Cantidad</li>
          <li>Unidad</li>
          <li>SKU</li>
          <li>Envío</li>
          <li></li>
        </ul>
      </div>
      <div class="tbody">
        <ul v-for="item in products" :key="item.id">
          <!-- <li>
            <router-link :to="`/inventario/productos/${item.producto.id}`"
              >#{{ item.producto.id }}</router-link
            >
          </li> -->
          <li class="photo">
            <router-link :to="`/inventario/productos/${item.producto.id}`">
              <img
                loading="lazy"
                :src="
                  idCloudinaryAwards(item.producto.foto_cloudinary, 150, 150)
                "
              />
            </router-link>
          </li>
          <li>
            <router-link :to="`/inventario/productos/${item.producto.id}`">
              <p class="text-table">{{ item.producto.nombre }}</p></router-link
            >
          </li>
          <li class="variants">
            <p
              v-for="(option, index) in formatJsonVariant(item.variantes)"
              :key="`variante_${index}`"
              class="mb-1"
            >
              <el-tag size="small">
                {{ option }}
              </el-tag>
            </p>
          </li>
          <li>
            <p class="text-normal-table">{{ item.unidades }}</p>
          </li>
          <li v-if="isQuotation">
            <money
              v-model="item.precio_producto"
              v-bind="money"
              class="el-input__inner"
              @change.native="handle_total"
            ></money>
          </li>
          <li v-else>
            <p class="text-normal-table">
              {{ item.precio_producto | formatCurrency(idCountryStore) }}
            </p>
          </li>
          <!-- <li>{{ item.precio_producto*item.unidades | currency }}</li> -->
          <li>
            <p class="text-normal-table">
              {{ item.producto.informacion_producto_simple[0].sku }}
            </p>
          </li>
          <li>
            <el-tag v-if="item.producto.envio_gratis" size="small">
              Gratis
            </el-tag>
          </li>
          <li>
            <p class="text-bold-table">
              {{ item.getTotal() | formatCurrency(idCountryStore) }}
            </p>
          </li>
        </ul>
      </div>
    </table>
    <div class="table-products-responsive">
      <div v-for="item in products" :key="item.id" class="content-table-body">
        <div class="left-content">
          <div class="photo">
            <router-link :to="`/inventario/productos/${item.producto.id}`">
              <img
                loading="lazy"
                :src="
                  idCloudinaryAwards(item.producto.foto_cloudinary, 150, 150)
                "
              />
            </router-link>
          </div>
          <div class="info-product">
            <div class="content-units">
              <router-link :to="`/inventario/productos/${item.producto.id}`">
                {{ item.producto.nombre }}
              </router-link>
              <p
                v-if="item.unidades > 1 || item.unidades == 0"
                class="price-units"
              >
                {{ item.unidades }} unidades
                <span class="equis"> x </span>
                <span class="price">
                  {{ item.precio_producto | formatCurrency(idCountryStore) }}
                </span>
              </p>
              <p v-else class="price-units">
                {{ item.unidades }} unidad
                <span class="equis"> x </span>
                <span class="price">
                  {{ item.precio_producto | formatCurrency(idCountryStore) }}
                </span>
              </p>
            </div>
            <div class="variants">
              <div v-if="item.variantes" class="square-variant">
                <p
                  v-for="(option, index) in formatJsonVariant(item.variantes)"
                  :key="`variante_${index}`"
                >
                  <el-tag size="small">
                    {{ option }}
                  </el-tag>
                </p>
              </div>
            </div>
            <div>
              <p
                v-if="item.producto.informacion_producto_simple[0].sku"
                class="text-normal-table"
              >
                Sku: {{ item.producto.informacion_producto_simple[0].sku }}
              </p>
            </div>
            <div>
              <el-tag v-if="item.producto.envio_gratis" size="small">
                Gratis
              </el-tag>
            </div>
          </div>
        </div>
        <div class="total-price-content">
          <p class="total-price">
            {{ item.getTotal() | formatCurrency(idCountryStore) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { CldImage, CldTransformation } from 'cloudinary-vue'
import idCloudinary from "../../mixins/idCloudinary";
import formatCurrency from "../../mixins/formatCurrent";

export default {
  // components: { CldImage, CldTransformation },
  mixins: [idCloudinary, formatCurrency],
  props: ["data", "isQuotation"],
  computed: {
    products() {
      return this.data.productos.map((product) => {
        product.getTotal = function () {
          this.total = this.precio_producto * this.unidades;
          return this.total;
        };
        return product;
      });
    },
  },
  watch: {
    data() {
      this.setFormatMoney(this.idCountryStore);
    },
  },
  created() {
    this.setFormatMoney(this.idCountryStore);
  },
  methods: {
    handle_total() {
      this.$emit("onQuotation");
    },
    formatJsonVariant(variants) {
      return JSON.parse(variants);
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 0;
  border-collapse: collapse;
}
.thead ul li {
  color: gray;
}
.text-table {
  color: var(--purple);
  font-size: 12px;
}
.text-normal-table {
  color: black;
  font-size: 12px;
}
.text-variant {
  color: black;
  font-size: 12px;
}
.text-bold-table {
  color: black;
  font-size: 14px;
  font-weight: bold;
  text-align: end;
  padding-right: 15px;
}
.invoice-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 20px;
}
.table-striped {
  width: 100%;
  display: grid;
  max-height: 600px;
  overflow-y: auto;
}
.table-striped .thead {
  width: 100%;
}
.table-striped .thead ul {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(8, 1fr);
  padding: 10px 0;
  list-style: none;
}
.table-striped .tbody ul {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(8, 1fr);
  padding: 10px 0;
  list-style: none;
}
.table-striped .tbody ul:nth-of-type(odd) {
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.photo img {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  object-fit: cover;
}
td {
  vertical-align: middle !important;
}
td.variants {
  display: flex;
}
td.variants p ~ p {
  margin-left: 10px;
}
.el-input__inner {
  width: 90%;
  height: 30px;
}
.table-products-responsive {
  display: none;
}
@media (max-width: 1040px) {
  .table-striped {
    display: none;
  }
  .table-products-responsive {
    display: initial;
  }
  .content-table-body {
    display: flex;
    padding-bottom: 7px;
    border-bottom: 1px solid #0000001a;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .left-content {
    display: flex;
  }
  .info-product {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content-units a {
    color: black;
  }
  .price-units {
    margin-top: 5px;
  }
  .info-product p {
    color: var(--purple);
    font-weight: 600;
    margin-bottom: 5px;
  }
  .price {
    color: #77869e;
  }
  .equis {
    color: black;
    font-weight: 600;
  }
  .total-price {
    color: black;
  }
  .variants {
    display: flex;
  }
  .square-variant {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    justify-content: center;
    padding: 5px;
    border-radius: 4px;
  }
  .variants p {
    color: #ffffff;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
  }
  .total-price-content {
    padding-left: 20px;
  }
}
</style>
