var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pos"},[_c('ModalNewUser',{attrs:{"show":_vm.show},on:{"update:show":function($event){_vm.show=$event},"onCreate":_vm.setCurrentCustomer}}),_c('ModalPaymentMethods',{attrs:{"visible":_vm.visiblePaymentMethods},on:{"update:visible":function($event){_vm.visiblePaymentMethods=$event}}}),_c('div',{staticClass:"pos__content",class:_vm.darkMode ? 'dark' : 'light'},[_c('div',{staticClass:"pos__content__header"},[_c('p',{staticClass:"flex items-center text-title mb-3",class:_vm.darkMode ? 'darktxt' : 'lighttxt'},[_vm._v(" Venta rápida "),_c('sliderInformation',{staticClass:"ml-2",attrs:{"numberId":77,"actions":true}})],1),_c('p',{staticClass:"text-infos mb-5",class:_vm.darkMode ? 'darktxt' : 'lighttxt'},[_vm._v(" Aquí puedes tomar pedidos y crear ventas, asociar productos y compartir la venta con tu cliente, realizar pagos o llevar un registro de tu punto de venta. ")]),_c('p',{staticClass:"text-info mb-1"},[_vm._v("Buscar producto")]),_c('el-input',{staticClass:"inputs-idTienda",class:_vm.darkMode ? 'darks-input' : 'light-input',attrs:{"placeholder":"Buscar producto"},on:{"input":function($event){return _vm.buscar(_vm.searchedProduct)}},model:{value:(_vm.searchedProduct),callback:function ($$v) {_vm.searchedProduct=$$v},expression:"searchedProduct"}},[_c('i',{staticClass:"el-input__icon fas el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c('carousel',{staticClass:"carousel",attrs:{"navigationEnabled":true,"paginationEnabled":false,"perPage":4}},[_c('slide',{staticClass:"carousel__slide"},[_c('div',{class:{
            header__categories_item: true,
            selected: !_vm.selectedCategory,
          },on:{"click":function($event){return _vm.selectCategory({ id: 0 })}}},[_c('p',[_vm._v("Todos")])])]),_vm._l((_vm.categoriesData),function(category){return _c('slide',{directives:[{name:"show",rawName:"v-show",value:(category.producto_count > 0),expression:"category.producto_count > 0"}],key:`category_${category.id}`,staticClass:"carousel__slide"},[_c('div',{class:{
            header__categories_item: true,
            selected: category.id === _vm.selectedCategory,
          },on:{"click":function($event){return _vm.selectCategory(category)}}},[_c('p',[_vm._v(_vm._s(category.nombre_categoria_producto.toLowerCase()))])])])})],2),_c('draggable',{staticClass:"pos__content__products",attrs:{"group":{ name: 'people', pull: 'clone', put: false },"animation":100,"sort":false},model:{value:(_vm.productsData),callback:function ($$v) {_vm.productsData=$$v},expression:"productsData"}},[(_vm.productsData)?_vm._l((_vm.productsData),function(product){return _c('ProductCard',{key:`product_${product.id}`,attrs:{"data":product,"inactive":false}})}):_vm._e()],2),_c('div',{staticClass:"wrapper-pagination"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","hide-on-single-page":true,"page-count":_vm.infoProductsData.last_page,"current-page":_vm.currentPage},on:{"current-change":_vm.currentChange}})],1)],1),_c('POS-order',{on:{"openModal":function($event){_vm.show = true},"onEnd":function($event){_vm.visiblePaymentMethods = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }