<template>
  <div class="wrapper">
    <div v-if="!loading">
      <p
        class="wrapper-nota-mipaquete-text"
        v-if="storeData && storeData.tipo_inventario == 1"
      >
        <strong>Nota:</strong> Las integraciones como mipaquete.com solo están
        disponibles para productos propios, no de dropshipping
      </p>
      <h1>{{ $route.params.step }}</h1>
      <div :is="currentStage.name" v-loading="loading"></div>
    </div>
    <div v-else class="py-11 px-16">
      <el-skeleton animated>
        <template slot="template">
          <div class="w-full flex justify-between items-center">
            <el-skeleton-item
              variant="image"
              style="width: 160px; height: 80px"
            />
            <el-skeleton-item variant="button" class="w-1/6" />
          </div>
          <div class="mt-10">
            <el-skeleton-item variant="h1" />
          </div>
          <div class="mt-5 px-1">
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
            <el-skeleton-item variant="text" class="mb-2" />
          </div>
        </template>
      </el-skeleton>
    </div>
  </div>
</template>

<script>
import Auth from "./auth";
import All from "./all";
import Quotation from "./quotation";
import QuotationResult from "./quotation_result";
import CreateService from "./create_service";
import Package from "./package_mipaquete.vue";
import sleep from "@/utils/sleep.js";

export default {
  name: "miPaqueteIndex",
  components: { Auth, All, Quotation, QuotationResult, CreateService, Package },
  async created() {
    localStorage.removeItem("Komercia_mipaquete_token");
    const { success } = await this.$store.dispatch(
      "mipaquete/GET_DATA_KOMERCIA"
    );
    if (success) {
      if (this.$route.query.order_id) {
        this.$store.commit("mipaquete/SET_INDEX_STAGE", 2);
      } else {
        this.$store.commit("mipaquete/SET_INDEX_STAGE", 1);
      }
    }
  },
  async mounted() {
    await sleep(1000);
    this.loading = false;
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    currentStage() {
      return this.$store.getters["mipaquete/currentStage"];
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  destroyed() {
    this.$store.commit("mipaquete/SET_QUOTATION_STATE", null);
  },
};
</script>

<style>
.wrapper-nota-mipaquete-text {
  text-align: end;
  /* padding: 0px 30px; */
  max-width: 1150px;
  min-width: 400px;
  width: 100%;
  font-size: 14px !important;
  color: #fdd103;
}
.wrapper {
  max-width: 1280px;
  min-width: 400px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  background: white;
}
.label {
  display: flex;
  align-items: center;
  background-color: var(--yellow);
  /* color: white; */
  border-radius: 6px;
  padding: 4px 20px;
  margin-bottom: 10px;
}
.label h3 {
  font-weight: bold;
  margin-right: 10px;
}
.label p {
  font-size: 13px;
}
</style>
