var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-photo mb-4",class:_vm.darkMode ? 'darkbg' : 'lightbg'},[_c('h2',{staticClass:"text-xl font-bold mb-3",class:_vm.darkMode ? 'darktxt' : 'lighttxt'},[_vm._v(" Imágenes del producto ")]),_c('div',{staticClass:"grid photos"},[_c('label',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingSavePhotoMain),expression:"loadingSavePhotoMain"}],staticClass:"upload-main cursor-pointer",on:{"click":function($event){return _vm.widgetCloudinary('major')}}},[_vm._m(0),(_vm.data.detalle.foto_cloudinary)?_c('img',{attrs:{"loading":"lazy","src":_vm.data.detalle.foto_cloudinary == _vm.defaultPhoto
            ? 'v1550852556/placeholder/product-image-placeholder.jpg'
            : _vm.idCloudinaryAwards(_vm.data.detalle.foto_cloudinary, 850, 850)}}):_c('div',{staticClass:"photo_empty"},[(_vm.isLoading)?_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"wrapper"},[_c('i',{staticClass:"el-icon-loading"}),_c('h2',{staticStyle:{"margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("text_inventario_cargando"))+" ")])])]):_vm._e(),_c('img',{attrs:{"loading":"lazy","src":require("../../assets/upload.png")}}),_c('br'),_c('p',[_vm._v(_vm._s(_vm.$t("text_inventario_fotoProducto")))])])]),_c('div',{staticClass:"photo_list"},[_vm._l((_vm.data.fotos),function(photo,index){return _c('div',{key:index,staticClass:"photo upload"},[_c('div',{staticClass:"photo_delete"},[_c('button',{staticClass:"bg-red-400 shadow-md px-2 py-1 rounded-lg text-white hover:bg-red-500",on:{"click":function($event){return _vm.deletePhoto(photo, index)}}},[_vm._v(" Eliminar "),_c('i',{staticClass:"el-icon-delete"})])]),_c('img',{attrs:{"loading":"lazy","src":_vm.idCloudinaryAwards(photo.foto_cloudinary, 850, 850)}})])}),(
          (_vm.storeData.id == 1559 || _vm.storeData.id == 2785
            ? _vm.data.fotos.length <= 15
            : _vm.data.fotos.length <= 4) && _vm.data.detalle.foto_cloudinary
        )?_c('label',{staticClass:"upload",on:{"click":function($event){return _vm.widgetCloudinary('minor')}}},[_c('div',{staticClass:"upload_empty"},[(_vm.isLoading)?_c('div',{staticClass:"overlay"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"up-image"},[_c('i',{staticClass:"el-icon-upload",staticStyle:{"font-size":"30px !important","color":"var(--purple)"}}),_c('p',[_vm._v(_vm._s(_vm.$t("text_inventario_subirOtraImagen")))])])])]):_vm._e()],2)]),_c('div',{staticClass:"input-area-full youtube"},[_c('div',{staticClass:"youtube__input"},[_c('p',{staticClass:"text-subtitle mb-2",class:_vm.darkMode ? 'darktxt' : 'lighttxt'},[_vm._v(" "+_vm._s(_vm.$t("text_inventario_videoProducto"))+" ")]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('div',{staticClass:"content-icon-youtube",class:_vm.darkMode ? 'darktxt' : 'lighttxt'},[_c('i',{staticClass:"ti-youtube",staticStyle:{"font-size":"20px"}})]),_c('el-input',{staticClass:"inputs-idTienda",class:_vm.darkMode ? 'darks-input' : 'light-input',attrs:{"placeholder":_vm.$t('text_inventario_URLvideoYoutube')},model:{value:(_vm.urlYoutube),callback:function ($$v) {_vm.urlYoutube=$$v},expression:"urlYoutube"}},[_c('i',{staticClass:"el-icon-question el-input__icon",attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.handleHelp('Video del producto', 'Video del producto')}},slot:"suffix"})])],1)]),(_vm.idYoutube)?_c('a',{staticClass:"youtube__thumbnail",attrs:{"href":_vm.urlYoutube,"title":"Abrir video de YouTube","target":"_blank"}},[_c('img',{attrs:{"loading":"lazy","src":`https://img.youtube.com/vi/${_vm.idYoutube}/0.jpg`,"title":"Imagen del video"}})]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover"},[_c('img',{attrs:{"loading":"lazy","src":require("../../assets/upload.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('i',{staticClass:"el-icon-loading",staticStyle:{"font-size":"30px !important"}})])
}]

export { render, staticRenderFns }