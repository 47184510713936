<template>
  <div
    class="w-full lg:max-w-screen-lg xl:max-w-screen-xl relative py-3 pb-20 px-4 sm:px-6 lg:py-5 lg:pb-3 lg:px-3"
  >
    <nav class="flex wrapper-invoice-nav" aria-label="Breadcrumb">
      <ol class="flex items-center space-x-4">
        <li>
          <div>
            <router-link to="/" class="text-gray-400 hover:text-gray-500">
              <svg
                class="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              <span class="sr-only" :class="darkMode ? 'darktxt' : 'lighttxt'">
                Home
              </span>
            </router-link>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <router-link
              to="/tienda/blog#3"
              class="ml-4 text-sm font-medium"
              :class="darkMode ? 'darktxt' : 'hover:text-gray-700'"
            >
              {{ $t("text_blog_listadoArticulos") }}
            </router-link>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <p
              class="ml-4 text-sm font-medium"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_blog_crearArticulo") }}
            </p>
          </div>
        </li>
      </ol>
    </nav>
    <div class="pb-1 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <div class="flex items-center">
                <div class="sm:col-span-6">
                  <p
                    for="cover_photo"
                    class="text-2xl leading-6 font-medium mr-2"
                    :class="darkMode ? 'darktxt' : 'text-gray-900'"
                  >
                    {{ $t("text_blog_portada") }}
                  </p>
                </div>
              </div>
              <div class="card">
                <div v-loading="loadingSavePhotoMain" class="upload-main">
                  <div
                    v-if="articles && !articles.imagen_principal_url"
                    class="hover"
                    @click="widgetCloudinary()"
                  >
                    <div class="wrapper-icon">
                      <i class="fas fa-sync"></i>
                      <h3 class="title-change">
                        {{ $t("text_blog_subirImagen") }}
                      </h3>
                    </div>
                  </div>
                  <img
                    loading="lazy"
                    :src="
                      articles && articles.imagen_principal_url
                        ? idCloudinaryAwards(
                            articles.imagen_principal_url,
                            400,
                            400
                          )
                        : article
                    "
                  />
                </div>
                <div class="pt-5 flex flex-row justify-between items-center">
                  <div>
                    <p>{{ $t("text_blog_tamañoSugerido") }}</p>
                  </div>
                  <div class="flex justify-end">
                    <button
                      v-if="articles && articles.imagen_principal_url"
                      type="button"
                      plain
                      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-komercia-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      @click="deletePhotoArticle()"
                    >
                      {{ $t("text_inventario_elimnar") }}
                    </button>
                    <button
                      v-else
                      type="button"
                      plain
                      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-komercia-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      @click="widgetCloudinary()"
                    >
                      {{ $t("text_blog_subirImagen") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:col-span-3">
              <div class="sm:col-span-3 mb-5 flex justify-end">
                <el-switch
                  v-model="articles.estado"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-text="$t('text_blog_visible')"
                  :inactive-text="$t('text_blog_oculto')"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </div>
              <label
                for="username"
                class="block text-base font-medium"
                :class="darkMode ? 'darktxt' : 'text-gray-700'"
              >
                {{ $t("text_blog_titulo") }}
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <el-input
                  id="title-blog"
                  v-model="articles.titulo"
                  name="title-blog"
                  type="text"
                  autocomplete="off"
                  autocorrect="off"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  required
                />
                <span v-if="articleVerify.tituloVerify" class="alert-form">
                  {{ $t("text_blog_faltaTitulo") }}
                </span>
              </div>
              <label
                for="username"
                class="block text-base font-medium"
                :class="darkMode ? 'darktxt' : 'text-gray-700'"
              >
                {{ $t("text_blog_autor") }}
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <el-input
                  id="author-blog"
                  v-model="articles.autor"
                  name="author-blog"
                  type="text"
                  autocomplete="off"
                  autocorrect="off"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  required
                />
                <span v-if="articleVerify.autorVerify" class="alert-form">
                  {{ $t("text_blog_faltaAutor") }}
                </span>
              </div>
              <label
                for="about"
                class="block text-base font-medium"
                :class="darkMode ? 'darktxt' : 'text-gray-700'"
              >
                {{ $t("text_blog_resumen") }}
              </label>
              <div class="mt-1">
                <el-input
                  id="resumen-blog"
                  v-model="articles.resumen"
                  name="resumen-blog"
                  type="textarea"
                  :maxlength="175"
                  autocomplete="off"
                  autocorrect="off"
                  :rows="4"
                  class="input-description"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  style="resize: none"
                  required
                ></el-input>
                <span v-if="articleVerify.resumenVerify" class="alert-form">
                  {{ $t("text_blog_faltaResumen") }}
                </span>
              </div>
            </div>
            <div class="sm:col-span-6">
              <label
                for="about"
                lass="block text-base font-medium"
                :class="darkMode ? 'darktxt' : 'text-gray-700'"
              >
                {{ $t("text_blog_contenido") }}
              </label>
              <div class="mt-1">
                <el-tiptap
                  v-model="content"
                  :extensions="extensions"
                  :spellcheck="true"
                  :readonly="false"
                  :charCounterCount="false"
                  :tooltip="true"
                  :showMenubar="true"
                  :bubble="true"
                  output="html"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <router-link
            to="/tienda/blog#3"
            type="button"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ $t("text_inventario_cancelar") }}
          </router-link>
          <button
            type="submit"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-komercia-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="newBlog()"
          >
            {{ $t("text_inventario_guardar") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { CldImage, CldTransformation } from 'cloudinary-vue'
import cloudinaryWidgetOptions from "@/mixins/cloudinaryWidgetOptions";
import getIdCloudinary from "@/mixins/getIdCloudinary";
import idCloudinary from "@/mixins/idCloudinary";
import extensions from "@/mixins/elemenTiptap.vue";

export default {
  name: "blogArticlesNew",
  mixins: [cloudinaryWidgetOptions, getIdCloudinary, idCloudinary, extensions],
  // components: {
  //   CldImage,
  //   CldTransformation,
  // },
  data() {
    return {
      article:
        "https://res.cloudinary.com/komercia-components/image/upload/c_scale,q_auto:best,f_auto/v1598040886/sws8xa8z0oyu252nqxzv.webp",

      articles: {
        titulo: "",
        autor: "",
        resumen: "",
        category: "",
        contenido: "",
        imagen_principal_id: "",
        imagen_principal_url: "",
      },
      loadingSavePhotoMain: false,
      myUploadWidget: null,
      isLoading: false,
      articleVerify: {
        tituloVerify: false,
        autorVerify: false,
        resumenVerify: false,
        categoryVerify: false,
        contenidoVerify: false,
        imagen_principal_idVerify: false,
        imagen_principal_urlVerify: false,
      },
      content: "<p>Comience a escribir...</p>",
    };
  },
  computed: {
    storeData() {
      return this.$store.state.storeData;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  methods: {
    async newBlog() {
      this.checkForm();
      if (
        !this.articleVerify.tituloVerify &&
        !this.articleVerify.autorVerify &&
        !this.articleVerify.resumenVerify
      ) {
        this.articles.contenido = this.content;
        let responde = await this.$store.dispatch(
          "CREATE_ARTICLE_BLOG",
          this.articles
        );
        if (responde) {
          this.$message({
            message: "El artículo se creo correctamente!",
            type: "success",
          });
          this.$router.push({ path: "/tienda/blog" });
        } else {
          this.$message({
            message: "Error al crear el artículo",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: `Faltan campos por llenar`,
          type: "error",
        });
      }
    },
    widgetCloudinary() {
      this.isLoading = true;
      this.myUploadWidget = cloudinary.openUploadWidget(
        this.cloudinaryWidgetOptions(
          "banners",
          "komercia-store",
          "bannersTiendas"
        ),
        (error, result) => {
          this.isLoading = false;
          this.afterUpload(error, result);
        }
      );
      this.myUploadWidget.open();
      setTimeout(() => {
        this.loadingSavePhotoMain = false;
      }, 7000);
    },
    async afterUpload(error, result) {
      if (result.event === "success") {
        if (this.articles.imagen_principal_url) {
          const { success } = await this.deleteImageCloudinary(
            true,
            this.articles.imagen_principal_url,
            "banners"
          );
          if (success) {
            this.articles.imagen_principal_url = result.info.secure_url;
            this.articles.imagen_principal_id = result.info.id;
          }
        } else {
          this.articles.imagen_principal_url = result.info.secure_url;
          this.articles.imagen_principal_id = result.info.id;
        }
        this.myUploadWidget.close();
      } else {
        console.log(error);
      }
    },
    deletePhotoArticle() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "¿Deseas eliminar esta imagen?",
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((r) => {
        if (r.isConfirmed) {
          this.deleteImageCloudinary(
            false,
            this.articles.imagen_principal_url,
            "banners"
          );
        }
      });
    },
    async deleteImageCloudinary(returnData, image, type) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${this.$store.state.configKomercia.url}/api/admin/cloudinary/delete`,
          headers: this.$store.state.configAxios.headers,
          data: {
            image: this.getIdCloudinary(image),
            tipo: type,
            template: this.storeData.template,
          },
        });
        if (data && data.estado === 200) {
          if (!returnData) {
            this.$swal("Imagen borrada correctamente.", "", "success");
            this.articles.imagen_principal_url = "";
            this.articles.imagen_principal_id = "";
          }
          return { success: true, data: data };
        }
      } catch (err) {
        console.log("Error delete cloudinary", err.response.data);
      }
    },
    checkForm() {
      if (this.articles.titulo == "") {
        this.articleVerify.tituloVerify = true;
      } else {
        this.articleVerify.tituloVerify = false;
      }
      if (this.articles.autor == "") {
        this.articleVerify.autorVerify = true;
      } else {
        this.articleVerify.autorVerify = false;
      }
      if (this.articles.resumen == "") {
        this.articleVerify.resumenVerify = true;
      } else {
        this.articleVerify.resumenVerify = false;
      }
    },
  },
};
</script>
<style scoped src="../../../assets/css/input.css"></style>
<style scoped>
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.darktxts {
  color: #5e39f1;
}
.lighttxts {
  color: #4429b4;
}
.card {
  position: relative;
  background-color: #fff;
  margin-top: 10px;
  padding: 15px 10px;
  box-shadow: 0 0 6px 2px hsl(228, 40%, 20%, 0.025);
  border-radius: 5px;
}
.upload-main {
  position: relative;
  max-height: 320px;
  height: 100%;
  width: 100%;
  @apply shadow;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
}
.hover {
  position: relative;
  color: var(--purple);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: height ease 0.5s;
  opacity: 0;
  display: flex;
  border-radius: 8px;
  cursor: pointer;
}
.wrapper-icon {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}
.wrapper-icon > i {
  color: var(--purple);
  font-size: 2rem;
}
.wrapper-icon > .title-change {
  color: rgba(0, 2, 7, 0.733);
  font-size: 1.8rem;
  font-weight: bold;
}
.upload-main:hover .hover {
  position: absolute;
  opacity: 1;
  background-color: rgba(197, 203, 223, 0.884);
  transition: all ease-in 0.3s;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.upload-main >>> .cld-image {
  height: 100%;
  width: 100%;
}
.upload-main > div > img {
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.upload-main img {
  max-width: 100%;
  max-height: 100%;
}
.upload-main .photo_empty {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 5px;
  position: relative;
}
.upload-main .photo_empty p {
  font-weight: 400;
  color: #565656;
  font-size: 12px;
}
.wrapper {
  min-height: 30px;
  height: auto;
}
.upload-main >>> img {
  vertical-align: top;
  width: 100%;
  height: 260px !important;
  object-fit: cover;
  border-radius: 8px;
}
.alert-form {
  padding: 0;
  color: red;
  font-size: 12px;
  margin-left: 5px;
}
.wrapper-editor >>> .el-tiptap-editor__content h1 {
  font-size: 2em;
}
.wrapper-editor >>> .el-tiptap-editor__content h2 {
  font-size: 1.5em;
}
.wrapper-editor >>> .el-tiptap-editor__content h3 {
  font-size: 1.17em;
}
.wrapper-editor >>> .el-tiptap-editor__content h4 {
  font-size: 1.12em;
}
.wrapper-editor >>> .el-tiptap-editor__content h5 {
  font-size: 0.83em;
}
</style>
