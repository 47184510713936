import axios from "axios";

export default {
  namespaced: true,
  state: {
    //store
    dataHelp: {},
    socialsHelp: {},
    domainHelp: {},
    //aticles
    articlesHelp: {},
    categoriesHelp: {},
    subcategoriesHelp: {},
    authorsHelp: {},
    rolHelp: {},
    searchData: {},
    //editor
    settingHelpDesk: {},
  },
  mutations: {
    // SET_ALL_COURSER(state, value) {
    //   state.courserUser = value
    // },
  },
  actions: {
    async GET_STORE_HELPDESK({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/store`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.dataHelp = data.body;
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_STORE_HELPDESK({ rootState, dispatch }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/store`,
          headers: rootState.configAxios.headers,
          data: {
            name: `${rootState.storeData.nombre} - ${rootState.storeData.id}`,
            logo: "https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649367838/2659360_s1ap5f.png",
            languague: "es",
            keywords: "",
            phone: `${rootState.storeData.informacion_tienda[0].telefono}`,
            email: `${rootState.storeData.informacion_tienda[0].email_tienda}`,
            description: "",
            state: true,
          },
        });
        if (data) {
          dispatch("GET_STORE_HELPDESK");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_STORE_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/store`,
          headers: rootState.configAxios.headers,
          data: {
            name: params.name,
            logo: params.logo,
            languague: params.languague,
            keywords: params.keywords,
            phone: params.phone,
            email: params.email,
            description: params.description,
            state: params.state,
          },
        });
        if (data) {
          dispatch("GET_STORE_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_DOMAIN_HELPDESK({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/domain`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.domainHelp = data.body;
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_DOMAIN_AVAILABLE({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/domains/search?text=${params.domain}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_DOMAIN_HELPDESK({ rootState, dispatch }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/domain`,
          headers: rootState.configAxios.headers,
          data: {
            name: `helpdesk${rootState.storeData.id}`,
          },
        });
        if (data) {
          dispatch("GET_DOMAIN_HELPDESK");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_DOMAIN_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/domain`,
          headers: rootState.configAxios.headers,
          data: {
            id: params.id,
            name: params.name,
          },
        });
        if (data) {
          dispatch("GET_DOMAIN_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    // ruta no necesaria
    // async DELETE_DOMAIN_HELPDESK({ rootState, dispatch }, params) {
    //   try {
    //     const { data } = await axios({
    //       method: 'DELETE',
    //       url: `${rootState.urlKomerciaHelpDesk}/api/domain/${params}`,
    //       headers: rootState.configAxios.headers,
    //     })
    //     if (data) {
    //       dispatch('GET_DOMAIN_HELPDESK')
    //       return { success: true, data: data }
    //     }
    //   } catch (err) {
    //     return { success: false, data: err.response }
    //   }
    // },
    async GET_SOCIALS_HELPDESK({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/social`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.socialsHelp = data.body;
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_SOCIALS_HELPDESK({ rootState, dispatch }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/social`,
          headers: rootState.configAxios.headers,
          data: {
            whatsapp: "",
            facebook: "",
            instagram: "",
            twitter: "",
            youtube: "",
            tiktok: "",
            gmail: "",
          },
        });
        if (data) {
          dispatch("GET_SOCIALS_HELPDESK");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_SOCIALS_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/social`,
          headers: rootState.configAxios.headers,
          data: {
            id: params.id,
            whatsApp: params.whatsApp,
            facebook: params.facebook,
            instagram: params.instagram,
            twitter: params.twitter,
            youtube: params.youtube,
            tiktok: params.tiktok,
            gmail: params.gmail,
          },
        });
        if (data) {
          dispatch("GET_SOCIALS_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    // ruta no necesaria
    // async DELETE_SOCIALS_HELPDESK({ rootState, dispatch }, params) {
    //   try {
    //     const { data } = await axios({
    //       method: 'DELETE',
    //       url: `${rootState.urlKomerciaHelpDesk}/api/color/api/social/${params}`,
    //       headers: rootState.configAxios.headers,
    //     })
    //     if (data) {
    //       dispatch('GET_SOCIALS_HELPDESK')
    //       return { success: true, data: data }
    //     }
    //   } catch (err) {
    //     return { success: false, data: err.response }
    //   }
    // },
    async GET_ALL_CATEGORIES_HELPDESK({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/category`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.categoriesHelp = data.body;
          return { success: true, data: state.categoriesHelp };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    // async GET_ID_CATEGORIES_HELPDESK({ rootState }, params) {
    //   try {
    //     const { data } = await axios({
    //       method: 'GET',
    //       url: `${rootState.urlKomerciaHelpDesk}/api/category/${params}`,
    //       headers: rootState.configAxios.headers,
    //     })
    //     if (data) {
    //       return { success: true, data: data.body }
    //     }
    //   } catch (err) {
    //     return { success: false, data: err.response }
    //   }
    // },
    async CREATED_CATEGORIES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/category`,
          headers: rootState.configAxios.headers,
          data: {
            title: params.title,
            description: params.description,
            icon: params.icon,
            stateImg: params.stateImg,
            state: params.state,
            order: params.order,
          },
        });
        if (data) {
          dispatch("GET_ALL_CATEGORIES_HELPDESK");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_CATEGORIES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/category`,
          headers: rootState.configAxios.headers,
          data: {
            id: params.id,
            title: params.title,
            description: params.description,
            icon: params.icon,
            stateImg: params.stateImg,
            state: params.state,
            order: params.order,
          },
        });
        if (data) {
          dispatch("GET_ALL_CATEGORIES_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_CATEGORIES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${rootState.urlKomerciaHelpDesk}/api/category/${params.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          dispatch("GET_ALL_CATEGORIES_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    // async GET_ID_SUBCATEGORIES_HELPDESK({ rootState, state }, params) {
    //   try {
    //     const { data } = await axios({
    //       method: 'GET',
    //       url: `${rootState.urlKomerciaHelpDesk}/api/subcategory/${params}`,
    //       headers: rootState.configAxios.headers,
    //     })
    //     if (data) {
    //       state.subcategoriesHelp = data.body
    //       return { success: true, data: state.subcategoriesHelp }
    //     }
    //   } catch (err) {
    //     return { success: false, data: err.response }
    //   }
    // },
    async GET_ALL_SUBCATEGORIES_HELPDESK({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/categories`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.subcategoriesHelp = data.body;
          return { success: true, data: state.subcategoriesHelp };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_SUBCATEGORIES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/subcategory`,
          headers: rootState.configAxios.headers,
          data: {
            idCategory: params.idCategory,
            title: params.title,
            description: params.description,
            state: params.state,
            order: params.order,
          },
        });
        if (data) {
          dispatch("GET_ALL_SUBCATEGORIES_HELPDESK");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_SUBCATEGORIES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/subcategory`,
          headers: rootState.configAxios.headers,
          data: {
            id: params.id,
            idCategory: params.idCategory,
            title: params.title,
            description: params.description,
            state: params.state,
            order: params.order,
          },
        });
        if (data) {
          dispatch("GET_ALL_SUBCATEGORIES_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_SUBCATEGORIES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${rootState.urlKomerciaHelpDesk}/api/subcategory/${params.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          dispatch("GET_ALL_SUBCATEGORIES_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_ID_AUTHORS_HELPDESK({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/authors/${params.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_ALL_AUTHORS_HELPDESK({ rootState, state }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/authors`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.authorsHelp = data.body;
          return { success: true, data: state.authorsHelp };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_AUTHORS_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/authors`,
          headers: rootState.configAxios.headers,
          data: {
            name: params.name,
            email: params.email,
            rol: params.rol,
            icon: params.icon,
          },
        });
        if (data) {
          dispatch("GET_ALL_AUTHORS_HELPDESK");
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_AUTHORS_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/authors`,
          headers: rootState.configAxios.headers,
          data: {
            id: params.id,
            name: params.name,
            email: params.email,
            rol: params.rol,
            icon: params.icon,
          },
        });
        if (data) {
          dispatch("GET_ALL_AUTHORS_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_AUTHORS_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${rootState.urlKomerciaHelpDesk}/api/authors/${params.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          dispatch("GET_ALL_AUTHORS_HELPDESK");
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async SEARCH_ARTICLES_HELPDESK({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/articles/search?${params.url}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_ID_ARTICLES_HELPDESK({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/article/${params}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_ALL_ARTICLES_HELPDESK({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/article/all?per_page=11&page=${params}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          state.articlesHelp = data.body;
          return { success: true, data: state.articlesHelp };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async CREATED_ARTICLES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/article`,
          headers: rootState.configAxios.headers,
          data: {
            title: params.title,
            state: params.state,
            subtitle: params.subtitle,
            priority: params.priority,
            rating: params.rating,
            content: params.content,
            tags: params.tags,
            idCategory: params.idCategory,
            idSubcategory: params.idSubcategory,
            idAuthor: params.idAuthor,
          },
        });
        if (data) {
          dispatch("GET_ALL_ARTICLES_HELPDESK", 1);
          return { success: true, data: data.body };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async UPDATE_ARTICLES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "PUT",
          url: `${rootState.urlKomerciaHelpDesk}/api/article`,
          headers: rootState.configAxios.headers,
          data: {
            id: params.id,
            title: params.title,
            state: params.state,
            subtitle: params.subtitle,
            priority: params.priority,
            rating: params.rating,
            content: params.content,
            tags: params.tags,
            idCategory: params.idCategory,
            idSubcategory: params.idSubcategory,
            idAuthor: params.idAuthor,
          },
        });
        if (data) {
          dispatch("GET_ALL_ARTICLES_HELPDESK", 1);
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_ARTICLES_HELPDESK({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${rootState.urlKomerciaHelpDesk}/api/article/${params.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          dispatch("GET_ALL_ARTICLES_HELPDESK", 1);
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    //editor
    async CREATED_SETTINGS_HELPDESK({ rootState }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.urlKomerciaHelpDesk}/api/settings`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async GET_SETTINGS_HELPDESK({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaHelpDesk}/api/settings?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          if (data && data.body) {
            let obj = {
              header: JSON.parse(
                JSON.stringify(JSON.parse(data.body.setting[0].header))
              ),
              footer: JSON.parse(
                JSON.stringify(JSON.parse(data.body.setting[0].footer))
              ),
              breadcrumb: JSON.parse(
                JSON.stringify(JSON.parse(data.body.setting[0].breadcrumb))
              ),
              list_categories: JSON.parse(
                JSON.stringify(JSON.parse(data.body.setting[0].list_categories))
              ),
              list_subcategories: JSON.parse(
                JSON.stringify(
                  JSON.parse(data.body.setting[0].list_subcategories)
                )
              ),
              list_articles: JSON.parse(
                JSON.stringify(JSON.parse(data.body.setting[0].list_articles))
              ),
              id: data.body.setting[0].id,
            };
            state.settingHelpDesk = obj;
          }
          return { success: true, data: state.settingHelpDesk };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async DELETE_SETTINGS_HELPDESK({ rootState }, params) {
      try {
        const { data } = await axios({
          method: "DELETE",
          url: `${rootState.urlKomerciaHelpDesk}/api/settings/${params.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          return { success: true, data: data };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
  },
  getters: {},
};
