export const dataPricesOld = [
  // Internacional
  {
    id: 0,
    name: 'internacional',
    title: 'Internacional',
    monthlyPriceEmprendedor: '5',
    annualPriceEmprendedor: '50',
    monthlyPricePremium: '10',
    annualPricePremium: '100',
    ahorroAnualEmprededor: '10',
    ahorroAnualPremium: '20',
    linkMonthlyPriceEmprendedor: 'price_1MXkNsLpoOizBqClXTdwG58z',
    linkannualPriceEmprendedor: 'price_1KP5HtLpoOizBqClJTg2sHf4',
    linkMonthlyPricePremium: 'price_1MbRwzLpoOizBqClSNty6M9x',
    linkannualPricePremium: 'price_1KP5IGLpoOizBqCl3mE8x9EY',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'USD',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Internacional_rzalry.png',
  },
  // Colombia
  {
    id: 1,
    name: 'co',
    title: 'Colombia',
    //Emprendedor
    monthlyPriceEmprendedor: '32.000',
    annualPriceEmprendedor: '250.000',
    ahorroAnualEmprededor: '134.000',
    //Premium
    monthlyPricePremium: '52.000',
    annualPricePremium: '450.000',
    ahorroAnualPremium: '150.000',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1MbRfwLpoOizBqClDdCChZEL',
    linkannualPriceEmprendedor: 'https://mpago.li/2SwxYFY',
    linkMonthlyPricePremium: 'price_1MbRYOLpoOizBqClaO9S13Wy',
    linkannualPricePremium: 'https://mpago.li/1GX4pcb',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: false,
    stateStripePremiumAnual: false,
    currencySign: '$',
    simbolo: 'COP',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Colombia_kz1t6d.png',
  },
  // Chile
  {
    id: 2,
    name: 'cl',
    title: 'Chile',
    //Emprendedor
    monthlyPriceEmprendedor: '8.000',
    annualPriceEmprendedor: '80.000',
    ahorroAnualEmprededor: '16.000',
    //Premium
    monthlyPricePremium: '14.000',
    annualPricePremium: '159.000',
    ahorroAnualPremium: '9.000',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1KOp3ULpoOizBqClJoRs5cv5',
    linkannualPriceEmprendedor: 'price_1KP5AQLpoOizBqClAcKTGK15',
    linkMonthlyPricePremium: 'price_1KOrtdLpoOizBqClWvsAOkeA',
    linkannualPricePremium: 'price_1KP5DDLpoOizBqClTgt9oavu',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'CLP',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Chile_rzygtf.png',
  },
  // México
  {
    id: 3,
    name: 'mx',
    title: 'México',
    //Emprendedor
    monthlyPriceEmprendedor: '130',
    annualPriceEmprendedor: '1.300',
    ahorroAnualEmprededor: '260',
    //Premium
    monthlyPricePremium: '250',
    annualPricePremium: '2500',
    ahorroAnualPremium: '500',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1KOp6HLpoOizBqCljDYm42TJ',
    linkannualPriceEmprendedor: 'price_1KP5EaLpoOizBqClhxoIXkdk',
    linkMonthlyPricePremium: 'price_1KOp6qLpoOizBqClhxwSOoQ9',
    linkannualPricePremium: 'price_1KP5FTLpoOizBqCltQ8zZkbl',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: false,
    currencySign: '$',
    simbolo: 'MXN',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Mexico_dibihf.png',
  },
  // Perú
  {
    id: 4,
    name: 'pe',
    title: 'Perú',
    //Emprendedor
    monthlyPriceEmprendedor: '24,89',
    annualPriceEmprendedor: '249',
    ahorroAnualEmprededor: '49,68',
    //Premium
    monthlyPricePremium: '47,89',
    annualPricePremium: '479',
    ahorroAnualPremium: '95,68',
    // Link
    linkMonthlyPriceEmprendedor:
      'https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380847e53a7d4017e547c5fdc005f',
    linkannualPriceEmprendedor:
      'https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380847e58ce1f017e5fe82a880259',
    linkMonthlyPricePremium:
      'https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380847e5829b7017e5fe98e7f0225',
    linkannualPricePremium:
      'https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380847e57f323017e5feaaed20248',
    stateStripeEmprendedorMensual: false,
    stateStripePremiumMensual: false,
    stateStripeEmprendedorAnual: false,
    stateStripePremiumAnual: false,
    currencySign: 'S/',
    simbolo: '',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Peru_v36g5q.png',
  },
  // Puerto Rico
  {
    id: 5,
    name: 'pr',
    title: 'Puerto Rico',
    //Emprendedor
    monthlyPriceEmprendedor: '7',
    annualPriceEmprendedor: '50',
    ahorroAnualEmprededor: '34',
    //Premium
    monthlyPricePremium: '12',
    annualPricePremium: '100',
    ahorroAnualPremium: '44',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1MXkNsLpoOizBqClXTdwG58z',
    linkannualPriceEmprendedor: 'price_1KP5HtLpoOizBqClJTg2sHf4',
    linkMonthlyPricePremium: 'price_1MbRwzLpoOizBqClSNty6M9x',
    linkannualPricePremium: 'price_1KP5IGLpoOizBqCl3mE8x9EY',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'USD',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Puerto_Rico_uorvfh.png',
  },
  // Argentina
  {
    id: 6,
    name: 'ar',
    title: 'Argentina',
    //Emprendedor
    monthlyPriceEmprendedor: '7',
    annualPriceEmprendedor: '50',
    ahorroAnualEmprededor: '34',
    //Premium
    monthlyPricePremium: '12',
    annualPricePremium: '100',
    ahorroAnualPremium: '44',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1MXkNsLpoOizBqClXTdwG58z',
    linkannualPriceEmprendedor: 'price_1KP5HtLpoOizBqClJTg2sHf4',
    linkMonthlyPricePremium: 'price_1MbRwzLpoOizBqClSNty6M9x',
    linkannualPricePremium: 'price_1KP5IGLpoOizBqCl3mE8x9EY',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'USD',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417092/komerica/Banderas/Argentina_twinoc.png',
  },
  // Estados Unidos
  {
    id: 7,
    name: 'us',
    title: 'Estados Unidos',
    //Emprendedor
    monthlyPriceEmprendedor: '7',
    annualPriceEmprendedor: '50',
    ahorroAnualEmprededor: '34',
    //Premium
    monthlyPricePremium: '12',
    annualPricePremium: '100',
    ahorroAnualPremium: '44',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1MXkNsLpoOizBqClXTdwG58z',
    linkannualPriceEmprendedor: 'price_1KP5HtLpoOizBqClJTg2sHf4',
    linkMonthlyPricePremium: 'price_1MbRwzLpoOizBqClSNty6M9x',
    linkannualPricePremium: 'price_1KP5IGLpoOizBqCl3mE8x9EY',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'USD',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1654543123/komerica/Banderas/eeuu_mxojop.png',
  },
  // Panamá
  {
    id: 8,
    name: 'pa',
    title: 'Panamá',
    //Emprendedor
    monthlyPriceEmprendedor: '7',
    annualPriceEmprendedor: '50',
    ahorroAnualEmprededor: '34',
    //Premium
    monthlyPricePremium: '12',
    annualPricePremium: '100',
    ahorroAnualPremium: '44',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1MXkNsLpoOizBqClXTdwG58z',
    linkannualPriceEmprendedor: 'price_1KP5HtLpoOizBqClJTg2sHf4',
    linkMonthlyPricePremium: 'price_1MbRwzLpoOizBqClSNty6M9x',
    linkannualPricePremium: 'price_1KP5IGLpoOizBqCl3mE8x9EY',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'USD',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1653417091/komerica/Banderas/Panama_pdney2.png',
  },
  // Honduras
  {
    id: 9,
    name: 'hn',
    title: 'Honduras',
    //Emprendedor
    monthlyPriceEmprendedor: '7',
    annualPriceEmprendedor: '50',
    ahorroAnualEmprededor: '34',
    //Premium
    monthlyPricePremium: '12',
    annualPricePremium: '100',
    ahorroAnualPremium: '44',
    // Link
    linkMonthlyPriceEmprendedor: 'price_1MXkNsLpoOizBqClXTdwG58z',
    linkannualPriceEmprendedor: 'price_1KP5HtLpoOizBqClJTg2sHf4',
    linkMonthlyPricePremium: 'price_1MbRwzLpoOizBqClSNty6M9x',
    linkannualPricePremium: 'price_1KP5IGLpoOizBqCl3mE8x9EY',
    stateStripeEmprendedorMensual: true,
    stateStripePremiumMensual: true,
    stateStripeEmprendedorAnual: true,
    stateStripePremiumAnual: true,
    currencySign: '$',
    simbolo: 'USD',
    img:
      'https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1657054271/komerica/Banderas/Honduras_x1yuwg.png',
  },
]
