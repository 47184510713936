<template>
  <transition name="el-zoom-in-top">
    <div class="content-wrapper">
      <!-- Main content -->
      <div class="title-section-content">
        <div class="title-section">
          <h2>{{ $t("text_ventas_misVentas") }}</h2>
        </div>
      </div>
      <section>
        <MultiplesClientsDialog
          :store-data="storeData"
          :visible.sync="dialogVisible"
        />
        <div
          :class="darkMode ? 'dark' : 'darktxt'"
          class="box box-not-responsive"
          style="margin-bottom: 0px; padding: 10px 12px 10px"
        >
          <stats-home />
        </div>
        <!-- Table-->
        <div
          :class="darkMode ? 'dark' : 'light'"
          class="box"
          style="margin-bottom: 0px"
        >
          <div class="title-sales">
            <div class="flex flex-row justify-between items-center">
              <h2
                class="flex items-center mb-1"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_ventas_misVentas") }}
                <sliderInformation
                  :numberId="76"
                  :actions="true"
                  class="ml-2"
                />
              </h2>
              <div class="boxb_buttonActions">
                <el-button
                  class="buttonActions"
                  @click="openMultiplesProducts('export')"
                >
                  {{ $t("text_ventas_exportarVentas") }}
                </el-button>
              </div>
            </div>
            <div
              class="mt-6 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-x-5 gap-3"
            >
              <!-- Filter id Order -->
              <div class="item-filter">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ $t("text_ventas_filtrarNOrden") }}
                </p>
                <el-input
                  v-model="filters.id"
                  class="inputSearch-filt"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  type="search"
                  :placeholder="$t('text_ventas_BUscarNOrden')"
                  autocomplete="off"
                  autocorrect="off"
                  @input="sendDataFilter()"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                >
                  <i
                    v-if="!filters.id"
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                  ></i>
                </el-input>
              </div>
              <!-- Filter estado  -->
              <div class="item-filter">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ $t("text_ventas_filtrarEstado") }}
                </p>
                <el-select
                  v-model="filters.state"
                  class="inputSearch-filt"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  clearable
                  filterable
                  :placeholder="$t('text_ventas_seleccionaEstado')"
                  @clear="clear"
                  @change="currentState()"
                >
                  <el-option
                    v-for="(item, index) in saleState"
                    :key="`${item.id}-${index}`"
                    :label="$t(item.state2)"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.state }}</span>
                  </el-option>
                </el-select>
              </div>
              <!-- Filter método de pago  -->
              <div class="item-filter">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ $t("text_ventas_filtrarMethodPay") }}
                </p>
                <el-select
                  v-model="filters.paymentMethod"
                  class="inputSearch-filt"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  clearable
                  filterable
                  :placeholder="$t('text_ventas_filtrarMethodPay')"
                  @clear="clear"
                  @change="sendDataFilter()"
                >
                  <el-option
                    v-for="(item, index) in methodPay"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.label }}</span>
                  </el-option>
                </el-select>
              </div>
              <!-- Filter date -->
              <div class="item-filter">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ $t("text_ventas_filtrarFecha") }}
                </p>
                <VueCtkDateTimePicker
                  v-model="pickupDate"
                  id="InlinePicker"
                  inputSize="lg"
                  noKeyboard
                  :label="$t('text_ventas_seleccionaFecha')"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  range
                  format="YYYY-MM-DD"
                  color="#4429b4"
                  :custom-shortcuts="customShortcuts"
                  buttonNowTranslation="Maintenant"
                  @input="changeDate($event)"
                />
              </div>
              <!-- filtro por nombre cliente -->
              <div class="item-filter">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ $t("text_ventas_filtrarCliente") }}
                </p>
                <el-input
                  v-model="filters.clientName"
                  class="inputSearch-filt"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  type="search"
                  autocomplete="off"
                  autocorrect="off"
                  placeholder="Buscar cliente"
                  @input="sendDataFilter()"
                  onkeypress="return ((event.charCode>96 && event.charCode<123) || (event.charCode>64 && event.charCode<91) || (event.charCode==32) || (event.charCode==241) || (event.charCode==209))"
                  ><i
                    v-if="!filters.clientName"
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                  ></i>
                </el-input>
              </div>
              <!-- filtro id cliente -->
              <div class="item-filter">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ $t("text_ventas_filtrarID") }}
                </p>
                <el-input
                  v-model="filters.clientIdentification"
                  class="inputSearch-filt"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  type="search"
                  autocomplete="off"
                  autocorrect="off"
                  placeholder="Buscar Identificación"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  @input="sendDataFilter()"
                >
                  <i
                    v-if="!filters.clientIdentification"
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                  ></i>
                </el-input>
              </div>
            </div>
            <div class="box box-responsive">
              <stats-home />
            </div>
          </div>
          <div
            class="box-body table-container"
            :class="darkMode ? 'darks-tablet' : 'light-tablet'"
          >
            <el-table
              :data="allOrdersData.sales"
              stripe
              lazy
              border
              style="width: 100%; font-size: 13px"
              :fit="true"
              highlight-current-row
            >
              <el-table-column
                prop="id"
                align="center"
                width="70"
                label="#"
              ></el-table-column>
              <el-table-column
                :label="$t('text_ventas_state_sales')"
                width="160"
              >
                <template slot-scope="scope">
                  <div>
                    <el-tag
                      v-if="scope.row.tipo"
                      size="medium"
                      :color="saleState[8].color"
                      class="tag-state"
                    >
                      {{ $t(saleState[8].state2) }}
                    </el-tag>
                    <el-tag
                      v-else-if="
                        saleState[scope.row.estado] &&
                        saleState[scope.row.estado].color
                      "
                      size="medium"
                      :color="saleState[scope.row.estado].color"
                      class="tag-state"
                    >
                      {{ $t(saleState[scope.row.estado].state2) }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('text_ventas_state_shipment')"
                width="160"
              >
                <template slot-scope="scope">
                  <div
                    class="flex flex-col justify-center items-center text-center"
                  >
                    <p
                      v-if="scope.row?.mi_paquete?.length > 0"
                      style="font-size: 11px; color: #ef8604"
                    >
                      Mipaquete.com
                    </p>
                    <div v-if="scope.row.transportadora != null">
                      <p style="font-size: 11px; color: #29ad5b">
                        {{ setTransportadora(scope.row.transportadora) }}
                      </p>
                    </div>
                    <div v-if="scope.row.deliveryStatusId !== null">
                      <el-tag
                        :size="
                          scope.row?.mi_paquete?.length > 0 ? 'small' : 'medium'
                        "
                        :color="
                          shippingState[scope.row.deliveryStatusId - 1]?.color
                        "
                        class="tag-state"
                      >
                        {{
                          shippingState[scope.row.deliveryStatusId - 1].state
                        }}
                      </el-tag>
                      <span v-if="scope.row?.mi_paquete?.length > 0"> </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('text_ventas_fecha')"
                width="175"
                sortable="true"
              >
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <b style="margin-left: 10px">
                    {{
                      formatCalendarMoment(
                        scope.row.createdAt,
                        "America/Bogota"
                      )
                    }}
                  </b>
                  <br />
                  <p
                    style="margin-left: 24px; font-size: 11px; margin-top: -6px"
                  >
                    {{ formatDateMomentAWS(scope.row.createdAt) }}
                  </p>
                </template>
              </el-table-column>
              <!-- <el-table-column
                :label="$t('text_ventas_updateFecha')"
                width="175"
                sortable="true"
              >
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <b style="margin-left: 10px">
                    {{
                      formatCalendarMoment(
                        scope.row.updatedAt
                          ? scope.row.updatedAt
                          : scope.row.createdAt
                      )
                    }}
                  </b>
                  <br />
                  <p
                    style="margin-left: 24px; font-size: 11px; margin-top: -6px"
                  >
                    {{
                      formatDateMomentAWS(
                        scope.row.updatedAt
                          ? scope.row.updatedAt
                          : scope.row.createdAt
                      )
                    }}
                  </p>
                </template>
              </el-table-column> -->
              <el-table-column :label="$t('text_ventas_medio')" width="200">
                <template slot-scope="scope">
                  <p>{{ $t(medioPago(scope.row.metodoPago).status) }}</p>
                  <p :style="getChannelStyle(scope.row.canal)">
                    {{ getChannelName(scope.row.canal) }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('text_ventas_cliente')" width="300">
                <template slot-scope="scope">
                  <div>
                    <p v-if="scope.row.usuario2.id != 30866">
                      {{ scope.row.usuario2.nombre }}
                    </p>
                    <div
                      v-else
                      class="w-full flex flex-col justify-start items-start"
                    >
                      <p><i class="fab fa-whatsapp"></i> WhatsApp</p>
                      <p>
                        {{
                          setDataCustomer(
                            scope.row.usuario2.id,
                            scope.row.comentario,
                            "name"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('text_ventas_ID')" width="150">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.usuario2.identificacion.length > 20"
                    class="w-full text-center"
                  >
                    <p>--</p>
                  </div>
                  <div v-else>
                    <p v-if="scope.row.usuario2.id != 30866">
                      {{ scope.row.usuario2.identificacion }}
                    </p>
                    <div
                      v-else
                      class="w-full flex flex-col justify-start items-start"
                    >
                      <p><i class="fab fa-whatsapp"></i> WhatsApp</p>
                      <p>
                        {{
                          setDataCustomer(
                            scope.row.usuario2.id,
                            scope.row.comentario,
                            "id"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                :label="$t('text_tiendaOnline_descuentos')"
                width="180"
              >
                <template slot-scope="scope">
                  <div class="flex flex-col justify-center items-start">
                    <p
                      v-if="scope.row?.descuento"
                      class="font-bold text-red-500"
                    >
                      {{ $t("text_descuentos_descuento") }}:
                      <span class="font-normal">
                        -
                        {{
                          scope.row?.descuento | formatCurrency(idCountryStore)
                        }}
                      </span>
                    </p>
                    <p
                      v-if="
                        scope.row?.cupon != 'null' &&
                        scope.row?.cupon != null &&
                        scope.row?.cupon != ''
                      "
                      class="font-bold text-red-500"
                    >
                      {{ $t("text_cupones_codigoCupon") }}:
                      <span class="font-normal"> - {{ scope.row?.cupon }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column :label="$t('text_inicio_valor')" width="180">
                <template slot-scope="scope">
                  <p v-if="scope.row.tipo">
                    <b>{{ $t("text_ventas_sinPrecios") }}</b>
                  </p>
                  <p v-else>
                    <b>
                      {{ scope.row.total | formatCurrency(idCountryStore) }}
                    </b>
                  </p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="75">
                <template slot-scope="scope">
                  <div class="details_button">
                    <el-button
                      v-if="scope.row.tipo"
                      size="mini"
                      round
                      type="primary"
                      plain
                      icon="el-icon-circle-plus-outline"
                      @click="handle_quotation(scope.row.id)"
                    >
                      {{ $t("text_ventas_asignar") }}
                    </el-button>
                    <router-link v-else :to="`listado/${scope.row.id}`">
                      <el-button size="mini" round type="primary" plain>
                        {{ $t("text_ventas_Ver") }}
                      </el-button>
                    </router-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="">
                <template slot-scope="scope">
                  <el-tooltip content="Copiar link de venta">
                    <span
                      class="link-seguimiento"
                      @click="
                        copyLink(scope.row.id, scope.row.usuario2, scope.$index)
                      "
                    >
                      {{
                        $t(
                          `${
                            indexSelected === scope.$index
                              ? "text_ventas_copiado"
                              : "text_ventas_copiarLink"
                          }`
                        )
                      }}
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <!-- <el-table-column fixed="right">
                <template class="details_button" slot-scope="scope">
                  https://checkout.komercia.co/?pay_link=1559.3480.92731
                  <a
                    style="margin-right: 10px"
                    v-if="scope.row.estado == 0"
                    :href="`https://checkout.komercia.co?pay_link=${scope.row.tienda}.1371.${scope.row.id}`"
                    target="blank"
                  >
                     <img loading="lazy"
                      width="30px"
                      style="margin-bottom: -5px"
                      src="https://purepng.com/public/uploads/large/share-icon-7nl.png"
                    />
                  </a>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            class="box-body table-container-responsive"
            :class="darkMode ? 'darks-tablet' : 'light-tablet'"
          >
            <el-table
              v-if="allOrdersData?.sales?.length"
              :data="allOrdersData.sales"
              stripe
              lazy
              border
              style="width: 100%; font-size: 13px"
              :fit="true"
              highlight-current-row
              :default-sort="{ prop: 'fecha', order: 'descending' }"
            >
              <el-table-column prop="id" align="center" width="55" label="#">
              </el-table-column>
              <el-table-column :label="$t('text_ventas_estado')">
                <template slot-scope="scope">
                  <router-link
                    :to="`listado/${scope.row.id}`"
                    class="state-row"
                  >
                    <div class="content-state">
                      <el-tag
                        v-if="scope.row.tipo"
                        size="medium"
                        :color="saleState[8]?.color"
                        class="tag-state"
                      >
                        {{ saleState[8].state }}
                      </el-tag>
                      <el-tag
                        v-else-if="
                          saleState[scope.row.estado] &&
                          saleState[scope.row.estado]?.color
                        "
                        size="medium"
                        :color="saleState[scope.row.estado]?.color"
                        class="tag-state"
                      >
                        {{ saleState[scope.row.estado].state }}
                      </el-tag>
                    </div>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column :label="$t('text_ventas_fecha')" sortable="true">
                <template slot-scope="scope">
                  <router-link :to="`listado/${scope.row.id}`">
                    <b style="margin-left: 0px">
                      {{ formatCalendarMoment(scope.row.createdAt) }}
                    </b>
                    <p
                      style="
                        margin-left: 0px;
                        font-size: 11px;
                        margin-top: -6px;
                      "
                    >
                      {{ formatDateMomentAWS(scope.row.createdAt) }}
                    </p>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column :label="$t('text_inicio_valor')">
                <template slot-scope="scope">
                  <router-link :to="`ingresos/${scope.row.id}`">
                    <p v-if="scope.row.tipo">
                      <b>{{ $t("text_ventas_sinPrecios") }}</b>
                    </p>
                    <p v-else>
                      <b>
                        {{ scope.row.total | formatCurrency(idCountryStore) }}
                      </b>
                    </p>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            class="wrapper-pagination"
            :class="darkMode ? 'darks-pagination' : 'light-pagination'"
          >
            <el-pagination
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :page-count="allOrdersData.lastPage"
              :current-page="previousPageOrden"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
        <!-- End of Table-->
      </section>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import statsHome from "../../components/StatsHome";
import MultiplesClientsDialog from "./item/modalExport.vue";
import formatDateMomentAWS from "../../mixins/formatDateMomentAWS";
import formatCurrency from "../../mixins/formatCurrent";
import formatCalendarMoment from "../../mixins/formatCalendarMoment";
import medioPago from "../../mixins/medioPago.js";
import states from "../../mixins/states.vue";
import sliderInformation from "@/components/information.vue";
export default {
  name: "OrdersTable",
  components: {
    statsHome,
    MultiplesClientsDialog,
    sliderInformation,
  },
  mixins: [
    formatDateMomentAWS,
    formatCalendarMoment,
    medioPago,
    formatCurrency,
    states,
  ],
  data() {
    return {
      stateData: false,
      indexSelected: null,
      tabAction: "export",
      dialogVisible: false,
      pickupDate: {},
      filters: {
        page: 1,
        limit: 25,
        order: "DESC", //  ASC DESC
        id: null,
        startDate: null,
        endDate: null,
        clientName: null,
        clientIdentification: null,
        state: null,
        paymentMethod: null,
      },
    };
  },
  computed: {
    ...mapState(["storeData", "darkMode", "allOrdersData"]),
    previousPageOrden: {
      get() {
        return this.$store.state.previousPageOrden;
      },
      set(newValue) {
        this.$store.state.previousPageOrden = newValue;
      },
    },
  },
  watch: {
    storeData() {
      this.sendDataFilter();
    },
  },
  created() {
    if (this.previousPageOrden) {
      this.filters.page = this.previousPageOrden;
      if (this.storeData.id) {
        this.sendDataFilter();
      }
    }
  },
  methods: {
    // setType(type) {
    //   return type === 1 ? "Cotización" : "Venta";
    // },
    openMultiplesProducts(value) {
      this.dialogVisible = true;
      this.tabAction = value;
    },
    handle_quotation(id) {
      this.$store.dispatch("GET_ORDER_BY_ID", id);
    },
    setDataCustomer(user, data, type) {
      if (data && user == 30866) {
        let tempData = {
          state: false,
          dataCustomer: JSON.parse(data) || null,
        };

        if (
          tempData.dataCustomer &&
          tempData.dataCustomer.nombre &&
          tempData.dataCustomer.phone &&
          tempData.dataCustomer.identificacion &&
          tempData.dataCustomer.direccion &&
          tempData.dataCustomer.barrio &&
          tempData.dataCustomer.ciudad
        ) {
          tempData.state = true;
        }

        if (tempData.state) {
          if (type == "name") {
            return tempData.dataCustomer.nombre;
          } else if (type == "id") {
            return tempData.dataCustomer.phone;
          }
        } else {
          if (type == "name") {
            let nameInput = tempData.dataCustomer.filter((item) =>
              item.textInput.toLowerCase().includes("nombre")
            );
            return nameInput.length ? nameInput[0].value : null;
          } else if (type == "id") {
            // console.log(tempData.dataCustomer);
            let idInput = tempData.dataCustomer.filter((item) => {
              if (
                item.textInput.toLowerCase().includes("cédula") ||
                item.textInput.toLowerCase().includes("identificación") ||
                item.textInput.toLowerCase().includes("cedula") ||
                item.textInput.toLowerCase().includes("identificacion")
              ) {
                return item;
              } else {
                if (item.type == "tel") {
                  return item;
                }
              }
            });
            return idInput.length ? idInput[0].value : null;
          }
        }
      }

      return null;
    },
    copyLink(id, user, index) {
      this.indexSelected = index;
      const el = document.createElement("textarea");
      if (this.storeData.template == 99) {
        el.value = `${this.getUrlProduct()}/wa/${
          this.storeData.id
        }/micompra?orden=${id}&usuario=${user.identificacion}`;
      } else {
        el.value = `${this.getUrlProduct()}/micompra?orden=${id}&usuario=${
          user.identificacion
        }`;
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setTimeout(() => {
        this.indexSelected = null;
      }, 1500);
    },
    getUrlProduct() {
      const suffix =
        this.storeData.entidades &&
        this.storeData.entidades.length > 0 &&
        this.storeData.entidades[0].id == 21
          ? "co"
          : this.storeData.template !== 3
          ? "store"
          : "co";
      if (this.storeData.informacion_tienda[0].dominio) {
        return this.storeData.informacion_tienda[0].dominio;
      } else {
        if (
          this.storeData.entidades &&
          this.storeData.entidades.length > 0 &&
          this.storeData.entidades[0].id == 21
        ) {
          return `https://${this.storeData.subdominio}.keepbuy.${suffix}`;
        } else {
          return `https://${this.storeData.subdominio}.komercia.${suffix}`;
        }
      }
    },

    async currentChange({
      page,
      limit,
      order,
      id,
      startDate,
      endDate,
      clientName,
      clientIdentification,
      state,
      paymentMethod,
    }) {
      this.stateData = false;
      const storeId = this.storeData.id;
      try {
        const { success, data } = await this.$store.dispatch(
          "GET_FILTER_ORDERS",
          {
            page,
            limit,
            order,
            storeId,
            id,
            startDate,
            endDate,
            clientName,
            clientIdentification,
            state,
            paymentMethod,
          }
        );
        if (success) {
          this.previousPageOrden = Number(data.currentPage);
          this.stateData = true;
        }
      } catch (error) {
        this.stateData = false;
        console.error("Error fetching order:", error);
      }
    },
    sendDataFilter() {
      this.currentChange(this.filters);
    },
    changeDate(value) {
      this.filters.startDate = value?.start || null;
      this.filters.endDate = value?.end || null;
      this.sendDataFilter();
    },
    currentState() {
      this.filters.state = this.filters.state.toString();
      this.sendDataFilter();
    },
    changePage(value) {
      this.previousPageOrden = Number(value);
      this.filters.page = value;
      this.sendDataFilter();
    },

    clear() {
      this.sendDataFilter();
    },
    setTransportadora(value) {
      if (value) {
        let temp = JSON.parse(JSON.stringify(JSON.parse(value)));
        return temp.name;
      }
    },
    getChannelStyle(canal) {
      const index = parseInt(canal) - 1;
      const channel = this.channelBuy[index];

      if (channel) {
        return `font-size: 11px; margin-top: -6px; color: ${channel.color};`;
      } else {
        return "font-size: 11px; margin-top: -6px; color: #EF4444;";
      }
    },
    getChannelName(canal) {
      const index = parseInt(canal) - 1;
      const channel = this.channelBuy[index];

      return channel ? channel.name : "ERROR";
    },
  },
};
</script>

<style scoped>
.item-filter >>> .field-input {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px !important;
  height: 40px !important;
  min-height: 40px !important;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  position: relative;
}
.inputSearch-filt >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  position: relative;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.darks-tablet >>> .el-table th.el-table__cell,
.current-row {
  background-color: #344a63 !important;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.darks-tablet >>> .el-table__body tr.hover-row.current-row > td.el-table__cell,
.darks-tablet
  >>> .el-table__body
  tr.hover-row.el-table__row--striped.current-row
  > td.el-table__cell,
.darks-tablet
  >>> .el-table__body
  tr.hover-row.el-table__row--striped
  > td.el-table__cell,
.darks-tablet >>> .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.wrapper-pagination >>> .el-pagination.is-background .btn-next {
  color: black;
  background-color: transparent;
}
.darks-pagination >>> .el-pagination button:disabled {
  color: white;
  background-color: #233345;
}
.darks-pagination >>> .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: center center no-repeat #233345;
  color: white;
}
.light-pagination >>> .el-pagination button:disabled {
  color: #4429b4;
  background-color: #fff;
}
.light-pagination >>> .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: center center no-repeat #fff;
  color: #4429b4;
}
.darks-pagination >>> .el-pager li.active {
  color: #fff;
  background-color: #344a63;
}
.darks-pagination >>> .el-pager li:hover {
  color: #fff;
  background-color: #4429b4;
}
.darks-pagination >>> .el-pager li {
  color: #fff;
  background-color: #233345;
}
.dark {
  background-color: #233345;
}
.light {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
i.fab.fa-whatsapp {
  color: #00bb2d;
  font-size: 20px;
  margin-right: 5px;
}
.state {
  width: 120px;
  display: flex;
  align-items: center;
  color: #1d1c1c;
  font-weight: 500;
  font-size: 12px;
  border-radius: 5px;
}
.details_button {
  display: flex;
}
.el-icon-question {
  height: initial;
}
.el-input__icon {
  /* display: flex; */
  align-items: center;
  line-height: 0px;
}
.bg {
  width: 30px;
  height: 30px;
  color: #4c4c4c;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  margin: 0 10px 0 0;
}
.tag-state {
  width: 125px;
  font-weight: 600;
  text-align: center;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
}
.table-container > div {
  font-weight: 500;
}
.table-container > thead {
  color: red;
}
.table-container th > .cell {
  color: red;
}
.title-sales {
  display: flex;
  width: 100%;
  padding: 20px 12px;
  flex-direction: column;
}
.title-sales h2 {
  font-size: 18px;
  font-weight: bold;
  /* color: #4c4c4c; */
}
.box-body {
  padding: 10px 12px 10px;
}
/* .wrapper-filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 15px;
} */
/* .item-filter > p {
  margin-bottom: 5px;
} */
.buttonActions {
  color: var(--purple);
  border: 2px solid var(--purple);
  font-weight: 600;
  height: 40px !important;
  border-radius: 8px;
}
.boxb_buttonActions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.wrapper-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0 50px;
}
.content {
  padding-left: 10px;
  padding-right: 10px;
}
.table-container-responsive {
  display: none;
}
.title-section-content {
  display: none;
}
.box-responsive {
  display: none;
}
.wrapper-seguimiento {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
}
.link-seguimiento {
  font-size: 12px;
  text-decoration: underline !important;
  cursor: pointer;
}
@media (max-width: 1080px) {
  /* .item-filter p {
    min-height: 42px;
  } */
}
@media (max-width: 767px) {
  /* .item-filter p {
    min-height: 30px;
  } */
  .box-responsive {
    display: inline;
    margin-bottom: 0px;
  }
  .box-not-responsive {
    display: none;
  }
  .table-container {
    display: none;
  }
  .table-container-responsive {
    display: inline;
    padding: 0;
  }
  .tag-state {
    max-width: 127px;
    width: 100%;
    padding: 7px 10px;
    height: unset;
    word-break: break-word;
    white-space: unset;
    line-height: 12px;
  }
  .cell {
    padding-left: 0px !important;
    padding-right: 0px;
  }
  .state-row {
    padding: 0;
  }
  a {
    color: #4c4c4c;
    width: 100%;
    height: 100%;
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
  }
  .title-section-content {
    display: inline;
    min-height: 76px;
    width: 100%;
    margin-top: 15px;
  }
  .title-section {
    min-height: 60px;
    background-color: var(--purple);
    /* position: sticky; */
    top: 0;
    z-index: 999;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #ffffff;
  }
  .title-sales h2 {
    display: none;
  }
  .title-sales {
    padding: 0px;
  }
  .box {
    background: transparent;
    box-shadow: unset;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .buttonActions {
    width: 100%;
    height: 40px !important;
    border: 1px solid var(--purple);
  }
  /* .wrapper-filters {
    grid-template-columns: 1fr 1fr;
  } */
  .boxb_buttonActions {
    width: 100%;
  }
  .box-responsive .content-stats .content-stats-detail-responsive {
    padding-left: 0px !important;
  }
  .title-section h2 {
    font-size: 18px;
  }
  .content-state {
    padding: 0 2.5px;
  }
}
/* @media (max-width: 700px) {
  .item-filter:nth-child(1) {
    display: none;
  }
} */
@media (max-width: 655px) {
  .boxb_buttonActions {
    justify-self: flex-start;
  }
}
@media (max-width: 380px) {
  .tag-state {
    font-size: 11px;
    padding: 7px 8px;
  }
}
</style>
