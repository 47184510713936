import axios from "axios";

// if (true) {
//   const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA
//   const URL_MERCADOPAGO = 'https://api.mercadopago.com'
//   const ACCESS_TOKEN = process.env.VUE_APP_MERCADOPAGO_ACCESS_TOKEN
// } else {
//   const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA
//   const URL_MERCADOPAGO = 'https://api.mercadopago.com'
//   const ACCESS_TOKEN = process.env.VUE_APP_MERCADOPAGO_ACCESS_TOKEN
// }
const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA;
const URL_MERCADOPAGO = "https://api.mercadopago.com";
const ACCESS_TOKEN = process.env.VUE_APP_MERCADOPAGO_ACCESS_TOKEN;
const PUBLIC_KEY = process.env.VUE_APP_MERCADOPAGO_PUBLIC_KEY;
// console.log("this.$store.state.storeData")

export default {
  namespaced: true,
  state: {
    cards: null,
    customer_id: null,
    installments: null,
    documentTypes: null,
    paymentResponse: null,
  },
  mutations: {
    SET_INSTALLMENTS(state, value) {
      state.installments = value;
    },
    SET_CARDS(state, value) {
      state.cards = value;
    },
    REMOVE_CARD(state, value) {
      const index = state.cards.findIndex((card) => card.id == value);
      state.cards.splice(index, 1);
    },
    SET_CUSTOMER_ID_BY_KOMERCIA(state, value) {
      state.customer_id = value;
    },
    SET_DOCUMENTTYPES(state, value) {
      state.documentTypes = value;
    },
    SET_PAYMENT_RESPONSE(state, value) {
      state.paymentResponse = value.data;
    },
  },
  actions: {
    SET_KEY_MERCADOPAGO() {
      // console.log(this.$store.state.storeData)
      Mercadopago.setPublishableKey(PUBLIC_KEY);
    },
    async GET_DOCUMENTTYPES({ commit }) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_MERCADOPAGO}/v1/identification_types`,
        params: {
          access_token: ACCESS_TOKEN,
        },
      });
      commit("SET_DOCUMENTTYPES", data);
    },
    GET_INSTALLMENTS({ commit }, payload) {
      Mercadopago.getInstallments(payload, (status, response) => {
        commit("SET_INSTALLMENTS", response[0]);
      });
    },
    async GET_CUSTOMER_BY_ID(id) {
      try {
        const response = await axios({
          method: "GET",
          url: `${URL_MERCADOPAGO}/v1/customers/${id}`,
          params: {
            access_token: ACCESS_TOKEN,
          },
          data: {},
        });
        return response;
      } catch (err) {
        console.error(err);
      }
    },
    async GET_CUSTOMER_BY_EMAIL(email) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_MERCADOPAGO}/v1/customers/search`,
        params: {
          access_token: ACCESS_TOKEN,
          email,
        },
      });
      // axios.get(`${URL_MERCADOPAGO}/v1/customers/search?access_token=${ACCESS_TOKEN}`).then(() => {
      //     console.log('yeah!')
      // })
      const customer = data.results.find((customer) => customer.email == email);
      return customer;
    },
    async GET_CARDS_BY_CUSTOMER({ commit }, customer_id) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_MERCADOPAGO}/v1/customers/${customer_id}/cards`,
        params: {
          access_token: ACCESS_TOKEN,
        },
      });
      commit("SET_CARDS", data);
    },
    async CREATE_CUSTOMER({ dispatch, rootState }) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_MERCADOPAGO}/v1/customers`,
          params: {
            access_token: ACCESS_TOKEN,
          },
          data: {
            email: rootState.user.email,
            first_name: rootState.user.nombre,
            last_name: "",
            phone: {
              area_code: "",
              number: "",
            },
            identification: {
              type: rootState.user.tipo_identificacion,
              number: rootState.user.identificacion,
            },
            address: {
              zip_code: "",
              street_name: "",
            },
            description: "",
          },
        });
        dispatch("UPDATE_CUSTOMER_ID_BY_KOMERCIA", data);
      } catch (err) {
        if (err.response.data.cause[0].code === "101") {
          const customer = await dispatch(
            "GET_CUSTOMER_BY_EMAIL",
            rootState.user.email
          );
          dispatch("UPDATE_CUSTOMER_ID_BY_KOMERCIA", customer);
        }
      }
    },
    async GET_CUSTOMER_ID_BY_KOMERCIA({ commit, rootState }) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_KOMERCIA_API}/api/usuario/mercado-libre/client`,
        headers: rootState.configAxios.headers,
      });
      commit("SET_CUSTOMER_ID_BY_KOMERCIA", data.data.mercado_libre_client);
      return data.data;
    },
    async UPDATE_CUSTOMER_ID_BY_KOMERCIA({ dispatch, rootState }, { id }) {
      axios({
        method: "POST",
        url: `${URL_KOMERCIA_API}/api/usuario/mercado-libre/client`,
        headers: rootState.configAxios.headers,
        data: {
          mercado_libre_client: id,
        },
      });
      dispatch("GET_CARDS_BY_CUSTOMER", id);
    },
    async SAVE_CARD_BY_CUSTOMER(
      { state, commit },
      { customer_id, card_token }
    ) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_MERCADOPAGO}/v1/customers/${customer_id}/cards`,
          params: {
            access_token: ACCESS_TOKEN,
          },
          data: {
            token: card_token,
          },
        });
        document.getElementById("pay").reset();
        if (data && data.response === null) {
          return { status: 400, error: data };
        }
        commit("SET_CARDS", [...state.cards, data]);
        return { status: 200, data: [...state.cards, data] };
      } catch (error) {
        return { status: 400, error: error.response.data };
      }
    },
    async DELETE_CARD({ commit }, { customer_id, card_id }) {
      await axios({
        method: "DELETE",
        url: `${URL_MERCADOPAGO}/v1/customers/${customer_id}/cards/${card_id}`,
        params: {
          access_token: ACCESS_TOKEN,
        },
      });
      commit("REMOVE_CARD", card_id);
    },
    CREATE_CARD_TOKEN({ dispatch }, payload) {
      Mercadopago.createToken(
        {
          cardId: payload.cardId,
          securityCode: payload.securityCode,
        },
        (status, response) => {
          dispatch("CREATE_PAYMENT", { ...payload, token: response.id });
        }
      );
    },
    async CREATE_PAYMENT({ rootState, commit }, payload) {
      const { data } = await axios({
        method: "POST",
        url: `${URL_KOMERCIA_API}/api/admin/envio-click/mercado-pago/confirmacion`,
        headers: rootState.configAxios.headers,
        data: payload,
      });
      commit("SET_PAYMENT_RESPONSE", data);
    },
  },
};
