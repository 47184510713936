<template>
  <div class="login-form">
    <div class="input-area">
      <p class="text-info mb-2">Correo electrónico</p>
      <el-input
        v-model="email"
        v-validate="'required'"
        class="inputs-idTienda light-input"
        placeholder="Ej. ashley@gmail.com"
        data-vv-name="correo electrónico"
        data-vv-scope="email"
      />
    </div>
    <div class="input-area">
      <p class="text-info mb-2">Contraseña</p>
      <el-input
        v-model="password"
        v-validate="'required'"
        class="inputs-idTienda light-input"
        placeholder="Ej. ABcd12345$"
        type="password"
        data-vv-name="contraseña"
        data-vv-scope="password"
      >
      </el-input>
    </div>
    <p v-if="authError" class="error">
      El correo o contraseña está incorrecto.
    </p>
    <div class="buttons mt-6">
      <el-button
        class="bg-purple-komercia-50 px-4 py-4 rounded-md text-gray-100"
        @click.native="getAuth"
      >
        {{ stateText ? "Ingresar a mipaquete.com" : "Integrar con Komercia" }}
      </el-button>
      <!-- TODO -->
      <!-- <el-button
        @click.native="$emit('changetab')"
        type="text"
        class="px-4 py-4 text-purple-komercia-500"
        >Crear cuenta</el-button
      > -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MiPaqueteLogin",
  data() {
    return {
      stateText: false,
      email: "",
      password: "",
      loading: false,
      authError: false,
    };
  },
  computed: {
    dataKomercia() {
      return this.$store.state.mipaquete.dataKomercia;
    },
  },
  watch: {
    email() {
      this.authError = false;
    },
    password() {
      this.authError = false;
    },
  },
  async created() {
    const { success, data } = await this.$store.dispatch(
      "mipaquete/GET_DATA_KOMERCIA"
    );
    if (success) {
      if (data && data.data) {
        this.email = data.data.username;
        this.password = data.data.pass;
        this.stateText = true;
      }
    }
  },
  methods: {
    async getAuth() {
      this.loading = true;
      const { success, data } = await this.$store.dispatch(
        "mipaquete/AUTHENTICATION",
        {
          email: this.email,
          password: this.password,
        }
      );
      if (success) {
        this.$store.dispatch("mipaquete/SET_DATA_KOMERCIA", {
          email: this.email,
          password: this.password,
          token: data.APIKey,
        });
        this.$store.commit("mipaquete/NEXT_STAGE");
        this.authError = false;
      } else {
        this.authError = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  .buttons {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
}
</style>
