import moment from "moment-timezone";

export default {
  methods: {
    formatDateMomentAWS(date) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedDate = moment
        .tz(date, "YYYY-MM-DDTHH:mm:ss.SSSZ", userTimeZone)
        .format("YYYY-MM-DD HH:mm:ss");
      return moment(formattedDate).fromNow();
    },
  },
};
