<template>
  <div class="pos">
    <ModalNewUser :show.sync="show" @onCreate="setCurrentCustomer" />
    <ModalPaymentMethods :visible.sync="visiblePaymentMethods" />
    <div class="pos__content" :class="darkMode ? 'dark' : 'light'">
      <div class="pos__content__header">
        <p
          class="flex items-center text-title mb-3"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          Venta rápida
          <sliderInformation :numberId="77" :actions="true" class="ml-2" />
        </p>
        <p class="text-infos mb-5" :class="darkMode ? 'darktxt' : 'lighttxt'">
          Aquí puedes tomar pedidos y crear ventas, asociar productos y
          compartir la venta con tu cliente, realizar pagos o llevar un registro
          de tu punto de venta.
        </p>
        <p class="text-info mb-1">Buscar producto</p>
        <el-input
          v-model="searchedProduct"
          placeholder="Buscar producto"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
          @input="buscar(searchedProduct)"
        >
          <i slot="prefix" class="el-input__icon fas el-icon-search" />
        </el-input>
      </div>
      <carousel
        class="carousel"
        :navigationEnabled="true"
        :paginationEnabled="false"
        :perPage="4"
      >
        <slide class="carousel__slide">
          <div
            :class="{
              header__categories_item: true,
              selected: !selectedCategory,
            }"
            @click="selectCategory({ id: 0 })"
          >
            <p>Todos</p>
          </div>
        </slide>
        <slide
          v-for="category in categoriesData"
          v-show="category.producto_count > 0"
          :key="`category_${category.id}`"
          class="carousel__slide"
        >
          <div
            :class="{
              header__categories_item: true,
              selected: category.id === selectedCategory,
            }"
            @click="selectCategory(category)"
          >
            <p>{{ category.nombre_categoria_producto.toLowerCase() }}</p>
          </div>
        </slide>
      </carousel>
      <draggable
        v-model="productsData"
        class="pos__content__products"
        :group="{ name: 'people', pull: 'clone', put: false }"
        :animation="100"
        :sort="false"
      >
        <template v-if="productsData">
          <ProductCard
            v-for="product in productsData"
            :key="`product_${product.id}`"
            :data="product"
            :inactive="false"
          />
        </template>
      </draggable>
      <div class="wrapper-pagination">
        <el-pagination
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="infoProductsData.last_page"
          :current-page="currentPage"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <POS-order @openModal="show = true" @onEnd="visiblePaymentMethods = true" />
  </div>
</template>

<script scoped>
import debounce from "debounce";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import { Carousel, Slide } from "vue-carousel";
import POSorder from "@/components/POS/order";
import ProductCard from "@/components/POS/productCard";
import ModalNewUser from "@/views/Customers/components/ModalNewUser";
import ModalPaymentMethods from "@/components/POS/modalPaymentMethods";
import sliderInformation from "@/components/information.vue"; // Ayuda
export default {
  name: "posIndex",
  components: {
    ProductCard,
    "POS-order": POSorder,
    draggable,
    ModalNewUser,
    Carousel,
    Slide,
    ModalPaymentMethods,
    sliderInformation,
  },
  data() {
    return {
      currentPage: 1,
      show: false,
      sizeColumns: 0,
      searchedProduct: null,
      selectedCategory: 0,
      visiblePaymentMethods: false,
      fuseOptions: {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["nombre", "marca", "descripcion"],
      },
    };
  },
  computed: {
    ...mapState(["categoriesData", "productsData", "darkMode"]),
    // allProducts() {
    //   return this.$store.state.productsData
    // },
    infoProductsData() {
      return this.$store.state.infoPaginationProducts;
    },
    // productsData: {
    //   get() {
    //     return this.$store.state.productsData.filter(product => {
    //       if (!product.activo) return false
    //       if (product.con_variante) {
    //         const arrCombinations = product.variantes
    //         let inventario = 0
    //         if (arrCombinations.length) {
    //           if (arrCombinations[0].combinaciones.length) {
    //             if (
    //               JSON.parse(arrCombinations[0].combinaciones[0].combinaciones)
    //                 .length
    //             ) {
    //               JSON.parse(
    //                 arrCombinations[0].combinaciones[0].combinaciones
    //               ).forEach(item => {
    //                 inventario += parseInt(item.unidades)
    //               })
    //             }
    //           }
    //         }
    //         return !!inventario
    //       }
    //       return true
    //     })
    //   },
    //   set(newValue) {
    //     this.$store.state.productsData = newValue
    //   },
    // },
    // filteredProducts() {
    //   return this.productsData.filter(
    //     product => this.selectedCategory == product.categoria_producto.id
    //   )
    // },
    // resultingProducts() {
    //   if (this.selectedCategory && !this.searchedProduct) {
    //     return this.filteredProducts
    //   } else if (this.selectedCategory && this.searchedProduct) {
    //     return this.searchingProducts.filter(
    //       product => this.selectedCategory == product.categoria_producto.id
    //     )
    //   } else if (!this.selectedCategory && this.searchedProduct) {
    //     return this.searchingProducts
    //   } else {
    //     return this.productsData
    //   }
    // },
    settingProducts() {
      return this.$store.getters.settingProducts;
    },
    validateAmount() {
      if (
        this.$store.state.infoPaginationProducts &&
        this.$store.state.infoPaginationProductsInactivos
      ) {
        return (
          this.$store.state.infoPaginationProducts.total +
            this.$store.state.infoPaginationProductsInactivos.total <
          this.settingProducts.amount
        );
      } else {
        return 0;
      }
    },
  },
  created() {
    this.$store.dispatch("PRODUCTS_INACTIVOS");
    this.currentChange(1);
    this.$store.dispatch("GET_CATEGORIES");
    // this.$store.dispatch('GET_CUSTOMERS')
  },
  // mounted() {
  //   setTimeout(() => {
  //     document.getElementById('tidio-chat').style.display = 'none'
  //   }, 1000)
  // },
  // watch: {
  //   searchedProduct(value) {
  //     this.$search(value, this.productsData, this.fuseOptions).then(results => {
  //       this.searchingProducts = results
  //     })
  //   },
  // },
  methods: {
    currentChange(page) {
      if (this.searchedProduct) {
        this.$store.dispatch("GET_PRODUCTS_BY_NAME", {
          search: this.searchedProduct,
          page: page,
        });
        this.currentPage = page;
      } else if (this.selectedCategory != 0) {
        this.$store.dispatch("GET_PRODUCTS_BY_CATEGORY", {
          idCategory: this.selectedCategory,
          page: page,
        });
        this.currentPage = page;
      } else {
        this.$store.dispatch("GET_PRODUCTS", { page, status: 1, per_page: 24 });
        this.currentPage = page;
      }
    },
    setCurrentCustomer(data) {
      this.$store.commit("pos/SET_CURRENT_CUSTOMER", data);
    },
    selectCategory(data) {
      this.searchedProduct = "";
      this.currentPage = 1;
      this.selectedCategory = data.id;
      this.currentChange(1);
    },
    checkMove() {
      return false;
    },
    buscar: debounce(function (e) {
      this.currentPage = 1;
      this.searchedProduct = e;
      this.selectedCategory = 0;
      if (this.validateAmount) {
        this.$store.dispatch("GET_PRODUCTS_BY_NAME", { search: e, page: 1 });
      } else {
        this.$swal({
          title: "Mejora tu plan",
          html: '<p>Ya superaste la cantidad de productos de tu plan, actualizate al plan <strong class="swal-bold">Premium</strong></p>',
          showCloseButton: true,
          confirmButtonText: "Aceptar",
          backdrop: "rgba(14,47,90,0.6)",
          icon: "warning",
        }).then((r) => {
          if (r.isConfirmed) {
            this.$router.push({ name: "Membresia" });
          }
        });
      }
    }, 300),
  },
};
</script>

<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 0px 0px 30px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  max-width: 400px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.pos {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  display: grid;
  grid-template-columns: 1fr 330px;
  /* align-content: center; */
  /* padding: 10px; */
  overflow: auto;
  background-color: var(--background);
}
.pos__content {
  width: 100%;
  display: grid;
  justify-items: center;
  align-content: start;
  overflow: auto;
}
.pos__content::-webkit-scrollbar-track {
  border-style: none;
  background-color: transparent;
}
.pos__content::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.pos__content::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 6px;
  margin-right: 5px;
}
.text-title {
  font-size: 18px;
  font-weight: 600;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.text-infos {
  font-size: 12px;
  font-weight: 500;
  /* color: #333; */
}
.pos__content__header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 5px 20px;
  margin: 25px 0px 15px 0px;
}
.carousel {
  width: calc(100% - 200px);
  margin-bottom: 12px;
}
.carousel__slide {
  display: grid;
  align-content: center;
  background-color: transparent;
  padding: 0px 5px;
  box-sizing: border-box;
}
.header__categories_item {
  width: 100%;
  min-height: 60px;
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.2s;
  text-transform: uppercase;
  border: 0;
}
.header__categories_item p {
  font-size: 12px;
  font-weight: bold;
}
.header__categories_item.selected {
  /* font-weight: bold; */
  box-shadow: inset 0 0 0 2px var(--green);
}
.pos__content__products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 230px);
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  padding: 0 10px;
}
.hidden {
  height: 0;
  opacity: 0;
}
.swiper-container {
  max-width: 800px;
  width: 100%;
  height: 100%;
  display: grid;
}
.swiper-slide {
  padding: 5px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.wrapper-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0 50px;
}
</style>
