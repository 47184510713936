<template>
  <div
    v-if="userData"
    class="contents wrapper-content-items"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div class="wrapper-box">
      <div class="box-bodyss" :class="darkMode ? 'dark' : 'light'">
        <h4
          class="title-section flex items-center"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_perfil_cambiarContraseña") }}
          <sliderInformation :numberId="98" :actions="true" class="ml-2" />
        </h4>
        <div class="w-full flex flex-col">
          <div class="input-area" style="max-width: 100%">
            <p
              class="text-info mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_perfil_contraseñaActuaal") }}
            </p>
            <el-input
              v-model="current_password"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              type="password"
              :placeholder="$t('text_perfil_contraseñaActuaal')"
            >
              <i slot="prefix" class="el-input__icon ti-lock"></i>
            </el-input>
          </div>
          <div class="content-grid">
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_nuevaContraseña") }}
              </p>
              <el-input
                v-model="new_password"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                type="password"
                :placeholder="$t('text_perfil_nuevaContraseña')"
              >
                <i slot="prefix" class="el-input__icon ti-lock"></i>
              </el-input>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_repiteContraseña") }}
              </p>
              <el-input
                v-model="confirmation_password"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                type="password"
                :placeholder="$t('text_perfil_repiteContraseña')"
              >
                <i slot="prefix" class="el-input__icon ti-lock"></i>
              </el-input>
            </div>
          </div>
        </div>
        <div class="actions">
          <el-button type="primary" class="pull-right" @click="savePassword">
            {{ $t("text_perfil_cambiarContraseña") }}
          </el-button>
          <el-button class="pull-right" @click="clearPassword">
            {{ $t("text_inventario_cancelar") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sliderInformation from "@/components/information.vue";
export default {
  name: "PasswordSettings",
  components: { sliderInformation },
  data() {
    return {
      codeCountryPhone: null,
      wpVisible: false,
      options: [
        {
          value: "+54",
          label: "+54   Argentina",
        },
        {
          value: "+55",
          label: "+55   Brasil",
        },
        {
          value: "+56",
          label: "+56 Chile",
        },
        {
          value: "+57",
          label: "+57   Colombia",
        },
        {
          value: "+593",
          label: "+593  Ecuador",
        },
        {
          value: "+34",
          label: "+34 España",
        },
        {
          value: "+1",
          label: "+1 Estados Unidos",
        },
        {
          value: "+52",
          label: "+52 México",
        },
        {
          value: "+51",
          label: "+51 Perú",
        },
        {
          value: "+1",
          label: "+1 Puerto Rico",
        },
        {
          value: "+1",
          label: "+1 República Dominicana",
        },
        {
          value: "+507",
          label: "+507 Panamá",
        },
      ],
      current_password: "",
      new_password: "",
      confirmation_password: "",
      user_info: [
        {
          apellido: "",
          barrio: "",
          birthday: "",
          direccion: "",
          genero: "",
          id_user: 0,
          reputacion: 0,
          telefono: "",
          visitas: 0,
        },
      ],
      placeholderDep: "text_empresa_departamento",
      placeholderCity: "text_perfil_ciudad",
    };
  },
  computed: {
    typesID() {
      return this.$store.state.identificationTypes.filter(
        (item) =>
          item.idCountry === this.storeData.informacion_tienda[0].paises_id
      );
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
    userData() {
      return this.$store.state.userData;
    },
    ciudadactual() {
      return this.userData.ciudad;
    },
    ciudades() {
      return this.$store.state.cities.filter(
        (ciudad) => ciudad.dep === this.userData.ciudad.departamento.id
      );
    },
    departamentoactual: {
      get() {
        return this.userData.ciudad.departamento.id;
      },
      set(newValue) {
        this.userData.ciudad.departamento.id = newValue;
      },
    },
    departments() {
      return this.$store.state.departments;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  created() {
    this.$store.dispatch("GET_USER_DATA").then(() => {
      this.userDataInfo();
    });
    this.$store.dispatch("GET_DEPARTMENTS");
    this.setPlaceholderDep();
    if (
      this.userData &&
      this.userData.user_info[0] &&
      this.userData.user_info[0].telefono == ""
    ) {
      this.wpVisible = true;
    }
  },
  methods: {
    showInputWP() {
      this.userData.user_info[0].telefono = "";
      this.wpVisible = true;
    },
    setCiudad() {
      let ciudadfinal = this.ciudades.find(
        (ciudadid) => ciudadid.id === this.userData.ciudad.nombre_ciu
      );
      this.userData.ciudad = ciudadfinal;
    },
    updateData() {
      let phone;
      if (this.codeCountryPhone == null) {
        phone = this.userData.user_info[0].telefono;
      } else {
        phone = this.codeCountryPhone + this.userData.user_info[0].telefono;
        this.userData.user_info[0].telefono = phone;
      }
      let params = {
        nombre: this.userData.nombre,
        email: this.userData.email,
        departamento: this.userData.ciudad.departamento.id,
        ciudad: this.userData.ciudad.id,
        telefono: phone,
        direccion: this.userData.user_info[0].direccion,
        tipo_identificacion: this.userData.tipo_identificacion,
        identificacion: this.userData.identificacion,
      };
      axios
        .post(
          `${this.$configKomercia.url}/api/usuario/actualizar`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.wpVisible = false;
          this.$message.success("Los datos se cambiaron correctamente");
        })
        .catch((error) => {
          if (error) {
            this.$notify.error({
              title: "Error",
              duration: 15000,
              message: Object.values(error.response.data.errores)[0][0],
            });
          }
        });
    },
    updateEmail() {
      this.$swal({
        title: "Cambiar correo electrónico",
        html:
          '<input id="swal-input1" class="swal2-input" placeholder="Ingrese el nuevo Email" type="email">' +
          '<input id="swal-input2" class="swal2-input" placeholder="Ingrese su contraseña" type="password">',
        showCancelButton: true,
        confirmButtonText: "Cambiar",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          let params = {
            new_email: document.getElementById("swal-input1").value,
            password_confirmation: document.getElementById("swal-input2").value,
          };
          try {
            const { data } = await axios.put(
              `${this.$configKomercia.url}/api/usuario/change-email`,
              params,
              this.$store.state.configAxios
            );
            if (data && data.estado == 200) {
              this.$swal(
                "Correo electrónico actualizado correctamente!",
                "",
                "success"
              );
              this.$store.dispatch("GET_USER_DATA").then(() => {
                this.userDataInfo();
              });
            }
            return data;
          } catch (err) {
            console.log(err);
            this.$swal(
              "Error al cambiar el email",
              "Puede tener el mismo email o la contraseña esta errónea",
              "error"
            );
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    savePassword() {
      let params = {
        last_password: this.current_password,
        password: this.new_password,
        password_confirmation: this.confirmation_password,
      };
      axios
        .post(
          `${this.$configKomercia.url}/api/usuario/cambiar-contrasena`,
          params,
          this.$store.state.configAxios
        )
        .then(() => {
          this.$message.success("La contraseña se cambio correctamente");
          this.clearPassword();
        })
        .catch((error) => {
          if (error) {
            this.$message.error("Vuelve a digitar la contraseña");
            this.clearPassword();
          }
        });
    },
    clearPassword() {
      this.current_password = "";
      this.new_password = "";
      this.confirmation_password = "";
    },
    uploadPhoto(event) {
      let params = new FormData();
      params.append("imagen", event.target.files[0]);
      axios
        .post(
          `${this.$configKomercia.url}/api/usuario/cambiar-foto`,
          params,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.userData.foto = response.data.data.foto;
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
        });
    },
    userDataInfo() {
      if (!this.userData.user_info.length) {
        this.userData.user_info = this.user_info;
      }
    },
    setPlaceholderDep() {
      if (
        this.storeData &&
        this.storeData.informacion_tienda &&
        this.storeData.informacion_tienda[0].paises_id
      ) {
        switch (this.storeData.informacion_tienda[0].paises_id) {
          case 1:
            this.placeholderDep = "text_empresa_departamento";
            break;
          case 2:
            this.placeholderDep = "text_empresa_estado";
            break;
          case 3:
            this.placeholderDep = "text_empresa_estado";
            break;
          case 6:
            this.placeholderDep = "text_empresa_region";
            break;
          case 7:
            this.placeholderDep = "text_empresa_region";
            break;
          case 8:
            this.placeholderDep = "text_empresa_municipios";
            break;
          case 9:
            this.placeholderDep = "text_empresa_departamento";
            break;
          case 10:
            this.placeholderDep = "text_empresa_provincia";
            this.placeholderCity = "text_empresa_distrito";
            break;
          default:
            this.placeholderDep = "text_empresa_departamento";
            this.placeholderCity = "text_perfil_ciudad";
        }
      }
    },
  },
};
</script>

<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  @apply bg-white;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
el-input {
  background-color: transparent;
}
.wrapper-box {
  position: relative;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 20px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.content_whatsapp_inputs {
  width: 100%;
  display: flex;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.content-wrapper {
  background-color: #eee;
}
.wrapper-content-items {
  width: 100%;
  /* padding: 30px 40px; */
  /* border-radius: 12px; */
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09); */
  @apply border;
}
.content {
  display: flex;
  min-width: 800px;
  justify-content: center;
}
.content-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.input-area {
  max-width: calc(50% - 20px);
  width: 100%;
  margin: 5px;
}
/* .input-area .el-input,
.input-area .quillWrapper,
.input-area .el-select {
  width: 100%;
} */
.upload {
  position: relative;
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}
.upload .hover {
  position: absolute;
  opacity: 0;
  border-color: #818592;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
}
.upload .hover i {
  color: #818592;
}
.upload .hover:hover {
  opacity: 1;
}
.upload img {
  max-width: 100%;
  max-height: 100%;
}
.upload div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px dashed #d8dce5;
}
.upload div i {
  font-size: 35px;
  color: #b1b5bc;
}
#uploadPhoto {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}
.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-top: 20px;
}
.title-section {
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 10px;
}
.user-photo {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 900px) {
  .input-area,
  .quillWrapper {
    max-width: 100%;
    width: 100%;
    margin: 5px;
  }
}
</style>
