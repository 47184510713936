<template>
  <div class="shipment_all">
    <div class="shipment_all--wrapper box" v-loading="loading">
      <div class="shipment_all__header">
        <img
          loading="lazy"
          src="@/assets/mipaquete/logo-mi-paquete-color.png"
          class="h-20"
        />
        <div class="header__actions">
          <el-popover
            placement="top"
            width="220"
            v-model="visible"
            v-if="dataKomercia"
          >
            <p>¿Estas seguro que quieres actualizar el token de conexión?</p>
            <br />
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="visible = false">
                cancelar
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="UpdateTokenConnection"
              >
                Actualizar
              </el-button>
            </div>
            <el-button slot="reference"> Actualizar token </el-button>
          </el-popover>

          <el-badge
            value="ACTIVO"
            class="item"
            type="success"
            v-if="this.dataMipaquete && this.dataMipaquete.state == 1"
          >
            <el-button
              class="bg-indigo-600 rounded-md px-2 py-3 font-semibold text-white text-xs ml-2"
              @click="doPackage"
            >
              Parámetros default
            </el-button>
          </el-badge>
          <el-badge value="INACTIVO" class="item" v-else>
            <el-button
              class="bg-indigo-600 rounded-md px-2 py-3 font-semibold text-white text-xs ml-2"
              @click="doPackage"
            >
              Parámetros default
            </el-button>
          </el-badge>
          <el-button
            class="bg-green-300 rounded-md px-2 py-3 font-semibold text-gray-900 text-xs ml-2"
            @click="doQuotation"
          >
            Nuevo Servicio
          </el-button>
        </div>
      </div>
      <div class="my-2">
        <p class="text-info">
          En caso de tener novedades logísticas puedes resolverlas directamente
          a través del Centro de Ayuda de mipaquete.com
          <a
            title="Ir a la pagina de mipaquete"
            target="_blank"
            href="https://app.mipaquete.com/"
            >Ingresa aquí.</a
          >
        </p>
      </div>
      <template v-if="services && services.length">
        <el-table :data="services" stripe lazy border style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="mx-5 my-5">
                <div class="mipaquete__services__item_details">
                  <h3><strong>ID:</strong> {{ props.row._id }}</h3>
                  <h3>
                    <strong>Transportadora:</strong>
                    {{ props.row.Transportadora }}
                  </h3>
                </div>
                <div class="mipaquete__services__item_details">
                  <div>
                    <h3><strong>ORIGEN</strong></h3>
                    <div class="ml-4">
                      <p><strong>Ciudad:</strong> {{ props.row["Origen"] }}</p>
                      <p>
                        <strong>Dirección:</strong>
                        {{ props.row["Dirección Remitente"] }}
                      </p>
                      <p>
                        <strong>Nombre:</strong>
                        {{ props.row["Nombre del remitente"] }}
                      </p>
                      <p>
                        <strong>Correo electrónico:</strong>
                        {{ props.row["Email del remitente"] }}
                      </p>
                      <p>
                        <strong>Celular:</strong>
                        {{ props.row["Celular del Remitente"] }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h3><strong>DESTINO</strong></h3>
                    <div class="ml-4">
                      <p><strong>Ciudad:</strong> {{ props.row["Destino"] }}</p>
                      <p>
                        <strong>Dirección:</strong>
                        {{ props.row["Dirección Destinatario"] }}
                      </p>
                      <p>
                        <strong>Nombre:</strong>
                        {{ props.row["Destinatario"] }}
                      </p>
                      <p>
                        <strong>Celular:</strong>
                        {{ props.row["Celular del destinatario"] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mipaquete__services__item_details">
                  <div>
                    <h3><strong>ENVÍO</strong></h3>
                    <div class="ml-4">
                      <p>
                        <strong>Peso (kg):</strong>
                        {{ props.row["Peso (kg)"] }}
                      </p>
                      <p v-if="props.row.large">
                        <strong>Largo (cm):</strong>
                        {{ props.row["large"] }}
                      </p>
                      <p v-if="props.row.width">
                        <strong>Ancho (cm):</strong>
                        {{ props.row["width"] }}
                      </p>
                      <p v-if="props.row.height">
                        <strong>Alto (cm):</strong>
                        {{ props.row["height"] }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h3>INFORMACIÓN DEL SERVICIO</h3>
                    <div class="ml-4">
                      <p>
                        <strong>Fecha de solicitud:</strong>
                        {{ props.row["Fecha de Solicitud"] }}
                        {{ props.row["Hora de Solicitud"] }}
                      </p>
                      <p>
                        <strong>Fecha de recogida:</strong>
                        {{ props.row["Fecha de Recogida"] }}
                      </p>
                      <p>
                        <strong>Valor total del servicio:</strong>
                        {{ props.row["Valor total del servicio"] }}
                      </p>
                      <p>
                        <strong
                          >Tiempo promesa de entrega en días hábiles:</strong
                        >
                        {{
                          props.row["Tiempo promesa de entrega en días hábiles"]
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Canal">
            <template slot-scope="scope">
              <div>
                <el-tag size="medium" class="tag-state" type="info">
                  {{ scope.row["Canal"] }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Estado">
            <template slot-scope="scope">
              <div>
                <el-tag size="medium" class="tag-state" type="info">
                  {{ scope.row["Estado actual del envío"] }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Cliente">
            <template slot-scope="scope">
              <p>
                {{ scope.row["Destinatario"] }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Guía" width="175">
            <template slot-scope="scope">
              <template v-if="scope.row.pdfGuide">
                <a
                  title="Descargar la guía de envió"
                  :href="item"
                  download
                  :key="item"
                  v-for="(item, index) in scope.row.pdfGuide"
                >
                  <el-button size="small" class="mr-2">
                    File {{ index + 1 }}
                  </el-button>
                </a>
              </template>
              <span v-else>No generada</span>
            </template>
          </el-table-column>
          <el-table-column label="Orden Relacionada" width="170">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.carritos_id"
                :to="`/ventas/listado/${scope.row.carritos_id}?success=true&codeMi=${scope.row['Código mipaquete']}`"
              >
                <el-button type="primary" class="btn--block">
                  Ver orden
                </el-button>
              </router-link>
              <p v-else>Sin relación</p>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="wrapper-pagination"
          v-if="this.mpServices && this.mpServices.totalItems > 9"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :total="this.mpServices.totalItems"
            :page-size="10"
            @current-change="currentChange"
            :current-page.sync="currentPage"
            class="pagination"
          ></el-pagination>
        </div>
      </template>
      <div v-else>No hay servicios</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "miPaqueteAll",
  async created() {
    this.loading = true;
    await this.$store.dispatch("mipaquete/GET_USER_INFORMATION");
    await this.$store.dispatch("mipaquete/GET_DEFAULT_DATA_KOMERCIA");
    await this.$store.dispatch("mipaquete/GET_SERVICES", 1);
  },
  mounted() {
    this.loading = false;
    // setInterval(() => {
    //   if (this.currentIndexPage == 1 || this.currentIndexStage == 1) {
    //     this.$store.dispatch('mipaquete/GET_SERVICES')
    //   } else {
    //     console.log('pageDi')
    //   }
    // // }, 120000)
    // this.UpdateTokenConnection()
  },
  data() {
    return {
      loading: false,
      visible: false,
      currentPage: 1,
      services: [],
      // currentIndexPage: this.$store.state.mipaquete.currentIndexStage,
    };
  },
  watch: {
    mpServices() {
      this.$store.dispatch("mipaquete/GET_SERVICES_KOMERCIA", 1);
    },
    servicesKomercia() {
      this.setServices();
    },
  },
  computed: {
    ...mapState({
      mpServices: (state) => state.mipaquete.services,
      servicesKomercia: (state) => state.mipaquete.servicesKomercia,
    }),
    dataKomercia() {
      return this.$store.state.mipaquete.dataKomercia;
    },
    dataMipaquete() {
      return this.$store.state.mipaquete.dataMipaquete;
    },
  },
  methods: {
    async setServices() {
      let services = [];
      this.mpServices.sendings.map((item) => {
        if (item["Canal"] == "komercia") {
          let komerciaItem = this.servicesKomercia.data.find((k) => {
            if (k.uuid_mipaquete == item["Código mipaquete"]) {
              return k;
            }
          });
          services.push({ ...item, ...komerciaItem });
        } else {
          services.push(item);
        }
      });
      this.services = services;
    },
    currentChange(page) {
      this.$store.dispatch("mipaquete/GET_SERVICES", page);
      this.$store.dispatch("mipaquete/GET_SERVICES_KOMERCIA", page);
      this.currentPage = page;
    },
    doQuotation() {
      this.$store.commit("mipaquete/NEXT_STAGE");
    },
    doPackage() {
      this.$store.commit("mipaquete/SET_INDEX_STAGE", 5);
    },
    async breakConnection() {},
    async UpdateTokenConnection() {
      const { success, data } = await this.$store.dispatch(
        "mipaquete/AUTHENTICATION",
        {
          email: this.dataKomercia.username,
          password: this.dataKomercia.pass,
        }
      );
      if (success) {
        const { success } = await this.$store.dispatch(
          "mipaquete/SET_DATA_KOMERCIA",
          {
            email: this.dataKomercia.username,
            password: this.dataKomercia.pass,
            token: data.APIKey,
          }
        );
        this.$store.commit("mipaquete/SET_INDEX_STAGE", 1);
        if (success) {
          setTimeout(location.reload(), 5000);
        }
      }
    },
  },
  filters: {
    currency(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.header__actions >>> .el-badge__content.is-fixed {
  transform: translateY(-50%) translateX(0%);
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.shipment_all {
  max-width: 1150px;
  min-width: 400px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px;
}
.shipment_all--wrapper {
  padding: 20px 35px;
}
.shipment_all__header {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__actions > button {
  margin-right: 5px;
}
.mipaquete__services__item_details {
  -moz-column-count: 2;
  -moz-column-gap: 10px;
  -webkit-column-count: 2;
  -webkit-column-gap: 10px;
  column-count: 2;
  column-gap: 10px;
}
.mipaquete__services__item_details p {
  line-height: 2;
}
h3 {
  line-height: 2.5;
}
.wrapper-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0 50px;
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
.btn--block {
  width: 100%;
}
</style>
