<!-- /* eslint-disable vue/valid-v-on */ -->
<template>
  <div class="quotation">
    <h2 class="flex items-center mb-2 text-title">
      Información por default para envíos automáticos
      <sliderInformation :numberId="87" :actions="true" class="ml-2" />
    </h2>
    <div class="mb-5">
      <p class="text-info">
        Esta información se usara para que los clientes hagan envíos automáticos
        directamente desde el checkout, los datos serán los parámetros de envió
        y sobre estos se calculara el envió, en caso de tener novedades
        logísticas puedes resolverlas directamente a través del Centro de Ayuda
        de mipaquete.com
        <a
          title="Ir a la pagina mipaquete"
          target="_blank"
          href="https://app.mipaquete.com/"
          >Ingresa aquí.</a
        >
      </p>
    </div>
    <el-row :gutter="30" class="quotation_form">
      <el-col :span="12">
        <div>
          <div v-if="stateBtn" class="input-area">
            <p class="text-info mb-2">Origen (Desde)</p>
            <p>
              {{ tempCity.locationName }} - {{ tempCity.departmentOrStateName }}
            </p>
            <el-button
              class="bg-purple-komercia-50 px-4 py-1.5 mr-0 rounded-md text-gray-100 mt-2"
              @click="stateBtn = !stateBtn"
            >
              Cambiar ciudad
            </el-button>
          </div>
          <div v-else class="input-area">
            <p class="text-info mb-2">Origen (Desde)</p>
            <el-select
              v-model="origin"
              class="inputs-idTienda light-input"
              filterable
              clearable
              remote
              placeholder="Escribe la ciudad de envío"
              style="display: flex"
              :remote-method="(query) => remoteMethod(query, 'originOptions')"
            >
              <el-option
                v-for="location in originOptions"
                :key="`city${location._id}`"
                :label="`${location.locationName} - ${location.departmentOrStateName}`"
                :value="location.locationCode"
              ></el-option>
            </el-select>
            <input
              v-model="origin"
              v-validate="'required'"
              type="hidden"
              data-vv-name="origin_city"
              data-vv-as="ciudad de origen"
            />
            <p class="error">{{ errors.first("origin_city") }}</p>
          </div>
        </div>
        <div>
          <p class="text-info mb-2 mt-5">Peso (kg)</p>
          <el-input-number
            v-model="weight"
            class="inputs-idTienda light-input"
            controls-position="right"
            :min="1"
            :max="999"
          />
          <input
            v-model="weight"
            v-validate="'required'"
            type="hidden"
            data-vv-name="weight"
            data-vv-as="peso del paquete"
          />
          <p class="error">
            {{ errors.first("weight") }}
          </p>
        </div>
        <el-row>
          <el-col :span="8">
            <p class="text-info mb-2 mt-5">Ancho (cm)</p>
            <el-input
              v-model="width"
              v-validate="'required'"
              class="inputs-idTienda light-input"
              placeholder="Centimetros"
              data-vv-name="width"
              data-vv-as="ancho del paquete"
            />
            <p class="error">
              {{ errors.first("width") }}
            </p>
          </el-col>
          <el-col :span="8">
            <p class="text-info mb-2 mt-5">Alto (cm)</p>
            <el-input
              v-model="height"
              v-validate="'required'"
              class="inputs-idTienda light-input"
              placeholder="Centimetros"
              data-vv-name="height"
              data-vv-as="alto"
            />
            <p class="error">
              {{ errors.first("height") }}
            </p>
          </el-col>
          <el-col :span="8">
            <p class="text-info mb-2 mt-5">Largo (cm)</p>
            <el-input
              v-model="large"
              v-validate="'required'"
              class="inputs-idTienda light-input"
              placeholder="Centimetros"
              data-vv-name="large"
              data-vv-as="largo"
            />
            <p class="error">
              {{ errors.first("large") }}
            </p>
          </el-col>
        </el-row>
        <div class="w-full">
          <p class="text-info mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
            ¿Deseas activar o desactivar envíos automáticos en el checkout?
          </p>
          <div class="radio-ckecked mt-4">
            <el-radio v-model="stateMipaquete" :label="0" border>{{
              $t("text_inventario_no")
            }}</el-radio>
            <el-radio v-model="stateMipaquete" :label="1" border>{{
              $t("text_inventario_si")
            }}</el-radio>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="w-full flex justify-center items-center">
          <img
            loading="lazy"
            src="https://www.mipaquete.com/images/services/almacenamiento.svg"
            alt="imagen_caja"
          />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30" type="flex" justify="end">
      <el-button @click.native="goBack">Volver</el-button>
      <el-button
        class="bg-purple-komercia-50 px-4 py-1.5 mr-0 rounded-md text-gray-100"
        @click.native="saveDefaultData"
      >
        Guardar
      </el-button>
    </el-row>
  </div>
</template>

<script>
import sliderInformation from "@/components/information.vue";
export default {
  components: { sliderInformation },
  $_veeValidate: {
    validator: "new", // inject a new validator to be used by the directives.
  },
  data() {
    return {
      defaultData: {},
      stateMipaquete: 0,
      weight: 1,
      width: null,
      height: null,
      large: null,
      origin: null,
      tempCity: null,
      originOptions: [],
      stateBtn: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: " ",
        precision: 0,
        masked: false,
      },
    };
  },
  computed: {
    dataMipaquete() {
      return this.$store.state.mipaquete.dataMipaquete;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
    state() {
      return this.$store.state.mipaquete.quotationState;
    },
    towns() {
      return this.$store.state.mipaquete.towns;
    },
    cities() {
      return this.$store.state.cities;
    },
    order() {
      if (this.$store.state.invoice && this.$store.state.invoice.venta) {
        return this.$store.state.invoice;
      } else {
        return "";
      }
    },
  },
  watch: {
    towns() {
      this.assingData();
    },
    dataMipaquete() {
      this.assingData();
    },
  },
  created() {
    this.$store.dispatch("mipaquete/GET_TOWN");
    if (this.dataMipaquete) {
      this.assingData();
    }
  },
  methods: {
    goBack() {
      this.$store.commit("mipaquete/SET_INDEX_STAGE", 1);
    },
    remoteMethod(query, optionsLabel) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      if (query !== "") {
        const timeoutDelay = 200;
        setTimeout(() => {
          this[optionsLabel] = this.towns.filter(({ locationName }) => {
            const normalizedLocationName = removeAccents(
              locationName.toLowerCase()
            );
            const normalizedQuery = removeAccents(query.toLowerCase());
            return normalizedLocationName.includes(normalizedQuery);
          });
        }, timeoutDelay);
      } else {
        this[optionsLabel] = [];
      }
    },
    async saveDefaultData() {
      // if (this.origin) {
      await this.$store.dispatch("mipaquete/CREATE_DEFAULT_DATA_KOMERCIA", {
        width: this.width,
        length: this.large,
        height: this.height,
        weight: this.weight,
        originCity: this.origin,
        state: this.stateMipaquete,
      });
      this.$swal("Información guardada correctamente!", "", "success");
      // }
    },
    assingData() {
      this.defaultData = this.dataMipaquete;
      this.weight = this.dataMipaquete.weight;
      this.width = this.dataMipaquete.width;
      this.height = this.dataMipaquete.height;
      this.large = this.dataMipaquete.length;
      this.stateMipaquete = this.dataMipaquete.state;
      this.tempCity = this.towns.find((city) => {
        if (city.locationCode == this.dataMipaquete.originCity) {
          this.stateBtn = true;
          return city;
        }
      });
      if (this.tempCity) {
        this.origin = this.tempCity.locationCode;
      }
    },
  },
};
</script>

<style scoped>
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.text-title {
  font-size: 18px;
  font-weight: 600;
}
.el-row {
  margin-bottom: 10px;
}
.title-input {
  margin-bottom: 7px;
}
.title-input > span {
  font-weight: 600;
}
.el-input-number {
  width: 100% !important;
}
.el-select {
  display: block !important;
}
.el-radio-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.quotation {
  padding: 20px 35px;
}
.quotation__couriers {
  display: grid;
  grid-gap: 5px;
  list-style: none;
}
.quotation__couriers__item {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 20px;
  border: 1px solid #dcdfe6;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.quotation__couriers__item img {
  width: 100%;
  height: 38px;
  object-fit: contain;
}
.quotation__couriers__item:hover {
  border: 1px solid var(--purple);
}
.quotation__couriers__item.selected {
  border: 2px solid var(--purple);
}
.opacity-0 {
  opacity: 0;
}
.error {
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #f14b5a;
}
.el-input__icon {
  line-height: 0 !important;
}
.msg__confirmation {
  width: 100%;
  display: flex;
}
.msg__confirmation p {
  word-break: break-word;
}
.msg__confirmation img {
  width: 50%;
}
</style>

<style scoped>
.quotation_form {
  border: 1px dashed #d8dde3;
  border-radius: 3px;
  padding: 15px;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.input-area {
  width: 100%;
}
.input-idTienda {
  padding: 12px 11px;
  border-radius: 10px;
  background-color: #f2efff;
  font-size: 13px;
  max-height: 40px;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-idTienda-valor >>> .el-input__inner {
  padding: 0px 25px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-dominio >>> .el-input__inner {
  font-size: 13px;
  border: none;
  background-color: transparent;
  transition: none;
  padding: 0px;
  height: 20px;
  max-height: 20px;

  @apply ml-2;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
</style>
