import Vue from "vue";
import VueI18n from "vue-i18n";
import index from "../i18n/index";
Vue.use(VueI18n);
const en = [index.en];
const es = [index.es];
const pt = [index.pt];
export default new VueI18n({
  locale: "es",
  messages: {
    es: Object.assign({}, ...es),
    en: Object.assign({}, ...en),
    pt: Object.assign({}, ...pt),
  },
});
