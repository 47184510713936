<template>
  <div class="wrapper-content-aside" :class="darkMode ? 'darkbg' : 'lightbg'">
    <div class="box_header">
      <h2 class="text-title mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_categorias") }}
      </h2>
      <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_textCrearOrganizaProductos") }}
      </p>
    </div>
    <div class="inputs_categories" :class="darkMode ? 'darkbg' : 'lightbg'">
      <div class="input-area-full">
        <p
          class="flex items-center text-subtitle"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_categorias") }}
          <sliderInformation :numberId="25" :actions="true" class="ml-2" />
        </p>
        <el-select
          v-model="data.detalle.categoria_producto.id"
          :placeholder="$t('text_inventario_seleccionaCategoria')"
          filterable
          clearable
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
          @change="clearSubcategorias()"
        >
          <el-option
            :label="$t('text_inventario_sinCategorias')"
            :value="0"
          ></el-option>
          <el-option
            v-for="category in categoriesData"
            :key="category.id"
            :label="category.nombre_categoria_producto"
            :value="category.id"
          ></el-option>
        </el-select>
      </div>
      <div class="input-area-full">
        <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_inventario_subCategorias") }}
          <!-- <i
            class="el-icon-question el-input__icon"
            slot="suffix"
            @click="handleHelp('Subcategorias', 'Subcategoria del producto')"
          ></i> -->
        </p>
        <el-select
          v-if="subcategories"
          v-model="data.detalle.subcategoria_producto.id"
          :placeholder="$t('text_inventario_seleccionaSubCategoria')"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
          filterable
          clearable
        >
          <el-option
            :label="$t('text_inventario_sinSubCategorias')"
            :value="0"
          ></el-option>
          <el-option
            v-for="subcategory in subcategories"
            :key="subcategory.id"
            :label="subcategory.nombre_subcategoria"
            :value="subcategory.id"
          ></el-option>
        </el-select>
      </div>
      <div class="mt-5 mb-5">
        <div
          class="content-btn content-btn-new cursor-pointer"
          @click="openModalCategory"
        >
          {{ $t("text_inventario_NuevaCategoria") }}
        </div>
      </div>
    </div>
    <!-- tag -->
    <div class="box_header">
      <h2
        class="flex items-center text-title mb-2"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        {{ $t("text_tags") }}
        <sliderInformation :numberId="88" :actions="true" class="ml-2" />
      </h2>
      <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
        {{ $t("text_inventario_textFiltrar") }}
      </p>
    </div>
    <div class="inputs_categories" :class="darkMode ? 'darkbg' : 'lightbg'">
      <div class="input-area-full">
        <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_etiquetas_grupoEtiquetas") }}
        </p>
        <el-select
          v-model="tag_select"
          :placeholder="$t('text_etiquetas_selectEtiquetas')"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
          filterable
          clearable
        >
          <el-option
            v-for="tag in tagsData"
            v-show="tag.status === 1 && tag.properties.length > 0"
            :key="tag.id"
            :label="tag.name"
            :value="tag.id"
          ></el-option>
        </el-select>
      </div>
      <div v-if="filterTag && filterTag.length > 0" class="input-area-full">
        <p class="text-subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_etiquetas_tittleEtiquetas") }}
        </p>
        <el-select
          v-if="filterTag"
          v-model="group_tag"
          placeholder="Selecciona una etiqueta"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
          filterable
          clearable
        >
          <!-- <el-option label="Sin etiquetas" value=""></el-option> -->
          <el-option
            v-for="tag in filterTag"
            v-show="tag.status === 1"
            :key="tag.id"
            :label="tag.name"
            :value="tag.id"
          ></el-option>
        </el-select>
      </div>
      <button
        class="content-btn content-btn-new mt-10 mb-5"
        @click="ValidateUpdateTag"
      >
        {{ $t("text_etiquetas_title") }}
      </button>
      <button
        class="content-btn content-btn-new2 mt-10 mb-5"
        @click="openModalEtiquetas"
      >
        {{ $t("text_etiquetas_newTag") }}
      </button>
      <div v-if="data && data.tags.length > 0" class="w-full my-3">
        <h2 class="text-title mb-2" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_etiquetas_assingedTag") }}
        </h2>
        <div class="flex flex-wrap gap-2 w-full">
          <div v-for="(tag, index) in data.tags" :key="index">
            <el-tag closable @close="deleteTag(tag)">
              {{ tag.property.tag_info.name }} - {{ tag.property.name }}
            </el-tag>
          </div>
        </div>
      </div>
    </div>
    <!-- tag -->
    <div class="box-body">
      <div class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_favoritos") }}
        </p>
        <el-checkbox
          v-model="data.detalle.favorito"
          :label="$t(label)"
          border
          true-label
          class="checkbox-idTienda"
          :class="darkMode ? 'darks-checkbox' : 'light-checkbox'"
          @change="changeFav()"
        >
        </el-checkbox>
      </div>
      <h2
        class="text-title mb-2 mt-3"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        Dimensiones
      </h2>
      <div class="row">
        <div>
          <div class="input-area-full">
            <p
              class="text-subtitle mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_garantia") }}
            </p>
            <el-input
              v-model="data.info[0].garantia"
              :placeholder="$t('text_inventario_garantiaProducto')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
            >
              <i
                slot="suffix"
                class="el-icon-question el-input__icon"
                @click="handleHelp('Garantia', 'Garantia del producto')"
              ></i>
            </el-input>
          </div>
          <div class="input-area-full">
            <p
              class="text-subtitle mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_marca") }}
            </p>
            <el-input
              v-model="data.info[0].marca"
              :placeholder="$t('text_inventario_marca')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
            >
              <i
                slot="suffix"
                class="el-icon-question el-input__icon"
                @click="handleHelp('Marca', 'Marca del producto')"
              ></i>
            </el-input>
          </div>
          <div class="input-area-full">
            <p
              class="text-subtitle mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_peso") }}
            </p>
            <el-input
              v-model="data.info[0].peso"
              :min="0"
              type="number"
              :placeholder="$t('text_inventario_peso')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
            >
              <i
                slot="suffix"
                class="el-icon-question el-input__icon"
                @click="handleHelp('Peso', 'Peso del producto (Kg)')"
              ></i>
            </el-input>
          </div>
        </div>
        <div>
          <div class="input-area-full">
            <p
              class="text-subtitle mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_largo") }}
            </p>
            <el-input
              v-model="data.info[0].largo"
              :min="0"
              type="number"
              :placeholder="$t('text_inventario_largo')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
            >
              <i
                slot="suffix"
                class="el-icon-question el-input__icon"
                @click="handleHelp('Dimensiones', 'Largo del producto (cm)')"
              ></i>
            </el-input>
          </div>
          <div class="input-area-full">
            <p
              class="text-subtitle mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_ancho") }}
            </p>
            <el-input
              v-model="data.info[0].ancho"
              :min="0"
              type="number"
              :placeholder="$t('text_inventario_ancho')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
            >
              <i
                slot="suffix"
                class="el-icon-question el-input__icon"
                @click="handleHelp('Dimensiones', 'Ancho del producto (cm)')"
              ></i>
            </el-input>
          </div>
          <div class="input-area-full">
            <p
              class="text-subtitle mb-2"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ $t("text_inventario_alto") }}
            </p>
            <el-input
              v-model="data.info[0].alto"
              :min="0"
              type="number"
              :placeholder="$t('text_inventario_alto')"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
            >
              <i
                slot="suffix"
                class="el-icon-question el-input__icon"
                @click="handleHelp('Dimensiones', 'Alto del producto (cm)')"
              ></i>
            </el-input>
          </div>
        </div>
      </div>
      <div v-if="isAsideComponent" class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_inventario_palabrasClave") }}
        </p>
        <el-input
          v-model="data.detalle.tag"
          :placeholder="$t('text_inventario_palabrasClave')"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
        >
          <i
            slot="suffix"
            class="el-icon-question el-input__icon"
            @click="handleHelp('Palabras clave', 'Palabras clave del producto')"
          ></i>
        </el-input>
      </div>
      <!-- <div class="input-area-full">
    <p class="input-label">Estado
      <i
        class="el-icon-question el-input__icon"
        slot="suffix"
        @click="handleHelp('Estado', 'Define si tu producto se encuentra disponible')">
      </i>
    </p>
    <el-select v-model="data.detalle.disponibilidad" placeholder="Selecciona el estado">
      <el-option label="Disponible" value="1"></el-option>
      <el-option label="Agotado" value="2"></el-option>
      <el-option label="Pre-Venta" value="3"></el-option>
      <el-option label="Por Encargo" value="4"></el-option>
    </el-select>
      </div>-->
    </div>
  </div>
</template>

<script>
import sliderInformation from "@/components/information.vue";
import { mapState } from "vuex";
import { bus } from "../editores/_items/helpers/bus";
export default {
  components: { sliderInformation },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isAsideComponent: Boolean,
  },
  data() {
    return {
      openModal: false,
      tag_select: null,
      group_tag: null,
      label: "",
    };
  },
  computed: {
    ...mapState([
      "darkMode",
      "categoriesData",
      "subcategoriesData",
      "tagsData",
    ]),
    subcategories() {
      return this.subcategoriesData.filter(
        (subcategory) =>
          subcategory.categoria === this.data.detalle.categoria_producto.id
      );
    },
    filterTag() {
      let result = null;
      if (this.tag_select) {
        this.tagsData.filter((item) => {
          if (item.id === this.tag_select) {
            result = item.properties;
          }
        });
      }
      return result;
    },
  },
  watch: {
    data() {
      this.changeFav();
    },
  },
  created() {
    if (this.data.detalle.favorito === 1) {
      this.data.detalle.favorito = true;
    } else if (this.data.detalle.favorito === 0) {
      this.data.detalle.favorito = false;
    }
    this.changeFav();
  },
  methods: {
    openModalCategory() {
      this.$store.state.modalCategory = true;
    },
    openModalEtiquetas() {
      this.$store.state.modalEtiquetas = true;
    },
    handleHelp(title, description) {
      this.$alert(description, title);
    },
    changeFav() {
      if (this.data.detalle.favorito) {
        this.label = "text_inventario_añadidoFavoritos";
      } else if (!this.data.detalle.favorito) {
        this.label = "text_inventario_añadirFavoritos";
      } else {
        this.label = "text_inventario_añadirFavoritos";
      }
    },
    clearSubcategorias() {
      this.data.detalle.subcategoria_producto.id = null;
    },
    ValidateUpdateTag() {
      if (this.data && this.data.tags.length > 0) {
        let item = this.data.tags.find((element) => {
          // if (element.tag_id === this.tag_select) {
          //   return true
          // } else
          if (element.tag_property_id === this.group_tag) {
            return true;
          } else {
            return false;
          }
        });
        if (item) {
          this.$message.warning(
            "Esta etiqueta ya esta asignado, por favor pruebe con otra"
          );
        } else {
          this.updateTag();
        }
      } else {
        this.updateTag();
      }
    },
    async updateTag() {
      if (this.tag_select && this.group_tag) {
        const { success } = await this.$store.dispatch("ADD_TAGS_PRODUCT", {
          product: this.data.detalle.id,
          tag_property: this.group_tag,
        });
        if (success) {
          bus.$emit("send-updateProduct", true);
          this.$message.success("etiqueta asignada correctamente!");
          // this.$swal('etiqueta asignada correctamente!', '', 'success')
          this.clearSelectTag();
          // this.$store.dispatch('GET_PRODUCT', this.$route.params.id)
        } else {
          this.$swal(
            "Información no fue actualizada!",
            "Vuelva a intentar",
            "error"
          );
        }
      } else {
        this.$swal(
          "Error al asignar etiquetas!",
          "Seleccione una etiqueta",
          "error"
        );
      }
    },
    async deleteTag(value) {
      const { success } = await this.$store.dispatch("DElETE_TAGS_PRODUCT", {
        productos_id: value.productos_id,
        tag_property_id: value.tag_property_id,
      });
      if (success) {
        bus.$emit("send-updateProduct", true);
        this.$message.success("etiqueta eliminada correctamente!");
        // this.$swal('etiqueta eliminada correctamente!', '', 'success')
        // this.$store.dispatch('GET_PRODUCT', this.$route.params.id)
      } else {
        this.$swal(
          "Información no fue actualizada!",
          "Vuelva a intentar",
          "error"
        );
      }
    },
    clearSelectTag() {
      this.tag_select = null;
      this.group_tag = null;
    },
  },
};
</script>

<style scoped>
.wrapper-content-aside {
  width: 100%;
  padding: 13px 0px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.text-title {
  font-size: 18px;
  font-weight: 600;
}
.text-subtitle {
  font-size: 13px;
  font-weight: 500;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.darktxts {
  background-color: #293d53;
  color: #fff;
}
.lighttxts {
  color: rgb(26, 46, 59);
}
.darkbgs {
  background-color: #1b2836;
}
.lightbgs {
  background-color: #fff;
}
.darkbg {
  background-color: #293d53;
}
.lightbg {
  background-color: #fff;
}
.box_header {
  padding: 10px 35px;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.checkbox-idTienda >>> .el-checkbox.is-bordered {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-checkbox >>> .el-checkbox {
  color: #f3f4f6;
}
.light-checkbox >>> .el-checkbox {
  color: #1f2937;
}
.inputs_categories {
  width: 100%;
  padding: 10px 35px;
  /* background-color: #f9fafb; */
  border-top: solid 1px #dcdfe6;
  border-bottom: solid 1px #dcdfe6;
}
.categories .el-button {
  margin-left: 5px;
}
.input-area-full {
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area-full .el-input,
.input-area-full .quillWrapper,
.input-area-full .el-select {
  width: 100%;
}
.row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 5fr;
  grid-gap: 10px;
}
.content-btn {
  margin: 5px;
  width: 100%;
  max-width: 329px;
  padding: 8px 0;
  text-align: center;
  border-radius: 10px;
}
.content-btn-new {
  color: white;
  background: #4429b4;
}
.content-btn-new:hover {
  color: #4429b4;
  background: #00dd8d;
}
.content-btn-new2 {
  color: #4429b4;
  background: transparent;
  border: 2px solid #4429b4;
}
.content-btn-new2:hover {
  color: #00dd8d;
  border: 2px solid #00dd8d;
}
.el-icon-star-on.is-checked {
  color: var(--yellow);
}
.el-icon-star-on {
  font-size: 20px;
}
@media (max-width: 767px) {
  .box_header {
    padding: 10px 10px;
  }
  .inputs_categories {
    padding: 10px 10px 30px 10px;
  }
  .input-area-full {
    max-width: unset;
    margin: 5px 0px;
  }
  .col-right .box-body {
    padding: 30px 10px;
  }
}
</style>
