const storelayot = {
  footer: {
    label: 'Pie de pagina 3',
    name: 'ko-footer-3',
    source:
      'http://res.cloudinary.com/komercia-store/image/upload/q_auto,f_auto/v1533768627/placeholder/footer-3.png',
  },
  header: {
    label: 'Encabezado 4',
    name: 'ko-header-4',
    source:
      'http://res.cloudinary.com/komercia-store/image/upload/q_auto,f_auto/v1533763880/placeholder/header-4.png',
  },
  pages: [
    {
      active: true,
      components: [
        {
          label: 'Banner 1',
          name: 'ko-slider-1',
          setting: {
            name: 'koSliderSetting1',
          },
          source:
            'https://create.shoplo.com/img/shoplov2/theme-builder/1020/banner-7.png',
        },
        {
          label: 'Separator 1',
          name: 'ko-separator-1',
          setting: {
            description: 'Descripción del separador',
            name: 'koSeparatorSetting1',
            title: 'Titulo',
          },
          source:
            'https://create.shoplo.com/img/shoplov2/theme-builder/1020/separator-1.jpg',
        },
        {
          label: 'Productos 1',
          name: 'ko-grid-1',
          source:
            'https://create.shoplo.com/img/shoplov2/theme-builder/220/grid-3-7.png',
        },
      ],
      value: 'home',
    },
    {
      active: true,
      components: [
        {
          label: 'Lista de productos 2',
          name: 'ko-product-list-2',
          source:
            'http://res.cloudinary.com/komercia-store/image/upload/q_auto,f_auto/v1531437422/placeholder/grid-4-10.jpg',
        },
      ],
      value: 'products',
    },
    {
      active: true,
      components: [
        {
          label: 'Producto 1',
          name: 'ko-product-1',
          source:
            'https://create.shoplo.com/img/shoplov2/theme-builder/1020/product-1.png',
        },
      ],
      value: 'product_details',
    },
    {
      active: true,
      components: [
        {
          label: 'Cart 1',
          name: 'ko-cart-1',
          source:
            'https://create.shoplo.com/img/shoplov2/theme-builder/1020/cart-2.png',
        },
      ],
      value: 'cart',
    },
    {
      active: true,
      components: [
        {
          label: 'Contacto 2',
          name: 'ko-contact-2',
          source:
            'http://res.cloudinary.com/komercia-store/image/upload/q_auto,f_auto/v1536763539/placeholder/contact2.png',
        },
      ],
      value: 'contact',
    },
  ],
  setting: {
    colors: [
      {
        hex: '#000000',
        title: 'Color Principal',
        var: '--main_color',
      },
      {
        hex: '#000000',
        title: 'Color de texto',
        var: '--text_color',
      },
      {
        hex: '#000000',
        title: 'Color de botones',
        var: '--button_color',
      },
      {
        hex: '#FFF',
        title: 'Texto de botones',
        var: '--button_text_color',
      },
      {
        hex: '#FFF',
        title: 'Color de fondo',
        var: '--background_color',
      },
    ],
    faviconURL: 'https://api.komercia.co/logos/logo_nuevas_tiendas.png',
    font: 'Open Sans',
  },
  store_id: 0,
}

export default storelayot
