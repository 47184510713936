<template>
  <div
    class="w-full rounded-xl px-[40px] py-[30px]"
    :class="darkMode ? 'bg-[#1b2836]' : 'bg-white'"
    style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09)"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: "generalBranch",
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  created() {
    this.$store.dispatch("GET_BRANCH");
  },
};
</script>
