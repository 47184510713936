import axios from "axios";
var axiosCrossDomain = axios;
delete axiosCrossDomain.defaults.headers.common["X-CSRF-TOKEN"];

const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA;
const URL_ENVIOCLICK = "https://api.envioclickpro.com.co/api";

export default {
  namespaced: true,
  state: {
    token: "empty",
    currentStep: 1,
    currentCarrier: {
      idRate: 0,
    },
    states: [
      {
        id: 0,
        state: "Sin Pagar",
        type: "warning",
      },
      {
        id: 1,
        state: "Pagada",
        type: "info",
      },
      {
        id: 2,
        state: "Procesando",
        type: "info",
      },
      {
        id: 3,
        state: "Cancelado",
        type: "danger",
      },
      {
        id: 4,
        state: "Rechazado",
        type: "danger",
      },
      {
        id: 5,
        state: "Error Envioclick",
        type: "danger",
      },
      {
        id: 6,
        state: "Enviado",
        type: "success",
      },
      {
        id: 7,
        state: "Entregado",
        type: "success",
      },
    ],
    quotationRequest: {
      package: {
        description: "",
        contentValue: null,
        weight: null,
        size: null,
        height: null,
        width: null,
      },
      originCode: null,
      originAddresse: null,
      originCity: null,
      originState: null,
      destinationCode: null,
      destinationAddress: null,
      destinationCity: null,
      destinationState: null,
      checked: false,
    },
    quotationResponse: null,
    shipmentRequest: {
      id_rate: null,
      my_shipment_reference: "",
      request_pickup: true,
      pickup_date: null,
      insurance: false,
      thermal_label: false,

      description: "",
      content_value: null,
      weight: null,
      length: null,
      height: null,
      width: null,

      origin_company: "",
      origin_first_name: "",
      origin_last_name: "",
      origin_email: "",
      origin_phone: "",
      origin_street: "",
      origin_number: "",
      origin_suburb: "",
      origin_cross_street: "",
      origin_reference: "",
      origin_code: "",
      origin_addresse: "",

      destination_company: "",
      destination_first_name: "",
      destination_last_name: "",
      destination_email: "",
      destination_phone: "",
      destination_street: "",
      destination_number: "",
      destination_suburb: "",
      destination_cross_street: "",
      destination_reference: "",
      destination_code: "",
      destination_address: "",
    },
    shipmentResponse: null,
    shipmentKomercia: null,
    shipments: null,
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_CURRENTSTEP(state, value) {
      state.currentStep = value;
    },
    SET_CURRENTCARRIER(state, value) {
      state.currentCarrier = value;
    },
    SET_QUOTATIONREQUEST(state, value) {
      state.quotationRequest = value;
    },
    SET_QUOTATIONRESPONSE(state, value) {
      state.quotationResponse = value;
    },
    SET_SHIPMENTREQUEST(state, value) {
      state.shipmentRequest = value;
    },
    SET_SHIPMENTRESPONSE(state, value) {
      state.shipmentResponse = value;
    },
    SET_SHIPMENT_KOMERCIA(state, value) {
      state.shipmentKomercia = value;
    },
    SET_SHIPMENTS(state, value) {
      state.shipments = value;
    },
  },
  actions: {
    async AUTHENTICATION({ rootState, commit }, token) {
      try {
        const { data } = await axios({
          method: "POST",
          headers: rootState.configAxios.headers,
          url: `${process.env.VUE_APP_URL_KOMERCIA}/api/admin/envio-click/credenciales`,
          data: {
            authorizationKey: token,
          },
        });
        commit("SET_TOKEN", token);
        return { success: true, data: data.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_TOKEN({ rootState, commit }) {
      try {
        const { data } = await axios({
          method: "GET",
          headers: rootState.configAxios.headers,
          url: `${process.env.VUE_APP_URL_KOMERCIA}/api/admin/envio-click/credenciales`,
        });
        commit("SET_TOKEN", data.data);
        return { success: true, data: data.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async DELETE_TOKEN({ rootState, commit }) {
      try {
        const { data } = await axios({
          method: "DELETE",
          headers: rootState.configAxios.headers,
          url: `${process.env.VUE_APP_URL_KOMERCIA}/api/admin/envio-click/credenciales/eliminar`,
        });
        commit("SET_TOKEN", null);
        return { success: true, data: data.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_SHIPMENTS({ commit, rootState }, page) {
      const { data } = await axios({
        method: "GET",
        headers: rootState.configAxios.headers,
        url: `${process.env.VUE_APP_URL_KOMERCIA}/api/admin/envio-click?page=${page}`,
      });
      commit("SET_SHIPMENTS", data.data);
    },
    async CREATE_QUOTATION({ commit, state }) {
      try {
        let payload = {
          ...state.quotationRequest,
          package: {
            contentValue: parseInt(state.quotationRequest.package.contentValue),
            weight: parseInt(state.quotationRequest.package.weight),
            length: parseInt(state.quotationRequest.package.size),
            height: parseInt(state.quotationRequest.package.height),
            width: parseInt(state.quotationRequest.package.width),
          },
        };
        const { data } = await axiosCrossDomain({
          method: "POST",
          url: `${URL_ENVIOCLICK}/v1/quotation`,
          data: payload,
          headers: {
            "content-type": "application/json",
            AuthorizationKey: state.token.authorizationKey,
          },
        });
        commit("SET_CURRENTCARRIER", data.data.rates[0]);
        commit("SET_QUOTATIONRESPONSE", data.data);
        return { success: true, data: data.data };
      } catch (err) {
        if (err.response) {
          return {
            success: false,
            status: err.response.status,
            data: err.response.data,
          };
        } else {
          return {
            success: false,
            status: 500,
            data: null,
          };
        }
      }
    },
    async CREATE_SHIPMENT({ state, commit, rootState }, { orderId }) {
      try {
        let shipment = state.shipmentRequest;
        shipment = {
          ...shipment,
          my_shipment_reference:
            shipment.my_shipment_reference >= 27
              ? `${shipment.my_shipment_reference.slice(0, 24)}...`
              : shipment.my_shipment_reference,
          description:
            shipment.description.length >= 20
              ? `${shipment.description.slice(0, 17)}...`
              : shipment.description,
          origin_cross_street: shipment.origin_cross_street.slice(0, 35),
          origin_street:
            shipment.origin_street.length >= 20
              ? `${shipment.origin_street.slice(0, 17)}...`
              : shipment.origin_street,
          origin_number: shipment.origin_number.slice(0, 5),
          destination_last_name: shipment.destination_last_name.slice(0, 14),
          destination_number: shipment.destination_number.slice(0, 5),
          destination_street: shipment.destination_street.slice(0, 29),
          destination_cross_street: shipment.destination_street.slice(0, 35),
          carrito: orderId,
        };
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/envio-click`,
          headers: rootState.configAxios.headers,
          data: shipment,
        });
        commit("SET_SHIPMENT_KOMERCIA", data.data);
        return { success: true, data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
  },
};
