<template>
  <div
    class="w-full lg:max-w-screen-lg xl:max-w-screen-xl rounded-xl"
    style="padding: 30px 20px"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div class="flex w-full justify-between items-center pb-10">
      <div>
        <h1
          class="font-semibold text-lg"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ $t("text_tiendaOnline_descuentos") }}
          <sliderInformation :numberId="70" :actions="true" class="ml-2" />
        </h1>
        <p :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_tiendaOnline_descuentos_tittle") }}
        </p>
      </div>
      <button
        style="max-height: 40px"
        type="button"
        class="inline-flex w-48 justify-center max-w-xl items-center px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-100 bg-indigo-700 hover:bg-indigo-200"
        @click="newDescuentos()"
      >
        {{ $t("text_descuentos_crearDescuento") }}
      </button>
    </div>
    <div v-show="showForm" class="modal">
      <div class="wrapper-items-form">
        <div
          ref="form"
          class="content-form"
          :class="darkMode ? 'darks' : 'lights'"
        >
          <div>
            <div class="title" :class="darkMode ? 'darktxt' : 'lighttxt'">
              {{
                currentDescuentos == -1
                  ? `${$t("text_descuentos_crearDescuento")}`
                  : `${$t("text_descuentos_editarDescuento")}`
              }}
            </div>
            <div class="w-full grid grid-cols-2 gap-1 mb-3">
              <el-radio v-model="descuentos.tipo" :label="0" border>
                Cantidad de productos
              </el-radio>
              <el-radio v-model="descuentos.tipo" :label="1" border>
                Rango de precios
              </el-radio>
            </div>
            <p class="mb-3 text-base">
              {{
                descuentos.tipo == 0
                  ? "Descuento por cantidad de productos"
                  : "Descuento por rango de precios"
              }}
            </p>
            <div class="mb-2">
              <el-input
                v-model="descuentos.nombre"
                class="inputs-idTienda input-name"
                :class="darkMode ? 'darks-input' : 'light-input'"
                placeholder="Nombre del descuento"
              ></el-input>
            </div>
            <div v-if="descuentos.tipo == 0" class="items-form">
              <el-select
                v-model="descuentos.opcion"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                placeholder="Seleccionar"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-input
                ref="switcher"
                v-model="
                  descuentos[
                    descuentos && descuentos.opcion == 1
                      ? 'valor_descuento'
                      : 'porcentaje_descuento'
                  ]
                "
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                :placeholder="
                  descuentos && descuentos.opcion == 1
                    ? `${$t('text_cupones_valor')}`
                    : `${$t('text_cupones_porcentaje')}`
                "
                :prefix-icon="
                  descuentos && descuentos.opcion == 1
                    ? 'fa fa-dollar-sign'
                    : 'fas fa-percentage'
                "
                min="0"
                requerid
              >
              </el-input>
              <div>
                <el-input
                  v-model="descuentos.cantidad_productos"
                  class="inputs-idTienda input-name"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  :placeholder="$t('text_descuentos_cantidadProductos')"
                  requerid
                ></el-input>
              </div>
              <div class="wrapper-switch">
                <span class="switcher switcher-1">
                  <input
                    id="switcher-1"
                    ref="switcher"
                    v-model="descuentos.estado"
                    type="checkbox"
                  />
                  <label for="switcher-1"></label>
                </span>
              </div>
            </div>
            <div v-else-if="descuentos.tipo == 1">
              <div class="wrapper-ranges">
                <div
                  v-for="(range, index) in priceRanges"
                  :key="`range_${index}`"
                  class="range item-grid mb-3"
                >
                  <div class="input-area">
                    <p :class="darkMode ? 'darktxt' : 'lighttxt'">Desde</p>
                    <div class="el-input is-disabled">
                      <money
                        v-model="range.inicial"
                        v-bind="money"
                        class="money-idTienda"
                        :class="darkMode ? 'darks-money' : 'light-money'"
                      ></money>
                    </div>
                    <!-- <div class="el-input" v-else>
                      <money
                        v-model="range.inicial"
                        v-bind="money"
                        class="money-idTienda"
                        :class="
                          darkMode  ? 'darks-money' : 'light-money'
                        "
                      ></money>
                    </div> -->
                  </div>
                  <div class="input-area">
                    <p :class="darkMode ? 'darktxt' : 'lighttxt'">Hasta</p>
                    <div class="el-input is-disabled">
                      <money
                        v-model="range.final"
                        v-bind="money"
                        class="money-idTienda"
                        :class="darkMode ? 'darks-money' : 'light-money'"
                      ></money>
                    </div>
                    <!-- <div class="el-input" v-else>
                      <money
                        v-model="range.final"
                        v-bind="money"
                        class="money-idTienda"
                        :class="
                          darkMode  ? 'darks-money' : 'light-money'
                        "
                      ></money>
                    </div> -->
                  </div>
                  <div class="input-area">
                    <p :class="darkMode ? 'darktxt' : 'lighttxt'">Tipo</p>
                    <el-select
                      v-model="range.options"
                      class="inputs-idTienda"
                      :class="darkMode ? 'darks-input' : 'light-input'"
                      placeholder=""
                    >
                      <el-option
                        v-for="item in Rangeoptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="input-area">
                    <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                      {{ range.options == 0 ? "Porcentaje" : "Valor" }}
                    </p>
                    <div class="el-input">
                      <el-input
                        v-if="range.options == 0"
                        v-model="range.precio"
                        class="inputs-idTienda"
                        :class="darkMode ? 'darks-input' : 'light-input'"
                        placeholder=""
                        prefix-icon="fas fa-percentage"
                      ></el-input>
                      <money
                        v-else
                        v-model="range.precio"
                        v-bind="money"
                        class="money-idTienda"
                        :class="darkMode ? 'darks-money' : 'light-money'"
                      />
                    </div>
                  </div>
                  <el-button
                    class="flex justify-center"
                    type="danger"
                    icon="el-icon-delete"
                    @click="deletePriceRange(index)"
                  ></el-button>
                </div>
                <div class="items-form">
                  <el-button
                    class="pull-right"
                    plain
                    type="primary"
                    @click="addPriceRange"
                  >
                    <i class="el-icon-circle-plus-outline" />
                    {{ $t("text_envios_agregarRango") }}
                  </el-button>
                  <div class="wrapper-switch">
                    <span class="switcher switcher-1">
                      <input
                        id="switcher-1"
                        ref="switcher"
                        v-model="descuentos.estado"
                        type="checkbox"
                      />
                      <label for="switcher-1"></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="items-form">
              <el-button type="primary" class="btn" @click="submitForm()">
                {{
                  currentDescuentos == -1
                    ? `${$t("text_inventario_guardar")}`
                    : `${$t("text_cupones_actualizar")}`
                }}
              </el-button>
              <button
                v-if="currentDescuentos != -1"
                type="text"
                :disabled="currentDescuentos == -1"
                class="btn btn-remove"
                @click="openDialog()"
              >
                <i class="el-icon-delete"></i>
                {{ $t("text_inventario_elimnar") }}
              </button>
            </div>
          </div>
          <div class="w-full flex justify-center text-center">
            <p
              class="mt-2 text-black underline cursor-pointer"
              @click="closeDialog()"
            >
              {{ $t("text_cupones_cerrarVentana") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div :class="darkMode ? 'darks-tablet' : 'light-tablet'">
      <el-table
        stripe
        lazy
        border
        :data="listDescuentosData"
        style="width: 100%"
        :fit="true"
        highlight-current-row
        :default-sort="{ prop: 'fecha', order: 'descending' }"
      >
        <el-table-column label="Descuento" width="120">
          <template slot-scope="scope">
            <div class="text-base">
              {{ scope.row.tipo == 0 ? "Producto" : "Precio" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nombre" label="Nombre descuento" width="160">
        </el-table-column>
        <el-table-column :label="$t('text_cupones_valorDescuento')" width="160">
          <template slot-scope="scope">
            <div v-if="scope.row.tipo == 0" class="w-full flex justify-start">
              <div v-if="scope.row.opcion == 1" class="text-sm">
                <i class="fa fa-dollar-sign"></i>
                {{ scope.row.valor_descuento | currency }}
                <br /><span class="text-xs font-medium text-gray-500 -mt-4">
                  {{ $t("text_cupones_porValor") }}
                </span>
              </div>
              <div v-else-if="scope.row.opcion == 0" class="text-sm">
                {{ scope.row.porcentaje_descuento }}
                <i class="fas fa-percentage"></i>
                <br /><span class="text-xs font-medium text-gray-500 -mt-4">
                  {{ $t("text_cupones_porcentaje") }}
                </span>
              </div>
            </div>
            <div v-else-if="scope.row.tipo == 1" class="w-full">
              <div class="text-sm">
                <p>Rango de precios</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_ventas_estado')" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.estado == 1" class="state active">
              {{ $t("text_cupones_activo") }}
            </div>
            <div v-else class="state inactive">
              {{ $t("text_cupones_inactivo") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Tipo" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.tipo == 0">
              <div class="text-sm">
                {{ scope.row.opcion == 0 ? "Porcentaje" : "Valor" }}
              </div>
            </div>
            <div v-else-if="scope.row.tipo == 1">
              <div class="text-sm">Rango</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_cupones_creacion')">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <b style="margin-left: 10px">{{
              formatCalendarMoment(scope.row.created_at)
            }}</b
            ><br />
            <p style="margin-left: 24px; font-size: 11px; margin-top: -6px">
              {{ formatDateMoment(scope.row.created_at) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('text_integraciones_actualizacion')">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <b style="margin-left: 10px">
              {{ formatCalendarMoment(scope.row.updated_at) }} </b
            ><br />
            <p style="margin-left: 24px; font-size: 11px; margin-top: -6px">
              {{ formatDateMoment(scope.row.updated_at) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="" width="90">
          <template slot-scope="scope">
            <div class="w-full flex items-center justify-center">
              <el-button
                size="mini"
                round
                type="primary"
                plain
                @click="selectDescuento(scope.row)"
              >
                {{ $t("text_inventario_editar") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="listDescuentos && listDescuentos.total > 10"
        class="w-full flex justify-center items-center mt-3"
      >
        <el-pagination
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="listDescuentos.last_page"
          :current-page="listDescuentos.current_page"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMoment from "../../mixins/formatDateMoment";
import formatCalendarMoment from "../../mixins/formatCalendarMoment";
import setFormatMoney from "../../mixins/formatCurrent";
import sliderInformation from "@/components/information.vue";
import axios from "axios";
export default {
  name: "listDescuentos",
  filters: {
    currency(value) {
      if (value) {
        return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
  mixins: [formatDateMoment, formatCalendarMoment, setFormatMoney],
  components: { sliderInformation },
  data() {
    return {
      showForm: false,
      currentPage: 1,
      descuentos: {
        porcentaje_descuento: null,
        valor_descuento: null,
        cantidad_productos: null,
        tipo: 0, // 0 Productos - 1 Precio
        opcion: 0, // 0 Porcentaje - 1 Valor
        estado: true, // 0 False - 1 True
        rangos_precios: "",
        nombre: "",
      },
      options: [
        {
          id: 0,
          name: "Porcentaje",
        },
        {
          id: 1,
          name: "Valor",
        },
      ],
      Rangeoptions: [
        {
          id: 0,
          name: "%",
        },
        {
          id: 1,
          name: "$",
        },
      ],
      currentDescuentos: -1,
      listDescuentos: [],
      listDescuentosData: [],
      baseDescuentos: {
        porcentaje_descuento: null,
        valor_descuento: null,
        cantidad_productos: null,
        tipo: 0,
        opcion: 0,
        estado: true,
        rangos_precios: "",
        nombre: "",
      },
      priceRanges: [
        {
          inicial: 0,
          final: 0,
          precio: 0,
          options: 0,
        },
      ],
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  watch: {
    storeData() {
      this.currentChange(this.currentPage);
      this.setFormatMoney(this.idCountryStore);
    },
  },
  created() {
    this.currentChange(this.currentPage);
    this.setFormatMoney(this.idCountryStore);
  },
  methods: {
    closeDialog() {
      this.showForm = false;
      this.currentDescuentos = -1;
      this.priceRanges = [{ inicial: 0, final: 0, precio: 0, options: 0 }];
    },
    addPriceRange() {
      let ifDoesntExist = { inicial: 0, final: 0, precio: 0, options: 0 };
      let lastRange =
        this.priceRanges.slice(this.priceRanges.length - 1)[0] || ifDoesntExist;
      this.priceRanges.push({
        inicial: lastRange.final + 1,
        final: lastRange.final + 2,
        precio: 0,
        options: 0,
      });
    },
    deletePriceRange(index) {
      this.priceRanges.splice(index, 1);
    },
    submitForm() {
      //Editar descuento
      if (this.descuentos.id) {
        //producto
        if (this.descuentos.tipo == 0) {
          this.descuentos.rangos_precios = "";
          if (this.descuentos.opcion == 0) {
            if (
              this.descuentos.porcentaje_descuento &&
              this.descuentos.porcentaje_descuento > 0
            ) {
              this.updateDescuento(this.descuentos.id);
            } else {
              this.$message({
                message: "El porcentaje tiene que ser mayores que cero.",
                type: "error",
              });
            }
          } else if (this.descuentos.opcion == 1) {
            if (
              this.descuentos.valor_descuento &&
              this.descuentos.valor_descuento > 0
            ) {
              this.updateDescuento(this.descuentos.id);
            } else {
              this.$message({
                message: "El valor tiene que ser mayores que cero.",
                type: "error",
              });
            }
          }
        }
        //Precio
        else if (this.descuentos.tipo == 1) {
          this.descuentos.rangos_precios = this.priceRanges;
          if (
            this.descuentos.rangos_precios &&
            this.descuentos.rangos_precios !== "[object Object]"
          ) {
            this.updateDescuento(this.descuentos.id);
          } else {
            this.$message({
              message: "Ingresar rangos de precios.",
              type: "error",
            });
          }
        }
      }
      //crear descuento
      else {
        //producto
        if (this.descuentos.tipo == 0) {
          if (this.descuentos.opcion == 0) {
            if (
              this.descuentos.porcentaje_descuento &&
              this.descuentos.porcentaje_descuento > 0
            ) {
              this.createDescuento();
            } else {
              this.$message({
                message: "El porcentaje tiene que ser mayores que cero.",
                type: "error",
              });
            }
          } else if (this.descuentos.opcion == 1) {
            if (
              this.descuentos.valor_descuento &&
              this.descuentos.valor_descuento > 0
            ) {
              this.createDescuento();
            } else {
              this.$message({
                message: "El valor tiene que ser mayores que cero.",
                type: "error",
              });
            }
          }
        }
        //Precio
        else if (this.descuentos.tipo == 1) {
          this.descuentos.rangos_precios = this.priceRanges;
          if (
            this.descuentos.rangos_precios &&
            this.descuentos.rangos_precios !== "[object Object]"
          ) {
            this.createDescuento();
          } else {
            this.$message({
              message: "Ingresar rangos de precios.",
              type: "error",
            });
          }
        }
      }
    },
    createDescuento() {
      this.$store
        .dispatch("CREATE_DESCUENTOS", this.descuentos)
        .then((res) => {
          this.getDescuentos(1);
          this.$message({
            message: res.data.mensaje,
            type: "success",
          });
          this.descuentos = this.baseDescuentos;
          this.currentDescuentos = -1;
          this.showForm = false;
          this.priceRanges = [{ inicial: 0, final: 0, precio: 0, options: 0 }];
        })
        .catch((error) => {
          for (const property in error.response.data.errores) {
            setTimeout(() => {
              let e = error.response.data.errores[property];
              this.$message.error(`${e}`);
            }, 100);
          }
        });
    },
    selectDescuento(item) {
      this.showForm = true;
      this.descuentos = item;
      this.currentDescuentos = item;
      if (item.rangos_precios && item.rangos_precios !== "[object Object]") {
        let temp = JSON.parse(item.rangos_precios);
        if (temp.indexOf("") == -1) {
          this.priceRanges = temp;
        }
      }
    },
    currentChange(page) {
      this.getDescuentos(page);
      this.currentPage = page;
    },
    getDescuentos(page) {
      if (this.storeData.id) {
        axios
          .get(
            `${this.$store.state.configKomercia.url}/api/descuentos/${this.storeData.id}?page=${page}`,
            this.$store.state.configAxios
          )
          .then((response) => {
            this.listDescuentos = response.data.descuentos;
            this.listDescuentosData = this.listDescuentos.data.sort(function (
              prev,
              next
            ) {
              return prev.cantidad_productos - next.cantidad_productos;
            });
          });
      }
    },
    updateDescuento(id) {
      axios
        .put(
          `${this.$store.state.configKomercia.url}/api/admin/descuentos/${id}`,
          this.descuentos,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.getDescuentos(1);
          this.$message({
            message: response.data.mensaje,
            type: "success",
          });
          this.descuentos = this.baseDescuentos;
          this.currentDescuentos = -1;
          this.showForm = false;
          this.priceRanges = [{ inicial: 0, final: 0, precio: 0, options: 0 }];
        });
    },
    newDescuentos() {
      this.showForm = true;
      let descuentos = {
        porcentaje_descuento: null,
        valor_descuento: null,
        cantidad_productos: null,
        tipo: 0,
        opcion: 0,
        estado: true,
        rangos_precios: "",
        nombre: "",
      };
      let el = this.$refs.form;
      el.classList.toggle("pulse");
      setTimeout(() => {
        el.classList.toggle("pulse");
      }, 2000);
      this.descuentos = descuentos;
      this.currentDescuentos = -1;
      this.priceRanges = [{ inicial: 0, final: 0, precio: 0, options: 0 }];
    },
    removeDescuento() {
      return axios
        .delete(
          `${this.$store.state.configKomercia.url}/api/admin/descuentos/${this.descuentos.id}`,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.getDescuentos(1);
          this.$message({
            message: response.data.mensaje,
            type: "success",
          });
          this.descuentos = this.baseDescuentos;
          this.currentDescuentos = -1;
          this.showForm = false;
          this.priceRanges = [{ inicial: 0, final: 0, precio: 0, options: 0 }];
        });
    },
    openDialog() {
      this.$confirm(`Desea eliminar el descuento?`, "Warning", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }).then(() => {
        this.removeDescuento();
      });
    },
  },
};
</script>

<style scoped>
.inputs-idTienda >>> .el-input__inner {
  /* padding: 5px 10px; */
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.darks-tablet >>> .el-table th.el-table__cell {
  background-color: #344a63;
}
.darks-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.darks-tablet >>> .el-table tr {
  background-color: #344a63;
}
.darks-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #344a63;
}
.darks-tablet >>> .el-table .cell {
  color: white;
}
.darks-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.darks-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.darks-tablet
  >>> .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #293c52;
}
.darks-tablet >>> .el-table__body tr.hover-row.current-row > td.el-table__cell,
.darks-tablet
  >>> .el-table__body
  tr.hover-row.el-table__row--striped.current-row
  > td.el-table__cell,
.darks-tablet
  >>> .el-table__body
  tr.hover-row.el-table__row--striped
  > td.el-table__cell,
.darks-tablet >>> .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #293c52;
}
.light-tablet >>> .el-table th.el-table__cell {
  background-color: white;
}
.light-tablet >>> .el-table--border,
.el-table--group {
  border: 1px solid #ebeef5;
}
.light-tablet >>> .el-table tr {
  background-color: white;
}
.light-tablet
  >>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: white;
}
.light-tablet >>> .el-table .cell {
  color: black;
}
.light-tablet >>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #ebeef5 !important;
}
.light-tablet >>> .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ebeef5;
}
.inputs-idTienda >>> .el-input__inner {
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.money-idTienda >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  height: 40px;
  max-width: 158px;
  width: 158px;
}
.darks-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #f3f4f6;
}
.light-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #1f2937;
}
.modal {
  padding-top: 200px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @apply w-full h-full fixed z-10 left-0 top-0 overflow-auto;
}
.wrapper-items-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-form {
  width: 100%;
  max-width: 700px;
  padding: 40px 40px 15px 40px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
.items-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
}
.wrapper-ranges {
  display: flex;
  flex-direction: column;
}
.item-grid {
  width: 100%;
  display: grid;
  justify-content: flex-start;
  grid-template-columns: 160px 160px 60px 158px 20px;
  grid-gap: 10px;
}

.range {
  align-items: flex-end;
}
/* .range div:nth-child(1) {
  max-width: 40px;
} */
</style>
<style lang="scss" scoped>
$grey: #cccccc;
$white: #ffffff;

.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.darktxts {
  color: #5d38f1;
}
.lighttxts {
  color: #4429b4;
}
.content-cupones {
  display: grid;
  grid-template-columns: minmax(380px, 410px) auto;
  padding: 40px;
  height: 80vh;
  gap: 40px;
}
.wrapper-form {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.form {
  // box-shadow: 0 0 5px 5px hsla(224, 47%, 80%, 0.158);
  padding: 40px 40px 15px 40px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
  // margin-top: -30%;
  background-color: #fff;
}
.pulse {
  animation: shadow-pulse 1s infinite;
}
.title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
  grid-column: 1 / span 2;
  font-size: 1.2rem;
}
.input-name {
  grid-column: 1 / span 2;
}
.wrapper-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
span.switcher {
  position: relative;

  width: 100%;
  height: 40px;
  border-radius: 4px;
  input {
    appearance: none;

    position: relative;

    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;

    background-color: var(--purple);
    outline: none;

    font-family: "Oswald", sans-serif;
    &:before,
    &:after {
      z-index: 2;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      color: $white;
    }
    &:before {
      content: "ACTIVO";
      left: 13px;
    }
    &:after {
      content: "INACTIVO";
      right: 11px;
    }
  }
  label {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;

    border-radius: 4px;
  }
  &.switcher-1 {
    input {
      cursor: pointer;
      transition: 0.25s -0.1s;
      &:checked {
        background-color: $white;
        &:before {
          color: #2f8c4e;
          transition: color 0.5s 0.2s;
        }
        &:after {
          color: $grey;
          transition: color 0.5s;
        }
        & + label {
          left: 0;
          right: 83px;

          background: var(--green);

          transition: left 0.5s, right 0.4s 0.2s;
        }
      }
      &:not(:checked) {
        background: #f2f6f9;
        transition: background 0.5s -0.1s;
        &:before {
          color: $grey;
          transition: color 0.5s;
        }
        &:after {
          color: #fff;
          transition: color 0.5s 0.2s;
        }
        & + label {
          left: 70px;
          right: 0;

          background: var(--darktwo);

          transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
        }
      }
    }
  }
}
.wrapper-coupons {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
}
.coupons {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  padding: 10px;
}
.btn {
  grid-column: 1 / span 2;
  margin-top: 8px;
  font-weight: bold;
}
.btn-remove {
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
  color: rgba(243, 69, 69, 0.719) !important;
  border: none;
}
.btn-remove:hover {
  margin: 0 !important;
  font-weight: bold;
  color: rgba(247, 22, 22, 0.719) !important;
}
.coupon {
  background-color: #fff;
  box-shadow: 0 0 5px 5px hsla(224, 47%, 80%, 0.158);
  border-radius: 4px;
  width: 100%;
  padding: 30px 15px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.205);
  transition: all ease 0.3s;
}
.coupon:hover {
  border: 1px solid var(--green);
}
.coupon-new {
  border: 1px solid var(--purple);
  background-color: hsla(251, 62%, 95%, 0.2);
}
.coupon-new:hover {
  border: 1px solid var(--purple);
}
.top {
  display: flex;
  justify-content: space-between;
}
.top-new {
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  i {
    color: var(--purple);
    background-color: transparent;
    border: 2px solid var(--purple);
    border-radius: 50%;
    padding: 4px;
  }
}

.bottom {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.bottom-new {
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.title-new {
  font-weight: bold;
  color: var(--purple);
  text-transform: uppercase;
  font-size: 1rem;
  padding-top: 5px;
  line-height: 1;
}

.strong {
  font-weight: bold;
}
.value {
  font-size: 1.5rem;
  font-weight: bold;
}
.sup {
  font-size: 0.7rem;
  line-height: 1;
  color: var(--purple);
}
.code {
  font-size: 1.5rem;
  line-height: 1;
  color: var(--purple);
  font-weight: 700;
}
.state {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
}
.inactive {
  background-color: #f7858f;
  color: #ac2f39;
}
.active {
  background-color: #d5f6e0;
  color: #2f8c4e;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(46, 3, 82, 0.274);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
</style>
