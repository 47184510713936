export default {
  methods: {
    getIdCloudinary(url) {
      const arr = url.split("/");
      const v = arr.find((item) => item.charAt(0) === "v");
      const index = arr.indexOf(v);
      const arrId = arr
        .slice(index + 1)
        .join("/")
        .split(".");
      return arrId[0];
    },
  },
};
