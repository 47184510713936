<template>
  <div class="pos__order--wrapper">
    <div class="pos__order" :class="darkMode ? 'dark' : 'light'">
      <div class="pos__order__up">
        <div class="pos__order__channel">
          <p
            class="pos__order__channel__title"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            <strong>Canal de venta</strong> <br />
            <span>[Selecciona una opción]</span>
          </p>
          <div :class="{ channel__group: true, some_active: !!currentChannel }">
            <div
              v-for="channel in acquisitionChannels"
              :key="`channel${channel.name}`"
              :style="{ background: channel.color }"
              :title="channel.name"
              :class="{
                channel__group_option: true,
                active: channel.id === currentChannel,
              }"
              @click="currentChannel = channel.id"
            >
              <i :class="`channel__icon ${channel.icon}`"></i>
              <font-awesome-icon
                v-if="channel.differentIcon"
                :icon="channel.icon"
              />
              <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                {{ channel.name }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="!currentCustomer" class="pos__order__search_user">
          <p
            class="pos__order__search_user__title"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            Seleccionar filtro
          </p>
          <el-radio-group v-model="radio" class="mb-3 flex flex-wrap gap-2">
            <el-radio :label="1">Identificación</el-radio>
            <el-radio :label="2">Nombre</el-radio>
            <el-radio :label="3">E-mail</el-radio>
          </el-radio-group>
          <p
            class="pos__order__search_user__title"
            :class="darkMode ? 'darktxt' : 'lighttxt'"
          >
            Cliente
          </p>
          <div
            :class="{
              'search_user__action--wrapper': true,
              'slide-fade-hidden': email,
            }"
          >
            <form
              class="search_user__action"
              @submit.prevent="selectCustomer(IDnumber)"
            >
              <el-autocomplete
                v-model="IDnumber"
                placeholder="Numero de identificación"
                :fetch-suggestions="queryIDnumber"
                :trigger-on-focus="false"
                suffix-icon="el-icon-search"
                class="inputs-idTienda"
                clearable
                :class="darkMode ? 'darks-input' : 'light-input'"
              >
                <template slot-scope="{ item }">
                  <div @click="selectCustomer(item.usuario)">
                    <p
                      class="autocomplete_main_option"
                      :class="darkMode ? 'darktxt' : 'lighttxt'"
                    >
                      {{ item.usuario.nombre }}
                    </p>
                    <p
                      class="autocomplete_secundary_option"
                      :class="darkMode ? 'darktxt' : 'lighttxt'"
                    >
                      {{ item.usuario.identificacion }}
                    </p>
                  </div>
                </template>
              </el-autocomplete>
              <!-- <el-button @click="searchCustomer({type: 'byIDnumber'})">Buscar</el-button> -->
            </form>
          </div>
        </div>
        <div v-if="currentCustomer" class="pos__order__user">
          <div class="user__content">
            <img
              loading="lazy"
              src="https://api2.komercia.co/users/user.jpg"
              class="user__photo"
            />
            <p
              class="pos__order__name"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              {{ currentCustomer.nombre }}
            </p>
            <p :class="darkMode ? 'darktxt' : 'lighttxt'">
              {{ currentCustomer.email }}
            </p>
            <p :class="darkMode ? 'darktxt' : 'lighttxt'">
              {{ currentCustomer.tipo_identificacion }}
              {{ currentCustomer.identificacion }}
            </p>
          </div>
          <button class="user__action" @click="removeCurrentCustomer">
            <i class="ti-close"></i>
          </button>
        </div>
      </div>
      <div
        v-if="currentCustomer || products.length"
        class="pos__order__products"
      >
        <draggable
          v-model="products"
          tag="ul"
          group="people"
          class="products__list"
          ghost-class="hidden"
          @start="drag = false"
        >
          <ProductCard2
            v-for="(item, index) in products"
            :key="`product.${index}.${item.id}`"
            :item="item"
          />
        </draggable>
      </div>
      <template v-if="currentCustomer && products">
        <div class="pos__order__down">
          <hr />
          <div v-if="currentCustomer" class="pos__order__resume">
            <p
              class="resume_label bold !items-center !my-auto"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              Cupon
            </p>
            <div class="!text-sm" :class="darkMode ? 'darktxt' : 'lighttxt'">
              <el-select
                v-model="cupon"
                placeholder="Selecciona un cupón"
                clearable
                style="width: 100%"
                @change="obtainValueToCupon()"
              >
                <el-option
                  v-for="item in ListCupones"
                  :key="item.id"
                  :label="item.codigo"
                  :value="item.codigo"
                ></el-option>
              </el-select>
            </div>
            <p
              class="resume_label bold"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
              v-if="cuponValue > 0"
            >
              Descuento cupon
            </p>
            <p
              v-if="cuponValue > 0"
              class="resume__value !text-red-400"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              -{{ cuponValue | currencyOnlyValue }}
            </p>
            <p
              class="resume_label bold"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
              v-if="discountDescuentos > 0"
            >
              Descuento tienda
            </p>
            <p
              v-if="discountDescuentos > 0"
              class="resume__value !text-red-400"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              -{{ discountDescuentos | currencyOnlyValue }}
            </p>
            <p
              class="resume__label bold"
              :class="darkMode ? 'darktxt' : 'lighttxt'"
            >
              Subtotal
            </p>
            <p class="resume__value" :class="darkMode ? 'darktxt' : 'lighttxt'">
              {{ subtotal | currency }}
            </p>

            <p class="resume__label" :class="darkMode ? 'darktxt' : 'lighttxt'">
              Envío
            </p>
            <p class="resume__value" :class="darkMode ? 'darktxt' : 'lighttxt'">
              Gratis
            </p>
          </div>
          <hr />
          <div class="pos__order__resume">
            <p class="resume__label" :class="darkMode ? 'darktxt' : 'lighttxt'">
              Total
            </p>
            <p class="resume__value" :class="darkMode ? 'darktxt' : 'lighttxt'">
              {{ total | currency }}
            </p>
          </div>
          <br />
          <div class="pos__order__actions">
            <button
              v-if="currentCustomer"
              class="pos__order__action"
              @click="nextStep"
            >
              Siguiente Paso
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProductCard2 from "@/components/POS/productCard2";
import ListCupones from "@/views/comunidad/cupones/listCupones.vue";
import { list } from "postcss";
import draggable from "vuedraggable";
// import Fuse from 'fuse.js'
export default {
  components: { ProductCard2, draggable },
  filters: {
    currency(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return "Sin precio";
    },
    currencyOnlyValue(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
  data() {
    return {
      radio: 1,
      IDnumber: null,
      cupon: null,
      cuponValue: 0,
      validations: {
        byIDnumber: false,
        byEmail: false,
      },
      discountDescuentos: 0,
      email: null,
      resultedCustomers: null,
      fuseOptions: {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["identificacion", "nombre", "email"],
      },
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    acquisitionChannels() {
      return this.$store.state.pos.acquisitionChannels;
    },
    channel() {
      return this.$store.getters["pos/channel"](this.currentChannel);
    },
    // customers() {
    //   return this.$store.state.customers.clientes
    // },
    subtotal() {
      return this.$store.getters["pos/subtotal"];
    },
    total() {
      return this.$store.getters["pos/total"];
    },
    shippingDescuento() {
      return this.$store.getters["pos/listaDescuentosProductos"];
    },
    shippingDescuento2() {
      return this.$store.getters["pos/listaDescuentosPrecio"];
    },
    ListCupones() {
      return this.$store.getters["pos/listaCupones"];
    },
    products: {
      get() {
        return this.$store.state.pos.products;
      },
      set(newValue) {
        this.$store.state.pos.products = newValue;
      },
    },
    currentCustomer: {
      get() {
        return this.$store.state.pos.currentCustomer;
      },
      set(newValue) {
        this.$store.commit("pos/SET_CURRENT_CUSTOMER", newValue);
      },
    },
    currentChannel: {
      get() {
        return this.$store.state.pos.currentChannel;
      },
      set(newValue) {
        this.$store.commit("pos/SET_CURRENT_CHANNEL", newValue);
      },
    },
  },
  watch: {
    currentChannel(value) {
      if (value) {
        localStorage.setItem("POST_currentChannel", value);
      } else {
        localStorage.setItem("POST_currentChannel", "");
      }
    },
    subtotal() {
      this.obtainDiscountValue();
      this.obtainValueToCupon();
    },
    shippingDescuento() {
      this.obtainDiscountValue();
    },
    shippingDescuento2() {
      this.obtainDiscountValue();
    },
    IDnumber() {
      this.validations.byIDnumber = false;
    },
    email() {
      this.validations.byEmail = false;
    },
  },
  mounted() {
    this.$store.dispatch("pos/GET_DESCUENTOS");
    this.$store.dispatch("pos/GET_CUPONES");
    this.obtainDiscountValue();
  },
  created() {
    this.getChannelOfLocalStorage();
  },
  methods: {
    getChannelOfLocalStorage() {
      const channel = parseInt(localStorage.getItem("POST_currentChannel"));
      this.currentChannel = channel ? channel : null;
    },
    selectCustomer(IDnumber) {
      // let result = null
      // result = this.customers.find(
      //   customer => customer.identificacion === IDnumber
      // )
      // if (IDnumber && !result) {
      //   this.openModalCreateUser()
      // }
      // this.currentCustomer = result
      this.currentCustomer = IDnumber;
    },
    obtainDiscountValue() {
      let value1 = 0;
      let value2 = 0;
      if (this.shippingDescuento && this.shippingDescuento.tipo == 1) {
        value1 = this.shippingDescuento.valor
          ? this.shippingDescuento.valor
          : 0;
      } else if (this.shippingDescuento && this.shippingDescuento.tipo == 0) {
        value1 = this.shippingDescuento.valor
          ? Math.trunc((this.subtotal * this.shippingDescuento.valor) / 100)
          : 0;
      }
      if (this.shippingDescuento2 && this.shippingDescuento2.precio) {
        if (this.shippingDescuento2.options == 0) {
          value2 = parseInt(this.shippingDescuento2.precio)
            ? Math.trunc(
                (this.subtotal * parseInt(this.shippingDescuento2.precio)) / 100
              )
            : 0;
        } else if (this.shippingDescuento2.options == 1) {
          value2 = this.shippingDescuento2.precio;
        }
      }
      this.discountDescuentos = value1 + value2;
      this.$store.commit("pos/SET_TOTAL_DISCOUNT", this.discountDescuentos);
    },
    obtainValueToCupon() {
      let cupon = this.cupon;
      let value = 0;
      if (cupon) {
        let result = this.ListCupones.find((item) => item.codigo === cupon);
        if (result.tipo == 1) {
          value = result.valor_descuento ? result.valor_descuento : 0;
        } else if (result.tipo == 0) {
          value = result.porcentaje_descuento
            ? Math.trunc((this.subtotal * result.porcentaje_descuento) / 100)
            : 0;
        }
      }
      this.cuponValue = value;
      this.$store.commit("pos/SET_DISCOUNT_CUPON", value);
      this.$store.commit("pos/SET_COUPON_CODE_SELECTED", cupon);
    },
    async queryIDnumber(queryString, resolve) {
      let type = "";
      if (this.radio === 3) {
        type = "customer_email";
      } else if (this.radio === 1) {
        type = "customer_identification";
      } else {
        type = "customer_name";
      }
      const { success, data } = await this.$store.dispatch("FILTER_CUSTOMERS", {
        type: type,
        value: queryString,
        page: "1",
      });
      if (success) {
        resolve(data.data);
      }
      // const fuse = new Fuse(this.customers, this.fuseOptions)
      // let results = fuse.search(queryString)
      // results = results.map(item => {
      //   return { ...item, value: item.identificacion }
      // })
      // resolve(results)
    },
    openModalCreateUser() {
      this.$emit("openModal", true);
    },
    removeChannel() {
      this.currentChannel = null;
    },
    removeCurrentCustomer() {
      this.currentCustomer = null;
    },
    nextStep() {
      if (this.products.length) {
        this.$emit("onEnd");
      } else {
        this.$message("Cuidado!, te falta agregar productos.");
      }
    },
    nothing() {
      // console.log("enter");
    },
  },
};
</script>

<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 30px 0px 10px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
  max-width: 400px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.channel__group {
  width: 100%;
  display: flex;
}
.channel__group_option {
  width: 100%;
  display: grid;
  flex: 1;
  justify-content: center;
  justify-items: center;
  align-content: center;
  padding: 6px 0;
  /* border: 1px solid #dcdfe6; */
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  margin: 0 2.5px;
  transition: 0.3s;
}
.channel__group_option.active {
  flex: 4;
}
.channel__group.some_active .channel__group_option:not(.active) {
  opacity: 0.6;
}
.channel__group.some_active .channel__group_option:not(.active):hover {
  opacity: 1;
}
.channel__group_option i,
.channel__group_option svg {
  font-size: 24px;
  margin-bottom: 3px;
  transition: all ease 0.3s;
}
.channel__group_option p {
  font-weight: 500;
  display: none;
}
.channel__group_option.active p {
  display: block;
}
.channel__group_option.active i,
.channel__group_option.active svg {
  font-size: 18px;
}
.channel__group_option:hover {
  color: #fff !important;
  background-color: #4429b4;
  opacity: 0.7;
}
.pos__order--wrapper {
  /* padding: 10px; */
  height: calc(100vh - 50px);
}
.pos__order {
  width: 100%;
  height: 100%;
  /* background-color: #fff; */
  padding: 20px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-content: start;
  /* border-radius: 6px; */
  overflow: auto;
}
.pos__order::-webkit-scrollbar-track {
  border-style: none;
  background-color: transparent;
}
.pos__order::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.pos__order::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 6px;
  margin-right: 5px;
}
.pos__order__channel {
  width: 100%;
  display: grid;
  margin-top: 7px;
  margin-bottom: 20px;
}
.pos__order__channel__title {
  margin-bottom: 16px;
  text-align: center;
  line-height: 0.9;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.pos__order__channel__title > span {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
  text-transform: lowercase;
}
.channel__current {
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
  border: 0.7px solid rgb(197, 197, 197);
  border-radius: 6px;
}
.channel {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  margin: 5px 6px;
}
.channel__action {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 5px;
  /* color: black; */
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  background-color: #eee;
  padding: 0 9px;
}
.channel__action .material-icons {
  width: 15px;
  height: 15px;
  display: grid;
  justify-content: center;
  align-content: center;
  /* background-color: #9dd880; */
  font-size: 13px;
  border-radius: 50%;
  /* border: 1px solid #000000; */
  cursor: pointer;
}
.channel__action:hover {
  color: #000000;
}
.autocomplete_main_option {
  font-weight: bold;
  line-height: 1.5;
}
.autocomplete_secundary_option {
  line-height: 1.5;
}
.pos__order__search_user {
  display: grid;
  justify-items: center;
  margin-bottom: 15px;
}
.pos__order__search_user__title {
  width: 100%;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.search_user__action--wrapper {
  width: 100%;
  max-height: 80px;
  transition: all 0.3s;
}
.search_user__action {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  z-index: 2;
}
.search_user__alert {
  text-align: center;
  margin: 10px 0;
}
.pos__order__user {
  min-height: 70px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 10px;
  border-radius: 6px;
  /* padding: 5px; */
  /* box-shadow: 0px 0px 2px 1px #eee; */
  border: 0.7px solid rgb(197, 197, 197);
  overflow: hidden;
  margin-bottom: 15px;
}
.pos__order__user .user__content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  grid-template-areas:
    "photo name"
    "photo email"
    "photo identification";
  padding: 5px;
}
.pos__order__name {
  font-size: 18px;
  font-weight: bold;
}
.user__photo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  grid-area: photo;
}
.user__action {
  width: 30px;
  height: 100%;
  padding: 5px;
  border-style: none;
  /* border: 0.5px solid gray; */
  background-color: #eee;
  color: #000;
  justify-self: end;
  outline: none;
  cursor: pointer;
}
.pos__order__products {
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: calc(100vh - 30px - 75px - 35px - 300px);
  overflow: auto;
}
.products__list {
  width: 100%;
  height: 100%;
  list-style: none;
  /* display: grid; */
  /* grid-gap: 5px; */
}
.pos__order__resume {
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 6px;
}
.resume__label,
.resume__value {
  font-size: 15px;
}
.resume__label.bold {
  font-weight: 600;
}
.resume__value {
  justify-self: flex-end;
}
.pos__order hr {
  margin: 15px 0;
}
.pos__order__actions {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.pos__order__action {
  width: 100%;
  padding: 10px;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  background-color: #5c6af6;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
}
.pos__order__action:hover {
  opacity: 0.85;
}
.pos__order__action.action_link {
  background-color: #12cc6f;
}
.slide-fade-hidden {
  max-height: 0px;
  opacity: 0;
}
</style>
