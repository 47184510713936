import Vue from "vue";
import VueAnalytics from "vue-analytics";

export default (router) => {
  Vue.use(VueAnalytics, {
    id: "G-JP84QXL3KJ",
    router,
    autoTracking: {
      pageviewTemplate(route) {
        return {
          page: `panel${route.path}`,
          title: document.title,
          location: window.location.href,
        };
      },
    },
  });
};
