<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div
      v-if="paymentMethods"
      class="w-full max-w-[900px] flex flex-wrap justify-center items-center gap-x-4 gap-y-5"
    >
      <div
        v-for="item in methods"
        :key="item.id"
        class="w-full h-[60px] max-w-[210px] flex justify-between items-center rounded-xl px-4 border cursor-pointer transition-all ease-in-out duration-300"
        :label="item.name"
        :class="[
          selectMethods?.id == item.id ? 'bg-[#E0FAEF] border-[#00d57e]' : '',
          (paymentMethods[item.key]?.estado === 1 ||
            paymentMethods[item.key] === 1) &&
          selectMethods?.id !== item.id
            ? 'bg-[#f2efff] border-[#4429b4]'
            : '',
          !(
            selectMethods?.id == item.id ||
            paymentMethods[item.key]?.estado === 1 ||
            paymentMethods[item.key] === 1
          )
            ? 'bg-[#f3f4f6] border-[#f3f4f6]'
            : '',
        ]"
        @click="setMethodsValue(item)"
      >
        <p class="text-xs w-[90%]">
          {{ item.name }}
        </p>
        <i
          :class="
            paymentMethods[item.key]?.estado == 1 ||
            paymentMethods[item.key] === 1
              ? 'el-icon-success text-[#00d57e]'
              : 'el-icon-error text-[#d84343]'
          "
        ></i>
      </div>
    </div>
    <div v-else>
      <div
        class="w-full max-w-[900px] flex flex-wrap justify-center items-center gap-x-4 gap-y-5"
      >
        <div
          v-for="item in 7"
          :key="item"
          class="w-full h-[60px] max-w-[210px] flex justify-between items-center rounded-xl px-4 border bg-[#f3f4f6] border-[#f3f4f6]"
        >
          <div class="h-[18px] w-[160px] bg-gray-200 animate-pulse"></div>
          <div class="h-[14px] w-[14px] bg-gray-200 animate-pulse ml-3"></div>
        </div>
      </div>
    </div>

    <div
      v-if="selectMethods?.id"
      class="w-full max-w-[950px] h-full rounded-xl mt-10 px-[30px] py-[20px]"
      :class="darkMode ? 'bg-[#233345]' : 'bg-[#f3f4f6]'"
    >
      <!-- contraentrega -->
      <div v-if="selectMethods?.id == 1" class="py-10">
        <el-switch
          v-model="paymentMethods[selectMethods.key].estado"
          :active-value="1"
          :inactive-value="0"
          active-text="Activada"
          inactive-text="Desactivada"
          active-color="#13ce66"
          inactive-color="#ff4949"
          class="flex justify-end"
          :disabled="statusData ? false : true"
          @change="
            updateStateMethods(
              selectMethods?.method,
              paymentMethods[selectMethods.key].estado === 1
                ? 'ACTIVATE'
                : 'DEACTIVATE'
            )
          "
        ></el-switch>
        <p
          class="font-semibold text-base mt-2"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ selectMethods?.name }}
        </p>
        <p
          class="text-sm mt-2 mb-5"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          {{ selectMethods?.description }}
        </p>
        <p
          class="text-sm mb-1"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          Comentario:
        </p>
        <el-input
          v-model="paymentMethods.contraentrega.comentario"
          class="input-description"
          :class="darkMode ? 'darks-input' : 'light-input'"
          type="textarea"
          :rows="2"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="Comentario u observación"
          :disabled="statusData ? false : true"
        ></el-input>
        <p
          class="text-sm mb-1 mt-3"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          Selecciona tus ciudades:
        </p>
        <div class="w-full grid grid-cols-2 gap-x-5">
          <el-select
            v-model="currentCity"
            class="inputs-idTienda w-full"
            :class="darkMode ? 'darks-input' : 'light-input'"
            filterable
            clearable
            remote
            placeholder="Escribe la ciudad"
            :remote-method="remoteMethod"
            :disabled="statusData ? false : true"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="`${item.nombre_ciu} - ${item.departamento.nombre_dep}`"
              :value="item.id"
              :disabled="citiesWithPayOnDelivery.includes(item.id)"
            ></el-option>
          </el-select>
          <button
            class="max-w-[220px] max-h-[40px] rounded-xl px-10 py-2 border-2 border-[#4429b4] hover:border-[#00d57e] text-[#4429b4] hover:text-[#00d57e] transition-all ease-in-out duration-300"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="addCurrentCity"
          >
            Agregar
          </button>
        </div>
        <div class="wrapper-payOnDelivery-options">
          <div class="w-full flex flex-wrap gap-2 mt-[15px]">
            <el-tag
              v-for="(city, index) in cityLabelsWithPayOnDelivery"
              v-show="city && city.nombre_ciu"
              :key="index"
              class="flex relative group"
            >
              {{ city.nombre_ciu }} - {{ city.departamento.nombre_dep }}
              <button
                type="danger"
                class="bg-[#f56c6c] absolute -top-[12px] -right-[14px] flex justify-center items-center text-sm px-2 py-[1px] opacity-0 font-bold transition ease-in-out duration-300 z-[1] text-white group-hover:opacity-100 rounded-[4px]"
                @click="removeCurrentCity(index)"
              >
                x
              </button>
            </el-tag>
          </div>
        </div>
        <div class="mt-5">
          <p
            class="text-sm mb-1"
            :class="darkMode ? 'text-white' : 'text-[#919191]'"
          >
            <strong :class="darkMode ? 'text-blue-500' : 'text-[#000000]'"
              >Nota:
            </strong>
            Si seleccionas una o mas ciudades, solo aparecerá la opción de pago
            contraentrega para compradores de estas ciudades.<br /><br />
            Si no seleccionas ninguna ciudad el pago contra estará disponible
            para todo el territorio de
            <strong :class="darkMode ? 'text-blue-500' : 'text-[#000000]'">
              {{ storeData.informacion_tienda[0].pais.pais }}.
            </strong>
          </p>
        </div>
        <div class="w-full flex justify-end items-center mt-10">
          <button
            class="text-red-400 hover:text-red-600 border-2 border-red-400 hover:border-red-600 rounded-xl px-10 py-3 transition-all ease-in-out duration-300 mr-5"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="CloseShowItems"
          >
            Cerrar
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="
              sendDataMethods(
                paymentMethods[selectMethods.key],
                selectMethods.method
              )
            "
          >
            {{
              paymentMethods[selectMethods.key]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- convenir - tienda -->
      <div
        v-if="selectMethods?.id == 2 || selectMethods?.id == 3"
        class="py-10"
      >
        <el-switch
          v-model="paymentMethods[selectMethods.key]"
          :active-value="1"
          :inactive-value="0"
          active-text="Activada"
          inactive-text="Desactivada"
          active-color="#13ce66"
          inactive-color="#ff4949"
          class="flex justify-end"
          :disabled="statusData ? false : true"
          @change="
            updateStateMethods(
              selectMethods?.method,
              paymentMethods[selectMethods.key] === 1
                ? 'ACTIVATE'
                : 'DEACTIVATE'
            )
          "
        ></el-switch>
        <p
          class="font-semibold text-base mt-2"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ selectMethods?.name }}
        </p>
        <p
          class="text-sm mt-2"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          {{ selectMethods?.description }}
        </p>
        <div class="w-full flex justify-end items-center mt-10">
          <button
            class="text-red-400 hover:text-red-600 border-2 border-red-400 hover:border-red-600 rounded-xl px-10 py-3 transition-all ease-in-out duration-300 mr-5"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="CloseShowItems"
          >
            Cerrar
          </button>
        </div>
      </div>
      <!-- consignacion -->
      <div v-if="selectMethods?.id == 4" class="py-10">
        <el-switch
          v-model="paymentMethods[selectMethods.key].estado"
          :active-value="1"
          :inactive-value="0"
          active-text="Activada"
          inactive-text="Desactivada"
          active-color="#13ce66"
          inactive-color="#ff4949"
          class="flex justify-end"
          :disabled="statusData ? false : true"
          @change="
            updateStateMethods(
              selectMethods?.method,
              paymentMethods[selectMethods.key].estado === 1
                ? 'ACTIVATE'
                : 'DEACTIVATE'
            )
          "
        ></el-switch>
        <p
          class="font-semibold text-base mt-2"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ selectMethods?.name }}
        </p>
        <p
          class="text-sm mt-2 mb-5"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          {{ selectMethods?.description }}
        </p>

        <ValidationObserver ref="observer" tag="form">
          <div class="w-full grid grid-cols-2 gap-5 mt-8 mb-5">
            <div class="w-full flex flex-col">
              <label
                for="institución"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Institución <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="institución"
                rules="required"
                v-slot="{ errors }"
              >
                <el-select
                  v-model="paymentMethods.consignacion.bancosId"
                  name="institución"
                  class="inputs-idTienda w-full"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Institución"
                  filterable
                  :disabled="statusData ? false : true"
                >
                  <el-option
                    v-for="item in banksData"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Tipo de cuenta"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Tipo de cuenta <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Tipo de cuenta"
                rules="required"
                v-slot="{ errors }"
              >
                <el-select
                  v-model="paymentMethods.consignacion.tipo"
                  name="Tipo de cuenta"
                  class="inputs-idTienda w-full"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Tipo Cuenta"
                  filterable
                  :disabled="statusData ? false : true"
                >
                  <el-option label="Ahorros" :value="0"></el-option>
                  <el-option label="Corriente" :value="1"></el-option>
                  <el-option
                    v-if="storeData.informacion_tienda[0].paises_id == 7"
                    label="Cuenta vista"
                    :value="2"
                  ></el-option>
                </el-select>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Número de cuenta"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Número de cuenta <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Número de cuenta"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods.consignacion.numeroCuenta"
                  name="Número de cuenta"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Número Cuenta"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Titular"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Titular <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Titular"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods.consignacion.titular"
                  name="Titular"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Titular"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Tipo de identificación"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Tipo de identificación <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Tipo de identificación"
                rules="required"
                v-slot="{ errors }"
              >
                <el-select
                  v-model="paymentMethods.consignacion.tipoIdentificacion"
                  name="Tipo de identificación"
                  class="inputs-idTienda w-full"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Tipo de identificación"
                  filterable
                  :disabled="statusData ? false : true"
                >
                  <el-option
                    v-for="(item, index) in typesID[0].option"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Número de Identificación"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Número de Identificación
                <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Número de Identificación"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods.consignacion.identificacion"
                  name="Número de Identificación"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Número de Identificación"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <label
            for="consignación comentario"
            class="text-sm"
            :class="darkMode ? 'text-white' : 'text-[#919191]'"
          >
            Comentario:
          </label>
          <el-input
            v-model="paymentMethods.consignacion.comentario"
            name="consignación comentario"
            class="input-description"
            :class="darkMode ? 'darks-input' : 'light-input'"
            type="textarea"
            :rows="5"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="Comentario u observación"
            :disabled="statusData ? false : true"
          ></el-input>
        </ValidationObserver>

        <div class="w-full flex justify-end items-center mt-10">
          <button
            class="text-red-400 hover:text-red-600 border-2 border-red-400 hover:border-red-600 rounded-xl px-10 py-3 transition-all ease-in-out duration-300 mr-5"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="CloseShowItems"
          >
            Cerrar
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="
              validateAndSubmit(
                paymentMethods[selectMethods.key],
                selectMethods.method
              )
            "
          >
            {{
              paymentMethods[selectMethods.key]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- efecty -->
      <div v-if="selectMethods?.id == 5" class="py-10">
        <el-switch
          v-model="paymentMethods[selectMethods.key].estado"
          :active-value="1"
          :inactive-value="0"
          active-text="Activada"
          inactive-text="Desactivada"
          active-color="#13ce66"
          inactive-color="#ff4949"
          class="flex justify-end"
          :disabled="statusData ? false : true"
          @change="
            updateStateMethods(
              selectMethods?.method,
              paymentMethods[selectMethods.key].estado === 1
                ? 'ACTIVATE'
                : 'DEACTIVATE'
            )
          "
        ></el-switch>
        <p
          class="font-semibold text-base mt-2"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ selectMethods?.name }}
        </p>
        <p
          class="text-sm mt-2 mb-5"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          {{ selectMethods?.description }}
        </p>
        <ValidationObserver ref="observer" tag="form">
          <div class="w-full grid grid-cols-2 gap-5 mt-8">
            <div class="w-full flex flex-col">
              <label
                for="nombre"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Nombre <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="nombre"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods.efecty.nombre"
                  name="nombre"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Nombre"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Número Identificación efecty"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Número Identificación <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Número Identificación efecty"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods.efecty.referencia"
                  name="Número Identificación efecty"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Número Identificación"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="w-full flex flex-col">
              <label
                for="Número Telefónico efecty"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Número Telefónico <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                name="Número Telefónico efecty"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods.efecty.telefono"
                  name="Número Telefónico efecty"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Número Telefónico"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <p
            class="text-sm mt-5"
            :class="darkMode ? 'text-white' : 'text-[#919191]'"
          >
            Comentario:
          </p>
          <el-input
            v-model="paymentMethods.efecty.comentario"
            class="input-description"
            :class="darkMode ? 'darks-input' : 'light-input'"
            type="textarea"
            :rows="2"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="Comentario u observación"
            :disabled="statusData ? false : true"
          ></el-input>
        </ValidationObserver>

        <div class="w-full flex justify-end items-center mt-10">
          <button
            class="text-red-400 hover:text-red-600 border-2 border-red-400 hover:border-red-600 rounded-xl px-10 py-3 transition-all ease-in-out duration-300 mr-5"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="CloseShowItems"
          >
            Cerrar
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="
              validateAndSubmit(
                paymentMethods[selectMethods.key],
                selectMethods.method
              )
            "
          >
            {{
              paymentMethods[selectMethods.key]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
      <!-- nequi - daviplata-->
      <div
        v-if="selectMethods?.id == 6 || selectMethods?.id == 7"
        class="py-10"
      >
        <el-switch
          v-model="paymentMethods[selectMethods.key].estado"
          :active-value="1"
          :inactive-value="0"
          active-text="Activada"
          inactive-text="Desactivada"
          active-color="#13ce66"
          inactive-color="#ff4949"
          class="flex justify-end"
          :disabled="statusData ? false : true"
          @change="
            updateStateMethods(
              selectMethods?.method,
              paymentMethods[selectMethods.key].estado === 1
                ? 'ACTIVATE'
                : 'DEACTIVATE'
            )
          "
        ></el-switch>
        <p
          class="font-semibold text-base mt-2"
          :class="darkMode ? 'text-white' : 'text-[#1f2937]'"
        >
          {{ selectMethods?.name }}
        </p>
        <p
          class="text-sm mt-2 mb-5"
          :class="darkMode ? 'text-white' : 'text-[#919191]'"
        >
          {{ selectMethods?.description }}
        </p>
        <ValidationObserver ref="observer" tag="form">
          <div class="w-full grid grid-cols-2 gap-5 mt-8">
            <div class="w-full flex flex-col">
              <label
                :for="`Número Telefónico ${selectMethods?.name}`"
                class="text-sm"
                :class="darkMode ? 'text-white' : 'text-[#919191]'"
              >
                Número Telefónico <span class="ml-1 text-red-500">*</span>
              </label>
              <ValidationProvider
                :name="`Número Telefónico ${selectMethods?.name}`"
                rules="required"
                v-slot="{ errors }"
              >
                <el-input
                  v-model="paymentMethods[selectMethods?.key].referencia"
                  :name="`Número Telefónico ${selectMethods?.name}`"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Número Telefónico"
                  :disabled="statusData ? false : true"
                ></el-input>
                <span v-if="errors" class="text-xs text-red-500">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <p
            class="text-sm mt-5"
            :class="darkMode ? 'text-white' : 'text-[#919191]'"
          >
            Comentario:
          </p>
          <el-input
            v-model="paymentMethods[selectMethods?.key].comentario"
            class="input-description"
            :class="darkMode ? 'darks-input' : 'light-input'"
            type="textarea"
            :rows="2"
            placeholder="Comentario u observación"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :disabled="statusData ? false : true"
          ></el-input>
        </ValidationObserver>
        <div class="w-full flex justify-end items-center mt-10">
          <button
            class="text-red-400 hover:text-red-600 border-2 border-red-400 hover:border-red-600 rounded-xl px-10 py-3 transition-all ease-in-out duration-300 mr-5"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="CloseShowItems"
          >
            Cerrar
          </button>
          <button
            class="bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out duration-300"
            :class="!statusData ? ' cursor-wait' : ''"
            :disabled="statusData ? false : true"
            @click="
              validateAndSubmit(
                paymentMethods[selectMethods.key],
                selectMethods.method
              )
            "
          >
            {{
              paymentMethods[selectMethods.key]?.createdAt
                ? "Actualizar"
                : "Guardar"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "paymentMethods",
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      statusData: true,
      selectMethods: null,
      loading: false,
      options: [],
      currentCity: "",
      citiesWithPayOnDelivery: [],
      paymentDataTemp: {
        consignacion: {
          valores: "",
          comentario: "",
          tipo: "",
          banco: "",
          cuenta: "",
          identificacion: "",
          tipo_identificacion: "",
          titular: "",
          estado: 0,
        },
        contraentrega: { valores: "", comentario: "", estado: 0 },
        convenir: 1,
        tienda: 1,
        efecty: {
          referencia: "",
          nombre: "",
          telefono: "",
          comentario: "",
          estado: 0,
        },
        nequi: { referencia: "", comentario: "", estado: 0 },
        daviplata: { referencia: "", comentario: "", estado: 0 },
      },
    };
  },
  computed: {
    ...mapState(["banksData", "storeData", "darkMode", "paymentMethods"]),
    typesID() {
      return this.$store.state.identificationTypes.filter(
        (item) =>
          item.idCountry === this.storeData.informacion_tienda[0].paises_id
      );
    },
    cityLabelsWithPayOnDelivery: {
      get() {
        if (this.citiesWithPayOnDelivery.length) {
          return this.citiesWithPayOnDelivery.map((city) => {
            return this.cities.find((item) => item.id == city);
          });
        } else {
          return [];
        }
      },
      set() {},
    },
    methods() {
      if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais == "Colombia"
      ) {
        let payment = [
          {
            id: 1,
            name: "Pago contra entrega",
            description:
              "Elija esta opción en caso que desee que su cliente page al recibir el envió.",
            key: "contraentrega",
            method: "CASH_ON_DELIVERY",
          },
          {
            id: 2,
            name: "Pago a convenir",
            description:
              "Elija esta opción en caso que desee arreglar la logística del pago de sus productos directamente con el cliente comprador.",
            key: "convenir",
            method: "PAYMENT_TO_BE_AGREED",
          },
          {
            id: 3,
            name: "Recoger y pagar en la tienda",
            description:
              "El comprador recoge y paga su compra en tu establecimiento, de esa forma no aplica el costo de envío.",
            key: "tienda",
            method: "PICKUP_AND_PAY_IN_STORE",
          },
          {
            id: 4,
            name: "Consignación bancaria",
            description:
              "Elige esta opción en caso de que desees recibir el pago mediante consignación bancaria. Coloca la información correspondiente a tu cuenta bancaria para que el comprador pueda verla en el checkout. En este caso, debes confirmar pago y hacer envió del producto o entrega del servicio según el caso.",
            key: "consignacion",
            method: "BANK_CONSIGNMENT",
          },
          {
            id: 5,
            name: "Efecty",
            description:
              "Acepta pagos en línea desde tu tienda online, ofrece múltiples medios y formas de pago. Tus clientes podrán comprar más seguro, fácil y rápido tus productos.",
            key: "efecty",
            path: "efecty",
            method: "EFECTY",
          },
          {
            id: 6,
            name: "Nequi",
            description:
              "Acepta pagos en línea y recibe en tu celular, no importa si están a tu lado o en cualquier lugar del país.",
            key: "nequi",
            method: "NEQUI",
          },
          {
            id: 7,
            name: "DaviPlata",
            description:
              "Acepta pagos en línea y recibe en tu celular, no importa si están a tu lado o en cualquier lugar del país.",
            key: "daviplata",
            method: "DAVIPLATA",
          },
        ];
        return payment;
      } else if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais == "Chile"
      ) {
        let payment = [
          {
            id: 1,
            name: "Pago contra entrega",
            description:
              "Elija esta opción en caso que desee que su cliente page al recibir el envió.",
            key: "contraentrega",
            method: "CASH_ON_DELIVERY",
          },
          {
            id: 2,
            name: "Envíos por pagar",
            description:
              "Elija esta opción en caso que desee arreglar la logística del pago de sus productos directamente con el cliente comprador.",
            key: "convenir",
            method: "PAYMENT_TO_BE_AGREED",
          },
          {
            id: 3,
            name: "Recoger y pagar en la tienda",
            description:
              "El comprador paga online y pasa por tu establecimiento a recoger su compra, de esa forma no aplica el costo de envío.",
            key: "tienda",
            method: "PICKUP_AND_PAY_IN_STORE",
          },
          {
            id: 4,
            name: "Consignación bancaria",
            description:
              "Elige esta opción en caso de que desees recibir el pago mediante consignación bancaria. Coloca la información correspondiente a tu cuenta bancaria para que el comprador pueda verla en el checkout. En este caso, debes confirmar pago y hacer envió del producto o entrega del servicio según el caso.",
            key: "consignacion",
            method: "BANK_CONSIGNMENT",
          },
        ];
        return payment;
      } else if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais == "Perú"
      ) {
        let payment = [
          {
            id: 1,
            name: "Pago contra entrega",
            description:
              "Elija esta opción en caso que desee que su cliente page al recibir el envió.",
            key: "contraentrega",
            method: "CASH_ON_DELIVERY",
          },
          {
            id: 3,
            name: "Recoger y pagar en la tienda",
            description:
              "El comprador paga online y pasa por tu establecimiento a recoger su compra, de esa forma no aplica el costo de envío.",
            key: "tienda",
            method: "PICKUP_AND_PAY_IN_STORE",
          },
        ];
        return payment;
      } else if (
        this.storeData?.informacion_tienda?.length > 0 &&
        this.storeData.informacion_tienda[0]?.pais?.pais == "Panamá"
      ) {
        let payment = [
          {
            id: 1,
            name: "Pago contra entrega",
            description:
              "Elija esta opción en caso que desee que su cliente page al recibir el envió.",
            key: "contraentrega",
            method: "CASH_ON_DELIVERY",
          },
          {
            id: 2,
            name: "Pago a convenir",
            description:
              "Elija esta opción en caso que desee arreglar la logística del pago de sus productos directamente con el cliente comprador.",
            key: "convenir",
            method: "PAYMENT_TO_BE_AGREED",
          },
          {
            id: 3,
            name: "Recoger y pagar en la tienda",
            description:
              "El comprador paga online y pasa por tu establecimiento a recoger su compra, de esa forma no aplica el costo de envío.",
            key: "tienda",
            method: "PICKUP_AND_PAY_IN_STORE",
          },
          {
            id: 6,
            name: "Nequi",
            description:
              "Acepta pagos en línea y recibe en tu celular, no importa si están a tu lado o en cualquier lugar del país.",
            key: "nequi",
            method: "NEQUI",
          },
        ];
        return payment;
      } else {
        let payment = [
          {
            id: 1,
            name: "Pago contra entrega",
            description:
              "Elija esta opción en caso que desee que su cliente page al recibir el envió.",
            key: "contraentrega",
            method: "CASH_ON_DELIVERY",
          },
          {
            id: 2,
            name: "Pago a convenir",
            description:
              "Elija esta opción en caso que desee arreglar la logística del pago de sus productos directamente con el cliente comprador.",
            key: "convenir",
            method: "PAYMENT_TO_BE_AGREED",
          },
          {
            id: 3,
            name: "Recoger y pagar en la tienda",
            description:
              "El comprador paga online y pasa por tu establecimiento a recoger su compra, de esa forma no aplica el costo de envío.",
            key: "tienda",
            method: "PICKUP_AND_PAY_IN_STORE",
          },
        ];
        return payment;
      }
    },
    cities() {
      return this.$store.state.cities.filter(
        (cities) =>
          cities.departamento.paises_id ===
          this.storeData.informacion_tienda[0].paises_id
      );
    },
  },
  watch: {
    citiesWithPayOnDelivery() {
      if (this.citiesWithPayOnDelivery.length) {
        this.cityLabelsWithPayOnDelivery = this.citiesWithPayOnDelivery.map(
          (city) => {
            return this.cities.find((item) => item.id == city);
          }
        );
      }
    },
  },
  methods: {
    setMethodsValue(value) {
      this.selectMethods = value;

      if (value?.key === "consignacion") {
        this.$store.dispatch("GET_BANKS_COUNTRY", {
          idCountry: this.storeData.informacion_tienda[0].paises_id,
        });
      }
      if (this.paymentMethods[value.key]?.createdAt) {
        if (value?.key === "contraentrega") {
          this.citiesWithPayOnDelivery = JSON.parse(
            this.paymentMethods.contraentrega.valores
          );
        }
      } else {
        this.paymentMethods[value.key] = this.paymentDataTemp[[value.key]];
      }
    },
    CloseShowItems() {
      this.showIndexMethods = false;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.cities.filter((item) => {
            const itemName = this.removeAccents(item.nombre_ciu).toLowerCase();
            const queryText = this.removeAccents(query).toLowerCase();
            return itemName.indexOf(queryText) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    addCurrentCity() {
      if (this.currentCity) {
        this.citiesWithPayOnDelivery.unshift(this.currentCity);
        this.currentCity = "";
        this.paymentMethods.contraentrega.valores = JSON.stringify(
          this.citiesWithPayOnDelivery
        );
      } else {
        this.$message.error("Llenar el campo de ciudad");
      }
    },
    removeCurrentCity(index) {
      this.citiesWithPayOnDelivery.splice(index, 1);
      this.paymentMethods.contraentrega.valores = JSON.stringify(
        this.citiesWithPayOnDelivery
      );
    },
    validateAndSubmit(data, payment) {
      this.$refs.observer
        .validate()
        .then(async (response) => {
          if (response) {
            if (data?.createdAt) {
              this.processPaymentData(data, payment, "UPDATE_DATA_PAYMENT");
            } else {
              this.processPaymentData(data, payment, "CREATED_PAYMENTS");
            }
          } else {
            this.$message.warning(`Todos los campos son requeridos`);
          }
        })
        .catch((error) => {
          this.$message.error(`La validación del formulario falló, ${error}`);
        });
    },
    sendDataMethods(data, payment) {
      if (data?.createdAt) {
        this.processPaymentData(data, payment, "UPDATE_DATA_PAYMENT");
      } else {
        this.processPaymentData(data, payment, "CREATED_PAYMENTS");
      }
    },
    async processPaymentData(data, payment, action) {
      this.statusData = false;
      const tempData = JSON.parse(JSON.stringify(data));
      const keysToDelete = ["id", "createdAt", "updatedAt", "estado", "bancos"];
      keysToDelete.forEach((key) => delete tempData[key]);
      if (action === "CREATED_PAYMENTS") {
        tempData.tiendaId = this.storeData.id;
      }
      const { success } = await this.$store.dispatch(action, {
        payment,
        data: tempData,
        storeId: this.storeData.id,
      });
      if (success) {
        this.statusData = true;
        await this.$store.dispatch("GET_STORE_PAYMENTS", {
          storeId: this.storeData.id,
        });
        this.$message.success(`Método de pagos ${payment} esta actualizada!`);
      } else {
        this.statusData = true;
        this.$message.error(
          `Error al cambiar la informacion de ${payment}, fallo de API`
        );
      }
    },
    async updateStateMethods(payment, state) {
      this.statusData = false;
      const { success } = await this.$store.dispatch(
        "ACTIVATE_OR_DISABLE_PAYMENT",
        {
          state,
          payment,
          storeId: this.storeData.id,
        }
      );
      if (success) {
        this.statusData = true;
        await this.$store.dispatch("GET_STORE_PAYMENTS", {
          storeId: this.storeData.id,
        });
        this.$message.success(
          `Método de pagos ${payment} esta ${
            state === "ACTIVATE" ? "activado" : "desactivada"
          }!`
        );
      } else {
        this.statusData = true;
        this.$message.error("Error al cambiar de estado, fallo de API");
      }
    },
  },
};
</script>

<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}

.input-description >>> .el-textarea__inner {
  padding: 10px 8px;
  color: #1f2937;
  border: solid 1px #cdcdcd;
  transition: none;
  @apply rounded-xl;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border: solid 1px #cdcdcd;
  transition: none;
  max-height: 40px;
  @apply rounded-xl;
}
.darks-input >>> .el-input__inner,
.darks-input >>> .el-textarea__inner {
  color: #f3f4f6;
  background-color: #233345;
}
.light-input >>> .el-input__inner,
.light-input >>> .el-textarea__inner {
  color: #1f2937;
  background-color: #f3f4f6;
}

.wrapper-cards >>> .el-radio.is-bordered {
  box-sizing: border-box;
  height: 40px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 1px #737374;
}
.darks-radio >>> .el-radio.is-bordered {
  background-color: #f2efff;
}
.light-radio >>> .el-radio.is-bordered {
  background-color: #233345;
}
.wrapper-payOnDelivery-options {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 170px;
}
.wrapper-payOnDelivery-options::-webkit-scrollbar {
  -webkit-appearance: none;
}
.wrapper-payOnDelivery-options::-webkit-scrollbar:vertical {
  width: 11px;
}
.wrapper-payOnDelivery-options::-webkit-scrollbar-button:increment,
.wrapper-payOnDelivery-options::-webkit-scrollbar-button {
  display: none;
}
.wrapper-payOnDelivery-options::-webkit-scrollbar:horizontal {
  height: 10px;
}
.wrapper-payOnDelivery-options::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.wrapper-actions {
  display: flex;
  margin-top: 20px;
  align-self: flex-end;
}
.wrapper-form {
  margin: 20px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 10px;
}
.alert-form {
  text-align: center;
  display: inline-block;
  margin: 10px auto 0;
  color: #ff0000;
}

.switch__method {
  align-self: flex-end;
}
</style>
