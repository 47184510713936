<template>
  <div
    class="wrapper-content-information mb-4"
    :class="darkMode ? 'darkbg' : 'lightbg'"
  >
    <h2
      class="text-xl font-bold mb-3"
      :class="darkMode ? 'darktxt' : 'lighttxt'"
    >
      Dropshipping
    </h2>
    <div class="row">
      <div class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          ¿Compartir producto con dropshipping?
        </p>
        <p class="details"></p>
        <div
          v-if="
            data.detalle.drop_shipping && data.detalle.drop_shipping.length == 0
          "
          class="radio-ckecked"
          style="margin-top: 8px"
        >
          <el-radio v-model="dropshipping.state" :label="false" border>
            {{ $t("text_inventario_no") }}
          </el-radio>
          <el-radio v-model="dropshipping.state" :label="true" border>
            {{ $t("text_inventario_si") }}
          </el-radio>
        </div>
        <div v-else class="radio-ckecked" style="margin-top: 8px">
          <el-radio
            v-model="data.detalle.drop_shipping[0].estado"
            :label="0"
            border
          >
            {{ $t("text_inventario_no") }}
          </el-radio>
          <el-radio
            v-model="data.detalle.drop_shipping[0].estado"
            :label="1"
            border
          >
            {{ $t("text_inventario_si") }}
          </el-radio>
        </div>
      </div>
      <div class="input-area-full">
        <p
          class="text-subtitle mb-2"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          Comisión del producto
        </p>
        <div
          v-if="
            data.detalle.drop_shipping && data.detalle.drop_shipping.length == 0
          "
          class="flex flex-row el-input"
        >
          <money
            v-model="dropshipping.price"
            v-bind="money"
            class="money-idTienda"
            :class="darkMode ? 'darks-money' : 'light-money'"
          >
          </money>
          <button class="btnUpdatePrice" @click="setDropshipping">
            Activar dropshipping
          </button>
        </div>
        <div v-else class="flex flex-row el-input">
          <money
            v-model="data.detalle.drop_shipping[0].comision"
            v-bind="money"
            class="money-idTienda"
            :class="darkMode ? 'darks-money' : 'light-money'"
          >
          </money>
          <button class="btnUpdatePrice" @click="updateDropshipping">
            Actualizar dropshipping
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setFormatMoney from "../../mixins/formatCurrent";
import { bus } from "../editores/_items/helpers/bus";
export default {
  filters: {
    currency(value) {
      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
  },
  mixins: [setFormatMoney],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropshipping: {
        state: false,
        price: 0,
      },
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  watch: {
    data() {
      this.setFormatMoney(this.idCountryStore);
    },
  },
  created() {
    this.setFormatMoney(this.idCountryStore);
  },
  methods: {
    async setDropshipping() {
      const { success, data } = await this.$store.dispatch("SET_DROPSHIPPING", {
        producto: this.data.detalle.id,
        comision: this.dropshipping.price,
      });
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha agregado al listado de dropshipping!",
          "success"
        );
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    async updateDropshipping() {
      const { success, data } = await this.$store.dispatch(
        "UPDATE_DROPSHIPPING",
        {
          producto: this.data.detalle.id,
          comision: this.data.detalle.drop_shipping[0].comision,
          estado: this.data.detalle.drop_shipping[0].estado,
        }
      );
      if (success) {
        bus.$emit("send-updateProduct", true);
        this.$swal(
          "Felicitaciones!",
          "Se ha actualizado el producto dropshipping!",
          "success"
        );
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
  },
};
</script>

<style scoped>
.btnUpdatePrice {
  background-color: transparent;
  color: #4429b4;
  border: 2px solid #4429b4;
  border-radius: 10px;
  height: 40px;
  max-height: 40px;
  width: 260px;
  margin-left: 10px;
  transition: all ease 0.3s;
}
.btnUpdatePrice:hover {
  color: #35dd8d;
  border: 2px solid #35dd8d;
}
.wrapper-content-information {
  width: 100%;
  padding: 23px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.text-subtitle {
  font-size: 13px;
  font-weight: 500;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.darktxts {
  background-color: #293d53;
  color: #fff;
}
.lighttxts {
  color: rgb(26, 46, 59);
}
.darkbgs {
  background-color: #1b2836;
}
.lightbgs {
  background-color: #fff;
}
.darkbg {
  background-color: #293d53;
}
.lightbg {
  background-color: #fff;
}

.row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 5fr;
  grid-gap: 10px;
}
.input-area {
  max-width: calc(50% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area-full {
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 5px;
}

.input-area .el-input,
.input-area .quillWrapper,
.input-area .el-select {
  width: 100%;
}
.radio-ckecked >>> .el-radio.is-bordered.is-checked {
  border-color: #4429b4;
}
.radio-ckecked >>> .el-radio__input.is-checked + .el-radio__label {
  color: #4429b4;
}
.radio-ckecked >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #4429b4;
  background: #4429b4;
}
.el-radio {
  margin-left: 0 !important;
}
.details {
  font-size: 12px;
  margin: 0;
}
.money-idTienda >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  width: 100%;
  padding: 5px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #f3f4f6;
}
.light-money >>> [type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  color: #1f2937;
}
@media (max-width: 1100px) {
  .row {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .el-radio {
    margin-right: 0px;
  }
  .input-area-full:first-child {
    margin: 0px;
    max-width: 480px;
  }
  .input-area-full {
    margin: 0px;
    max-width: unset;
  }
}
</style>
