import { render, staticRenderFns } from "./Cupones.vue?vue&type=template&id=ae149834&scoped=true"
import script from "./Cupones.vue?vue&type=script&lang=js"
export * from "./Cupones.vue?vue&type=script&lang=js"
import style0 from "./Cupones.vue?vue&type=style&index=0&id=ae149834&prod&scoped=true&lang=css"
import style1 from "./Cupones.vue?vue&type=style&index=1&id=ae149834&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae149834",
  null
  
)

export default component.exports