<template>
  <div v-loading="loading" class="create_service">
    <h2 class="mb-8">Crear servicio</h2>
    <el-row :gutter="30">
      <el-col class="create_service__form mb-4">
        <div class="input-area">
          <p class="text-info mb-2">Seleccionar cliente (Opcional)</p>
          <el-autocomplete
            v-model="IDnumber"
            placeholder="Escribe el nombre del cliente"
            :fetch-suggestions="queryIDnumber"
            :trigger-on-focus="false"
            suffix-icon="el-icon-search"
            class="flex inputs-idTienda light-input"
            clearable
            @clear="clear"
          >
            <template slot-scope="{ item }">
              <div
                class="border-b border-grey mb-2"
                @click="selectCustomer(item.usuario)"
              >
                <p
                  class="font-bold leading-6 text-sm"
                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                >
                  {{ item.usuario.nombre }}
                </p>
                <p
                  class="leading-6 text-xs"
                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                >
                  {{ item.usuario.identificacion }}
                </p>
              </div>
            </template>
          </el-autocomplete>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="12" class="create_service__form">
        <h3 class="mb-6">Lugar de origen (Tienda)</h3>
        <div class="input-area">
          <p class="text-info mb-2">Nombre</p>
          <el-input
            v-model="sender.name"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. Mafe"
            data-vv-name="senderName"
            data-vv-as="nombre"
          />
          <p class="error">
            {{ errors.first("senderName") }}
          </p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Apellido</p>
          <el-input
            v-model="sender.surname"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. Gonzalez"
          />
        </div>
        <div class="input-area">
          <p class="text-info mb-2">C.C.</p>
          <el-input
            v-model="sender.nit"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. 1002390456"
            data-vv-name="senderCc"
            data-vv-as="C.C"
          />
          <p class="error">
            {{ errors.first("senderCc") }}
          </p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Celular</p>
          <el-input
            v-model="sender.cellPhone"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. 3150000000"
            data-vv-name="senderPhone"
            data-vv-as="numero de celular"
          />
          <p class="error">
            {{ errors.first("senderPhone") }}
          </p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Correo electrónico</p>
          <el-input
            v-model="sender.email"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. mafe@miempresa.com"
          />
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Dirección</p>
          <el-input
            v-model="sender.pickupAddress"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. Avenida 40, #0 - 00"
            data-vv-name="senderAddress"
            data-vv-as="dirección"
          />
          <p class="error">
            {{ errors.first("senderAddress") }}
          </p>
        </div>
      </el-col>
      <el-col :span="12" class="create_service__form">
        <h3 class="mb-6">Lugar de destino (Cliente)</h3>
        <div class="input-area">
          <p class="text-info mb-2">Nombre</p>
          <el-input
            v-model="receiver.name"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. Diego"
            data-vv-name="receiver_name"
            data-vv-as="nombre"
          />
          <p class="error">
            {{ errors.first("receiver_name") }}
          </p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Apellido</p>
          <el-input
            v-model="receiver.surname"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. Aguilar"
          />
        </div>
        <div class="input-area">
          <p class="text-info mb-2">C.C.</p>
          <el-input
            v-model="receiver.nit"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. 1202390456"
            data-vv-name="receiverCc"
            data-vv-as="C.C"
          />
          <p class="error">
            {{ errors.first("receiverCc") }}
          </p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Celular</p>
          <el-input
            v-model="receiver.cellPhone"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. 3120000000"
            data-vv-name="receiver_phone"
            data-vv-as="numero de celular"
          />
          <p class="error">
            {{ errors.first("receiver_phone") }}
          </p>
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Correo electrónico</p>
          <el-input
            v-model="receiver.email"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. user@mail.com"
          />
        </div>
        <div class="input-area">
          <p class="text-info mb-2">Dirección</p>
          <el-input
            v-model="receiver.destinationAddress"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            placeholder="Ej. Cll 00A #00C - 00 El trapiche"
            data-vv-name="receiver_destinationAddress"
            data-vv-as="dirección de destino"
          />
          <p class="error">
            {{ errors.first("receiver_destinationAddress") }}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30" class="my-4">
      <el-col :span="12" class="create_service__form">
        <div class="input-area">
          <p class="text-info mb-2">Comentarios (Opcional)</p>
          <el-input
            v-model="comments"
            class="inputs-idTienda light-input"
            type="textarea"
            :rows="2"
            placeholder="Si tienes una observación puedes escribirla aquí"
          />
        </div>
      </el-col>
      <el-col :span="12" class="create_service__form">
        <div class="input-area">
          <p class="text-info mb-2">Descripción del producto</p>
          <el-input
            v-model="description"
            v-validate="'required'"
            class="inputs-idTienda light-input"
            type="textarea"
            :rows="2"
            placeholder="Déjanos una descripción de los productos que vas a enviar"
            data-vv-name="description"
            data-vv-as="descripción"
          />
          <p class="error">
            {{ errors.first("description") }}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="12">
        <div class="summary summary__pack">
          <h3 class="mb-4">Información del producto</h3>
          <div>
            <p
              v-if="
                quotationPayload.paymentType == 102 &&
                quotationPayload.addShippingCost
              "
            >
              <strong>Valor envió + Valor venta:</strong>
              {{ quotationPayload.valueCollection | currency }}
            </p>
            <p v-else>
              <strong>Valor total:</strong>
              {{ quotationPayload.declaredValue | currency }}
            </p>
            <p
              v-if="
                quotationPayload.width &&
                quotationPayload.large &&
                quotationPayload.height
              "
            >
              <strong>Dimensiones:</strong> {{ quotationPayload.width }} x
              {{ quotationPayload.large }} x {{ quotationPayload.height }}CM
            </p>
            <p><strong>Peso:</strong> {{ quotationPayload.weight }}KG</p>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="summary summary__general">
          <img
            loading="lazy"
            :src="
              currentCourier.deliveryCompanyImgUrl
                ? currentCourier.deliveryCompanyImgUrl
                : require('@/assets/mipaquete/placeholder.png')
            "
          />
          <div>
            <div v-if="currentCourier">
              <h4>{{ currentCourier.deliveryCompanyName }}</h4>
              <p>
                <strong>Valor envió:</strong>
                {{ currentCourier.shippingCost | currency }}
              </p>
            </div>
            <p>
              <strong>Origen:</strong> {{ origin.locationName }} -
              {{ origin.departmentOrStateName }}
            </p>
            <p>
              <strong>Destino:</strong> {{ destiny.locationName }} -
              {{ destiny.departmentOrStateName }}
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end" class="my-6" :gutter="30">
      <el-button @click.native="goBack">Volver</el-button>
      <el-button
        class="bg-purple-komercia-50 px-4 py-1.5 ml-3 rounded-md text-gray-100"
        @click.native="createService"
      >
        Crear Servicio
      </el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new", // inject a new validator to be used by the directives.
  },
  filters: {
    currency(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
  data() {
    return {
      IDnumber: "",
      statePreEnvio: false,
      loading: false,
      sender: {
        name: "",
        surname: "",
        prefix: "+57",
        cellPhone: "",
        email: "",
        pickupAddress: "",
        nit: "",
        nitType: "CC",
      },
      receiver: {
        name: "",
        surname: "",
        nit: "",
        cellPhone: null,
        prefix: "+57",
        email: "",
        destinationAddress: "",
        nitType: "CC",
      },
      selectedCustomer: null,
      comments: "",
      description: "",
    };
  },
  computed: {
    quotationPayload() {
      return this.$store.state.mipaquete.quotationPayload;
    },
    currentCourier() {
      return this.$store.state.mipaquete.currentCourier;
    },
    order() {
      return this.$store.state.invoice && this.$store.state.invoice.venta
        ? this.$store.state.invoice
        : "";
    },
    towns() {
      return this.$store.state.mipaquete.towns;
    },
    origin() {
      return this.towns.find(
        (item) => item.locationCode === this.quotationPayload.origin
      );
    },
    destiny() {
      return this.towns.find(
        (item) => item.locationCode === this.quotationPayload.destiny
      );
    },
  },
  watch: {
    selectedCustomer(value) {
      if (value) {
        this.receiver.name = value.nombre;
        this.receiver.surname = value.user_info[0].apellido;
        this.receiver.nit = value.identificacion;
        this.receiver.cellPhone = value.user_info[0].telefono;
        this.receiver.email = value.email;
        this.receiver.destinationAddress = value.user_info[0].direccion;
      } else {
        this.receiver.name = "";
        this.receiver.surname = "";
        this.receiver.nit = "";
        this.receiver.cellPhone = "";
        this.receiver.email = "";
        this.receiver.destinationAddress = "";
      }
    },
  },
  created() {
    if (this.$route.query.pre_envio == "true") {
      this.statePreEnvio = true;
    }
  },
  mounted() {
    this.$route.query.order_id
      ? this.assignOrderData()
      : this.assignNoOrderData();
  },
  methods: {
    goBack() {
      this.$store.commit("mipaquete/PREVIOUS_STAGE");
    },
    async assignOrderData() {
      if (this.order) {
        try {
          this.sender.name = this.order.venta.tienda_venta.nombre;
          this.sender.email =
            this.order.venta.tienda_venta.informacion_tienda[0].email_tienda;
          this.sender.cellPhone =
            this.order.venta.tienda_venta.informacion_tienda[0].telefono;
          if (this.statePreEnvio) {
            if (
              this.order.venta.mi_paquete_pre_envio &&
              this.order.venta.mi_paquete_pre_envio[0].receiver
            ) {
              let resReceiver = JSON.parse(
                this.order.venta.mi_paquete_pre_envio[0].receiver
              );
              this.receiver.name = resReceiver.name;
              this.receiver.surname = resReceiver.surname;
              this.receiver.cellPhone = resReceiver.cellPhone;
              this.receiver.email = resReceiver.email;
              this.receiver.destinationAddress = resReceiver.destinationAddress;
              this.receiver.nit = resReceiver.nit;
              this.receiver.nitType = resReceiver.nitType;
            }
          } else {
            if (
              this.order.venta.direccion_entrega &&
              this.order.venta.direccion_entrega.type === 1
            ) {
              this.receiver.name =
                this.order.venta.direccion_entrega.value.nombre;
              this.receiver.surname = this.order.venta.direccion_entrega.value
                .apellido
                ? this.order.venta.direccion_entrega.value.apellido
                : "";
              this.receiver.cellPhone =
                this.order.venta.direccion_entrega.value.celular;
              this.receiver.email = this.order.venta.usuario.email;
              this.receiver.destinationAddress = `${this.order.venta.direccion_entrega.value.direccion} ${this.order.venta.direccion_entrega.value.barrio}`;
            }
          }
        } catch (err) {
          console.warn(err);
        }
      }
    },
    async assignNoOrderData() {
      const user = this.$store.state.user;
      if (user) {
        this.sender.name = user.nombre ? user.nombre : "";
        this.sender.email = user.email ? user.emai : "";
        this.sender.nit = user.identificacion ? user.identificacion : "";
      }
    },
    async createService() {
      this.$validator.validate().then(async (result) => {
        if (result) {
          this.loading = true;
          const { success, data } = await this.$store.dispatch(
            "mipaquete/CREATE_SERVICE",
            {
              sender: this.sender,
              receiver: this.receiver,
              comments: this.comments,
              description: this.description,
            }
          );
          if (success) {
            if (this.statePreEnvio) {
              await this.$store.dispatch("mipaquete/EDIT_SERVICE_KOMERCIA", {
                miPaqueteResponse: data,
                orderId: this.$route.query.order_id,
                value_id: this.$route.query.order_mipaquete,
              });
            } else {
              await this.$store.dispatch("mipaquete/CREATE_SERVICE_KOMERCIA", {
                miPaqueteResponse: data,
                orderId: this.$route.query.order_id,
              });
            }
            this.$store.commit("mipaquete/SET_INDEX_STAGE", 1);
            this.$notify({
              title: "Creada correctamente!",
              message: data.message.detail,
              type: "success",
              duration: 9000,
            });
          } else {
            this.$notify({
              title: "Error",
              message: data.message.detail,
              type: "error",
              duration: 9000,
            });
          }
          this.loading = false;
        }
      });
    },
    selectCustomer(customers) {
      this.selectedCustomer = customers;
    },
    async queryIDnumber(queryString, resolve) {
      const { success, data } = await this.$store.dispatch("FILTER_CUSTOMERS", {
        type: "customer_name",
        value: queryString,
        page: "1",
      });
      if (success) {
        resolve(data.data);
      }
    },
    clear() {
      this.selectedCustomer = [];
    },
  },
};
</script>

<style scoped>
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.input-area {
  width: 100%;
}
.input-idTienda {
  padding: 12px 11px;
  border-radius: 10px;
  background-color: #f2efff;
  font-size: 13px;
  max-height: 40px;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-idTienda-valor >>> .el-input__inner {
  padding: 0px 25px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-dominio >>> .el-input__inner {
  font-size: 13px;
  border: none;
  background-color: transparent;
  transition: none;
  padding: 0px;
  height: 20px;
  max-height: 20px;

  @apply ml-2;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.create_service {
  padding: 20px 35px;
}
.create_service__form {
  border: 1px dashed #d8dde3;
  border-radius: 3px;
  padding: 15px;
}
h3 {
  font-weight: 600;
}
.summary {
  display: flex;
  background-color: #f2efff;
  padding: 15px;
  border-radius: 3px;
}
.summary__general img {
  width: 100px;
  margin-right: 15px;
  object-fit: contain;
}
.summary__general h4 {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.summary__pack {
  display: grid;
}
.summary__pack > div {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.summary p {
  font-weight: 400;
  margin-bottom: 4px;
}
.summary p strong {
  font-weight: 600;
}
.error {
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #f14b5a;
}
</style>
