<template>
  <div v-if="integrationSelected">
    <div class="mt-10" :is="integrationSelected.component"></div>
  </div>
  <page404 v-else />
</template>

<script>
import MiPaquete from "./MiPaquete";
import page404 from "./page404";

export default {
  name: "DashboardEnvios",
  components: { page404, MiPaquete },
  data() {
    return {
      integrations: [
        {
          slug: "mi-paquete",
          name: "MiPaquete",
          component: "MiPaquete",
        },
        {
          slug: "mensajeros-urbanos",
          name: "Mensajeros Urbanos",
          path: "./MiPaquete/layout.vue",
        },
        {
          slug: "envio-click",
          name: "Envioclick",
          path: "./MiPaquete/layout.vue",
        },
      ],
    };
  },
  computed: {
    integrationSelected() {
      return this.integrations.find(
        (i) => i.slug === this.$route.params.integration
      );
    },
  },
};
</script>
