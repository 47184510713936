<template>
  <div
    class="wrapper-content-items relative"
    :class="darkMode ? 'darkbgs' : 'lightbgs'"
  >
    <div v-if="productData" class="w-full flex flex-col">
      <div class="product_form_breadcrumb">
        <nav class="flex wrapper-invoice-nav" aria-label="Breadcrumb">
          <ol class="flex items-center space-x-4">
            <li>
              <div>
                <router-link to="/" class="text-gray-400 hover:text-gray-500">
                  <svg
                    class="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                    />
                  </svg>
                  <span class="sr-only">Home</span>
                </router-link>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <router-link
                  to="/inventario/productos#1"
                  class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {{ $t("text_inventario_lisdatoProducto") }}
                </router-link>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <p
                  v-if="productData && productData.detalle.id"
                  class="ml-4 text-sm font-medium text-gray-500"
                >
                  <strong>{{ $t("text_inventario_productoN") }} :</strong>
                  {{ productData.detalle.id }}
                </p>
              </div>
            </li>
            <li>
              <div class="ml-6">
                <ProductStatitics :product="productData" />
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div v-if="productData" class="w-full flex flex-col">
        <div class="backandhelp">
          <ButtonHelp catstay="Productos" routeback="/inventario/productos#1" />
        </div>
        <div class="item-content-user">
          <div class="grid-item-user">
            <div class="item-left sticky top-0 md:top-10">
              <div v-for="(item, index) in menu" :key="index" class="w-full">
                <router-link class="w-full" :to="item.urlDirect">
                  <button
                    class="item-settings"
                    :class="
                      !darkMode && item.selectSetting == selectSetting
                        ? 'user-light '
                        : darkMode && item.selectSetting == selectSetting
                        ? 'user-dark'
                        : ''
                    "
                    @click="selectSettings(item.id)"
                  >
                    <svg
                      v-if="item.id === 1"
                      class="svg h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                      :class="
                        darkMode || item.selectSetting == selectSetting
                          ? 'darktxt'
                          : 'lighttxt'
                      "
                    >
                      <g>
                        <path
                          d="M20 17V8C20 7.44772 19.5523 7 19 7H16.618C16.2393 7 15.893 6.786 15.7236 6.44721L15.2764 5.55279C15.107 5.214 14.7607 5 14.382 5H9.61803C9.23926 5 8.893 5.214 8.72361 5.55279L8.27639 6.44721C8.107 6.786 7.76074 7 7.38197 7H5C4.44772 7 4 7.44772 4 8V17C4 17.5523 4.44772 18 5 18H19C19.5523 18 20 17.5523 20 17Z"
                        />
                        <path
                          d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        />
                      </g>
                    </svg>
                    <svg
                      v-if="item.id === 2"
                      :class="
                        darkMode || item.selectSetting == selectSetting
                          ? 'darktxt'
                          : 'lighttxt'
                      "
                      class="svg h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.71,16.71l-2.42-2.42a1,1,0,0,0-1.42,0l-3.58,3.58a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1h2.42a1,1,0,0,0,.71-.29l3.58-3.58A1,1,0,0,0,20.71,16.71ZM16,20H15V19l2.58-2.58,1,1Zm-6,0H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h5V7a3,3,0,0,0,3,3h3v1a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0L12.06,2H6A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3h4a1,1,0,0,0,0-2ZM13,5.41,15.59,8H14a1,1,0,0,1-1-1ZM8,14h6a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2Zm0-4H9A1,1,0,0,0,9,8H8a1,1,0,0,0,0,2Zm2,6H8a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"
                      />
                    </svg>
                    <svg
                      v-if="item.id === 3"
                      :class="
                        darkMode || item.selectSetting == selectSetting
                          ? 'darktxt'
                          : 'lighttxt'
                      "
                      class="svg h-6 w-6"
                      fill="currentColor"
                      viewBox="-2 0 19 19"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m13.842 11.52-4.389 4.388a1.112 1.112 0 0 1-1.567 0l-6.28-6.28a3.027 3.027 0 0 1-.771-1.892l.043-3.681A1.141 1.141 0 0 1 2 2.935L5.67 2.9a3.04 3.04 0 0 1 1.892.773l6.28 6.28a1.112 1.112 0 0 1 0 1.567zM3.826 5.133a.792.792 0 1 0-.792.792.792.792 0 0 0 .792-.792zm6.594 7.348a.554.554 0 0 0 0-.784l-.401-.401a2.53 2.53 0 0 0 .35-.83 1.565 1.565 0 0 0-.397-1.503 1.59 1.59 0 0 0-1.017-.46 2.14 2.14 0 0 0-.75.085h-.002a2.444 2.444 0 0 0-.59.277H7.61a2.677 2.677 0 0 0-.438.357 2.043 2.043 0 0 1-.259.22 1.29 1.29 0 0 1-.329.17h-.002a.835.835 0 0 1-.338.038h-.002a.53.53 0 0 1-.314-.136.539.539 0 0 1-.106-.534 1.54 1.54 0 0 1 .41-.71 1.632 1.632 0 0 1 .23-.165l.03-.019a1.783 1.783 0 0 1 .322-.155.942.942 0 0 1 .325-.06.554.554 0 0 0 0-1.108h-.001a2.058 2.058 0 0 0-.717.132 2.846 2.846 0 0 0-.529.26l-.01.006-.398-.4a.554.554 0 1 0-.784.785l.388.387a2.513 2.513 0 0 0-.347.803 1.644 1.644 0 0 0 .404 1.561 1.622 1.622 0 0 0 .983.456 1.922 1.922 0 0 0 .805-.089 2.372 2.372 0 0 0 .624-.319 3.142 3.142 0 0 0 .398-.339 1.569 1.569 0 0 1 .256-.208 1.381 1.381 0 0 1 .32-.151 1.023 1.023 0 0 1 .348-.038.485.485 0 0 1 .308.139c.05.049.165.165.097.488a1.558 1.558 0 0 1-.413.729 2.476 2.476 0 0 1-.28.219 1.727 1.727 0 0 1-.306.157.687.687 0 0 1-.32.042.554.554 0 1 0-.08 1.106c.052.004.103.005.152.005a1.723 1.723 0 0 0 .685-.134 2.678 2.678 0 0 0 .507-.27l.01-.007.397.398a.555.555 0 0 0 .783 0z"
                      />
                    </svg>
                    <svg
                      v-if="item.id === 4"
                      :class="
                        darkMode || item.selectSetting == selectSetting
                          ? 'darktxt'
                          : 'lighttxt'
                      "
                      class="svg h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.236 5.316c-0.003-0.021-0.017-0.037-0.021-0.057-0.023-0.094-0.053-0.176-0.092-0.253l0.003 0.007c-0.029-0.078-0.061-0.144-0.098-0.207l0.003 0.006c-0.054-0.073-0.114-0.137-0.179-0.194l-0.001-0.001c-0.038-0.047-0.077-0.089-0.118-0.128l-0.001-0.001c-0.012-0.009-0.028-0.010-0.041-0.019-0.062-0.037-0.133-0.070-0.208-0.097l-0.009-0.003c-0.070-0.036-0.152-0.067-0.238-0.088l-0.008-0.002c-0.014-0.003-0.025-0.012-0.040-0.015l-12.999-2c-0.057-0.009-0.123-0.015-0.19-0.015s-0.133 0.005-0.197 0.016l0.007-0.001-13 2c-0.017 0.003-0.030 0.014-0.047 0.017-0.083 0.022-0.154 0.049-0.222 0.082l0.007-0.003c-0.092 0.031-0.172 0.067-0.247 0.112l0.006-0.003c-0.012 0.008-0.028 0.009-0.040 0.018-0.042 0.040-0.081 0.082-0.117 0.126l-0.002 0.002c-0.067 0.058-0.126 0.122-0.178 0.192l-0.002 0.003c-0.035 0.058-0.067 0.124-0.093 0.194l-0.003 0.008c-0.035 0.070-0.065 0.151-0.086 0.236l-0.002 0.008c-0.004 0.021-0.018 0.037-0.021 0.058l-1 6.75c-0.009 0.055-0.014 0.119-0.014 0.184 0 0.601 0.425 1.104 0.991 1.223l0.008 0.001 1.001 0.205v12.321c0 0.6 0.423 1.102 0.987 1.223l0.008 0.001 12 2.5c0.077 0.016 0.165 0.025 0.255 0.025s0.178-0.009 0.263-0.027l-0.008 0.001 12.001-2.5c0.572-0.123 0.994-0.625 0.994-1.225v-12.321l1-0.205c0.575-0.12 1-0.623 1-1.225 0-0.065-0.005-0.128-0.014-0.191l0.001 0.007zM28.59 11.263l-8.855 1.812-1.94-4.366 10.157-1.758zM16 4.765l5.252 0.808-5.252 0.909-5.252-0.909zM3.41 11.263l0.639-4.312 10.157 1.758-1.94 4.366zM5.25 14.191l7.499 1.534c0.075 0.016 0.161 0.025 0.249 0.025 0.507 0 0.944-0.301 1.141-0.734l0.003-0.008 0.607-1.367v13.321l-9.5-1.978zM26.75 24.984l-9.5 1.978v-13.321l0.607 1.367c0.2 0.441 0.636 0.742 1.142 0.742 0 0 0 0 0 0v0c0.001 0 0.002 0 0.002 0 0.088 0 0.173-0.009 0.256-0.027l-0.008 0.001 7.5-1.534z"
                      ></path>
                    </svg>
                    <svg
                      v-if="item.id === 6"
                      :class="
                        darkMode || item.selectSetting == selectSetting
                          ? 'darktxt'
                          : 'lighttxt'
                      "
                      class="svg h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M 0 6 L 0 8 L 19 8 L 19 23 L 12.84375 23 C 12.398438 21.28125 10.851563 20 9 20 C 7.148438 20 5.601563 21.28125 5.15625 23 L 4 23 L 4 18 L 2 18 L 2 25 L 5.15625 25 C 5.601563 26.71875 7.148438 28 9 28 C 10.851563 28 12.398438 26.71875 12.84375 25 L 21.15625 25 C 21.601563 26.71875 23.148438 28 25 28 C 26.851563 28 28.398438 26.71875 28.84375 25 L 32 25 L 32 16.84375 L 31.9375 16.6875 L 29.9375 10.6875 L 29.71875 10 L 21 10 L 21 6 Z M 1 10 L 1 12 L 10 12 L 10 10 Z M 21 12 L 28.28125 12 L 30 17.125 L 30 23 L 28.84375 23 C 28.398438 21.28125 26.851563 20 25 20 C 23.148438 20 21.601563 21.28125 21.15625 23 L 21 23 Z M 2 14 L 2 16 L 8 16 L 8 14 Z M 9 22 C 10.117188 22 11 22.882813 11 24 C 11 25.117188 10.117188 26 9 26 C 7.882813 26 7 25.117188 7 24 C 7 22.882813 7.882813 22 9 22 Z M 25 22 C 26.117188 22 27 22.882813 27 24 C 27 25.117188 26.117188 26 25 26 C 23.882813 26 23 25.117188 23 24 C 23 22.882813 23.882813 22 25 22 Z"
                      />
                    </svg>
                    <p
                      class="txt-setting"
                      :class="
                        darkMode || item.selectSetting == selectSetting
                          ? 'darktxt'
                          : 'lighttxt'
                      "
                    >
                      {{ $t(item.label) }}
                    </p>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="item-right">
              <Photos
                v-if="selectSetting === 1"
                :data="productData"
                :youtube="productData.info[0].video"
                @upload="getPhoto"
                @update:youtube="getYoutube"
              />
              <Information v-if="selectSetting === 2" :data="productData" />

              <Inventory
                v-if="selectSetting === 3"
                :data="productData"
                @change="updateWithVariant"
              />
              <div
                v-if="selectSetting === 4"
                class="w-full"
                :isAsideComponent="!isAsideComponent"
              >
                <AsideComponent :data="productData" />
              </div>
              <dropshipping v-if="selectSetting === 6" :data="productData" />
              <div v-if="selectSetting != 6" class="actions">
                <el-button
                  class="pull-right"
                  type="danger"
                  plain
                  :disabled="!stateSave ? false : true"
                  @click="redirectTo"
                >
                  {{ $t("text_inventario_cancelar") }}
                </el-button>
                <el-button
                  type="primary"
                  class="pull-right"
                  :disabled="!stateSave ? false : true"
                  @click="updateProduct"
                >
                  <i v-if="stateSave" class="el-icon-loading" />
                  {{ $t("text_inventario_guardar") }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <el-tooltip
          v-if="selectSetting != 6 && selectSetting != 5 && !stateSave"
          class="item"
          effect="dark"
          content="Cancelar"
          placement="left"
        >
          <router-link
            to="/inventario/productos#1"
            class="fixed rounded-full p-3 right-7 bg-red-500 hover:bg-red-700"
            style="bottom: 185px"
          >
            <svg
              class="h-5 w-5"
              viewBox="0 0 21 21"
              xmlns="http://www.w3.org/2000/svg"
              fill="transparent"
            >
              <g id="Interface / Exit">
                <path
                  id="Vector"
                  d="M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="selectSetting != 6 && selectSetting != 5"
          class="item"
          effect="dark"
          :content="!stateSave ? 'Guardar producto' : 'Guardando producto'"
          placement="left"
        >
          <button
            class="fixed rounded-full p-3 bottom-32 right-7 bg-purple-600 hover:bg-purple-900"
            :disabled="!stateSave ? false : true"
            @click="updateProduct()"
          >
            <p v-if="stateSave" class="h-5 w-5">
              <i class="el-icon-loading text-white" />
            </p>
            <svg
              v-else
              id="Capa_1"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 330 330"
              style="enable-background: new 0 0 330 330"
              xml:space="preserve"
              class="h-5 w-5"
              fill="white"
            >
              <path
                d="M325.606,84.668L245.334,4.394c-2.813-2.813-6.628-4.393-10.607-4.393H195.02C195.013,0.001,195.007,0,195,0H75
	c-0.007,0-0.013,0.001-0.02,0.001H15c-8.284,0-15,6.716-15,15V315c0,8.284,6.716,15,15,15h60h180h60c8.284,0,15-6.716,15-15V95.274
	C330,91.296,328.42,87.48,325.606,84.668z M90,30.001h30V70c0,8.284,6.716,15,15,15s15-6.716,15-15V30.001h30V110H90V30.001z
M240,240H90v-30h150V240z M90,300v-30h150v30H90z M300,300h-30V195c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v105H30
	V30.001h30V125c0,8.284,6.716,15,15,15h120c8.284,0,15-6.716,15-15V30.001h18.515L300,101.487V300z"
              />
            </svg>
          </button>
        </el-tooltip>
        <div v-if="modalCategory" class="bgModal" @click="closeCategory">
          <div class="divmodal">
            <Modal-Category :state-category="true" />
          </div>
        </div>
        <div v-if="modalEtiquetas" class="bgModal" @click="closeCategory">
          <div class="divmodal">
            <Modal-Etiquetas :state-etiquetas="true" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-skeleton style="width: 100%">
        <template slot="template">
          <el-skeleton-item
            variant="image"
            style="width: 240px; height: 240px"
          />
          <div style="padding: 14px">
            <el-skeleton
              :rows="1"
              variant="h1"
              style="width: 500px"
              class="my-5"
            />
            <el-skeleton :rows="8" variant="p" class="w-full" />
          </div>
        </template>
      </el-skeleton>
    </div>
    <div v-show="stateSave" class="modal">
      <div class="w-full flex justify-center items-center">
        <div class="w-full flex flex-col justify-center items-center">
          <div class="peeek-loading">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div
            style="top: 500px"
            class="absolute flex flex-col justify-center items-center animate-pulse"
          >
            <p class="text-white">Guardando la Información.</p>
            <p class="text-white">Por favor espere ⏳</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ButtonHelp from "@/components/Help/k-helpbutton.vue";
import Photos from "@/components/ProductForm/Photos.vue";
import Information from "@/components/ProductForm/Information.vue";
import AsideComponent from "@/components/ProductForm/Aside.vue";
import Inventory from "@/components/ProductForm/Inventory.vue";
import ModalCategory from "@/views/Inventory/Categories.vue";
import ModalEtiquetas from "@/views/Inventory/Tags.vue";
import dropshipping from "@/components/ProductForm/dropshipping.vue";
import ProductStatitics from "@/components/ProductStatitics.vue";
// import duplicateProduct from "@/components/DuplicateProductDialog.vue";
import { bus } from "@/components/editores/_items/helpers/bus";
export default {
  components: {
    ButtonHelp,
    Photos,
    Information,
    Inventory,
    AsideComponent,
    ModalCategory,
    ModalEtiquetas,
    dropshipping,
    ProductStatitics,
    // duplicateProduct,
  },
  data() {
    return {
      stateSave: false,
      visibleDuplicateProduct: false,
      stateTags: false,
      selectSetting: 2,
      menu: [],
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    modalCategory() {
      return this.$store.state.modalCategory;
    },
    modalEtiquetas() {
      return this.$store.state.modalEtiquetas;
    },
    productsData() {
      return this.$store.state.productsData;
    },
    productData() {
      return this.$store.state.productData;
    },
    isAsideComponent() {
      let resp = false;
      this.$store.state.storeData.entidades.forEach(
        (entidad) => (resp = entidad.id === 9 ? true : false)
      );
      return resp;
    },
    subdomain() {
      return this.$store.state.storeData.subdominio;
    },
    variantsJSON() {
      return JSON.stringify(this.$store.getters.getValidateVariants);
    },
    variantsNormal() {
      return this.$store.state.variants;
    },
    combinations() {
      return JSON.stringify(this.$store.state.combinations);
    },
    selectedTab() {
      return this.$store.state.productData.detalle.con_variante;
    },
    storeData() {
      return this.$store.state.storeData;
    },
  },
  watch: {
    storeData() {
      this.setMenu();
    },
    // $route(to, from) {
    //   if (to.query.p != from.query.p) {
    //     this.updateProduct()
    //   } else {
    //     console.log('Es igual la ubicación')
    //   }
    // },
    productData() {
      this.setCombinations();
    },
  },
  beforeCreate() {
    this.$store.commit("SET_PRODUCT", null);
  },
  async created() {
    this.setMenu();

    if (this.$route.query && this.$route.query.p) {
      let item = this.menu.find((item) => item.id == this.$route.query.p);
      this.selectSetting = item.id;
    }
    this.$store.dispatch("GET_CATEGORIES");
    // this.$store.dispatch('GET_PRODUCT', this.$route.params.id)-
    const { success } = await this.$store.dispatch(
      "GET_PRODUCT",
      this.$route.params.id
    );
    if (success) {
      this.setCombinations();
    }
    this.$store.dispatch("GET_ALL_TAGS");
    bus.$on("send-updateProduct", this.SendTagsUpdate);
  },
  beforeDestroy() {
    bus.$off("send-updateProduct", this.SendTagsUpdate);
  },
  destroyed() {
    this.$store.commit("SET_PRODUCT", null);
  },
  methods: {
    setCombinations() {
      if (typeof this.productData.combinaciones.combinaciones === "string") {
        this.$store.state.combinations = JSON.parse(
          this.productData.combinaciones.combinaciones
        );
      } else {
        this.$store.state.combinations = [];
      }
      this.$store.state.variants = this.productData.variantes;
    },
    selectSettings(value) {
      if (value) {
        this.selectSetting = value;
      }
    },
    SendTagsUpdate(value) {
      if (value) {
        this.updateProduct();
        this.stateTags = true;
      }
    },
    getYoutube(url) {
      this.productData.info[0].video = url;
    },
    getPhoto(photo) {
      if (photo.type === "major") {
        this.updateMajorPhoto(photo);
      } else {
        this.updateMinorPhoto(photo);
      }
    },
    async updateMajorPhoto(photo) {
      let params = new FormData();
      params.append("id_producto", this.productData.detalle.id);
      params.append("foto_cloudinary", photo.payload.secure_url);
      params.append("id_cloudinary", photo.payload.public_id);
      await axios.post(
        `${this.$configKomercia.url}/api/admin/producto/imagen/principal/cambiar`,
        params,
        this.$store.state.configAxios
      );
      await this.$store.dispatch(
        "REMOVE_CLOUDINARY_PHOTO",
        this.productData.detalle.id_cloudinary
      );
      this.productData.detalle.foto_cloudinary = photo.payload.secure_url;
      this.productData.detalle.id_cloudinary = photo.payload.public_id;
      const index = this.productsData.findIndex(
        (product) => product.id === this.productData.detalle.id
      );
      const product = this.productsData.find(
        (product) => product.id === this.productData.detalle.id
      );
      product.foto_cloudinary = photo.payload.secure_url;
      this.productsData.splice(index, 1, product);
    },
    async updateMinorPhoto(photo) {
      let params = new FormData();
      params.append("id_producto", this.productData.detalle.id);
      params.append("foto_cloudinary", photo.payload.secure_url);
      params.append("id_cloudinary", photo.payload.public_id);
      const data = this.$store.dispatch(
        "UPLOAD_EXTRA_PHOTO_OF_PRODUCT",
        params
      );
      let resultado = {};
      await data.then((result) => (resultado = result));
      if (resultado.status === 200) {
        this.productData.fotos.push(resultado.data.data);
      }
    },
    updateWithVariant(state) {
      this.productData.detalle.con_variante = state.active;
    },
    async dataSave() {
      this.stateSave = true;
      // window.scroll({
      //   top: 0,
      //   behavior: 'smooth',
      // })
      let productData = this.productData;
      let params = {
        id: this.$route.params.id,
        nombre: productData.detalle.nombre.trimStart().trimEnd(),
        marca: productData.info[0].marca,
        precio: this.selectedTab === 0 ? productData.detalle.precio || 0 : 0,
        garantia: productData.info[0].garantia,
        disponibilidad: productData.detalle.disponibilidad,
        descripcion_corta: productData.info[0].descripcion_corta,
        descripcion: productData.info[0].descripcion,
        video: productData.info[0].video,
        categoria_producto: productData.detalle.categoria_producto.id || 0,
        subcategoria: productData.detalle.subcategoria_producto.id || 0,
        activo: productData.detalle.activo,
        sku: productData.info[0].sku,
        peso: productData.info[0].peso,
        alto: productData.info[0].alto,
        ancho: productData.info[0].ancho,
        largo: productData.info[0].largo,
        inventario: this.selectedTab === 0 ? productData.info[0].inventario : 0,
        variantes: this.selectedTab === 1 ? this.variantsJSON : "[]",
        combinaciones: this.selectedTab === 1 ? this.combinations : "[]",
        envio_gratis: productData.detalle.envio_gratis,
        con_variante:
          this.selectedTab === 1 ? productData.detalle.con_variante : 0,
        tag: !this.isAsideComponent ? productData.detalle.tag : null,
        favorito: productData.detalle.favorito,
        orden: productData.detalle.orden,
        activar_mensajes: productData.info[0].activar_mensajes,
        label_mensaje: productData.info[0].label_mensaje,
        mensaje_obligatorio: productData.info[0].mensaje_obligatorio,
        mensaje: productData.info[0].mensaje,
        promocion_valor: productData.info[0].promocion_valor,
        tag_promocion: productData.info[0].tag_promocion,
        tipo_servicio: productData.info[0].tipo_servicio
          ? productData.info[0].tipo_servicio
          : "0",
        dealer_whatsapp:
          this.selectedTab === 1 && this.storeData.id === 16436 ? true : false,
        condicion: productData.info[0].condicion,
      };
      const { status, data } = await this.$store.dispatch(
        "UPDATE_PRODUCT",
        params
      );
      if (status === 200) {
        let productsData = this.$store.state.productsData;
        let productIndex = productsData.findIndex(
          (product) => product.id === parseInt(this.$route.params.id)
        );
        let newProduct = {
          activo: parseInt(data.data.detalle.activo),
          foto_cloudinary: data.data.detalle.foto_cloudinary,
          id: data.data.detalle.id,
          nombre: data.data.detalle.nombre,
          precio: data.data.detalle.precio || 0,
          visitas: 0,
          inventario: data.data.info[0].inventario,
          slug: data.data.detalle.slug,
          con_variante: data.data.detalle.con_variante,
          variantes: [
            {
              combinaciones: [
                {
                  combinaciones: data.data.combinaciones.combinaciones,
                },
              ],
            },
          ],
        };
        productsData.splice(productIndex, 1, newProduct);
        this.stateSave = false;
        if (!this.stateTags) {
          this.$message.success("Datos del producto guardados!");
          this.$store.dispatch("GET_PRODUCT", this.$route.params.id);
          // this.$router.push('/inventario/productos#1')
        } else {
          this.$message.success("Datos del producto guardados!");
          this.$store.dispatch("GET_PRODUCT", this.$route.params.id);
        }
        this.stateTags = false;
      } else {
        this.$notify.error(Object.values(data.errores)[0][0]);
      }
    },
    async updateProduct() {
      if (this.selectedTab === 1) {
        if (this.variantsNormal.length === 3) {
          if (
            this.variantsNormal[0].nombre === "" ||
            this.variantsNormal[1].nombre === "" ||
            this.variantsNormal[2].nombre === "" ||
            this.variantsNormal[0].valores.length === 0 ||
            this.variantsNormal[1].valores.length === 0 ||
            this.variantsNormal[2].valores.length === 0
          ) {
            this.$message.error("No se permiten campos vacíos");
          } else {
            this.dataSave();
          }
        } else if (this.variantsNormal.length === 2) {
          if (
            this.variantsNormal[0].nombre === "" ||
            this.variantsNormal[1].nombre === "" ||
            this.variantsNormal[0].valores.length === 0 ||
            this.variantsNormal[1].valores.length === 0
          ) {
            this.$message.error("No se permiten campos vacíos");
          } else {
            this.dataSave();
          }
        } else if (this.variantsNormal.length === 1) {
          if (
            this.variantsNormal[0].nombre === "" ||
            this.variantsNormal[0].valores.length === 0
          ) {
            this.$message.error("No se permiten campos vacíos");
          } else {
            this.dataSave();
          }
        } else {
          this.$message.warning(
            'Si tu producto no tiene variantes, selecciona la opción "sin variantes".'
          );
        }
      } else if (this.selectedTab === 0) {
        this.dataSave();
      }
    },
    redirectTo() {
      this.$router.push("/inventario/productos#1");
    },
    closeCategory(event) {
      const element = event.target.className;
      if (element === "bgModal") {
        this.$store.state.modalCategory = false;
        this.$store.state.modalEtiquetas = false;
      }
    },
    setMenu() {
      let data;
      if (
        this.storeData &&
        (this.storeData.id === 1559 || this.storeData.id === 582)
      ) {
        data = {
          menu: [
            {
              id: 1,
              label: "text_inventario_photos",
              selectSetting: 1,
              urlDirect: "?p=1",
            },
            {
              id: 2,
              label: "text_inventario_informacion",
              selectSetting: 2,
              urlDirect: "?p=2",
            },
            {
              id: 3,
              label: "text_inventario_precios",
              selectSetting: 3,
              urlDirect: "?p=3",
            },
            {
              id: 4,
              label: "text_inventario_filtros",
              selectSetting: 4,
              urlDirect: "?p=4",
            },
            {
              id: 6,
              label: "text_inventario_dropshipping",
              selectSetting: 6,
              urlDirect: "?p=6",
            },
          ],
        };
      } else {
        data = {
          menu: [
            {
              id: 1,
              label: "text_inventario_photos",
              selectSetting: 1,
              urlDirect: "?p=1",
            },
            {
              id: 2,
              label: "text_inventario_informacion",
              selectSetting: 2,
              urlDirect: "?p=2",
            },
            {
              id: 3,
              label: "text_inventario_precios",
              selectSetting: 3,
              urlDirect: "?p=3",
            },
            {
              id: 4,
              label: "text_inventario_filtros",
              selectSetting: 4,
              urlDirect: "?p=4",
            },
            // {
            //   id: 6,
            //   label: 'text_inventario_dropshipping',
            //   selectSetting: 6,
            //   urlDirect: '?p=6',
            // },
          ],
        };
      }
      this.menu = data.menu;
    },
    // duplicateProduct() {
    //   this.visibleDuplicateProduct = true;
    // }
  },
};
</script>
<style scoped lang="scss">
$size: 1.4em;
$dotCount: 10;
$animationTime: 2.5s;

.peeek-loading {
  // background-color: #38d368;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.statitics-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.peeek-loading ul {
  position: absolute;
  left: calc(50% - 0.7em);
  top: 390px;
  display: inline-block;
  text-indent: $size * 2;
}
.peeek-loading ul li:after,
.peeek-loading ul:after {
  width: $size;
  height: $size;
  background-color: #fff;
  border-radius: 100%;
}
.peeek-loading ul li:after,
.peeek-loading ul:after {
  content: "";
  display: block;
}
.peeek-loading ul:after {
  position: absolute;
  top: $size * 2;
}
.peeek-loading li {
  position: absolute;
  padding-bottom: $size * 4;
  top: 0;
  left: 0;
}

// Creating Circle and Animation Delay
@for $i from 1 through 10 {
  .peeek-loading li:nth-child(#{$i}) {
    $deg: ($i - 1) * (360deg / $dotCount);
    transform: rotate($deg);
    animation-delay: $animationTime * $i/$dotCount/2;
  }
  .peeek-loading li:nth-child(#{$i}):after {
    animation-delay: $animationTime * $i/$dotCount/2;
  }
}

// Animations
.peeek-loading li {
  animation: dotAnimation $animationTime infinite;
}
@keyframes dotAnimation {
  0%,
  55%,
  100% {
    padding: 0 0 $size * 4 0;
  }
  5%,
  50% {
    padding: $size * 2 0;
  }
}

.peeek-loading li:after {
  animation: dotAnimationTwo $animationTime infinite;
}
@-webkit-keyframes dotAnimationTwo {
  0%,
  55%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  5%,
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
}
</style>
<style scoped>
.modal {
  padding-top: 200px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @apply w-full h-full fixed z-10 left-0 top-0 overflow-auto;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.darktxts {
  background-color: #293d53;
  color: #fff;
}
.lighttxts {
  color: rgb(26, 46, 59);
}
.darkbgs {
  background-color: #1b2836;
}
.lightbgs {
  background-color: #fff;
}
.darkbg {
  background-color: #293d53;
}
.lightbg {
  background-color: #fff;
}
.wrapper-content-items {
  width: 100%;
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.product_form_breadcrumb {
  max-width: 1366px;
  width: 100%;
  display: grid;
  justify-content: flex-start;
  align-items: center;
}
.wrapper-invoice-nav {
  padding: 15px 0px;
}
.backandhelp {
  display: grid;
  grid-area: top;
}

.bgModal {
  background-color: rgba(0, 0, 0, 0.768);
  z-index: 99999;
  top: 0px;
  left: 0px;
  @apply w-full h-full fixed flex justify-center items-center;
}
.divmodal {
  z-index: 999999;
  max-width: 1500px;
  max-height: 800px;
  @apply w-full flex justify-start items-center shadow-md rounded-md overflow-hidden overflow-y-auto;
}
.col-left {
  display: grid;
  grid-area: left;
}
.return_products {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.return_products i {
  margin-right: 7px;
}
/* .col-right {
  position: sticky;
  top: 50px;
  grid-area: right;
} */
.title {
  font-size: 20px;
  margin: 15px 0;
  margin-left: 30px;
}
.col-left .box-body {
  display: flex;
  flex-direction: column;
}
.grid {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.input-area {
  max-width: calc(50% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area-full {
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 5px;
}
.input-area .el-input,
.input-area .quillWrapper,
.input-area .el-select {
  width: 100%;
}
.iframe_youtube {
  width: 100px;
  height: 100px;
}
.actions {
  @apply w-full flex justify-end mb-16 mt-4;
}

.actions_left {
  justify-self: end;
}
.free-shipping {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 2fr;
  align-items: center;
}
.free-shipping div:nth-child(2) {
  /*justify-self: end;*/
  align-self: end;
}
.details {
  font-size: 12px;
  margin: 0;
}
/* @media (max-width: 1400px) {
  .divmodal {
    max-width: 1400px;
  }
}
@media (max-width: 1366px) {
  .divmodal {
    max-width: 1366px;
  }
} */

/* @media (max-width: 1200px) {
  .col-right {
    position: relative;
    top: 0px;
   
  }
}  */
@media (max-width: 1440px) {
  .divmodal {
    max-width: 1300px;
  }
  .wrapper-content-items {
    padding: 10px 20px;
  }
}
@media (max-width: 1366px) {
  .divmodal {
    max-width: 1200px;
  }
}
@media (max-width: 1280px) {
  .divmodal {
    max-width: 1100px;
  }
}
@media (max-width: 1080px) {
  .divmodal {
    max-width: 850px;
  }
}
@media (min-width: 800px) {
  /* .grid-item-user {
    @apply w-full flex flex-row justify-start items-start;
  } */
  .item-left {
    width: auto;
    @apply flex flex-col justify-center items-center;
  }
  .item-right {
    @apply w-full flex flex-col justify-center items-center;
  }
}
@media (min-width: 768px) {
  .item-content-user {
    /* box-shadow: 0 0 10px 0 rgb(0 0 0 / 9%); */
    @apply w-full flex flex-col justify-center items-center pb-8 pt-2 rounded-xl;
  }
  .grid-item-user {
    @apply w-full flex flex-row justify-start items-start;
  }
  .item-left {
    max-width: 170px;
    @apply w-full flex flex-col justify-center items-center;
  }
  .item-right {
    @apply w-full flex flex-col justify-center items-center border-l pl-5;
  }
  .item-settings {
    @apply w-full flex flex-row justify-start items-center mb-2 cursor-pointer py-2 pl-4 pr-4;
  }
  .item-settings:hover {
    background-color: #4e2fca;
    border-color: #02e5a0;
  }
  .txt-setting {
    @apply ml-4;
  }
  .item-settings:hover .txt-setting {
    color: #fff;
  }
  .item-settings:hover .svg {
    color: #fff;
  }
  .user-light {
    background-color: #4429b4;
    border-color: #02e5a0;
    @apply border-l-4;
  }
  .user-dark {
    background-color: #4429b4;
    border-color: #02e5a0;
    @apply border-l-4;
  }
  .item-settings:active .txt-setting {
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .item-left {
    max-width: 200px;
    @apply flex flex-col justify-center items-center;
  }
}
</style>
