<template>
  <div class="flex flex-col">
    <div class="px-6 w-full flex justify-between items-center">
      <h2
        class="flex items-center font-semibold text-lg"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        {{ $t("text_perfil_suscription") }}
      </h2>
      <a
        class="flex items-center border border-purple-komercia-100 rounded-lg py-2 px-3 text-purple-komercia-100 hover:border-purple-600 hover:text-purple-600"
        href="https://ayuda.komercia.co/komercia/categories/582/Mi%20perfil%20/26"
        title="Abrir ayuda Komercia"
        target="_blank"
      >
        {{ $t("text_helpDesk_Aprende") }}
        <img
          loading="lazy"
          class="w-5 ml-2"
          src="@/assets/information.webp"
          alt="imgInformation"
        />
      </a>
    </div>
    <div
      v-if="!activePlan"
      class="grid-suscrip"
      element-loading-text="Cargando link de pagos"
    >
      <div class="mt-0 w-full sm:mt-0 sm:pb-18 lg:pb-15">
        <div class="relative max-w-7xl mx-auto">
          <div
            class="max-w-lg mx-auto rounded-lg shadow-md overflow-hidden lg:max-w-none lg:flex"
          >
            <div
              class="flex-1 px-6 py-4"
              :class="darkMode ? 'darks' : 'lights'"
            >
              <div class="mt-0">
                <h3
                  class="sub-title mb-2"
                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                >
                  Plan Emprendedor! 😃
                </h3>
                <div class="flex items-center">
                  <h4
                    class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                  >
                    plan emprendedor Incluye
                  </h4>
                </div>
                <ul
                  class="mt-5 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                >
                  <li
                    v-for="(feature, index) in featuresFree"
                    :key="index"
                    class="flex items-start lg:col-span-1"
                  >
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: check-circle -->
                      <svg
                        class="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p
                      class="ml-3 text-sm"
                      :class="darkMode ? 'darktxt' : 'text-gray-700'"
                    >
                      {{ feature }}
                    </p>
                  </li>
                </ul>
                <div v-if="!activePlan && !isPlan2000" v-loading="loading">
                  <div
                    v-if="
                      storeData &&
                      storeData.informacion_tienda &&
                      (storeData.informacion_tienda[0].pais.pais ==
                        'Colombia' ||
                        storeData.informacion_tienda[0].pais.pais == 'Chile' ||
                        storeData.informacion_tienda[0].pais.pais == 'Mexico' ||
                        storeData.informacion_tienda[0].pais.pais == 'Perú' ||
                        storeData.informacion_tienda[0].pais.pais ==
                          'Puerto Rico' ||
                        storeData.informacion_tienda[0].pais.pais ==
                          'Argentina' ||
                        storeData.informacion_tienda[0].pais.pais ==
                          'Internacional' ||
                        storeData.informacion_tienda[0].pais.pais == 'Panamá')
                    "
                  >
                    <div class="flex justify-end">
                      <div v-if="urlSubscription" class="my-5">
                        <a
                          :href="`${urlSubscription.url}`"
                          target="_blank"
                          class="border rounded-lg bg-purple-komercia-600 text-white font-bold px-4 py-3 hover:bg-green-komercia-600"
                        >
                          Administrar suscripción</a
                        >
                      </div>
                      <div v-else class="w-full py-5 rounded-lg">
                        <div
                          v-if="storeData && storeData.id"
                          class="mt-5 flex flex-row"
                        >
                          <h1
                            class="flex sub-title text-md"
                            :class="darkMode ? 'darktxt' : 'text-gray-900'"
                          >
                            Comprar emprendedor 🏆
                          </h1>
                          <img
                            loading="lazy"
                            :src="selectOptionCountry.img"
                            width="50px"
                            class="ml-2"
                            alt="bandera pais"
                          />
                        </div>
                        <div>
                          <div
                            class="flex-1 border-t-2 border-gray-200 mt-5"
                          ></div>
                          <div
                            class="w-full flex flex-row justify-between items-center"
                          >
                            <div
                              class="flex flex-col justify-start items-start mb-6 mt-5"
                            >
                              <h4
                                class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                              >
                                Pago mensual
                              </h4>
                              <p
                                v-if="
                                  storeData.informacion_tienda[0].paises_id ===
                                    1 && expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 65.000
                                </span>
                              </p>
                              <p
                                v-if="
                                  (storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 6 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 8 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 10) &&
                                  expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 10
                                </span>
                              </p>
                              <div class="content-txt-CLP">
                                <p
                                  class="text-2xl font-semibold"
                                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                                >
                                  <strong
                                    v-if="
                                      (storeData.informacion_tienda[0]
                                        .paises_id === 1 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 2 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 6 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 8 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 10) &&
                                      expiredDate(ExpiredPromo)
                                    "
                                    class="text-xs"
                                  >
                                    Ahora
                                  </strong>
                                  {{ selectOptionCountry.currencySign }}
                                  {{
                                    selectOptionCountry.monthlyPriceEmprendedor
                                  }}
                                  {{ selectOptionCountry.simbolo }}
                                  <br />
                                </p>
                              </div>
                            </div>
                            <div
                              v-if="
                                selectOptionCountry.linkMonthlyPriceEmprendedor
                              "
                              class="flex justify-between items-center"
                            >
                              <button
                                v-if="
                                  selectOptionCountry.stateStripeEmprendedorMensual
                                "
                                :class="[
                                  darkMode ? 'text-white' : 'text-gray-900',
                                  !loadingPay
                                    ? 'cursor-pointer'
                                    : 'cursor-wait',
                                ]"
                                class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                                :loading="loadingPay"
                                :disabled="loadingPay ? true : false"
                                @click="
                                  suscription(
                                    selectOptionCountry.linkMonthlyPriceEmprendedor
                                  )
                                "
                              >
                                {{
                                  loadingPay
                                    ? "Cargando link de pago"
                                    : "Suscribirme Ahora"
                                }}
                              </button>
                              <a
                                v-else
                                :href="
                                  selectOptionCountry.linkMonthlyPriceEmprendedor
                                "
                                rel="noreferrer noopener"
                                target="_blank"
                                :class="
                                  darkMode ? 'text-white' : 'text-gray-900'
                                "
                                class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                              >
                                Suscribirme Ahora
                              </a>
                            </div>
                            <div
                              v-else
                              class="text-gray-900 text-base font-medium"
                            >
                              <p>No disponible</p>
                            </div>
                          </div>
                          <div
                            class="flex-1 border-t-2 border-gray-200 mt-6"
                          ></div>
                          <div
                            class="w-full flex flex-row justify-between items-center"
                          >
                            <div
                              class="flex flex-col justify-start items-start mb-6 mt-5"
                            >
                              <h4
                                class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                              >
                                Pago Anual
                              </h4>
                              <p
                                v-if="
                                  storeData.informacion_tienda[0].paises_id ===
                                    1 && expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 420.000
                                </span>
                              </p>
                              <p
                                v-if="
                                  (storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 6 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 8 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 10) &&
                                  expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 100
                                </span>
                              </p>

                              <div class="content-txt-CLP">
                                <p
                                  class="text-gray-900 text-2xl font-semibold"
                                  :class="
                                    darkMode ? 'darktxt' : 'text-gray-900'
                                  "
                                >
                                  <strong
                                    v-if="
                                      (storeData.informacion_tienda[0]
                                        .paises_id === 1 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 2 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 6 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 8 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 10) &&
                                      expiredDate(ExpiredPromo)
                                    "
                                    class="text-xs"
                                  >
                                    Ahora
                                  </strong>
                                  {{ selectOptionCountry.currencySign }}
                                  {{
                                    selectOptionCountry.annualPriceEmprendedor
                                  }}
                                  {{ selectOptionCountry.simbolo }}
                                  <br />
                                </p>
                              </div>
                              <p
                                v-if="selectOptionCountry.ahorroAnualEmprededor"
                                class="text-xs"
                                :class="darkMode ? 'darktxt' : 'text-gray-900'"
                              >
                                Te ahorras
                                {{ selectOptionCountry.currencySign }}
                                {{ selectOptionCountry.ahorroAnualEmprededor }}
                                {{ selectOptionCountry.simbolo }}
                              </p>
                            </div>
                            <div
                              v-if="
                                selectOptionCountry.linkannualPriceEmprendedor
                              "
                              class="flex justify-between items-center"
                            >
                              <button
                                v-if="
                                  selectOptionCountry.stateStripeEmprendedorAnual
                                "
                                :class="
                                  !loadingPay ? 'cursor-pointer' : 'cursor-wait'
                                "
                                class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                                :loading="loadingPay"
                                :disabled="loadingPay ? true : false"
                                @click="
                                  suscription(
                                    selectOptionCountry.linkannualPriceEmprendedor
                                  )
                                "
                              >
                                {{
                                  loadingPay
                                    ? "Cargando link de pago"
                                    : "Suscribirme Ahora"
                                }}
                              </button>
                              <a
                                v-else
                                :href="
                                  selectOptionCountry.linkannualPriceEmprendedor
                                "
                                rel="noreferrer noopener"
                                target="_blank"
                                class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                              >
                                Suscribirme Ahora
                              </a>
                            </div>
                            <div
                              v-else
                              class="text-gray-900 text-base font-medium"
                            >
                              <p>No disponible</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-0 w-full sm:mt-0 sm:pb-18 lg:pb-15">
        <div class="relative max-w-7xl mx-auto">
          <div
            class="max-w-lg mx-auto rounded-lg shadow-md overflow-hidden lg:max-w-none lg:flex"
          >
            <div
              class="flex-1 px-6 py-4"
              :class="darkMode ? 'darks' : 'lights'"
            >
              <div class="mt-0">
                <h3
                  class="sub-title mb-2"
                  :class="darkMode ? 'darktxt' : 'lighttxt'"
                >
                  Plan premium! 😃
                </h3>
                <div class="flex items-center mt-4">
                  <h4
                    class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                  >
                    Plan premium incluye
                  </h4>
                </div>
                <ul
                  class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                >
                  <li
                    v-for="(feature, index) in featuresPremium"
                    :key="index"
                    class="flex items-start lg:col-span-1"
                  >
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p
                      class="ml-3 text-sm"
                      :class="darkMode ? 'darktxt' : 'text-gray-700'"
                    >
                      {{ feature }}
                    </p>
                  </li>
                </ul>
                <div v-if="!activePlan && !isPlan2000" v-loading="loading">
                  <div
                    v-if="
                      storeData &&
                      storeData.informacion_tienda &&
                      (storeData.informacion_tienda[0].pais.pais ==
                        'Colombia' ||
                        storeData.informacion_tienda[0].pais.pais == 'Chile' ||
                        storeData.informacion_tienda[0].pais.pais == 'Mexico' ||
                        storeData.informacion_tienda[0].pais.pais == 'Perú' ||
                        storeData.informacion_tienda[0].pais.pais ==
                          'Puerto Rico' ||
                        storeData.informacion_tienda[0].pais.pais ==
                          'Argentina' ||
                        storeData.informacion_tienda[0].pais.pais ==
                          'Internacional' ||
                        storeData.informacion_tienda[0].pais.pais == 'Panamá')
                    "
                  >
                    <div class="flex justify-end">
                      <div v-if="urlSubscription" class="my-5">
                        <a
                          :href="`${urlSubscription.url}`"
                          target="_blank"
                          class="border rounded-lg bg-purple-komercia-600 text-white font-bold px-4 py-3 hover:bg-green-komercia-600"
                        >
                          Administrar suscripción</a
                        >
                      </div>
                      <div v-else class="w-full py-5 rounded-lg">
                        <div v-if="storeData && storeData.id" class="mt-5 flex">
                          <h1
                            class="flex flex-row sub-title text-gray-900"
                            :class="darkMode ? 'darktxt' : 'text-gray-900'"
                          >
                            Comprar Premium 🏆
                          </h1>
                          <img
                            loading="lazy"
                            :src="selectOptionCountry.img"
                            width="50px"
                            class="ml-2"
                            alt="bandera pais"
                          />
                        </div>
                        <div>
                          <div
                            class="flex-1 border-t-2 border-gray-200 mt-5"
                          ></div>
                          <div
                            class="w-full flex flex-row justify-between items-center"
                          >
                            <div
                              class="flex flex-col justify-start items-start mb-6 mt-5"
                            >
                              <h4
                                class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                              >
                                Pago mensual
                              </h4>
                              <p
                                v-if="
                                  storeData.informacion_tienda[0].paises_id ===
                                    1 && expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 42.000
                                </span>
                              </p>
                              <p
                                v-if="
                                  (storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 6 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 8 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 10) &&
                                  expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 15
                                </span>
                              </p>
                              <div class="content-txt-CLP">
                                <p
                                  class="text-gray-900 text-2xl font-semibold"
                                  :class="
                                    darkMode ? 'darktxt' : 'text-gray-900'
                                  "
                                >
                                  <strong
                                    v-if="
                                      (storeData.informacion_tienda[0]
                                        .paises_id === 1 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 2 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 6 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 8 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 10) &&
                                      expiredDate(ExpiredPromo)
                                    "
                                    class="text-xs"
                                  >
                                    Ahora
                                  </strong>
                                  {{ selectOptionCountry.currencySign }}
                                  {{ selectOptionCountry.monthlyPricePremium }}
                                  {{ selectOptionCountry.simbolo }}

                                  <br />
                                </p>
                              </div>
                            </div>
                            <div
                              v-if="selectOptionCountry.linkMonthlyPricePremium"
                              class="flex justify-between items-center"
                            >
                              <button
                                v-if="
                                  selectOptionCountry.stateStripePremiumMensual
                                "
                                :class="[
                                  darkMode ? 'text-white' : 'text-gray-900',
                                  !loadingPay
                                    ? 'cursor-pointer'
                                    : 'cursor-wait',
                                ]"
                                class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                                :loading="loadingPay"
                                :disabled="loadingPay ? true : false"
                                @click="
                                  suscription(
                                    selectOptionCountry.linkMonthlyPricePremium
                                  )
                                "
                              >
                                {{
                                  loadingPay
                                    ? "Cargando link de pago"
                                    : "Suscribirme Ahora"
                                }}
                              </button>
                              <a
                                v-else
                                :href="
                                  selectOptionCountry.linkMonthlyPricePremium
                                "
                                rel="noreferrer noopener"
                                target="_blank"
                                :class="
                                  darkMode ? 'text-white' : 'text-gray-900'
                                "
                                class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                              >
                                Suscribirme Ahora
                              </a>
                            </div>
                            <div
                              v-else
                              class="text-gray-900 text-base font-medium"
                            >
                              <p>No disponible</p>
                            </div>
                          </div>
                          <div
                            class="flex-1 border-t-2 border-gray-200 mt-6"
                          ></div>
                          <div
                            class="w-full flex flex-row justify-between items-center"
                          >
                            <div
                              class="flex flex-col justify-start items-start mb-6 mt-5"
                            >
                              <h4
                                class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                                :class="darkMode ? 'darktxt' : 'text-gray-900'"
                              >
                                Pago Anual
                              </h4>
                              <p
                                v-if="
                                  storeData.informacion_tienda[0].paises_id ===
                                    1 && expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 650.000
                                </span>
                              </p>
                              <p
                                v-if="
                                  (storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 6 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 8 ||
                                    storeData.informacion_tienda[0]
                                      .paises_id === 10) &&
                                  expiredDate(ExpiredPromo)
                                "
                                class="card-price-before"
                              >
                                Después
                                <span class="card-price-span-before">
                                  $ 150
                                </span>
                              </p>
                              <div class="content-txt-CLP">
                                <p
                                  class="text-2xl font-semibold"
                                  :class="
                                    darkMode ? 'darktxt' : 'text-gray-900'
                                  "
                                >
                                  <strong
                                    v-if="
                                      (storeData.informacion_tienda[0]
                                        .paises_id === 1 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 2 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 6 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 8 ||
                                        storeData.informacion_tienda[0]
                                          .paises_id === 10) &&
                                      expiredDate(ExpiredPromo)
                                    "
                                    class="text-xs"
                                  >
                                    Ahora
                                  </strong>
                                  {{ selectOptionCountry.currencySign }}
                                  {{ selectOptionCountry.annualPricePremium }}
                                  {{ selectOptionCountry.simbolo }}
                                  <br />
                                </p>
                              </div>
                              <p
                                v-if="selectOptionCountry.ahorroAnualPremium"
                                class="text-xs"
                                :class="darkMode ? 'darktxt' : 'text-gray-900'"
                              >
                                Te ahorras
                                {{ selectOptionCountry.currencySign }}
                                {{ selectOptionCountry.ahorroAnualPremium }}
                                {{ selectOptionCountry.simbolo }}
                              </p>
                            </div>
                            <div
                              v-if="selectOptionCountry.linkannualPricePremium"
                              class="flex justify-between items-center"
                            >
                              <button
                                v-if="
                                  selectOptionCountry.stateStripePremiumAnual
                                "
                                :class="
                                  !loadingPay ? 'cursor-pointer' : 'cursor-wait'
                                "
                                class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                                @click="
                                  suscription(
                                    selectOptionCountry.linkannualPricePremium
                                  )
                                "
                              >
                                {{
                                  loadingPay
                                    ? "Cargando link de pago"
                                    : "Suscribirme Ahora"
                                }}
                              </button>
                              <a
                                v-else
                                :href="
                                  selectOptionCountry.linkannualPricePremium
                                "
                                rel="noreferrer noopener"
                                target="_blank"
                                class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                              >
                                Suscribirme Ahora
                              </a>
                            </div>
                            <div
                              v-else
                              class="text-gray-900 text-base font-medium"
                            >
                              <p>No disponible</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="darkMode ? 'darks' : 'lights'"
      class="mt-0 w-full sm:mt-0 sm:pb-18 lg:pb-15 bg-white px-6 py-4 rounded-lg shadow-md overflow-hidden"
    >
      <div class="relative w-full mx-auto pr-5">
        <div class="w-full mx-auto">
          <div class="w-full flex flex-row justify-between items-center">
            <h3
              class="sub-title mb-5"
              :class="darkMode ? 'darktxt' : 'text-gray-700'"
            >
              {{
                storeData.tipo == 0
                  ? "Eres Emprendedor 🏆"
                  : storeData.tipo == 3
                  ? "Eres premium anual 🏆"
                  : "Eres premium mensual 🏆"
              }}
            </h3>
            <a
              v-if="urlSubscription"
              :href="`${urlSubscription.url}`"
              target="_blank"
              class="border rounded-lg bg-purple-komercia-600 text-white font-bold px-4 py-3 hover:bg-green-komercia-600"
            >
              Administrar suscripción
            </a>
          </div>
          <div class="flex items-center">
            <h4
              class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600"
            >
              Incluye
            </h4>
            <div class="flex-1 border-t-2 border-gray-200"></div>
          </div>
          <ul
            class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
          >
            <li
              v-for="(feature, index) in selectplan"
              :key="index"
              class="flex items-start lg:col-span-1"
            >
              <div class="flex-shrink-0">
                <!-- Heroicon name: check-circle -->
                <svg
                  class="h-5 w-5 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <p
                class="ml-3 text-sm"
                :class="darkMode ? 'darktxt' : 'text-gray-700'"
              >
                {{ feature }}
              </p>
            </li>
          </ul>
          <p
            v-if="
              modalExpiration.stateDemo &&
              modalExpiration.daysUntilExpiration > 0
            "
            class="text-blue-700 mt-10"
          >
            <i class="fas fa-clock"></i>
            {{
              modalExpiration.daysUntilExpiration + 1 == 1
                ? `Te queda ${
                    modalExpiration.daysUntilExpiration + 1
                  } dia de prueba.`
                : `Te queda ${
                    modalExpiration.daysUntilExpiration + 1
                  }  días de prueba.`
            }}
          </p>
          <p
            v-if="
              modalExpiration.stateDemo &&
              modalExpiration.daysUntilExpiration == 0
            "
            class="text-blue-700 mt-10"
          >
            <i class="fas fa-clock"></i>
            Hoy terminan tus días de prueba, Renueva tu suscripción y sigue
            creciendo!
          </p>
          <p
            v-else-if="
              storeData.fecha_expiracion != null &&
              modalExpiration.daysUntilExpiration > 0
            "
            class="text-blue-700 mt-10"
          >
            <i class="fas fa-clock"></i>
            Renovación: {{ storeData.fecha_expiracion }}
          </p>
          <p class="mt-2 max-w-2xl text-sm text-gray-500">
            <b>{{ urlSubscription ? "Pagos automatizados:" : "" }}</b>
            {{
              urlSubscription
                ? `La suscripción
                ${storeData.tipo == 3 ? "anual" : "mensual"}
                se cobra automáticamente cada
                ${storeData.tipo == 3 ? "año" : "mes"} de la tarjeta de
                crédito registrada.`
                : `La suscripción
                ${storeData.tipo == 3 ? "anual" : "mensual"}
                se tiene pagar manualmente cada
                ${
                  storeData.tipo == 3 ? "año" : "mes"
                } por el método de pago seleccionado.`
            }}
          </p>
          <div class="flex flex-row justify-start items-start mt-2">
            <img
              loading="lazy"
              :src="selectOptionCountry.img"
              width="50px"
              class="mr-2"
              alt="bandera pais"
            />

            <div v-if="urlSubscription" class="mt-3">
              <svg
                viewBox="0 0 60 25"
                xmlns="http://www.w3.org/2000/svg"
                width="65"
                height="30"
              >
                <path
                  fill="var(--userLogoColor, #0A2540)"
                  d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <!-- GRID PARA BORRAR -->
          <div v-if="!urlSubscription">
            <!-- v-if="daysUntilExpiration > 0 || courtesyDays == 0" -->
            <div
              class="w-full grid grid-cols-2 gap-5 justify-center items-center"
            >
              <div class="flex justify-end">
                <div class="w-full py-5 rounded-lg">
                  <div v-if="storeData && storeData.id" class="mt-5 flex">
                    <h1
                      class="flex sub-title"
                      :class="darkMode ? 'darktxt' : 'text-gray-900'"
                    >
                      Comprar emprendedor 🏆
                    </h1>
                    <img
                      loading="lazy"
                      :src="selectOptionCountry.img"
                      width="50px"
                      class="ml-2"
                      alt="bandera pais"
                    />
                  </div>
                  <div>
                    <div class="flex-1 border-t-2 border-gray-200 mt-5"></div>
                    <div
                      class="w-full flex flex-row justify-between items-center"
                    >
                      <div
                        class="flex flex-col justify-start items-start mb-6 mt-5"
                      >
                        <h4
                          class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                        >
                          Pago mensual
                        </h4>
                        <p
                          v-if="
                            storeData.informacion_tienda[0].paises_id === 1 &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before"> $ 65.000 </span>
                        </p>
                        <div class="content-txt-CLP">
                          <p
                            class="text-2xl font-semibold"
                            :class="darkMode ? 'darktxt' : 'lighttxt'"
                          >
                            <strong
                              v-if="
                                (storeData.informacion_tienda[0].paises_id ===
                                  1 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    6 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    8 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    10) &&
                                expiredDate(ExpiredPromo)
                              "
                              class="text-xs"
                            >
                              Ahora
                            </strong>
                            {{ selectOptionCountry.currencySign }}
                            {{ selectOptionCountry.monthlyPriceEmprendedor }}
                            {{ selectOptionCountry.simbolo }}
                            <br />
                          </p>
                        </div>
                      </div>
                      <div
                        v-if="selectOptionCountry.linkMonthlyPriceEmprendedor"
                        class="flex justify-between items-center"
                      >
                        <button
                          v-if="
                            selectOptionCountry.stateStripeEmprendedorMensual
                          "
                          :class="[
                            darkMode ? 'text-white' : 'text-gray-900',
                            !loadingPay ? 'cursor-pointer' : 'cursor-wait',
                          ]"
                          class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                          :loading="loadingPay"
                          :disabled="loadingPay ? true : false"
                          @click="
                            suscription(
                              selectOptionCountry.linkMonthlyPriceEmprendedor
                            )
                          "
                        >
                          Suscribirme Ahora
                        </button>
                        <a
                          v-else
                          :href="
                            selectOptionCountry.linkMonthlyPriceEmprendedor
                          "
                          rel="noreferrer noopener"
                          target="_blank"
                          :class="darkMode ? 'text-white' : 'text-gray-900'"
                          class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                        >
                          Suscribirme Ahora
                        </a>
                      </div>
                      <div v-else class="text-gray-900 text-base font-medium">
                        <p>No disponible</p>
                      </div>
                    </div>
                    <div class="flex-1 border-t-2 border-gray-200 mt-6"></div>
                    <div
                      class="w-full flex flex-row justify-between items-center"
                    >
                      <div
                        class="flex flex-col justify-start items-start mb-6 mt-5"
                      >
                        <h4
                          class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                        >
                          Pago Anual
                        </h4>
                        <p
                          v-if="
                            storeData.informacion_tienda[0].paises_id === 1 &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before">
                            $ 420.000
                          </span>
                        </p>
                        <p
                          v-if="
                            (storeData.informacion_tienda[0].paises_id === 2 ||
                              storeData.informacion_tienda[0].paises_id === 6 ||
                              storeData.informacion_tienda[0].paises_id === 8 ||
                              storeData.informacion_tienda[0].paises_id ===
                                10) &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before"> $ 100 </span>
                        </p>
                        <div class="content-txt-CLP">
                          <p
                            class="text-gray-900 text-2xl font-semibold"
                            :class="darkMode ? 'darktxt' : 'text-gray-900'"
                          >
                            <strong
                              v-if="
                                (storeData.informacion_tienda[0].paises_id ===
                                  1 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    6 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    8 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    10) &&
                                expiredDate(ExpiredPromo)
                              "
                              class="text-xs"
                            >
                              Ahora
                            </strong>
                            {{ selectOptionCountry.currencySign }}
                            {{ selectOptionCountry.annualPriceEmprendedor }}
                            {{ selectOptionCountry.simbolo }}
                            <br />
                          </p>
                        </div>
                        <p
                          v-if="selectOptionCountry.ahorroAnualEmprededor"
                          class="text-xs"
                          :class="darkMode ? 'darktxt' : 'text-gray-900'"
                        >
                          Te ahorras
                          {{ selectOptionCountry.currencySign }}
                          {{ selectOptionCountry.ahorroAnualEmprededor }}
                          {{ selectOptionCountry.simbolo }}
                        </p>
                      </div>
                      <div
                        v-if="selectOptionCountry.linkannualPriceEmprendedor"
                        class="flex justify-between items-center"
                      >
                        <button
                          v-if="selectOptionCountry.stateStripeEmprendedorAnual"
                          :class="
                            !loadingPay ? 'cursor-pointer' : 'cursor-wait'
                          "
                          class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                          :loading="loadingPay"
                          :disabled="loadingPay ? true : false"
                          @click="
                            suscription(
                              selectOptionCountry.linkannualPriceEmprendedor
                            )
                          "
                        >
                          Suscribirme Ahora
                        </button>
                        <a
                          v-else
                          :href="selectOptionCountry.linkannualPriceEmprendedor"
                          rel="noreferrer noopener"
                          target="_blank"
                          class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                        >
                          Suscribirme Ahora
                        </a>
                      </div>
                      <div v-else class="text-gray-900 text-base font-medium">
                        <p>No disponible</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <div class="w-full py-5 rounded-lg">
                  <div v-if="storeData && storeData.id" class="mt-5 flex">
                    <h1
                      class="flex sub-title text-gray-900"
                      :class="darkMode ? 'darktxt' : 'text-gray-900'"
                    >
                      Comprar Premium 🏆
                    </h1>
                    <img
                      loading="lazy"
                      :src="selectOptionCountry.img"
                      width="50px"
                      class="ml-2"
                      alt="bandera pais"
                    />
                  </div>
                  <div>
                    <div class="flex-1 border-t-2 border-gray-200 mt-5"></div>
                    <div
                      class="w-full flex flex-row justify-between items-center"
                    >
                      <div
                        class="flex flex-col justify-start items-start mb-6 mt-5"
                      >
                        <h4
                          class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                        >
                          Pago mensual
                        </h4>
                        <p
                          v-if="
                            storeData.informacion_tienda[0].paises_id === 1 &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before"> $ 42.000 </span>
                        </p>
                        <p
                          v-if="
                            (storeData.informacion_tienda[0].paises_id === 2 ||
                              storeData.informacion_tienda[0].paises_id === 6 ||
                              storeData.informacion_tienda[0].paises_id === 8 ||
                              storeData.informacion_tienda[0].paises_id ===
                                10) &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before"> $ 15 </span>
                        </p>
                        <div class="content-txt-CLP">
                          <p
                            class="text-gray-900 text-2xl font-semibold"
                            :class="darkMode ? 'darktxt' : 'text-gray-900'"
                          >
                            <strong
                              v-if="
                                (storeData.informacion_tienda[0].paises_id ===
                                  1 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    6 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    8 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    10) &&
                                expiredDate(ExpiredPromo)
                              "
                              class="text-xs"
                            >
                              Ahora
                            </strong>
                            {{ selectOptionCountry.currencySign }}
                            {{ selectOptionCountry.monthlyPricePremium }}
                            {{ selectOptionCountry.simbolo }}
                            <br />
                          </p>
                        </div>
                      </div>
                      <div
                        v-if="selectOptionCountry.linkMonthlyPricePremium"
                        class="flex justify-between items-center"
                      >
                        <button
                          v-if="selectOptionCountry.stateStripePremiumMensual"
                          :class="[
                            darkMode ? 'text-white' : 'text-gray-900',
                            !loadingPay ? 'cursor-pointer' : 'cursor-wait',
                          ]"
                          class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                          :loading="loadingPay"
                          :disabled="loadingPay ? true : false"
                          @click="
                            suscription(
                              selectOptionCountry.linkMonthlyPricePremium
                            )
                          "
                        >
                          Suscribirme Ahora
                        </button>
                        <a
                          v-else
                          :href="selectOptionCountry.linkMonthlyPricePremium"
                          rel="noreferrer noopener"
                          target="_blank"
                          :class="darkMode ? 'text-white' : 'text-gray-900'"
                          class="flex items-center justify-center px-2 py-3 border-2 border-gray-900 text-base font-medium rounded-md bg-transparent"
                        >
                          Suscribirme Ahora
                        </a>
                      </div>
                      <div v-else class="text-gray-900 text-base font-medium">
                        <p>No disponible</p>
                      </div>
                    </div>
                    <div class="flex-1 border-t-2 border-gray-200 mt-6"></div>
                    <div
                      class="w-full flex flex-row justify-between items-center"
                    >
                      <div
                        class="flex flex-col justify-start items-start mb-6 mt-5"
                      >
                        <h4
                          class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                          :class="darkMode ? 'darktxt' : 'text-gray-900'"
                        >
                          Pago Anual
                        </h4>
                        <p
                          v-if="
                            storeData.informacion_tienda[0].paises_id === 1 &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before">
                            $ 650.000
                          </span>
                        </p>
                        <p
                          v-if="
                            (storeData.informacion_tienda[0].paises_id === 2 ||
                              storeData.informacion_tienda[0].paises_id === 6 ||
                              storeData.informacion_tienda[0].paises_id === 8 ||
                              storeData.informacion_tienda[0].paises_id ===
                                10) &&
                            expiredDate(ExpiredPromo)
                          "
                          class="card-price-before"
                        >
                          Después
                          <span class="card-price-span-before"> $ 150 </span>
                        </p>
                        <div class="content-txt-CLP">
                          <p
                            class="text-2xl font-semibold"
                            :class="darkMode ? 'darktxt' : 'text-gray-900'"
                          >
                            <strong
                              v-if="
                                (storeData.informacion_tienda[0].paises_id ===
                                  1 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    2 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    6 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    8 ||
                                  storeData.informacion_tienda[0].paises_id ===
                                    10) &&
                                expiredDate(ExpiredPromo)
                              "
                              class="text-xs"
                            >
                              Ahora
                            </strong>
                            {{ selectOptionCountry.currencySign }}
                            {{ selectOptionCountry.annualPricePremium }}
                            {{ selectOptionCountry.simbolo }}
                            <br />
                          </p>
                        </div>
                        <p
                          v-if="selectOptionCountry.ahorroAnualPremium"
                          class="text-xs"
                          :class="darkMode ? 'darktxt' : 'text-gray-900'"
                        >
                          Te ahorras
                          {{ selectOptionCountry.currencySign }}
                          {{ selectOptionCountry.ahorroAnualPremium }}
                          {{ selectOptionCountry.simbolo }}
                        </p>
                      </div>
                      <div
                        v-if="selectOptionCountry.linkannualPricePremium"
                        class="flex justify-between items-center"
                      >
                        <button
                          v-if="selectOptionCountry.stateStripePremiumAnual"
                          :class="
                            !loadingPay ? 'cursor-pointer' : 'cursor-wait'
                          "
                          class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                          :loading="loadingPay"
                          :disabled="loadingPay ? true : false"
                          @click="
                            suscription(
                              selectOptionCountry.linkannualPricePremium
                            )
                          "
                        >
                          Suscribirme Ahora
                        </button>
                        <a
                          v-else
                          :href="selectOptionCountry.linkannualPricePremium"
                          rel="noreferrer noopener"
                          target="_blank"
                          class="flex items-center justify-center px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800"
                        >
                          Suscribirme Ahora
                        </a>
                      </div>
                      <div v-else class="text-gray-900 text-base font-medium">
                        <p>No disponible</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="storeData.informacion_tienda[0].paises_id === 1"
              class="w-full flex flex-row justify-between items-center border-t-2 border-gray-200"
            >
              <div class="flex flex-col justify-start items-start py-5">
                <h4
                  class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600"
                >
                  PAGO ANUAL X2
                </h4>
                <div class="content-txt-CLP">
                  <p
                    class="text-2xl font-semibold"
                    :class="darkMode ? 'darktxt' : 'lighttxt'"
                  >
                    {{ selectOptionCountry.currencySign }}
                    990.000
                    {{ selectOptionCountry.simbolo }}
                    <br />
                  </p>
                  <p
                    v-if="selectOptionCountry.ahorroAnualEmprededor"
                    class="text-xs"
                    :class="darkMode ? 'darktxt' : 'text-gray-900'"
                  >
                    Te ahorras
                    {{ selectOptionCountry.currencySign }}
                    642.000
                    {{ selectOptionCountry.simbolo }}
                  </p>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <a
                  href="https://mpago.li/1EUV6YQ"
                  class="flex items-center justify-center px-2 py-3 border-2 text-white border-gray-900 bg-gray-900 text-base font-medium rounded-md"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Suscribirme Ahora
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        user &&
        (user.id === 3480 ||
          user.id === 207 ||
          user.id === 39409 ||
          user.id === 1131 ||
          user.id === 97 ||
          user.id === 46441) &&
        urlSubscription
      "
      class="my-4 py-2 px-5 w-full flex flex-col justify-center items-center bg-gray-200 rounded-xl"
    >
      <h3 class="text-base font-bold mb-1">Información stripe</h3>
      <p class="text-sm text-black text-center">
        ID : {{ urlSubscription.id }}
      </p>
      <p class="text-sm text-black text-center">
        Customer : {{ urlSubscription.customer }}
      </p>
    </div>
    <!-- Cupones , Solo tiendas nuevas, Agregar solo con tiendas demo - &&  modalExpiration.stateDemo -->

    <div
      v-if="modalExpiration.stateDemo || !activePlan"
      ref="redeemCode"
      class="mt-8 wrapper-content-items"
      :class="darkMode ? 'darks' : 'lights'"
    >
      <div
        class="w-full grid grid-cols-2 gap-x-3"
        :class="darkMode ? 'darks' : 'lights'"
      >
        <div class="w-full flex flex-col justify-center items-center">
          <p
            :class="darkMode ? 'darktxt' : 'lighttxt'"
            class="sub-title text-center mb-6"
          >
            Agrega una tarjeta de regalo, un código promocional o un cupón
          </p>
          <div
            class="w-full max-w-[375px] flex flex-row justify-center items-center"
          >
            <el-input
              v-model="codeCoupon"
              class="inputs-idTienda w-full"
              :class="darkMode ? 'darks-input' : 'light-input'"
              placeholder="Ingresar el código"
            >
            </el-input>
            <el-button
              :loading="stateCoupon"
              :disabled="stateCoupon ? true : false"
              class="h-[40px] max-h-[40px] px-4 text-center text-white rounded-md ml-2 bg-black transition ease-in-out delay-150"
              @click="applyCoupon"
            >
              {{ stateCoupon ? "Validando" : "Reclamar" }}
            </el-button>
          </div>
        </div>
        <img
          loading="lazy"
          src="../../assets/couponsStore.webp"
          class="object-cover object-center max-w-[426px] max-h-[318px]"
          width="426"
          height="318"
          alt="couponsStore"
        />
      </div>
    </div>
    <!-- Pregunta Frecuentes -->
    <div
      class="mt-8 wrapper-content-items"
      :class="darkMode ? 'darks' : 'lights'"
    >
      <div
        class="max-w-7xl mx-auto py-12 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:py-16 lg:px-8"
        :class="darkMode ? 'darks' : 'lights'"
      >
        <h2 class="sub-title" :class="darkMode ? 'darktxt' : 'lighttxt'">
          Preguntas Frecuentes
        </h2>
        <div class="mt-6">
          <dl class="space-y-8 divide-y divide-gray-200">
            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                ¿Desde qué momento inicia la fecha la vigencia de la
                suscripción?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  La suscripción mensual comienza la fecha de pago y la
                  suscripción anual comienza una vez se realice la configuración
                  del dominio o 10 días después de la fecha de compra, lo que
                  primero ocurra.
                </p>
              </dd>
            </div>

            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                ¿Cuanto tiempo puedo tener mi tienda gratuitamente?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Podrás probar la plataforma por 20 días sin costo
                </p>
              </dd>
            </div>

            <!-- <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode  ? 'darktxt' : 'lighttxt'"
              >
                ¿Puedo pasarme del plan gratuito a un plan pago?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Si. Si necesitas los servicios y herramientas que ofrecen
                  alguno de los planes pago podrás adquirirlo en cualquier
                  momento.
                </p>
              </dd>
            </div> -->

            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                ¿Puedo integrar un dominio a la tienda online?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Si, puedes usar el dominio que hayas adquirido con un
                  proveedor o usar el subdominio de Komercia (komercia.store) La
                  compra del dominio no esta incluido dentro del plan de
                  suscripción, sin embargo nuestro soporte técnico te puede
                  asesorar en el proceso de compra y configuración con tu
                  tienda.
                </p>
              </dd>
            </div>

            <!-- <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode  ? 'darktxt' : 'lighttxt'"
              >
                ¿Cómo recibiré los pagos de mis clientes??
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Komercia cuenta integraciones con pasarelas de pago, estas son
                  plataformas que procesan pagos en línea de forma segura.
                  Mediante estas integraciones las tiendas online pueden recibir
                  múltiples medios y formas de pago para que tus clientes
                  compren seguro, facil y rapido tus productos.
                </p>
              </dd>
            </div> -->

            <!-- <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode  ? 'darktxt' : 'lighttxt'"
              >
                ¿Puedo recibir pagos de otra forma diferente a la pasarela de
                pagos?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Si. En la sección de pagos puedes habilitar cualquiera de las
                  siguientes opciones: 1. Pago contraentrega, 2. Pago a
                  convenir, 3. Pago en tienda, 4. Consignación Bancaria, 5.
                  Efecty. 6. Nequi 7. Daviplata
                </p>
              </dd>
            </div> -->

            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                ¿Me puedo retirar en cualquier momento?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Puedes suspender o cancelar tu suscripción mensual enviando un
                  correo a hola@komercia.com 7 días antes del siguiente pago,
                  seguirá teniendo acceso al servicio de komercia.co hasta el
                  final de su periodo de facturación las suscripciones anuales
                  no pueden ser cancelada antes de que pasen los 12 meses de
                  compromiso.
                </p>
              </dd>
            </div>

            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                ¿Quien realiza la integración del dominio a mi tienda online?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Nosotros te damos las instrucciones para que configures en la
                  administración de tu dominio los DNS, una vez lista esta
                  sencilla configuración nosotros nos encargamos de integrar el
                  dominio
                </p>
              </dd>
            </div>

            <!-- <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode  ? 'darktxt' : 'lighttxt'"
              >
                ¿Puedo cancelar mi suscripción en cualquier momento?
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Si. Solo debes enviar un correo a ventas@komercia.co desde el
                  mismo correo con el que tienes tu usuario en Komercia,
                  solicitando la cancelación del plan y escribiendo el motivo.
                  De esa forma una vez termines tu periodo pagado anual o
                  mensual de tu suscripción, no se te cobrara nada adicional.
                </p>
              </dd>
            </div> -->

            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                No voy a continuar con mi tienda
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Si cerraste temporalmente puedes dejar tu tienda inactiva
                  (hasta que vuelvas a retomar) y debes escribirnos por medio
                  del chat de WhatsApp o enviando un correo a hola@komercia.co
                  con la siguiente información: nombre de la tienda, ID, URL y
                  tiempo de inactividad, esto hará que no sea eliminada después
                  de los 30 días sin suscripción activa. Sí, definitivamente no
                  quieres continuar con tu tienda online, esta será eliminada
                  pasado los 30 días de inactividad (sin suscripción activa).
                </p>
              </dd>
            </div>
            <div class="pt-6 md:grid md:grid-cols-12 md:gap-8">
              <dt
                class="text-base font-medium md:col-span-5"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                Política de desembolso
              </dt>
              <dd class="mt-2 md:mt-0 md:col-span-7">
                <p class="text-base text-gray-500">
                  Podemos hacerte un reembolso parcial de valor de tu
                  suscripción anual según el tiempo que te queda, siempre y
                  cuando estés dentro del primer mes del plan. Después de esto
                  no realizamos devoluciones. No hacemos reembolso si estas en
                  la suscripción mensual.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { dataPricesOld } from "../../utils/pricesOld";
import { dataPricesNew } from "../../utils/pricesNew";
import expiredDate from "../../mixins/expiredDate";
import formatDateMomentAWS from "../../mixins/formatDateMomentAWS";
import moment from "moment";
export default {
  name: "SettingSubscription",
  mixins: [expiredDate, formatDateMomentAWS],
  data() {
    return {
      stateCoupon: false,
      codeCoupon: null,
      dataPricesOld,
      dataPricesNew,
      ExpiredPromo: "2023-03-03",
      urlSubscription: "",
      dateCreateAt: "",
      loadingPay: false,
      isGold: false,
      loading: false,
      featuresFree: [
        "Tienda online",
        "Canales de Ventas ",
        "Panel de control",
        "Conexión de dominio",
        "Certificado SSL gratis",
        "50 Productos",
        "5 Categorías",
        "2% comisión por venta",
        "3 plantillas de diseño",
      ],
      featuresPremium: [
        "Tienda online",
        "Canales de Ventas ",
        "Panel de control",
        "Conexión de dominio",
        "Certificado SSL gratis",
        "Productos ilimitados",
        "Categorías ilimitadas",
        "0% comisión x venta",
        "Plantillas de diseño ",
      ],
      optionsCountry: [],
      modalExpiration: {
        stateDemo: false,
        modalMembership: false,
        daysUntilExpiration: 0,
        daysBetweenCreationAndExpiration: 0,
        messageModalMembership: "",
      },
    };
  },
  computed: {
    ...mapState(["storeData", "user", "darkMode"]),
    isPlan2000() {
      if (this.storeData.entidades && this.storeData.entidades.length) {
        let entidad2000 = this.storeData.entidades.filter((x) => x.id == 7);
        if (entidad2000.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    activePlan() {
      if (this.storeData.fecha_expiracion == null) {
        return false;
      } else {
        let x = this.storeData.fecha_expiracion;
        let d1 = new Date();
        let d2 = new Date(x);
        //Vencido
        if (d1.getTime() > d2.getTime()) {
          return false;
        }
        //Activo
        else {
          return true;
        }
      }
    },
    selectplan() {
      let result;
      if (this.storeData.tipo == 0) {
        result = this.featuresFree;
      }
      // premium anual
      else if (this.storeData.tipo == 3) {
        result = this.featuresPremium;
      }
      // remium mensual
      else if (this.storeData.tipo == 9) {
        result = this.featuresPremium;
      }
      return result;
    },
    selectOptionCountry() {
      // if (this.expiredDate(this.ExpiredPromo)) {
      //   return this.dataPricesOld.find(
      //     (country) =>
      //       country.name === this.storeData.informacion_tienda[0].pais.codigo
      //   );
      // } else {
      return this.dataPricesNew.find(
        (country) =>
          country.name === this.storeData.informacion_tienda[0].pais.codigo
      );
      // }
    },
  },
  watch: {
    storeData() {
      this.isGold = [3, 9, 20, 21].indexOf(this.storeData.tipo) !== -1;
      this.setDays();
    },
    currentCountry() {
      this.$store.dispatch("GET_DEPARTMENTS");
    },
  },
  created() {
    this.setDays();
    this.redirectSubscription();
  },
  mounted() {
    this.$store.dispatch("GET_DEPARTMENTS");
    if (
      this.$route.query?.membershipCode &&
      (this.modalExpiration.stateDemo || !this.activePlan)
    ) {
      this.codeCoupon = this.$route.query.membershipCode;
      const section = this.$refs.redeemCode;
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
  methods: {
    async applyCoupon() {
      this.stateCoupon = true;
      if (this.codeCoupon) {
        const { success, data } = await this.$store.dispatch(
          "GET_IS_COUPON_AVAILABLE",
          {
            coupon: this.codeCoupon,
          }
        );
        const { success1, data1 } = await this.$store.dispatch(
          "GET_VERIFY_CLAIM",
          {
            storeId: this.storeData.id,
            coupon: this.codeCoupon,
          }
        );
        if (success && data.available) {
          if (success1 && (!data1?.exists || !data1?.hasCoupon)) {
            const { success, data } = await this.$store.dispatch(
              "PUT_REDEEM_COUPON",
              {
                storeId: this.storeData.id,
                coupon: this.codeCoupon,
              }
            );
            if (success && data) {
              this.stateCoupon = false;
              this.codeCoupon = null;
              this.$swal(
                "Cupón reclamado!",
                `Tu fecha ha sido actualizada, ahora vencerá ${this.formatDateMomentAWS(
                  data.expirationDate
                )}.`,
                "success"
              );
              await this.$store.dispatch("GET_STORE");
            } else {
              if (data?.data?.statusCode === 409) {
                this.$swal(
                  "!Warning!",
                  "Esta tienda ya redimió un cupón.",
                  "warning"
                );
              } else {
                this.$swal("!Error!", "Error al redimir este cupón.", "error");
              }
              this.stateCoupon = false;
            }
          } else {
            this.stateCoupon = false;
            this.$swal(
              "!Advertencia!",
              "Este cupón ya lo redimiste anteriormente.",
              "warning"
            );
          }
        } else {
          this.stateCoupon = false;
          this.$swal(
            "!Error!",
            "Este cupón no existe o ya no esta disponible.",
            "error"
          );
        }
      } else {
        this.$message.warning("Por favor ingresar un código de cupón");
        this.stateCoupon = false;
      }
    },
    suscription(value) {
      this.loadingPay = true;
      axios
        .get(
          `${this.$configKomercia.url}/api/admin/suscripcion?priceId=${value}`,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.loadingPay = false;
          window.location.href = response.data.data.url;
        });
    },
    async redirectSubscription() {
      try {
        const { data } = await axios.get(
          `${this.$configKomercia.url}/api/admin/subscription/my-subscription`,
          this.$store.state.configAxios
        );
        if (data.estado == 200 && data.data) {
          this.urlSubscription = data.data;
        }
      } catch (error) {
        console.log(error.response.data);
      }
    },
    setDays() {
      if (
        this.storeData &&
        this.storeData.created_at &&
        this.storeData.fecha_expiracion
      ) {
        var currentDate = new Date();
        var creationDate = new Date(
          moment(this.storeData.created_at).locale("en").format("LL")
        );
        var expirationDate = new Date(
          moment(this.storeData.fecha_expiracion).locale("en").format("LL")
        );
        this.modalExpiration.daysUntilExpiration = Math.round(
          (expirationDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        this.modalExpiration.daysBetweenCreationAndExpiration = Math.round(
          (expirationDate - creationDate) / (1000 * 60 * 60 * 24)
        );
        if (this.modalExpiration.daysBetweenCreationAndExpiration <= 20) {
          this.modalExpiration.stateDemo = true;
          if (
            this.modalExpiration.daysUntilExpiration >= 0 &&
            this.modalExpiration.daysUntilExpiration <= 20
          ) {
            this.modalExpiration.modalMembership = true;
            this.modalExpiration.messageModalMembership =
              "Modo de prueba. Quedan " +
              this.modalExpiration.daysUntilExpiration +
              " días hasta el vencimiento.";
            this.modalExpiration.modalMembership = true;
          }
        } else {
          if (this.modalExpiration.daysUntilExpiration <= 1) {
            this.modalExpiration.modalMembership = true;
          }
          if (
            this.modalExpiration.daysUntilExpiration >= 0 &&
            this.modalExpiration.daysUntilExpiration <= 365
          ) {
            this.modalExpiration.messageModalMembership =
              "Membresía activa (NORMAL). Quedan " +
              this.modalExpiration.daysUntilExpiration +
              " días hasta el vencimiento.";
          } else if (this.modalExpiration.daysUntilExpiration >= 0) {
            this.modalExpiration.messageModalMembership =
              "Membresía activa (RARO). Quedan " +
              this.modalExpiration.daysUntilExpiration +
              " días hasta el vencimiento.";
          }
        }
        if (this.modalExpiration.daysUntilExpiration < 0) {
          this.modalExpiration.messageModalMembership = "vencida";
        }
      }
    },
  },
};
</script>

<style scoped>
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.card-price-before {
  font-size: 12px;
  color: #4429b4;
  @apply w-auto flex flex-row justify-center items-center text-center mt-1;
}
.card-price-span-before {
  font-size: 17px;
  color: #4429b4;
  @apply w-auto flex flex-col justify-center items-center text-center ml-5 line-through;
}
.wrapper-content-items {
  width: 100%;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.dark {
  background-color: #1b2836;
}
.light {
  @apply bg-gray-100;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  @apply text-gray-800;
}
.span-promo {
  color: #755fc2;
}
.content-txt-CLP {
  @apply w-auto h-full flex flex-col justify-start items-start leading-tight;
}
.txt-promoCLP-1 {
  color: #755fc2;
  font-size: 12px;
}
.refCLP-1 {
  color: #333;
  font-size: 16px;
  margin-left: 5px;
}
.txt-promoCLP {
  background-color: #4429ab;
  color: #7cdb8f;
  font-size: 16px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
  margin-top: 2px;
  @apply rounded items-center justify-items-center font-bold;
}
.refCLP {
  color: #fff;
  font-size: 20px;
  margin-left: 5px;
  @apply font-bold;
}
.content-text-promo {
  @apply w-full flex flex-row justify-center items-center;
}
.countdown {
  width: auto;
  background-color: #3e3e3e;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  @apply flex flex-row justify-center items-center rounded;
}
.txt-ref {
  color: #9886d6;
  font-size: 14px;
}
.txt-discount {
  color: #fff;
  font-size: 14px;
  @apply font-semibold;
}
.content-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  background-color: #172b4d;
  margin-right: 15px;
  border-radius: 5px;
  padding: 26px;
}
.pay {
  padding: 20px 26px 10px 26px;
  /* max-width: 400px; */
  /* width: 100%; */
  background-color: #172b4d;
  border-radius: 5px;
  display: grid;
  gap: 4px;
}
.content-date {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.content-pricing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  height: 100%;
  margin-top: 15px;
  background-color: #172b4d;
  padding: 20px;
  border-radius: 5px;
}
.title {
  /* font-size: 24px; */
  font-weight: bold;
  color: white;
  font-size: 1.125rem;
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-weight: 500;
  letter-spacing: 0.01rem;
  margin-bottom: 5px;
}
.title-button {
  font-size: 14px;
  color: rgb(26, 46, 59);
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-weight: 500;
  letter-spacing: 0.01rem;
  background-color: #fdd100;
  background-color: #4429b4;
  color: white;
}
.pay-button {
  margin-top: 9px;
  font-size: 14px;
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-weight: 500;
  letter-spacing: 0.01rem;
  background-color: #fdd100;
  color: black;
  text-align: center;
}
.tag-warning {
  font-size: 12px;
  color: rgb(26, 46, 59);
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-weight: 500;
  letter-spacing: 0.01rem;
  background-color: #fffdf4;
  border: 1px solid red;
  margin-top: 8px;
  padding: 5px 10px;
  border-radius: 4px;
}
/* p {
  font-size: 12px;
  font-weight: 600;
  color: rgb(26, 46, 59);
  color: white;
  font-weight: 500;
  letter-spacing: 0.01rem;
} */
.content-suscription {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background-color: #253858;
  border-radius: 5px;
  color: white;
}
.content-paying {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #253858;
  border-radius: 5px;
  color: white;
}
.content-suscription p {
  margin-bottom: 5px;
}
.btn-pay {
  border: 0;
  background-color: var(--green);
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: #114b24;
  transition: all ease 0.3s;
  margin-top: 12px;
}
.btn-pay:hover {
  background-color: #abedc1;
}
/* label {
  color: hsla(0, 0%, 100%, 0.494);
  line-height: 1.5;
} */
.message {
  padding: 5px 22px;
}
.message p {
  color: #172b4d;
  text-align: center;
}
.wrapper-img {
  padding: 0 80px;
  overflow: hidden;
  margin-top: 10px;
}
.cards {
  width: 220px;
  border-radius: 4px;
}
.header {
  display: flex;
  justify-content: space-between;
}
/* .flex {
  display: flex;
  flex-direction: column;
}
.flex >>> input:disabled {
  background-color: #dae7fc;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
} */
.pulse {
  animation: shadow-pulse 1s infinite;
}
.img-logo-pago {
  width: 100%;
  max-width: 160px;
}
.text-refer {
  color: #4429ab;
  @apply font-semibold;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(54, 255, 144);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
@media (max-width: 700px) {
  .wrapper {
    flex-wrap: wrap;
  }
  .content-info {
    margin: 0 0 0px 0;
  }
  .pay {
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 800px) {
  .sub-title {
    font-size: 15px;
  }
  .grid-suscrip {
    @apply w-full grid grid-cols-1 gap-4;
  }
}
@media (min-width: 1250px) {
  .sub-title {
    font-size: 22px;
  }
  .grid-suscrip {
    @apply w-full grid grid-cols-2 gap-4;
  }
}
</style>
