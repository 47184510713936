<template>
  <div class="content-stats p-3" :class="darkMode ? 'darks' : 'lights'">
    <div class="content-stats-detail w-11/12 lg:w-10/12">
      <h1
        v-if="storeData.id"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
        class="title"
      >
        {{
          storeData.cantidad_visitas.length
            ? storeData.cantidad_visitas[0].numero_visitas
            : 0 | formatCurrencys()
        }}
      </h1>
      <div class="flex flex-row">
        <p class="subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_inicio_visitar") }}
          <br />{{ $t("text_inicio_totales") }}
        </p>
        <div class="content-visits">
          <img
            loading="lazy"
            class="visits-img"
            src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/User_Icon_z2jqj7.webp"
            alt="Imagen de usuario por defecto"
          />
        </div>
      </div>
    </div>
    <div class="divider-vertical" />
    <div class="content-stats-detail w-11/12 lg:w-10/12">
      <h1
        v-if="storeData && storeData.productos_count"
        class="title"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        {{ storeData.productos_count }}
      </h1>
      <h1 v-else class="title" :class="darkMode ? 'darktxt' : 'lighttxt'">0</h1>
      <div class="flex flex-row">
        <p class="subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_inicio_productos") }}
          <br />{{ $t("text_inicio_publicados") }}
        </p>
        <div class="content-products">
          <img
            loading="lazy"
            class="products-img"
            src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/Photo_Icon_oaxphe.webp"
            alt="Imagen de productos"
          />
        </div>
      </div>
    </div>
    <div class="divider-vertical" />
    <div class="content-stats-detail w-11/12 lg:w-10/12">
      <h1
        v-if="storeData && storeData.carritos_count"
        class="title"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
      >
        {{ storeData.carritos_count }}
      </h1>
      <h1 v-else class="title" :class="darkMode ? 'darktxt' : 'lighttxt'">0</h1>
      <div class="flex flex-row">
        <p class="subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_inicio_ventas") }}
          <br />{{ $t("text_inicio_generadas") }}
        </p>
        <div class="content-arrow">
          <img
            loading="lazy"
            class="arrow-vertical"
            src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/arrow_uqs8pq.webp"
            alt="Flecha vertical"
          />
        </div>
      </div>
    </div>
    <div class="divider-vertical" />
    <div class="content-stats-detail w-full">
      <h1
        v-if="storeData && storeData.ventas && storeData.ventas.total_ventas"
        :class="darkMode ? 'darktxt' : 'lighttxt'"
        class="title"
      >
        {{
          storeData.ventas.total_ventas
            | formatCurrency(
              storeData &&
                storeData.informacion_tienda &&
                storeData.informacion_tienda.length > 0 &&
                storeData.informacion_tienda[0].paises_id
                ? storeData.informacion_tienda[0].paises_id
                : 1
            )
        }}
      </h1>
      <h1 v-else :class="darkMode ? 'darktxt' : 'lighttxt'" class="title">0</h1>
      <div class="flex flex-row">
        <p class="subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_inicio_valor") }}
          <br />{{ $t("text_inicio_total") }}
        </p>
        <div class="content-income">
          <img
            loading="lazy"
            class="income-img"
            src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/hearth_dwusmy.webp"
            alt="Imagen de ingresos"
          />
        </div>
      </div>
    </div>
    <!-- Version Responsive -->
    <div class="content-stats-detail-responsive">
      <div class="content-arrow">
        <img
          loading="lazy"
          class="arrow-vertical"
          src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/arrow_uqs8pq.webp"
          alt="Flecha vertical"
        />
      </div>
      <div class="content-info">
        <p class="subtitle" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_inicio_ventas") }}
        </p>
        <h1
          v-if="storeData && storeData.carritos_count"
          class="title"
          :class="darkMode ? 'darktxt' : 'lighttxt'"
        >
          {{ storeData.carritos_count }}
        </h1>
        <h1 v-else class="title" :class="darkMode ? 'darktxt' : 'lighttxt'">
          0
        </h1>
      </div>
    </div>
    <div class="content-stats-detail-responsive-visits">
      <div class="content-visits">
        <img
          loading="lazy"
          class="visits-img"
          src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/User_Icon_z2jqj7.webp"
          alt="Imagen de usuario por defecto"
        />
      </div>
      <div class="content-info">
        <p class="subtitle">
          {{ $t("text_inicio_visitar") }}
        </p>
        <h1 v-if="storeData.id" class="title">
          {{
            storeData.cantidad_visitas.length
              ? storeData.cantidad_visitas[0].numero_visitas
              : 0
          }}
        </h1>
      </div>
    </div>
    <div class="content-stats-detail-responsive-income">
      <div class="content-income">
        <img
          loading="lazy"
          class="income-img"
          src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/hearth_dwusmy.webp"
          alt="Imagen de ingresos"
        />
      </div>
      <div class="content-info">
        <p class="subtitle">
          {{ $t("text_inicio_ingresos") }}
        </p>
        <h1
          v-if="storeData && storeData.ventas && storeData.ventas.total_ventas"
          class="title"
        >
          {{
            storeData.ventas.total_ventas
              | formatCurrency(
                storeData &&
                  storeData.informacion_tienda &&
                  storeData.informacion_tienda.length > 0 &&
                  storeData.informacion_tienda[0].paises_id
                  ? storeData.informacion_tienda[0].paises_id
                  : 1
              )
          }}
        </h1>
        <h1 v-else class="title">0</h1>
      </div>
    </div>
    <div class="content-stats-detail-responsive-products">
      <div class="content-products">
        <img
          loading="lazy"
          class="products-img"
          src="https://res.cloudinary.com/santaluciawebpage/image/upload/q_auto,f_auto/v1593694081/Photo_Icon_oaxphe.webp"
          alt="Imagen de productos"
        />
      </div>
      <div class="content-info">
        <p class="subtitle">
          {{ $t("text_inicio_productos") }}
        </p>
        <h1 v-if="storeData && storeData.productos_count" class="title">
          {{ storeData.productos_count }}
        </h1>
        <h1 v-else class="title">0</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formatCurrency from "../mixins/formatCurrent";
export default {
  filters: {
    formatCurrencys(n) {
      if (n) {
        return parseInt(n)
          .toFixed()
          .replace(/(\d)(?=(\d{3})+(,|$))/g, "$1.");
      } else {
        return 0;
      }
    },
  },
  mixins: [formatCurrency],
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    ...mapState(["storeData"]),
  },
};
</script>

<style scoped>
.darks {
  background-color: rgb(41, 61, 81);
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.content-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.content-stats-detail {
  @apply flex flex-row justify-between items-center;
}
.divider-vertical {
  height: 50px;
  width: 1.3px;
  background-color: rgba(163, 162, 162, 0.397);
  @apply mx-3;
}
.title {
  margin-right: 2px;
  font-size: 20px;
  font-weight: bold;
  /* color: #4c4c4c; */
}
.subtitle {
  font-size: 12px;
  font-weight: 400;
  margin-right: 5px;
  /* color: #706f6f; */
}
.icon_stats {
  color: rgb(5, 172, 5);
  margin-left: 8px;
  font-size: 18px;
}
.content-stats-detail-responsive {
  display: none;
}
.content-stats-detail-responsive-visits {
  display: none;
}
.content-stats-detail-responsive-income {
  display: none;
}
.content-stats-detail-responsive-products {
  display: none;
}
.content-arrow {
  background: rgba(110, 50, 186, 0.2);
  border-radius: 3px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-visits {
  background: rgba(41, 191, 118, 0.2);
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-income {
  background: #fbf0c8;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-products {
  background: rgba(191, 77, 41, 0.2);
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .content-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .divider-vertical {
    display: none;
  }
}
@media (max-width: 767px) {
  .content-arrow {
    background: rgba(110, 50, 186, 0.2);
    border-radius: 3px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-visits {
    background: rgba(41, 191, 118, 0.2);
    border-radius: 5px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-income {
    background: #fbf0c8;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-products {
    background: rgba(191, 77, 41, 0.2);
    border-radius: 5px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-stats {
    display: flex;
    margin: 0px 0px 5px 0px;
    overflow-x: auto;
  }
  .content-stats-detail {
    display: none;
  }
  .box {
    box-shadow: unset;
  }
  .content-stats-detail-responsive {
    display: flex;
    align-items: center;
    padding: 24px;
    padding-left: 13px;
  }
  .content-stats-detail-responsive-visits {
    display: flex;
    align-items: center;
    padding: 24px;
    padding-left: 5px;
  }
  .content-stats-detail-responsive-income {
    display: flex;
    align-items: center;
    padding: 24px;
    padding-left: 5px;
  }
  .content-stats-detail-responsive-products {
    display: flex;
    align-items: center;
    padding: 24px;
    padding-left: 5px;
  }
  .title {
    font-size: 16px;
    /* color: #042c5c; */
  }
  .subtitle {
    font-size: 11px;
    /* color: #77869e; */
  }
  .content-info {
    margin-left: 8px;
  }
}
@media (max-width: 465px) {
  .content-stats {
    grid-template-columns: 1fr;
  }
}
</style>
