<template>
  <div
    class="w-full relative pt-3 pb-14 px-4 sm:px-6 lg:pt-5 lg:px-3"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div
      class="w-full m-auto rounded text-center md:text-left px-4 py-3 md:rounded-xl md:shadow"
      :class="darkMode ? 'darks' : 'lights'"
    >
      <h1
        class="flex items-center text-2xl sm:mb-4"
        :class="darkMode ? 'darktxts' : 'lighttxts'"
      >
        Blog
        <sliderInformation :numberId="71" :actions="true" class="ml-2" />
      </h1>
      <p
        class="font-normal hidden md:flex"
        :class="darkMode ? 'darktxts' : 'lighttxts'"
      >
        {{ $t("text_blog_info") }}
      </p>
      <div class="my-4">
        <el-input
          v-model="search"
          type="text"
          class="inputs-idTienda"
          :class="darkMode ? 'darks-input' : 'light-input'"
          :placeholder="$t('text_blog_buscarBlog')"
        />
      </div>
    </div>
    <div class="w-full relative mt-6 max-w-7xl mx-auto">
      <div v-if="stateShow" class="w-full">
        <div class="w-full mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          <router-link
            to="/tienda/blog/newArticulo"
            class="card h-full flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer hover:shadow-xl hover:scale-95 transform transition duration-100 ease-in-out border-2 hover:border-green-komercia-500"
          >
            <div
              class="flex-1 p-1 flex flex-col items-center justify-center hover:text-green-komercia-500"
              :class="darkMode ? 'darktxtst darks' : 'lighttxtst lights'"
            >
              <i class="fas fa-plus-circle text-4xl pb-2"></i>
              <p class="font-bold no-underline text-2xl">
                {{ $t("text_blog_crearNblog") }}
              </p>
            </div>
          </router-link>
          <div v-for="(item, index) in filteredList" :key="index">
            <router-link
              :to="`/tienda/blog/editArticulo/${item.id}`"
              class="relative flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer hover:shadow-xl hover:scale-95 transform transition duration-100 ease-in-out"
            >
              <div class="absolute top-0 right-0">
                <div
                  v-if="item.estado == 1"
                  class="p-1 flex bg-green-komercia-600 items-baseline"
                >
                  <p class="text-sm font-semibold text-black">
                    {{ $t("text_blog_publicado") }}
                  </p>
                </div>
                <div
                  v-if="item.estado == 0"
                  class="p-1 flex bg-red-400 items-baseline"
                >
                  <p class="text-sm font-semibold text-white">
                    {{ $t("text_blog_editando") }}
                  </p>
                </div>
              </div>
              <div class="flex-shrink-0">
                <img
                  loading="lazy"
                  :src="
                    item && item.imagen_principal_url
                      ? idCloudinaryAwards(item.imagen_principal_url, 400, 400)
                      : article
                  "
                  class="h-48 w-full object-cover"
                />
              </div>
              <div
                class="flex-1 p-6 flex flex-col justify-between"
                :class="darkMode ? 'darks' : 'lights'"
              >
                <div class="flex-1">
                  <!-- <p
                  class="text-sm font-medium text-purple-komercia-400"
                  v-if="item.category"
                >
                  {{ item.category }}
                </p>
                <p style="height: 21px;" v-else></p> -->
                  <div
                    class="block mt-2"
                    style="min-height: 147px; max-height: 147px"
                  >
                    <p
                      class="text-xl font-semibold limit-title"
                      :class="darkMode ? 'darktxt' : 'lighttxt'"
                    >
                      {{ item.titulo }}
                    </p>
                    <p
                      class="mt-3 text-gray-500 limit-description"
                      style="font-size: 15px"
                    >
                      {{ item.resumen }}
                    </p>
                  </div>
                </div>
                <div v-if="item.autor" class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#">
                      <span class="sr-only">{{ item.autor }}</span>
                      <img
                        loading="lazy"
                        class="h-10 w-10 rounded-full"
                        src="https://api2.komercia.co/users/user.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="ml-3">
                    <p
                      class="text-sm font-medium"
                      :class="darkMode ? 'darktxt' : 'lighttxt'"
                    >
                      <a href="#" class="hover:underline">
                        {{ item.autor }}
                      </a>
                    </p>
                    <div
                      class="flex space-x-1 text-sm"
                      :class="darkMode ? 'darktxts' : 'lighttxts'"
                    >
                      <time datetime="2020-03-16">
                        {{ item.created_at }}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          v-if="listBlogs && listBlogs.total > 11 && !isFiltered"
          class="w-full flex justify-center items-center mt-3"
        >
          <el-pagination
            layout="prev, pager, next"
            :hide-on-single-page="true"
            :page-count="listBlogs.last_page"
            :current-page="listBlogs.page"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div v-else class="flex flex-col justify-center w-full items-center">
        <div class="spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
        <p class="text-2xl font-semibold text-black">Cargando artículos..</p>
      </div>
    </div>
  </div>
</template>

<script>
import sliderInformation from "@/components/information.vue";
import idCloudinary from "@/mixins/idCloudinary";
import axios from "axios";
export default {
  mixins: [idCloudinary],
  components: {
    sliderInformation,
  },
  data() {
    return {
      search: "",
      listBlogs: [],
      currentPage: 1,
      isFiltered: false,
      stateShow: false,
      article:
        "https://res.cloudinary.com/komercia-components/image/upload/c_scale,q_auto:best,f_auto/v1598040886/sws8xa8z0oyu252nqxzv.webp",
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    filteredList() {
      if (this.search) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isFiltered = true;
        return this.listBlogs.data.filter((element) => {
          // console.log(element)
          return element.titulo
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isFiltered = false;
        return this.listBlogs.data;
      }
    },
  },
  created() {
    this.currentChange(this.currentPage);
  },
  methods: {
    currentChange(value) {
      this.getArticles(value);
    },
    getArticles(value) {
      axios
        .get(
          `${this.$store.state.configKomercia.url}/api/admin/blog?page=${value}&per_page=11`,
          this.$store.state.configAxios
        )
        .then((response) => {
          this.listBlogs = response.data.blogs;
          this.stateShow = true;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped src="../../../assets/css/input.css"></style>
<style scoped>
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: #333;
}
.darktxts {
  color: #939cae;
}
.lighttxts {
  @apply text-purple-komercia-500;
}
.darktxtst {
  color: #939cae;
}
.lighttxtst {
  @apply text-purple-komercia-500;
}
.card {
  max-height: 460px;
  min-height: 460px;
}
.limit-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.limit-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.spinner {
  margin: 50px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}
.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #4429b4;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
