import axios from "axios";

export default {
  namespaced: true,
  state: {
    dataAnalytics: {},
    dataEventsCount: {},
    dataDeviceCount: {},
    dataTopCategories: {},
    dataTopProductCart: {},
    dataTopClient: {},
    dataProductAnalytics: {},
    dataEventHistory: {},
  },
  mutations: {
    SET_DATA_ANALYTICS_STORE(state, value) {
      state.dataAnalytics = value;
    },
    SET_EVENTS_COUNT(state, value) {
      state.dataEventsCount = value;
    },
    SET_DEVICE_COUNT(state, value) {
      state.dataDeviceCount = value;
    },
    SET_CATEGORIES_TOP(state, value) {
      state.dataTopCategories = value;
    },
    SET_PRODUCT_TO_CART_TOP(state, value) {
      state.dataTopProductCart = value;
    },
    SET_CLIENT_TOP(state, value) {
      state.dataTopClient = value;
    },
    SET_PRODUCT_ANALYTICS(state, value) {
      state.dataProductAnalytics = value;
    },
    SET_EVENT_HISTORY(state, value) {
      state.dataEventHistory = value;
    },
  },
  actions: {
    async GET_DATA_ANALYTICS_STORE({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/v1/stores/private/analytics`,
          headers: rootState.configAxios.headers,
          params: {
            event: params.event || null,
            startDate: params.startDate || null,
            endDate: params.endDate || null,
            device: params.device || null,
            productId: params.productId || null,
            categoryId: params.categoryId || null,
            mostRecent: params.mostRecent || null,
          },
        });
        if (data) {
          commit("SET_DATA_ANALYTICS_STORE", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_EVENTS_COUNT({ commit, rootState }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/v1/stores/private/analytics/events-count`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          commit("SET_EVENTS_COUNT", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_DEVICE_COUNT({ commit, rootState }) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/v1/stores/private/analytics/devices-count`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          commit("SET_DEVICE_COUNT", data.data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },

    async GET_CATEGORIES_TOP({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/analytics/${params.idStore}/categories/top-ten`,
          headers: {
            KOMERCIA_PUBLIC_ROUTES_KEY: rootState.routerKey,
          },
        });
        if (data) {
          commit("SET_CATEGORIES_TOP", data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_PRODUCT_TO_CART_TOP({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/analytics/${params.idStore}/products/top-ten`,
          headers: {
            KOMERCIA_PUBLIC_ROUTES_KEY: rootState.routerKey,
          },
        });
        if (data) {
          commit("SET_PRODUCT_TO_CART_TOP", data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_CLIENT_TOP({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/analytics/${params.idStore}/clients/top-ten`,
          headers: {
            KOMERCIA_PUBLIC_ROUTES_KEY: rootState.routerKey,
          },
        });
        if (data) {
          commit("SET_CLIENT_TOP", data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_PRODUCT_ANALYTICS({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/analytics/${params.storeId}/products`,
          headers: {
            KOMERCIA_PUBLIC_ROUTES_KEY: rootState.routerKey,
          },
          params: {
            page: params.page,
            limit: params.limit,
            name: params.name,
            startDate: params.startDate,
            endDate: params.endDate,
          },
        });
        if (data) {
          commit("SET_PRODUCT_ANALYTICS", data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
    async GET_EVENT_HISTORY({ commit, rootState }, params) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/analytics/${params.storeId}/history`,
          headers: {
            KOMERCIA_PUBLIC_ROUTES_KEY: rootState.routerKey,
          },
          params: {
            startDate: params.startDate,
            endDate: params.endDate,
            event: params.event,
          },
        });
        if (data) {
          commit("SET_EVENT_HISTORY", data);
          return {
            success: true,
            data: data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response,
        };
      }
    },
  },
};
