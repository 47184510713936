<template>
  <div v-show="modalExport" class="modal" @click="closeOrder">
    <div class="wrapper-items-form">
      <div
        ref="form"
        class="content-form"
        :class="darkMode ? 'darks' : 'lights'"
      >
        <div v-if="showCard" class="wrapper-item-card">
          <!-- Exportar -->
          <div
            class="py-5 flex flex-col items-center shadow-lg border rounded-lg border-gray-200"
          >
            <img
              loading="lazy"
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649276085/2264789_x9nlrl.webp"
              alt="icon export"
              width="140"
            />
            <h1 class="flex items-center text-xl font-bold mt-5">
              {{ $t("text_paymentBlock_title_exportar") }}
              <sliderInformation :numberId="30" :actions="true" class="ml-2" />
            </h1>
            <p class="text-center text-sm mt-2 text-gray-400">
              {{ $t("text_paymentBlock_descrip_exportar") }}
            </p>
            <!-- <download-excel
              
              :data="products"
              :fields="productFields"
              type="xls"
              :name="`products${new Date()}.xls`"
            >
              <button>{{ $t('text_paymentBlock_textbtn_exportar') }}</button>
            </download-excel> -->
            <button
              v-if="products && products.length"
              class="btn_export"
              @click="exportProducts"
            >
              {{ $t("text_paymentBlock_textbtn_exportar") }}
            </button>
          </div>
          <!-- Importar -->
          <div
            v-if="infoProductsData.total < settingProducts.amount"
            class="py-5 flex-col justify-center items-center shadow-lg border rounded-lg border-gray-200 showResponsive"
          >
            <img
              loading="lazy"
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649276085/2264798_nkyxw3.webp"
              alt="icon export"
              width="140"
            />
            <h1 class="flex items-center text-xl font-bold mt-5">
              {{ $t("text_paymentBlock_title_importar") }}
              <sliderInformation :numberId="31" :actions="true" class="ml-2" />
            </h1>
            <p class="text-center text-sm mt-2 text-gray-400">
              {{ $t("text_paymentBlock_descrip_importar") }}
            </p>
            <button
              v-if="infoProductsData.total < settingProducts.amount"
              class="btn_import"
              @click="showCard = !showCard"
            >
              {{ $t("text_paymentBlock_textbtn_importar") }}
            </button>
          </div>
          <div
            v-else
            class="py-5 flex flex-col justify-center items-center shadow-lg border rounded-lg border-gray-200"
          >
            <img
              loading="lazy"
              src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1648662138/595067_dn9c6c.webp"
              alt="icon export"
              width="140"
            />
            <h1 class="text-xl font-bold mt-5">
              {{ $t("text_paymentBlock_advertencia") }}
            </h1>
            <p class="text-center text-sm mt-2 text-gray-400">
              {{ $t("text_paymentBlock_text_advertencia") }}
            </p>
            <router-link to="/configuracion/membresia" class="btn_membresia">
              {{ $t("text_paymentBlock_renovar") }}
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row justify-start items-center mb-4">
            <button
              class="pl-1 text-sm text-red-600"
              @click="showCard = !showCard"
            >
              {{ $t("text_paymentBlock_brear_text1") }}
            </button>
            <p class="pl-2 text-sm text-red-300">
              {{ $t("text_paymentBlock_brear_text2") }}
            </p>
          </div>
          <div
            class="py-5 flex flex-col items-center shadow-lg border rounded-lg border-gray-200 bg-white"
          >
            <el-upload
              ref="upload"
              class="text-center"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-change="handleFileUpload"
              :auto-upload="false"
              :multiple="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Soltar el archivo aquí o
                <em>haga clic para subir</em>
              </div>
              <div slot="tip" class="el-upload__tip">
                Archivos csv con un peso menor a 500 kilobytes
              </div>
            </el-upload>

            <div class="import__action">
              <button
                v-if="selectedFile"
                class="mt-2 border rounded-md bg-indigo-700 text-white px-5 py-2 cursor-pointer"
                @click="createProductsApi"
              >
                {{ $t("text_paymentBlock_confirmar") }}
              </button>
            </div>
            <p
              v-if="stateMgs"
              class="mt-5 px-4 py-2 bg-red-500 rounded-md text-white text-base font-bold flex flex-row text-center"
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Subiendo archivos, por favor espere.
            </p>
          </div>
          <div class="flex flex-row justify-center items-center text-center">
            <p class="text-sm text-gray-400 my-7">
              Te facilitamos una plantilla con las columnas limitadas para la
              creación pero necesarias para la importación.
              <a
                href="../../template_multiples_product.csv"
                download="template_multiples_product.csv"
                title=" Descargar la plantilla de productos"
              >
                <button class="cursor-pointer underline ml-2 text-indigo-700">
                  Descargar la plantilla aquí
                </button>
              </a>
            </p>
          </div>
        </div>

        <div v-if="showCard" class="w-full flex justify-center text-center">
          <p
            class="mt-5 py-2 px-5 bg-red-500 text-white cursor-pointer rounded-lg"
            @click="closeDialog()"
          >
            {{ $t("text_cupones_cerrarVentana") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import XLSX from 'xlsx'
import sliderInformation from "@/components/information.vue";
import axios from "axios";
export default {
  components: { sliderInformation },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      productFields: {
        id: "id",
        nombre: "nombre",
        imagen: "foto_cloudinary",
        descripcion_corta: "descripcion_corta",
        precio_sin_variantes: "precio_sin_variantes",
        unidades: "inventario",
        alto: "alto",
        ancho: "ancho",
        largo: "largo",
        peso: "peso",
        sku: "sku",
        marca: "marca",
        garantia: "garantia",
        envio_gratis: "envio_gratis",
        video_youtube: "video_youtube",
      },
      stateMgs: false,
      selectedFile: null,
      showCard: true,
      file: {},
      json_array: [],
    };
  },
  computed: {
    modalExport() {
      return this.$store.state.modalExport;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
    infoProductsData() {
      return this.$store.state.infoPaginationProducts;
    },
    settingProducts() {
      return this.$store.getters.settingProducts;
    },
    tabSelected: {
      get() {
        return this.tabAction;
      },
      set(newValue) {
        this.$emit("update:tabAction", newValue);
      },
    },
  },
  methods: {
    closeDialog() {
      // this.showCard = true
      this.$store.state.modalExport = false;
      this.$refs.upload.clearFiles();
    },
    closeOrder(event) {
      const element = event.target.className;
      if (element === "wrapper-items-form") {
        this.$store.state.modalExport = false;
        // this.showCard = true
      }
    },
    handleFileUpload(file_incoming) {
      if (
        file_incoming.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file_incoming.raw.type === "text/csv"
      ) {
        this.selectedFile = file_incoming.raw;
      } else {
        this.$refs.upload.clearFiles();
        this.$swal("Error!", "Tipo de archivo inválido", "error");
      }
    },

    async createProductsApi() {
      this.stateMgs = true;
      const file = new FormData();
      file.append("file", this.selectedFile);
      try {
        const data = await axios.post(
          `${this.$store.state.urlKomerciaAWS}/api/v1/products/admin/import`,
          file,
          this.$store.state.configAxios
        );
        if (data?.data.success) {
          this.$store.state.modalExport = false;
          this.$store.dispatch("GET_PRODUCTS", {
            page: 1,
            status: 1,
            per_page: 24,
          });
          this.$swal(
            "Productos importados",
            "Todos tus productos han sido importados.",
            "success"
          );
          this.$refs.upload.clearFiles();
          this.stateMgs = false;
        } else {
          this.$swal(
            "Productos no importados",
            "Se ha producido un error al importar los productos.",
            "Error"
          );
          this.$refs.upload.clearFiles();
          this.stateMgs = false;
        }
      } catch (err) {
        this.$swal(
          "Productos no importados",
          "Se ha producido un error al importar los productos.",
          "Error"
        );

        this.$refs.upload.clearFiles();
        this.stateMgs = false;
        console.log(err);
      }
    },
    async exportProducts() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${this.$configKomercia.url}/api/admin/productos/exportar`,
          headers: this.$store.state.configAxios.headers,
        });
        if (data) {
          window.open(
            `${this.$configKomercia.url}/${data.data.route}`,
            "_blank"
          );
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    // onChange() {
    //   this.file = this.$refs.file.files[0]
    //   let self = this
    //   var reader = new FileReader()
    //   reader.onload = async function(e) {
    //     var data = new Uint8Array(e.target.result)
    //     var workbook = XLSX.read(data, { type: 'array' })
    //     let sheetName = workbook.SheetNames[0]
    //     let worksheet = workbook.Sheets[sheetName]
    //     self.json_array = await XLSX.utils.sheet_to_json(worksheet)
    //   }
    //   reader.readAsArrayBuffer(this.file)
    // },
    // dragOver(event) {
    //   event.preventDefault()
    //   this.showEffect()
    // },
    // dragLeave(event) {
    //   event.preventDefault()
    //   this.resetEffect()
    // },
    // drop(event) {
    //   event.preventDefault()
    //   this.$refs.file.files = event.dataTransfer.files
    //   this.resetEffect()
    //   this.onChange()
    // },
    // showEffect() {
    //   document.querySelector('.drop-rect').style.border = '3px green dashed'
    // },
    // resetEffect() {
    //   document.querySelector('.drop-rect').style.border = '1px gray dashed'
    // },
  },
};
</script>

<style scoped>
.modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @apply w-full h-full fixed z-10 left-0 top-0 overflow-auto;
}
.wrapper-items-form {
  @apply flex justify-center items-center w-full h-full top-1/2;
}
.content-form {
  width: 100%;
  max-width: 700px;
  padding: 40px 40px 15px 40px;
  border-radius: 6px;
  background-color: #f3f4f6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
.btn_export {
  @apply mt-5 border rounded-md border-indigo-700 text-indigo-700 px-5 py-2 cursor-pointer;
}
.btn_export:hover {
  @apply border-green-500 text-green-500;
}
.btn_import {
  @apply mt-5 border rounded-md bg-indigo-700 text-white px-5 py-2 cursor-pointer;
}
.btn_import:hover {
  @apply bg-green-500;
}
.btn_membresia {
  @apply mt-5 border rounded-md bg-red-500 text-white px-5 py-2 cursor-pointer;
}
.btn_membresia:hover {
  @apply bg-red-600;
}
.drop-rect {
  width: 100%;
  border: 3px dashed #bbb;
  height: 100px;
  padding: 25px;
  text-align: center;
  font: 20px;
  font-weight: bold;
  color: #bbb;
}
.drop-rect:hover {
  border: 3px green dashed;
  color: green;
}
.wrapper-item-card {
  @apply grid grid-cols-2 gap-5;
}
.showResponsive {
  display: flex;
}
@media (max-width: 900px) {
  .wrapper-item-card {
    @apply flex;
  }
  .content-form {
    max-width: 600px;
    padding: 20px 20px 5px 20px;
  }
}
@media (max-width: 700px) {
  .wrapper-item-card {
    @apply flex;
  }
  .content-form {
    max-width: 350px;
    padding: 20px 20px 5px 20px;
  }
  .showResponsive {
    display: none;
  }
}
</style>
