<template>
  <div class="wrapper-content-items" :class="darkMode ? 'darkbgs' : 'lightbgs'">
    <div
      class="content-header-items"
      :class="darkMode ? 'darkbgs2' : 'lightbgs'"
    >
      <div>
        <h1
          class="text-lg font-bold"
          :class="darkMode ? 'darktxts' : 'lighttxts'"
        >
          {{ $t("text_tags") }} 🔖
        </h1>
        <!-- <p>description</p> -->
      </div>
      <div>
        <button
          :class="darkMode ? 'darktxts' : 'lighttxts'"
          size="small"
          class="btnCategories flex items-center"
          @click="handleSidebarHelp('88')"
        >
          {{ $t("text_inicio_capacitacion") }} {{ $t("text_tags") }}
          <img
            loading="lazy"
            class="w-5 ml-2"
            src="@/assets/information.webp"
            alt="imgInformation"
          />
        </button>
        <el-button
          v-if="stateEtiquetas"
          class="buttonActions"
          @click="closeEtiquetas"
        >
          Cerrar ventana
        </el-button>
      </div>
    </div>
    <div class="wrapper-box mt-4 h-full">
      <!-- tabla -->
      <div
        class="content-list-items h-full"
        :class="darkMode ? 'darkbgs2' : 'lightbgs'"
      >
        <div v-if="filterTags.length > 0" class="h-full">
          <div class="tags_header">
            <p
              class="text-base font-semibold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              {{ $t("text_inventario_nombre") }}
            </p>
            <p
              class="text-base font-semibold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              {{ $t("text_etiquetas_posicion") }}
            </p>
            <p
              class="text-base font-semibold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              Status
            </p>
            <p
              class="text-base font-semibold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              Visibilidad
            </p>
            <p
              class="text-base font-semibold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              {{ $t("text_inventario_copiarUrl") }}
            </p>
            <p
              class="text-base font-semibold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              {{ $t("text_ventas_accciones") }}
            </p>
          </div>
          <ul>
            <li
              v-for="(items, index) in filterTags"
              :key="`tag_${items.id}`"
              class="tags_item"
            >
              <div
                :class="{
                  tags_item_row: true,
                  selected: selectedTags === items.id,
                }"
              >
                <div class="tag_item_row_text">
                  <p :class="darkMode ? 'darktxts' : 'lighttxts'">
                    {{ items.name }}
                  </p>
                </div>
                <p :class="darkMode ? 'darktxts' : 'lighttxts'">
                  {{ items.order }}
                </p>
                <p
                  v-if="items.status == 1"
                  class="tagActive tagGeneral"
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                >
                  {{ $t("text_cupones_activo") }}
                </p>
                <p
                  v-else
                  class="tagInactive tagGeneral"
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                >
                  {{ $t("text_cupones_inactivo") }}
                </p>
                <p
                  v-if="items.visible == 1"
                  class="tagActive tagGeneral"
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                >
                  {{ $t("text_inventario_si") }}
                </p>
                <p
                  v-else
                  class="tagInactive tagGeneral"
                  :class="darkMode ? 'darktxts' : 'lighttxts'"
                >
                  {{ $t("text_inventario_no") }}
                </p>
                <div></div>
                <div v-if="!items.edit" class="tags_item_actions">
                  <img
                    loading="lazy"
                    :key="items.id"
                    class="mr-1"
                    src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/1057097_cemejt.png"
                    alt="iconEdit"
                    width="23"
                    @click="editTag(1, items, index)"
                  />
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/3935726_mfqhi5.png"
                    alt="iconDelete"
                    width="23"
                    @click="removeTag(items.id, items.name)"
                  />
                </div>
                <div v-else class="tags_item_actions">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1643158137/x-icon-png-27_wc7njs.png"
                    alt="iconCancelar"
                    width="23"
                    @click="cancelEdit('tag', index)"
                  />
                </div>
              </div>
              <ul
                v-for="(itemSub, index2) in items.properties"
                :key="`subTag_${itemSub.id}`"
                class="subcategories_list"
                :class="{
                  subcategories_list: true,
                  selected: selectedTags === items.id,
                }"
              >
                <li class="subcategories_item">
                  <div class="subcategories_item_text">
                    <p :class="darkMode ? 'darktxts' : 'lighttxts'">
                      {{ itemSub.name }}
                    </p>
                  </div>
                  <p class="py-2" :class="darkMode ? 'darktxts' : 'lighttxts'">
                    {{ itemSub.order }}
                  </p>
                  <div
                    v-if="itemSub.status == 1"
                    class="py-2"
                    :class="darkMode ? 'darktxts' : 'lighttxts'"
                  >
                    <p class="tagActive tagGeneral">
                      {{ $t("text_cupones_activo") }}
                    </p>
                  </div>
                  <div v-else class="py-2">
                    <p
                      class="tagInactive tagGeneral"
                      :class="darkMode ? 'darktxts' : 'lighttxts'"
                    >
                      {{ $t("text_cupones_inactivo") }}
                    </p>
                  </div>
                  <div></div>
                  <div class="wrapper-link" @click="copyLink(itemSub)">
                    <p
                      :class="darkMode ? 'darktxts' : 'lighttxts'"
                      class="link-copy"
                    >
                      <i class="ti-link"></i>{{ $t("text_inventario_copiar") }}
                    </p>
                  </div>
                  <div v-if="!itemSub.edit" class="tags_item_actions py-2">
                    <img
                      loading="lazy"
                      :key="itemSub.id"
                      class="mr-1"
                      src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/1057097_cemejt.png"
                      alt="iconEdit"
                      width="23"
                      @click="editTag(2, itemSub, index, index2)"
                    />
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649375425/3935726_mfqhi5.png"
                      alt="iconDelete"
                      width="23"
                      @click="removeSubTag(itemSub.id, itemSub.name)"
                    />
                  </div>
                  <div v-else class="tags_item_actions">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1643158137/x-icon-png-27_wc7njs.png"
                      alt="iconCancelar"
                      width="23"
                      @click="cancelEdit('subTag')"
                    />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else class="content-list-items-empty">
          <img
            loading="lazy"
            class="mb-4"
            src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1654800390/347674_qzjkdc.png"
            alt="img etiqueta"
            width="80"
          />
          <p :class="darkMode ? 'darktxts' : 'lighttxts'">
            {{ $t("text_etiquetas_notEtiqueta") }}
          </p>
        </div>
      </div>
      <!-- Derecha -->
      <div class="tag_actions h-full">
        <div
          class="wrapper-card w-full flex flex-col h-full"
          :class="darkMode ? 'darkbgs2' : 'lightbgs'"
        >
          <div class="w-full text-center mb-3">
            <p
              class="text-base font-bold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              {{
                filterTags.length > 0
                  ? tag.editTag == 0
                    ? "Agregar Etiqueta"
                    : "Editar Etiqueta: "
                  : "Crear Etiqueta"
              }}
              <span> {{ tag.editTag == 0 ? "" : `(${tag.name})` }}</span>
            </p>
          </div>
          <div class="flex flex-col justify-center mb-3">
            <div class="w-full mb-2">
              <p class="text-info">Nombre del grupo de las etiquetas</p>
              <el-input
                v-model="tag.name"
                class="inputs-idTienda"
                :class="darkMode ? 'darks-input' : 'light-input'"
                placeholder="Ingresar nombre"
              ></el-input>
            </div>
            <div class="wrapper-items-input">
              <div class="w-full mb-2">
                <p class="text-info mb-2">Estado de la etiqueta</p>
                <div class="wrapper-switch">
                  <span class="switcher switcher-1">
                    <input
                      id="switcher-tag"
                      ref="switcherTag"
                      type="checkbox"
                      @change="changeState"
                    />
                    <label for="switcher-tag"></label>
                  </span>
                </div>
              </div>
              <div class="w-full mb-2">
                <p class="text-info">Visibilidad de la etiqueta</p>
                <div class="radio-ckecked" style="margin-top: 8px">
                  <el-radio v-model="tag.visible" :label="0" border>
                    {{ $t("text_inventario_no") }}
                  </el-radio>
                  <el-radio v-model="tag.visible" :label="1" border>
                    {{ $t("text_inventario_si") }}
                  </el-radio>
                </div>
              </div>
            </div>
            <div class="block-slider mb-3">
              <p class="text-info mt-2">Prioridad de aparición</p>
              <el-slider
                v-model="tag.order"
                :marks="marks"
                :step="1"
                :max="max"
                :show-stops="stops"
              ></el-slider>
              <p class="text-info mt-5">
                Nota: La etiqueta con alta prioridad alta (10), saldrá en las
                primeras posiciones.
              </p>
            </div>
            <el-button
              type="primary"
              class="btn"
              @click="tag.editTag == 0 ? setTag() : updateTag()"
            >
              {{ tag.editTag == 0 ? "Guardar" : "Editar" }}
            </el-button>
          </div>
        </div>
        <div
          v-if="filterTags.length > 0"
          class="wrapper-card w-full flex flex-col"
          :class="darkMode ? 'darkbgs2' : 'lightbgs'"
        >
          <div class="w-full text-center mb-3">
            <p
              class="text-base font-bold"
              :class="darkMode ? 'darktxts' : 'lighttxts'"
            >
              {{
                subTag.editSubTag == 0
                  ? "Crear propiedades"
                  : "Editar propiedades: "
              }}
              <span>
                {{ subTag.editSubTag == 0 ? "" : `(${subTag.name})` }}</span
              >
            </p>
          </div>
          <div class="flex flex-col justify-center mb-3">
            <div class="mb-2">
              <p class="text-info">Seleccionar el grupo de etiquetas</p>
              <el-select
                v-model="subTag.idTag"
                filterable
                clearable
                placeholder="Seleccionar etiqueta"
                class="inputs-idTienda w-full"
                :class="darkMode ? 'darks-input' : 'light-input'"
              >
                <el-option
                  v-for="tags in tagsData"
                  :key="tags.id"
                  :label="tags.name"
                  :value="tags.id"
                />
              </el-select>
            </div>
            <div class="wrapper-items-input">
              <div v-if="subTag.idTag" class="w-full mb-2">
                <p class="text-info">Nombre de la propiedad</p>
                <el-input
                  v-model="subTag.name"
                  class="inputs-idTienda"
                  :class="darkMode ? 'darks-input' : 'light-input'"
                  placeholder="Ingresar nombre"
                ></el-input>
              </div>
              <div v-show="subTag.idTag" class="w-full mb-2">
                <p class="text-info">Estado de la propiedad</p>
                <div class="wrapper-switch">
                  <span class="switcher switcher-1">
                    <input
                      id="switcher-tag2"
                      ref="switcherTag2"
                      type="checkbox"
                      @change="changeState2"
                    />
                    <label for="switcher-tag2"></label>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="subTag.idTag" class="block-slider mb-3">
              <p class="text-info mt-2">Prioridad de aparición</p>
              <el-slider
                v-model="subTag.order"
                :marks="marks"
                :step="1"
                :max="max"
                :show-stops="stops"
              ></el-slider>
              <p class="text-info mt-5">
                Nota: La propiedad con alta prioridad alta (10), saldrá en las
                primeras posiciones.
              </p>
            </div>
            <el-button
              v-if="subTag.idTag"
              type="primary"
              class="btn"
              @click="!subTag.editSubTag ? setSubTag() : updateSubTag()"
            >
              {{ !subTag.editSubTag ? "Guardar" : "Editar" }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "InventoryTags",
  props: {
    stateEtiquetas: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      tag: {
        id: "",
        name: "",
        status: 0,
        order: 0,
        index: "",
        editTag: 0,
        visible: 1,
      },
      subTag: {
        id: "",
        idTag: "",
        name: "",
        status: 0,
        order: 0,
        index: "",
        editSubTag: 0,
      },
      selectedTags: "",
      selectedSubTags: "",
      stateBtnTags: true,
      stateBtnSubTags: true,
      max: 10,
      stops: true,
      marks: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
      },
    };
  },
  computed: {
    ...mapState(["darkMode", "storeData", "tagsData"]),
    filterTags() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tagsData.sort(function (prev, next) {
        return next.order - prev.order;
      });
    },
  },
  created() {
    this.$store.dispatch("GET_ALL_TAGS");
  },
  methods: {
    handleSidebarHelp(contentId) {
      this.$store.state.miscSidebar.isActive = true;
      this.$store.state.miscSidebar.isHelpdesk = false;
      this.$store.state.miscSidebar.data = contentId;
    },
    filterSubTag(value) {
      if (value) {
        return value.sort(function (prev, next) {
          return next.order - prev.order;
        });
      }
      return [];
    },
    changeState() {
      let checkBox = this.$refs.switcherTag.checked;
      if (checkBox) {
        this.$set(this.tag, "status", 1);
      } else {
        this.$set(this.tag, "status", 0);
      }
    },
    changeState2() {
      let checkBox2 = this.$refs.switcherTag2.checked;
      if (checkBox2) {
        this.$set(this.subTag, "status", 1);
      } else {
        this.$set(this.subTag, "status", 0);
      }
    },
    async setTag() {
      const { success, data } = await this.$store.dispatch("CREATED_TAGS", {
        name: this.tag.name,
        status: this.tag.status,
        order: this.tag.order,
        visible: this.tag.visible,
      });
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha guardado correctamente!",
          "success"
        );
        this.cancelEdit("tag");
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    async updateTag() {
      const { success, data } = await this.$store.dispatch("UPDATE_TAGS", {
        id: this.tag.id,
        name: this.tag.name,
        status: this.tag.status,
        order: this.tag.order,
        visible: this.tag.visible,
      });
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha actualizado correctamente!",
          "success"
        );
        this.cancelEdit("tag");
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    async removeTag(id, name) {
      this.$swal({
        title: "Eliminar categoría",
        text: `¿Estas seguro de eliminar la etiqueta: ${name}?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        icon: "question",
      }).then(async (r) => {
        if (r.isConfirmed) {
          const { success } = await this.$store.dispatch("DELETE_TAGS", {
            id: id,
          });
          if (success) {
            this.$swal(
              "Felicitaciones!",
              "Se ha eliminado correctamente!",
              "success"
            );
            this.cancelEdit("tag");
          } else {
            this.$swal("Error!", "vuelva a internar", "error");
          }
        }
      });
    },
    editTag(type, data, indexTags, indexProperties) {
      if (type == 1) {
        this.selectedTags = data.id;
        this.tag.id = data.id;
        this.tag.name = data.name;
        this.tag.status = data.status;
        this.$refs.switcherTag.checked = !!data.status;
        this.tag.order = data.order;
        this.tag.visible = data.visible;
        this.tag.index = indexTags;
        this.tag.editTag = true;
        this.filterTags[this.tag.index].edit = true;
      } else if (type == 2) {
        this.selectedSubTags = data.id;
        this.subTag.id = data.id;
        this.subTag.idTag = data.tag_id;
        this.subTag.name = data.name;
        this.subTag.status = data.status;
        this.$refs.switcherTag2.checked = !!data.status;
        this.subTag.order = data.order;
        this.tag.index = indexTags;
        this.subTag.index = indexProperties;
        this.subTag.editSubTag = true;
        this.filterTags[this.tag.index].properties[
          this.subTag.index
        ].edit = true;
      }
    },
    cancelEdit(type) {
      this.selectedTags = "";
      if (type == "tag") {
        if (this.tag && (this.tag.index || this.tag.index === 0)) {
          this.filterTags[this.tag.index].edit = false;
        }
        this.tag.id = "";
        this.tag.name = "";
        this.tag.status = 0;
        this.tag.order = 0;
        this.tag.visible = 1;
        this.tag.index = "";
        this.tag.editTag = false;
        this.$refs.switcherTag.checked = 0;
      } else if (type == "subTag") {
        if (this.subTag && (this.subTag.index || this.subTag.index === 0)) {
          this.filterTags[this.tag.index].properties[
            this.subTag.index
          ].edit = false;
        }
        this.subTag.id = "";
        this.subTag.idTag = "";
        this.subTag.name = "";
        this.subTag.status = 0;
        this.subTag.order = 0;
        this.$refs.switcherTag2.checked = 0;
        this.tag.index = 0;
        this.subTag.index = "";
        this.subTag.editSubTag = false;
      }
    },
    async setSubTag() {
      const { success, data } = await this.$store.dispatch("CREATED_SUB_TAGS", {
        idTag: this.subTag.idTag,
        name: this.subTag.name,
        status: this.subTag.status,
        order: this.subTag.order,
      });
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha guardado correctamente!",
          "success"
        );
        this.cancelEdit("subTag");
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    async updateSubTag() {
      const { success, data } = await this.$store.dispatch("UPDATE_SUB_TAGS", {
        id: this.subTag.id,
        name: this.subTag.name,
        status: this.subTag.status,
        order: this.subTag.order,
      });
      if (success) {
        this.$swal(
          "Felicitaciones!",
          "Se ha actualizado correctamente!",
          "success"
        );
        this.cancelEdit("subTag");
      } else {
        if (data && data.data && data.data.errors) {
          this.$swal("Error!", data.data.errors.name[0], "error");
        }
      }
    },
    async removeSubTag(id, name) {
      this.$swal({
        title: "Eliminar categoría",
        text: `¿Estas seguro de eliminar la sub-etiqueta: ${name}?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        icon: "question",
      }).then(async (r) => {
        if (r.isConfirmed) {
          const { success } = await this.$store.dispatch("DELETE_SUB_TAGS", {
            id: id,
          });
          if (success) {
            this.$swal(
              "Felicitaciones!",
              "Se ha eliminado correctamente!",
              "success"
            );
            this.cancelEdit("subTag");
          } else {
            this.$swal("Error!", "vuelva a internar", "error");
          }
        }
      });
    },
    closeEtiquetas() {
      this.$store.state.modalEtiquetas = false;
    },
    copyLink(value) {
      const suffix = this.storeData.template !== 3 ? "store" : "co";
      if (this.storeData.template == 99) {
        if (this.storeData.informacion_tienda[0].dominio) {
          const link = `${this.storeData.informacion_tienda[0].dominio}/wa/${this.storeData.id}?page=1&tag=${value.id}`;
          this.getCopyLink(link);
        } else {
          const link = `https://${this.storeData.subdominio}.komercia.${suffix}/wa/${this.storeData.id}?page=1&tag=${value.id}`;
          this.getCopyLink(link);
        }
      } else {
        if (this.storeData.informacion_tienda[0].dominio) {
          const link = `${this.storeData.informacion_tienda[0].dominio}/productos?page=1&tag=${value.id}`;
          this.getCopyLink(link);
        } else {
          const link = `https://${this.storeData.subdominio}.komercia.${suffix}/productos?page=1&tag=${value.id}`;
          this.getCopyLink(link);
        }
      }
    },
    getCopyLink(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$message.success("Se ha copiado la Url");
    },
  },
};
</script>

<style scoped>
.btnCategories {
  background: transparent;
  border: 2px solid #f1f1f1;
  border-radius: 12px;
  padding: 8px 12px;
  margin-right: 10px;
}
.btnCategories:hover {
  border: 2px solid var(--purple);
}
.wrapper-content-items {
  width: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.darkbgs {
  background-color: #1b2836;
}
.darkbgs2 {
  background-color: #283c51;
}
.lightbgs {
  background-color: #fff;
}
.darktxts {
  color: #fff;
}
.lighttxts {
  color: rgb(26, 46, 59);
}
.content-header-items {
  padding: 20px 15px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  @apply w-full flex flex-row justify-between items-center;
}
.content-list-items {
  padding: 20px 15px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  @apply w-full;
}
.content-list-items-empty {
  height: 100%;
  padding: 20px 15px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  @apply flex flex-col justify-center items-center;
}
.inputs-idTienda >>> .el-input__inner {
  font-size: 13px;
  border-radius: 12px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.buttonActions {
  color: white;
  border: var(--purple);
  background: var(--purple);
  font-weight: 600;
  max-height: 40px;
  border-radius: 12px;
}
.wrapper-card {
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.wrapper-items-input {
  @apply w-full grid grid-cols-2 gap-3;
}
.wrapper-box {
  /* padding: 20px; */
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-column-gap: 20px;
}
.tag_actions {
  @apply grid sticky top-5 items-start gap-5;
}
/* tabla */
.tags_header {
  padding: 0 10px 5px;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr 1fr;
  text-align: start;
  border-bottom: 1px solid #ebebeb;
}
.tags_item {
  padding-bottom: 0;
}
.tags_item:first-child {
  border-style: none;
}
.tags_item_row {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr 1fr;
  padding: 10px;
  text-align: start;
}
.tags_item_row.selected {
  background-color: #f1f1f1;
}
.tag_item_row_text {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  position: relative;
  padding-left: 21px;
  justify-self: start;
  font-weight: 600;
}
.tag_item_row_text::before {
  position: absolute;
  top: 3px;
  left: 0px;
  width: 12px;
  height: 12px;
  background-color: var(--green);
  border-radius: 50%;
  content: "";
}
.subcategories_item {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr 1fr;
  /* border-bottom: 1px solid #ebebeb; */
}
.subcategories_item::before {
  position: absolute;
  top: -20px;
  left: 5px;
  content: "";
  width: 30px;
  height: 41px;
  border-left: 1px solid var(--purple);
  border-bottom: 1px solid var(--purple);
}
.subcategories_list {
  padding: 0 10px !important;
}
.subcategories_list.selected {
  background-color: #f1f1f1;
}
.subcategories_item_text {
  position: relative;
  padding: 8px 0;
  padding-left: calc(21px + 42px);
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-self: start;
}
.subcategories_item_text::before {
  position: absolute;
  top: 13px;
  left: 42px;
  width: 12px;
  height: 12px;
  background-color: var(--green);
  border-radius: 50%;
  content: "";
}
.tags_item_actions {
  justify-self: self-start;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
}
.tagGeneral {
  border-radius: 12px;
  margin: 2px 4px;
  width: 90px;
  text-align: center;
}
.tagActive {
  background-color: #d5f6e0;
  color: #2f8c4e;
}
.tagInactive {
  background-color: #f7858f;
  color: #ac2f39;
}
.radio-ckecked >>> .el-radio.is-bordered.is-checked {
  border-color: #4429b4;
}
.radio-ckecked >>> .el-radio__input.is-checked + .el-radio__label {
  color: #4429b4;
}
.radio-ckecked >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #4429b4;
  background: #4429b4;
}
@media (max-width: 1280px) {
  .wrapper-box {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
  .tags {
    grid-row: 2;
  }
  .tag_actions {
    position: relative;
    height: initial;
    display: grid;
    grid-row: 1;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1080px) {
  .tag_actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
  .wrapper-content-items {
    padding: 20px 10px;
    overflow-y: auto;
    max-height: 650px;
  }
  .wrapper-content-items::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .wrapper-content-items::-webkit-scrollbar:vertical {
    width: 11px;
  }
  .wrapper-content-items::-webkit-scrollbar-button:increment,
  .wrapper-content-items::-webkit-scrollbar-button {
    display: none;
  }
  .wrapper-content-items::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  .wrapper-content-items::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  .wrapper-box {
    padding: 0px;
  }
  .wrapper-btn {
    margin-bottom: 10px;
  }
  .content-list-items[data-v-fffa4eee] {
    padding: 20px 15px;
  }
  /* .wrapper-items-input {
    @apply w-full grid grid-rows-1 gap-3;
  } */
}
</style>
<style lang="scss" scoped>
$grey: #cccccc;
$white: #ffffff;
.wrapper-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
span.switcher {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 12px;
  input {
    appearance: none;
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 12px;
    border: 1px solid #dcdfe6;
    background-color: var(--purple);
    outline: none;
    font-family: "Oswald", sans-serif;
    &:before,
    &:after {
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
    }
    &:before {
      content: "ACTIVO";
      left: 13px;
    }
    &:after {
      content: "INACTIVO";
      right: 11px;
    }
  }
  label {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 12px;
  }
  &.switcher-1 {
    input {
      cursor: pointer;
      transition: 0.25s -0.1s;
      &:checked {
        background-color: $white;
        &:before {
          color: #2f8c4e;
          transition: color 0.5s 0.2s;
        }
        &:after {
          color: $grey;
          transition: color 0.5s;
        }
        & + label {
          left: 0;
          right: 83px;
          background: var(--green);
          transition: left 0.5s, right 0.4s 0.2s;
        }
      }
      &:not(:checked) {
        background: #f2f6f9;
        transition: background 0.5s -0.1s;
        &:before {
          color: $grey;
          transition: color 0.5s;
        }
        &:after {
          color: #fff;
          transition: color 0.5s 0.2s;
        }
        & + label {
          left: 70px;
          right: 0;
          background: var(--darktwo);
          transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
        }
      }
    }
  }
}
</style>
