<template>
  <div class="branch__update">
    <div class="box">
      <div class="box-body">
        <GmapMap
          :center="locationData"
          :zoom="13"
          style="width: 100%; height: 500px"
        >
          <GmapMarker
            :position="locationData"
            :clickable="true"
            :draggable="true"
            @dragend="changePositionMarker"
          ></GmapMarker>
        </GmapMap>
      </div>
      <branch-form
        v-model="locationData"
        @saveData="updateBranch"
      ></branch-form>
    </div>
  </div>
</template>
<script>
import BranchForm from "../../../components/BranchForm.vue";
export default {
  name: "EditarSedesEmpresa",
  components: { BranchForm },
  created() {
    if (this.branchData.length) {
      this.setLocation();
    }
  },
  data() {
    return {
      locationData: {
        lat: 0,
        lng: 0,
        address: "",
        city: 0,
        branch_name: "",
        schedule: "",
      },
    };
  },
  computed: {
    branchData() {
      return this.$store.state.branchesData;
    },
  },
  watch: {
    branchData() {
      this.setLocation();
    },
  },
  methods: {
    setLocation() {
      const data = this.branchData.find(
        (branch) => branch.id === parseInt(this.$route.params.id)
      );
      this.locationData.lat = data.latitud;
      this.locationData.lng = data.longitud;
      this.locationData.address = data.direccion;
      this.locationData.city = data.ciudad;
      this.locationData.branch_name = data.nombre_sede;
      this.locationData.schedule = data.horario;
    },
    updateBranch() {
      this.$store.dispatch("UPDATE_BRANCH", {
        id: this.$route.params.id,
        direccion: this.locationData.address,
        latitud: this.locationData.lat,
        longitud: this.locationData.lng,
        ciudad: this.locationData.city,
        nombre_sede: this.locationData.branch_name,
        horario: this.locationData.schedule,
      });
      this.$router.push("/empresa/sedes");
    },
    changePositionMarker(value) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: value.latLng }, (results) => {
        this.locationData.lat = results[0].geometry.location.lat();
        this.locationData.lng = results[0].geometry.location.lng();
        this.locationData.address = results[0].formatted_address;
      });
    },
  },
};
</script>

<style scoped>
.branch__update .box {
  display: grid;
  grid-template-columns: 1fr 300px;
}
.box-body {
  padding: 5px;
}
</style>
