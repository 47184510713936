export default {
  getLimitProducts: (state) => {
    return state.alertLimitProducts.show;
  },
  settingProducts(state) {
    return state.storeData.tipo === 0
      ? {
          amount: 50,
        }
      : state.storeData.tipo === 1
      ? {
          amount: 150,
        }
      : state.storeData.tipo === 2
      ? {
          amount: 500,
        }
      : state.storeData.tipo === 3
      ? {
          amount: 99999999,
        }
      : state.storeData.tipo === 9
      ? {
          amount: 99999999,
        }
      : state.storeData.tipo === 20
      ? {
          amount: 99999999,
        }
      : state.storeData.tipo === 21
      ? {
          amount: 99999999,
        }
      : {
          amount: 99999999,
        };
  },
  getActiveProducts(state, getters) {
    if (state.productsData && state.productsData.length) {
      return state.productsData
        .filter((product) => product.activo === 1)
        .slice(0, getters.settingProducts.amount);
    } else {
      return [];
    }
  },
  getInactiveProducts(state) {
    if (state.productsData && state.productsData.length) {
      return state.productsData.filter((product) => product.activo !== 1);
      // .slice(0, getters.settingProducts.amount)
    } else {
      return [];
    }
  },
  numberOfCombinations(state, getters) {
    return getters.getActiveProducts.filter(
      (producto) => producto.con_variante !== 0
    );
  },
  getSoldOutProducts(state, getters) {
    if (getters.numberOfCombinations.length) {
      let filtered = getters.numberOfCombinations.filter((producto) => {
        if (producto.variantes[0]) {
          if (
            producto.variantes[0].combinaciones[0].combinaciones !== "[]" &&
            producto.variantes[0].combinaciones[0].combinaciones !== "Array"
          ) {
            let variantes = JSON.parse(
              producto.variantes[0].combinaciones[0].combinaciones
            );
            for (const variante of variantes) {
              if (variante.unidades == "0" && variante.estado) {
                return producto;
              }
            }
            return "";
          }
          return "";
        }
      });
      if (getters.getActiveProducts) {
        let filtered2 = getters.getActiveProducts.filter(
          (product) => product.inventario === 0 && product.con_variante === 0
        );
        return filtered2.concat(filtered);
      }
      return [];
    } else {
      if (getters.getActiveProducts) {
        let filtered2 = getters.getActiveProducts.filter(
          (product) => product.inventario === 0 && product.con_variante === 0
        );
        return filtered2;
      }
    }
    return [];
  },
  getValidateVariants(state) {
    state.variants.forEach((variant) => {
      variant.nombre = variant.nombre.trimStart().trimEnd();
      variant.valores.forEach((valor) => {
        valor.option = valor.option.trimStart().trimEnd();
      });
    });
    return state.variants;
  },
};
