<template>
  <div class="shipping-services box">
    <div class="wrapper" v-if="packagingOptions && packagingOptions.length > 0">
      <el-row :gutter="20">
        <el-col :span="18" :offset="3">
          <p class="title">Selecciona un servicio para tu envío</p>
        </el-col>
      </el-row>
      <br />
      <div v-if="this.$route.query.pre_envio == 'true'" class="mx-10 mb-5">
        <p class="text-green-komercia-600">
          <strong>Nota:</strong>
          El cliente selecciono esa transportadora por preferencia
        </p>
      </div>
      <div class="couriers w-full" v-if="packagingOptions">
        <li
          v-for="item in packagingOptions"
          :gutter="20"
          @click="selectCarrier(item)"
          :key="item.deliveryCompanyId"
          :class="{
            couriers__item: true,
            selected:
              item.deliveryCompanyId == currentCourier.deliveryCompanyId,
          }"
        >
          <div class="col wrapper-image">
            <img
              loading="lazy"
              :src="
                item.deliveryCompanyImgUrl
                  ? item.deliveryCompanyImgUrl
                  : require('@/assets/mipaquete/placeholder.png')
              "
            />
          </div>
          <el-col>
            <p class="bold">{{ item.deliveryCompanyName }}</p>
            <p>{{ item.officeAddress }}</p>
          </el-col>
          <el-col>
            <p>Calificación</p>
            <el-rate v-model="item.score" disabled text-color="#ff9900">
            </el-rate>
          </el-col>
          <el-col>
            <p>Precio Envío</p>
            <p class="bold">{{ item.shippingCost | currency }} COP</p>
          </el-col>
          <el-col class="couriers__item__status">
            <i class="el-icon-circle-check"></i>
          </el-col>
        </li>
      </div>
      <el-row :gutter="30">
        <el-col
          :span="24"
          v-if="
            quotationPayload.paymentType == 102 &&
            quotationPayload.addShippingCost
          "
        >
          <div class="summary summary__general justify-content-center">
            <div style="max-width: 300px">
              <h1>Calculadora de envío</h1>
              <p class="text-info">
                Conoce el valor real del envío que vas a realizar. Como
                especificaste anteriormente, sumaremos el valor del envío al
                valor que vamos a recaudar en tu pago contraentrega
              </p>
            </div>
            <div class="mx-12">
              <p>
                <strong class="mr-2">Valor de la venta: </strong>
                {{ quotationPayload.saleValue | currency }}
              </p>
              <p>
                <strong class="mr-2">Valor del envío: </strong>
                {{ currentCourier.shippingCost | currency }}
              </p>
              <p>
                <strong class="mr-2">Costo total del envío:</strong>
                {{ collectionValue | currency }}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="my-6 quotation_result__actions">
        <el-button @click.native="goBack">Volver</el-button>
        <el-button
          :disabled="!currentCourier.deliveryCompanyId"
          @click.native="createQuotation"
          class="bg-purple-komercia-50 px-4 py-1.5 ml-3 rounded-md text-gray-100"
        >
          Siguiente
        </el-button>
      </div>
    </div>
    <div class="wrapper" v-else>
      <div class="w-full flex flex-col justify-center items-center my-10 px-5">
        <img
          loading="lazy"
          src="@/assets/mipaquete/logo-mi-paquete-color.png"
          class="h-20 mb-5"
        />
        <p class="text-center font-medium text-xl">
          No se han encontrado transportadoras para las opciones de tu envío.
          Inténtalo nuevamente.
        </p>
        <div class="my-6 quotation_result__actions">
          <el-button @click.native="goBack">Volver</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    if (this.$route.query.pre_envio == "true") {
      const { success } = await this.$store.dispatch(
        "GET_INVOICE",
        this.$route.query.order_id
      );
      if (success) {
        this.assignOrderData();
      }
    }
  },
  computed: {
    currentCourier: {
      get() {
        return this.$store.state.mipaquete.currentCourier;
      },
      set(newValue) {
        return (this.$store.state.mipaquete.currentCourier = newValue);
      },
    },
    packagingOptions() {
      return this.$store.state.mipaquete.packagingOptions;
    },
    quotationPayload() {
      return this.$store.state.mipaquete.quotationPayload;
    },
    collectionValue() {
      return (
        parseInt(this.quotationPayload.saleValue) +
        parseInt(this.currentCourier.shippingCost)
      );
    },
    order() {
      if (this.$store.state.invoice && this.$store.state.invoice.venta) {
        return this.$store.state.invoice;
      } else {
        return "";
      }
    },
  },
  methods: {
    async assignOrderData() {
      if (this.$route.query.order_id) {
        if (this.order) {
          this.packagingOptions.find((item) => {
            if (
              item.deliveryCompanyId ==
              JSON.parse(
                this.order.venta.mi_paquete_pre_envio[0].delivery_company
              )
            ) {
              this.currentCourier = item;
            }
          });
        } else {
          console.log("no tiene transportadora");
        }
      }
    },
    goBack() {
      this.$store.commit("mipaquete/SET_PACKAGING_OPTIONS", null);
      this.$store.commit("mipaquete/SET_CURRENTCOURIER", null);
      this.$store.commit("mipaquete/PREVIOUS_STAGE");
    },
    selectCarrier(item) {
      this.$store.commit("mipaquete/SET_CURRENTCOURIER", item);
    },
    createQuotation() {
      let options = this.quotationPayload;
      options.valueCollection = this.collectionValue;
      this.$store.commit("mipaquete/SET_QUOTATIONPAYLOAD", options);
      this.$store.commit("mipaquete/NEXT_STAGE");
    },
    showObservations(message) {
      this.$alert(message, "Observaciones", {
        customClass: "mipaquete",
        confirmButtonText: "Entendido",
        lockScroll: false,
      });
    },
  },
  filters: {
    currency(value) {
      if (value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.summary {
  display: flex;
  background-color: #f2efff;
  padding: 15px;
  border-radius: 3px;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.shipping-services {
  box-sizing: border-box;
  padding: 40px 0;
}
.title {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}
.col h4 {
  font-size: 12px;
}
.el-row {
  border-bottom: 1px solid #eee;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.el-row:hover {
  background-color: rgba(15, 147, 127, 0.05);
  cursor: pointer;
}
.wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}
.wrapper > .el-row:first-child {
  border: 0;
}
.wrapper > .el-row:first-child:hover {
  background-color: initial;
  cursor: initial;
}
.wrapper-row {
  max-width: 1000px;
  margin: 0 auto;
}
.wrapper-row p {
  font-size: 0.9em;
  line-height: 20px;
}
.bold {
  font-weight: bold;
}
.wrapper-image {
  /* padding: 15px 40px 15px 0; */
  padding: 10px 5px;
}
.wrapper-image img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}
.empty {
  border: 1px solid rgb(243, 243, 243);
  border-radius: 4px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  height: 400px;
}
.shipping_services__actions {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
}
.observations p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.quotation_result__actions {
  display: flex;
  justify-content: flex-end;
}
.couriers {
  display: grid;
  grid-gap: 10px;
}
.couriers__item {
  display: grid;
  grid-template-columns: 3fr 4fr 4fr 4fr 3fr 4fr 2fr;
  grid-gap: 10px;
  border: 1px solid rgb(243, 243, 243);
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;
}
.couriers__item__status {
  padding-right: 1vw;
  align-self: center;
  justify-self: end;
}
.couriers__item__status i {
  font-size: 24px;
  color: rgb(235, 235, 235);
  transition: 0.3s;
}
.couriers__item.selected .couriers__item__status i {
  color: var(--green);
}
.couriers__item:hover {
  background-color: rgba(15, 147, 127, 0.05);
  border-color: white;
}
.couriers__item:hover .couriers__item__status i {
  color: #fff;
}
.couriers__item.selected {
  border: 2px solid var(--green);
}
</style>
