<template>
  <div class="wrapper-content-help mb-4" :class="darkMode ? 'dark' : 'light'">
    <duplicateProduct
      v-if="productData"
      :visible.sync="visibleDuplicateProduct"
      :payload="productData"
    />
    <div class="helpbutton">
      <div class="product__options">
        <el-button type="primary" plain size="small" @click="duplicateProduct">
          <i class="fas fa-clone icono" />
          {{ $t("text_inventario_duplicar") }}
        </el-button>
        <div v-if="productData && productData.detalle">
          <a
            title="Ayuda Komercia"
            class="link__redirect"
            :href="getUrlProduct()"
            target="_blank"
          >
            <el-button type="primary" plain size="small">
              <i class="fas fa-external-link-alt icono" />
              {{ $t("text_inventario_ver") }}
            </el-button>
          </a>
        </div>
      </div>
      <div class="help">
        <el-button size="small" @click="handleSidebarHelp('27')">
          {{ $t("text_inventario_aprendeMas") }} {{ catstay }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import duplicateProduct from "@/components/DuplicateProductDialog.vue";
export default {
  components: {
    duplicateProduct,
  },
  props: ["catstay", "routeback"],
  data() {
    return {
      visibleDuplicateProduct: false,
    };
  },
  computed: {
    subdomain() {
      return this.$store.state.storeData.subdominio;
    },
    productData() {
      return this.$store.state.productData;
    },
    storeData() {
      return this.$store.state.storeData;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
  methods: {
    handleSidebarHelp(contentId) {
      this.$store.state.miscSidebar.isActive = true;
      this.$store.state.miscSidebar.isHelpdesk = false;
      this.$store.state.miscSidebar.data = contentId;
    },
    handleHelp(cat) {
      if (cat.art) {
        this.$router.push({
          path: "",
          query: { help: true, category: cat.cat, art: cat.art },
        });
      } else {
        this.$router.push({ path: "", query: { help: true, category: cat } });
      }
    },
    backtobfore(back) {
      this.$router.push({ path: back });
    },
    duplicateProduct() {
      this.visibleDuplicateProduct = true;
    },
    getUrlProduct() {
      const suffix = this.storeData.template !== 3 ? "store" : "co";
      if (
        this.storeData.entidades &&
        this.storeData.entidades.length > 0 &&
        this.storeData.entidades[0].id == 21
      ) {
        if (this.storeData.template == 99) {
          return `https://${this.subdomain}.keepbuy.co/wa/${this.storeData.id}/productos/${this.data.slug}`;
        } else {
          return `https://${this.subdomain}.keepbuy.co/productos/${this.productData.detalle.slug}`;
        }
      } else {
        if (this.storeData.template == 99) {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}/wa/${this.storeData.id}/productos/${this.productData.detalle.slug}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}/wa/${this.storeData.id}/productos/${this.productData.detalle.slug}`;
          }
        } else {
          if (this.storeData.informacion_tienda[0].dominio) {
            return `${this.storeData.informacion_tienda[0].dominio}/productos/${this.productData.detalle.slug}`;
          } else {
            return `https://${this.subdomain}.komercia.${suffix}/productos/${this.productData.detalle.slug}`;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.wrapper-content-help {
  width: 100%;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.dark {
  background-color: #293d53;
}
.light {
  background-color: #fff;
}
.helpbutton {
  display: grid;
  grid-auto-flow: column;
  padding: 10px 0;
  justify-content: space-between;
}
.helpbutton .back {
  cursor: pointer;
}
.buttonhelp {
  background-color: red;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin: 0 auto;
}
.product__options {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 20px;
  align-items: center;
}
.product__options__item {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: 5px;
  padding: 5px;
  border-radius: 3px;
  color: #7a7a7a;
  cursor: pointer;
}
.product__options__item p,
.link__redirect p {
  margin: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.788);
  line-height: 2;
  font-size: 12px;
}
.product__options__item:hover {
  color: rgb(26, 26, 26);
  background-color: rgb(218, 218, 218);
}
.linkHelp {
  display: flex;
}
@media (max-width: 767px) {
  .product__options {
    gap: 10px;
  }
  .helpbutton {
    gap: 10px;
  }
}
@media (max-width: 400px) {
  .helpbutton {
    grid-auto-flow: row;
  }
  .product__options {
    width: 100%;
    display: flex;
  }
  .product__options div {
    width: 100%;
  }
  .product__options div .el-button {
    width: 100%;
  }
}
</style>
