<template>
  <transition name="el-zoom-in-top">
    <section class="wrapper_invoice">
      <invoice
        v-if="orden"
        :stateDataOrder="stateDataOrder"
        :data="orden"
        :is-quotation="isQuotation"
      />
      <div
        v-if="stateErrorOrder"
        class="flex flex-col justify-center items-center"
        style="height: calc(100vh - 350px)"
      >
        <img
          loading="lazy"
          src="../../assets/ilustracion_warnign.svg"
          alt="LogoError"
          width="350"
          height="350"
        />
        <p class="text-xl font-bold text-gray-600 max-w-md text-center mt-5">
          Esta orden no existe o es un error en nuestro sistema, por favor
          contacta con soporte
        </p>
      </div>
    </section>
  </transition>
</template>

<script>
import Invoice from "../../components/Invoice.vue";
export default {
  components: { Invoice },
  data() {
    return {
      stateDataOrder: false,
      stateErrorOrder: false,
    };
  },
  computed: {
    showFactura() {
      return this.$store.state.facturaShow;
    },
    orden() {
      return this.$store.state.invoice;
    },
    isQuotation() {
      return this.orden.venta.tipo;
    },
  },
  created() {
    this.fetchInvoice(this.$route.params.id);
  },
  // watch: {
  //   "$route.params": {
  //     handler(value) {
  //       this.fetchInvoice(value.id);
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    async fetchInvoice(id) {
      this.stateDataOrder = false;
      this.stateErrorOrder = false;
      const { success } = await this.$store.dispatch("GET_INVOICE", id);
      if (success) {
        this.stateDataOrder = true;
      } else {
        this.stateErrorOrder = true;
      }
      // next();
    },
  },
};
</script>

<style scoped>
.wrapper_invoice {
  padding: 0 15px;
}
@media (max-width: 767px) {
  .content-wrapper {
    background-color: #ffffff;
  }
}
</style>
