export default {
  computed: {
    idStore() {
      return this.$store.state.storeData.id;
    },
  },
  methods: {
    // Huge images. eg: Banners
    cloudinaryWidgetOptions(
      folder = "products",
      cloud_name = "komercia-store",
      upload_preset = "q7ifgtpp",
      cropping_aspect_ratio = null
    ) {
      return {
        language: "es",
        text: {
          es: {
            or: "O",
            facebook: {
              main_title: "Facebook",
              no_photos: "No hay fotos...",
              no_auth_title: "Subir imágenes de mi cuenta de Facebook",
              no_auth_action: "Conectar con Facebook",
              no_auth_statement: "No publicaremos nada sin tu permiso",
              album_subtitle: "{{count}} fotos",
              menu: {
                uploaded: "Fotos",
                tagged: "Tus Fotos",
                albums: "Álbumes",
              },
            },
            menu: {
              files: "Mi equipo",
              web: "URL web",
              camera: "Camera",
              gsearch: "Google",
              gdrive: "Google Photos",
              dropbox: "Dropbox",
              facebook: "Facebook",
              instagram: "Instagram",
            },
            notifications: {
              general_error: "Un error ha ocurrido",
              general_prompt: "Estas seguro?",
              limit_reached: "No more files can be selected",
              invalid_add_url: "Ingresa una URL válida",
              invalid_public_id: "Public ID cannot contain \\,?,&,#,%,<,>",
              no_new_files: "Las imágenes ha sido cargadas",
            },
            image_search: {
              main_title: "Buscar imágenes en Google",
              inputPlaceholder: "Escribe que imagen quieres buscar",
              customPlaceholder: "Buscar {{site}}",
              filters_title: "site",
              rights: "Usage rights",
              rights_options: {
                not_filtered: "not filtered by license",
                free: "free to use or share",
                free_com: "free to use or share, even commercially",
                free_mod: "free to use share or modify",
                free_mod_com: "free to use, share or modify, even commercially",
              },
              search_error: "Search failed, please try again.",
            },
            instagram: {
              main_title: "Instagram",
              no_auth_title: "Utilizar imágenes de tu cuenta de Instagram",
              no_auth_action: "Conectar con Instagram",
              header_title: "Tus fotos recientes de Instagram",
              authenticating: "Autenticando...",
            },
            url: {
              main_title: "URL remota",
              inner_title: "URL pública del archivo a cargar:",
              input_placeholder:
                "http://remote.site.example/images/remote-image.jpg",
            },
            local: {
              main_title: "Subir imágenes de mi equipo",
              browse: "Buscar en PC",
              dd_title_single: "Arrastra y suelta la imagen aquí",
            },
            queue: {
              title: "Cargar Imagen",
              title_uploading_with_counter: "Uploading {{num}} Assets",
              title_uploading: "Uploading Assets",
              mini_title: "Uploaded",
              mini_title_uploading: "Uploading",
              show_completed: "Show completed",
              retry_failed: "Reintentar falló",
              abort_all: "Abort all",
              upload_more: "Upload more",
              done: "Aceptar",
              mini_upload_count: "{{num}} uploaded",
              mini_failed: "{{num}} failed",
              statuses: {
                uploading: "Uploading...",
                error: "Error",
                uploaded: "Aceptar",
                aborted: "Aborted",
              },
            },
            crop: {
              title: "Recortar",
              crop_btn: "Recortar",
              skip_btn: "Omitir",
              cancel_btn: "Cancelar",
              reset_btn: "Reiniciar",
              close_btn: "Cerrar",
              close_prompt: "El cierre cancelará la subida, ¿Estás seguro?",
              image_error: "Error al cargar la imagen",
              corner_tooltip: "Arrastre la esquina para cambiar el tamaño",
              handle_tooltip: "Arrastre la manija para cambiar el tamaño",
              showSkipCropButton: cropping_aspect_ratio ? false : true,
            },
            uploader: {
              errors: {
                file_too_large:
                  "Tamaño del archivo ({{size}}) excede el máximo permitido ({{allowed}})",
              },
            },
            selection_counter: {
              selected: "seleccionado",
            },
            actions: {
              upload: "Subir Imagen",
              clear_all: "Limpiar Todo",
              log_out: "Cerrar sesión",
            },
          },
        },
        max_image_file_size: 4000000,
        cropping: true,
        showSkipCropButton: cropping_aspect_ratio ? false : true,
        cropping_aspect_ratio: cropping_aspect_ratio,
        cropping_default_selection_ratio: 0.9,
        cropping_show_dimensions: true,
        croppingCoordinatesMode: "custom",
        cropping_show_back_button: true,
        folder: `${this.idStore}/${folder}`,
        show_powered_by: false,
        keep_widget_open: true,
        cloudName: cloud_name,
        uploadPreset: upload_preset,
        sources: [
          "local",
          "url",
          "image_search",
          "google_drive",
          "facebook",
          "dropbox",
          "instagram",
        ],
        google_api_key: "AIzaSyCHRRTM9jAyT3N4RMZGSGZn5H5QeaWbb3M",
        showAdvancedOptions: false,
        multiple: false,
        defaultSource: "local",
        styles: {
          palette: {
            window: "#1F2937",
            windowBorder: "#90A0B3",
            tabIcon: "#4F46E5",
            menuIcons: "#5A616A",
            textDark: "#000000",
            textLight: "#FFFFFF",
            link: "#4F46E5",
            action: "#4F46E5",
            inactiveTabIcon: "#FFFFFF",
            error: "#F44235",
            inProgress: "#4F46E5",
            complete: "#20B832",
            sourceBg: "#E4EBF1",
          },
          fonts: {
            default: null,
            "'Poppins', sans-serif": {
              url: "https://fonts.googleapis.com/css?family=Poppins",
              active: true,
            },
          },
        },
      };
    },
  },
};
