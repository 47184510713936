import Vue from "vue";
import VueRouter from "vue-router";
import Steps from "@/views/NewSteps.vue";
// import Layout from '@/views/Layout'
import Home from "@/views/Home.vue";
import Chat from "@/views/Chat.vue";
import POS from "@/views/POS.vue";
// import Billing from '@/views/Billing.vue'
// import BillingParameters from '@/views/billing/Parameters'
// import GenerateInvoice from '@/views/billing/GenerateInvoice'
// import Inventory from '@/views/Inventory.vue'
import InventoryProducts from "@/views/Inventory/Products.vue";
// import InventoryInactive from '@/views/Inventory/Inactives.vue'
// import InventoryCategories from '@/views/Inventory/Categories.vue'
import InventoryProductNew from "@/views/Inventory/Products/New.vue";
import InventoryProductUpdate from "@/views/Inventory/Products/NewUpdate.vue";
import InventoryProductProducts from "@/views/Inventory/Products/Products.vue";
// import Store from '@/views/Store.vue'
// import StoreDiseno from '@/views/design/Diseno.vue'
// import StoreSEO from '@/views/Store/SEO.vue'
import Cupones from "@/views/Ecommerce/Cupones.vue";
import Descuentos from "@/views/Ecommerce/Descuentos.vue";
import BlogList from "@/views/Blog/articles/listBlog.vue";
import BlogNew from "@/views/Blog/articles/new.vue";
import BlogEdit from "@/views/Blog/articles/update.vue";
// import General from '@/views/General.vue'
import GeneralInformation from "@/views/General/Information.vue";
import GeneralLegal from "@/views/General/Legal.vue";
import GeneralRedes from "@/views/General/Social.vue";
// import GeneralPayments from '@/views/General/Payments.vue'
import GeneralPayments from "@/views/General/NewPayments.vue";
import GeneralShipping from "@/views/General/Shipping.vue";
import Factura from "@/views/General/Facturacion.vue";
// import GeneralShippingNational from '@/views/General/Shipping/National.vue'
// import GeneralShippingInternational from '@/views/General/Shipping/International.vue'
import GeneralBranch from "@/views/General/Branch.vue";
import GeneralBranchBranches from "@/views/General/Branch/Branches.vue";
import GeneralBranchNew from "@/views/General/Branch/New.vue";
import GeneralBranchUpdate from "@/views/General/Branch/Update.vue";
// import Invoices from '@/views/Invoices.vue'
// import InvoicesInformation from '@/views/Invoices/Information.vue'
// import Sales from '@/views/Sales.vue'
import SalesOrdersTable from "@/views/sales/OrdersTable.vue";
import SalesOrdersInvoice from "@/views/sales/OrdersInvoice.vue";
// import Customers from '@/views/Customers/Customers.vue'
import CustomerTable from "@/views/Customers/components/CustomerTable.vue";
import SubscribersTable from "@/views/Customers/components/SubscribersTable.vue";
// import Settings from '@/views/Settings.vue'
import User from "@/views/Settings/User";
import admin from "@/views/Settings/admin";
import Contrasena from "@/views/Settings/contrasena";
import Referred from "@/views/comunidad/referidosAwards/Referred.vue";
import Membresia from "@/views/Settings/Suscription";
import Logout from "@/views/Logout.vue";
import Support from "@/views/Support.vue";
// import Shipment from '@/views/envioclick.vue'
// import ShipmentAll from '@/views/envioclick/index.vue'
// import ShipmentNew from '@/views/envioclick/New.vue'
// import ShipmentLocal from '@/views/mensajerosurbanos/index.vue'
// import ShipmentLocalAll from '@/views/mensajerosurbanos/all.vue'
// import ShipmentLocalNew from '@/views/mensajerosurbanos/new.vue'
import Integrations from "@/integrations/root";
import Cubiko from "@/views/integraciones/cubiko/Cubiko.vue";
import CubikoLogin from "@/views/integraciones/cubiko/Cubikologin.vue";
const Multichannel = () => import("@/views/Multichannel.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/Layout"),
    children: [
      {
        path: "/",
        component: Home,
      },
      {
        path: "mensajes",
        component: Chat,
      },
      {
        path: "step",
        component: Steps,
      },
      {
        path: "ventas",
        component: () => import("@/views/Ventas.vue"),
        children: [
          {
            path: "pos",
            component: POS,
          },
          {
            path: "listado",
            // component: Sales,
            component: () => import("@/views/Sales.vue"),
            children: [
              {
                path: "/",
                name: "SalesOrdersTable",
                component: SalesOrdersTable,
              },
              {
                path: ":id",
                name: "SalesOrdersInvoice",
                component: SalesOrdersInvoice,
              },
            ],
          },
          {
            path: "listado-hoko",
            // component: Sales,
            component: () => import("@/views/Sales.vue"),
            children: [
              {
                path: "/",
                name: "SalesOrdersTableHoko",
                component: () =>
                  import("@/views/sales/hoko/OrdersTableHoko.vue"),
              },
            ],
          },
          {
            path: "infoVenta",
            component: () => import("@/components/printSale.vue"),
          },
          {
            path: "clientes",
            component: CustomerTable,
          },
          // {
          //   path: "statistics",
          //   component: () => import("@/views/statistics/index.vue"),
          //   children: [
          //     {
          //       path: "ventas",
          //       name: "salesStatistics",
          //       component: () => import("@/views/statistics/ventas.vue"),
          //     },
          //     {
          //       path: "productos",
          //       name: "productStatistics",
          //       component: () => import("@/views/statistics/productos.vue"),
          //     },
          //   ],
          // },
        ],
      },
      {
        path: "/select-inventario",
        name: "selectInventory",
        component: () => import("@/views/Inventory/selectInventory.vue"),
      },
      {
        path: "inventario",
        component: () => import("@/views/Inventory.vue"),
        children: [
          {
            path: "productos",
            component: InventoryProducts,
            children: [
              {
                path: "/",
                name: "InventoryProducts",
                component: InventoryProductProducts,
              },
              {
                path: "nuevo",
                name: "InventoryProductNew",
                component: InventoryProductNew,
              },
              {
                path: ":id",
                name: "InventoryProductUpdate",
                component: InventoryProductUpdate,
              },
            ],
          },
          {
            path: "productosDropshipping",
            name: "ListDropshipping",
            component: () => import("@/views/Inventory/dropshipping.vue"),
          },
          {
            path: "inactivos",
            name: "InventoryInactive",
            component: () => import("@/views/Inventory/Inactives.vue"),
          },
          {
            path: "categorias",
            name: "InventoryCategories",
            component: () => import("@/views/Inventory/Categories.vue"),
          },
          {
            path: "tags",
            name: "InventoryTags",
            component: () => import("@/views/Inventory/Tags.vue"),
          },
        ],
      },
      {
        path: "hoko",
        component: () => import("@/views/Inventory/hoko/index.vue"),
        children: [
          {
            path: "login-hoko",
            name: "loginHoko",
            component: () => import("@/views/Inventory/hoko/login.vue"),
          },
          {
            path: "mega-bodega-hoko",
            component: () => import("@/views/Inventory/hoko/listProduct.vue"),
            children: [
              {
                path: "/",
                name: "InventoryProductsHoko",
                component: () =>
                  import("@/views/Inventory/hoko/_items/megaBodega.vue"),
              },
              {
                path: "inventory-hoko",
                name: "MyInventoryProductHoko",
                component: () =>
                  import("@/views/Inventory/hoko/_items/bogeda.vue"),
              },
              {
                path: ":id",
                name: "productHoko",
                component: () =>
                  import("@/views/Inventory/hoko/_items/products.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/empresa",
        component: () => import("@/views/General.vue"),
        children: [
          {
            path: "/",
            component: GeneralInformation,
          },
          {
            path: "informacion",
            component: GeneralInformation,
          },
          {
            path: "legal",
            component: GeneralLegal,
          },
          {
            path: "redes",
            component: GeneralRedes,
          },
          {
            path: "pagos",
            component: GeneralPayments,
          },
          {
            path: "envios",
            component: GeneralShipping,
          },
          {
            path: "factura",
            component: Factura,
          },
          {
            path: "statistics",
            component: () => import("@/views/General/statistics/index.vue"),
          },
          {
            path: "sedes",
            component: GeneralBranch,
            children: [
              {
                path: "/",
                component: GeneralBranchBranches,
              },
              {
                path: "nueva",
                component: GeneralBranchNew,
              },
              {
                path: "editar/:id",
                component: GeneralBranchUpdate,
              },
            ],
          },
        ],
      },
      {
        path: "whatsapp",
        component: () => import("@/views/Whatsapp.vue"),
        children: [
          {
            path: "editor",
            component: () => import("@/views/design/EditorWhatsapp.vue"),
          },
          {
            path: "redes",
            component: GeneralRedes,
          },
          {
            path: "checkout",
            component: () => import("@/views/whatsApp/index.vue"),
          },
        ],
      },
      {
        path: "academia",
        component: () => import("@/views/Academia/index.vue"),
        children: [
          {
            path: "listadoCursos",
            name: "listadoCursos",
            component: () => import("@/views/Academia/listCourse.vue"),
          },
          {
            path: "curso",
            component: () => import("@/views/Academia/index.vue"),
            children: [
              {
                path: ":id",
                component: () => import("@/views/Academia/contentCourse.vue"),
              },
            ],
          },
          {
            path: "video",
            component: () => import("@/views/Academia/index.vue"),
            children: [
              {
                path: ":id",
                component: () => import("@/views/Academia/previewCourse.vue"),
              },
            ],
          },
          {
            path: "videoYou",
            component: () => import("@/views/Academia/index.vue"),
            children: [
              {
                path: ":id",
                component: () =>
                  import("@/views/Academia/previewCourseYoutube.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "analytics",
        component: () => import("@/views/analytics/index.vue"),
        children: [
          {
            path: "statistics",
            name: "analyticsStore",
            component: () => import("@/views/analytics/analyticsTable.vue"),
          },
          {
            path: "ventas",
            name: "salesStatistics",
            component: () => import("@/views/analytics/ventas.vue"),
          },
          {
            path: "productos",
            name: "productStatistics",
            component: () => import("@/views/analytics/productos.vue"),
          },
          {
            path: "cliente",
            name: "clientStatistics",
            component: () => import("@/views/analytics/clientes.vue"),
          },
        ],
      },
      {
        path: "/tienda",
        component: () => import("@/views/Store.vue"),
        children: [
          {
            path: "cupones",
            name: "Cupones",
            component: Cupones,
          },
          {
            path: "cubiko",
            name: "Cubiko",
            component: Cubiko,
          },
          {
            path: "getcubikologin",
            name: "CubikoLogin",
            component: CubikoLogin,
          },
          {
            path: "descuentos",
            name: "Descuentos",
            component: Descuentos,
          },
          {
            path: "blog",
            component: () => import("@/views/Blog/blog.vue"),
            children: [
              {
                path: "/",
                name: "BlogArticle",
                component: BlogList,
              },
              {
                path: "newArticulo",
                name: "BlogArticuloNew",
                component: BlogNew,
              },
              {
                path: "editArticulo",
                component: () => import("@/views/Blog/blog.vue"),
                children: [
                  {
                    path: ":id",
                    name: "EditArticlo",
                    component: BlogEdit,
                  },
                ],
              },
            ],
          },
          // {
          //   path: 'diseno',
          //   name: 'Disenos',
          //   component: () => import('@/views/design/index.vue'),
          //   children: [
          //     {
          //       path: '/',
          //       name: 'Diseno',
          //       component: () => import('@/views/design/Diseno.vue'),
          //     },
          //     {
          //       path: 'editor',
          //       name: 'Editor',
          //       component: () => import('@/views/design/Editor.vue'),
          //     },
          //   ],
          // },
          {
            path: "mensajes",
            component: Chat,
          },
          {
            path: "suscriptores",
            name: "SubscribersTable",
            component: SubscribersTable,
          },
          // {
          //   path: 'seo',
          //   name: 'StoreSEO',
          //   component: StoreSEO,
          // },
          {
            path: "editor/modal",
            name: "EditorModal",
            component: () => import("@/views/design/EditorModal.vue"),
          },
          {
            path: "security",
            name: "usersecurity",
            component: () => import("@/views/Store/userSecurity"),
          },
        ],
      },
      {
        path: "diseno",
        component: () => import("@/views/diseno.vue"),
        children: [
          {
            path: "/",
            name: "design",
            component: () => import("@/views/design/newDesigns.vue"),
          },
          {
            path: "/mis-sitios",
            name: "multipleDesigns",
            component: () => import("@/views/design/multipleDesigns.vue"),
          },
        ],
      },
      {
        path: "editor/perfecta",
        name: "EditorTemplate5",
        component: () => import("@/views/design/EditorTemplate5.vue"),
      },
      {
        path: "editor/madrid",
        name: "EditorTemplate9",
        component: () => import("@/views/design/EditorTemplate9.vue"),
      },
      {
        path: "editor/santiago",
        name: "EditorTemplate12",
        component: () => import("@/views/design/EditorTemplate12.vue"),
      },
      {
        path: "editor/template",
        name: "EditorTemplate",
        component: () => import("@/views/design/EditorTemplate.vue"),
      },
      {
        path: "editor/helpDesk",
        name: "EditorHelpDesk",
        component: () => import("@/views/helpDesk/editor.vue"),
      },
      {
        path: "editor/LinkKomercia",
        name: "editorLinkKomercia",
        component: () => import("@/views/design/EditorLink.vue"),
      },
      {
        path: "helpDesk",
        component: () => import("@/views/helpDesk/index.vue"),
        children: [
          {
            path: "information",
            name: "informationArticle",
            component: () => import("@/views/helpDesk/information.vue"),
          },
          {
            path: "Articles",
            component: () => import("@/views/helpDesk/listArticle.vue"),
            children: [
              {
                path: "/",
                name: "ListArticles",
                component: () =>
                  import("@/views/helpDesk/article/listArticle.vue"),
              },
              {
                path: "nuevo",
                name: "newArticle",
                component: () => import("@/views/helpDesk/article/new.vue"),
              },
              {
                path: ":id",
                name: "updateArticle",
                component: () => import("@/views/helpDesk/article/update.vue"),
              },
            ],
          },
          {
            path: "socials",
            name: "socialsArticle",
            component: () => import("@/views/helpDesk/Social.vue"),
          },
          {
            path: "categories",
            name: "categoriesArticle",
            component: () => import("@/views/helpDesk/categorias.vue"),
          },
          {
            path: "authors",
            name: "authorsArticles",
            component: () => import("@/views/helpDesk/authors.vue"),
          },
        ],
      },
      // {
      //   path: 'vacantes',
      //   component: () => import('@/views/Vacantes/index.vue'),
      //   children: [
      //     {
      //       path: 'remoteJobs',
      //       name: 'remoteJobs',
      //       component: () => import('@/views/Vacantes/remoteJobs.vue'),
      //     },
      //     {
      //       path: 'postJob',
      //       name: 'postJob',
      //       component: () => import('@/views/Vacantes/postJob.vue'),
      //     },
      //     {
      //       path: 'editar/:id',
      //       name: 'editJob',
      //       component: () => import('@/views/Vacantes/editpostJob.vue'),
      //     },
      //   ],
      // },
      {
        path: "comunidad",
        component: () => import("@/views/comunidad.vue"),
        children: [
          {
            path: "partners",
            name: "partners",
            component: () => import("@/views/comunidad/Partnerts/partnert.vue"),
          },
          {
            path: "newpartner",
            name: "newpartner",
            component: () =>
              import("@/views/comunidad/Partnerts/newPartner.vue"),
          },
          {
            path: "editpartner/:id",
            name: "editpartner",
            component: () =>
              import("@/views/comunidad/Partnerts/editPartner.vue"),
          },
          {
            path: "partner/:id",
            name: "partnerDesc",
            component: () =>
              import("@/views/comunidad/Partnerts/partnerDesc.vue"),
          },
          {
            path: "remoteJobs",
            name: "remoteJobs",
            component: () =>
              import("@/views/comunidad/Vacantes/remoteJobs.vue"),
          },
          {
            path: "postJob",
            name: "postJob",
            component: () => import("@/views/comunidad/Vacantes/postJob.vue"),
          },
          {
            path: "editar/:id",
            name: "editJob",
            component: () =>
              import("@/views/comunidad/Vacantes/editpostJob.vue"),
          },
          {
            path: "perks",
            name: "perks",
            component: () => import("@/views/comunidad/perks/listPerks.vue"),
          },
          {
            path: "cupones",
            name: "listadoCupones",
            component: () =>
              import("@/views/comunidad/cupones/listCupones.vue"),
          },
        ],
      },
      {
        path: "/awards",
        component: () =>
          import("@/views/comunidad/referidosAwards/listAwards.vue"),
      },
      // {
      //   path: 'rocketfy',
      //   component: () => import('@/views/Rocketfy/index.vue'),
      //   children: [
      //     {
      //       path: 'login',
      //       name: 'loginRocketfy',
      //       component: () => import('@/views/Rocketfy/login.vue'),
      //     },
      //     {
      //       path: 'registro',
      //       name: 'registroRocketfy',
      //       component: () => import('@/views/Rocketfy/register.vue'),
      //     },
      //     {
      //       path: 'envios',
      //       name: 'enviosRocketfy',
      //       component: () => import('@/views/Rocketfy/envios.vue'),
      //     },
      //     // {
      //     //   path: 'video',
      //     //   component: () => import('@/views/Academia/index.vue'),
      //     //   children: [
      //     //     {
      //     //       path: ':id',
      //     //       component: () => import('@/views/Academia/previewCourse.vue'),
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      // {
      //   path: 'ingresos',
      //   // component: Sales,
      //   component: () => import('@/views/Sales.vue'),
      //   children: [
      //     {
      //       path: '/',
      //       name: 'SalesOrdersTable',
      //       component: SalesOrdersTable,
      //     },
      //     {
      //       path: ':id',
      //       name: 'SalesOrdersInvoice',
      //       component: SalesOrdersInvoice,
      //     },
      //   ],
      // },
      // {
      //   path: 'entrega',
      //   // component: Shipment,
      //   component: () => import('@/views/envioclick.vue'),
      //   children: [
      //     {
      //       path: '/',
      //       name: 'ShipmentAll',
      //       component: ShipmentAll,
      //     },
      //     {
      //       path: 'nuevo',
      //       name: 'ShipmentNew',
      //       component: ShipmentNew,
      //     },
      //   ],
      // },
      // {
      //   path: 'mensajeros-urbanos',
      //   component: ShipmentLocal,
      //   children: [
      //     {
      //       path: '/',
      //       name: 'ShipmentLocalAll',
      //       component: ShipmentLocalAll,
      //     },
      //     {
      //       path: 'nuevo',
      //       name: 'ShipmentLocalNew',
      //       component: ShipmentLocalNew,
      //     },
      //   ],
      // },
      {
        path: "integraciones/:integration",
        component: Integrations,
      },
      // {
      //   path: 'configuracion',
      //   name: 'configuracion',
      //   component: Settings,
      //   children: [
      //     {
      //       path: '/',
      //       name: 'User',
      //       component: User,
      //     },
      //     {
      //       path: 'referidos',
      //       name: 'Referred',
      //       component: Referred,
      //     },
      //     {
      //       path: 'membresia',
      //       name: 'Membresia',
      //       component: Membresia,
      //     },
      //     {
      //       path: 'facturacion',
      //       name: 'Facturación',
      //       component: Factura,
      //     },
      //   ],
      // },
      // {
      //   path: 'diseno',
      //   name: 'Diseno',
      //   component: () => import('@/views/Store.vue'),
      //   children: [
      //     {
      //       path: '/',
      //       name: 'Diseno',
      //       component: () => import('@/views/design/Diseno.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: 'membresia',
      //   name: 'Membresia',
      //   component: Membresia,
      // },
      {
        path: "integraciones",
        component: () => import("@/views/integraciones/Integraciones.vue"),
      },
      {
        path: "configuracion",
        // component: Settings,
        component: () => import("@/views/Settings.vue"),
        children: [
          {
            path: "usuario",
            name: "User",
            component: User,
          },
          {
            path: "adminuser",
            name: "adminUser",
            component: admin,
          },
          {
            path: "contrasena",
            name: "Contrasena",
            component: Contrasena,
          },
          {
            path: "referidos",
            name: "Referred",
            component: Referred,
          },
          {
            path: "membresia",
            name: "Membresia",
            component: Membresia,
          },
          {
            path: "facturacion",
            name: "Facturación",
            component: Factura,
          },
        ],
      },
      {
        path: "suscription",
        component: () => import("@/views/suscription/index.vue"),
        children: [
          {
            path: "success",
            name: "successpay",
            component: () => import("@/views/suscription/success.vue"),
          },
          {
            path: "cancel",
            name: "cancelpay",
            component: () => import("@/views/suscription/cancel.vue"),
          },
        ],
      },
      {
        path: "multicanal",
        name: "Multichannel",
        component: Multichannel,
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout,
      },
      {
        path: "soporte",
        name: "Support",
        component: Support,
      },
      {
        path: "ayuda",
        name: "Help",
        component: Support,
      },
      { path: "*", component: Home },
      // {
      //   path: '/404',
      //   name: '404',
      //   component: NotFound
      // },
      // {
      //   path: '/500',
      //   name: '500',
      //   component: NotFoundSecond
      // }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.URL_KOMERCIA,
  routes,
});

export default router;
