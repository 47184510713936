<template>
  <div
    class="wrapper-content-items content"
    :class="darkMode ? 'dark' : 'light'"
  >
    <div class="wrapper-box" :class="darkMode ? 'darks' : 'lights'">
      <div class="box-body" :class="darkMode ? 'darks' : 'lights'">
        <h4 class="title-payment" :class="darkMode ? 'darktxt' : 'lighttxt'">
          {{ $t("text_perfil_configuracionFacturacion") }}
        </h4>
        <ValidationObserver ref="validationObserver">
          <div class="grid">
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_tipo") }}
              </p>
              <ValidationProvider name="Tipo" rules="required">
                <template #default="{ errors }">
                  <el-select
                    v-model="info.party_type"
                    filterable
                    clearable
                    :placeholder="$t('text_perfil_tipo')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  >
                    <el-option
                      v-for="item in tipos"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_numeroDocumento") }}
              </p>
              <ValidationProvider name="Número de documento" rules="required">
                <template #default="{ errors }">
                  <el-input
                    v-model="info.party_identification"
                    :placeholder="$t('text_perfil_numeroDocumento')"
                    class="inputs-idTienda"
                    :class="errors[0] ? 'validate-error' : null"
                  >
                    <el-select
                      slot="prepend"
                      v-model="info.party_identification_type"
                      placeholder="Select"
                      style="width: 80px"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="(type, index) in typesDocuments"
                        :key="index"
                        :label="type"
                        :value="type"
                      ></el-option>
                    </el-select>
                  </el-input>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_regimen") }}
              </p>
              <ValidationProvider name="Regimen" rules="required">
                <template #default="{ errors }">
                  <el-select
                    v-model="info.regimen"
                    filterable
                    clearable
                    :placeholder="$t('text_perfil_regimen')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  >
                    <el-option
                      v-for="item in regimenes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_codigoImpuestos") }}
              </p>
              <ValidationProvider name="Código" rules="required">
                <template #default="{ errors }">
                  <el-select
                    v-model="info.tax_level_code"
                    filterable
                    clearable
                    :placeholder="$t('text_perfil_codigoImpuestos')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  >
                    <el-option
                      v-for="item in levelCode"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div
              v-if="info.party_type === 'PERSONA_NATURAL'"
              class="input-area"
            >
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_primerNombre") }}
              </p>
              <ValidationProvider
                name="Primer nombre"
                :rules="
                  info.party_type === 'PERSONA_NATURAL' ? 'required' : null
                "
              >
                <template #default="{ errors }">
                  <el-input
                    v-model="info.first_name"
                    class="inputs-idTienda"
                    :placeholder="$t('text_perfil_primerNombre')"
                    :class="errors[0] ? 'validate-error' : null"
                  >
                  </el-input>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div
              v-if="info.party_type === 'PERSONA_NATURAL'"
              class="input-area"
            >
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_apellido") }}
              </p>
              <ValidationProvider
                name="Apellido"
                :rules="
                  info.party_type === 'PERSONA_NATURAL' ? 'required' : null
                "
              >
                <template #default="{ errors }">
                  <el-input
                    v-model="info.family_name"
                    :placeholder="$t('text_perfil_apellido')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  >
                  </el-input>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div
              v-if="info.party_type === 'PERSONA_JURIDICA'"
              class="input-area"
            >
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_empresa") }}
              </p>
              <ValidationProvider
                name="Empresa"
                :rules="
                  info.party_type === 'PERSONA_JURIDICA' ? 'required' : null
                "
              >
                <template #default="{ errors }">
                  <el-input
                    v-model="info.company_name"
                    :placeholder="$t('text_empresa')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  >
                  </el-input>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_departamento") }}
              </p>
              <ValidationProvider name="Departamento" rules="required">
                <template #default="{ errors }">
                  <el-select
                    v-model="department"
                    filterable
                    clearable
                    :placeholder="$t('text_perfil_departamento')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                    @change="getCities"
                  >
                    <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.nombre_dep"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_ciudad") }}
              </p>
              <ValidationProvider name="Ciudad" rules="required">
                <template #default="{ errors }">
                  <el-select
                    v-model="city"
                    filterable
                    clearable
                    :placeholder="$t('text_perfil_ciudad')"
                    :disabled="!cities.length"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                    @change="getCity"
                  >
                    <el-option
                      v-for="item in cities"
                      :key="item.id"
                      :label="item.nombre_ciu"
                      :value="item.nombre_ciu"
                    ></el-option>
                  </el-select>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_telefono") }}
              </p>
              <ValidationProvider name="Teléfono" rules="required|numeric">
                <template #default="{ errors }">
                  <el-input
                    v-model="info.phone"
                    :placeholder="$t('text_perfil_telefono')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  ></el-input>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
            <div class="input-area">
              <p
                class="text-info mb-2"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_perfil_correoElectronico") }}
              </p>
              <ValidationProvider
                name="Correo electrónico"
                rules="required|email"
              >
                <template #default="{ errors }">
                  <el-input
                    v-model="info.email"
                    :placeholder="$t('text_perfil_correoElectronico')"
                    :class="errors[0] ? 'validate-error' : null"
                    class="inputs-idTienda"
                  ></el-input>
                  <p class="text-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div class="content-btn-footer" :class="darkMode ? 'darks' : 'lights'">
        <el-button type="primary" @click.native="saveData">{{
          $t("text_inventario_guardar")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "shippingFacturación",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      info: {
        email: "",
        phone: "",
        party_type: "",
        party_identification: "",
        party_identification_type: "CC",
        tax_level_code: "",
        regimen: "",
        department: "",
        city: "",
        company_name: "",
        first_name: "",
        family_name: "",
      },
      department: "",
      city: "",
      cities: [],
      tipos: [
        {
          value: "PERSONA_JURIDICA",
          label: "Persona juridica",
        },
        {
          value: "PERSONA_NATURAL",
          label: "Persona natural",
        },
      ],
      typesDocuments: [
        "CC",
        "NIT",
        "PASAPORTE",
        "RC",
        "TI",
        "TE",
        "CE",
        "IE",
        "NIT_OTRO_PAIS",
      ],
      regimenes: [
        { value: "SIMPLE", label: "Simple" },
        { value: "ORDINARIO", label: "Ordinario" },
        { value: "GRAN_CONTRIBUYENTE", label: "Gran contribuyente" },
        { value: "AUTORRETENEDOR", label: "Autorretenedor" },
        { value: "AGENTE_RETENCION_IVA", label: "Agente retención iva" },
      ],
      levelCode: [
        { value: "RESPONSABLE_DE_IVA", label: "Responsable de IVA" },
        { value: "NO_RESPONSABLE_DE_IVA", label: "No responsable de IVA" },
      ],
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    ...mapState(["departments", "countries"]),
  },
  created() {
    this.$store.dispatch("GET_DEPARTMENTS");
  },
  methods: {
    getCities(id) {
      this.info.city = "";
      this.city = "";
      const department = this.departments.find((dep) => dep.id === id);
      this.info.department = department.nombre_dep.toUpperCase();
      axios
        .get(`${this.$store.state.configKomercia.url}/api/ciudades/${id}`)
        .then((resp) => {
          this.cities = resp.data.data;
        });
    },
    getCity(nameCity) {
      this.info.city = nameCity.toUpperCase();
    },
    saveData() {
      this.$refs.validationObserver.validate().then((result) => {
        if (result) {
          axios
            .post(
              `${this.$store.state.configKomercia.url}/api/admin/tienda/facturacion`,
              this.info,
              this.$store.state.configAxios
            )
            .then((resp) => {
              // console.log(resp)
              this.$swal(resp.data.mensaje, "", "success");
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  background-color: var(--background);
  width: 100%;
}
.dark {
  background-color: #1b2836;
}
.light {
  background-color: #fff;
}
.darks {
  background-color: #233345;
}
.lights {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.wrapper-content-items {
  width: 100%;
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.wrapper-box {
  position: relative;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
}
.content {
  display: flex;
  width: 100%;
  justify-content: center;
}
.title-payment {
  font-size: 18px;
  font-weight: 600;
}
.grid {
  display: grid;
  gap: 20px 40px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}
.text-info {
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.inputs-idTienda >>> .el-input-group__append,
.inputs-idTienda >>> .el-input-group__prepend {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.content-btn-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.validate-error >>> .el-input__inner {
  border: 1px solid #f00;
}
.text-error {
  font-size: 12px;
  color: #f00;
}
</style>
