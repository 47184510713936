import axios from "axios";

const URL_KOMERCIA_API = process.env.VUE_APP_URL_KOMERCIA;
const URL_MU_SANDBOX = "https://dev.api.mensajerosurbanos.com";
const URL_MU_PROD = "https://cerberus.mensajerosurbanos.com";
//  * `1` - Bogota DC.
//  * `2` - Cali.
//  * `3` - Medellin.
//  * `4` - Barranquilla.
//  * `8` - Cartagena.
//  * `9` - Santa Marta.
//  * `10` - Bucaramanga.
//  * `11` - Ibagué.
//  * `41` - Monterrey.
//  * `50` - Ciudad de México.

export default {
  namespaced: true,
  state: {
    currentStep: 1,
    urlMUServer: "",
    credentialsIsEmpty: true,
    isVisibleCredentials: false,
    credentials: {
      sandbox: 1,
      client_id: "",
      client_secret: "",
      user_id: null,
      company_id: null,
    },
    deliveryCostRequest: null,
    authentication: null,
    deliveryCost: null,
    origins: null,
    currentStore: null,
    services: null,
    cities: [
      {
        id: 1,
        name: "Bogota DC",
      },
      {
        id: 2,
        name: "Cali",
      },
      {
        id: 3,
        name: "Medellin",
      },
      {
        id: 4,
        name: "Barranquilla",
      },
      {
        id: 8,
        name: "Cartagena",
      },
      {
        id: 9,
        name: "Santa Marta",
      },
      {
        id: 10,
        name: "Bucaramanga",
      },
      {
        id: 11,
        name: "Ibagué",
      },
      {
        id: 41,
        name: "Monterrey",
      },
      {
        id: 50,
        name: "Ciudad de México",
      },
    ],
    locations: [
      { city_parent: "1", label: "Bogota", value: "bogota" },
      { city_parent: "1", label: "Chia", value: "chia" },
      { city_parent: "1", label: "Soacha", value: "cun_soacha" },
      { city_parent: "1", label: "Cajica", value: "cun_cajica" },
      { city_parent: "1", label: "La Calera", value: "cun_la_calera" },
      { city_parent: "1", label: "Cota", value: "cota" },
      { city_parent: "1", label: "Funza", value: "funza" },
      { city_parent: "1", label: "Zipaquira", value: "zipaquira" },
      { city_parent: "1", label: "Tenjo", value: "cun_tenjo" },
      { city_parent: "2", label: "Cali", value: "cali" },
      { city_parent: "2", label: "Yumbo", value: "vac_yumbo" },
      { city_parent: "3", label: "Medellin", value: "medellin" },
      { city_parent: "3", label: "Barbosa Antioquia", value: "ant_barbosa" },
      { city_parent: "3", label: "Bello", value: "ant_bello" },
      { city_parent: "3", label: "Caldas Antioquia", value: "ant_caldas" },
      {
        city_parent: "3",
        label: "Copacabana Antioquia",
        value: "ant_copacabana",
      },
      { city_parent: "3", label: "Envigado", value: "ant_envigado" },
      { city_parent: "3", label: "Girardota", value: "ant_girardota" },
      { city_parent: "3", label: "Itagui", value: "ant_itagui" },
      {
        city_parent: "3",
        label: "La Estrella Antioquia",
        value: "ant_la_estrella",
      },
      { city_parent: "3", label: "Sabaneta", value: "ant_sabaneta" },
      { city_parent: "4", label: "Barranquilla", value: "barranquilla" },
      { city_parent: "4", label: "Soledad Atlantico", value: "atl_soledad" },
      { city_parent: "8", label: "Cartagena", value: "cartagena" },
      { city_parent: "9", label: "Santa Marta", value: "sta_marta" },
      {
        city_parent: "9",
        label: "Gaira Santa Marta",
        value: "sta_marta_gaira",
      },
      { city_parent: "10", label: "Bucaramanga", value: "bucaramanga" },
      { city_parent: "10", label: "Floridablanca", value: "san_floridablanca" },
      { city_parent: "10", label: "Girón", value: "san_giron" },
      { city_parent: "10", label: "Piedecuesta", value: "san_piedecuesta" },
      { city_parent: "11", label: "Ibague", value: "ibague" },
      { city_parent: "41", label: "Monterrey", value: "Monterrey" },
      { city_parent: "41", label: "San Pedro Garza", value: "san pedro garza" },
      { city_parent: "41", label: "Santa Catarina", value: "santa catarina" },
      { city_parent: "41", label: "Guadalupe", value: "guadalupe" },
      {
        city_parent: "41",
        label: "San Nicolas De Los Garza",
        value: "san nicolas de los garza",
      },
      {
        city_parent: "41",
        label: "Ciudad General Escobedo",
        value: "ciudad general escobedo",
      },
      { city_parent: "41", label: "Ciudad Apodaca", value: "ciudad apodaca" },
      { city_parent: "0", label: "Aeropuerto", value: "aeropuerto" },
      { city_parent: "50", label: "Ciudad de Mexico", value: "ciudad mexico" },
    ],
    segmentationType: [
      {
        id: "1",
        label: "Vacío (Todos los Mensajeros)",
        source: "/mu/ic-ninguno.svg",
      },
      {
        id: "2",
        label: "Parrilla",
        source: "/mu/ic-parrilla.svg",
      },
      {
        id: "3",
        label: "Baúl o maletero (30x30x30 cms)",
        source: "/mu/ic-baul.svg",
      },
      {
        id: "4",
        label: "Maleta (50x50x50 cms y máximo 50kg)   ",
        source: "/mu/ic-maleta.svg",
      },
      {
        id: "5",
        label: "Envío de domicilios con caja o maleta",
        source: "/mu/ic-sobre.svg",
      },
    ],
    states: {
      1: [
        {
          type: "info",
          label: "Creada",
        },
      ],
      2: [
        {
          type: "info",
          label: "En espera",
        },
      ],
      3: [
        {
          type: "info",
          label: "Asignada",
        },
      ],
      4: [
        {
          type: "info",
          label: "En progreso",
        },
      ],
      5: [
        {
          type: "danger",
          label: "Fallida",
        },
        {
          type: "success",
          label: "Finalizada",
        },
      ],
      6: [
        {
          type: "danger",
          label: "Cancelada",
        },
      ],
    },
  },
  getters: {
    whichCredentials: (state) => {
      return state.credentials.sandbox
        ? {
            sandbox: 1,
            client_id: "2kenbwg1wkasd0dpv_murbanos",
            client_secret: "b92a4243f4dbc6d4926afb60c1dde41355ee7a8f",
            user_id: 146020,
            company_id: 145373,
          }
        : state.credentials;
    },
  },
  mutations: {
    SET_CREDENTIALS(state, value) {
      state.credentials = value;
    },
    SET_ISVISIBLECREDENTIALS(state, value) {
      state.isVisibleCredentials = value;
    },
    SET_CREDENTIALSISEMPTY(state, value) {
      state.credentialsIsEmpty = value;
    },
    SET_URL_MU_SERVER(state, value) {
      state.urlMUServer = value ? URL_MU_SANDBOX : URL_MU_PROD;
    },
    SET_AUTHENTICATION(state, value) {
      state.authentication = value;
    },
    SET_DELIVERY_COST_REQUEST(state, value) {
      state.deliveryCostRequest = value;
    },
    SET_DELIVERY_COST(state, value) {
      state.deliveryCost = value;
    },
    SET_CURRENTSTEP(state, value) {
      state.currentStep = value;
    },
    SET_ORIGINS(state, value) {
      state.origins = value;
    },
    SET_CURRENT_STORE(state, value) {
      if (value) {
        state.currentStore = {
          id: value.id,
          name: value.name,
          address: value.address,
          city: value.city,
          city_specific: value.city_specified,
          phone: value.phone,
          schedule: value.schedule,
          parking: value.parking,
        };
      } else {
        state.currentStore = null;
      }
      // state.currentStore = {
      //   id: value.id_point,
      //   name: value.name,
      //   address: value.origin_address,
      //   city: value.cityId,
      //   city_specific: value.city_specific,
      //   phone: value.phone,
      //   schedule: value.schedule,
      //   parking: value.parking_surcharge,
      // }
    },
    SET_SERVICES(state, value) {
      state.services = value;
    },
    ADD_STORE(state, value) {
      state.origins.unshift(value);
    },
    REMOVE_STORE(state, value) {
      const index = state.origins.findIndex((item) => item.id === value);
      state.origins.splice(index, 1);
    },
  },
  actions: {
    async GET_CREDENTIALS({ commit, rootState }) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/credenciales`,
        headers: rootState.configAxios.headers,
      });
      if (data.data) {
        commit("SET_CREDENTIALSISEMPTY", false);
        commit("SET_CREDENTIALS", data.data);
        commit("SET_URL_MU_SERVER", data.data.sandbox);
        return { success: true, data: data.data };
      } else {
        commit("SET_CREDENTIALSISEMPTY", true);
        commit("SET_ISVISIBLECREDENTIALS", true);
        return { success: false, data: {} };
      }
    },
    async UPDATE_CREDENTIALS({ rootState }, payload) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/credenciales`,
          headers: rootState.configAxios.headers,
          data: {
            sandbox: payload.sandbox ? 1 : 0,
            client_id: payload.client_id,
            client_secret: payload.client_secret,
            user_id: parseInt(payload.user_id),
            company_id: parseInt(payload.company_id),
          },
        });
        return { success: true, data: data.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async AUTHENTICATION_WITH_MU({ state, getters, commit }) {
      try {
        let newData = new FormData();
        newData.append("client_id", getters.whichCredentials.client_id);
        newData.append("client_secret", getters.whichCredentials.client_secret);
        newData.append("grant_type", "client_credentials");

        const { data } = await axios({
          method: "POST",
          url: `${state.urlMUServer}/oauth/token`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: newData,
        });
        commit("SET_AUTHENTICATION", data);
        return { success: true, data: data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async GET_ORIGINS({ commit, rootState }) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/tiendas?page=1&per_page=10`,
        headers: rootState.configAxios.headers,
      });
      commit("SET_ORIGINS", data.data.data);
    },
    async GET_SERVICES({ commit, rootState }) {
      const { data } = await axios({
        method: "GET",
        url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/domicilios`,
        headers: rootState.configAxios.headers,
        params: {
          page: 1,
          per_page: 10,
        },
      });
      commit("SET_SERVICES", data.data.data);
    },
    async CALCULATE_DELIVERY_COST(
      { commit, state, getters },
      {
        roundtrip,
        declared_value,
        cityId,
        origin_city,
        destination_city,
        origin_address,
        destination_address,
        parking_surcharge,
      }
    ) {
      try {
        commit("SET_DELIVERY_COST_REQUEST", {
          roundtrip,
          declared_value,
          cityId,
          origin_city,
          destination_city,
          origin_address,
          destination_address,
          parking_surcharge,
        });
        const { data } = await axios({
          method: "POST",
          url: `${state.urlMUServer}/calculate`,
          headers: {
            access_token: `${state.authentication.access_token}`,
          },
          data: {
            id_user: parseInt(getters.whichCredentials.user_id),
            type_service: 4,
            roundtrip,
            declared_value,
            city: cityId,
            parking_surcharge: parking_surcharge,
            coordinates: [
              {
                address: origin_address,
                city: origin_city,
              },
              {
                address: destination_address,
                city: destination_city,
              },
            ],
          },
        });
        commit("SET_DELIVERY_COST", data);
        return { success: true, data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async CREATE_SERVICE({ dispatch }, payload) {
      const { success, data } = await dispatch("CREATE_SERVICE_MU", payload);
      if (success) {
        return await dispatch("CREATE_SERVICE_KOMERCIA", {
          requestBody: payload,
          responseBody: data,
        });
      } else {
        return { success, data };
      }
    },
    async CREATE_SERVICE_MU({ state, getters }, payload) {
      try {
        const requestBody = {
          id_user: parseInt(getters.whichCredentials.user_id),
          type_service: 4,
          roundtrip: state.deliveryCost.data.roundtrip,
          declared_value: state.deliveryCost.data.declared_value,
          city: state.deliveryCostRequest.cityId,
          start_date: payload.start_date,
          start_time: payload.start_time,
          observation: payload.observation,
          user_payment_type: payload.payment_type,
          type_segmentation: payload.type_segmentation,
          type_task_cargo_id: 2,
          os: "NEW API 2.0",
          coordinates: [
            {
              type: "1",
              order_id: 0,
              address: state.deliveryCost.data.adress_data[1].address,
              token: null,
              city: state.deliveryCost.data.adress_data[1].city,
              description: "",
              client_data: {
                client_name: payload.client_name,
                client_phone: payload.client_phone,
                client_email: payload.client_email,
                products_value: state.deliveryCostRequest.declared_value,
                domicile_value: "0",
                client_document: payload.client_document,
                payment_type: payload.payment_type,
              },
              products: payload.products,
            },
          ],
        };
        const { data } = await axios({
          method: "POST",
          url: `${state.urlMUServer}/Create-services`,
          headers: {
            access_token: `${state.authentication.access_token}`,
          },
          data: requestBody,
        });
        return { success: true, data: data.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async CREATE_SERVICE_KOMERCIA(
      { rootState },
      { requestBody, responseBody }
    ) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/domicilios/create`,
          headers: rootState.configAxios.headers,
          data: {
            total_service: Math.ceil(responseBody.total),
            observation: requestBody.observation,
            status: responseBody.status,
            uuid: responseBody.uuid,
            carrito: requestBody.carrito,
          },
        });
        return { success: true, data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async ADD_STORE_KOMERCIA(
      { dispatch, rootState },
      {
        name,
        cityId,
        origin_address,
        origin_city,
        parking_surcharge,
        phone,
        schedule,
      }
    ) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/tiendas/create`,
          headers: rootState.configAxios.headers,
          data: {
            name,
            address: origin_address,
            city: cityId,
            city_specified: origin_city,
            phone,
            schedule,
            parking: parking_surcharge,
          },
        });
        const dataMU = await dispatch("ADD_STORE_MU", {
          data: data.data,
          cityId,
          origin_address,
          parking_surcharge,
          id_point: data.data.id,
          city_specific: origin_city,
          name,
          phone,
          schedule,
        });
        if (dataMU.success) {
          return { success: true, data: data.data };
        }
        return { success: false, data: dataMU.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async ADD_STORE_MU({ state, commit, getters, dispatch }, payload) {
      try {
        const { data } = await axios({
          method: "POST",
          url: `${state.urlMUServer}/Add-store`,
          headers: {
            access_token: `${state.authentication.access_token}`,
          },
          data: {
            id_user: parseInt(getters.whichCredentials.user_id),
            id_point: JSON.stringify(payload.id_point),
            name: payload.name,
            address: payload.origin_address,
            city: payload.cityId,
            phone: payload.phone,
            schedule: payload.schedule,
            parking: payload.parking_surcharge,
            id_company: parseInt(getters.whichCredentials.company_id),
          },
        });
        commit("ADD_STORE", payload.data);
        commit("SET_CURRENT_STORE", payload);
        return { success: true, data };
      } catch (err) {
        dispatch("DELETE_STORE", payload.id_point);
        return { success: false, data: err.response.data };
      }
    },
    async DELETE_STORE({ commit, rootState }, id) {
      try {
        await axios({
          method: "DELETE",
          url: `${URL_KOMERCIA_API}/api/admin/mensajeros-urbanos/tiendas/${id}`,
          headers: rootState.configAxios.headers,
        });
        commit("REMOVE_STORE", id);
        return { success: true, data: {} };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
  },
};
