import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import getCookie from "./utils/getCookie.js";
import useVueAnalytics from "@/plugins/vue-analytics";
import i18n from "./plugins/i18n";
import device from "vue-device-detector";

// import { firebaseup } from '@/utils/data.js'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'

// import "@/plugins/vue-json-excel.js";
// import "@/plugins/vue-the-mask";

import "@/plugins/vue-awesome-swiper";
import "@/plugins/vue-carousel";
import "@/plugins/vue-fuse";
import "@/plugins/vue-ctk-date-time-picker";
import "@/plugins/image-cloudinary";
import "@/plugins/vue-moment";
import "@/plugins/fontawesome";
import "@/plugins/vue-facebook-signin-button";
import "@/plugins/element-ui";
import "@/plugins/element-tiptap";
import "@/plugins/vue2-google-maps";
import "@/plugins/v-money";
import "@/plugins/vee-validate";
import "@/plugins/vue-uuid.js";
import "@/plugins/vue-sweetalert2.js";
import "@/plugins/vue-qrcode.js";
import "@/plugins/material-icons.js";
import "@/directives/numericOnly.js";
import "./assets/tailwind.css";

Vue.use(device);
Vue.config.productionTip = false;
useVueAnalytics(router);
const authData = getCookie("authData");
if (authData.length < 1000) {
  document.cookie = `authData=; domain = komercia.co; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  window.location.href = `https://login.komercia.co`;
}
router.beforeEach((to, from, next) => {
  if (authData !== "") {
    next();
  } else {
    window.location.href = `https://login.komercia.co/auth?from=panel&path=${to.path}`;
  }
});
router.afterEach((to) => {
  switch (to.path) {
    case "/pos":
      store.commit("UPDATE_COLLAPSE_LAYOUT", true);
      break;
    default:
      store.commit("UPDATE_COLLAPSE_LAYOUT", false);
      break;
  }
});
if (authData) {
  let configKomercia = {
    usuario: {
      nombre: "",
      email: "",
      foto: "",
      rol: 0,
      id: 0,
    },
    accessToken: authData,
    url: process.env.VUE_APP_URL_KOMERCIA,
  };
  store.commit("SET_TOKEN", configKomercia);
  Vue.prototype.$configKomercia = configKomercia;
  store.dispatch("GET_USER");
}
// Vue.config.devtools = true
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

const isMobile = Vue.prototype.$device.mobile;
if (process.env.NODE_ENV === "production") {
  if (isMobile) {
    window.location.href = "https://mobile.komercia.co/";
  }
}
