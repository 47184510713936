var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full rounded-xl px-[40px] xl:px-[60px] py-[15px]",class:_vm.darkMode ? 'bg-[#1b2836]' : 'bg-white',staticStyle:{"box-shadow":"0 0 10px 0 rgba(0, 0, 0, 0.09)"}},[_c('div',{staticClass:"w-full flex flex-col justify-center items-center py-6"},[_c('div',{staticClass:"w-full max-w-[650px] grid grid-cols-3 gap-x-3 justify-center items-center"},[_c('button',{staticClass:"w-auto flex flex-col justify-center items-center text-base rounded-l-xl py-3 transition-all ease-in-out delay-75",class:_vm.selectOpt == 1
            ? 'text-[#00d57e] bg-[#4429b4]'
            : ' text-[#919191] bg-[#f3f4f6]',on:{"click":function($event){return _vm.selectOptMethod(1)}}},[_vm._v(" "+_vm._s(_vm.$t("text_opc_pagos"))+" ")]),_c('button',{staticClass:"w-auto flex flex-col justify-center items-center text-base py-3 transition-all ease-in-out delay-75",class:_vm.selectOpt == 2
            ? 'text-[#00d57e] bg-[#4429b4]'
            : ' text-[#919191] bg-[#f3f4f6]',on:{"click":function($event){return _vm.selectOptMethod(2)}}},[_vm._v(" "+_vm._s(_vm.$t("text_pago_metodosPago"))+" ")]),_c('button',{staticClass:"w-auto flex flex-col justify-center items-center text-base rounded-r-xl py-3 transition-all ease-in-out delay-75",class:_vm.selectOpt == 3
            ? 'text-[#00d57e] bg-[#4429b4]'
            : ' text-[#919191] bg-[#f3f4f6]',on:{"click":function($event){return _vm.selectOptMethod(3)}}},[_vm._v(" "+_vm._s(_vm.$t("text_pago_politicasPago"))+" ")])]),_c('div',{staticClass:"w-full text-center text-sm my-10",class:_vm.darkMode ? 'text-white' : 'text-[#6b6b6b]'},[_vm._v(" "+_vm._s(_vm.selectOpt == 1 ? _vm.$t("text_pago_subTitle") : _vm.selectOpt == 2 ? _vm.$t("text_meth_pago_subTitle") : "Ingresa las políticas de pago para tu tienda online")+" ")]),_c('div',{staticClass:"w-full flex flex-col justify-start items-center transition-all ease-in-out delay-75"},[(_vm.selectOpt == 1)?_c('PaymentGateways'):_vm._e(),(_vm.selectOpt == 2)?_c('PaymentMethods'):_vm._e(),(_vm.selectOpt == 3)?_c('div',{staticClass:"w-full flex flex-col justify-start items-center"},[_c('div',{staticClass:"w-full flex flex-col sticky justify-center self-start editor"},[_c('vue-editor',{staticClass:"bg-white rounded-xl",attrs:{"id":"pago"},model:{value:(_vm.policiesData.pagos),callback:function ($$v) {_vm.$set(_vm.policiesData, "pagos", $$v)},expression:"policiesData.pagos"}}),_c('div',{staticClass:"w-full flex justify-end mt-8"},[_c('button',{staticClass:"bg-[#4429b4] text-white rounded-xl px-10 py-3 hover:bg-[#00d57e] hover:text-[#4429b4] transition-all ease-in-out delay-75",on:{"click":_vm.updatePolicies}},[_vm._v(" "+_vm._s(_vm.$t("text_inventario_guardar"))+" ")])])],1)]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }