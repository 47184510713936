<template>
  <div class="branch__new">
    <div class="box">
      <div class="box-body map">
        <GmapMap
          :center="locationData"
          :zoom="12"
          style="width: 100%; height: 500px"
        >
          <GmapMarker
            :position="locationData"
            :clickable="true"
            :draggable="true"
            @dragend="changePositionMarker"
          ></GmapMarker>
        </GmapMap>
      </div>
      <branch-form
        v-model="locationData"
        @saveData="createBranch"
      ></branch-form>
    </div>
  </div>
</template>
<script>
import BranchForm from "../../../components/BranchForm.vue";
export default {
  name: "branchNew",
  components: { BranchForm },
  created() {
    navigator.geolocation.getCurrentPosition(
      this.onSuccessGeolocating,
      this.onErrorGeolocating,
      {
        enableHighAccuracy: true,
        maximumAge: 5000,
        timeout: 10000,
      }
    );
  },
  data() {
    return {
      locationData: {
        lat: 0,
        lng: 0,
        address: "",
        city: 0,
        branch_name: "",
        schedule: "",
      },
    };
  },
  methods: {
    onSuccessGeolocating(data) {
      this.changePositionMarker({
        latLng: {
          lat: data.coords.latitude,
          lng: data.coords.longitude,
        },
      });
    },
    onErrorGeolocating(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    },
    async createBranch() {
      await this.$store.dispatch("CREATE_BRANCH", {
        direccion: this.locationData.address,
        latitud: this.locationData.lat,
        longitud: this.locationData.lng,
        ciudad: this.locationData.city,
        nombre_sede: this.locationData.branch_name,
        horario: this.locationData.schedule,
      });
      this.$router.push("/empresa/sedes");
    },
    changePositionMarker(value) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: value.latLng }, (results) => {
        this.locationData.lat = results[0].geometry.location.lat();
        this.locationData.lng = results[0].geometry.location.lng();
        this.locationData.address = results[0].formatted_address;
      });
    },
  },
};
</script>

<style scoped>
.branch__new {
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  grid-gap: 20px;
}
.branch__new .box {
  display: grid;
  grid-template-columns: 1fr 300px;
}
.box-body.map {
  padding: 5px;
}
</style>
