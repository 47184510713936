export default {
  methods: {
    medioPago(value) {
      let nombrePago = "Sin especificar";
      let status = "Sin especificar";
      let imagen = "";
      switch (value) {
        case "1":
          nombrePago = "ePayco TC";
          status = "text_pagos_epaycoTC";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "2":
          nombrePago = "ePayco PSE";
          status = "text_pagos_epaycoPSE";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "3":
          nombrePago = "ePayco efectivo";
          status = "text_pagos_epaycoEfectivo";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "4":
          nombrePago = "Consignación";
          status = "text_pagos_consignación";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "5":
          nombrePago = "Efecty";
          status = "text_pagos_efecty";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "6":
          nombrePago = "Paga en Tienda";
          status = "text_pagos_pagaTienda";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "7":
          nombrePago = "Pago a convenir";
          status = "text_pagos_pagaConvernir";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "8":
          nombrePago = "ePayco SafetyPay";
          status = "text_pagos_epaycoSafetyPay";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "9":
          nombrePago = "Pago contraentrega";
          status = "text_pagos_pagaContraentrega";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "10":
          nombrePago = "Mercado Pago TC";
          status = "text_pagos_mercadopagoTC";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "11":
          nombrePago = "PayU TC";
          status = "text_pagos_payUTUC";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "12":
          nombrePago = "PayU efectivo";
          status = "text_pagos_payUEfectivo";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "13":
          nombrePago = "PayU PSE";
          status = "text_pagos_payUPSE";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "14":
          nombrePago = "Mercado Pago PSE";
          status = "text_pagos_mercadopagoPSE";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "15":
          nombrePago = "Daviplata";
          status = "text_pagos_daviplata";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "16":
          nombrePago = "Nequi";
          status = "text_pagos_nequi";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "17":
          nombrePago = "Credibanco";
          status = "Credibanco";
          imagen = require("../assets/payments/credibanco.png");
          break;
        case "18":
          nombrePago = "Wompi";
          status = "text_pagos_Wompi";
          imagen = require("../assets/payments/wompi.png");
          break;
        case "19":
          nombrePago = "FLOW";
          status = "text_pagos_FLOW";
          imagen = require("../assets/payments/flow.png");
          break;
        case "20":
          nombrePago = "Wepay4U";
          status = "text_pagos_wepay4U";
          imagen =
            "https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1635200779/komerica/cropped-logo-wp4u_fo7gqc.png";
          break;
        case "21":
          nombrePago = "TuCompra";
          status = "text_pagos_TUCOMPRA";
          imagen = require("../assets/payments/tucompra.png");
          break;
        case "22":
          nombrePago = "HOKO (Recaudo en efectivo)";
          status = "text_pagos_Hoko_recaudo";
          imagen =
            "https://res.cloudinary.com/komerciaacademico/image/upload/c_scale,q_auto:best,f_auto/v1644954621/hoko-svg_ygzvza.png";
          break;
        case "23":
          nombrePago = "PayU";
          status = "text_pagos_payU";
          imagen = require("../assets/payments/Payu.png");
          break;
        case "24":
          nombrePago = "Mercado Pago";
          status = "text_pagos_mercadoPAgo";
          imagen = require("../assets/payments/mercadopago.png");
          break;
        case "25":
          nombrePago = "ePayco";
          status = "text_pagos_ePayco";
          imagen = require("../assets/payments/epayco.png");
          break;
        case "26":
          nombrePago = "Addi";
          status = "text_pagos_addi";
          imagen = require("../assets/payments/addi.svg");
          break;
      }
      const medio = {
        nombrePago,
        status,
        imagen,
      };
      return medio;
    },
  },
};
