import axios from "axios";

export default {
  namespaced: true,
  state: {
    totalCart: 0,
    listCupones: [],
    couponCodeSelected: "",
    discountCupon: 0,
    discountDescuentos: 0,
    listDescuentos: [],
    currentCustomer: null,
    currentChannel: null,
    products: [],
    acquisitionChannels: [
      {
        id: 4,
        name: "Punto fisico",
        icon: ["fas", "cash-register"],
        differentIcon: true,
        color: "#4429b4",
      },
      {
        id: 1,
        name: "Whatsapp",
        icon: "fab fa-whatsapp",
        color: "#25D366",
      },
      {
        id: 2,
        name: "Instagram",
        icon: "fab fa-instagram",
        color: "#e1306c",
      },
      {
        id: 3,
        name: "Facebook",
        icon: "fab fa-facebook-f",
        color: "#3b5998",
      },
    ],
  },
  getters: {
    channel: (state) => (id) => {
      return (
        state.acquisitionChannels.find((channel) => channel.id === id) || {
          id: 0,
        }
      );
    },
    subtotal(state, getters) {
      return state.products.reduce((total, item) => {
        return total + item.precio * item.cantidad;
      }, 0);
    },
    total(state, getters) {
      return getters.subtotal - state.discountDescuentos - state.discountCupon;
    },
    totalDiscount(state, getters) {
      return state.discountDescuentos + state.discountCupon;
    },
    cantidadProductos(state) {
      return state.products
        ? state.products.reduce(
            (total, product) => total + parseInt(product.cantidad),
            0
          )
        : 0;
    },

    listaDescuentosProductos(state, getters) {
      const activeDiscounts = state.listDescuentos
        .filter((element) => element.tipo === 0 && element.estado === 1)
        .filter(
          (element) => getters.cantidadProductos >= element.cantidad_productos
        );

      if (activeDiscounts.length > 0) {
        const resultDesc = activeDiscounts[0];
        const data = {
          cantidad: resultDesc.cantidad_productos,
          valor:
            resultDesc.opcion === 1
              ? resultDesc.valor_descuento
              : resultDesc.porcentaje_descuento,
          tipo: resultDesc.opcion,
        };
        return data;
      } else {
        return "";
      }
    },
    listaDescuentosPrecio(state, getters) {
      const activeDiscounts = state.listDescuentos.filter(
        (element) => element.tipo === 1 && element.estado === 1
      );
      let resultDesc = null;

      activeDiscounts.forEach((element) => {
        const rangosByDisconunt = JSON.parse(element.rangos_precios);
        if (rangosByDisconunt) {
          rangosByDisconunt.some((rango) => {
            if (
              getters.subtotal >= rango["inicial"] &&
              getters.subtotal <= rango["final"]
            ) {
              resultDesc = rango;
              return true; // Termina la iteración al encontrar un rango válido
            }
            return false;
          });
        }
      });
      return resultDesc || "";
    },
    listaCupones(state) {
      const cuponesActivos = state.listCupones.filter(
        (cupon) => cupon.estado === 1
      );
      return cuponesActivos;
    },
  },
  mutations: {
    SET_CURRENT_CUSTOMER(state, newValue) {
      state.currentCustomer = newValue;
    },
    SET_CURRENT_CHANNEL(state, newValue) {
      state.currentChannel = newValue;
    },
    SET_TOTAL_CART(state, newValue) {
      state.totalCart = newValue;
    },
    SET_TOTAL_DISCOUNT(state, newValue) {
      state.discountDescuentos = newValue;
    },
    SET_DISCOUNT_CUPON(state, newValue) {
      state.discountCupon = newValue;
    },
    SET_LIST_CUPONES(state, cupones) {
      state.listCupones = cupones;
    },
    SET_COUPON_CODE_SELECTED(state, newValue) {
      state.couponCodeSelected = newValue;
    },
  },
  actions: {
    async ADD_PRODUCT({ state, dispatch }, newProduct) {
      const { payload } = await dispatch("findProduct", newProduct);
      if (payload) {
        dispatch("ADD_UNIT_PRODUCT", newProduct);
      } else {
        state.products.unshift(newProduct);
      }
    },
    async REMOVE_PRODUCT({ state, dispatch }, product) {
      const { index } = await dispatch("findProduct", product);
      state.products.splice(index, 1);
    },
    async ADD_UNIT_PRODUCT({ state, dispatch }, product) {
      const { payload, index } = await dispatch("findProduct", product);
      if (payload.inventario > payload.cantidad) {
        state.products.splice(
          index,
          1,
          Object.assign({}, payload, { cantidad: payload.cantidad + 1 })
        );
      }
    },
    async REMOVE_UNIT_PRODUCT({ state, dispatch }, product) {
      const { payload, index } = await dispatch("findProduct", product);
      state.products.splice(
        index,
        1,
        Object.assign({}, payload, { cantidad: payload.cantidad - 1 })
      );
    },
    async CREATE_ORDER(
      { rootState, state, getters, commit, dispatch },
      { respond = true, paymentMethod }
    ) {
      // console.log(respond)
      const channel = getters.channel(state.currentChannel).id;

      try {
        const { data } = await axios({
          method: "POST",
          url: `${rootState.configKomercia.url}/api/usuario/orden`,
          headers: rootState.configAxios.headers,
          data: {
            productos: state.products.map((product) => {
              if (
                Object.prototype.hasOwnProperty.call(product, "combinacion")
              ) {
                product.combinacion = product.combinacion.combinacion;
              }
              return product;
            }),
            tienda: rootState.storeData.id,
            usuario: state.currentCustomer.id,
            tipo: 0,
            total: getters.total,
            estado: channel === 4 && paymentMethod === "6" ? 1 : 0,
            direccion_entrega: 0,
            descuento: getters.totalDiscount || null,
            cupon: state.couponCodeSelected || null,
            metodo_pago: paymentMethod || 7,
            costo_envio: 0,
            cliente: state.currentCustomer.id,
            canal: channel,
            ip: "186.114.244.90",
            takeout: false,
            estado_entrega: 0,
            comentario: "Generado por link de pago",
          },
        });
        if (respond) {
          await dispatch("UPDATE_INVENTORY");
          state.products = [];
          commit("SET_CURRENT_CUSTOMER", null);
        }
        if (data) {
          // commit("ADD_ORDER", data.data, { root: true });
          return { success: true, data: data };
        }
      } catch (err) {
        console.log("ERROR CREATE_ORDER");

        console.log(err.response);
        return { success: false, data: null };
      }
    },
    async UPDATE_INVENTORY({ rootState, state, commit }) {
      state.products.forEach((product) => {
        const index = rootState.productsData.findIndex(
          (rootProduct) => rootProduct.id === product.id
        );
        let params = {
          index,
          quantity: product.cantidad,
          with_variant: product.con_variante,
        };
        if (Object.prototype.hasOwnProperty.call(product, "combinacion")) {
          params.combination = product.combinacion;
        }
        commit("SET_INVENTORY_BY_PRODUCT", params, { root: true });
      });
      return true;
    },
    async findProduct({ state }, { id, combinacion }) {
      const payload = await state.products.find((product) => {
        if (product.id === id) {
          if (product.con_variante) {
            if (
              JSON.stringify(product.combinacion.combinacion) ===
              JSON.stringify(combinacion.combinacion)
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      });
      const index = await state.products.findIndex((product) => {
        if (product.id === id) {
          if (product.con_variante) {
            if (
              JSON.stringify(product.combinacion.combinacion) ===
              JSON.stringify(combinacion.combinacion)
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      });
      return {
        payload,
        index,
      };
    },
    async VERIFY_PRODUCTS({ rootState, state, commit }) {
      if (state.products?.length > 0) {
        let idProducts = state.products.map((a) => a.id.toString());
        if (idProducts.length == 1) {
          idProducts = [idProducts];
        }

        const { data } = await axios({
          method: "POST",
          url: `${rootState.configKomercia.url}/api/ids/por/productos`,
          headers: rootState.configAxios.headers,
          data: {
            id_tienda: rootState.storeData.id,
            ids: idProducts,
          },
        });
        if (data) {
          let productServer = data.data;
          let merged = [];
          for (let i = 0; i < productServer.length; i++) {
            merged.push({
              ...state.products[i],
              ...productServer[i],
            });
          }

          const productsFinal = merged.map((product) => {
            if (product.con_variante === 1 && product.variantes?.length > 0) {
              let variantesConSplit =
                product.variantes[0].combinaciones[0].combinaciones
                  .toString()
                  .slice(1, -1);
              let arrayObtain = JSON.parse(`[${variantesConSplit}]`);

              let filterCombination = arrayObtain.filter(
                (item) =>
                  item?.combinacion.toString() ===
                  product?.combinacion?.combinacion.toString()
              );
              const newProduct = {
                cantidad: product.cantidad,
                combinacion: filterCombination[0].combinacion,
                envio_gratis: product.envio_gratis,
                foto_cloudinary: product.foto_cloudinary,
                id: product.id,
                limitQuantity: parseInt(filterCombination[0].unidades),
                nombre: product.nombre,
                precio: filterCombination[0].precio,
                activo: product.activo,
                stock_disponible: 1,
                con_variante: product.con_variante,
              };
              const filterEstado = filterCombination[0].estado;
              const filterUnidades = filterCombination[0].unidades;

              if (!filterEstado || filterUnidades == 0) {
                newProduct.activo = 0;
              } else if (newProduct.cantidad > newProduct.limitQuantity) {
                newProduct.stock_disponible = 0;
              }
              return newProduct;
            } else {
              const newProduct = {
                cantidad: product.cantidad,
                envio_gratis: product.envio_gratis,
                foto_cloudinary: product.foto_cloudinary,
                id: product.id,
                limitQuantity: product.informacion_producto[0].inventario,
                nombre: product.nombre,
                precio: product.precio,
                activo: product.activo,
                stock_disponible: 1,
                con_variante: 0,
              };
              if (newProduct.cantidad === 0) {
                newProduct.activo = 0;
              } else {
                newProduct.stock_disponible =
                  newProduct.cantidad > newProduct.limitQuantity ? 0 : 1;
              }
              return newProduct;
            }
          });
          state.products = productsFinal;
          return { successProduct: true };
        }
      }
    },
    async GET_DESCUENTOS({ state, rootState, dispatch }) {
      await dispatch("GET_STORE", null, { root: true });
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.urlKomerciaAWS}/api/v1/stores/discounts/${rootState.storeData.id}`,
          headers: {
            KOMERCIA_PUBLIC_ROUTES_KEY: rootState.routerKey,
          },
        });
        if (data) {
          state.listDescuentos = data.data.sort(function (prev, next) {
            return prev.cantidad_productos - next.cantidad_productos;
          });
        }
      } catch (err) {
        console.log( err?.response);
      }
    },
    async GET_CUPONES({ state, rootState, dispatch, commit }) {
      if (!rootState.storeData.id) {
        await dispatch("GET_STORE", null, { root: true });
      }
      try {
        const { data } = await axios({
          method: "GET",
          url: `${rootState.configKomercia.url}/api/cupones/${rootState.storeData.id}`,
          headers: rootState.configAxios.headers,
        });
        if (data) {
          // Utiliza la mutación para establecer los cupones
          commit("SET_LIST_CUPONES", data.cupones);
        }
      } catch (err) {
        console.log( err?.response);
      }
    },
    // Otros actions...
  },
};
