<template>
  <div>
    <div class="content-main">
      <div class="content-title">
        <h1 :class="darkMode ? 'darktxt' : 'lighttxt'" class="title-main">
          {{ $t("text_inicio_cursoRecomendados") }}
        </h1>
        <a
          title="Ir al curso"
          href="https://panel.komercia.co/academia/listadoCursos"
          rel="noreferrer noopener"
          target="_blank "
        >
          <p :class="darkMode ? 'darktxt' : 'lighttxt'" class="view-more-text">
            {{ $t("text_inicio_ver_cursoRecomendados") }}
          </p>
        </a>
      </div>
      <div class="content-blog">
        <div
          v-for="(post, index) in postsDataCursos"
          :key="`post${index}`"
          class="posts_item"
          :class="darkMode ? 'posts_item_dark' : 'posts_item_lightbg'"
        >
          <router-link :to="post.link" rel="noreferrer noopener">
            <img loading="lazy" class="post-image" :src="post.photo" />
            <div class="box-body" :class="darkMode ? 'darkbg' : 'lightbg'">
              <h4
                :class="darkMode ? 'darktxt' : 'lighttxt'"
                class="title-video"
              >
                {{ $t(`${post.title}`) }}
              </h4>
              <div class="footer">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ post.date }}
                </p>
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ post.author }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="content-main" style="margin-top: 20px">
      <div class="content-title">
        <h1 :class="darkMode ? 'darktxt' : 'lighttxt'" class="title-main">
          {{ $t("text_inicio_videosRecomendados") }}
        </h1>
        <a
          title="Canal de Komercia"
          href="https://www.youtube.com/channel/UCrdslJeTIswf2dk4wO_lKJA"
          rel="noreferrer noopener"
          target="_blank "
        >
          <p :class="darkMode ? 'darktxt' : 'lighttxt'" class="view-more-text">
            {{ $t("text_inicio_ver_videosRecomendados") }}
          </p>
        </a>
      </div>
      <div class="content-blog">
        <div
          v-for="(post, index) in postsDataVideos"
          :key="`post${index}`"
          class="posts_item"
          :class="darkMode ? 'posts_item_dark' : 'posts_item_lightbg'"
        >
          <a
            title="Curso de Komercia"
            :href="post.link"
            rel="noreferrer noopener"
            target="_blank "
          >
            <img loading="lazy" class="post-image" :src="post.photo" />
            <div class="box-body" :class="darkMode ? 'darkbg' : 'lightbg'">
              <h4
                :class="darkMode ? 'darktxt' : 'lighttxt'"
                class="title-video"
              >
                {{ $t(`${post.title}`) }}
              </h4>
              <div class="footer">
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ post.date }}
                </p>
                <p :class="darkMode ? 'darktxt' : 'lighttxt'">
                  {{ post.author }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      postsDataVideos: [
        {
          link: "https://youtu.be/hP_RMbhhrv8",
          title: "text_inicio_video_info1",
          photo: "https://i3.ytimg.com/vi/hP_RMbhhrv8/maxresdefault.jpg",
          author: "Gustavo Moreno",
          date: "Feb 24, 2020",
        },
        {
          link: "https://youtu.be/gRVK39Tk614",
          title: "text_inicio_video_info2",
          photo: "https://i3.ytimg.com/vi/gRVK39Tk614/maxresdefault.jpg",
          author: "Komercia",
          date: "Jul 22, 2019",
        },
        {
          link: "https://www.youtube.com/embed/Ec0YIpWfJtA",
          title: "text_inicio_video_info3",
          photo:
            "https://res.cloudinary.com/komerciaacademico/image/upload/c_crop,g_custom/v1626899562/komerciaAcademico/Sin_t%C3%ADtulo_cxxks7.png",
          author: "Komercia",
          date: "Dic 10, 2020",
        },
        {
          link: "https://www.youtube.com/embed/s2ZMMbW47aY",
          title: "text_inicio_video_info4",
          photo:
            "https://res.cloudinary.com/komerciaacademico/image/upload/c_crop,g_custom/v1626899776/komerciaAcademico/2_vbxqvv.png",
          author: "Komercia",
          date: "Jun 22, 2021",
        },
      ],
      postsDataCursos: [
        {
          link: "academia/curso/9121-Aprende%20a%20crear%20tu%20tienda%20online",
          title: "text_inicio_video_info5",
          photo:
            "https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1586809516/komerciaAcademico/cursos/1_kv1j7l.jpg",
          author: "Angélica Bohórquez",
          date: "Abril 13, 2020",
        },
        {
          link: "academia/curso/9124-Aterrizando%20el%20marketing%20digital",
          title: "text_inicio_video_info6",
          photo: "https://i3.ytimg.com/vi/8i_A32Je-NA/maxresdefault.jpg",
          author: "Luis Betancourt",
          date: "Ago 23, 2019",
        },
        {
          link: "academia/curso/9123-Curso%20Fotografía%20de%20Producto",
          title: "text_inicio_video_info7",
          photo: "https://i3.ytimg.com/vi/Rw18PIjcuC8/maxresdefault.jpg",
          author: "Alexis Marchesini",
          date: "Jul 08, 2019",
        },
        {
          link: "academia/curso/9126-Redes%20sociales%20desde%20cero",
          title: "text_inicio_video_info8",
          photo:
            "https://res.cloudinary.com/brahyanr10/image/upload/q_auto,f_auto/v1621038911/Panel-Komercia/Redes/banner-redes_v4smum.jpg",
          author: "Jorge Gómez",
          date: "Abril 01, 2021",
        },
      ],
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
  },
};
</script>
<style scoped>
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.darkbg {
  background-color: #38526f;
}
.lightbg {
  background-color: #fff;
}
.content-main {
  @apply flex flex-row justify-center items-center flex-col w-full;
}
.content-title {
  @apply flex flex-row w-full justify-between items-center mt-4;
}
.content-blog {
  @apply grid w-full grid-cols-1 gap-x-3 mt-4;
}
.posts_item {
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  @apply overflow-hidden mt-6;
}
.posts_item_dark {
  background-color: transparent;
}
.posts_item_lightbg {
  @apply bg-white;
}
@screen sm {
  .content-blog {
    @apply grid-cols-2;
  }
}
@screen md {
  .content-blog {
    @apply grid-cols-3;
  }
}
@screen lg {
  .content-blog {
    @apply grid-cols-4;
  }
  .posts_item {
    @apply mt-0;
  }
}
.post-image {
  min-height: 120px;
  height: 150px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
.box-body {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px 20px;
}
.title-main {
  @apply text-lg font-semibold;
}
.view-more-text {
  @apply underline text-xs;
}
.title-video {
  font-weight: 500;
  font-size: 13px;
  /* color: rgb(26, 46, 59); */
  font-size: 0.875rem;
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-weight: 500;
  letter-spacing: 0.01rem;
  line-height: 1.42857;
}
.desc {
  width: 400px;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  color: #4c4c4c;
  text-align: justify;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer p {
  font-size: 11px;
  /* color: #4c4c4c; */
}
.content-scroll {
  @apply w-full;
}
@media (max-width: 324px) {
  .view-more-text {
    text-align: right;
  }
}
</style>
