<template>
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      style="fill: #0e9347"
      d="M488.727,325.818H23.273C10.473,325.818,0,315.345,0,302.545v-256
	c0-12.8,10.473-23.273,23.273-23.273h465.455c12.8,0,23.273,10.473,23.273,23.273v256C512,315.345,501.527,325.818,488.727,325.818z
	"
    ></path>
    <path
      style="fill: #0d8944"
      d="M430.545,232.727c-26.764,0-51.2,12.8-65.164,33.745C353.745,260.655,340.945,256,325.818,256
	c-40.727,0-74.473,30.255-80.291,69.818h243.2c11.636,0,20.945-8.145,23.273-19.782
	C507.345,265.309,472.436,232.727,430.545,232.727z"
    ></path>
    <path
      style="fill: #3bb54a"
      d="M442.182,302.545H69.818c0-25.6-20.945-46.545-46.545-46.545V93.091
	c25.6,0,46.545-20.945,46.545-46.545h372.364c0,25.6,20.945,46.545,46.545,46.545V256C463.127,256,442.182,276.945,442.182,302.545z
	"
    ></path>
    <g>
      <path
        style="fill: #0e9347"
        d="M430.545,232.727c-26.764,0-51.2,12.8-65.164,33.745C353.745,260.655,340.945,256,325.818,256
		c-32.582,0-60.509,18.618-73.309,46.545h189.673c0-25.6,19.782-45.382,45.382-46.545
		C472.436,242.036,452.655,232.727,430.545,232.727z"
      ></path>
      <ellipse
        style="fill: #0e9347"
        cx="256"
        cy="174.545"
        rx="93.091"
        ry="104.727"
      ></ellipse>
      <circle
        style="fill: #0e9347"
        cx="116.364"
        cy="174.545"
        r="23.273"
      ></circle>
      <circle
        style="fill: #0e9347"
        cx="395.636"
        cy="174.545"
        r="23.273"
      ></circle>
    </g>
    <path
      style="fill: #89c763"
      d="M267.636,162.909h-23.273c-6.982,0-11.636-4.655-11.636-11.636c0-6.982,4.655-11.636,11.636-11.636
	h34.909c6.982,0,11.636-4.655,11.636-11.636c0-6.982-4.655-11.636-11.636-11.636h-11.636c0-6.982-4.655-11.636-11.636-11.636
	c-6.982,0-11.636,4.655-11.636,11.636c-19.782,0-34.909,15.127-34.909,34.909s15.127,34.909,34.909,34.909h23.273
	c6.982,0,11.636,4.655,11.636,11.636c0,6.982-4.655,11.636-11.636,11.636h-34.909c-6.982,0-11.636,4.655-11.636,11.636
	c0,6.982,4.655,11.636,11.636,11.636h11.636c0,6.982,4.655,11.636,11.636,11.636c6.982,0,11.636-4.655,11.636-11.636
	c19.782,0,34.909-15.127,34.909-34.909S287.418,162.909,267.636,162.909z"
    ></path>
    <circle style="fill: #ffca5d" cx="302.545" cy="337.455" r="81.455"></circle>
    <circle style="fill: #f6b545" cx="407.273" cy="314.182" r="81.455"></circle>
    <path
      style="fill: #ffcb5b"
      d="M407.273,232.727c-45.382,0-81.455,36.073-81.455,81.455s36.073,81.455,81.455,81.455
	s81.455-36.073,81.455-81.455S452.655,232.727,407.273,232.727z M407.273,372.364c-32.582,0-58.182-25.6-58.182-58.182
	S374.691,256,407.273,256s58.182,25.6,58.182,58.182S439.855,372.364,407.273,372.364z"
    ></path>
    <path
      style="fill: #f6b545"
      d="M407.273,394.473L407.273,394.473c45.382,1.164,81.455-36.073,81.455-80.291h-1.164
	c-5.818,0-10.473,4.655-11.636,10.473c-2.327,16.291-10.473,31.418-22.109,41.891c-9.309,9.309-22.109,15.127-36.073,17.454
	C411.927,384,407.273,388.655,407.273,394.473z"
    ></path>
    <path
      style="fill: #ffe27a"
      d="M407.273,233.891L407.273,233.891c-45.382-1.164-81.455,36.073-81.455,80.291h1.164
	c5.818,0,10.473-4.655,11.636-10.473c2.327-16.291,10.473-31.418,22.109-41.891c9.309-9.309,22.109-15.127,36.073-17.455
	C402.618,244.364,407.273,239.709,407.273,233.891z"
    ></path>
    <path
      style="fill: #f19920"
      d="M357.236,322.327c0-32.582,25.6-58.182,58.182-58.182c13.964,0,26.764,4.655,37.236,13.964
	C441.018,264.145,425.891,256,407.273,256c-32.582,0-58.182,25.6-58.182,58.182c0,18.618,8.145,33.745,20.945,44.218
	C361.891,349.091,357.236,336.291,357.236,322.327z"
    ></path>
    <path
      style="fill: #ffcb5b"
      d="M442.182,350.255c-1.164,0-2.327,0-3.491-1.164c-2.327-2.327-2.327-5.818,0-8.145
	c6.982-6.982,10.473-17.455,10.473-26.764c0-3.491,2.327-5.818,5.818-5.818c3.491,0,5.818,2.327,5.818,5.818
	c0,12.8-4.655,25.6-12.8,34.909C445.673,350.255,443.345,350.255,442.182,350.255z"
    ></path>
    <g>
      <path
        style="fill: #f19920"
        d="M407.273,349.091c-6.982,0-11.636-4.655-11.636-11.636v-46.545c0-6.982,4.655-11.636,11.636-11.636
		s11.636,4.655,11.636,11.636v46.545C418.909,344.436,414.255,349.091,407.273,349.091z"
      ></path>
      <path
        style="fill: #f19920"
        d="M450.327,382.836c-8.145-29.091-29.091-52.364-55.855-62.836c-4.655-26.764-22.109-50.036-44.218-64
		c-13.964,15.127-23.273,34.909-23.273,57.018c0,45.382,36.073,81.455,81.455,81.455
		C423.564,395.636,437.527,390.982,450.327,382.836z"
      ></path>
    </g>
    <g>
      <path
        style="fill: #e78825"
        d="M370.036,358.4c-8.145-10.473-13.964-23.273-13.964-37.236c0-16.291,6.982-31.418,18.618-41.891
		c-2.327-3.491-4.655-5.818-6.982-8.145c-11.636,10.473-18.618,25.6-18.618,43.055C349.091,332.8,357.236,347.927,370.036,358.4z"
      ></path>
      <path
        style="fill: #e78825"
        d="M395.636,321.164v16.291c0,6.982,4.655,11.636,11.636,11.636s11.636-4.655,11.636-11.636v-2.327
		C411.927,329.309,403.782,324.655,395.636,321.164z"
      ></path>
    </g>
    <path
      style="fill: #f6b545"
      d="M450.327,382.836c-2.327-8.145-4.655-15.127-9.309-22.109c-9.309,6.982-20.945,11.636-33.745,11.636
	c-32.582,0-58.182-25.6-58.182-58.182c0-17.455,6.982-32.582,18.618-43.055c-5.818-5.818-11.636-10.473-18.618-13.964
	c-13.964,15.127-23.273,34.909-23.273,57.018c0,45.382,36.073,81.455,81.455,81.455
	C423.564,395.636,437.527,390.982,450.327,382.836z"
    ></path>
    <path
      style="fill: #f19920"
      d="M446.836,372.364c-8.145,5.818-18.618,9.309-29.091,11.636c-5.818,1.164-10.473,5.818-10.473,11.636
	l0,0c16.291,0,30.255-4.655,43.055-12.8C449.164,379.345,448,375.855,446.836,372.364z"
    ></path>
    <path
      style="fill: #ffcb5b"
      d="M349.091,257.164c-13.964,15.127-23.273,34.909-23.273,57.018h1.164
	c5.818,0,10.473-4.655,11.636-10.473c2.327-16.291,10.473-30.255,20.945-40.727C356.073,261.818,352.582,259.491,349.091,257.164z"
    ></path>
    <circle style="fill: #f6b545" cx="302.545" cy="337.455" r="81.455"></circle>
    <path
      style="fill: #f19920"
      d="M290.909,395.636c0,8.145,1.164,15.127,3.491,23.273c2.327,0,5.818,0,8.145,0
	c45.382,0,81.455-36.073,81.455-81.455c0-8.145-1.164-15.127-3.491-23.273c-2.327,0-5.818,0-8.145,0
	C326.982,314.182,290.909,350.255,290.909,395.636z"
    ></path>
    <path
      style="fill: #ffcb5b"
      d="M302.545,256c-45.382,0-81.455,36.073-81.455,81.455s36.073,81.455,81.455,81.455
	S384,382.836,384,337.455S347.927,256,302.545,256z M302.545,395.636c-32.582,0-58.182-25.6-58.182-58.182
	s25.6-58.182,58.182-58.182s58.182,25.6,58.182,58.182S335.127,395.636,302.545,395.636z"
    ></path>
    <path
      style="fill: #f6b545"
      d="M302.545,417.745L302.545,417.745C347.927,418.909,384,381.673,384,337.455h-1.164
	c-5.818,0-10.473,4.655-11.636,10.473c-2.327,16.291-10.473,31.418-22.109,41.891c-9.309,9.309-22.109,15.127-36.073,17.455
	C307.2,407.273,302.545,411.927,302.545,417.745z"
    ></path>
    <path
      style="fill: #ffe27a"
      d="M302.545,257.164L302.545,257.164c-45.382-1.164-81.455,36.073-81.455,80.291h1.164
	c5.818,0,10.473-4.655,11.636-10.473c2.327-16.291,10.473-31.418,22.109-41.891c9.309-9.309,22.109-15.127,36.073-17.455
	C297.891,267.636,302.545,262.982,302.545,257.164z"
    ></path>
    <path
      style="fill: #f19920"
      d="M252.509,345.6c0-32.582,25.6-58.182,58.182-58.182c13.964,0,26.764,4.655,37.236,13.964
	c-11.636-13.964-26.764-22.109-45.382-22.109c-32.582,0-58.182,25.6-58.182,58.182c0,18.618,8.145,33.745,20.945,44.218
	C257.164,372.364,252.509,359.564,252.509,345.6z"
    ></path>
    <path
      style="fill: #ffcb5b"
      d="M337.455,373.527c-1.164,0-2.327,0-3.491-1.164c-2.327-2.327-2.327-5.818,0-8.145
	c6.982-6.982,10.473-17.455,10.473-26.764c0-3.491,2.327-5.818,5.818-5.818c3.491,0,5.818,2.327,5.818,5.818
	c0,12.8-4.655,25.6-12.8,34.909C340.945,373.527,338.618,373.527,337.455,373.527z"
    ></path>
    <path
      style="fill: #f19920"
      d="M302.545,372.364c-6.982,0-11.636-4.655-11.636-11.636v-46.545c0-6.982,4.655-11.636,11.636-11.636
	c6.982,0,11.636,4.655,11.636,11.636v46.545C314.182,367.709,309.527,372.364,302.545,372.364z"
    ></path>
    <g>
      <path
        style="fill: #f6b545"
        d="M356.073,315.345c2.327,6.982,4.655,13.964,4.655,22.109c0,32.582-25.6,58.182-58.182,58.182
		c-3.491,0-8.145,0-11.636-1.164v1.164c0,8.145,1.164,15.127,3.491,23.273c2.327,0,5.818,0,8.145,0
		c45.382,0,81.455-36.073,81.455-81.455c0-8.145-1.164-15.127-3.491-23.273c-2.327,0-5.818,0-8.145,0
		C366.545,314.182,361.891,314.182,356.073,315.345z"
      ></path>
      <circle
        style="fill: #f6b545"
        cx="360.727"
        cy="407.273"
        r="81.455"
      ></circle>
    </g>
    <path
      style="fill: #ffcb5b"
      d="M360.727,325.818c-45.382,0-81.455,36.073-81.455,81.455s36.073,81.455,81.455,81.455
	s81.455-36.073,81.455-81.455S406.109,325.818,360.727,325.818z M360.727,465.455c-32.582,0-58.182-25.6-58.182-58.182
	s25.6-58.182,58.182-58.182s58.182,25.6,58.182,58.182S393.309,465.455,360.727,465.455z"
    ></path>
    <path
      style="fill: #f6b545"
      d="M360.727,487.564L360.727,487.564c45.382,1.164,81.455-36.073,81.455-80.291h-1.164
	c-5.818,0-10.473,4.655-11.636,10.473c-2.327,16.291-10.473,31.418-22.109,41.891c-9.309,9.309-22.109,15.127-36.073,17.455
	C365.382,477.091,360.727,481.745,360.727,487.564z"
    ></path>
    <path
      style="fill: #ffe27a"
      d="M360.727,326.982L360.727,326.982c-45.382-1.164-81.455,36.073-81.455,80.291h1.164
	c5.818,0,10.473-4.655,11.636-10.473c2.327-16.291,10.473-31.418,22.109-41.891c9.309-9.309,22.109-15.127,36.073-17.455
	C356.073,337.455,360.727,332.8,360.727,326.982z"
    ></path>
    <path
      style="fill: #f19920"
      d="M310.691,415.418c0-32.582,25.6-58.182,58.182-58.182c13.964,0,26.764,4.655,37.236,13.964
	c-11.636-13.964-26.764-22.109-45.382-22.109c-32.582,0-58.182,25.6-58.182,58.182c0,18.618,8.145,33.745,20.945,44.218
	C315.345,442.182,310.691,429.382,310.691,415.418z"
    ></path>
    <path
      style="fill: #ffcb5b"
      d="M395.636,443.345c-1.164,0-2.327,0-3.491-1.164c-2.327-2.327-2.327-5.818,0-8.145
	c6.982-6.982,10.473-17.455,10.473-26.764c0-3.491,2.327-5.818,5.818-5.818s5.818,2.327,5.818,5.818c0,12.8-4.655,25.6-12.8,34.909
	C399.127,443.345,396.8,443.345,395.636,443.345z"
    ></path>
    <path
      style="fill: #f19920"
      d="M360.727,442.182c-6.982,0-11.636-4.655-11.636-11.636V384c0-6.982,4.655-11.636,11.636-11.636
	c6.982,0,11.636,4.655,11.636,11.636v46.545C372.364,437.527,367.709,442.182,360.727,442.182z"
    ></path>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {
  name: "iconCash",
};
</script>

<style></style>
